import React, { FC, useCallback } from 'react';

import { useTranslation } from 'Components/Helpers/translate';

import type { FileType } from '../UploadFileModal';
import fileSize from 'App/babel/helpers/fileSize';

type FileDropzoneTableProps = {
	files: Array<FileType>;
};

const FileDropzoneTable: FC<FileDropzoneTableProps> = ({ files }) => {
	const { t } = useTranslation();

	const getStatusIcon = useCallback(status => {
		let iconClass = 'fa';

		if (status === 'success') {
			iconClass += ' fa-check';
		} else if (status === 'error') {
			iconClass += ' fa-times';
		} else if (status === 'uploading') {
			iconClass += ' fa-refresh fa-spin';
		}

		return iconClass;
	}, []);

	if (!files.length) return null;

	return (
		<table className="upload-table main-table">
			<thead>
				<tr>
					<th />
					<th />
					<th>{t('file.fileName')}</th>
					<th>{t('file.fileSize')}</th>
					<th />
				</tr>
			</thead>
			<tbody>
				{files.map(file => (
					<tr
						key={file.id}
						className={file.status === 'success' ? 'success' : file.status === 'error' ? 'failed' : ''}
					>
						<td className="icon">
							<b className={getStatusIcon(file.status)}></b>
						</td>
						<td>
							<b up-file-type-icon="file.mimetype"></b>
						</td>
						<td>{file.name}</td>
						<td>{fileSize(file.size ?? 0)}</td>
						<td>
							{file.progress! < 1 ? (
								<div className="upload-progress-wrap">
									<div className="upload-progress" style={{ width: file.progress! * 100 }} />
								</div>
							) : null}
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default FileDropzoneTable;
