import { Text, Loader, ModalHeader, ModalContent, ModalControls, Button, Link, Input } from '@upsales/components';
import React, { Fragment, useEffect, useState } from 'react';
import translate from 'Components/Helpers/translate';
import { getFileSize } from 'App/babel/helpers/file';
import Bem from '@upsales/components/Utils/bemClass';
import { asyncModalAdapter, setupComponentCompatibility } from 'App/helpers/angularPortingHelpers';
import PropTypes from 'prop-types';
import './ImageUrl.scss';

const Component = ({ resolve, reject }) => {
	const [urlValue, setUrlValue] = useState('');
	const [imageSize, setImageSize] = useState(null);
	const [loading, setLoading] = useState(false);

	let urlInput;
	useEffect(() => {
		urlInput.focus();
	}, []);

	let t;
	const onUrlValueChange = e => {
		const { value } = e.target;
		setUrlValue(value);

		clearTimeout(t);
		t = setTimeout(() => {
			if (value) {
				setLoading(true);
				getFileSize(value, (err, fileSize) => {
					setLoading(false);
					let fileSizeText, fileSizeColor;
					if (err || isNaN(fileSize)) {
						fileSizeText = 'Image size: unknown';
					} else {
						const fileSizeKb = Math.round(fileSize / 1000);
						fileSizeText = `Image size: ${fileSizeKb}kb`;
						fileSizeColor = fileSizeKb > 60 ? 'red' : 'green';
					}

					return setImageSize(
						<Fragment>
							{fileSizeText} <span style={{ color: fileSizeColor }}>{'(60kb recommended)'}</span>
						</Fragment>
					);
				});
			} else {
				setImageSize(null);
			}
		}, 200);
	};

	const ImageUrlBem = new Bem('ImageUrl');
	return (
		<div className={ImageUrlBem.b()}>
			<ModalHeader title={translate('admin.enterLogoUrl')} onClose={reject} />
			<ModalContent>
				<div>
					<Input inputRef={r => (urlInput = r)} value={urlValue} onChange={onUrlValueChange} type="url" />
					{imageSize && !loading ? <Text>{imageSize}</Text> : null}
					{loading ? <Loader size="xs" noU /> : null}
				</div>
			</ModalContent>
			<ModalControls>
				<Button color="blue" onClick={() => resolve(urlValue)}>
					{translate('default.save')}
				</Button>
				<Link color="bright-blue" onClick={() => reject()}>
					{translate('default.abort')}
				</Link>
			</ModalControls>
		</div>
	);
};

Component.propTypes = {
	reject: PropTypes.func,
	resolve: PropTypes.func
};
export const ImageUrlModal = setupComponentCompatibility(Component, {
	modalName: 'ImageUrlModal',
	modalSize: 'sm'
});
export const openImageUrlModal = asyncModalAdapter({
	openModalName: 'ImageUrlModal',
	upModalName: 'imageUrl',
	featureFlag: 'REACT_IMAGE_URL'
});

export default Component;
