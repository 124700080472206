import BemClass from '@upsales/components/Utils/bemClass';
import { useTranslation } from 'Components/Helpers/translate';
import {
	Button,
	Checkbox,
	Flex,
	Label,
	Modal,
	ModalContent,
	ModalControls,
	ModalHeader,
	Tooltip
} from '@upsales/components';
import React, { useEffect, useRef, useState } from 'react';

import './MoveOrCloseActivity.scss';
import IconName from '@upsales/components/Icon/IconName';
import { ModalProps } from '../Modals/Modals';
import NotesWithSignature from 'Components/Inputs/NotesWithSignature';
import DateAndTime from './DateAndTime';
import { PrimaryButton } from '@upsales/components/Buttons';
import UpActivityTypes from 'Components/Inputs/UpActivityTypes';

export type OnCloseReturnType = {
	notes: string;
	time?: string;
	date?: Date;
	activityType?: number;
	followUp?: boolean;
};

type Props = ModalProps<OnCloseReturnType> & {
	icon: IconName;
	notes?: string;
	description: string;
	isMove?: boolean;
	isClose?: boolean;
};

type SelectActivityType = { title: string; id: number };

const MoveOrCloseActivity = ({ icon, notes: oldNotes, description, isMove, isClose, close, className }: Props) => {
	const classes = new BemClass('MoveOrCloseActivity', className);
	const { t } = useTranslation();
	const [time, setTime] = useState(undefined as string | undefined);
	const [date, setDate] = useState(undefined as Date | undefined);
	const [notes, setNotes] = useState('');
	const [followUp, setFollowUp] = useState(false);
	const [activityType, setActivityType] = useState(null as SelectActivityType | null);
	const [saving, setSaving] = useState(false);
	const anchor = useRef<Element | null>(null);

	useEffect(() => {
		if (!anchor.current) {
			anchor.current = document.querySelector('.Modals');
		}
	}, [anchor.current]);

	const onSave = () => {
		setSaving(true);

		const fixedDate = date;
		if (time && fixedDate) {
			const [hours, minutes] = time.split(':');
			fixedDate.setHours(Number(hours), Number(minutes), 0, 0);
		} else if (fixedDate) {
			fixedDate.setHours(0, 0, 0, 0);
		}

		let fixedNotes = notes;
		if (notes && oldNotes) {
			fixedNotes = notes + '\n\n' + oldNotes;
		} else {
			fixedNotes = oldNotes || notes;
		}
		close({ notes: fixedNotes, time, date: fixedDate, activityType: activityType?.id, followUp });
	};

	const abort = () => {
		close(undefined, true);
	};

	const title = isMove ? t('activity.move') : t('default.closeActivity');
	const submitBtn = isMove ? t('default.move') : t('default.close');
	const andCreateFollowUp = t('default.and').toLowerCase() + ' ' + t('activity.createFolowup').toLowerCase();
	const disableSave = (isMove && !date) || (followUp && (!date || !activityType));

	const requiredMissing: string[] = [];
	if (!date && (isMove || followUp)) {
		requiredMissing.push(t('validation.missingRequiredFields', { field: t('default.date') }));
	}
	if (followUp && !activityType) {
		requiredMissing.push(t('validation.missingRequiredFields', { field: t('default.activityType') }));
	}

	return (
		<Modal size="sm" className={classes.b()}>
			<>
				<ModalHeader icon={icon} title={title} onClose={abort} />
				<ModalContent>
					<div className="up-panel">
						<div className="up-panel-content">
							<fieldset>
								<div className="row">
									<div className="col-md-12">
										<div className="form-group">
											<Label>{t('default.description')}</Label>
											<div className="input-group">
												<span>{description}</span>
											</div>
										</div>
									</div>
								</div>
								{isMove ? (
									<DateAndTime
										anchor={anchor}
										date={date}
										setDate={setDate}
										time={time}
										setTime={setTime}
									/>
								) : null}
								<div className="row">
									<div className="col-md-12">
										<div className={'form-group'} id="notes">
											<NotesWithSignature onChange={setNotes} value={notes} />
										</div>
									</div>
								</div>

								{isClose ? (
									<div className="row">
										<div className="col-md-12">
											<Flex direction="row" gap={8} space="mbl">
												<Label>
													{t('activity.followup')} {t('default.activity').toLowerCase()}{' '}
												</Label>
												<Checkbox onChange={setFollowUp} checked={followUp} size="sm" />
											</Flex>
										</div>
									</div>
								) : null}
								{followUp ? (
									<>
										<DateAndTime
											anchor={anchor}
											date={date}
											setDate={setDate}
											time={time}
											setTime={setTime}
										/>
										<div className="row">
											<div className="col-md-8">
												<div className={'form-group'}>
													<Label required>{t('default.activityType')}</Label>
													<UpActivityTypes
														required
														entity="activity"
														value={activityType}
														onChange={setActivityType}
													/>
												</div>
											</div>
										</div>
									</>
								) : null}
							</fieldset>
						</div>
					</div>
				</ModalContent>
				<ModalControls>
					<Tooltip title={requiredMissing.join(', ')} disabled={!disableSave}>
						<PrimaryButton onClick={onSave} disabled={disableSave || saving}>
							{submitBtn} {followUp ? andCreateFollowUp : ''}
						</PrimaryButton>
					</Tooltip>
					<Button type="link" onClick={() => abort()}>
						{t('default.abort')}
					</Button>
				</ModalControls>
			</>
		</Modal>
	);
};

export default MoveOrCloseActivity;
