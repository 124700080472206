import { CustomFieldWithValue } from 'App/resources/Model/CustomField';
import Project, { ProjectIdName } from 'App/resources/Model/Project';
import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import { makeCancelable } from 'App/babel/helpers/promise';
import BemClass from '@upsales/components/Utils/bemClass';
import { ModalProps } from 'App/components/Modals/Modals';
import { Self, Metadata } from 'App/resources/AllIWant';
import Category from 'App/resources/Model/Category';
import React, { useState, useEffect } from 'react';
import logError from 'App/babel/helpers/logError';
import T from 'Components/Helpers/translate';
import User from 'App/resources/Model/User';
import './ConfirmBuyProspect.scss';
import _ from 'lodash';
import { ModalContent, ModalControls, ModalHeader, Select, Title, Text, Icon, Link, Flex } from '@upsales/components';

export interface Props extends ModalProps<string | null> {
	campaigns: Project[];
	categories: Category[];
	onClose: (didBuy: string | null) => void;
	customFields: CustomFieldWithValue[];
	customerId: number;
	existing: number;
	filters: FilterConfig[];
	isProspecting: boolean;
	metadata: Metadata;
	self: Self;
	total: number;
	users: User[];
	className: string;
}

const ConfirmBuyProspect = (props: Props) => {
	const { customFields, customerId, existing, filters, metadata, self, total, users } = props;

	const classes = new BemClass('ConfirmBuyProspect', props.className);
	const anchor = '.Modals';
	const newAccounts = total - existing;
	const showCustomFields = customFields.length > 0;
	const credits = metadata.soliditetCredits;
	const multipleAccountManagers = metadata.params.teamAccountManager;
	const arrayProperties = ['Users', 'Projects', 'Categories'];
	const updateExisting = existing > 0 ? true : false;
	const [saving, setSaving] = useState(false);
	const [selectedUsers, setSelectedUsers] = useState<{ id: User['id']; title: User['name'] }[]>([]);
	const [selectedUser, setSelectedUser] = useState<{ id: User['id']; title: User['name'] } | null>(null);
	const [selectedCampaigns, setSelectedCampaigns] = useState<ProjectIdName[]>([]);
	const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
	const [displayFields, setDisplayFields] = useState(
		customFields.filter(field => field.obligatoryField && field.editable)
	);
	const [selectableFields, setSelectableFields] = useState(
		customFields.filter(field => !field.obligatoryField && field.editable)
	);
	const [requiredFieldsFilled, setRequiredFieldsFilled] = useState<{ [key: string]: boolean }>(
		displayFields.reduce((acc, field) => ({ ...acc, [field.id]: false }), {})
	);
	const [properties, setProperties] = useState<{
		[key: string]: any[];
	}>({
		User: [],
		Project: [],
		Categories: []
	});

	const cost = updateExisting ? total : newAccounts;

	const close = (didBuy: string | null) => {
		props.close(didBuy);
	};

	const selectMe = () => {
		const user = users.find(user => user.id === self.id);
		if (multipleAccountManagers) {
			if (user && !selectedUsers.some(selectedUser => selectedUser.id === user.id)) {
				setSelectedUsers(user ? [...selectedUsers, { id: user.id, title: user.name }] : selectedUsers);
			}
		} else {
			setSelectedUser(user ? { id: user.id, title: user.name } : null);
		}
	};

	const createCampaign = async () => {
		return Tools.$upModal
			.open('editCampaign', { customerId: customerId, noRedirect: true })
			.then(function (campaign) {
				if (campaign) {
					const updatedCampaigns = [...selectedCampaigns, campaign];
					setSelectedCampaigns(updatedCampaigns);
				}
			});
	};

	useEffect(() => {
		if (multipleAccountManagers) {
			setProperties({ ...properties, User: [] });
		}
	}, []);

	useEffect(() => {
		setProperties({
			...properties,
			User: multipleAccountManagers ? selectedUsers : selectedUser ? [selectedUser] : []
		});
	}, [selectedUsers, selectedUser]);

	useEffect(() => {
		setProperties({ ...properties, Project: selectedCampaigns.map(campaign => campaign.id) });
	}, [selectedCampaigns]);

	useEffect(() => {
		setProperties({ ...properties, Categories: selectedCategories.map(category => category.id) });
	}, [selectedCategories]);

	const handleSelectedUsersChange = (userSelected: { id: User['id']; title: User['name'] }) => {
		if (!selectedUsers.some(user => user.id === userSelected.id)) {
			if (multipleAccountManagers) {
				setSelectedUsers([...selectedUsers, userSelected]);
			} else {
				setSelectedUser(userSelected);
			}
		}
	};

	const handleSelectedFieldChange = (value: {
		id: CustomFieldWithValue['id'];
		title: CustomFieldWithValue['name'];
	}) => {
		const selectedField = selectableFields.find(field => field.id === value.id);
		if (selectedField) {
			setDisplayFields([...displayFields, selectedField]);
		}
		setSelectableFields(selectableFields.filter(field => field.id !== value.id));
	};

	const handleValueChange = (field: CustomFieldWithValue, value: string) => {
		if (requiredFieldsFilled.hasOwnProperty(field.id)) {
			setRequiredFieldsFilled(prevFields => ({
				...prevFields,
				[field.id]: !!value
			}));
		}

		setDisplayFields(
			displayFields.map(f => {
				if (f.id === field.id) {
					return { ...f, value: value };
				}
				return f;
			})
		);
	};

	// Function where we will return for the onClose event of the newer modals
	const done = () => {
		const options = {
			updateExisting: updateExisting,
			skipProjects: false,
			skipAccountManagers: false,
			skipAddresses: false,
			skipCategories: false,
			runActionsForExisting: !updateExisting && !!existing
		};

		let buildProperties: {
			User?: (User | number)[];
			AddUser: number[];
			Project: Project[];
			Categories: Category[];
		} = {
			User: [],
			AddUser: [],
			Project: [],
			Categories: []
		};

		buildProperties = _.pick(properties, function (value) {
			if ((Array.isArray(value) && value.length) || (!Array.isArray(value) && value)) {
				return true;
			}
			return false;
		});

		// Map out the account managers if any were selected
		if (buildProperties.User && buildProperties.User.length > 0) {
			if (multipleAccountManagers) {
				buildProperties.AddUser = buildProperties.User.map(user => {
					if (typeof user === 'number') {
						return user;
					} else {
						return user.id;
					}
				});
				delete buildProperties.User;
			} else {
				buildProperties.User = buildProperties.User.map(user => {
					if (typeof user === 'number') {
						return user;
					} else {
						return user.id;
					}
				});
			}
		}

		const finalProperties: { name: string; value: number[] | string }[] = [];

		setSaving(true);

		// Map Custom fields and other properties
		Tools.ActionProperties.mapCustomFields(finalProperties, [...selectableFields, ...displayFields], 'Client');
		Tools.ActionProperties.map(finalProperties, buildProperties as any, arrayProperties);

		const buyPreviewPromise = makeCancelable(
			Tools.SoliditetClient.customer(customerId).buyPreview(filters, finalProperties, options)
		);

		buyPreviewPromise.promise
			.then(function () {
				const metadata = Tools.AppService.getMetadata();
				metadata.soliditetCredits = Math.max(metadata.soliditetCredits - cost, 0);
				Tools.AppService.setMetadata(metadata);

				setSaving(false);
				close('true');
			})
			.catch(error => {
				logError(error, 'confirmBuyProspect - soliditet');
				setSaving(false);
			});
		return () => {
			if (buyPreviewPromise) {
				buyPreviewPromise.cancel();
			}
		};
	};

	return (
		<form
			autoComplete="off"
			onSubmit={e => {
				e.preventDefault();
				done();
			}}
		>
			<ModalContent className={classes.elem('up-modal-content').b()}>
				<ModalHeader className={classes.elem('up-modal-header').b()} onClose={() => close(null)} />
				{showCustomFields && (
					<div className="section-white-bg">
						<Title className="pbl" size="xl">
							{T('editBisnodeAccount.requiredFields')}
						</Title>
						<div className="row">
							{displayFields.map(field => (
								<div className="col-md-6" key={field.id}>
									<div className="form-group">
										<Text bold={true} size="sm">
											{field.name}
											<Text
												size="xl"
												color="red"
												bold={true}
												className={classes.elem('required-icon').b()}
												style={{ display: field.obligatoryField ? 'inline' : 'none' }}
											>
												&bull;
											</Text>
										</Text>
										<ReactTemplates.INPUTS.customFieldInput
											field={field}
											name={field.name}
											onChange={(value: string) => handleValueChange(field, value)}
											usenewdate
											useNewTime
										/>
									</div>
								</div>
							))}
							{selectableFields.length > 0 && (
								<div className="col-md-6">
									<div style={{ marginTop: '15px' }} className="form-group">
										<div className="input-group">
											<span className="input-group-addon">
												<Icon name="plus" />
											</span>
											<Select
												data-testid="Input_field"
												anchor={anchor}
												options={selectableFields.map(field => ({
													id: field.id,
													title: field.name
												}))}
												value={null}
												onChange={handleSelectedFieldChange}
												placeholder={T('soliditet.addField')}
											/>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				)}
				<div className="section">
					<Flex gap="u4" flex={1}>
						<Flex direction="column" flex={0.5}>
							<Text size="lg">
								{T('default.accountManager')}
								<Link className={classes.elem('select-me').b()} nostyle={true} onClick={selectMe}>
									{T('default.select')} {T('default.me').toLowerCase()}
								</Link>
							</Text>

							<div className="form-group">
								<div className="input-group">
									<span className="input-group-addon">
										<Icon name="user-plus" />
									</span>
									{multipleAccountManagers ? (
										<Select
											data-testid="Input_user"
											multi
											anchor={anchor}
											options={users.map(user => ({ id: user.id, title: user.name }))}
											value={selectedUsers || []}
											onChange={handleSelectedUsersChange}
											onRemove={id =>
												setSelectedUsers(selectedUsers.filter(user => user.id !== id))
											}
											placeholder={T('default.select') + ' ' + T('default.user').toLowerCase()}
										/>
									) : (
										<Select
											data-testid="Input_user"
											anchor={anchor}
											options={users.map(user => ({ id: user.id, title: user.name }))}
											value={selectedUser}
											onChange={handleSelectedUsersChange}
											onClear={() => setSelectedUser(null)}
											placeholder={T('default.select') + ' ' + T('default.user').toLowerCase()}
										/>
									)}
								</div>
							</div>
						</Flex>
					</Flex>
				</div>

				<div className="section">
					<Text size="lg">{T('soliditet.addToCampaignCategory')}</Text>

					<Text className={classes.elem('add-to-campaign-category').b()} size="sm" color="grey-11">
						{T('soliditet.addToCampaignCategoryInfo')}
					</Text>

					<Flex gap="u4" flex={1}>
						<Flex direction="column" flex={0.5}>
							<div className="form-group">
								<Text bold={true} size="sm">
									{T('default.categories')}
								</Text>
								<ReactTemplates.INPUTS.upCategories
									multiple={true}
									type="account"
									value={selectedCategories || []}
									onChange={category => {
										setSelectedCategories(category); //upCategories returns the selected array
									}}
									placeholder={T('category.chooseCategory')}
								/>
							</div>
						</Flex>
						<Flex direction="column" flex={0.5}>
							<div className="form-group">
								<Text bold={true} size="sm">
									{T('default.campaigns')}
									<Link
										nostyle={true}
										className={classes.elem('create-campaign').b()}
										onClick={createCampaign}
									>
										{T('default.create')} {T('default.new').toLowerCase()}
									</Link>
								</Text>
								<ReactTemplates.INPUTS.upCampaigns
									multiple={true}
									value={selectedCampaigns || []}
									onChange={campaign => {
										setSelectedCampaigns(campaign); //upCampaigns returns the selected array
									}}
									placeholder={T('campaign.chooseCampaign')}
								/>
							</div>
						</Flex>
					</Flex>
				</div>
			</ModalContent>
			<ModalControls className={classes.elem('up-modal-controls').b()}>
				{credits < 1 && (
					<Text color="red" size="sm">
						{T('soliditet.notEnoughCreditsMulti')}
					</Text>
				)}
				<button
					type="submit"
					className="button btn btn-block up-btn btn-green no-shadow"
					disabled={
						credits < 1 || saving || Object.values(requiredFieldsFilled).some(value => value === false)
					}
				>
					{T('default.addAccount')}
					{saving && <span className="fa fa-refresh fa-spin"></span>}
				</button>
				<button type="button" className="btn btn-block up-btn btn-link btn-grey" onClick={() => close(null)}>
					{T('default.abort')}
				</button>
			</ModalControls>
		</form>
	);
};

export default ConfirmBuyProspect;
