import RequestBuilder from 'Resources/RequestBuilder';
import type { MultiSelect } from 'App/components/ListView/ListViewRenderHelpers';
import { openMultiCloseActivity } from 'App/components/MultiActionModal/MultiCloseActivity/MultiCloseActivity';

export default function closeActivities(rb: RequestBuilder, entity: string, multiSelect: MultiSelect) {
	const opts = {
		customerId: Tools.AppService.getCustomerId(),
		filters: rb,
		entity,
		multiSelect
	};
	return openMultiCloseActivity(opts);
}
