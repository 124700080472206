import { Checkbox, Flag, Flex, Icon, Link, Text } from '@upsales/components';
import { currencyFormat } from 'App/babel/components/Filters/Currencies';
import { ExpandedTableValues } from './DuplicatesExpandedTable';
import { formatOrgNumber, getCompanyAddress } from '../Helpers/Helpers';
import { useSelector } from 'react-redux';
import { useTranslation } from 'Components/Helpers/translate';
import bemClass from '@upsales/components/Utils/bemClass';
import Client from 'App/resources/Model/Client';
import Contact from 'App/resources/Model/Contact';
import React from 'react';
import Tooltip, { EllipsisTooltip } from '@upsales/components/Tooltip';
import type { ClientAddress } from 'App/resources/Model/Client';
import type { RootState } from 'Store/index';
import UserStack from 'App/components/UserStack';

import './DuplicatesCard.scss';
import { CustomFieldIdAndType } from '../HandleDuplicates';

type Props = {
	object: Partial<Client> | Partial<Contact>;
	entity: string;
	id: number;
	handleCardSelection: (id: number) => void;
	isChecked: boolean;
	isExpanded: boolean;
	shouldShowMoreRows?: boolean;
	customFieldIdAndTypes: CustomFieldIdAndType[];
};

function DuplicatesCard({
	object,
	entity,
	id,
	handleCardSelection,
	isChecked,
	isExpanded,
	shouldShowMoreRows,
	customFieldIdAndTypes
}: Props) {
	const { t } = useTranslation();
	const { customerId } = useSelector(({ App }: RootState) => ({
		customerId: App.customerId
	}));
	const classes = new bemClass('DuplicatesCard');
	const clientData = entity === 'accounts' ? (object as Partial<Client>) : undefined;
	const contactData = entity === 'contacts' ? (object as Partial<Contact>) : undefined;

	const contactEntity = entity === 'contacts';
	const accountEntity = entity === 'accounts';

	const handleCheckboxClick = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		handleCardSelection(id);
	};

	const companyAddress = (addresses: ClientAddress[]) => {
		const address = getCompanyAddress(addresses);
		return (
			<Flex alignItems="center">
				<Flex alignItems="center">
					{address?.country ? <Flag code={address.country.toLowerCase()} /> : null}
					{address?.city ? (
						<Text size="sm" color="grey-11" space="mls">
							{address.city}
						</Text>
					) : null}
				</Flex>
			</Flex>
		);
	};

	const clientHasAddress = !!(clientData?.addresses?.length ?? 0 > 0);

	return (
		<Flex direction="column" className={classes.mod({ greyBackground: !isChecked }).b()}>
			<Flex
				alignItems="center"
				justifyContent="space-between"
				className={classes.elem('container').mod({ decreaseWidth: shouldShowMoreRows }).b()}
				onClick={e => handleCheckboxClick(e)}
			>
				<Flex
					space="pbl ptl"
					alignItems="center"
					justifyContent="space-between"
					className={classes.elem('visibleCard').b()}
				>
					<Tooltip
						title={
							contactEntity
								? t('detectedDuplicates.keepDuplicateContact')
								: t('detectedDuplicates.keepDuplicateClient')
						}
					>
						<Checkbox data-testid={`checkbox-${id}`} size="sm" space="mrl mll" checked={isChecked} />
					</Tooltip>
					<Flex direction="column" gap="u1" className={classes.elem('subtitleColumns').b()}>
						<Link
							href={`#/${customerId}/${entity}/${object.id}/`}
							target="_blank"
							onClick={e => e.stopPropagation()}
						>
							{contactEntity ? contactData?.name : null}
							{accountEntity ? clientData?.name : null}
						</Link>
						<Flex gap="u2" alignItems="center">
							{accountEntity && clientHasAddress ? (
								companyAddress(clientData?.addresses ?? [])
							) : contactEntity && contactData?.email ? (
								<EllipsisTooltip title={contactData.email}>
									<Text size="sm" color="grey-11">
										{contactData.email}
									</Text>
								</EllipsisTooltip>
							) : null}

							{accountEntity && clientData?.noEmployees ? (
								<>
									<Icon name="circle" color="grey-11" className={classes.elem('circle').b()} />
									<Flex>
										<Icon name="users" color="grey-11" space="mrs" />
										<Text size="sm" color="grey-11">
											{clientData?.noEmployees}
										</Text>
									</Flex>
								</>
							) : null}
							{accountEntity && clientData?.turnover && clientData?.currency ? (
								<>
									<Icon name="circle" color="grey-11" className={classes.elem('circle').b()} />
									<Flex>
										<Icon name="bar-chart" color="grey-11" space="mrs" />
										<Text size="sm" color="grey-11">
											{currencyFormat(clientData.turnover, clientData.currency)}
										</Text>
									</Flex>
								</>
							) : null}
						</Flex>
						{accountEntity && clientData?.orgNo ? (
							<Text size="sm" color="grey-11">
								{t('default.orgNo')}: {formatOrgNumber(clientData.orgNo)}
							</Text>
						) : null}
						{contactEntity && contactData?.client ? (
							<EllipsisTooltip title={contactData.client?.name}>
								<Text size="sm" color="grey-11">
									{contactData.client?.name}
								</Text>
							</EllipsisTooltip>
						) : null}
					</Flex>
				</Flex>
				{accountEntity && !shouldShowMoreRows && clientData?.users?.length ? (
					<Flex space="mrl" direction="column" gap="u1">
						<Flex justifyContent="flex-end">
							<UserStack users={clientData?.users} />
						</Flex>
						<Text size="sm" color="grey-11">
							{t('default.accountManager')}
						</Text>
					</Flex>
				) : null}
			</Flex>
			<ExpandedTableValues
				data={clientData ?? contactData}
				entity={entity}
				isExpanded={isExpanded}
				duplicateIsNotSelected={!isChecked}
				shouldShowMoreRows={shouldShowMoreRows}
				customFieldIdAndTypes={customFieldIdAndTypes}
			/>
		</Flex>
	);
}

export default DuplicatesCard;
