import { getJourneyStep, LEAD } from 'Components/Helpers/journeyStep';
import { phoneNumberHelper } from '@upsales/common';
import { SlideFade } from '@upsales/components/animations';
import { Table, TableColumn, TableRow, Text, Block, EllipsisTooltip } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import Client from 'App/resources/Model/Client';
import Contact from 'App/resources/Model/Contact';
import moment from 'moment';
import React from 'react';
import SalesHistory from 'App/components/SalesHistory';
import type { DetectedDuplicateType } from 'App/resources/Model/DetectedDuplicates';
import UserStack from 'App/components/UserStack';
import { MAX_CUSTOM_FIELDS_BEFORE_SCROLL } from '../Helpers/Helpers';
import { getCorrectCustomFieldValue } from 'App/components/CustomFieldValueText/CustomFieldValueText';
import { CustomFieldIdAndType } from '../HandleDuplicates';
import BemClass from '@upsales/components/Utils/bemClass';

type Props = {
	data?: Partial<Client> | Partial<Contact>;
	entity: string;
	isExpanded?: boolean;
	duplicateIsNotSelected: boolean;
	shouldShowMoreRows?: boolean;
	customFieldIdAndTypes: CustomFieldIdAndType[];
};

const RenderTable = ({
	visible,
	tableData,
	greyTextColor = true,
	className = '',
	customFieldsAmount = 0
}: {
	visible: boolean | undefined;
	tableData: { title: any }[];
	greyTextColor?: boolean;
	className?: string;
	customFieldsAmount?: number;
}) => {
	const lengthWithoutCustomFields = tableData.length - customFieldsAmount;

	return (
		<SlideFade
			visible={visible}
			height
			maxHeight={Math.min(tableData.length, lengthWithoutCustomFields + MAX_CUSTOM_FIELDS_BEFORE_SCROLL) * 40}
			bounce={false}
		>
			<Block className={className}>
				<Table>
					{tableData.map((row, index) => (
						<TableRow key={index}>
							<TableColumn>
								<EllipsisTooltip title={typeof row.title === 'string' ? row.title : ''}>
									<Text size="sm" color={greyTextColor ? 'grey-10' : 'black'}>
										{row.title}
									</Text>
								</EllipsisTooltip>
							</TableColumn>
						</TableRow>
					))}
				</Table>
			</Block>
		</SlideFade>
	);
};

export const ExpandedTableTitles = ({
	shouldShowMoreRows,
	entity,
	item,
	expandedCards,
	customFieldNames
}: {
	shouldShowMoreRows: boolean;
	entity: string;
	item: DetectedDuplicateType;
	expandedCards: { [key: string]: boolean };
	customFieldNames: string[];
}) => {
	const classes = new BemClass('ExpandedTableTitles');
	const { t } = useTranslation();
	const lang = {
		accountManager: t('default.accountManager'),
		created: t('default.regDate'),
		createdBy: t('admin.documentTemplate.createdBy'),
		history: t('default.history'),
		journeyStep: t('default.journeyStep'),
		phone: t('default.phone'),
		title: t('default.title')
	};

	const tableData = [
		{ title: lang.created },
		{ title: lang.createdBy },
		{ title: lang.journeyStep },
		{ title: lang.history }
	];

	if (entity === 'contacts') {
		tableData.splice(0, 0, { title: lang.title });
		tableData.splice(tableData.length - 1, 0, {
			title: lang.phone
		});
	} else if (entity === 'accounts' && shouldShowMoreRows) {
		tableData.splice(0, 0, { title: lang.accountManager });
	}

	if (customFieldNames.length) {
		tableData.push(...customFieldNames.map(name => ({ title: name })));
	}

	return (
		<RenderTable
			visible={item.duplicates.some(duplicate => expandedCards[duplicate?.id])}
			tableData={tableData}
			className={classes.b()}
			customFieldsAmount={customFieldNames.length}
		/>
	);
};

export const ExpandedTableValues = ({
	data,
	entity,
	isExpanded,
	duplicateIsNotSelected,
	shouldShowMoreRows,
	customFieldIdAndTypes
}: Props) => {
	const classes = new BemClass('ExpandedTableValues');

	const entityName = entity === 'accounts' ? 'client' : 'contact';

	const tableData = [
		{ title: moment(data?.regDate).format('L') },
		{ title: data?.regBy?.name || '-' },
		{ title: getJourneyStep(data?.journeyStep || LEAD)?.name },
		{ title: data ? <SalesHistory entity={data} entityName={entityName} /> : '-' }
	];

	if (entity === 'contacts') {
		tableData.splice(0, 0, { title: (data as Partial<Contact>)?.title || '-' });
		tableData.splice(tableData.length - 1, 0, {
			title: phoneNumberHelper.formatNumber((data?.phone || '').toString() || '-')
		});
	} else if (entity === 'accounts' && shouldShowMoreRows) {
		tableData.splice(0, 0, {
			title: (data as Partial<Client>)?.users?.length ? (
				<UserStack
					users={(data as Partial<Client>).users?.map(({ name, id, email }) => ({ name, id, email })) || []}
				/>
			) : (
				'-'
			)
		});
	}

	customFieldIdAndTypes.forEach(([id, type]) => {
		const fieldMatch = data?.custom?.find(({ fieldId }) => fieldId === id);
		if (!fieldMatch) {
			tableData.push({ title: '-' });
			return;
		}
		tableData.push({ title: getCorrectCustomFieldValue(fieldMatch.value, type) ?? '-' });
	});

	return (
		<RenderTable
			visible={isExpanded}
			tableData={tableData}
			greyTextColor={duplicateIsNotSelected}
			customFieldsAmount={customFieldIdAndTypes.length}
			className={classes.mod({ decreasedWith: shouldShowMoreRows }).b()}
		/>
	);
};
