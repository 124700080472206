import { Modal, ModalContent, ModalControls, ModalHeader } from '@upsales/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'Components/Helpers/translate';
import RequestBuilder from 'Resources/RequestBuilder';
import ComparisonTypes from 'Resources/ComparisonTypes';
import Client from 'App/resources/Model/Client';
import logError from 'Helpers/logError';
import { makeCancelable } from 'App/babel/helpers/promise';
import './UpdateClientDuns.scss';
import { ModalProps } from '../Modals/Modals';

type Props = ModalProps & {
	name: string;
	dunsNo: number;
};

const UpdateClientDuns = (props: Props) => {
	const { t } = useTranslation();
	const { className, close, name, dunsNo } = props;
	const [buying, setBuying] = React.useState(false);
	const [credits, setCredits] = React.useState(0);
	const [selectedClient, setSelectedClient] = React.useState<Client | null>(null);
	const [select2Client, setSelect2Client] = React.useState<Client | null>(null);
	const [duns, setDuns] = React.useState<Client & { isHeadqueraters?: boolean; city?: string }>();
	const [accounts, setAccounts] = React.useState<(Client & { isHeadqueraters?: boolean; city?: string })[]>([]);
	const properties: any = [];

	const updateFail = () => {
		setBuying(false);
	};

	const confirm = () => {
		const options = {
			updateExisting: true,
			skipProjects: true,
			skipAccountManagers: true,
			skipAddresses: false,
			skipCategories: true
		};

		setBuying(true);
		const customerId = Tools.AppService.getCustomerId();

		if (selectedClient) {
			Tools.Account.customer(customerId)
				.save({ id: selectedClient.id, dunsNo: dunsNo }, { skipNotification: true })
				.then(() => {
					Tools.SoliditetClient.customer(customerId)
						.refresh(selectedClient?.id, properties, options)
						.then(() => {
							close();
						})
						.catch(updateFail);
				})
				.catch(updateFail);
		}
	};

	const selectClient = (e: Client) => {
		setSelectedClient(e);
	};

	const listSelectClient = (account: Client) => {
		setSelect2Client(null);
		setSelectedClient(account);
	};

	useEffect(() => {
		const { soliditetCredits } = Tools.AppService.getMetadata();
		setCredits(soliditetCredits);
		const nameFilterRb = new RequestBuilder();
		nameFilterRb.addFilter(Tools.Account.attr.name, ComparisonTypes.Search, name);
		nameFilterRb.addSort('_score', false);
		nameFilterRb.limit = 5;

		const dunsFilterRb = new RequestBuilder();
		dunsFilterRb.addFilter(Tools.Account.attr.dunsNo, ComparisonTypes.Equals, dunsNo);
		dunsFilterRb.limit = 1;

		const customerId = Tools.AppService.getCustomerId();

		const { promise, cancel } = makeCancelable(
			Promise.all([
				Tools.Account.customer(customerId).find(nameFilterRb.build()),
				Tools.Account.customer(customerId).find(dunsFilterRb.build())
			])
		);
		promise
			.then(([nameRes, dunsRes]) => {
				if (dunsRes.data.length) {
					if (nameRes.data.length) {
						setAccounts(nameRes.data.filter((acc: Client) => acc.dunsNo !== dunsRes.data[0].dunsNo));
					}
					setDuns(dunsRes.data[0]);
				} else {
					setAccounts(nameRes.data);
				}
			})
			.catch(err => {
				logError(err);
			});
		return cancel;
	}, []);

	return (
		<Modal size="sm" className={className}>
			<ModalHeader title={t('soliditet.connectWithUpsalesClient')} onClose={() => close()}></ModalHeader>
			<ModalContent className="UpdateClientDuns">
				<div className="up-panel">
					{credits ? (
						<>
							<div>
								<b>{name}</b> {t('soliditet.updateDunsNoInfo')}.
							</div>

							{!accounts.length ? <p>{t('soliditet.noMatchesInUpsales')}</p> : null}
							{duns ? (
								<h2
									style={{
										fontSize: '17px',
										marginBottom: '10px',
										marginTop: '10px',
										fontWeight: 'bold'
									}}
								>
									{t('soliditet.clientsInUpsalesDunsNo')}
								</h2>
							) : null}

							{duns ? (
								<div className="list-row">
									<button
										type="button"
										className="pull-right btn up-btn btn-sm btn-bright-blue"
										disabled={buying}
										onClick={() => listSelectClient(duns)}
										style={{ marginTop: '5px' }}
									>
										{buying ? (
											<>
												<b className={`fa fa-refresh ${buying ? 'fa-spin' : ''}`}></b>
												<>{t('default.updating')}</>
											</>
										) : (
											<>{t('default.choose')}</>
										)}
									</button>

									<span
										style={{
											display: 'inline-block',
											width: '268px',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
											overflow: 'hidden'
										}}
									>
										{duns.name}
									</span>

									<br />
									<span
										style={{
											display: 'inline-block',
											color: '#999',
											textTransform: 'uppercase',
											fontSize: '12px',
											letterSpacing: '0.5px'
										}}
									>
										{duns.city}
									</span>
									{duns.isHeadqueraters ? (
										<>
											<b className="fa fa-home text-bright-blue">{t('soliditet.headquarters')}</b>
										</>
									) : (
										<div className="clearfix"></div>
									)}
								</div>
							) : null}

							{accounts.length ? (
								<h2
									style={{
										fontSize: '17px',
										marginBottom: '10px',
										marginTop: '10px',
										fontWeight: 'bold'
									}}
								>
									{t('soliditet.clientsInUpsalesName')}
								</h2>
							) : null}

							{accounts.length
								? accounts.map(account => (
										<div className="list-row" key={account.id}>
											<button
												type="button"
												className="pull-right btn up-btn btn-sm btn-bright-blue"
												disabled={buying}
												onClick={() => listSelectClient(account)}
												style={{ marginTop: '5px' }}
											>
												{buying && selectedClient?.id === account.id ? (
													<>
														<b className={`fa fa-refresh ${buying ? 'fa-spin' : ''}`}></b>
														<>{t('default.updating')}</>
													</>
												) : (
													<>{t('default.choose')}</>
												)}
											</button>

											<span
												style={{
													display: 'inline-block',
													width: '268px',
													textOverflow: 'ellipsis',
													whiteSpace: 'nowrap',
													overflow: 'hidden'
												}}
											>
												{account.name}
											</span>

											<br />
											<span
												style={{
													display: 'inline-block',
													color: '#999',
													textTransform: 'uppercase',
													fontSize: '12px',
													letterSpacing: '0.5px'
												}}
											>
												{account.city}
											</span>
											{account.isHeadqueraters ? (
												<>
													<b className="fa fa-home text-bright-blue">
														{t('soliditet.headquarters')}
													</b>
												</>
											) : (
												<div className="clearfix"></div>
											)}
										</div>
								  ))
								: null}

							<div style={{ marginTop: '20px' }}>
								<h2
									style={{
										fontSize: '17px',
										marginBottom: '10px',
										marginTop: '10px',
										fontWeight: 'bold'
									}}
								>
									{t('client.search')}
								</h2>
								<ReactTemplates.INPUTS.upAccounts
									value={select2Client?.name || ''}
									onChange={selectClient}
									placeholder={t('agreement.moveSubscriptionAccountSelectPlaceholder')}
								/>
							</div>

							{selectedClient ? (
								<div style={{ marginTop: '10px' }}>
									<h2
										style={{
											fontSize: '17px',
											marginBottom: '10px',
											marginTop: '10px',
											fontWeight: 'bold'
										}}
									>
										{t('company.selected')}
									</h2>
									<div>{selectedClient.name}</div>
									<p style={{ marginTop: '20px' }}>
										{`${t('soliditet.youHave')} `}
										<b className={credits ? 'text-green' : ''}>{credits}</b>
										{` ${t('soliditet.availableCompanyPurchases').toLowerCase()}.`}
									</p>
								</div>
							) : (
								<p style={{ marginTop: '20px' }}>
									{`${t('soliditet.youHave')} `}
									<b className={credits ? 'text-green' : ''}>{credits}</b>
									{` ${t('soliditet.availableCompanyPurchases').toLowerCase()}.`}
								</p>
							)}
						</>
					) : (
						<p style={{ marginTop: '20px' }}>
							{`${t('soliditet.youHave')} `}
							<b className={credits ? 'text-green' : ''}>{credits}</b>
							{` ${t('soliditet.availableCompanyPurchases').toLowerCase()}.`}
						</p>
					)}
				</div>
			</ModalContent>
			<ModalControls style={{ textAlign: 'right' }}>
				<button
					type="submit"
					className="btn up-btn btn-green"
					autoFocus
					onClick={() => confirm()}
					disabled={!credits || buying || !selectedClient}
				>
					{buying ? (
						<>
							<span className={`fa fa-refresh ${buying ? 'fa-spin' : ''}`}></span>
							<p>{t('default.updating')}</p>
						</>
					) : (
						<p>{t('default.update')}</p>
					)}
				</button>

				<button type="button" className="btn up-btn btn-bright-blue btn-link" onClick={() => close()}>
					{t('default.abort')}
				</button>
			</ModalControls>
		</Modal>
	);
};

export default UpdateClientDuns;
