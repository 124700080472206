import { RESET, DEACTIVATE, ADDED_SEATS } from '../reducers/MaxCeilingReachedOnSeats';

export const reset = () => ({ type: RESET });

export const nextPage = id => async dispatch => {
	if (id === 'deactivate') {
		dispatch({ type: DEACTIVATE });
	}
	if (id === 'added_seats') {
		dispatch({ type: ADDED_SEATS });
	}
};
