import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Text, Icon, Button } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import './UserDeactivated.scss';
import T from 'Components/Helpers/translate';

export class UserDeactivated extends Component {
	static propTypes = {
		closeModal: PropTypes.func.isRequired
	};
	constructor(props) {
		super(props);
	}

	render() {
		const classes = new bemClass('UserDeactivated');

		return (
			<div className={classes.elem('content').b()}>
				<Icon name="check" color="green" />
				<Text bold size="xl">
					{T('admin.deactivateUser.deactivateUser')}
				</Text>
				<Button size="xl" onClick={() => this.props.closeModal()}>
					{T('admin.deactivateUser.startUpsales')}
				</Button>
			</div>
		);
	}
}

const mapStateToProps = ({ MaxCeilingReachedOnSeats }) => ({
	userDeactivated: MaxCeilingReachedOnSeats.userDeactivated
});

export default connect(mapStateToProps)(UserDeactivated);
