import ResourceTyped from 'Resources/ResourceTyped';
import CustomerPortfolioCompany from './Model/CustomerPortfolioCompany';

class CustomerPortfolioCompanyResource extends ResourceTyped<CustomerPortfolioCompany> {
	constructor() {
		super('customerPortfolioCompanies');
	}
}

const resource = new CustomerPortfolioCompanyResource();

export default resource;
