import ResourceTyped from 'Resources/ResourceTyped';
import type Form from 'App/resources/Model/Form';
import { TYPE, STYLE } from 'Store/reducers/SystemNotificationReducer';
import parseForm from './parsers/form';

class FormResource extends ResourceTyped<Form> {
	constructor() {
		super('forms', null, { notificationTitle: 'form.form' });

		const archiveError = this.notifications.saveError;
		this.notifications = {
			...this.notifications,

			//@ts-ignore Remove this when Resource is ported to Typescript...
			archive: ({ data: form }: { data: Form }) => {
				const body = form.isArchived ? 'form.formArchived' : 'form.formUnarchived';
				return {
					title: 'form.form',
					body,
					style: STYLE.SUCCESS,
					icon: 'save',
					type: TYPE.BODY
				};
			},
			archiveError
		};

		this.parse = parseForm;
	}

	archive(id: number, isArchived: boolean) {
		return this._putRequest(id, { id, isArchived }, { methodName: 'archive' }).then(res => res.data);
	}
}

const resource = new FormResource();

export default resource;
