import React from 'react';
import { DropDownButton, DropDownMenu, Table, TableColumn, TableRow } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import './PlanNextStepDropDown.scss';
import { openDrawer } from 'Services/Drawer';
import T from 'Components/Helpers/translate';
import Client from 'App/resources/Model/Client';

const createActions = [
	{
		icon: 'phone',
		title: 'default.phonecall',
		onClick: (close: () => void, client: Pick<Client, 'id' | 'name' | 'createRights'>) => {
			close();
			openDrawer('CreateCall', { client });
		}
	}
];

type Props = {
	client: Pick<Client, 'id' | 'name' | 'createRights'>;
};

const PlanNextStepDropDown = ({ client }: Props) => {
	const classes = new bemClass('PlanNextStepDropDown');
	return (
		<div className={classes.b()}>
			<DropDownMenu
				anchor={document.getElementsByClassName('ReportcenterDrilldownDrawer')[0]}
				renderTrigger={(expanded, setExpanded) => (
					<DropDownButton
						className={'DropDownButton--inherit'}
						title={T('opportunity.noNextStepPlanned')}
						onClick={(e: Parameters<typeof setExpanded>[0]) => {
							e.stopPropagation();
							setExpanded(e);
						}}
						size={'sm'}
						expanded={expanded}
						color={'green'}
					/>
				)}
			>
				{close => (
					<Table>
						{createActions.map(item => (
							<TableRow key={item.icon} onClick={item.onClick.bind(null, close, client)}>
								<TableColumn size="lg" icon={item.icon} title={T(item.title)} />
							</TableRow>
						))}
					</Table>
				)}
			</DropDownMenu>
		</div>
	);
};

export default PlanNextStepDropDown;
