import Resource from './Resource';

class document extends Resource {
	constructor() {
		super('document');
	}

	getUrl = function (entityId: number, templateId: number, fileFormat: string, type: string, contactId: number) {
		if (!type) {
			throw new Error('No type set for document resource');
		}

		const customer = Tools.AppService.getCustomerId();
		const contactParam = contactId ? '&contactId=' + contactId : '';

		return (
			'/api/' +
				'v2/' +
				customer +
				'/' +
				type +
				'/' +
				entityId +
				'/documents/' +
				templateId +
				'?fileFormat=' +
				fileFormat || 'pdf' + contactParam
		);
	};

	getDocument(entityId: number, templateId: number, type: string, contactId: number) {
		// Change url to customerId because we can't access it in the constructor,
		// due to the timing of when the constructor is called vs when we are "logged in"
		this._url = Tools.AppService.getCustomerId().toString();

		return this._getRequest(`${type}/${entityId}/documents/${templateId}`, {
			customerId: contactId,
			entity: type,
			entityId: entityId,
			templateId: templateId
		});
	}
}

const resource = new document();

export default resource;
