import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ModalContent, ModalControls, Text, Column, ModalHeader, Button, Input, Label, Row } from '@upsales/components';
import T from '../Helpers/translate';
import { removeHtml } from '@upsales/common';
import { asyncModalAdapter, setupComponentCompatibility } from 'App/helpers/angularPortingHelpers';

export default function ConfirmLandingPage(props) {
	const initialState = {
		name: '',
		internalName: localStorage.getItem('forms.internalName') || ''
	};
	const maxCharactersName = 255;
	const maxCharactersInternalName = 40;
	const [state, setState] = useState(initialState);
	const isDisabled = validate(state);

	if (state.internalName.length > maxCharactersInternalName || state.name.length > maxCharactersName) {
		props.reloadModalPosition(10);
	}

	return (
		<div id="confirm-landingpage" className="form">
			<ModalHeader title={T('landingpage.savePage')} onClose={() => props.reject()} color="bright-blue" />
			<ModalContent>
				<Row direction="column" style={{ marginBottom: '20px' }}>
					<Column>
						<Label>{T('landingpage.pageName')}</Label>
					</Column>
					<Column>
						<Input
							state={state.name.length > maxCharactersName ? 'error' : null}
							value={state.name}
							onChange={event => onChange(event, 'name')}
						/>
						{state.name.length > maxCharactersName ? (
							<Text size="sm" color="dark-red">
								{T('landingpage.error.internalName.tooLong', {
									size: maxCharactersName,
									field: T('landingpage.pageName')
								})}
							</Text>
						) : null}
					</Column>
				</Row>
				<Row direction="column">
					<Column>
						<Label>{T('landingpage.pageNameInternal')}</Label>
					</Column>
					<Column>
						<Input
							state={state.internalName.length > maxCharactersInternalName ? 'error' : null}
							value={state.internalName}
							onChange={event => onChange(event, 'internalName')}
						/>
						{state.internalName.length > maxCharactersInternalName ? (
							<Text size="sm" color="dark-red">
								{T('landingpage.error.internalName.tooLong', {
									size: maxCharactersInternalName,
									field: T('landingpage.pageNameInternal')
								})}
							</Text>
						) : null}
					</Column>
				</Row>
			</ModalContent>
			<ModalControls>
				<Button shadow="none" onClick={onConfirm} disabled={isDisabled}>
					{T('default.save')}
				</Button>
				<Button onClick={() => props.reject()} shadow="none" type="link" color="grey">
					{T('default.abort')}
				</Button>
			</ModalControls>
		</div>
	);

	function onChange(event, key) {
		const newState = { ...state };
		let value = event.target.value;
		if (key === 'name') {
			value = removeHtml(value);
		}
		newState[key] = value;

		setState(newState);
	}

	function validate(state) {
		if (state.internalName.length && state.name.length) {
			if (state.internalName.length < maxCharactersInternalName) {
				return false;
			}

			return true;
		}

		return true;
	}

	function onConfirm() {
		props.resolve(state);
	}
}

ConfirmLandingPage.propTypes = {
	reject: PropTypes.func,
	resolve: PropTypes.func,
	reloadModalPosition: PropTypes.func
};

window.ConfirmLandingPage = ConfirmLandingPage;

export const ConfirmLandingPageModal = setupComponentCompatibility(ConfirmLandingPage, {
	modalName: 'CreateActivityModal',
	modalSize: 'sm',
	modalParamsMapper: $modalParams => $modalParams
});

export const openConfirmLandingPageModal = asyncModalAdapter({
	upModalName: 'confirmLandingpage',
	openModalName: 'ConfirmLandingPageModal',
	featureFlag: 'REACT_CONFIRM_LANDING_PAGE'
});
