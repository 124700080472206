import { makeCancelable } from 'Helpers/promise';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import CreditRiskResource from 'App/resources/CreditRisks';
import CustomerPortfolioCompany from 'App/resources/Model/CustomerPortfolioCompany';
import moment from 'moment';

export type Severity = 'medium' | 'high';

export const fetchCreditRisks = (orgNumber: number[]) => {
	const rb = new RequestBuilder();
	rb.addSort('severity', true);
	rb.addSort('date', false);
	rb.addFilter({ field: 'orgNumber' }, comparisonTypes.Equals, orgNumber);
	rb.addFilter({ field: 'date' }, comparisonTypes.GreaterThan, moment().subtract(3, 'months').format('YYYY-MM-DD'));
	return makeCancelable(CreditRiskResource.find(rb.build()));
};

export function getOrgNumbersFromProspectingId(clients: CustomerPortfolioCompany[]) {
	return clients.map(company => (company.prospectingId ? parseInt(company.prospectingId.split('.').pop()!) : 0));
}

export const getRiskTooltipTitle = (severity: Severity) => {
	switch (severity) {
		case 'medium':
			return 'default.risk';
		case 'high':
			return 'default.warning';
	}
};
