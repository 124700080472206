import React, { useState } from 'react';
import { Card, Drawer, DrawerHeader, Flex, Text } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import { ModalProps } from 'App/components/Modals/Modals';
import Client from 'App/resources/Model/Client';
import AddParentCompany from './AddParentCompany/AddParentCompany';
import AddRelation from './AddRelation/AddRelation';
import AddSubaccount from './AddSubaccount/AddSubaccount';
import { useSoftDeployAccess } from 'App/components/hooks';

import './CreateRelation.scss';

export enum CreateRelationView {
	Default,
	Relation,
	Subaccount,
	ParentCompany
}

type Props = {
	client: Client;
	defaultView?: CreateRelationView;
} & ModalProps;

export type DrawerClient = Pick<Client, 'id' | 'name'> & { hasAccess: boolean };

const CreateRelation = ({ className, close, client, modalId, defaultView = CreateRelationView.Default }: Props) => {
	const classes = new BemClass('CreateRelation', className);
	const { t } = useTranslation();
	const [selectedView, setSelectedView] = useState(defaultView);
	const hasSubaccounts = useSoftDeployAccess('SUB_ACCOUNTS');
	const hasSubaccountsV2 = useSoftDeployAccess('SUB_ACCOUNTS_V2') && hasSubaccounts;

	const lang = {
		headerTitle: t('createRelation.header.title'),
		cardTitleRelation: t('createRelation.card.titleRelation'),
		cardSubtitleRelation: t('createRelation.card.subtitleRelation'),
		cardTitleSubaccount: t('createRelation.card.titleSubaccount'),
		cardSubtitleSubaccount: t('createRelation.card.subtitleSubaccount'),
		cardTitleParentCompany: t('createRelation.card.titleParent'),
		cardSubtitleParentCompany: t('createRelation.card.subtitleParent')
	};

	const cardData = [
		{
			title: lang.cardTitleRelation,
			subtitle: lang.cardSubtitleRelation,
			imgSrc: 'img/subaccounts/relation.svg',
			onClick: () => setSelectedView(CreateRelationView.Relation)
		},
		{
			title: lang.cardTitleParentCompany,
			subtitle: lang.cardSubtitleParentCompany,
			imgSrc: 'img/subaccounts/parent-company.svg',
			onClick: () => setSelectedView(CreateRelationView.ParentCompany)
		}
	];

	if (hasSubaccountsV2) {
		cardData.splice(1, 0, {
			title: lang.cardTitleSubaccount,
			subtitle: lang.cardSubtitleSubaccount,
			imgSrc: 'img/subaccounts/subaccount.svg',
			onClick: () => setSelectedView(CreateRelationView.Subaccount)
		});
	}

	const goBackToDefault = () => {
		setSelectedView(CreateRelationView.Default);
	};

	const closeDrawer = () => {
		close(undefined, true);
	};

	return (
		<Drawer className={classes.b()}>
			<DrawerHeader title={lang.headerTitle} subtitle={client.name} onHide={closeDrawer} />
			{selectedView === CreateRelationView.Default ? (
				<Flex direction="column" gap="u5" className={classes.elem('content').b()}>
					{cardData.map((card, index) => (
						<Card key={index} borderRadius onClick={card.onClick}>
							<Text size="lg" bold space="ptxl plxl">
								{card.title}
							</Text>
							<Flex gap="u5">
								<Text space="ptm plxl" color="grey-11" className={classes.elem('card-text').b()}>
									{card.subtitle}
								</Text>
								<img src={card.imgSrc} />
							</Flex>
						</Card>
					))}
				</Flex>
			) : selectedView === CreateRelationView.Relation ? (
				<AddRelation goBack={goBackToDefault} client={client} modalId={modalId} close={closeDrawer} />
			) : selectedView === CreateRelationView.Subaccount ? (
				<AddSubaccount
					goBack={goBackToDefault}
					client={{ ...client, hasAccess: true }}
					modalId={modalId}
					close={closeDrawer}
				/>
			) : (
				<AddParentCompany goBack={goBackToDefault} client={client} modalId={modalId} close={closeDrawer} />
			)}
		</Drawer>
	);
};

export default CreateRelation;
