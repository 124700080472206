import { Modal, ModalContent, ModalHeader } from '@upsales/components';
import React from 'react';
import { useTranslation } from 'Components/Helpers/translate';
import ModalControls from '@upsales/components/ModalControls';
import User from 'App/resources/Model/User';
import Contact from 'App/resources/Model/Contact';
import { ModalProps } from '../Modals/Modals';

type Props = ModalProps & {
	user: Omit<User, 'crm' | 'support'>;
	contact: Pick<Contact, 'email' | 'phone'>;
	daysDelayed: number;
	daysLeft: number;
};

export default (props: Props) => {
	const { contact, daysDelayed, daysLeft, user, className, close } = props;
	const { t } = useTranslation();
	return (
		<Modal size="sm" className={className} style={{ overflow: 'visible' }}>
			<ModalHeader style={{ backgroundColor: '#f6f9fb', border: 'none' }}>
				<div
					style={{
						position: 'absolute',
						top: '-40px',
						left: '50%',
						marginLeft: '-40px',
						border: '3px solid #fff',
						width: '80px',
						height: '80px',
						borderRadius: '50%',
						overflow: 'hidden',
						boxSizing: 'content-box'
					}}
				>
					<img src="img/browser_notification_icon.png" height="80px" width="80px" />
				</div>
			</ModalHeader>
			<ModalContent className="ModalContent--no-padding pior">
				<div className="up-panel">
					<div className="up-panel-content text-center">
						<h4>
							{t('default.hello')} {user.name}, <br />
							{t('delayedInvoice.weMissPayment')}!
						</h4>
						<div>
							{t('delayedInvoice.weSeeInfo')}
							<span style={{ color: daysDelayed >= 15 ? 'red' : 'inherit' }}>
								{` ${daysDelayed} ${t('delayedInvoice.daysLate').toLowerCase()}`}
							</span>
							.
							{daysDelayed >= 15 ? (
								<span>{` ${t('delayedInvoice.ifYouNotPayWithin', { daysLeft })}`}</span>
							) : null}
							<br />
							<br />
							<span>{t('delayedInvoice.questions')}</span>
							<a href={`mailto:${contact.email}`}> {contact.email}</a>
							{` ${t('default.or').toLowerCase()} `}
							<a href={`callto:${contact.phone}`}>{contact.phone}</a>
						</div>
					</div>
				</div>
			</ModalContent>
			<ModalControls style={{ textAlign: 'center', borderTop: '2px solid #a4b3c7' }}>
				<button
					type="button"
					className="btn btn-bright-blue btn-link up-btn btn-block"
					style={{ marginLeft: 0 }}
					onClick={() => close()}
				>
					{daysDelayed >= 15 ? t('default.iAgree') : t('default.closeWindow')}
				</button>
			</ModalControls>
		</Modal>
	);
};
