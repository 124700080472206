import React from 'react';
import { IconName, Modal, ModalContent, ModalControls, ModalHeader } from '@upsales/components';
import T from 'Components/Helpers/translate';
import { ModalProps } from '../Modals/Modals';

interface DataRow {
	[key: string]: any;
	$$disabled?: boolean;
	$$selected?: boolean;
}

interface Props extends ModalProps<DataRow> {
	title: string;
	icon?: IconName;
	columns: { title: string; value: string }[];
	data: DataRow[];
	highlight?: boolean;
	hideHeader?: boolean;
	hideControls?: boolean;
}

const ListModal = (props: Props) => {
	return (
		<Modal className={props.className}>
			<ModalHeader title={T(props.title)} onClose={props.close} icon={props.icon} />
			<ModalContent className="ModalContent--no-padding">
				<table className="main-table">
					{props.hideHeader ? null : (
						<thead>
							{props.columns.map((column, index) => (
								<th key={index}>{T(column.title)}</th>
							))}
						</thead>
					)}
					<tbody>
						{props.data.map((row, index) => (
							<tr
								key={index}
								onClick={() => !row.$$disabled && props.close(row)}
								className={props.highlight && row.$$selected ? 'selected' : ''}
							>
								{props.columns.map((column, index) => (
									<td key={index} dangerouslySetInnerHTML={{ __html: row[column.value] }} />
								))}
							</tr>
						))}
					</tbody>
				</table>
			</ModalContent>
			{props.hideControls ? null : (
				<ModalControls>
					<button type="submit" className="up-btn btn-link btn-grey" onClick={props.close}>
						{T('default.close')}
					</button>
				</ModalControls>
			)}
		</Modal>
	);
};

export default ListModal;
