import Resource from 'Resources/ResourceTyped';
import type TicketDefaultEmailContactModel from './Model/TicketDefaultEmailContact';

class TicketDefaultEmailContact extends Resource<TicketDefaultEmailContactModel> {
	eventName = 'ticketDefaultEmailContact';
	constructor() {
		super('ticketDefaultEmailContacts');

		this.notifications = {
			save: () => ({
				title: 'default.saved',
				body: 'saved.defaultEmailContact',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			saveError: data => ({
				title: 'default.error',
				body: 'saveError.defaultEmailContact',
				style: 'error',
				icon: 'times',
				type: 'body',
				error: data?.error
			}),
			delete: () => ({
				title: 'default.deleted',
				body: 'deleted.defaultEmailContact',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			deleteError: data => ({
				title: 'default.error',
				body: 'deleteError.defaultEmailContact',
				style: 'error',
				icon: 'times',
				type: 'body',
				error: data?.error
			})
		};
	}

	updateEmail(id: number, data: Partial<TicketDefaultEmailContactModel>) {
		return this.save({ ...data, id });
	}

	findByEmail(email: string) {
		return this.find({ email }).then(({ data }) => data[0] || null);
	}
}

const resource = new TicketDefaultEmailContact();
export default resource;
