enum Actions {
	SET_PRIORITY = '[EditTicketContext] SET_PRIORITY',
	SET_FILTER = '[EditTicketContext] SET_FILTER',
	SET_DESCRIPTION = '[EditTicketContext] SET_DESCRIPTION',
	SET_LOADING = '[EditTicketContext] SET_LOADING',
	SET_CONTACT = '[EditTicketContext] SET_CONTACT',
	SET_TICKET = '[EditTicketContext] SET_TICKET',
	SET_CLIENT = '[EditTicketContext] SET_CLIENT',
	SET_SAVING = '[EditTicketContext] SET_SAVING',
	SET_USER = '[EditTicketContext] SET_USER',
	SET_TITLE = '[EditTicketContext] SET_TITLE',
	SET_EVENTS = '[EditTicketContext] SET_EVENTS',
	SET_COMMENT = '[EditTicketContext] SET_COMMENT',
	SET_SAVING_COMMENT = '[EditTicketContext] SET_SAVING_COMMENT',
	SET_PUBLIC_MESSAGE = '[EditTicketContext] SET_PUBLIC_MESSAGE',
	SET_SENDING_PUBLIC_MESSAGE = '[EditTicketContext] SET_SENDING_PUBLIC_MESSAGE',
	SET_EMAIL_ON_CONTACT = '[EditTicketContext] SET_EMAIL_ON_CONTACT',
	SET_HAS_MORE_EVENTS = '[EditTicketContext] SET_HAS_MORE_EVENTS',
	SET_OFFSET = '[EditTicketContext] SET_OFFSET',
	SET_CAN_SEND_PUBLIC_MESSAGE = '[EditTicketContext] SET_CAN_SEND_PUBLIC_MESSAGE',
	SET_SUPPORT_EMAILS = '[EditTicketContext] SET_SUPPORT_EMAILS',
	SET_TYPE = '[EditTicketContext] SET_TYPE',
	SET_SHOW_CLOSED_SCREEN = '[EditTicketContext] SET_SHOW_CLOSED_SCREEN',
	SET_STATE = '[EditTicketContext] SET_STATE',
	ADD_FILE = '[EditTicketContext] ADD_FILE',
	SET_FILES = '[EditTicketContext] SET_FILES',
	SET_TOTAL_FILE_SIZE = '[EditTicketContext] SET_TOTAL_FILE_SIZE',
	SET_TICKET_RESPONSE_TEMPLATE = '[EditTicketContext] SET_RESPONSE_TEMPLATE',
	SET_ACTIVE_EXTERNAL_CONTACT_ID = '[EditTicketContext] SET_ACTIVE_EXTERNAL_CONTACT_ID',
	SET_DISMISS_MATCH_POPUP = '[EditTicketContext] SET_DISMISS_MATCH_POPUP'
}

export default Actions;
