import Attribute, { DisplayType, Type } from './Attribute';

const MailAttributes = {
	id: Attribute({
		title: 'default.id',
		field: 'id',
		type: Type.Number,
		displayType: DisplayType.Number,
		groupable: false,
		selectableColumn: false,
		filterable: false
	}),
	date: Attribute({
		title: 'default.date',
		field: 'date',
		type: Type.Date,
		displayType: DisplayType.Date,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'date'
	}),
	type: Attribute({
		title: 'default.status',
		field: 'type',
		selectableColumn: true,
		displayKey: ['type', 'errorCause'],
		type: Type.String,
		displayType: DisplayType.MailStatus,
		elevioId: 278
	}),
	from: Attribute({
		title: 'mail.from',
		field: 'from',
		selectableColumn: true,
		type: Type.String,
		displayType: DisplayType.Text,
		sortable: 'from'
	}),
	cc: Attribute({
		title: 'mail.cc',
		field: 'cc',
		selectableColumn: false,
		type: Type.String,
		displayType: DisplayType.Text
	}),
	bcc: Attribute({
		title: 'mail.bcc',
		field: 'bcc',
		selectableColumn: false,
		type: Type.String,
		displayType: DisplayType.Text
	}),
	fromName: Attribute({
		title: 'mail.fromName',
		field: 'fromName',
		selectableColumn: true,
		type: Type.String,
		sortable: 'fromName',
		displayType: DisplayType.Text
	}),
	body: Attribute({
		title: 'mail.body',
		field: 'body',
		selectableColumn: false,
		type: Type.String,
		displayType: DisplayType.Text
	}),
	contact: Attribute({
		title: 'default.contact',
		field: 'contact',
		type: Type.String,
		displayType: DisplayType.Contact,
		selectableColumn: true,
		attr: {
			id: {
				field: 'contact.id',
				type: Type.Number
			},
			name: {
				field: 'contact.name',
				type: Type.String
			}
		}
	}),
	client: Attribute({
		title: 'default.account',
		field: 'client',
		type: Type.String,
		displayType: DisplayType.ClientLink,
		selectableColumn: false
	}),
	userId: Attribute({
		title: 'default.user',
		field: 'userId',
		selectableColumn: false,
		type: Type.Number,
		displayType: DisplayType.Number
	}),
	socialEventId: Attribute({
		title: 'default.socialEventId',
		field: 'socialEventId',
		selectableColumn: false,
		type: Type.Number,
		displayType: DisplayType.Number
	}),
	template: Attribute({
		title: 'mail.template',
		field: 'template.id',
		type: Type.String,
		displayType: DisplayType.MailTemplate,
		selectableColumn: true
		// sortable: 'template.name',
	}),
	events: Attribute({
		title: 'mail.readclick',
		field: 'events.id',
		type: Type.Number,
		displayType: DisplayType.Text,
		selectableColumn: true
	}),
	to: Attribute({
		title: 'default.mail',
		field: 'to',
		selectableColumn: true,
		type: Type.String,
		displayType: DisplayType.Text,
		sortable: 'to'
	}),
	subject: Attribute({
		title: 'mail.subject',
		field: 'subject',
		selectableColumn: true,
		sortable: 'subject',
		type: Type.String,
		displayType: DisplayType.Text
	}),
	lastClickDate: Attribute({
		title: 'mail.readclick',
		field: 'lastClickDate',
		sortable: 'lastClickDate',
		selectableColumn: false,
		type: Type.Date,
		displayType: DisplayType.Text
	}),
	lastEventDate: Attribute({
		title: 'mail.readclickDate',
		field: 'lastEventDate',
		sortable: 'lastEventDate',
		selectableColumn: true,
		type: Type.Date,
		displayType: DisplayType.Date
	}),
	lastReadDate: Attribute({
		title: 'mail.readDate',
		field: 'lastReadDate',
		sortable: 'lastReadDate',
		selectableColumn: false,
		type: Type.Date,
		displayType: DisplayType.Date
	}),
	groupMailId: Attribute({
		title: 'mail.mailing',
		field: 'groupMailId',
		selectableColumn: false,
		type: Type.Number,
		displayType: DisplayType.Number
	}),
	jobId: Attribute({
		title: 'mail.jobId',
		field: 'jobId',
		selectableColumn: false,
		type: Type.String,
		displayType: DisplayType.Text
	}),
	isMap: Attribute({
		title: 'mail.isMap',
		field: 'isMap',
		selectableColumn: false,
		type: Type.String,
		displayType: DisplayType.Text
	}),
	errorCause: Attribute({
		title: 'mail.errorCause',
		field: 'errorCause',
		selectableColumn: false,
		type: Type.String,
		displayType: DisplayType.Text
	}),
	attachments: Attribute({
		title: 'mail.attachments',
		field: 'attachments',
		selectableColumn: false,
		type: Type.String,
		displayType: DisplayType.Text
	}),
	mailBodySnapshotId: Attribute({
		title: 'admin.anonymization.mail_body_snapshot_id',
		field: 'mailBodySnapshotId',
		selectableColumn: false,
		type: Type.Number,
		displayType: DisplayType.Number
	}),
	journeyStep: Attribute({
		title: 'default.journeyStep',
		field: 'client.journeyStep',
		selectableColumn: false,
		groupable: false,
		type: Type.String,
		displayType: DisplayType.Journey
	}),
	clientJourneyStep: Attribute({
		title: 'default.journeyStepClient',
		field: 'client.journeyStep',
		displayKey: 'client.journeyStep',
		selectableColumn: true,
		groupable: false,
		type: Type.String,
		displayType: DisplayType.Journey
	}),
	contactJourneyStep: Attribute({
		title: 'default.journeyStepContact',
		field: 'contact.journeyStep',
		displayKey: 'contact.journeyStep',
		selectableColumn: true,
		groupable: false,
		type: Type.String,
		displayType: DisplayType.Journey
	})
};

export default MailAttributes;
