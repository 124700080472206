import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
	Button,
	Input,
	Label,
	ModalContent,
	ModalControls,
	ModalHeader,
	Row,
	Column,
	DateInput,
	Toggle,
	Progress,
	Modal
} from '@upsales/components';

import TextEditor from 'Components/TextEditor';
import ModalTagList from '../Modals/ModalTagList';
import UserRoleSelect from '../Inputs/UserRoleSelect';
import logError from 'App/babel/helpers/logError';

import './EditMailSignatureModal.scss';
import { openFileBrowserModal } from 'Components/FileBrowser';

class EditMailSignatureModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			name: props.data.name || '',
			endDate: (props.data.endDate && new Date(props.data.endDate)) || null,
			startDate: (props.data.startDate && new Date(props.data.startDate)) || null,
			body: props.data.body || null,
			active: props.data.active || false,
			standard: props.data.standard || false,
			showTagList: false,
			imageData: null,
			endDateFlash: false,
			startDateFlash: false,
			nameError: props.data.nameError || false,
			users: props.data.users || [],
			roles: props.data.roles || [],
			usersNRoles: props.data.usersNRoles || []
		};

		this.onChange = this.onChange.bind(this);
		this.generateProfileLink = this.generateProfileLink.bind(this);
		this.generateSignatureFromCompanyProfile = this.generateSignatureFromCompanyProfile.bind(this);
	}

	onChange(key, value) {
		const state = Object.assign({}, this.state);

		if (key === 'name' && value.length > 80) {
			value = value.substr(0, 80);
		}

		if (key === 'name' && state.nameError) {
			state.nameError = false;
		}

		if (key === 'startDate' && moment(value).isAfter(state.endDate)) {
			// if startDate is set after endDate, set endDate to +1 day
			state.endDate = moment(value).add(1, 'day').toDate();
			state.endDateFlash = true;
		}

		if (key === 'endDate' && moment(value).isBefore(state.startDate)) {
			// if startDate is set after endDate, set endDate to +1 day
			state.startDate = moment(value).add(-1, 'day').toDate();
			state.startDateFlash = true;
		}

		if (state.startDateFlash || state.endDateFlash) {
			setTimeout(() => {
				this.setState({
					startDateFlash: false,
					endDateFlash: false
				});
			}, 750);
		}

		state[key] = value;
		this.setState(state);
	}

	componentDidMount() {
		const { data } = this.props;

		if (!data.body || data.body.length === 0) {
			this.generateSignatureFromCompanyProfile();
		}
	}

	generateSignatureFromCompanyProfile() {
		Tools.AccountProfile.get()
			.then(res => {
				const profile = res.data;

				var str = `
				<p style="margin: 0 0 10px 0;">Best regards,</p>
				<p style="margin: 0 0 10px 0;"><span style="font-size:14px;">{{General.CurrentUserName}}</span></p>
				<p style="margin: 0 0 10px 0;" class="small">{{General.CurrentUserTitle}}</p>
			`;

				if (profile) {
					str += `
					<p style="margin: 0 0 10px 0;"><img src='${profile.darkLogo || profile.logo}' /></p>
					<p style="margin: 0 0 10px 0;">${profile.name}<br/>${profile.address}<br/>${profile.zipcode} ${profile.city}</p>
				`;
				}

				str += '<p style="margin: 0 0 10px 0;">M: {{General.CurrentUserCellPhone}}</p>';

				this.setState({
					body: str
				});
			})
			.catch(e => logError(e, 'Failed to get account profile'));
	}

	generateProfileLink(event) {
		event.preventDefault();

		this.generateSignatureFromCompanyProfile();
	}

	toggleTagList() {
		this.setState({
			showTagList: !this.state.showTagList
		});
	}

	getButton = function (title, icon) {
		var a = angular.element('<a class="cke_button cke_button__image cke_button_off" />');

		a.attr({
			title: Tools.$translate(title),
			role: 'button',
			tabindex: '-1',
			hidefocus: true
		});

		if (icon) {
			a.addClass(icon);
			var iconElement = angular.element('<span class="cke_button_icon cke_button__image_icon"></span>');
			iconElement.addClass(icon + '_icon');
			a.append(iconElement);
		} else {
			var text = angular.element(
				'<span class="cke_button_label cke_button__source_label" aria-hidden="false" />'
			);
			text.text(Tools.$translate(title));
			a.append(text);
		}

		return a;
	};

	onTextEditorInstanceReady = e => {
		var editor = e.editor;
		var editorElem = angular.element(editor.container.$);
		var top = editorElem.find('.cke_top');
		var toolbar = top.find('.cke_toolbox');

		angular.element(editor.element.$).data('ckName', editor.name);

		var newGroupWrap;
		var newGroup;
		newGroupWrap = angular.element('<span class="cke_toolbar"/>');
		// Create a new btn group
		newGroup = angular.element('<span class="cke_toolgroup" />');

		var addImg = this.getButton('default.image', 'cke_button__image');
		newGroup.append(addImg);

		addImg.on('click', function (e) {
			e.preventDefault();

			// eslint-disable-next-line promise/catch-or-return
			openFileBrowserModal({
				types: ['image'],
				public: true
			}).then(function (selectedFiles) {
				var img = '<img alt="" data-widget="image2" src="' + selectedFiles[0].$$publicUrl + '" />';
				var imgHtml = CKEDITOR.dom.element.createFromHtml(img);
				editor.insertElement(imgHtml);
				editor.setData(editor.getData());
			});
		});

		newGroupWrap.append(newGroup);
		toolbar.append(newGroupWrap);

		// Type timeout
		// var onResize;
		var typeTimeout;
		var sourceKeyup = function () {
			if (typeTimeout) {
				clearTimeout(typeTimeout);
			}

			typeTimeout = setTimeout(function () {
				editor.setData(editor.getData());
				editor.fire('change');
			}, 200);
		};

		$(document).on('keyup', 'textarea.cke_source', sourceKeyup);

		// Listen for destroy
		editor.on('destroy', function () {
			// remove listeners
			$(document).off('keyup', 'textarea.cke_source', sourceKeyup);
		});

		this.onChange('body', e.editor.getData());

		setTimeout(function () {
			e.editor.container.setOpacity(1);
		}, 200);

		// Return image for testing?
		return addImg;
	};

	renderContent = () => {
		const { close, onSave, onClose } = this.props;
		const closeFunction = Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL') ? close : onClose; // onClose is angular upModal close function

		// maxLength of name
		const maxLength = 80;
		let progressState = null;
		const percentageName = (this.state.name.length / maxLength) * 100;
		if (percentageName === 100) {
			progressState = 'error';
		}
		const dateFormat = moment.localeData().longDateFormat('L');

		return (
			<>
				<ModalHeader
					color="blue"
					className="ModalHeader"
					onClose={closeFunction}
					title={Tools.$translate('mailSignature.editMailSignature')}
				>
					<Button
						key="show-tags"
						style={{
							position: 'absolute',
							top: 0,
							right: '52px',
							height: '46px'
						}}
						onClick={() => this.toggleTagList()}
						type="link"
						size="sm"
					>
						{Tools.$translate('tag.available')}
					</Button>
				</ModalHeader>
				<ModalContent style={{ padding: '20px' }}>
					<Row>
						<Column className="form-holder">
							<Row>
								<Column style={{ position: 'relative' }}>
									<Label required={true}>{Tools.$translate('default.name')}</Label>
									<Progress
										hideText
										state={progressState}
										percentage={percentageName}
										className="EditMailSignature__progress"
									/>
									<Input
										state={this.state.nameError ? 'error' : null}
										value={this.state.name || ''}
										onChange={e => this.onChange('name', e.target.value)}
										maxLength={maxLength}
									/>
								</Column>
								<Column>
									<Label>{Tools.$translate('admin.mailSignature.usersAndRoles')}</Label>
									<UserRoleSelect
										defaultValue={this.state.usersNRoles}
										includeLoggedInUser
										onChange={value => {
											this.onChange(
												'usersNRoles',
												value.reduce((res, item) => {
													if (!item.roleId || !res.find(itm => itm.roleId === item.roleId)) {
														res.push(item);
													}
													return res;
												}, [])
											);
										}}
									/>
								</Column>
							</Row>
							<Row>
								<Column>
									<Label>{Tools.$translate('default.startDate')}</Label>
									<DateInput
										icon="calendar"
										closeOnSelect={true}
										placeholder={dateFormat}
										value={this.state.startDate}
										className={this.state.startDateFlash ? 'flash' : ''}
										onChange={e => this.onChange('startDate', e.target.value)}
										calendarSize={6}
									/>
								</Column>
								<Column>
									<Label>{Tools.$translate('default.endDate')}</Label>
									<DateInput
										icon="calendar"
										closeOnSelect={true}
										placeholder={dateFormat}
										value={this.state.endDate}
										className={this.state.endDateFlash ? 'flash' : ''}
										onChange={e => this.onChange('endDate', e.target.value)}
										calendarSize={6}
									/>
								</Column>
							</Row>
							<Row>
								<Column>
									<Label>
										{Tools.$translate('default.signature')}
										<a
											style={{
												float: 'right',
												cursor: 'pointer'
											}}
											onClick={this.generateProfileLink}
										>
											{Tools.$translate('mailSignature.generateFromProfile')}
										</a>
									</Label>
									<TextEditor
										className="ckeditor-react-holder"
										value={this.state.body}
										config={{
											language: 'en',
											uiColor: '#fafafa',
											height: '400px',
											resize_enabled: false,
											toolbar: [
												{ name: 'styles', items: ['Font', 'FontSize', 'Format'] },
												{ name: 'basicstyles', items: ['Bold', 'Italic', 'Underline'] },
												{ name: 'colors', items: ['TextColor', 'BGColor'] },
												{ name: 'paragraph', items: ['NumberedList', 'BulletedList'] },
												{ name: 'links', items: ['Link', 'Unlink'] },
												{ name: 'source', items: ['Source'] }
											],
											allowedContent: true,
											entities: false,
											enterMode: CKEDITOR.ENTER_BR,
											removePlugins: 'image',
											contentsCss: [
												CKEDITOR.getUrl('contents.css'),
												'/styles/custom-ckeditor.css'
											],
											fontSize_sizes:
												'8/8px;9/9px;10/10px;11/11px;12/12px;13/13px;14/14px;15/15px;16/16px;18/18px;19/19px;20/20px;21/21px;22/22px;23/23px;34/34px;35/35px;26/26px;27/27px;28/28px;29/29px;30/30px;32/32px;34/34px;36/36px;38/38px;40/40px;'
										}}
										onChange={editorData => {
											this.onChange('body', editorData.value);
										}}
										onInstanceReady={this.onTextEditorInstanceReady}
									/>
								</Column>
							</Row>
						</Column>
						{!this.state.showTagList && (
							<Column style={{ maxWidth: '250px' }}>
								<Column
									style={{
										marginLeft: '5px',
										padding: '20px 0 20px 20px',
										borderLeft: '4px solid #E5E8ED'
									}}
								>
									<div className="info-area">
										<p>{Tools.$translate('mailSignature.sidebar_description')}</p>
										<ReactTemplates.elevio
											sidebar="true"
											articleId="797"
											text={Tools.$translate('default.readMore')}
										/>
									</div>
								</Column>
							</Column>
						)}
					</Row>
				</ModalContent>
				<ModalControls>
					<div className="ModalControls__leftSide">
						<div
							style={{
								display: 'inline-block',
								float: 'left',
								margin: '7px 10px 7px 0',
								verticalAlign: 'middle'
							}}
						>
							<Toggle checked={!!this.state.active} onChange={value => this.onChange('active', value)} />
						</div>
						<Label
							style={{
								display: 'inline-block',
								float: 'left',
								margin: '0 10px 0 0',
								verticalAlign: 'middle',
								cursor: 'pointer'
							}}
							onClick={() => this.onChange('active', !this.state.active)}
						>
							<span style={{ verticalAlign: 'middle', height: '34px', display: 'table-cell' }}>
								{Tools.$translate('mailSignature.signature.isActive')}
							</span>
						</Label>
					</div>
					<div>
						<Button
							shadow="none"
							onClick={() => {
								if (this.state.name.length) {
									onSave(Object.assign({}, this.props.data, this.state));
									closeFunction();
								} else {
									this.setState({
										nameError: true
									});
								}
							}}
						>
							{Tools.$translate('default.save')}
						</Button>
						<Button color="grey" type="link" onClick={() => closeFunction()}>
							{Tools.$translate('cancel')}
						</Button>
					</div>
				</ModalControls>
				<ModalTagList
					entity="general"
					onClose={() => this.toggleTagList()}
					style={this.state.showTagList ? { display: 'block' } : { display: 'none' }}
					excludedTags={['General.CurrentUserMailSignature']}
				/>
			</>
		);
	};

	render() {
		const { className } = this.props;

		if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
			return (
				<Modal
					size="xl"
					className={[
						className,
						this.state.showTagList ? 'EditMailSignature tags-visible' : 'EditMailSignature'
					].join(' ')}
				>
					{this.renderContent()}
				</Modal>
			);
		}

		return (
			<div className={this.state.showTagList ? 'EditMailSignature tags-visible' : 'EditMailSignature'}>
				{this.renderContent()}
			</div>
		);
	}
}

EditMailSignatureModal.propTypes = {
	close: PropTypes.func,
	onClose: PropTypes.func, // angular upModal close function
	onSave: PropTypes.func,
	startEdit: PropTypes.func,
	data: PropTypes.object,
	className: PropTypes.string
};

export const detached = EditMailSignatureModal;
export default EditMailSignatureModal;
