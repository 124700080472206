import React from 'react';
import { Action, createLocalContextWithActionHandlers } from 'App/helpers/stateHelper';
import Actions from './Actions';
export { type Dispatch } from 'App/helpers/stateHelper';
import { type Financials } from '../GroupFinancials/GroupFinancials';

export type CompanyGroupState = {
	prospectingId: string;
	tree: any;
	treeMeta: any;
	loadingTree: boolean;
	countries: string[];
	journeySteps: string[];
	sniCodeMap: { [key: string]: number };
	total: number;
	orgNumbers: string[];
	clientIds: number[];
	initialClients: number[];
	groupStatement: Financials | null;
	activityData: {
		open: number | null;
		overdue: number | null;
		closed: number | null;
	};
	numberOfContacts: number | null;
	salesData: { total: number | null };
	opportunityData: { count: number; total: null | number; existingPipeline: number };
};

const ACTION_HANDLERS: { [key: string]: (s: CompanyGroupState, a: Action) => CompanyGroupState } = {
	[Actions.SET_TREE]: (state, { tree, treeMeta, countries, journeySteps, sniCodeMap, total }) => ({
		...state,
		tree: tree ?? state.tree,
		treeMeta: treeMeta ?? state.treeMeta,
		countries: countries ?? state.countries,
		journeySteps: journeySteps ?? state.journeySteps,
		sniCodeMap: sniCodeMap ?? state.sniCodeMap,
		total: total ?? state.total
	}),
	[Actions.SET_GROUP]: (state, { orgNumbers, initialClients, clientIds }) => ({
		...state,
		orgNumbers,
		initialClients,
		clientIds
	}),
	[Actions.SET_LOADING_TREE]: (state, { loadingTree }) => ({
		...state,
		loadingTree
	}),
	[Actions.SET_PROSPECTING_ID]: (state, { prospectingId }) => ({
		...state,
		prospectingId
	}),
	[Actions.SET_CLIENT_IDS]: (state, { clientIds, orgNumbers }) => ({
		...state,
		clientIds,
		orgNumbers
	}),
	[Actions.SET_GROUP_STATEMENT]: (state, { groupStatement }) => ({
		...state,
		groupStatement
	}),
	[Actions.SET_ACTIVITY_NUMBERS]: (state, { open, overdue, closed }) => ({
		...state,
		activityData: {
			open,
			overdue,
			closed
		}
	}),
	[Actions.SET_NUMBER_OF_CONTACTS]: (state, { numberOfContacts }) => ({
		...state,
		numberOfContacts
	}),
	[Actions.SET_SALES_DATA]: (state, { salesData }) => ({
		...state,
		salesData
	}),
	[Actions.SET_OPPORTUNITY_DATA]: (state, { opportunityData }) => ({
		...state,
		opportunityData
	}),
	[Actions.NO_CLIENTS]: state => ({
		...state,
		activityData: {
			open: 0,
			overdue: 0,
			closed: 0
		},
		numberOfContacts: 0,
		salesData: { total: 0 },
		opportunityData: { count: 0, total: 0, existingPipeline: 0 }
	})
};

const { Provider, useDispatch, useSelector } = createLocalContextWithActionHandlers(ACTION_HANDLERS);
export const useCompanyGroupDispatch = useDispatch;
export const useCompanyGroupSelector = useSelector;

export function CompanyGroupProvider({ children }: { children: React.ReactNode }) {
	const initialState = {
		prospectingId: '',
		tree: null,
		treeMeta: { total: 0, unknowns: [], unbought: [], unboughtKnown: [] },
		countries: [],
		journeySteps: [],
		sniCodeMap: {},
		loadingTree: false,
		clientIds: [],
		orgNumbers: [],
		initialClients: [],
		groupStatement: null,
		activityData: {
			open: null,
			overdue: null,
			closed: null
		},
		numberOfContacts: null,
		total: 0,
		salesData: { total: null },
		opportunityData: { count: 0, total: null, existingPipeline: 0 }
	};

	return <Provider value={initialState}>{children}</Provider>;
}
