import { Block, ButtonSelect, Text, Help, Icon, Title } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React from 'react';

import './CustomerPortfolioWidgetHeader.scss';

export type CustomerPortfolioWidgetHeaderProps = {
	title: string;
	selectOptions?: {
		title: string;
		value: string;
	}[];
	onChange?: (value: string) => void;
	extraInfo?: React.ReactNode;
	helpArticleId?: number;
	helpText?: string;
};

const CustomerPortfolioWidgetHeader = ({
	title,
	selectOptions,
	onChange,
	extraInfo,
	helpArticleId,
	helpText
}: CustomerPortfolioWidgetHeaderProps) => {
	const classes = new BemClass('CustomerPortfolioWidgetHeader');
	const [selectedOption, setSelectedOption] = React.useState(selectOptions?.[0]?.value);

	return (
		<Block space="mlxl mrxl mtl mbs" className={classes.b()}>
			<Block>
				<Title className={classes.elem('title').b() + ' text-ellipsis'}>{title}</Title>
				{helpArticleId ? (
					<Help articleId={helpArticleId} sidebar={true}>
						<Text size="sm" color="blue">
							<Icon name="question-circle" space="mrm" />
							{helpText}
						</Text>
					</Help>
				) : null}
			</Block>
			{selectOptions?.length ? (
				<ButtonSelect
					onChange={selected => {
						setSelectedOption(selected);
						onChange?.(selected ?? '');
					}}
					options={selectOptions}
					value={selectedOption}
				/>
			) : null}
			{extraInfo}
		</Block>
	);
};

export default CustomerPortfolioWidgetHeader;
