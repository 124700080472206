import Attribute, { DisplayType, Type } from './Attribute';

const FormSubmitAttributes: Readonly<{ [key: string]: ReturnType<typeof Attribute> }> = {
	id: Attribute({
		title: 'default.id',
		field: 'id',
		type: Type.Number,
		displayType: DisplayType.Number
	}),
	formId: Attribute({
		title: 'default.account',
		field: 'formId',
		type: Type.Number,
		displayType: DisplayType.Number
	}),
	form: Attribute({
		title: 'form.form',
		field: 'form.id',
		type: Type.Number,
		displayType: DisplayType.Text,
		displayAttr: 'name',
		parent: 'form',
		attr: {
			id: Attribute({
				field: 'form.id',
				type: Type.Number
			}),
			title: Attribute({
				field: 'form.title',
				type: Type.String
			}),
			name: Attribute({
				field: 'form.name',
				type: Type.String
			})
		},
		sortable: 'form.name',
		selectableColumn: true
	}),
	contact: Attribute({
		title: 'default.contact',
		field: 'contact.id',
		inputType: 'select',
		displayAttr: 'name',
		type: Type.Number,
		displayType: DisplayType.Text,
		parent: 'contact',
		sortable: 'contact.name',
		attr: {
			id: Attribute({
				field: 'contact.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'contact.name',
				type: Type.String
			})
		}
	}),
	contactAndClient: Attribute({
		title: 'default.contactAndClient',
		field: 'contactAndClient',
		inputType: 'select',
		displayAttr: 'name',
		type: Type.Number,
		displayType: DisplayType.Text,
		selectableColumn: true
	}),
	clientStatus: Attribute({
		title: 'default.clientHistory',
		field: 'client.status',
		type: Type.String
	}),
	eventTypes: Attribute({
		type: Type.Number,
		displayType: DisplayType.Text,
		field: 'eventTypes',
		title: 'scoreboard.eventTypes',
		selectableColumn: true
	}),
	client: Attribute({
		type: Type.Number,
		title: 'default.account',
		field: 'client.id',
		inputType: 'select',
		displayAttr: 'name',
		displayType: DisplayType.Text,
		link: 'accounts',
		sortable: 'client.name',
		parent: 'client',
		attr: {
			id: Attribute({
				field: 'client.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'client.name',
				type: Type.String
			}),
			user: Attribute({
				field: 'client.user',
				type: Type.String
			}),
			category: Attribute({
				field: 'client.category',
				type: Type.String
			})
		}
	}),
	processedDate: Attribute({
		title: 'default.date',
		field: 'processedDate',
		type: Type.Date,
		displayType: DisplayType.DateTime,
		sortable: 'processedDate',
		selectableColumn: true
	}),
	regDate: Attribute({
		title: 'default.wasCreated',
		field: 'regDate',
		type: Type.Date,
		displayType: DisplayType.Date
	}),
	score: Attribute({
		title: 'default.score',
		field: 'score',
		type: Type.Number,
		displayType: DisplayType.Number,
		selectableColumn: true,
		sortable: 'score'
	}),
	status: Attribute({
		type: Type.Number,
		displayType: DisplayType.Text,
		field: 'status',
		title: 'default.clientHistory',
		elevioId: 663,
		selectableColumn: true
	}),
	assigned: Attribute({
		type: Type.Number,
		displayType: DisplayType.Text,
		field: 'assigned',
		title: 'assign.assignedTo',
		elevioId: 242,
		selectableColumn: true
	}),
	lastAssigned: Attribute({
		title: 'assign.lastAssigned',
		field: 'client.assigned',
		displayKey: 'client.assigned',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: true,
		attr: {
			user: Attribute({
				field: 'client.assigned.user.name',
				type: Type.String
			}),
			regDate: Attribute({
				field: 'client.assigned.regDate',
				type: Type.Date
			})
		}
	}),
	landingPage: Attribute({
		title: 'default.type',
		field: 'form.landingPageBody',
		type: Type.Boolean,
		displayType: DisplayType.Boolean,
		selectableColumn: true
	}),
	utmCampaign: Attribute({
		type: Type.String,
		displayType: DisplayType.Text,
		field: 'visit.leadSource.utm.campaign',
		title: 'column.utmCampaign',
		selectableColumn: true,
		sortable: 'visit.leadSource.utm.campaign'
	}),
	utmContent: Attribute({
		type: Type.String,
		displayType: DisplayType.Text,
		field: 'visit.leadSource.utm.content',
		title: 'column.utmContent',
		selectableColumn: true,
		sortable: 'visit.leadSource.utm.content'
	}),
	utmMedium: Attribute({
		type: Type.String,
		displayType: DisplayType.Text,
		field: 'visit.leadSource.utm.medium',
		title: 'column.utmMedium',
		selectableColumn: true,
		sortable: 'visit.leadSource.utm.medium'
	}),
	utmSource: Attribute({
		type: Type.String,
		displayType: DisplayType.Text,
		field: 'visit.leadSource.utm.source',
		title: 'column.utmSource',
		selectableColumn: true,
		sortable: 'visit.leadSource.utm.source'
	}),
	utmTerm: Attribute({
		type: Type.String,
		displayType: DisplayType.Text,
		field: 'visit.leadSource.utm.term',
		title: 'column.utmTerm',
		selectableColumn: true,
		sortable: 'visit.leadSource.utm.term'
	}),
	leadSource: Attribute({
		title: 'default.leadsource',
		field: 'visit.leadSource',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: true,
		elevioId: 241,
		sortable: 'visit.leadSource.source',
		attr: {
			type: Attribute({
				field: 'visit.leadSource.type',
				type: Type.Number
			}),
			source: Attribute({
				field: 'visit.leadSource.source',
				type: Type.String
			}),
			value: Attribute({
				field: 'visit.leadSource.value',
				type: Type.String
			})
		}
	}),
	users: Attribute({
		type: Type.Array,
		displayType: DisplayType.Users,
		field: 'client.users',
		title: 'default.accountManager',
		sortable: 'client.users.name',
		displayKey: 'client.users',
		placeholder: 'default.noAccountManager',
		selectableColumn: true,
		attr: {
			id: Attribute({
				field: 'client.users.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'client.users.name',
				type: Type.String
			})
		}
	}),
	journeyStep: Attribute({
		type: Type.String,
		title: 'default.journeyStep',
		field: 'client.journeyStep',
		displayKey: 'client.journeyStep',
		displayType: DisplayType.Journey,
		selectableColumn: true
	}),
	clientJourneyStep: Attribute({
		type: Type.String,
		title: 'default.journeyStepClient',
		field: 'client.journeyStep',
		displayKey: 'client.journeyStep',
		displayType: DisplayType.Journey
	}),
	contactJourneyStep: Attribute({
		type: Type.String,
		title: 'default.journeyStepContact',
		field: 'contact.journeyStep',
		displayKey: 'contact.journeyStep',
		displayType: DisplayType.Journey
	})
};

export default FormSubmitAttributes;
