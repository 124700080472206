import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
	Button,
	Label,
	Input,
	ModalHeader,
	ModalContent,
	ModalControls,
	Row,
	Column,
	Text,
	Icon,
	Block,
	Help,
	Tooltip
} from '@upsales/components';
import { addSocialEventDomain } from 'Store/reducers/DomainReducer';
import type { RootState } from 'Store/index';
import LandingPageDomain from 'App/resources/Model/LandingPageDomain';
import logError from 'App/babel/helpers/logError';
import './EditEventDomain.scss';
import { asyncModalAdapter, setupComponentCompatibility } from 'App/helpers/angularPortingHelpers';

const mapStateToProps = (state: RootState) => ({
	saving: state.Domains.savingEventDomain
});

const mapDispatchToProps = {
	addSocialEventDomain
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type OwnProps = {
	domain: LandingPageDomain;
	reject: () => void;
	saving: boolean;
};

type State = {
	domain: Partial<LandingPageDomain> & Pick<LandingPageDomain, 'name'>;
	isValidDomain: boolean | null;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = OwnProps & PropsFromRedux;

class EditEventDomain extends React.Component<Props, State> {
	lang: { [key: string]: string };
	showGmapKey: boolean;

	constructor(props: Props) {
		super(props);
		const t = Tools.$translate;
		this.lang = {
			abort: t('default.abort'),
			save: t('default.save'),
			domain: t('mail.domain'),
			editDomain: t('admin.domains.editDomain'),
			reCaptchaKey: t('admin.domains.event.recaptchaKey'),
			reCaptchaSecret: t('admin.domains.event.recaptchaSecret'),
			whatIsRecaptcha: t('admin.domains.event.whatIsRecaptcha'),
			gmapKey: t('admin.domains.event.gmapKey'),
			howToGenerateGmapKey: t('admin.domains.event.howToGenerateGmapKey'),
			subdomainText: t('admin.domains.subdomainSubtext'),
			subdomainExample: t('admin.domains.subdomainExample'),
			subdomainError: t('admin.domains.subdomainError')
		};

		this.state = {
			domain: props.domain || { name: '' },
			isValidDomain: null
		};

		this.showGmapKey = Tools.FeatureHelper.hasSoftDeployAccess('SHOW_SAVE_GMAP_KEY');
	}

	hasValidDomain = () => {
		const domain = this.state.domain;
		const split = domain?.name?.split('.');
		if (!split) {
			return false;
		}
		return split.length > 2 && split.every(part => part.length > 0);
	};

	updateValidDomainState = () => {
		this.setState({ isValidDomain: this.hasValidDomain() });
	};

	updateDomainProp = (prop: keyof LandingPageDomain, value: string) => {
		this.setState({ domain: { ...this.state.domain, [prop]: value } });
	};

	save = () => {
		const { addSocialEventDomain, reject } = this.props;
		const domain = this.state.domain;
		addSocialEventDomain(domain)
			.then(reject)
			.catch(error => logError(error, 'EditEventDomain save'));
	};

	render() {
		const domain = this.state.domain;
		const isValidDomain = this.state.isValidDomain;
		const lang = this.lang;
		const hideTooltip = this.state.isValidDomain || this.state.isValidDomain === null;

		return (
			<div className="EditEventDomain">
				<ModalHeader title={lang.editDomain} onClose={this.props.reject} />
				<ModalContent>
					<Row>
						<Column>
							<Label>{lang.domain}</Label>
							<Tooltip title={lang.subdomainError} disabled={hideTooltip}>
								<Input
									placeholder={lang.subdomainExample}
									value={domain.name}
									onChange={e => this.updateDomainProp('name', e.target.value)}
									onBlur={this.updateValidDomainState}
									state={domain.name ? (isValidDomain ? 'success' : 'error') : null}
								/>
							</Tooltip>
							<Text space="mls mts" size="sm" color="grey-11">
								{lang.subdomainText}
							</Text>
						</Column>
					</Row>
					<Row>
						<Column>
							<Label>{this.lang.reCaptchaKey}</Label>
							<Input
								placeholder={this.lang.reCaptchaKey}
								value={domain.reCaptchaKey || ''}
								onChange={e => this.updateDomainProp('reCaptchaKey', e.target.value)}
							/>
						</Column>
						<Column>
							<Label>{this.lang.reCaptchaSecret}</Label>
							<Input
								placeholder={this.lang.reCaptchaSecret}
								value={domain.reCaptchaSecret || ''}
								onChange={e => this.updateDomainProp('reCaptchaSecret', e.target.value)}
							/>
						</Column>
					</Row>
					<Help articleId={842} sidebar>
						<Block className="EditEventDomain__help">
							<Icon name="info-circle" />
							<Text>{lang.whatIsRecaptcha}</Text>
						</Block>
					</Help>
					{this.showGmapKey ? (
						<>
							<Row>
								<Column>
									<Block space="mtl" />
									<Label>{this.lang.gmapKey}</Label>
									<Input
										placeholder={this.lang.gmapKey}
										value={domain.gmapKey || ''}
										onChange={e => this.updateDomainProp('gmapKey', e.target.value)}
									/>
								</Column>
							</Row>
							<a
								href="https://developers.google.com/maps/documentation/embed/get-api-key#create-api-keys"
								target="_blank"
							>
								<Block className="EditEventDomain__help">
									<Icon name="info-circle" color="black" />
									<Text>{lang.howToGenerateGmapKey}</Text>
								</Block>
							</a>
						</>
					) : null}
				</ModalContent>
				<ModalControls>
					<Button
						color="bright-blue"
						shadow="none"
						onClick={this.save}
						loading={this.props.saving}
						disabled={!domain.name || !this.hasValidDomain()}
					>
						{lang.save}
					</Button>
					<Button color="grey" type="link" onClick={this.props.reject}>
						{lang.abort}
					</Button>
				</ModalControls>
			</div>
		);
	}
}

export const detached = EditEventDomain;

const ConnectedComponent = connector(EditEventDomain);

export const EditEventDomainModal = setupComponentCompatibility(ConnectedComponent);

export const openEditEventDomainModal = asyncModalAdapter({
	featureFlag: 'REACT_EDIT_EVENT_DOMAIN',
	upModalName: 'editEventDomain',
	openModalName: 'EditEventDomainModal'
});

export default ConnectedComponent;
