import Resource from 'Resources/Resource';

class Visit extends Resource {
	constructor() {
		super('visits');
	}
}

const resource = new Visit();

export default resource;
