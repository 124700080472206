import { Block, Card, CardContent, Checkbox, Flex, Paginator, Text, Title } from '@upsales/components';
import NameProspecting from 'Components/Modals/CreateNewAccount/Columns/NameProspecting';
import ProspectingClient from 'App/resources/Model/ProspectingClient';
import SoliditetClient from 'App/resources/Model/SoliditetClient';
import { useTranslation } from 'Components/Helpers/translate';
import ExistInUpsales from 'Components/Misc/ExistInUpsales';
import BemClass from '@upsales/components/Utils/bemClass';
import type Client from 'App/resources/Model/Client';
import React, { useEffect, useState } from 'react';

import './BranchOffices.scss';

const RESULT_LIMIT = 20;

type CompanyRowType = {
	company: ProspectingClient | SoliditetClient;
	selected: boolean;
	isProspecting: boolean;
	onSelectCompany: (branchCompany: ProspectingClient | SoliditetClient) => void;
};
const CompanyRow = ({ company, selected, onSelectCompany, isProspecting }: CompanyRowType) => (
	<Card border="ts" borderColor="grey-4">
		<CardContent>
			<Flex alignItems="center" gap="u3">
				<Flex alignItems="center">
					<Checkbox checked={selected} size="sm" onClick={() => onSelectCompany(company)} />
				</Flex>
				<Flex flex={1}>
					<NameProspecting account={company} noTable />
				</Flex>
				<Flex alignItems="center">
					{isProspecting && (company as ProspectingClient).matchInUpsales ? (
						<ExistInUpsales client={(company as ProspectingClient).matchInUpsales as any} space="mrl" />
					) : null}
				</Flex>
			</Flex>
		</CardContent>
	</Card>
);

type BranchOfficesProps = {
	selectedClients: Client[];
	hasPendingPurchase: boolean;
	unknowns: ProspectingClient[] | SoliditetClient[];
	onChange: (branches: ProspectingClient[] | SoliditetClient[]) => void;
	isProspecting: boolean;
	clientName: string;
};

const BranchOffices = ({
	selectedClients,
	hasPendingPurchase,
	unknowns,
	onChange,
	isProspecting,
	clientName
}: BranchOfficesProps) => {
	const classes = new BemClass('BranchOffices');
	const { t } = useTranslation();
	const [selectedBranches, setSelectedBranches] = useState<ProspectingClient[] | SoliditetClient[]>([]);
	const [branchOfficesOffset, setBranchOfficesOffset] = useState(0);
	const [branchOffices, setBranchOffices] = useState<ProspectingClient[] | SoliditetClient[]>(unknowns || []);

	const isSameBranch = (branchA: ProspectingClient | SoliditetClient, branchB: ProspectingClient | SoliditetClient) =>
		isProspecting
			? (branchA as ProspectingClient).prospectingId === (branchB as ProspectingClient).prospectingId
			: (branchA as SoliditetClient).dunsNo === (branchB as SoliditetClient).dunsNo;

	const getTypedBranches = (branches: (ProspectingClient | SoliditetClient)[]) => {
		if (isProspecting) {
			return branches as ProspectingClient[];
		}
		return branches as SoliditetClient[];
	};

	// Need this to make typescript happy
	const findInBranches = (
		branches: (ProspectingClient | SoliditetClient)[],
		branch: ProspectingClient | SoliditetClient
	) => {
		return branches.find(b => isSameBranch(b, branch));
	};

	// Normal filter transforms the array to (ProspectingClient | SoliditetClient)[] which is not the same as the original array
	const getProperlyTypedFilter = (
		branch: ProspectingClient[] | SoliditetClient[],
		filterFunc: (a: ProspectingClient | SoliditetClient) => boolean
	) => {
		const res = [];
		for (const item of branch) {
			if (filterFunc(item)) {
				res.push(item);
			}
		}
		return getTypedBranches(res);
	};

	const toggleBranch = (branch: ProspectingClient | SoliditetClient) => {
		const prevSelected = findInBranches(selectedBranches, branch);
		if (prevSelected) {
			setSelectedBranches(prev =>
				getProperlyTypedFilter(prev, branchItem => !isSameBranch(prevSelected, branchItem))
			);
		} else {
			setSelectedBranches(prev => getTypedBranches([...prev, branch]));
		}
	};

	const toggleAll = () => {
		if (selectedBranches.length === branchOffices.length) {
			setSelectedBranches([]);
		} else {
			setSelectedBranches(branchOffices);
		}
	};

	useEffect(() => {
		const refreshBranchOffices = () => {
			const availableOffices = getProperlyTypedFilter(
				unknowns ?? [],
				office => !(office as ProspectingClient).matchInUpsales?.operationalAccount && !hasPendingPurchase
			);
			let typedOffices = [];
			if (isProspecting) {
				typedOffices = availableOffices as ProspectingClient[];
			} else {
				typedOffices = availableOffices as SoliditetClient[];
			}
			setBranchOffices(typedOffices || []);
		};
		refreshBranchOffices();
	}, [unknowns, hasPendingPurchase]);

	useEffect(() => {
		const refreshSelected = () => {
			const newSelecteds: ProspectingClient[] = [];
			const toRemove: ProspectingClient[] = [];

			// Prospecting branches can have match in upsales
			for (const client of selectedClients) {
				const selectBranch = (branchOffices as ProspectingClient[]).find(
					branch => branch.matchInUpsales?.id === client.id
				) as ProspectingClient;
				if (
					selectBranch &&
					!(selectedBranches as ProspectingClient[]).find(
						branch => branch.prospectingId === selectBranch.prospectingId
					)
				) {
					newSelecteds.push(selectBranch);
				}
			}
			for (const branch of selectedBranches) {
				const typedBranch = branch as ProspectingClient;
				if (
					typedBranch.matchInUpsales &&
					!selectedClients.find(({ id }) => id === typedBranch.matchInUpsales?.id)
				) {
					toRemove.push(typedBranch);
				}
			}

			setSelectedBranches(prev =>
				getProperlyTypedFilter(
					prev,
					branchItem =>
						!(branchItem as ProspectingClient).matchInUpsales ||
						!toRemove.some(
							e => e.matchInUpsales?.id === (branchItem as ProspectingClient).matchInUpsales?.id
						)
				)
			);
		};
		if (isProspecting) {
			refreshSelected();
		}
	}, [selectedClients, isProspecting]);

	useEffect(() => {
		onChange(selectedBranches);
	}, [selectedBranches]);

	return branchOffices?.length ? (
		<>
			<Title space="mtxl mbm">
				{branchOffices.length}
				{branchOffices.length === 1
					? t('addSubaccount.branchOffice.one')
					: t('addSubaccount.branchOffice.many')}
				{clientName}
			</Title>
			<Block className={classes.b()} borderRadius border="ts ls rs" borderColor="grey-4">
				<Card space="pll ptm pbm" border="bm" borderColor="grey-4">
					<Flex alignItems="center" space="mls" gap="u4">
						<Checkbox
							size="xs"
							checked={selectedBranches.length === branchOffices.length}
							half={selectedBranches.length > 0 && selectedBranches.length < branchOffices.length}
							onClick={toggleAll}
						/>
						<Text size="sm">{t('account.branches')}</Text>
					</Flex>
				</Card>
				{branchOffices.slice(branchOfficesOffset, branchOfficesOffset + RESULT_LIMIT).map(branchOffice => (
					<CompanyRow
						key={
							isProspecting
								? (branchOffice as ProspectingClient).prospectingId
								: (branchOffice as SoliditetClient).dunsNo
						}
						company={branchOffice}
						onSelectCompany={toggleBranch}
						isProspecting={isProspecting}
						selected={selectedBranches.some(branch => isSameBranch(branch, branchOffice))}
					/>
				))}
			</Block>
			{branchOffices.length > RESULT_LIMIT ? (
				<Block space="mtm">
					<Paginator
						limit={RESULT_LIMIT}
						offset={branchOfficesOffset}
						total={branchOffices.length}
						onChange={offset => {
							setBranchOfficesOffset(offset);
						}}
					/>
				</Block>
			) : null}
		</>
	) : null;
};

export default BranchOffices;
