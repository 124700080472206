import React, { useMemo } from 'react';
import { Headline, Text, Block, Row, Column, Icon, IconName } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import { currencyFormat, numberFormat } from 'App/babel/components/Filters/Currencies';
import { SlideFade } from 'App/components/animations';

import './TopList.scss';

type Props = {
	next: () => void;
	companiesSoldTo: number;
	createdPipeline: { value: number; currency: string };
	meetings: number;
	calls: number;
	visible: boolean;
};

export const Item: React.FC<{
	visible: boolean;
	classes: bemClass;
	icon: IconName;
	title: string;
	value: string;
	delay?: number;
}> = ({ visible, classes, icon, title, value, delay = 0 }) => {
	return (
		<SlideFade visible={visible} delayInMs={delay} direction="top">
			<Block className={classes.elem('item').b()} space="mbxl">
				<Row>
					<Column fixedWidth={64}>
						<Icon name={icon} />
					</Column>
					<Column>
						<Text size="xl" color="grey-11">
							{title}
						</Text>
						<Headline size="sm">{value}</Headline>
					</Column>
				</Row>
			</Block>
		</SlideFade>
	);
};

const delay = 1000;

const TopList: React.FC<Props> = ({ companiesSoldTo, next, createdPipeline, meetings, calls, visible }) => {
	const classNames = useMemo(() => new bemClass('TopList'), []);

	return (
		<div className={classNames.b()} onClick={next}>
			<div className={classNames.elem('content').b()}>
				<Block space="mbxl">
					<Headline size="md">{T('yourYearWithUpsales.itsBeenABusyYear')}</Headline>
				</Block>

				<Item
					visible={visible}
					classes={classNames}
					icon="home"
					title={T('yourYearWithUpsales.numberOfCompaniesSoldTo')}
					value={numberFormat(companiesSoldTo)}
					delay={delay * 1}
				/>
				<Item
					visible={visible}
					classes={classNames}
					icon="opportunity"
					title={T('yourYearWithUpsales.createdAmmountInPipeline')}
					value={currencyFormat(createdPipeline.value, createdPipeline.currency)}
					delay={delay * 2}
				/>
				<Item
					visible={visible}
					classes={classNames}
					icon="appointment"
					title={T('yourYearWithUpsales.numberOfMeetings')}
					value={numberFormat(meetings)}
					delay={delay * 3}
				/>
				<Item
					visible={visible}
					classes={classNames}
					icon="phone"
					title={T('yourYearWithUpsales.numberOfCalls')}
					value={numberFormat(calls)}
					delay={delay * 4}
				/>
			</div>
			<img className={classNames.elem('trophies').b()} src="img/yourYearWithUpsales/trophies.svg" />
		</div>
	);
};

export default TopList;
