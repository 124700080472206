import React from 'react';
import { Title, Button, Icon, Loader, Text } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import FileBrowserHoc from './FileBrowserHoc';
import './FileBrowser.scss';
import FileBrowserFilters from './FileBrowserFilters';
import FileBrowserFiles from './FileBrowserFiles';
import FileBrowserLabel from './FileBrowserLabel';
import File from 'App/resources/Model/File';
import Label from 'App/resources/Model/Label';
import { BasicUserWithPermissions as User } from 'App/resources/Model/User';
import Tracker from 'App/babel/helpers/Tracker';

import FileUpload, { FileUploadDropzoneActive, FileUploadDropzone } from 'Components/FileUpload';
import FileBrowserLabels from './FileBrowserLabels';
import { asyncModalAdapter, setupComponentCompatibility } from 'App/helpers/angularPortingHelpers';

interface FileBrowserProps {
	imageOnly: boolean;
	searchString: string;
	onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
	files: File[];
	onDeleteFile: (file: File) => void;
	onSelectFile: (file: File) => void;
	onClose: () => void;
	onUploadFile: <File>(files: File[]) => void;
	selectedUser?: User;
	changeUserFilter: (user: User) => void;
	onSortChange: (sort: { field: string; asc: boolean }) => void;
	currentSort: string | null;
	currentDirection: boolean;
	sortTypes: { name: string; id: string }[];
	loading: boolean;
	uploadedFiles: File[];
	onScrollContent: (e: React.UIEvent<HTMLElement>) => void;
	labels: Label[];
	onSaveLabel: (label: Label) => void;
	onDeleteLabel: (label: Label) => void;
	onAddFileLabel: (files: File[], label: Label | []) => void;
	selectedLabel: Label;
	onSelectLabel: (label: Label) => void;
	standardLabels: Label[];
	unlabeled: Label;
	allImagesLabel: Label;
	tracker: Tracker;
}

export const acceptedFileTypes = {
	documents: [
		'.pdf',
		'.doc',
		'.docx',
		'.txt',
		'.zip',
		'.xml',
		'.xls',
		'.xlsx',
		'.ppt',
		'.pptx',
		'.ods',
		'.odt',
		'.odp',
		'.rtf'
	],
	images: ['.jpe', '.jpg', '.jpeg', '.png', '.gif']
};

class FileBrowser extends React.PureComponent<FileBrowserProps> {
	lang: { [key: string]: string };
	constructor(props: FileBrowserProps) {
		super(props);

		const t = getAngularModule('$translate');

		this.lang = {
			pickFile: t('file.selectFile'),
			pickImage: t('mail.selectImage'),
			multipleFiles: t('file.files'),
			all: t('default.all'),
			documents: t('default.document'),
			images: t('default.images'),
			fileTypeDropdownPlaceholder: t('file.allFileTypes'),
			sortDropdownPlaceholder: t('default.latest') + ' ' + t('file.uploadedDate').toLowerCase(),
			recentUploaded: t('default.recently') + ' ' + t('file.uploadedPlural').toLowerCase(),
			isUploading: t('esign.uploading'),
			directionDropdownPlaceholder: t('file.sort.change'),
			uploadImage: t('default.uploadImage'),
			uploadFile: t('default.uploadFile'),
			standardLabel: t('file.label.standard')
		};
	}
	render() {
		const {
			imageOnly,
			searchString,
			onSearch,
			files,
			onDeleteFile,
			onSelectFile,
			onClose,
			onUploadFile,
			selectedUser,
			changeUserFilter,
			onSortChange,
			currentSort,
			currentDirection,
			sortTypes,
			loading,
			uploadedFiles,
			onScrollContent,
			labels,
			onSaveLabel,
			onDeleteLabel,
			onAddFileLabel,
			selectedLabel,
			onSelectLabel,
			standardLabels,
			unlabeled,
			allImagesLabel,
			tracker
		} = this.props;
		const classes = new bemClass('FileBrowser');
		const hasFileLabel = Tools.FeatureHelper.hasSoftDeployAccess('FILE_METADATA_LABEL');
		return (
			<div className={classes.b()}>
				<div className={classes.elem('header').b()}>
					<Title>{this.lang[imageOnly ? 'pickImage' : 'pickFile']}</Title>
					<Button type="link" color="grey" onClick={onClose} className={classes.elem('close').b()}>
						<Icon name="times" />
					</Button>
				</div>
				<div className={classes.elem('sidebar').b()}>
					<FileBrowserFilters
						onSearch={onSearch}
						onSortChange={onSortChange}
						changeUserFilter={changeUserFilter}
						searchString={searchString}
						selectedUser={selectedUser}
						currentSort={currentSort}
						sortTypes={sortTypes}
					/>
					{hasFileLabel ? (
						<div>
							<FileBrowserLabels
								onDeleteLabel={onDeleteLabel}
								onSaveLabel={onSaveLabel}
								onSelectLabel={onSelectLabel}
								labels={labels}
								filterLabel={selectedLabel}
								entityType={'FileMetadata'}
								unlabeled={unlabeled}
								allEntitiesLabel={allImagesLabel}
								tracker={tracker}
							></FileBrowserLabels>
							<Text color="grey-10" size="sm" className={classes.elem('standardLabels').b()}>
								{this.lang.standardLabel}{' '}
							</Text>
							{standardLabels.map(standardLabel => (
								<FileBrowserLabel
									key={standardLabel.type}
									onSelect={() => onSelectLabel(standardLabel)}
									title={standardLabel.name}
									selected={selectedLabel?.type === standardLabel.type}
									numEntities={standardLabel.numEntities}
								/>
							))}
						</div>
					) : null}
				</div>
				<div className={classes.elem('content').b()} onScroll={onScrollContent}>
					<FileUpload onDrop={onUploadFile} accept={acceptedFileTypes[imageOnly ? 'images' : 'documents']}>
						{({ open, isDragActive }) => (
							<div>
								<FileUploadDropzoneActive visible={isDragActive} />
								<FileUploadDropzone
									onClick={open}
									title={this.lang[imageOnly ? 'uploadImage' : 'uploadFile']}
								/>
							</div>
						)}
					</FileUpload>

					<FileBrowserFiles
						uploadedFiles={uploadedFiles}
						files={files}
						imageOnly={imageOnly}
						onDelete={onDeleteFile}
						onSelect={onSelectFile}
						onSortChange={onSortChange}
						currentSort={currentSort}
						currentDirection={currentDirection}
						loading={loading}
						labels={labels}
						onAddFileLabel={onAddFileLabel}
						selectedLabel={selectedLabel}
					/>
				</div>
				{loading ? <Loader className={classes.elem('loader').b()} /> : null}
			</div>
		);
	}
}

const FileBrowserComponent = FileBrowserHoc(FileBrowser);

export const FileBrowserModal = setupComponentCompatibility(FileBrowserComponent, {
	modalName: 'FileBrowserModal',
	modalParamsMapper: $modalParams => ({
		...$modalParams
	})
});

export const openFileBrowserModal = asyncModalAdapter({
	upModalName: 'fileBrowser',
	openModalName: 'FileBrowserModal',
	featureFlag: 'REACT_FILE_BROWSER_MODAL'
});

export default FileBrowserComponent;
