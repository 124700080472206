import React, { useEffect, useRef, useState, useContext } from 'react';
import ReportcenterWidget from '../ReportcenterWidget';
import ReportWidget, { RCWidgetData, RCWidgetMeta } from 'Resources/ReportWidget';
import { CancelablePromise, makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import logError from 'Helpers/logError';
import { RCDashboardWidget } from 'Resources/ReportDashboard';
import { getQueriableFilters } from 'App/babel/store/actions/ReportcenterActions';

type WidgetMetaState = {
	meta: {
		[key: string]: RCWidgetMeta;
	};
	loading: boolean;
	error: boolean;
};

export const WidgetMetaContext = React.createContext<WidgetMetaState | null>(null);

export function WidgetMetaProvider({ children }: { children: React.ReactNode }) {
	const initialState = {
		meta: {},
		loading: true,
		error: false
	};

	const [state, setState] = useState<WidgetMetaState>(initialState);

	useEffect(() => {
		const cancelablePromsie = makeCancelable(ReportWidget.getMeta());
		cancelablePromsie.promise
			.then(({ data: meta }) => {
				setState({ ...state, meta, loading: false });
			})
			.catch(e => {
				logError(e, 'WidgetMetaProvider: Failed to load widget meta');
				setState({ ...state, error: true, loading: false });
			});

		return () => cancelablePromsie.cancel();
	}, []);

	return <WidgetMetaContext.Provider value={state}>{children}</WidgetMetaContext.Provider>;
}

type WidgetDataProps = {
	config: RCDashboardWidget;
	onDataFetched?: (data: RCWidgetData) => void;
	drilldown?: boolean;
	hideHeader?: boolean;
};

type WidgetDataState = {
	data: RCWidgetData;
	loading: boolean;
	error: boolean;
};

const ReportcenterWidgetWithFetch = ({
	config,
	onDataFetched,
	drilldown = false,
	hideHeader = false
}: WidgetDataProps) => {
	const widgetMeta = useContext(WidgetMetaContext) as WidgetMetaState;
	const fetcherData = useRef<CancelablePromise<{ data: RCWidgetData }> | null>(null);
	const [widgetData, setWidgetData] = useState<WidgetDataState>({
		data: {} as RCWidgetData,
		loading: true,
		error: false
	});

	const getWidgetData = () => {
		fetcherData.current?.cancel(); // So we prevent request race conditions between faster and slower requests

		fetcherData.current = makeCancelable(
			ReportWidget.find(config.type, {
				q: getQueriableFilters(config.filters),
				valueType: config.valueType,
				aggregateSubAccounts: config.aggregateSubAccounts,
				hideNoData: config.hideNoData,
				showAllRisks: config.showAllRisks,
				grouping: config.groupBy?.length ? config.groupBy : undefined
			})
		);

		fetcherData.current.promise
			.then(({ data }) => {
				setWidgetData({ ...widgetData, loading: false, data });
				onDataFetched?.(data);
			})
			.catch(e => {
				logError(e, 'Failed to load widget data');
				setWidgetData({ ...widgetData, loading: false, error: true });
			});
	};

	useEffect(() => () => fetcherData.current?.cancel(), []);

	return (
		<ReportcenterWidget
			widgetConfig={config}
			drilldown={drilldown}
			widgetData={{
				data: widgetData.data,
				loading: widgetData.loading || widgetMeta.loading,
				error: widgetData.error || widgetMeta.error
			}}
			getWidgetData={getWidgetData}
			widgetMetaObj={widgetMeta.meta}
			hideHeader={hideHeader}
		/>
	);
};

export default (props: WidgetDataProps) => {
	const context = useContext(WidgetMetaContext);

	if (context === null) {
		return (
			<WidgetMetaProvider>
				<ReportcenterWidgetWithFetch {...props} />
			</WidgetMetaProvider>
		);
	} else {
		return <ReportcenterWidgetWithFetch {...props} />;
	}
};
