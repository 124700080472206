import BillingAddonBanner from 'App/components/BillingAddonBanner';
import React, { ComponentProps } from 'react';
import { Block } from '@upsales/components';
import { NEXT_STEPS } from 'Components/Billing/BillingAddonCard';
import { useTranslation } from 'Components/Helpers/translate';

type Props = ComponentProps<typeof Block> & {
	riskCustomerAmount: number;
};

export default function CustomerPortfolioAddonBanner({ riskCustomerAmount, ...blockProps }: Props) {
	const { t } = useTranslation();
	const addonAlias = 'PROSPECTING_PRO_SIGNALS';

	return (
		<Block {...blockProps}>
			<Block />
			<img src="img/BerntoBlobb.svg" />
			<BillingAddonBanner
				alias={addonAlias}
				loading={false}
				chipText={t('admin.billing.addon.PROSPECTING_PRO.name')}
				title={
					riskCustomerAmount > 0
						? t('customerPortfolio.plgWithRisks.title', { count: riskCustomerAmount })
						: t('customerPortfolio.plgWithoutRisks.title')
				}
				text={
					riskCustomerAmount > 0
						? t('customerPortfolio.plgWithRisks.text')
						: t('customerPortfolio.plgWithoutRisks.text')
				}
				location="customerPortfolio"
				nextSteps={NEXT_STEPS[addonAlias]}
			/>
		</Block>
	);
}
