import BemClass from '@upsales/components/Utils/bemClass';
import ClientAttributes from 'App/babel/attributes/Client';
import ClientResource from 'App/resources/Client';
import React, { useMemo, useState, useRef } from 'react';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import T from 'Components/Helpers/translate';
import logError from 'App/babel/helpers/logError';
import openModal, { shouldOpenModal } from 'App/services/Modal';
import { AccountManagers, ClientColumnTitle, ClientColumnSubtitle } from './columnParts';
import {
	Card,
	CardContent,
	Link,
	Block,
	SelectAsync,
	Label,
	Row,
	Column,
	Flex,
	Tooltip,
	Text,
	ClickableItem,
	StateFrame
} from '@upsales/components';
import { PrimaryButton } from '@upsales/components/Buttons';
import { findAndRemoveItem } from 'Store/helpers/array';
import { makeCancelable } from 'App/babel/helpers/promise';
import { useModalClose } from 'App/components/Modals/Modals';
import type Client from 'App/resources/Model/Client';
import type { DrawerClient } from '../AddSubaccount';
import { useSelector } from 'App/components/hooks';
import ProspectingClient from 'App/resources/Model/ProspectingClient';
import { addManyAccounts } from 'Store/reducers/AccountReducer';
import { connect } from 'react-redux';
import BranchOffices from './BranchOffices';
import PendingPurchaseBanner, { PendingPurchaseType } from './PendingPurchaseBanner';
import SoliditetClient from 'App/resources/Model/SoliditetClient';
import SubaccountHelp from 'Components/SubaccountHelp/SubaccountHelp';
import BackButton from 'Components/CreateRelation/BackButton';
import Preview from './Preview';

import './Add.scss';

type Props = {
	client: DrawerClient;
	closeDrawer: () => void;
	modalId: number;
	goBack: () => void;
	subaccounts: Pick<Client, 'id' | 'name'>[];
	addBranches: (branches: SoliditetClient[] | ProspectingClient[], operationalAccountId: number) => Promise<boolean>;
	pendingPurchase?: PendingPurchaseType | null;
};

type SelectableClient = Client & { title: string };
type TemplateOption = { id: number; title: string } & { children: SelectableClient[] };

const RESULT_LIMIT = 25;

const getTemplateOptions = (clients: Client[]) => {
	if (clients === null || clients.length === 0) {
		return [];
	}

	const clientsWithProspectingId = clients
		.filter(client => client.prospectingId)
		.map((client: Client) => ({
			...client,
			title: client.name,
			disabled: true
		}));

	const clientsWithoutProspectingId = clients
		.filter(client => !client.prospectingId)
		.map((client: Client) => ({
			...client,
			title: client.name,
			disabled: false
		}));

	const arrayOfClients: TemplateOption[] = [];

	if (clientsWithoutProspectingId.length > 0) {
		arrayOfClients.push({
			id: 1,
			title: T('createRelation.subaccount.optionHeaderTitle1'),
			children: clientsWithoutProspectingId
		});
	}

	if (clientsWithProspectingId.length > 0) {
		arrayOfClients.push({
			id: 2,
			title: T('createRelation.subaccount.optionHeaderTitle2'),
			children: clientsWithProspectingId
		});
	}

	return arrayOfClients;
};

const getFetcher =
	(clientIds: number[]) =>
	(searchString?: string): Promise<TemplateOption[]> => {
		const filter = new RequestBuilder();

		filter.addFilter(ClientAttributes.active, comparisonTypes.Equals, 1);
		filter.addFilter(ClientAttributes.id, comparisonTypes.NotEquals, clientIds);
		filter.addFilter(ClientAttributes.operationalAccount.attr.id, comparisonTypes.Equals, null);
		const orBuilder = filter.orBuilder();
		orBuilder.next();
		orBuilder.addFilter(ClientAttributes.numberOfSubaccounts, comparisonTypes.Equals, 0);
		orBuilder.next();
		orBuilder.addFilter(ClientAttributes.numberOfSubaccounts, comparisonTypes.Equals, null);
		orBuilder.done();

		if (searchString) {
			filter.addFilter(ClientAttributes.name, comparisonTypes.Search, searchString);
		}

		filter.addSort(ClientAttributes.name, true);

		filter.limit = RESULT_LIMIT;

		return ClientResource.find(filter.build()).then(({ data }) => {
			return getTemplateOptions(data);
		});
	};

const getSaveButtonText = (
	selectedClients: Client[],
	selectedBranches: ProspectingClient[] | SoliditetClient[],
	lang: { save: string; saveOne: string }
) => {
	const keySet = new Set<number | string>(selectedClients.map(({ id }) => id));
	selectedBranches?.forEach(branch => {
		const matchInUpsales = (branch as ProspectingClient).matchInUpsales;
		if (matchInUpsales) {
			keySet.add(matchInUpsales.id);
			return;
		}
		const keyId = branch.hasOwnProperty('prospectingId')
			? (branch as ProspectingClient).prospectingId
			: (branch as SoliditetClient).dunsNo;
		keySet.add(keyId);
	});
	switch (keySet.size) {
		case 0:
			return lang.save;
		case 1:
			return lang.saveOne;
		default:
			return T('SubaccountDrawer.Add.saveMany', { count: keySet.size });
	}
};

const mapDispatchToProps = { addBranches: addManyAccounts };

// This code is taken and modified from ui/app/babel/components/SubaccountDrawer/Add.tsx and its imported components
// The subaccount drawer will be depricated in the future due to a new version of the subaccounts but since a customer is using the first version
// unitl the second is finished I copied the code to make the changes needed to add the new features and after that we can remve the enite subaccountDrawer folder
const AddSubaccount = ({ modalId, closeDrawer, client, subaccounts, goBack, addBranches, pendingPurchase }: Props) => {
	const { unknowns, unbought, config } = useSelector(state => state.Account);
	const isProspecting = config?.id === 'prospecting';

	const classes = new BemClass('Add');
	const lang = useMemo(
		() => ({
			chooseCompany: T('createRelation.subaccount.addSubbaccount'),
			select: T('select_account'),
			clickToDeselect: T('activity.outcome.clickToDeselect'),
			save: T('SubaccountDrawer.Add.save'),
			saveOne: T('SubaccountDrawer.Add.saveOne'),
			createAccount: T('default.newCustomers'),
			typeToSearch: T('default.typeToSearch'),
			backButton: T('createRelation.subaccount.backButton', { companyName: client.name }),
			preview: T('createRelation.subaccount.preview'),
			backButtonPreview: T('createRelation.subaccount.backButtonPreview'),
			noResults: T('default.noResults'),
			branchOffices: T('default.branchOffices'),
			stateFrameTitle: T('createRelation.subaccount.optionStateFrame'),
			inputLanguage: {
				typeToSearch: T('default.typeToSearch')
			}
		}),
		[]
	);
	const [selectedClients, setSelectedClients] = useState<SelectableClient[]>([]);
	const [selectedBranches, setSelectedBranches] = useState<ProspectingClient[] | SoliditetClient[]>([]);
	const [saving, setSaving] = useState<boolean>(false);
	const [showPreview, setShowPreview] = useState<boolean>(false);

	const cancelRef = useRef<(() => void) | null>(null);
	const isSavingBranches = useRef(false);
	const inputRef = useRef<HTMLInputElement>(null);

	const addSubaccounts = async () => {
		if (
			selectedBranches.length &&
			selectedBranches.some(branch =>
				unbought.includes(
					isProspecting ? (branch as ProspectingClient).prospectingId : (branch as SoliditetClient).dunsNo
				)
			)
		) {
			isSavingBranches.current = true;
			const addedBranches = await addBranches(selectedBranches, client.id);
			// it means that the did not opt for adding the companies from prospecting
			if (!addedBranches) {
				return;
			}
		}

		const clients = [...selectedClients];
		const clientsSet = new Set(selectedClients.map(({ id }) => id));
		if (selectedBranches.length) {
			for (const branch of selectedBranches) {
				if (
					branch.hasOwnProperty('matchInUpsales') &&
					branch.matchInUpsales &&
					!clientsSet.has(branch.matchInUpsales.id)
				) {
					clientsSet.add(branch.matchInUpsales.id);
					clients.push({ ...branch.matchInUpsales, title: branch.name } as SelectableClient);
				}
			}
		}
		if (!clients.length) {
			goBack();
		}
		// We do not want to spam notifications if multiple accounts are added
		const promises = Array.from(clientsSet).map(id =>
			ClientResource.save(
				{ id, operationalAccount: { id: client.id } },
				{
					skipEvent: true, // This becomes very spammy if you save multiple subaccounts, listen for account.subaccountsAdded instead.
					skipNotification: true // This becomes very spammy if you save multiple subaccounts, not really needed as we have the "banner notification".
				}
			)
		);
		const { promise, cancel } = makeCancelable(Promise.all(promises));
		cancelRef.current = cancel;
		promise
			.then(() => {
				closeDrawer();
				Tools.$rootScope.$broadcast('account.subaccountsAdded', { clients: clients });
			})
			.catch(error => {
				logError(error, 'Failed to add accounts as subaccounts.');
				setSaving(false);
			});

		setSaving(true);
	};

	useModalClose(
		modalId,
		event => {
			event.preventDefault();

			if (selectedClients.length > 0 && !saving) {
				const body =
					selectedClients.length === 1
						? T('SubaccountDrawer.Add.unsavedSubaccountsOne')
						: T('SubaccountDrawer.Add.unsavedSubaccountsMany');

				openModal('UnsavedChangesAlert', {
					body,
					confirmButtonText: T('default.goBack'),
					onClose: (confirmed?: boolean) => {
						if (confirmed || confirmed === undefined) {
							return;
						}
						closeDrawer();
					}
				});
			} else {
				closeDrawer();
			}
		},
		[selectedClients, saving]
	);

	const deselectClient = (clientId: number) => {
		const result = findAndRemoveItem(selectedClients, { id: clientId });
		setSelectedClients(result);
	};

	const selectClient = (selectedClient: SelectableClient) => {
		const alreadySelected = selectedClients.some(client => client.id === selectedClient.id);
		if (alreadySelected) {
			deselectClient(selectedClient.id);
			return;
		}
		setSelectedClients([selectedClient, ...selectedClients]);
	};

	const createAccount = () => {
		const onSave = (client?: Client) => {
			if (client && client.id && client.numberOfSubaccounts === 0) {
				selectClient({ ...client, title: client.name });
			}
		};

		if (shouldOpenModal('EditClient')) {
			openModal('EditClient', { onClose: onSave });
		} else {
			Tools.$upModal
				.open('editAccount', { fromModal: true })
				.then(client => {
					onSave(client);
				})
				.catch(error => {
					logError(error, 'Failed to open edit account modal.');
				});
		}
	};

	const allClientIdsToSkip = [client.id, ...subaccounts.map(client => client.id)];
	const saveButtonText = getSaveButtonText(selectedClients, selectedBranches, lang);

	return (
		<Flex className={classes.b()} direction="column" justifyContent="space-between">
			<Block className={classes.elem('content').b()}>
				<BackButton
					buttonText={showPreview ? lang.backButtonPreview : lang.backButton}
					classes={classes}
					onClick={showPreview ? () => setShowPreview(false) : goBack}
				/>
				{showPreview ? (
					<Flex direction="column">
						{selectedClients.length || selectedBranches.length ? (
							<Preview selectedClients={selectedClients} selectedBranches={selectedBranches} />
						) : null}
						<SubaccountHelp />
					</Flex>
				) : (
					<Block>
						<PendingPurchaseBanner pendingPurchase={pendingPurchase} space="mtl" />
						<Block space="mtxl">
							<Flex justifyContent="space-between">
								<Label>{lang.chooseCompany}</Label>
								<Link onClick={() => createAccount()}>{lang.createAccount}</Link>
							</Flex>
							<SelectAsync
								multi
								hideSelected={false}
								keepOpenOnSelect
								anchor={'.Add__content'}
								disabled={saving}
								// Seems like the typing is wrong from the UI library. Can't get this to work.
								// @ts-ignore
								fetcher={getFetcher(allClientIdsToSkip)}
								value={selectedClients?.map(c => ({ ...c, title: c.name })) ?? []}
								inputRef={inputRef}
								onChange={selectClient}
								placeholder={lang.select}
								keepSearchOnBlur
								optionHeaderType="disabled"
								renderItem={client => (
									<Flex
										justifyContent="space-between"
										space="pts pbs"
										className={classes.elem('selectCustomRow').b()}
									>
										<Flex direction="column" gap="u1">
											<ClientColumnTitle client={client} />
											<ClientColumnSubtitle client={client} />
										</Flex>
										<Flex alignItems="center">
											<AccountManagers users={client.users} />
										</Flex>
									</Flex>
								)}
								language={lang.inputLanguage}
								renderCustomNoResults={() => (
									<Block>
										{(inputRef.current?.value?.length ?? 0) > 1 ? (
											<Block space="ptm prm pbm plm">
												<Text color="grey-10">{lang.noResults}</Text>
											</Block>
										) : (
											<Block space="ptm prm pbm plm">
												<Text bold size="sm">
													{lang.typeToSearch}
												</Text>
											</Block>
										)}
										<Block space="ptl pll pbl prl">
											<StateFrame icon="info-circle" title={lang.stateFrameTitle} state="info" />
										</Block>
									</Block>
								)}
							/>
						</Block>
						<Block>
							{selectedClients.map(client => (
								<Card key={client.id} space="mtm" borderRadius borderColor="grey-4" border="bs">
									<CardContent>
										<Row>
											<Column>
												<Block>
													<ClientColumnTitle client={client} />
												</Block>
												<Block>
													<ClientColumnSubtitle client={client} />
												</Block>
											</Column>
											<Column fixedWidth={300}>
												<Flex alignItems="center" space="mrl mll">
													<AccountManagers users={client.users} />
												</Flex>
											</Column>
											<Column fixedWidth={50}>
												<Flex alignItems="center" justifyContent="end">
													<Tooltip distance={25} title={lang.clickToDeselect}>
														<ClickableItem
															borderRadius
															icon="times"
															onClick={() => deselectClient(client.id)}
															size="sm"
														/>
													</Tooltip>
												</Flex>
											</Column>
										</Row>
									</CardContent>
								</Card>
							))}
						</Block>
						{unknowns?.length ? (
							<BranchOffices
								clientName={client.name}
								isProspecting={isProspecting}
								hasPendingPurchase={!!pendingPurchase}
								unknowns={unknowns}
								selectedClients={selectedClients}
								onChange={branches => {
									const newClients: SelectableClient[] = [];
									const toRemove: Client[] = [];
									if (isProspecting) {
										// if the branch has a match in upsales then add it to the upper list
										for (const branch of branches) {
											if (
												branch.hasOwnProperty('matchInUpsales') &&
												branch.matchInUpsales &&
												!selectedClients.some(({ id }) => id === branch.matchInUpsales?.id)
											) {
												newClients.push({
													...branch.matchInUpsales,
													title: branch.name
												} as SelectableClient);
											}
										}
										// if we deselected a branch that it is a match in upsales then remove it from the upper list
										for (const selectedClient of selectedClients) {
											if (
												unknowns?.find(
													(unknown: ProspectingClient) =>
														unknown.matchInUpsales?.id === selectedClient.id
												) &&
												!(branches as ProspectingClient[]).find(
													({ matchInUpsales }) => matchInUpsales?.id === selectedClient.id
												)
											) {
												toRemove.push(selectedClient);
											}
										}
										if (newClients.length || toRemove.length) {
											const updatedList = selectedClients.filter(
												({ id }) => !toRemove.some(cr => cr.id === id)
											);
											setSelectedClients([...updatedList, ...newClients]);
										}
									}
									setSelectedBranches(branches);
								}}
							/>
						) : null}
					</Block>
				)}
			</Block>
			<Block
				backgroundColor="grey-1"
				border="ts"
				borderColor="grey-4"
				className={classes.elem('footer').b()}
				space="ptl pbl"
			>
				<Flex alignItems="center" justifyContent="center">
					<PrimaryButton
						disabled={(selectedClients.length === 0 && selectedBranches.length === 0) || saving}
						icon="check"
						onClick={showPreview ? addSubaccounts : () => setShowPreview(true)}
						size="lg"
					>
						{showPreview ? saveButtonText : lang.preview}
					</PrimaryButton>
				</Flex>
			</Block>
		</Flex>
	);
};
const Component = connect(null, mapDispatchToProps)(AddSubaccount);
export default Component;
