import React from 'react';
import { ButtonSelect, Block } from '@upsales/components';
import CheckableList from './CheckableList';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import UpSelect from 'Components/Inputs/UpSelect';
import PropTypes from 'prop-types';
import RoleSelect from 'Components/RoleSelect';
import { useUsers } from 'App/components/hooks/appHooks';

const UserRoleList = ({
	space,
	assignOneUser,
	usersOnly,
	selectedUsersRoles,
	setSelectedUsersRoles,
	assignmentType = 'users',
	changeAssignmentType,
	toggleUserRole,
	allAsDefault = false,
	asIds = true
}) => {
	// It might be the case that a deleted user still exists as a selected item in this dropdown.
	// Therefore, we need to get deleted user data here just in case.
	// However we don't allow selecting already deleted users, so we also keep a separate list of active users.

	const users = useUsers('active', false, false, false).map(({ id, name, email }) => ({
		id,
		name: name || T('todo.noName'),
		title: name,
		email
	}));

	// 2021-09-07, same problem as comment explained above but for non CMR users.
	// Has to include support users in all Users since users are either CRM or Support.
	const allUsers = [
		...useUsers('active', false, true, false).map(({ id, name, email }) => ({
			id,
			name: name || T('todo.noName'),
			title: name,
			email
		})),
		...useUsers('deleted', true, false, false).map(({ id, name }) => ({
			id,
			name: name || T('todo.noName'),
			title: name
		}))
	];

	const roles = Tools.AppService.getRoles();
	const showSelect = users.length > 5 || roles.length > 5;

	const isEqual = asIds ? (a, b) => a.id === b : (a, b) => a.id === b.id;

	const onUserSelectChange = event => {
		let selected = [...selectedUsersRoles];
		if (event.target.added) {
			const addedValue = asIds ? event.target.added.id : event.target.added;
			if (assignOneUser) {
				selected = [addedValue];
			} else {
				selected.push(addedValue);
			}
		} else if (event.target.removed) {
			const index = selected.findIndex(itemOrId => isEqual(event.target.removed, itemOrId));
			if (index > -1) {
				selected.splice(index, 1);
			}
		}

		setSelectedUsersRoles(selected);
	};
	const items = assignmentType === 'users' ? users : roles;
	const classes = new bemClass('UserRoleList');
	const hasTreeSelect = Tools.FeatureHelper.hasSoftDeployAccess('TREE_SELECT');
	const selectedRoles =
		Array.isArray(selectedUsersRoles) && selectedUsersRoles.length && typeof selectedUsersRoles[0] === 'string'
			? null
			: selectedUsersRoles.map(roleOrRoleId => roles.find(role => isEqual(role, roleOrRoleId)));

	return (
		<div className={classes.b()}>
			{usersOnly ? null : (
				<Block space="mbl">
					<ButtonSelect
						value={assignmentType}
						onChange={v => changeAssignmentType(v)}
						options={[
							{ value: 'users', title: T('default.users') },
							{ value: 'roles', title: T('default.roles') }
						]}
					/>
				</Block>
			)}
			<Block space={space}>
				{showSelect ? (
					assignmentType === 'users' ? (
						<UpSelect
							key={'user'}
							className="form-control"
							data={users}
							placeholder={allAsDefault ? T('admin.newSalesProcess.allUsers') : T('activity.searchUsers')}
							multiple={!assignOneUser}
							defaultValue={
								Array.isArray(selectedUsersRoles) &&
								selectedUsersRoles.length &&
								typeof selectedUsersRoles[0] === 'string'
									? null
									: assignOneUser
									? users.find(u => u.id === parseInt(selectedUsersRoles))
									: selectedUsersRoles.map(userOrUserId =>
											allUsers.find(user => isEqual(user, userOrUserId))
									  )
							}
							onChange={onUserSelectChange}
							storeIdInState={true}
						/>
					) : hasTreeSelect ? (
						<RoleSelect
							roles={roles}
							selectedRoles={selectedRoles}
							onChange={roles => setSelectedUsersRoles(asIds ? roles.map(r => r.id) : roles)}
						/>
					) : (
						<UpSelect
							key="role"
							className="form-control"
							data={roles}
							placeholder={allAsDefault ? T('admin.newSalesProcess.allRoles') : T('activity.searchRoles')}
							multiple={true}
							defaultValue={selectedRoles}
							onChange={onUserSelectChange}
							storeIdInState={true}
						/>
					)
				) : (
					<CheckableList
						multiple={!assignOneUser}
						onItemToggle={toggleUserRole}
						displayKey="name"
						valueKey="id"
						asIds={asIds}
						items={items}
						selectedItems={selectedUsersRoles}
					/>
				)}
			</Block>
		</div>
	);
};
UserRoleList.propTypes = {
	asIds: PropTypes.bool,
	usersOnly: PropTypes.bool,
	space: PropTypes.string,
	assignOneUser: PropTypes.bool,
	selectedUsersRoles: PropTypes.any,
	setSelectedUsersRoles: PropTypes.func,
	assignmentType: PropTypes.string,
	changeAssignmentType: PropTypes.func,
	toggleUserRole: PropTypes.func
};
export default UserRoleList;
