import { Action, Actions, State as IndexingState } from './SubscriptionIndexingActions';
import type IndexIncreaseSettings from 'App/resources/Model/IndexIncreaseSettings';
import IndexIncreaseSettingsResource from 'Resources/IndexIncreaseSettings';
import IndexIncreaseConfigResource from 'Resources/IndexIncreaseConfig';
import IndexConflictDecisionsResource from 'Resources/IndexConflictDecisions';
import Report, { ReportEntity } from 'App/resources/Report';
import Agreement from 'App/resources/Model/Agreement';
import AgreementResource from 'Resources/Agreement';
import { globalTracker } from 'Helpers/Tracker';
import logError from 'Helpers/logError';
import moment from 'moment';

export type State = IndexingState;

export default (dispatch: React.Dispatch<Action>) => {
	const setSubscriptionMeta = (totalIncreaseARR: number, subscriptionCount: number) =>
		dispatch({ type: Actions.SET_STATE, payload: { totalIncreaseARR, subscriptionCount } });

	/**
	 * get all the conflicts for the existing filters. If there is an existing decision for the agreement keep that value,
	 * if not just add a conflict with unhandled value
	 */
	const getIndexConflicts = async (
		filter: State['filter'],
		indexConflictDecisionsMap: State['indexConflictDecisionsMap'],
		id: State['id']
	) => {
		const noLimitFilter = { ...filter, limit: 0, f: ['id'] };
		const { data: conflicts } = await AgreementResource.getAgreementsWithConflicts(noLimitFilter, id);
		return conflicts.map(
			x =>
				indexConflictDecisionsMap[x.id] ?? {
					indexIncreaseId: id,
					agreementId: x.id,
					decision: 'unhandled'
				}
		);
	};

	const setFilterQ = (filter: State['filterQ']) => dispatch({ type: Actions.SET_FILTER_Q, payload: filter });

	/**
	 * @deprecated
	 */
	const getSettingsOld = async () => {
		const { data: initialState } = (await IndexIncreaseSettingsResource.get()) as { data: IndexIncreaseSettings };

		if (!initialState) {
			return;
		}

		dispatch({ type: Actions.SET_STATE, payload: { ...initialState } });
	};

	const getSubscriptionCount = async (
		filter: State['filter'],
		indexConflictDecisionsMap: State['indexConflictDecisionsMap'],
		id: State['id']
	) => {
		const conflictPromise = getIndexConflicts(filter, indexConflictDecisionsMap, id);

		const updatedFilter = { ...filter, q: [...(filter?.q ?? [])] };

		const countFilter = { ...updatedFilter };
		countFilter.limit = 0;
		const reportFilter: any = { ...updatedFilter };
		const listPromise = AgreementResource.find(countFilter);

		reportFilter.aggs = [
			{
				type: 'sum',
				field: 'yearlyValueInMasterCurrency',
				aggName: 'totalValue'
			}
		];
		const reportPromise = Report.find(ReportEntity.AGREEMENT, reportFilter) as Promise<{
			data: { totalValue: { value: number } };
		}>;

		Promise.all([listPromise, reportPromise, conflictPromise])
			.then(([list, report, conflicts]) => {
				const count = list.metadata.total;
				const value = report.data.totalValue.value;

				dispatch({ type: Actions.SET_SUBSCRIPTION_COUNT, payload: count });
				dispatch({ type: Actions.SET_SUBSCRIPTION_VALUE, payload: value });
				dispatch({
					type: Actions.SET_CONFLICTING_SUBSCRIPTIONS,
					payload: conflicts
				});
			})
			.catch(() => {
				dispatch({ type: Actions.SET_SUBSCRIPTION_COUNT, payload: 0 });
			});
	};

	/**
	 * @deprecated Use getSubscriptionCount instead or build a new function called getSubscriptionIds
	 */
	const getSubscriptions = async (filter: State['filter']) => {
		const fetchAndUpdate = async (filter: any) => {
			const { data: subscriptions } = await AgreementResource.find(filter);

			dispatch({ type: Actions.SET_SUBSCRIPTIONS, payload: subscriptions });
		};

		fetchAndUpdate(filter);
	};

	/**
	 * get an array with the excluded subscriptions from the decisions
	 * @param indexConflictDecisionsMap
	 * @returns
	 */
	const getSubscriptionFilterWithDecisions = (indexConflictDecisionsMap: State['indexConflictDecisionsMap']) => {
		return Object.values(indexConflictDecisionsMap)
			.filter(conflict => conflict.decision === 'exclude')
			.map(x => x.agreementId);
	};

	const setUiFilters = (uiFilters: State['uiFilters']) =>
		dispatch({ type: Actions.SET_UI_FILTER, payload: uiFilters });

	const doSave = async (state: State) => {
		dispatch({ type: Actions.TOGGLE_SAVING, payload: true });
		const { isSaving, isDirty, lastRunDate, indexConflictDecisionsMap, excludedSubscriptionIds, ...stateToSave } =
			state;
		const hasConflictDecisions = Tools.FeatureHelper.hasSoftDeployAccess('INDEXING_CONFLICT_HANDLING');

		const updatedFilter = { ...state.filter, q: [...state.filter.q] };
		let updatedExcludedSubscriptionIds = [...excludedSubscriptionIds];

		// if we have excluded decisions, exclude them in the filters
		const excludedFromDecisions = getSubscriptionFilterWithDecisions(indexConflictDecisionsMap);
		if (excludedFromDecisions.length) {
			updatedExcludedSubscriptionIds = updatedExcludedSubscriptionIds.concat(excludedFromDecisions);
			updatedFilter.q.push({ a: 'id', c: 'ne', v: excludedFromDecisions });
		}
		const setAsLocked = state.setAsLocked || (!state.setAsLocked && !state.isLocked);
		await IndexIncreaseConfigResource.save({
			...stateToSave,
			filter: updatedFilter,
			excludedSubscriptionIds: updatedExcludedSubscriptionIds,
			isLocked: state.isLocked || setAsLocked,
			setAsLocked: setAsLocked
		}).then(configRes => {
			if (hasConflictDecisions && configRes.data) {
				const { id } = configRes.data;
				const decisions = Object.values(indexConflictDecisionsMap).map(decision => ({
					...decision,
					indexIncreaseId: id
				}));
				return IndexConflictDecisionsResource.saveConflictDecisions(decisions);
			}
			return configRes.data;
		});

		globalTracker.track('saveIndexIncreaseSettings', {
			...stateToSave,
			lastRunDate,
			filter: null,
			numberOfAffectedSubscriptions: state.subscriptionCount ?? 0,
			accountFilter: {
				...state.accountFilter,
				count: state.accountFilter.values.length,
				values: undefined
			},
			subscriptionFilter: {
				...state.subscriptionFilter,
				count: state.subscriptionFilter.values.length,
				values: undefined
			}
		});

		dispatch({ type: Actions.TOGGLE_SAVING, payload: false });
		dispatch({ type: Actions.TOGGLE_DIRTY, payload: false });
	};

	const setIsLoadingSubscriptions = (isLoading: boolean) =>
		dispatch({ type: Actions.SET_IS_LOADING_SUBSCRIPTIONS, payload: isLoading });

	const setHasConflicts = (isLoading: boolean) => dispatch({ type: Actions.SET_HAS_CONFLICTS, payload: isLoading });

	/**
	 * @deprecated
	 */
	const doSaveOld = async (state: State) => {
		dispatch({ type: Actions.TOGGLE_SAVING, payload: true });

		const { isSaving, isDirty, subscriptions, lastRunDate, ...stateToSave } = state;

		await IndexIncreaseSettingsResource.save({
			...stateToSave
		});

		globalTracker.track('saveIndexIncreaseSettings', {
			...stateToSave,
			lastRunDate,
			filter: null,
			numberOfAffectedSubscriptions: subscriptions?.length ?? 0,
			accountFilter: {
				...state.accountFilter,
				count: state.accountFilter.values.length,
				values: undefined
			},
			subscriptionFilter: {
				...state.subscriptionFilter,
				count: state.subscriptionFilter.values.length,
				values: undefined
			}
		});

		dispatch({ type: Actions.TOGGLE_SAVING, payload: false });
		dispatch({ type: Actions.TOGGLE_DIRTY, payload: false });
	};

	const toggleIndexingEnabled = (toggled: boolean) =>
		dispatch({ type: Actions.TOGGLE_INDEXING_ENABLED, payload: toggled });

	const excludeSubscriptionId = (subscriptionId: number) => {
		dispatch({ type: Actions.EXCLUDE_SUBSCRIPTION_ID, payload: subscriptionId });
	};

	const includeSubscriptionId = (subscriptionId: number) => {
		dispatch({ type: Actions.INCLUDE_SUBSCRIPTION_ID, payload: subscriptionId });
	};

	const setExcludingSubscriptions = (excluded: boolean) => {
		dispatch({ type: Actions.SET_EXCLUDING_SUBSCRIPTIONS, payload: excluded });
	};

	const excludeAllConflicts = async (filter: State['filter']) => {
		setExcludingSubscriptions(true);
		try {
			const fullFilter = { ...filter, limit: 0, f: ['id'] };
			const { data: subscriptions } = await AgreementResource.getAgreementsWithConflicts(fullFilter);
			const ids = (subscriptions ?? []).map(x => x.id);
			dispatch({ type: Actions.EXCLUDE_MULTIPLE_SUBSCRIPTIONS, payload: ids });
		} catch (e) {
			logError(e, 'Failed to exclude all conflicts');
		} finally {
			setExcludingSubscriptions(false);
		}
	};

	const excludeSubscription = (subscription: Agreement, subscriptionFilter: State['subscriptionFilter']) => {
		const mappedSub = {
			id: subscription.id,
			description: subscription.description,
			client: {
				id: subscription.client?.id,
				name: subscription.client?.name
			}
		};

		if (!subscriptionFilter.enabled) {
			subscriptionFilter.enabled = true;
			subscriptionFilter.type = 'exclude';
			subscriptionFilter.values = [mappedSub];
		} else {
			if (subscriptionFilter.type === 'include') {
				// Remove from include list
				subscriptionFilter.values = subscriptionFilter.values.filter(x => x.id !== subscription.id);
			} else {
				// Add to exclude list
				subscriptionFilter.values = [...subscriptionFilter.values, mappedSub];
			}
		}

		dispatch({ type: Actions.SET_SUBSCRIPTION_FILTER, payload: subscriptionFilter });
	};

	const toggleLock = (isLocked: boolean) => {
		dispatch({ type: Actions.TOGGLE_LOCK, payload: !isLocked });
		if (!isLocked) {
			dispatch({ type: Actions.SET_AS_LOCKED, payload: true });
		}
	};

	/** @deprecated */
	const toggleLockOld = (isLocked: boolean) => {
		dispatch({ type: Actions.TOGGLE_LOCK_OLD, payload: !isLocked });
		if (!isLocked) {
			dispatch({ type: Actions.SET_AS_LOCKED, payload: true });
		}
	};

	const setIndexPercentage = (val: number | undefined) =>
		dispatch({ type: Actions.SET_INDEX_PERCENTAGE, payload: val ?? 0 });

	const toggleSpecificDate = (toggle: boolean) => dispatch({ type: Actions.TOGGLE_SPECIFIC_DATE, payload: toggle });

	const lockAtSave = () => {
		dispatch({ type: Actions.SET_AS_LOCKED, payload: true });
		dispatch({ type: Actions.TOGGLE_LOCK, payload: false });
	};

	const lockAtSaveOld = () => {
		dispatch({ type: Actions.SET_AS_LOCKED, payload: true });
		dispatch({ type: Actions.TOGGLE_LOCK_OLD, payload: false });
	};

	const setSpecificDate = (date: string) => dispatch({ type: Actions.SET_SPECIFIC_DATE, payload: date });

	/** @deprecated */
	const setSpecificDateOld = (date: string) => dispatch({ type: Actions.SET_SPECIFIC_DATE_OLD, payload: date });

	const setIndexInterval = (value: State['indexInterval']) => {
		if (value === 'renewal') {
			dispatch({ type: Actions.SET_SPECIFIC_DATE, payload: undefined });
		} else {
			dispatch({ type: Actions.SET_SPECIFIC_DATE, payload: moment().add(1, 'day').format('YYYY-MM-DD') });
		}

		dispatch({ type: Actions.SET_INDEX_INTERVAL, payload: value });
	};

	const setConflictDecision = (agreement: Agreement, decision: string) => {
		return dispatch({
			type: Actions.SET_CONFLICT_DECISION,
			payload: {
				agreementId: agreement.id,
				decision,
				description: agreement.description
			}
		});
	};

	const toggleCreateDiffOrder = (toggled: boolean) =>
		dispatch({ type: Actions.SET_SHOULD_CREATE_DIFF, payload: toggled });

	const toggleSpecificUser = (toggle: boolean) =>
		dispatch({ type: Actions.TOGGLE_SPECIFIC_RECIPIENT, payload: toggle });

	const setSpecificUser = (user: State['specificUser']) =>
		dispatch({ type: Actions.SET_SPECIFIC_USER, payload: user });

	const setName = (name: string) => dispatch({ type: Actions.SET_NAME, payload: name });

	const setSubscriptionNote = (note: string) => dispatch({ type: Actions.SET_SUBSCRIPTION_NOTE, payload: note });

	const setOrderNote = (note: string) => dispatch({ type: Actions.SET_ORDER_NOTE, payload: note });

	const setAccountFilter = (
		accountFilter: State['accountFilter'],
		subscriptionFilter: State['subscriptionFilter']
	) => {
		const accountFilterIsInclude = accountFilter.type === 'include';

		if (accountFilterIsInclude) {
			subscriptionFilter.values = subscriptionFilter.values.filter(sub =>
				accountFilter.values.some(acc => acc.id === sub.client.id)
			);
		} else {
			subscriptionFilter.values = subscriptionFilter.values.filter(sub =>
				accountFilter.values.every(acc => acc.id !== sub.client.id)
			);
		}

		dispatch({
			type: Actions.SET_ACCOUNT_FILTER,
			payload: {
				enabled: accountFilter.enabled,
				type: accountFilter.type,
				values: accountFilter.values.map(x => ({
					id: x.id,
					name: x.name,
					type: x.type,
					nrOfAccounts: x.nrOfAccounts
				}))
			}
		});

		dispatch({ type: Actions.SET_SUBSCRIPTION_FILTER, payload: subscriptionFilter });
	};

	const setSubscriptionFilter = (filter: State['subscriptionFilter']) =>
		dispatch({
			type: Actions.SET_SUBSCRIPTION_FILTER,
			payload: {
				enabled: filter.enabled,
				type: filter.type,
				values: filter.values.map(x => ({
					id: x.id,
					description: x.description,
					client: {
						id: x.client.id,
						name: x.client.name
					}
				}))
			}
		});

	return {
		setExcludingSubscriptions,
		setIsLoadingSubscriptions,
		excludeSubscriptionId,
		includeSubscriptionId,
		toggleCreateDiffOrder,
		setSubscriptionFilter,
		toggleIndexingEnabled,
		getSubscriptionCount,
		excludeAllConflicts,
		setSubscriptionMeta,
		setSubscriptionNote,
		excludeSubscription,
		setConflictDecision,
		toggleSpecificUser,
		toggleSpecificDate,
		setIndexPercentage,
		setSpecificDateOld,
		setAccountFilter,
		setIndexInterval,
		getSubscriptions,
		setHasConflicts,
		setSpecificUser,
		setSpecificDate,
		getSettingsOld,
		toggleLockOld,
		setOrderNote,
		setUiFilters,
		toggleLock,
		lockAtSaveOld,
		lockAtSave,
		setFilterQ,
		doSaveOld,
		setName,
		doSave
	};
};
