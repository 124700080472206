import { Block, Flex, Label, Text, ClickableItem, Tooltip, Icon, EllipsisTooltip } from '@upsales/components';
import { useFeatureAvailable, useSoftDeployAccess } from 'App/components/hooks';
import { FormObserverOnFieldChange } from 'App/components/FormObserver';
import { TicketForm, getMainRecipient } from '../../Context/Helpers';
import TelephoneLink from 'App/components/columns/TelephoneLink';
import { getJourneyStep } from 'Components/Helpers/journeyStep';
import openModal, { shouldOpenModal } from 'App/services/Modal';
import { useTranslation } from 'Components/Helpers/translate';
import { ExternalContact } from 'App/resources/Model/Ticket';
import { FormComponent } from 'App/components/FormComponent';
import { useEditTicketContext } from '../../Context/Context';
import bemClass from '@upsales/components/Utils/bemClass';
import { ThirdButton } from '@upsales/components/Buttons';
import ChangeContact from '../TicketInfo/ChangeContact';
import Contact from 'App/resources/Model/Contact';
import Client from 'App/resources/Model/Client';
import CcSelect from '../../Common/CcSelect';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import './ExternalContactInfo.scss';
import React from 'react';

type Props = {
	onFormChange: FormObserverOnFieldChange;
	formInputProps: any;
	formValues: TicketForm;
	tooltipAnchorRef: React.RefObject<HTMLDivElement>;
};
const ExternalContactInfo = ({ onFormChange, formValues, tooltipAnchorRef }: Props) => {
	const classes = new bemClass('ExternalContactInfo');
	const {
		state: { activeExternalContactId, ticket }
	} = useEditTicketContext();
	const { t } = useTranslation();
	const hasTicketCC = useSoftDeployAccess('TICKET_CC');
	const hasImprovedMatching = useSoftDeployAccess('TICKET_IMPROVED_MATCHING');
	const hasCustomerSupport = useFeatureAvailable('CUSTOMER_SUPPORT');
	const { customerId, self } = useSelector((state: RootState) => ({
		customerId: state.App?.customerId,
		self: state.App?.self
	}));

	const activeExternalContact = ticket.externalContacts.find(
		(ec: ExternalContact) => ec.id === activeExternalContactId
	);
	if (!activeExternalContact || !formValues.externalContacts[activeExternalContact.id]) {
		return null;
	}

	const { involved, client } = activeExternalContact;
	const mainRecipient = getMainRecipient(involved);
	const { email, contact } = mainRecipient ?? {};
	const isSupportUser = self?.support;
	const disableEditing = ticket?.status.closed;
	const canEdit = !disableEditing && isSupportUser;

	return (
		<Flex className={classes.b()} direction="column" gap="u4" space="ptl pbl pll prl">
			<Block>
				<Flex justifyContent="space-between" alignItems="center">
					<Label>{t('ticket.externalContact')}</Label>
					{canEdit ? (
						<ChangeContact
							onChange={(type, value) => {
								onFormChange('externalContacts', {
									...formValues.externalContacts,
									[activeExternalContact.id]: {
										...formValues.externalContacts[activeExternalContact.id],
										[type]: value
									}
								});
							}}
							values={formValues.externalContacts[activeExternalContact.id]?.contactInfo}
							disabled={!hasCustomerSupport}
							useText={hasImprovedMatching}
						/>
					) : null}
				</Flex>
				{email && !contact?.email ? (
					<Flex alignItems="center" space="plxl">
						<EllipsisTooltip title={email} appendTo={tooltipAnchorRef.current as HTMLDivElement}>
							<Text>
								<Icon space="mrm" name="email" />
								{email}
							</Text>
						</EllipsisTooltip>
					</Flex>
				) : null}

				{contact ? (
					<>
						<Flex alignItems="center" justifyContent="space-between">
							<Tooltip title={t('ticket.visitContact')} position="bottom" distance={30}>
								<ClickableItem
									bold
									size="lg"
									icon="user"
									borderRadius
									textSize="lg"
									color="black"
									iconColor="black"
									title={contact.name}
									onClick={() => {
										window.open(
											Tools.$state.href('contact.dashboard', {
												customerId,
												id: contact?.id
											}),
											'_blank'
										);
									}}
									className={classes.elem('contact').b()}
									subtitle={contact.title}
								/>
							</Tooltip>
							{hasImprovedMatching ? (
								<Tooltip title={t('default.editContact')} position="left">
									<ThirdButton
										onClick={() => {
											// eslint-disable-next-line promise/catch-or-return
											Tools.$upModal
												.open('editContact', contact)
												.then((updatedContact?: Contact) => {
													if (updatedContact) {
														onFormChange('externalContacts', {
															...formValues.externalContacts,
															[activeExternalContact.id]: {
																...formValues.externalContacts[
																	activeExternalContact.id
																],
																contactInfo: {
																	email: updatedContact.email,
																	contact: updatedContact,
																	client: updatedContact.client
																}
															}
														});
													}
												});
										}}
										disabled={!hasCustomerSupport}
										icon={'edit'}
									/>
								</Tooltip>
							) : null}
						</Flex>
						<Flex direction="column" gap="u2">
							{contact?.cellPhone || contact?.phone ? (
								<Flex space="plxl" alignItems="center">
									<Icon name="phone" space="mrl" />
									<TelephoneLink
										client={client}
										contact={contact}
										number={contact.cellPhone || contact.phone}
									/>
								</Flex>
							) : null}
							{email && !contact?.email ? null : contact?.email ? (
								<Flex space="plxl" alignItems="center">
									<Icon name="email" space="mrl" />
									{ReactTemplates.TOOLS.mailTo(customerId, { ...contact, email: contact.email }, {})}
								</Flex>
							) : null}
						</Flex>
					</>
				) : null}
			</Block>
			{client ? (
				<Block>
					<Label>{t('default.client')}</Label>
					<Flex alignItems="center" justifyContent="space-between">
						<Tooltip title={t('ticket.visitCompany')} position="bottom" distance={30}>
							<ClickableItem
								className={classes.elem('client').b()}
								title={client?.name}
								subtitle={getJourneyStep(client?.journeyStep)?.name}
								size="lg"
								bold
								textSize="lg"
								icon="home"
								color="black"
								iconColor="black"
								onClick={() => {
									window.open(
										Tools.$state.href('account.dashboard', {
											customerId,
											id: client.id
										}),
										'_blank'
									);
								}}
								borderRadius
							/>
						</Tooltip>
						{/* TODO: Add userEditable check */}
						{hasImprovedMatching ? (
							<Tooltip title={t('default.editCompany')} position="left">
								<ThirdButton
									onClick={() => {
										if (shouldOpenModal('EditClient')) {
											openModal('EditClient', {
												id: client.id,
												onClose: (updatedClient?: Client) => {
													if (updatedClient) {
														onFormChange('externalContacts', {
															...formValues.externalContacts,
															[activeExternalContact.id]: {
																...formValues.externalContacts[
																	activeExternalContact.id
																],
																contactInfo: {
																	email: contact?.email || email,
																	contact,
																	client: updatedClient
																}
															}
														});
													}
												}
											});
										} else {
											return Tools.$upModal
												.open('editAccount', { account: client })
												.then((updatedClient?: Client) => {
													if (updatedClient) {
														onFormChange('externalContacts', {
															...formValues.externalContacts,
															[activeExternalContact.id]: {
																...formValues.externalContacts[
																	activeExternalContact.id
																],
																contactInfo: {
																	email: contact?.email || email,
																	contact,
																	client: updatedClient
																}
															}
														});
													}
												});
										}
									}}
									disabled={!hasCustomerSupport}
									icon={'edit'}
								/>
							</Tooltip>
						) : null}
					</Flex>
				</Block>
			) : null}
			{hasTicketCC ? (
				<FormComponent label={t('mail.ccShort')} hideMessage>
					<CcSelect
						value={formValues.externalContacts[activeExternalContact.id].cc}
						onChange={values =>
							onFormChange('externalContacts', {
								...formValues.externalContacts,
								[activeExternalContact.id]: {
									...formValues.externalContacts[activeExternalContact.id],
									cc: values
								}
							})
						}
					/>
				</FormComponent>
			) : null}
		</Flex>
	);
};

export default ExternalContactInfo;
