import { useSubscriptionGroupContext } from '../../Context/SubscriptionGroupContext';
import { formatSubscriptionPeriod } from 'App/components/EditSubscription/Context/SubscriptionPeriodContextHelpers';
import { getDocumentTemplates } from 'Store/selectors/AppSelectors';
import BemClass from '@upsales/components/Utils/bemClass';
import { Icon, Tooltip, Text } from '@upsales/components';
import T from 'Components/Helpers/translate';
import openModal from 'App/services/Modal';
import React from 'react';

import './SubscriptionSidebarActions.scss';
import Agreement from 'App/resources/Model/Agreement';

type Props = {
	close: () => void;
};

const SubscriptionSidebarActions = ({ close }: Props) => {
	const {
		state: { id: agreementGroupId, isEdit, client, dontWait },
		currentPeriod
	} = useSubscriptionGroupContext();

	if (!isEdit) return null;

	const templates = getDocumentTemplates('agreement');

	const classes = new BemClass('SubscriptionSidebarActions');
	const hideCreateTemplate = templates.length === 0;

	return (
		<div className={classes.b()}>
			<div className={classes.elem('actions').b()}>
				<Tooltip title={T('agreement.moveSubscriptionButtonTooltip')}>
					<Icon
						color="grey-11"
						name="arrow-right"
						space="mrs"
						onClick={e => {
							e.stopPropagation();
							const formattedAgreement = formatSubscriptionPeriod(currentPeriod);
							openModal('MoveSubscription', {
								agreementGroup: {
									id: agreementGroupId,
									currentAgreement: { ...formattedAgreement, client } as unknown as Agreement
								},
								agreement: formattedAgreement as unknown as Agreement,
								show: true,
								animate: true
							});
							close();
						}}
					/>
				</Tooltip>

				<Tooltip title={T('subscription.card.copy')}>
					<Icon
						color="grey-11"
						name="copy"
						space="mrs"
						onClick={e => {
							e.stopPropagation();
							openModal('EditSubscription', {
								agreementGroupId: agreementGroupId,
								agreementId: currentPeriod.id,
								createCopy: true,
								dontWait
							});
							setTimeout(() => {
								close(); // Close after 300ms due to animation showing Subscription list during transition.
							}, 300);
						}}
					/>
				</Tooltip>
				{hideCreateTemplate ? null : (
					<Tooltip
						title={T(
							currentPeriod.id
								? 'default.createDocument'
								: 'subscription.action.createTemplate.disabled.tooltip'
						)}
					>
						<Icon
							color="grey-11"
							name="file"
							space="mrs"
							onClick={
								currentPeriod.id
									? e => {
											e.stopPropagation();
											if (Tools.FeatureHelper.hasSoftDeployAccess('CREATE_DOCUMENT_REACT')) {
												openModal('CreateDocumentModal', {
													type: 'agreement',
													id: currentPeriod?.id,
													templates: templates,
													accountId: client?.id
												});
											} else {
												Tools.$upModal.open('createDocument', {
													type: 'agreement',
													id: currentPeriod.id,
													templates: templates,
													accountId: client?.id
												});
											}
									  }
									: undefined
							}
						/>
					</Tooltip>
				)}
			</div>

			<div className={classes.elem('divider').b()} />

			<div className={classes.elem('id-wrapper').b()}>
				<Text color="grey-9" size="sm">
					{T('default.id2')}
				</Text>
				<Text>{currentPeriod.id ?? T('default.none')}</Text>
			</div>
		</div>
	);
};

export default SubscriptionSidebarActions;
