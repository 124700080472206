import { Flex, DrawerHeader, Drawer, ButtonSelect } from '@upsales/components';
import { ModalProps } from 'App/components/Modals/Modals';
import BemClass from '@upsales/components/Utils/bemClass';
import { ClientIdName } from 'App/resources/Model/Client';
import React, { useEffect, useState } from 'react';

import './ListDrawer.scss';

type Props = {
	account: ClientIdName;
	lists: {
		value: string;
		title: string;
		component: JSX.Element;
	}[];
	isContactsTab?: boolean;
} & ModalProps;

const ListDrawer = (props: Props) => {
	const { account, lists, isContactsTab, className } = props;
	const [currentList, setCurrentList] = useState(lists[0]?.value);

	useEffect(() => {
		const unsubStateChange = Tools.$rootScope.$on('$stateChangeStart', () => props.close?.());
		return () => {
			unsubStateChange();
		};
	}, []);

	if (!currentList) {
		return null;
	}

	const listView = lists.find(list => list.value === currentList)?.component;

	const classes = new BemClass('ListDrawer');
	classes.add(className);

	return (
		<Drawer className={classes.mod({ contactsTabDrawer: isContactsTab }).b()}>
			<DrawerHeader title={account.name} onHide={() => props.close?.()}>
				<Flex alignItems="center" space="mrl">
					{lists.length > 1 ? (
						<ButtonSelect
							size="sm"
							options={lists}
							value={currentList}
							onChange={setCurrentList}
						></ButtonSelect>
					) : null}
				</Flex>
			</DrawerHeader>
			{listView}
		</Drawer>
	);
};

export default ListDrawer;
