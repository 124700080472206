import './ConfirmBuyProspectsModal.scss';

import React from 'react';

import { Modal } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import ConfirmBuyProspects, { Props } from './ConfirmBuyProspects';

export default (props: Props) => {
	const classes = new BemClass('ConfirmBuyProspectsModal', props.className);
	return (
		<Modal className={classes.b()}>
			<ConfirmBuyProspects {...props} />
		</Modal>
	);
};
