import Client from './Client';
import File from './File';

export const EsignEnum = {
	DRAFT: 0,
	PENDING: 10,
	REJECTED: 20,
	ALL_SIGNED: 30,
	CANCELED: 40
} as const;

export type EsignInvolved = {
	id: number;
	userId: number | null;
	contactId: number | null;
	undelivered: string | null;
	sign: Date | null;
	declineDate: Date | null;
	declineMsg: string | null;
	seen: Date | null;
	fstname: string;
	sndname: string;
	email: string;
	company: string;
	companynr: string;
	personalnr: string;
	tsupdated: string;
	mobile: string;
	signlink: string | null;
	esignId: number;
	authMethod: string;
	deliveryMethod: string;
	deliveryDate: Date | null;
};

export type Esign = {
	id: number;
	involved: EsignInvolved[];
	type: number;
	authorization: number;
	mdate: string;
	title: string;
	localeLanguage: string;
	tsupdated: string;
	version: number;
	documentId: string;
	upsalesStatus: number;
	seenBySignatory: Date | null;
	localeRegion: string | null;
	file: File | null;
	user: {
		id: number;
		name: string;
		role: string | null;
		email: string;
	};
	state: number;
	client: Partial<Client>;
	opportunity: {
		id: number;
		orderValue: number;
		description: string;
		valueInMasterCurrency: number;
	};
	userRemovable: boolean;
	orderStage: string | null;
	reminderDate: Date | null;
	integrationId: number;
	fieldValues: {
		template: string;
	};
	sortDate: string;
	sendToScrive: boolean;
};
