import { openFileBrowserModal } from 'Components/FileBrowser';
import translate from 'Components/Helpers/translate';

CKEDITOR.plugins.add('images', {
	init: editor => {
		editor.addCommand('insertImage', {
			// create named command
			exec: editor => {
				const fileBrowserOpts = {
					types: ['image'],
					public: true
				};

				openFileBrowserModal(fileBrowserOpts)
					.then(selectedImages => {
						if (selectedImages.length) {
							const src = selectedImages[0].$$publicUrl;
							editor.insertHtml(`<img src="${src}"/>`);
						}
					})
					.catch(err => {
						console.error(err);
					});
			}
		});

		editor.ui.addButton('InsertImage', {
			label: translate('editor.insertImage'),
			command: 'insertImage',
			toolbar: 'images'
		});
	},
	afterInit: editor => {
		setTimeout(() => {
			const btnLabel = document.querySelectorAll(`#cke_${editor.name} .cke_button__insertimage_label`)[0];
			const btnIcon = document.querySelectorAll(`#cke_${editor.name} .cke_button__insertimage_icon`)[0];
			if (btnLabel) {
				btnLabel.style.display = 'inline';
				btnLabel.style.paddingLeft = '0';
				btnIcon.style.display = 'none';
			}
		}, 100);
	}
});
