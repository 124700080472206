import React, { useReducer, useContext, createContext } from 'react';
import { AnyAction } from 'redux';
import Actions from './Actions';
import {
	State,
	init,
	setFilter,
	removeFile,
	addFile,
	save,
	getMoreEvents,
	getEvents,
	addEvent,
	setComment,
	addComment,
	markAsSolved,
	setEmailUsed,
	markAsOpen,
	setPublicMessage,
	setUserAndSave,
	onTicketChange,
	deleteTicket,
	getCustomFields,
	setTicket,
	setFiles,
	setTicketResponseTemplate,
	addExternalContact,
	setActiveExternalContactId,
	setReplyMode,
	deleteExternalContact,
	dismissMatchPopup
} from './Helpers';
import type Ticket from 'App/resources/Model/Ticket';
import TicketResource from 'App/resources/Ticket';
import { getTicketTypesFromState } from 'Store/selectors/AppSelectors';
import store from 'Store/index';

type Dispatch = (action: any) => void;

type ProviderProps = {
	initialState?: { client?: Ticket['client']; contact?: Ticket['contact']; description?: Ticket['description'] };
	children: React.ReactNode;
};

const getInitialState = (initialState: ProviderProps['initialState']): State => {
	const app = store.getState().App;

	const ticketTypes = getTicketTypesFromState(app) ?? [];
	const defaultType = ticketTypes.find(type => type.isDefault) ?? ticketTypes[0];
	const ticket: Parameters<typeof TicketResource.save>[0] = {
		status: { id: 1 },
		type: defaultType,
		priority: 0,
		emailUsed: null,
		custom: [],
		client: null,
		contact: null,
		involved: [],
		externalContacts: []
	};

	if (initialState?.client) {
		ticket.client = initialState.client;
	}

	if (initialState?.contact) {
		ticket.involved = [{ type: 'TO', email: initialState.contact?.email, contact: initialState.contact }];
		ticket.contact = initialState.contact;
	}

	if (initialState?.description) {
		ticket.description = initialState.description;
	}

	const customFields = getCustomFields(ticket.type!.id);

	return {
		loading: false,
		files: [],
		saving: false,
		savingComment: false,
		ticket,
		events: [],
		comment: '',
		publicMessage: '',
		sendingPublicMessage: false,
		filter: 'all',
		hasMoreEvents: false,
		offset: 0,
		replyMode: 'public',
		supportEmails: [],
		canSendPublicMessage: false,
		showClosedScreen: false,
		isValid: true,
		errorMessages: {},
		ticketHash: '',
		customFields,
		formStateResetTrigger: false,
		hasUnsavedChanges: false,
		ticketResponseTemplate: undefined,
		totalFileSize: 0,
		activeExternalContactId: null,
		isMatchPopupDismissed: false
	};
};

const ACTION_HANDLERS: {
	[key: string]: (s: State, a: AnyAction) => State;
} = {
	[Actions.SET_DESCRIPTION]: (state, { description }) => ({ ...state, ticket: { ...state.ticket, description } }),
	[Actions.SET_CONTACT]: (state, { contact }) => ({ ...state, ticket: { ...state.ticket, contact } }),
	[Actions.SET_CLIENT]: (state, { client }) => ({ ...state, ticket: { ...state.ticket, client } }),
	[Actions.SET_TITLE]: (state, { title }) => ({ ...state, ticket: { ...state.ticket, title } }),
	[Actions.SET_USER]: (state, { user }) => ({ ...state, ticket: { ...state.ticket, user } }),
	[Actions.SET_LOADING]: (state, { loading }) => ({ ...state, loading }),
	[Actions.SET_TICKET]: (state, { ticket }) => ({ ...state, ticket }),
	[Actions.SET_SAVING]: (state, { saving }) => ({ ...state, saving }),
	[Actions.SET_EVENTS]: (state, { events }) => ({ ...state, events }),
	[Actions.SET_COMMENT]: (state, { comment }) => ({ ...state, comment }),
	[Actions.SET_SAVING_COMMENT]: (state, { savingComment }) => ({ ...state, savingComment }),
	[Actions.SET_PUBLIC_MESSAGE]: (state, { publicMessage }) => ({ ...state, publicMessage }),
	[Actions.SET_SENDING_PUBLIC_MESSAGE]: (state, { sendingPublicMessage }) => ({ ...state, sendingPublicMessage }),
	[Actions.SET_FILTER]: (state, { filter }) => ({ ...state, filter, offset: 0, hasMoreEvents: false }),
	[Actions.SET_EMAIL_ON_CONTACT]: (state, { email }) => ({
		...state,
		ticket: { ...state.ticket, contact: { ...state.ticket.contact, email } }
	}),
	[Actions.SET_HAS_MORE_EVENTS]: (state, { hasMoreEvents }) => ({ ...state, hasMoreEvents }),
	[Actions.SET_OFFSET]: (state, { offset }) => ({ ...state, offset }),
	[Actions.SET_SUPPORT_EMAILS]: (state, { supportEmails }) => ({ ...state, supportEmails }),
	[Actions.SET_CAN_SEND_PUBLIC_MESSAGE]: (state, { canSendPublicMessage }) => ({ ...state, canSendPublicMessage }),
	[Actions.SET_PRIORITY]: (state, { priority }) => ({ ...state, ticket: { ...state.ticket, priority } }),
	[Actions.SET_TYPE]: (state, { ticketType }) => ({ ...state, ticket: { ...state.ticket, type: ticketType } }),
	[Actions.SET_SHOW_CLOSED_SCREEN]: (state, { showClosedScreen }) => ({ ...state, showClosedScreen }),
	[Actions.SET_STATE]: (state, updatedState) => ({ ...state, ...updatedState }),
	[Actions.ADD_FILE]: (state, { file }) => ({ ...state, files: [...state.files, file] }),
	[Actions.SET_FILES]: (state, { files }) => ({ ...state, files }),
	[Actions.SET_TOTAL_FILE_SIZE]: (state, { totalFileSize }) => ({ ...state, totalFileSize }),
	[Actions.SET_TICKET_RESPONSE_TEMPLATE]: (state, { ticketResponseTemplate }) => ({
		...state,
		ticketResponseTemplate
	}),
	[Actions.SET_ACTIVE_EXTERNAL_CONTACT_ID]: (state, { activeExternalContactId }) => ({
		...state,
		activeExternalContactId
	}),
	[Actions.SET_DISMISS_MATCH_POPUP]: (state, { isMatchPopupDismissed }) => ({
		...state,
		isMatchPopupDismissed
	})
};

const reducer = (state: any, action: AnyAction) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};

const EditTicketContext = createContext<
	| {
			state: State;
			dispatch: Dispatch;
	  }
	| undefined
>(undefined);

export function EditTicketProvider({ initialState: partialState, children }: ProviderProps) {
	const initialState = getInitialState(partialState);

	const [state, dispatch] = useReducer(reducer, { ...initialState });
	const value = { state, dispatch };

	return <EditTicketContext.Provider value={value}>{children}</EditTicketContext.Provider>;
}

export function useEditTicketContext() {
	const context = useContext(EditTicketContext);
	if (typeof context === 'undefined') {
		throw new Error('useEditTicketContext must be used within a Provider');
	}
	const { state, dispatch } = context;

	const actions = {
		setPublicMessage: setPublicMessage(dispatch, state),
		setUserAndSave: setUserAndSave(dispatch, state),
		getMoreEvents: getMoreEvents(dispatch, state),
		markAsSolved: markAsSolved(dispatch, state),
		setEmailUsed: setEmailUsed(dispatch, state),
		addComment: addComment(dispatch, state),
		markAsOpen: markAsOpen(dispatch, state),
		setComment: setComment(dispatch, state),
		removeFile: removeFile(dispatch, state),
		getEvents: getEvents(dispatch, state),
		setFilter: setFilter(dispatch, state),
		setTicket: setTicket(dispatch, state),
		addEvent: addEvent(dispatch, state),
		setFiles: setFiles(dispatch, state),
		addFile: addFile(dispatch, state),
		init: init(dispatch, state),
		save: save(dispatch, state),
		onTicketChange: onTicketChange(dispatch, state),
		deleteTicket: deleteTicket(dispatch, state),
		setTicketResponseTemplate: setTicketResponseTemplate(dispatch, state),
		addExternalContact: addExternalContact(dispatch, state),
		setActiveExternalContactId: setActiveExternalContactId(dispatch, state),
		setReplyMode: setReplyMode(dispatch, state),
		deleteExternalContact: deleteExternalContact(dispatch, state),
		dismissMatchPopup: dismissMatchPopup(dispatch, state)
	};

	return { state, ...actions };
}
