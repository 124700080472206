import Analytics from 'App/services/Analytics';
import InAppChat from 'App/babel/services/InAppChat';

/*
 * Example:
 *	const tracker = new Tracker('activity'); // init new tracker with scope = activity
 * 	tracker.track('add', {type: 'activity', location: 'sidebar'});
 *
 * This will trigger event activity.add
 * If no scope is provided it will only trigger activity in this case
 */
type Events = {
	[eventName: string]: string;
};

type TrackValues = {
	[eventName: string]: number;
};
type Scope = string | null;

const getScopedString = (scope: string | null, str: string) => {
	return scope ? `${scope}.${str}` : str;
};

class Tracker<T extends TrackValues = {}> {
	scope: Scope;
	events: Events;
	trackToChat: boolean;
	valuesToTrack: T;
	timeTracker: TrackValues;

	constructor(scope: Scope = null, events: Events = {}, trackToChat = true, valuesToTrack: T = {} as T) {
		this.scope = scope;
		this.events = events;
		this.trackToChat = trackToChat;
		this.valuesToTrack = valuesToTrack;
		this.timeTracker = {};
	}

	track(evt: string, data: string | { [key: string]: any } = {}) {
		if (this.trackToChat) {
			InAppChat.trackEvent(
				(
					getScopedString(this.scope, evt) +
					' ' +
					(typeof data === 'object' && data.type ? data.type : '')
				).trim()
			);
		}
		Analytics.track(getScopedString(this.scope, evt), data);
	}

	increment(name: string) {
		Analytics.peopleIncrement(getScopedString(this.scope, name));
	}

	set(name: string, value: unknown) {
		Analytics.peopleSet(getScopedString(this.scope, name), value);
	}

	registerOnce(key: string, value: any) {
		Analytics.registerOnce({
			[getScopedString(this.scope, key)]: value
		});
	}

	register(key: string, value: any) {
		Analytics.register({
			[getScopedString(this.scope, key)]: value
		});
	}

	incrementValue(name: keyof T, valueToAdd = 1) {
		this.valuesToTrack[name] += valueToAdd as any; // This is so bizarre...
	}

	startTrackTime(name: string) {
		this.timeTracker[name] = Date.now();
	}

	endTrackTime(name: string) {
		if (!this.timeTracker[name]) return 0;
		const time = Date.now() - this.timeTracker[name];
		delete this.timeTracker[name];
		return Math.round(time / 1000);
	}

	getTrackedTime(name: string) {
		return this.timeTracker[name];
	}

	trackWithValues(event: string, extra = {}, keepValuesWithZero = false) {
		const values = { ...this.valuesToTrack };

		if (!keepValuesWithZero) {
			Object.keys(values).forEach(key => {
				if (values[key] === 0) {
					delete values[key];
				}
			});
		}

		this.track(event, { ...values, ...extra });
	}
}

export default Tracker;
