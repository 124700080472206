import config from 'App/babel/config';
import MailTemplate from 'App/resources/Model/MailTemplate';

const parseStandardMailTemplate = (template: MailTemplate) => {
	// TemplateThumb url
	template.$$thumbnailUrl = `${config.URL}${config.API}thumbnail/standardTemplate/${
		template.id
	}?timestamp=${Date.now()}`;

	return template;
};

export default parseStandardMailTemplate;
