export enum RolePermissionValues {
	NO = 'no',
	ALL = 'all',
	OWN = 'own'
}

export enum AccessRolePermissionValues {
	TOP = 'top',
	OWN_AND_NO_USER = 'ownAndNoUser',
	ROLE_AND_NO_USER = 'roleAndNoUser'
}
