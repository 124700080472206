import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';
import AccountProfile from 'App/resources/Model/AccountProfile';
import MailTemplate from 'App/resources/Model/MailTemplate';
import openModal from 'App/services/Modal/Modal';
import { getCurrentLanguage } from 'Components/Helpers/translate';
import logError from 'Helpers/logError';
import React, { useEffect, useState } from 'react';
import StandardMailTemplate from 'Resources/StandardMailTemplate';

type Props = {
	optIn: object;
	close: (arg?: any) => void;
};

export default function OptIn(props: Props) {
	const currentLanguage = getCurrentLanguage();
	const optInInfoLanguages = window.OptInInfoText.getLangs();
	const [stateState, setStateState] = useState(props.optIn ? 'form' : 'info');
	const [activeTab, setActiveTab] = useState(0);
	const [optIn, setOptIn] = useState<any>(props.optIn ?? Tools.OptIn.new());
	const [loading, setLoading] = useState(false);
	const [terms, setTerms] = useState('');
	const [predefinedTextState, setPredefinedTextState] = useState<any>(
		window.OptInInfoText.translate(currentLanguage)
	);
	const [modalHeight, setModalHeight] = useState('506px');
	const [infoLanguage, setInfoLanguage] = useState<string>(currentLanguage);
	const [template, setTemplate] = useState<MailTemplate>();

	const [profile, setProfile] = useState<AccountProfile | undefined>();

	const reloadModalPosition = () => {
		// Look at this when searching for OPT_IN_REACT
		// no-op, the functionality of what this function SHOULD be doing is instead done in the changeTab function
		// i will keep this here for now to satisfy the component props, but it should be removed when removing angular
	};

	const fillEmptyValues = (optIn: any) => {
		const predefinedText = predefinedTextState;
		optIn.emailTitle = optIn.emailTitle || predefinedText.email.headline;
		optIn.emailDescription = optIn.emailDescription || predefinedText.email.description;
		optIn.emailLinkText = optIn.emailLinkText || predefinedText.email.acceptLink;
		// Replace brackets with curls because using curls in translation file tries to parse tags. And we want to show them
		optIn.emailExplanation = optIn.emailExplanation || predefinedText.email.explanation;
		optIn.landingpageTitle = optIn.landingpageTitle || predefinedText.landingpage.headline;
		optIn.landingpageDescription = optIn.landingpageDescription || predefinedText.landingpage.description;
	};

	const setPredefinedText = (lang: string) => {
		setPredefinedTextState(window.OptInInfoText.translate(lang));
	};

	const showForm = () => {
		setStateState('form');
	};

	const changeTab = (i: number) => {
		setActiveTab(i);
		switch (i) {
			case 0:
				setModalHeight('506px');
				break;
			case 1:
				setModalHeight('574px');
				break;
			case 2:
				setModalHeight('660px');
				break;
			default:
				setModalHeight('477px');
				break;
		}
	};

	const closeModal = () => {
		props.close();
	};

	const setType = (type: any) => {
		if (loading || optIn.id) {
			return;
		}
		setOptIn({ ...optIn, type: type });
	};

	const onChange = (key: string, value: any) => {
		setOptIn({ ...optIn, [key]: value });
	};

	const storeTerms = (terms: string) => {
		setTerms(terms);
	};

	const save = () => {
		fillEmptyValues(optIn);
		setLoading(true);
		Tools.OptIn.save(optIn)
			.then(function (res: any) {
				props.close(res.data);
			})
			.finally(function () {
				setLoading(false);
			})
			.catch(function (err: any) {
				logError(err);
			});
	};

	const previewEmail = () => {
		// eslint-disable-next-line promise/catch-or-return
		if (!template || !profile) {
			return;
		}
		// eslint-disable-next-line promise/catch-or-return
		Tools.InkyParser.parse(template.bodyJson, {
			profile: profile
		}).then(function (res) {
			// Replace tags
			var html = res.data;
			var predefinedText = predefinedTextState;

			var regExLogo = new RegExp('{{LOGO}}', 'g');
			var regExTitle = new RegExp('{{TITLE}}', 'g');
			var regExDescription = new RegExp('{{DESCRIPTION}}', 'g');
			var regExLinkText = new RegExp('{{LINK.TEXT}}', 'g');
			var regExLink = new RegExp('{{LINK}}', 'g');
			var regExExplanation = new RegExp('{{EXPLANATION}}', 'g');
			html = html.replace(regExLogo, profile.darkLogo || profile.logo);
			html = html.replace(regExTitle, optIn.emailTitle || predefinedText.email.headline);
			html = html.replace(regExDescription, optIn.emailDescription || predefinedText.email.description);
			html = html.replace(regExLinkText, optIn.emailLinkText || predefinedText.email.acceptLink);
			html = html.replace(regExLink, '#" onClick="return false;');
			html = html.replace(regExExplanation, optIn.emailExplanation || predefinedText.email.explanation);
			html = html.replace(/\'/g, '&#39'); // eslint-disable-line no-useless-escape

			// CSS hack fix
			html = html.replace('table.container{background:#fefefe;', 'table.container{');
			html = html.replace('html{min-height:100%;background:#f3f3f3}', 'html{min-height:100%}');

			openModal('MailPreview', {
				name: '',
				html: `<iframe style="border: none; width: 100%; height: 560px;" srcdoc='${html}'></iframe>`,
				isPlainText: false
			});
		});
	};

	const previewLandingPage = () => {
		var predefinedText = predefinedTextState;
		const frameSrc =
			Tools.URL +
			'external/optInPreview?title=' +
			(optIn.landingpageTitle || predefinedText.landingpage.headline) +
			'&description=' +
			(optIn.landingpageDescription || predefinedText.landingpage.description);
		openModal('MailPreview', {
			name: '',
			html: `<iframe style="border: none; width: 100%; height: 700px;" src='${frameSrc}'></iframe>`,
			isPlainText: false
		});
	};

	const changeInfoLanguage = (lang: string) => {
		setPredefinedText(lang);
		setInfoLanguage(lang);
	};

	useEffect(() => {
		Tools.AccountProfile.get()
			.then(function (res) {
				return res.data;
			})
			.then(data => {
				setProfile(data);
			})
			.catch(function (err: any) {
				logError(err);
			});
		StandardMailTemplate.find({
			name: 'systemMailTemplate.doubleOptIn',
			getSystemTemplates: true
		})
			.then(function (res: any) {
				setTemplate(res.data[0]);
			})
			.catch(function (err: any) {
				logError(err);
			});
	}, []);

	return (
		<div
			style={{
				height: modalHeight,
				display: 'block',
				visibility: 'visible'
			}}
			id="opt-in-modal"
			className="up-modal notranslate default form ng-scope compiled"
		>
			<ReactTemplates.admin.optInModal
				actions={{
					showForm,
					changeTab,
					closeModal,
					setType,
					onChange,
					storeTerms,
					save,
					previewEmail,
					previewLandingPage,
					changeInfoLanguage
				}}
				activeTab={activeTab}
				infoLanguage={infoLanguage}
				loading={loading}
				optIn={optIn}
				optInInfoLanguages={optInInfoLanguages}
				predefinedText={predefinedTextState}
				reloadModalPosition={reloadModalPosition}
				state={stateState}
				terms={terms}
			/>
		</div>
	);
}

export const openOptInModal = asyncModalAdapter({
	upModalName: 'optIn',
	openModalName: 'OptIn',
	featureFlag: 'OPT_IN_REACT',
	rejectOnEmpty: true
});
