import { ListViewDefaultColumn } from 'App/components/ListView/ListViewRenderHelpers';
import CompanyPortfolioAttributes from 'App/babel/attributes/CustomerPortfolioCompany';
import CustomerPortfolioCompany from 'App/resources/Model/CustomerPortfolioCompany';
import { ListViewPropsExternal } from 'App/components/ListView/ListView';
import { Block, TableColumn, TableRow, Text, Icon } from '@upsales/components';
import React, { useMemo } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import ListView from 'App/components/ListView';
import { DisplayValueType, TabsEnum, ListViewFilterMap } from './CustomerPortfolio';
import T from 'Components/Helpers/translate';
import moment from 'moment';
import { getCustomerTableData, parseFiltersForRequest } from './fetchHelper';
import CreditRatingLabel from 'App/components/CreditRatingLabel';
import NumericCreditRatingColumn from './NumericCreditRatingColumn';
import { openDrawer } from 'Services/Drawer';
import { getMetadata } from 'Store/selectors/AppSelectors';
import { customerPorfolioTracker } from 'App/babel/helpers/Tracker';
import { useSoftDeployAccess } from 'App/components/hooks';

type ListViewProps = ListViewPropsExternal<CustomerPortfolioCompany>;

const renderIndustryColumn = (company: CustomerPortfolioCompany) => {
	const industryLookup: {
		[key: string]: string;
	} = {
		'04': 'A',
		'10': 'C',
		'34': 'C',
		'36': 'D',
		'41': 'E',
		'45': 'F',
		'49': 'G',
		'55': 'H',
		'58': 'I',
		'64': 'J',
		'68': 'K',
		'69': 'L',
		'77': 'M',
		'84': 'N',
		'85': 'O',
		'86': 'P',
		'89': 'Q',
		'94': 'R',
		'97': 'S',
		'99': 'T'
	};

	if (!company.sniCode) {
		return <Text size="md">{T(`default.sniCode.noSniCode`)}</Text>;
	}

	const firstTwoChars = company.sniCode.substring(0, 2);
	let industry = 'U';

	for (const key of Object.keys(industryLookup)) {
		if (firstTwoChars < key) {
			industry = industryLookup[key];
			break;
		}
	}

	return <Text size="md">{T(`default.snigroup${industry}`)}</Text>;
};

const renderCreditRatingColumn = (classes: BemClass, company: CustomerPortfolioCompany) => {
	const numeric =
		company.prospectingNumericCreditRating === null || company.prospectingCreditRating === 'other'
			? T('customerPortfolio.risks.missing')
			: Math.max(company.prospectingNumericCreditRating, 0);

	return (
		<Block className={classes.elem('credit-rating-column').b()}>
			<CreditRatingLabel prospectingCreditRating={company.prospectingCreditRating} space="mrm" />
			<Text size="md">{numeric}</Text>
		</Block>
	);
};

const navigateToAccount = ({ id }: CustomerPortfolioCompany) => {
	if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_CLIENT_CARD')) {
		Tools.$state.go('react-root-clientCard', { id: id, page: 'overview' });
	} else {
		Tools.$state.go('account.dashboard', { id });
	}
};

const openRiskDrawer = (company: CustomerPortfolioCompany, valueType: DisplayValueType) => {
	if (Tools.FeatureHelper.hasSoftDeployAccess('RISK_DRAWER') && company.prospectingCreditRating !== null) {
		openDrawer('RiskDrawer', { company, valueType });
	}
};

const getRenderTableRow = (tab: TabsEnum, valueType: DisplayValueType): ListViewProps['renderTableRow'] => {
	return (company: CustomerPortfolioCompany, { columns, attributes }) => {
		const classes = new BemClass('CustomerPortfolio');

		const columnElements = columns.map(column => {
			let content = null;
			switch (column) {
				case 'sniCode': {
					content = renderIndustryColumn(company);
					break;
				}
				case 'customerSince': {
					if (!company.isCustomer) {
						content = (
							<Text italic color="grey-10" size="md">
								{T('default.lostCustomer')}
							</Text>
						);
					} else if (company.customerSince) {
						content = <Text size="md">{moment(company.customerSince).format('YYYY')}</Text>;
					}
					break;
				}
				case 'prospectingCreditRating': {
					content = renderCreditRatingColumn(classes, company);
					break;
				}
				case 'prospectingNumericCreditRating': {
					return <NumericCreditRatingColumn key={column} company={company} />;
				}
				default: {
					return (
						<ListViewDefaultColumn<CustomerPortfolioCompany>
							key={column}
							item={company}
							attributes={attributes}
							column={column}
							colSize={'md'}
						/>
					);
				}
			}
			return (
				<TableColumn key={column} size="lg">
					{content}
				</TableColumn>
			);
		});

		const inactive =
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.INACTIVE_COMPANIES) && !company.active;

		return (
			<TableRow
				inactive={inactive}
				key={company.id}
				onClick={() =>
					tab === TabsEnum.Customers ? navigateToAccount(company) : openRiskDrawer(company, valueType)
				}
			>
				{columnElements}
			</TableRow>
		);
	};
};

function getInitialSorting(tab: TabsEnum, valueType: DisplayValueType) {
	if (tab === TabsEnum.Risk) {
		return [{ attribute: 'prospectingCreditRating', ascending: false }];
	} else {
		switch (valueType) {
			case 'contributionMargin':
				return [{ attribute: 'cmLast12Months', ascending: false }];
			case 'annualValue':
				return [{ attribute: 'arr', ascending: false }];
			case 'monthlyValue':
				return [{ attribute: 'mrr', ascending: false }];
			case 'value':
			default:
				return [{ attribute: 'salesLast12Months', ascending: false }];
		}
	}
}

const renderHeader = () => {
	const classes = new BemClass('CustomerPortfolio');
	return (
		<Block className={classes.elem('info-banner').b()} space="plxl ptxl pbxl" backgroundColor="super-light-blue">
			<Text color="blue">
				<Icon name="question-circle" space="mrl" />
				{T('customerPortfolio.risks.creditRatingInfo')}
			</Text>
			<Text color="blue">
				<Icon name="info" />
			</Text>
		</Block>
	);
};

type Props = {
	tab: TabsEnum;
	valueType: DisplayValueType;
	filters: ListViewFilterMap;
};

const CustomerPortfolioList = ({ tab, valueType, filters }: Props) => {
	const classes = new BemClass('CustomerPortfolio');
	const cutCorners = useSoftDeployAccess('FASTER_PORTFOLIO_QUERY');

	const defaultCurrency = getMetadata()!.defaultCurrency;

	const getData: ListViewProps['getData'] = async (
		rb
	): Promise<{
		data: CustomerPortfolioCompany[];
		metadata: {
			total: number;
		};
	}> => {
		// Had been nicer if you could configure this with the ListView attribute but it does not support "multiple columns" or "missing" and I do not have time to implement that now.
		if (rb._sorting[0]?.attribute === 'prospectingCreditRating') {
			const ascending = rb._sorting[0].ascending;
			const missing = 'last';
			rb._sorting = [
				{ attribute: 'prospectingCreditRating', ascending, missing },
				{ attribute: 'prospectingNumericCreditRating', ascending: !ascending, missing }
			];
		}

		const includeLostCustomers = tab === TabsEnum.Customers ? true : false;
		const rbWithFilters = parseFiltersForRequest(filters, rb);
		const result = await getCustomerTableData(rbWithFilters, valueType, includeLostCustomers).promise;

		const currencyFields = [
			'salesLast12Months',
			'cmLast12Months',
			'salesTrend12Months',
			'cmTrend12Months',
			'arrLast12Months',
			'arr',
			'mrrLast12Months',
			'mrr'
		] as const;
		for (const company of result.data) {
			company.currency = defaultCurrency.iso;
			for (const field of currencyFields) {
				const value = company[field];
				if (typeof value === 'number') {
					company[field] = Math.round(value * defaultCurrency.rate);
				}
			}
		}
		return result;
	};

	const customerColumns = ['name', 'sniCode', 'users'];
	if (!cutCorners) {
		customerColumns.push('customerSince');
	}
	const riskColumns = ['name', 'prospectingCreditRating', 'prospectingNumericCreditRating', 'users', 'sniCode'];

	if (valueType === 'value') {
		customerColumns.splice(1, 0, 'salesLast12Months', 'salesTrend12Months');
		riskColumns.splice(1, 0, 'salesLast12Months');
	} else if (valueType === 'annualValue') {
		customerColumns.splice(1, 0, 'arr', 'arrLast12Months');
		riskColumns.splice(1, 0, 'arr');
	} else if (valueType === 'monthlyValue') {
		customerColumns.splice(1, 0, 'mrr', 'mrrLast12Months');
		riskColumns.splice(1, 0, 'mrr');
	} else if (valueType === 'contributionMargin') {
		customerColumns.splice(1, 0, 'cmLast12Months', 'cmTrend12Months');
		riskColumns.splice(1, 0, 'cmLast12Months');
	}

	const columns = tab === TabsEnum.Customers ? customerColumns : riskColumns;
	const showCreditRiskInfoBanner = tab === TabsEnum.Risk;
	const key = tab + JSON.stringify(filters) + valueType; // We should not have to do this for filters changing, LiwView is to hard coupled with the sidebar filters atm.

	const renderTableRow = useMemo(() => getRenderTableRow(tab, valueType), [tab, valueType]);
	const initialSorting = useMemo(() => getInitialSorting(tab, valueType), [tab, valueType]);

	return (
		<ListView<CustomerPortfolioCompany>
			key={key}
			className={classes.elem('company-list-widget').b()}
			getData={getData}
			initialFilters={filters}
			columns={columns}
			renderTableRow={renderTableRow}
			renderHeader={showCreditRiskInfoBanner ? renderHeader : undefined}
			attributes={CompanyPortfolioAttributes}
			renderToolsColumn={false}
			initialSorting={initialSorting}
			skipSortById={true}
			onSortChange={sort =>
				customerPorfolioTracker.track(customerPorfolioTracker.events.SORT_CHANGED, { tab, ...sort })
			}
		/>
	);
};

export default CustomerPortfolioList;
