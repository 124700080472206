import Prospecting from 'App/babel/resources/Prospecting';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import openModal from 'App/services/Modal';
import { SET_LOADING } from './AppReducer';

export const initialState = {
	account: null,
	loading: true,
	relations: [],
	customRelations: [],
	siblings: [],
	subsidiaries: [],
	soliditetData: {},
	hasLoaded: false,
	tree: null,
	unknowns: null,
	unbought: [],
	showGroupSize: false,
	showCommercial: false
};

export const actions = {
	INIT: 'INIT',
	SET_DATA: 'SET_DATA',
	RESET: 'RESET',
	RESET_EXCEPT_ACCOUNT: 'RESET_EXCEPT_ACCOUNT',
	SET_LOADING: 'SET_LOADING'
};

const actionPrefix = '[Account]';
Object.keys(actions).forEach(key => {
	actions[key] = `${actionPrefix} ${actions[key]}`;
});

export default (state = initialState, action) => {
	switch (action.type) {
		case actions.INIT:
			return { ...state, ...action.data };
		case actions.RESET:
			return { ...state, ...initialState };
		case actions.RESET_EXCEPT_ACCOUNT:
			return { ...state, ...initialState, account: state.account };
		case actions.SET_LOADING:
			return { ...state, loading: action.data };
		default:
			return state;
	}
};

const Config = {
	Prospecting: {
		id: 'prospecting',
		idField: 'prospectingId',
		companyGroup: {
			groupIdField: 'orgNumber',
			parentField: 'closestGroupMotherOrgnumber',
			ultimateParentField: 'groupMotherOrgnumber'
		},
		pricing: null
	},
	Soliditet: {
		id: 'soliditet',
		idField: 'dunsNo',
		companyGroup: {
			groupIdField: 'dunsNo',
			parentField: 'parentDuns',
			ultimateParentField: 'rootParentDuns'
		},
		pricing: null
	}
};

const findAll = (resource, rb) => {
	return new Promise(resolve => {
		const result = [];
		const limit = 1000;

		const get = async offset => {
			rb.offset = offset;
			rb.limit = limit;

			const response = await resource.find(rb.build());
			result.push(response.data);

			if (response.data.length === limit) {
				get(offset + limit);
			} else {
				return resolve(_.flatten(result));
			}
		};

		get(0);
	});
};

const mapSoliditetToExistingUpsales = soliditetAccounts => {
	var dunses = _.map(soliditetAccounts, 'dunsNo');
	var dunsFilter = new Tools.RequestBuilder();

	if (!dunses.length) {
		return soliditetAccounts;
	}

	dunsFilter.addFilter(Tools.Account.attr.dunsNo, dunsFilter.comparisonTypes.Equals, dunses);
	dunsFilter.addFilter(Tools.Account.attr.isExternal, dunsFilter.comparisonTypes.Equals, false);

	return findAll(Tools.Account.customer(Tools.AppService.getCustomerId()), dunsFilter).then(function (
		upsalesAccounts
	) {
		_.forEach(upsalesAccounts, function (upsalesClient) {
			var index = _.findIndex(soliditetAccounts, function (soliditetClient) {
				return parseInt(upsalesClient.dunsNo) === soliditetClient.dunsNo;
			});

			if (index !== -1) {
				upsalesClient.existing = true;
				soliditetAccounts[index] = Object.assign({}, soliditetAccounts[index], upsalesClient);
			}
		});

		return soliditetAccounts;
	});
};

const getGroupData = async (customerId, duns) => {
	const rb = new Tools.RequestBuilder();
	const or = rb.orBuilder();

	or.next();
	or.addFilter(Tools.SoliditetClient.attr.rootParentDuns, rb.comparisonTypes.Equals, duns);
	or.next();
	or.addFilter(Tools.SoliditetClient.attr.dunsNo, rb.comparisonTypes.Equals, duns);
	or.done();

	const response = await findAll(Tools.SoliditetClient.customer(customerId), rb);
	return mapSoliditetToExistingUpsales(response);
};

export const init =
	(account, fetchRelations = true) =>
	async (dispatch, getState) => {
		try {
			// This is a quite ugly hack to avoid fetching unnecessary data in the subaccounts since the AccountRelations resource does a fetch
			// for each account in the list and if you have many reations it will be slow. Maybe we should try to optimize this resource.
			const { relations: oldRelations } = getState().Account;
			let relations = [];
			if (fetchRelations) {
				relations = await Tools.AccountRelations.get(account);
			} else {
				relations = oldRelations;
			}
			const soliditetIsActive = Tools.AppService.getSelf().userParams.soliditetIsActive;
			const hasGroupSize = Tools.FeatureHelper.isAvailable('GROUP_SIZE');
			const hasSubaccountsV2 =
				Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS_V2') &&
				Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS');
			const hasProspectingBasic = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_BASIC);
			const hasProspectingPro = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_PRO);
			const showCommercial = !hasGroupSize && !hasProspectingPro;
			const showGroupSize =
				showCommercial ||
				(account.prospectingId && hasProspectingPro) ||
				(account.dunsNo && soliditetIsActive && hasGroupSize);
			const data = {
				relations: [...relations],
				loading: false,
				customRelations: [..._.where(relations, { connected: true })],
				siblings: [..._.where(relations, { sibling: true })],
				subsidiaries: [..._.where(relations, { subsidiary: true })],
				account,
				soliditetData: {},
				companyGroupData: [],
				hasLoaded: true,
				tree: null,
				unknowns: null,
				config: null,
				size: {
					total: 1,
					branches: 0
				},
				unbought: [],
				showGroupSize,
				showCommercial
			};

			if (hasProspectingBasic && account.prospectingId) {
				data.config = Config.Prospecting;
				const rb = new RequestBuilder();
				rb.addFilter({ field: 'prospectingId' }, comparisonTypes.Equals, account.prospectingId);
				rb.extraParams.push({
					key: 'country',
					value: Prospecting.getCountryFromProspectingId(account.prospectingId)
				});
				const res = await Prospecting.findGroupstructure(rb.build());
				const tree = res.data[0];

				if (tree) {
					data.tree = tree;
					const res = { total: 0, unknowns: [], unbought: [] };
					fixProspectingData(res, tree);
					data.unknowns = res.unknowns;
					data.unbought = res.unbought;
					data.size.total = res.total;
					data.size.branches = res.unknowns.length;

					if (hasSubaccountsV2) {
						let totalNumberOfSubaccounts = 0;
						const traverseTree = node => {
							totalNumberOfSubaccounts += node.numberOfSubaccounts || 0;

							if (node.children) {
								node.children.forEach(child => {
									traverseTree(child);
								});
							}
						};

						traverseTree(tree);

						data.totalNumberOfSubaccounts = totalNumberOfSubaccounts;
					}
				} else {
					data.showGroupSize = false;
				}
			} else if (hasGroupSize && account.dunsNo) {
				data.config = Config.Soliditet;
				const customerId = Tools.AppService.getCustomerId();
				const res = await Tools.SoliditetClient.customer(customerId).find({ dunsNo: account.dunsNo });

				if (res.data.length) {
					data.soliditetData = res.data[0];
					const rootDuns = data.soliditetData.rootParentDuns || account.dunsNo;
					data.companyGroupData = await getGroupData(customerId, rootDuns);
					data.size.total = data.companyGroupData.length;

					const grouped = _.groupBy(data.companyGroupData, 'parentDuns');
					const nullGrouped = grouped['null'];
					if (nullGrouped) {
						const groupedAccounts = nullGrouped.filter(account => parseInt(account.dunsNo) !== rootDuns);
						data.size.branches = groupedAccounts.length;
					} else {
						data.size.branches = 0;
					}
					data.unbought = data.companyGroupData
						.filter(company => !company.hasOwnProperty('active'))
						.map(company => company.dunsNo);
				}
			}
			return dispatch({ type: actions.INIT, data });
		} catch (error) {
			console.error(error);
		}
	};

export const resetGroupSizeExceptAccount = () => dispatch => {
	dispatch({ type: actions.RESET_EXCEPT_ACCOUNT });
};

export const addAllAccounts = () => async (dispatch, getState) => {
	const { config, unbought, account } = getState().Account;
	const field = config.id === 'prospecting' ? 'prospectingId' : 'dunsNo';
	const rb = new Tools.RequestBuilder();
	rb.addFilter({ field }, rb.comparisonTypes.Equals, unbought);

	const modalParams = {
		customerId: Tools.AppService.getCustomerId(),
		existing: 0,
		total: unbought.length,
		customFields: _.filter(Tools.AppService.getCustomFields('account'), function (field) {
			return field.alias !== 'ORG_NO' && field.$hasAccess;
		}),
		metadata: Tools.AppService.getMetadata(),
		users: Tools.AppService.getUsers(),
		self: Tools.AppService.getSelf(),
		campaigns: [],
		categories: [],
		filters: rb.build()
	};

	if (config.id === 'prospecting') {
		modalParams.isProspecting = true;
		modalParams.country = Prospecting.getCountryFromProspectingId(account.prospectingId);
	}

	if (Tools.FeatureHelper.hasSoftDeployAccess('CONFIRM_BUY_PROSPECTS_REACT')) {
		openModal('ConfirmBuyProspectsModal', {
			...modalParams,
			onClose: didBuy => {
				if (didBuy) {
					dispatch(resetGroupSizeExceptAccount());
					dispatch(init(account));
				}
			}
		});
	} else {
		// eslint-disable-next-line promise/catch-or-return
		Tools.$upModal.open('confirmBuyProspects', modalParams).then(() => {
			dispatch(resetGroupSizeExceptAccount());
			dispatch(init(account));
		});
	}
};

export const addManyAccounts = (accounts, operationalAccountId) => async (dispatch, getState) => {
	const { account, config, unbought } = getState().Account;
	const toBuy = accounts.filter(account => unbought.includes(account.prospectingId));
	if (toBuy.length > 0) {
		const field = config.id === 'prospecting' ? 'prospectingId' : 'dunsNo';
		const rb = new Tools.RequestBuilder();
		rb.addFilter(
			{ field },
			rb.comparisonTypes.Equals,
			toBuy.map(acc => acc[field])
		);

		const modalParams = {
			customerId: Tools.AppService.getCustomerId(),
			operationalAccountId: operationalAccountId,
			existing: 0,
			total: toBuy.length,
			customFields: (Tools.AppService.getCustomFields('account') || []).filter(
				field => field.alias !== 'ORG_NO' && field.$hasAccess
			),
			metadata: Tools.AppService.getMetadata(),
			users: Tools.AppService.getUsers(),
			self: Tools.AppService.getSelf(),
			campaigns: [],
			categories: [],
			filters: rb.build()
		};

		if (config.id === 'prospecting') {
			modalParams.isProspecting = true;
			modalParams.country = Prospecting.getCountryFromProspectingId(account.prospectingId);
		}

		return Tools.$upModal
			.open('confirmBuyProspects', modalParams)
			.then(() => {
				dispatch(init(account));
				return true;
			})
			.catch(() => {
				return false;
			});
	}
	return true;
};

export const addAccount =
	({ externalId, customValues = [], resolve }) =>
	async (dispatch, getState) => {
		const { config, account } = getState().Account;

		if (config.id === 'soliditet') {
			const customerId = Tools.AppService.getCustomerId();
			const opts = {
				updateExisting: false,
				skipProjects: false,
				skipAccountManagers: false,
				skipAddresses: false,
				skipCategories: false
			};

			await Tools.SoliditetClient.customer(customerId).buy(externalId, customValues, opts);
		} else if (config.id === 'prospecting') {
			await Prospecting.save({ prospectingId: externalId, customValues });
		}

		if (resolve) {
			resolve();
		}
		dispatch(resetGroupSizeExceptAccount());
		dispatch(init(account));
	};

export const reducerMerge = (addingAccount, upsalesAccount, pricingKey, resolve) => async (dispatch, getState) => {
	const config = getState().Account.config;

	if (config.id === 'soliditet') {
		const customerId = Tools.AppService.getCustomerId();
		const action = {
			action: 'buy',
			id: upsalesAccount.id,
			dunsNo: addingAccount.account.dunsNo
		};

		await Tools.SoliditetClient.customer(customerId).updateMatches({ buy: [action] });
	} else if (config.id === 'prospecting') {
		await Prospecting.save({
			id: upsalesAccount.id,
			prospectingId: addingAccount.account.prospectingId
		});
	}

	if (resolve) {
		resolve();
	}

	dispatch(resetGroupSizeExceptAccount());
	dispatch(init(getState().Account.account));
};

export const resetGroupSize = () => dispatch => {
	dispatch({ type: actions.RESET });
};

export const setLoading = loading => dispatch => dispatch({ type: SET_LOADING, data: loading });

export const updateRelations = () => async (dispatch, getState) => {
	const account = getState().Account.account;
	const relations = await Tools.AccountRelations.get(account);

	const data = {
		relations: [...relations],
		customRelations: [..._.where(relations, { connected: true })],
		siblings: [..._.where(relations, { sibling: true })],
		subsidiaries: [..._.where(relations, { subsidiary: true })]
	};

	return dispatch({ type: actions.INIT, data });
};

// Don't pass unmutable objects here
export function fixProspectingData(res, item) {
	res.total++;

	if (item.matchInUpsales) {
		Object.assign(item, item.matchInUpsales, { existing: item.matchInUpsales });
	} else if (item.prospectingId) {
		res.unbought.push(item.prospectingId);
		res.unboughtKnown?.push(item.prospectingId);
	}
	for (const branch of item.branches) {
		res.unknowns.push(branch);
		res.total++;
		if (branch.matchInUpsales) {
			Object.assign(branch, branch.matchInUpsales, { existing: branch.matchInUpsales });
		} else if (branch.prospectingId) {
			res.unbought.push(branch.prospectingId);
		}
	}
	for (const child of item.children) {
		fixProspectingData(res, child);
	}
	return res;
}
