import React from 'react';
import UserStack from 'App/components/UserStack';

export default function AccountManagerColumn({ account }) {
	if (!account.users) {
		return null;
	}

	return (
		<td className="DataSourceColumn accountManager">
			<UserStack users={account.users} />
		</td>
	);
}
