import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import logError from 'App/babel/helpers/logError';
import {
	DrawerHeader,
	Row,
	Column,
	Text,
	Button,
	Card,
	CardContent,
	CardHeader,
	Link,
	Icon,
	TableHeader,
	Table,
	TableColumn,
	TableRow,
	Toggle
} from '@upsales/components';
import _ from 'lodash';
import BemClass from '@upsales/components/Utils/bemClass';
import DrawerWrap from 'Components/DrawerWrap';
import T from 'Components/Helpers/translate';
import './DeactivateUserDrawer.scss';

const mapStateToProps = ({ Billing }) => ({
	maxLicences: Billing.maxLicences,
	userCount: Billing.userCount
});

const DeactivateUserDrawer = ({ visible, onClose, activeUsers, maxLicences, userCount, switchDrawer, nextPage }) => {
	const classes = new BemClass('DeactivateUserDrawer');

	const getAngularModule = require('../../angularHelpers/getAngularModule').default;
	const User = getAngularModule('User');

	const [licenceCount, setLicenceCount] = useState(userCount);
	const [loading, setLoading] = useState(false);
	const [users, setUsers] = useState(activeUsers);

	const toggleChanged = (user, e) => {
		const newLicenceCount = e ? licenceCount + 1 : licenceCount - 1;
		setLicenceCount(newLicenceCount);

		const newUser = { ...user, active: e ? 1 : 0 };
		setUsers(users.map(item => (item.id === user.id ? newUser : item)));
	};

	const onSubmit = async () => {
		setLoading(true);
		const clientId = Tools.AppService.getCustomerId();
		const updateUsers = users.filter(function (u) {
			return activeUsers.find(function (au) {
				return au.active !== u.active;
			});
		});
		const updated = updateUsers.map(async user => {
			return await User.update({ ...user, clientId });
		});
		Promise.all(updated)
			.then(() => {
				nextPage('deactivate');
			})
			.catch(function (e) {
				logError(e, 'Error occured while saving users');
			})
			.finally(() => setLoading(false));
	};
	const handleClose = () => {
		setLicenceCount(userCount);
		setUsers(activeUsers);
		onClose();
	};

	var groupedUsers = _.groupBy(users, function (user) {
		return user.role ? user.role.name : null;
	});

	const self = Tools.AppService.getSelf();

	const renderRows = users => {
		return users?.map(user => {
			return (
				<TableRow key={user.id} onClick={() => {}}>
					<TableColumn className={classes.elem('user-avatar').b()}>
						<ReactTemplates.TOOLS.avatar user={user} />
					</TableColumn>
					<TableColumn className={classes.elem('user-name').b()} title={user.name} />
					<TableColumn align="right">
						<Toggle
							color="bright-blue"
							checked={user.active === 1}
							disabled={self.id === user.id}
							onChange={e => toggleChanged(user, e)}
						/>
					</TableColumn>
				</TableRow>
			);
		});
	};

	const renderGroup = (users, key) => {
		return (
			<React.Fragment key={key}>
				<TableRow key={key} disabled>
					<TableColumn className={classes.elem('folder-icon').b()}>
						<Icon name="folder-open" color="grey-10" />
					</TableColumn>
					<TableColumn className={classes.elem('role-title').b()}>
						<Text color="grey-10">{key}</Text>
					</TableColumn>
					<TableColumn className={classes.elem('group-icon').b()}>
						<Icon name="user" color="grey-10" />
						<Text color="grey-10">{users.length}</Text>
					</TableColumn>
				</TableRow>
				{renderRows(users)}
			</React.Fragment>
		);
	};

	const licenceDiff = licenceCount - maxLicences;

	return (
		<DrawerWrap className={classes.b()} isMounted={visible} onClose={handleClose}>
			<DrawerHeader
				icon="users"
				iconColor="blue"
				onHide={handleClose}
				title={T('admin.deactivateUserDrawer.deactivateUser')}
			>
				<Column align="right">
					<Button
						className={classes.elem('disable_btn').b()}
						size="sm"
						onClick={onSubmit}
						disabled={licenceCount - maxLicences > 0}
						loading={loading}
					>
						{T('admin.deactivateUserDrawer.deactivateUser')}
					</Button>
				</Column>
			</DrawerHeader>
			{licenceDiff > 0 ? (
				<Card className={classes.elem('info-card').b()}>
					<CardContent>
						<Row>
							<Column fixedWidth={40}>
								<Icon name="info-circle" size="lg" className={classes.elem('info-icon').b()} />
							</Column>
							<Column>
								<Row>
									<Text size="sm" color="grey-10">
										{licenceDiff === 1
											? T('admin.deactivateUserDrawer.licenceDiff', {
													maxLicences,
													licenceCount,
													licenceDiff
											  })
											: T('admin.deactivateUserDrawer.licenceDiff.plural', {
													maxLicences,
													licenceCount,
													licenceDiff
											  })}
										{maxLicences === 1
											? T('admin.deactivateUserDrawer.maxLicences', {
													maxLicences,
													licenceCount,
													licenceDiff
											  })
											: T('admin.deactivateUserDrawer.maxLicences.plural', {
													maxLicences,
													licenceCount,
													licenceDiff
											  })}
										{licenceCount === 1
											? T('admin.deactivateUserDrawer.licenceCount', {
													maxLicences,
													licenceCount,
													licenceDiff
											  })
											: T('admin.deactivateUserDrawer.licenceCount.plural', {
													maxLicences,
													licenceCount,
													licenceDiff
											  })}
									</Text>
								</Row>
								<Row>
									<Link size="sm" onClick={switchDrawer}>
										{T('admin.deactivateUserDrawer.youCanAddSeats')}
									</Link>
								</Row>
							</Column>
						</Row>
					</CardContent>
				</Card>
			) : null}
			<Card className={classes.elem('card-wrapper').b()}>
				<CardHeader
					title={T('admin.activeUsers')}
					subtitle={T('admin.deactivateUserDrawer.activeUsers', {
						licenceCount,
						maxLicences
					})}
				/>
				<CardContent className={classes.elem('card-content').b()}>
					<Table>
						<TableHeader
							className={classes.elem('table-title').b()}
							columns={[{ title: '' }, { title: '' }, { title: T('admin.active') }]}
						/>
						{renderRows(groupedUsers.null)}
						{Object.keys(groupedUsers).map(key => {
							if (key !== 'null') {
								return renderGroup(groupedUsers[key], key);
							}
						})}
					</Table>
				</CardContent>
			</Card>
		</DrawerWrap>
	);
};

DeactivateUserDrawer.propTypes = {
	visible: PropTypes.bool,
	activeUsers: PropTypes.array
};

export const detached = DeactivateUserDrawer;
export default connect(mapStateToProps)(DeactivateUserDrawer);
