import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import openModal from 'App/services/Modal/Modal';
import translate from 'Components/Helpers/translate';

CKEDITOR.plugins.add('tags', {
	init: editor => {
		editor.addCommand('insertTag', {
			// create named command
			exec: function (editor) {
				const tagOpts = {
					socialEvent: editor.config.pluginParams?.tags?.socialEvent,
					tagEntity: editor.config.pluginParams?.tags?.tagEntity
				};
				const hasInsertTagPorted = Tools.FeatureHelper.hasSoftDeployAccess('REACT_INSERT_TAG');
				if (hasInsertTagPorted) {
					openModal('InsertTag', {
						...tagOpts,
						onClose: selectedTag => {
							if (selectedTag) {
								if (selectedTag.html) {
									editor.insertHtml(selectedTag.value);
								} else {
									editor.insertText(selectedTag.value);
								}
							}
						}
					});
				} else {
					const $upModal = getAngularModule('$upModal');
					// eslint-disable-next-line promise/catch-or-return
					$upModal.open('insertTag', tagOpts).then(function (selectedTag) {
						if (selectedTag.html) {
							editor.insertHtml(selectedTag.value);
						} else {
							editor.insertText(selectedTag.value);
						}
					});
				}
			}
		});

		editor.ui.addButton('InsertTag', {
			label: translate('editor.insertTag'),
			command: 'insertTag',
			toolbar: 'tags'
		});
	},
	afterInit: editor => {
		setTimeout(() => {
			const btnLabel = document.querySelectorAll(`#cke_${editor.name} .cke_button__inserttag_label`)[0];
			const btnIcon = document.querySelectorAll(`#cke_${editor.name} .cke_button__inserttag_icon`)[0];
			if (btnLabel) {
				btnLabel.style.display = 'inline';
				btnLabel.style.paddingLeft = '0';
				btnIcon.style.display = 'none';
			}
		}, 100);
	}
});
