import BemClass from '@upsales/components/Utils/bemClass';
import { Modal } from '@upsales/components';
import React from 'react';

import './GenericModal.scss';
import { ModalProps } from '../Modals/Modals';
import { ModalProps as UIModalProps } from '@upsales/components/Modal/Modal';
import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';

type Props<T = any> = ModalProps & {
	ChildComponent: React.ComponentType<T>;
	style?: UIModalProps['style'];
	size?: UIModalProps['size'];
	modalClasses?: string;
	fullscreen?: boolean;
};

type OpenGenericModalProps = {
	Component: React.ComponentType<any>;
	className?: string;
	[key: string]: any;
};

const GenericModal: React.FC<Props> = ({
	close,
	className,
	ChildComponent,
	style,
	size,
	modalClasses,
	fullscreen,
	...props
}) => {
	const classes = new BemClass('GenericModal', className);

	if (fullscreen) {
		classes.add('FullscreenModal');
	}

	if (modalClasses) {
		classes.add(modalClasses);
	}

	return (
		<Modal className={classes.b()} style={style} size={size}>
			<ChildComponent {...props} resolve={close} reject={close} />
		</Modal>
	);
};

const openGeneric = asyncModalAdapter({
	featureFlag: 'GENERIC_MODAL_REACT',
	openModalName: 'GenericModal',
	upModalName: 'generic',
	rejectOnEvent: true
});

export const openGenericModal = (props: OpenGenericModalProps) => {
	return openGeneric({
		...props,
		/* Map props which are overridden by openModal */
		ChildComponent: props.Component,
		modalClasses: props.className
	});
};

export default GenericModal;
