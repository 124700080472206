import Client from 'App/resources/Model/Client';
import React, { useRef, useState, MutableRefObject, useEffect } from 'react';
import AddSubaccount from './Views/Add';
import { init, setLoading } from 'Store/reducers/AccountReducer';
import { useGetData } from 'App/components/hooks';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import ClientAttributes from 'App/babel/attributes/Client';
import ClientResource from 'App/resources/Client';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import moment from 'moment';
import logError from 'Helpers/logError';
import { NOTIFICATION_TYPES } from 'App/babel/enum/notification';
import { connect } from 'react-redux';

export type DrawerClient = Pick<Client, 'id' | 'name'> & { hasAccess: boolean };

export enum AddSubaccountView {
	Add,
	Preview
}

type Props = {
	client: DrawerClient;
	modalId: number;
	goBack: () => void;
	close: () => void;
	initAccountReducer: (account: Client, fetchRelations: boolean) => void;
	setLoadingAccount: (loading: boolean) => void;
};

type NotificationReduced = {
	action: string;
	status: number;
};

type NotificationResponse = {
	data: NotificationReduced[];
};

export const fetcher = (rb: RequestBuilder, { clientId }: { clientId: number }) => {
	rb.addFilter(ClientAttributes.operationalAccount.attr.id, comparisonTypes.Equals, clientId);
	rb.fields = ['id', 'name'];
	return ClientResource.find(rb.build());
};

const SubaccountProvider = ({ goBack, client, modalId, close, initAccountReducer, setLoadingAccount }: Props) => {
	const [pendingPurchase, setPendingPurchase] = useState<NotificationReduced | null>(null);

	const notificationsRef = useRef<CancelablePromise>(null) as MutableRefObject<CancelablePromise>;

	const { data: subaccounts } = useGetData({
		broadcastTypes: ['account.updated', 'account.deleted', 'account.subaccountsAdded'],
		broadcastWaitForIndex: true,
		fetcher,
		fetcherProps: {
			clientId: client.id
		},
		limit: 1000,
		offset: 0
	});

	useEffect(() => {
		if (client) {
			setLoadingAccount(true);
			initAccountReducer(client as unknown as Client, false);
		}
	}, [subaccounts, client]);

	useEffect(() => {
		const NOTIFICATION_ACTIONS = [
			'prospecting-save-bulk:prospecting-ai',
			'prospecting-save-bulk:prospecting-grouptree',
			'prospecting-save-bulk:prospecting-manual',
			'soliditet-buy-multiple-clients'
		];
		/**
		 * Need to check if there is a pending purchase running, if so we need to show the banner
		 * and hide the unbought companies from the list
		 */
		let unsub: (() => void) | null = null;
		const getNotifications = () => {
			notificationsRef.current?.cancel?.();
			const filters = new RequestBuilder();
			filters.addFilter({ field: 'type' }, comparisonTypes.Equals, 'job');
			filters.addFilter({ field: 'status' }, comparisonTypes.NotEquals, [100, -1]);
			filters.addFilter({ field: 'action' }, comparisonTypes.Wildcard, NOTIFICATION_ACTIONS);
			filters.addFilter(
				{ field: 'date' },
				comparisonTypes.GreaterThan,
				moment().subtract(1, 'days').format('YYYY-MM-DD')
			);

			notificationsRef.current = makeCancelable(
				Tools.PushNotifications.customer(Tools.AppService.getCustomerId()).find(filters.build())
			);

			return notificationsRef.current.promise
				.then((response: NotificationResponse) => {
					if (!response.data?.length) {
						setPendingPurchase(null);
						return;
					}
					setPendingPurchase(response.data[0]);
				})
				.catch(err => {
					if (!err.isCanceled) {
						logError(err);
					}
				});
		};
		const subscribeToNotifications = () => {
			unsub = Tools.$rootScope.$on('pushNotification.data', (e, data) => {
				if (data.type === NOTIFICATION_TYPES.JOB && NOTIFICATION_ACTIONS.includes(data.action)) {
					getNotifications();
				}
			});
		};

		getNotifications();
		subscribeToNotifications();

		return () => {
			unsub?.();
			notificationsRef.current?.cancel?.();
		};
	}, []);

	return (
		<AddSubaccount
			client={client}
			closeDrawer={close}
			modalId={modalId}
			goBack={goBack}
			subaccounts={subaccounts}
			pendingPurchase={pendingPurchase}
		/>
	);
};

const mapDispatchToProps = {
	initAccountReducer: init,
	setLoadingAccount: setLoading
};

export const detached = SubaccountProvider;
const Component = connect(null, mapDispatchToProps)(SubaccountProvider);
export default Component;
