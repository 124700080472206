import React, { Component } from 'react';
import { Headline, Button, Text, Icon } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import Lottie from 'react-lottie';
import fireworks from './fireworks.json';
import champagne from './champagne.json';
import T from 'Components/Helpers/translate';
import { PulseScale } from 'App/components/animations';

import './FirstScreen.scss';

type Props = {
	active: boolean;
	next: () => void;
};

class FirstScreen extends Component<Props> {
	lang: {
		whatAYear: string;
		showSummary: string;
	};

	constructor(p: Props) {
		super(p);
		this.lang = {
			whatAYear: T('yourYearWithUpsales.whatAYear'),
			showSummary: T('yourYearWithUpsales.showSummary')
		};
	}

	renderFireWorks(active: boolean) {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: JSON.parse(JSON.stringify(fireworks)),
			rendererSettings: {
				preserveAspectRatio: 'xMidYMid slice',
				className: 'FirstScreen__fireworks'
			}
		};

		return (
			<div className={'FirstScreen__fireWorks'}>
				<Lottie options={defaultOptions} isClickToPauseDisabled={true} isStopped={active} />
			</div>
		);
	}

	renderChampagne(active: boolean) {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: JSON.parse(JSON.stringify(champagne)),
			rendererSettings: {
				preserveAspectRatio: 'xMidYMid slice',
				className: 'FirstScreen__champagne'
			}
		};

		return (
			<div className={'FirstScreen__champagne'}>
				<Lottie options={defaultOptions} isClickToPauseDisabled={true} isStopped={active} />
			</div>
		);
	}

	render() {
		const classNames = new bemClass('FirstScreen');
		const { active, next } = this.props;
		return (
			<div
				className={classNames.b()}
				onClick={e => {
					e.stopPropagation();
					next();
				}}
			>
				{this.renderFireWorks(active)}
				<div className={classNames.elem('con').b()}>
					<div>
						<Headline>{this.lang.whatAYear}</Headline>
						{this.renderChampagne(active)}
					</div>
					<PulseScale>
						<Button
							type="link"
							onClick={e => {
								e.stopPropagation();
								next();
							}}
						>
							<Text>
								{this.lang.showSummary}

								<Icon name="arrow-right" space="mlm" />
							</Text>
						</Button>
					</PulseScale>
				</div>
			</div>
		);
	}
}

export default FirstScreen;
