import Resource from 'Resources/ResourceTyped';
import type TicketModel from './Model/Ticket';
import type Client from './Model/Client';
import type Contact from './Model/Contact';

type TicketSave = PartialOmit<
	TicketModel,
	'status' | 'contact' | 'type' | 'client' | 'involved' | 'activity' | 'appointment' | 'opportunity'
> & {
	status?: PartialPick<TicketModel['status'], 'id'>;
	type?: PartialPick<TicketModel['type'], 'id'>;
	contact?: PartialPick<Contact, 'id'> | null;
	client?: PartialPick<Client, 'id'> | null;
	activity?: { id: number } | null;
	appointment?: { id: number } | null;
	opportunity?: { id: number } | null;
	involved?: { type: 'TO' | 'CC'; email: string; contact: PartialPick<Contact, 'id'> | null }[];
};

class Ticket extends Resource<TicketModel, TicketModel, TicketSave> {
	eventName = 'ticket';
	constructor() {
		super('tickets');

		this.notifications = {
			save: () => ({
				title: 'default.saved',
				body: 'saved.ticket',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			saveError: () => ({
				title: 'default.error',
				body: 'saveError.ticket',
				style: 'error',
				icon: 'times',
				type: 'body'
			}),
			delete: () => ({
				title: 'default.deleted',
				body: 'deleted.ticket',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			deleteError: () => ({
				title: 'default.error',
				body: 'deleteError.ticket',
				style: 'error',
				icon: 'times',
				type: 'body'
			})
		};
	}

	async save(
		data: TicketSave & { attachments?: any[] },
		opts: {
			params?: { skipEmail?: boolean };
			skipNotification?: boolean;
			skipEvent?: boolean;
		} = {}
	): ReturnType<Resource<TicketModel>['save']> {
		const saveObj = { ...data };
		if (saveObj.contact) {
			saveObj.contact = { id: saveObj.contact.id, name: saveObj.contact.name };
		}
		if (saveObj.client) {
			saveObj.client = { id: saveObj.client.id, name: saveObj.client.name };
		}

		return super.save(saveObj, { ...opts, parseFiles: true, useNewMapDates: true, fileKeys: ['attachments'] });
	}

	async addComment(
		id: number,
		message: string,
		attachments: any[],
		type: string,
		statusId?: number,
		externalId?: number
	) {
		const formData = new FormData();
		formData.append('message', message);
		formData.append('type', type);

		if (externalId) {
			formData.append('externalId', externalId.toString());
		}

		if (statusId) {
			formData.append('statusId', statusId.toString());
		}

		for (const attachment of attachments) {
			if (attachment.blob) {
				formData.append('file', attachment.blob);
			} else {
				formData.append(
					'attachments',
					JSON.stringify({ filename: attachment.filename, value: attachment.value })
				);
			}
		}

		return this._postRequest(id + '/comment', formData, { methodName: 'addComment' }).then(({ data }) => {
			return data;
		});
	}
}

const resource = new Ticket();

export default resource;
