import { SalesboardListView } from 'App/resources/AllIWant';
import Role from 'App/resources/Model/Role';

type SalesboardHelperProps = {
	salesboards: SalesboardListView[];
	roles: Role[];
	currentRoleId?: number;
};

interface FormatedSalesboard {
	id: number | string | null;
	name: string;
}

export interface SalesboardHelperInterFace {
	salesboards: SalesboardListView[];
	roles: Role[];
	currentRoleId?: number;

	getFormatedSalesboards(): FormatedSalesboard[] | undefined;
	getDefaultSalesboard(): FormatedSalesboard | undefined;
	hasMultiSalesboards(): boolean;
}

export class SalesboardHelper implements SalesboardHelperInterFace {
	salesboards;
	roles;
	currentRoleId;

	constructor({ salesboards, roles, currentRoleId }: SalesboardHelperProps) {
		this.salesboards = salesboards;
		this.roles = roles;
		this.currentRoleId = currentRoleId;
	}

	getFormatedSalesboards() {
		// Unecessary sales board data is omited
		if (!this.salesboards || !this.salesboards.length) return;

		const standardSalesboards = this.salesboards.filter(({ standard }) => standard);

		const formatedSalesboards: FormatedSalesboard[] = standardSalesboards.map(({ id, title }) => ({
			id,
			name: title
		}));

		//Get right name for standard sales board and set id to -1

		const standardIndex = formatedSalesboards.findIndex(standard => standard.id === 'standard1');

		if (standardIndex >= 0) {
			formatedSalesboards[standardIndex].name = Tools.$translate(formatedSalesboards[standardIndex].name);
			formatedSalesboards[standardIndex].id = -1;
		}

		return formatedSalesboards;
	}

	getDefaultSalesboard() {
		if (!this.roles || !this.currentRoleId) return;
		// Trying to get the role to be edited
		const currentRole = this.roles.find(role => role.id === Number(this.currentRoleId));
		if (!currentRole) return;
		const salesboards = this.getFormatedSalesboards();

		// If current role got a defaultSalesboard we'll get it here
		if (!salesboards) return;

		const defaultSalesboard = salesboards.find(salesboard => currentRole.defaultSalesboardId === salesboard.id);
		return defaultSalesboard;
	}

	hasMultiSalesboards() {
		if (!this.salesboards || !this.salesboards.length) return false;
		return this.salesboards.length > 1;
	}
}
