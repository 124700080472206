import RequestBuilder from 'Resources/RequestBuilder';
import type { MultiSelect } from 'App/components/ListView/ListViewRenderHelpers';
import { openMultiUpdateContact } from 'App/components/MultiActionModal/MultiUpdateContact/MultiUpdateContact';

export default function updateContacts(
	rb: RequestBuilder,
	clientContacts: boolean = false,
	entity: string,
	extraParams: object | undefined,
	multiSelect: MultiSelect
) {
	const opts = {
		customerId: Tools.AppService.getCustomerId(),
		filters: rb,
		extraParams,
		clientContacts,
		entity,
		multiSelect
	};
	return openMultiUpdateContact(opts);
}
