import './ConfirmBuyProspects.scss';
import React, { useState, useEffect } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import { Text, Title, Icon, ModalContent, Select, Link, Flex, Toggle } from '@upsales/components';
import { ModalProps } from 'App/components/Modals/Modals';
import { makeCancelable } from 'App/babel/helpers/promise';
import User from 'App/resources/Model/User';
import Category from 'App/resources/Model/Category';
import Project, { ProjectIdName } from 'App/resources/Model/Project';
import { CustomFieldWithValue } from 'App/resources/Model/CustomField';
import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import logError from 'App/babel/helpers/logError';
import Prospecting from 'App/babel/resources/Prospecting';
import { getCustomFieldValue } from 'Services/ActionProperties';
import { Self, Metadata } from 'App/resources/AllIWant';
import _ from 'lodash';

export interface Props extends ModalProps<string | null> {
	campaigns: Project[];
	categories: Category[];
	onClose: (didBuy: boolean) => void;
	country: string;
	customFields: CustomFieldWithValue[];
	customerId: number;
	existing: number;
	filters: FilterConfig[];
	isProspecting: boolean;
	metadata: Metadata;
	self: Self;
	total: number;
	users: User[];
	className: string;
}

const ConfirmBuyProspects = (props: Props) => {
	const { country, customFields, customerId, existing, filters, isProspecting, metadata, self, total, users } = props;

	const classes = new BemClass('ConfirmBuyProspects', props.className);
	const anchor = document.querySelector('.Modals');

	const newAccounts = total - existing;
	const showCustomFields = customFields.length > 0;
	const credits = metadata.soliditetCredits;
	const multipleAccountManagers = metadata.params.teamAccountManager;
	const arrayProperties = ['Users', 'Projects', 'Categories'];

	const [saving, setSaving] = useState(false);
	const [updateExisting, setUpdateExisting] = useState(existing > 0 ? true : false);
	const [selectedUsers, setSelectedUsers] = useState<{ id: User['id']; title: User['name'] }[]>([]);
	const [selectedUser, setSelectedUser] = useState<{ id: User['id']; title: User['name'] } | null>(null);
	const [selectedCampaigns, setSelectedCampaigns] = useState<ProjectIdName[]>([]);
	const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
	const [displayFields, setDisplayFields] = useState(
		customFields.filter(field => field.obligatoryField && field.editable)
	);
	const [selectableFields, setSelectableFields] = useState(
		customFields.filter(field => !field.obligatoryField && field.editable)
	);
	const [requiredFieldsFilled, setRequiredFieldsFilled] = useState<{ [key: string]: boolean }>(
		displayFields.reduce((acc, field) => ({ ...acc, [field.id]: false }), {})
	);
	const [properties, setProperties] = useState<{
		[key: string]: any[];
	}>({
		User: [],
		Project: [],
		Categories: []
	});

	const cost = updateExisting ? total : newAccounts;

	const close = (didBuy: string | null) => {
		props.close(didBuy);
	};

	const getProperties = function () {
		let reduceProperties = [
			{
				key: 'User',
				singular: 'import.fields.client.title',
				plural: 'import.fields.client.title'
			},
			{
				key: 'Project',
				singular: 'default.addToCampaign',
				plural: 'default.addToXCampaigns'
			},
			{
				key: 'Categories',
				singular: 'default.addToCategory',
				plural: 'default.addToXCategories'
			}
		].reduce(function (res, config) {
			const value = properties[config.key];
			const length = value ? value.length : 0;

			if (length) {
				const langTag =
					length > 1 ? T(config.plural, { value: length }) : T(config.singular, { value: length });
				res.push(langTag as never);
			}

			return res;
		}, []);

		reduceProperties = customFields.reduce((res, cf) => {
			const matchingField = displayFields.find(field => field.id === cf.id && field.value);
			if (matchingField) {
				res.push((T('default.update') + ' ' + cf.name) as never);
			}
			return res;
		}, reduceProperties);

		return reduceProperties;
	};

	const selectMe = () => {
		const user = users.find(user => user.id === self.id);
		if (multipleAccountManagers) {
			if (user && !selectedUsers.some(selectedUser => selectedUser.id === user.id)) {
				setSelectedUsers(user ? [...selectedUsers, { id: user.id, title: user.name }] : selectedUsers);
			}
		} else {
			setSelectedUser(user ? { id: user.id, title: user.name } : null);
		}
	};

	const createCampaign = async () => {
		return Tools.$upModal
			.open('editCampaign', { customerId: customerId, noRedirect: true })
			.then(function (campaign) {
				if (campaign) {
					const updatedCampaigns = [...selectedCampaigns, campaign];
					setSelectedCampaigns(updatedCampaigns);
				}
			});
	};

	useEffect(() => {
		if (multipleAccountManagers) {
			setProperties({ ...properties, User: [] });
		}
	}, []);

	useEffect(() => {
		setProperties({
			...properties,
			User: multipleAccountManagers ? selectedUsers : selectedUser ? [selectedUser] : []
		});
	}, [selectedUsers, selectedUser]);

	useEffect(() => {
		setProperties({ ...properties, Project: selectedCampaigns.map(campaign => campaign.id) });
	}, [selectedCampaigns]);

	useEffect(() => {
		setProperties({ ...properties, Categories: selectedCategories.map(category => category.id) });
	}, [selectedCategories]);

	const handleSelectedUsersChange = (userSelected: { id: User['id']; title: User['name'] }) => {
		if (!selectedUsers.some(user => user.id === userSelected.id)) {
			if (multipleAccountManagers) {
				setSelectedUsers([...selectedUsers, userSelected]);
			} else {
				setSelectedUser(userSelected);
			}
		}
	};

	const handleSelectedFieldChange = (value: {
		id: CustomFieldWithValue['id'];
		title: CustomFieldWithValue['name'];
	}) => {
		const selectedField = selectableFields.find(field => field.id === value.id);
		if (selectedField) {
			setDisplayFields([...displayFields, selectedField]);
		}
		setSelectableFields(selectableFields.filter(field => field.id !== value.id));
	};

	const handleValueChange = (field: CustomFieldWithValue, value: string) => {
		if (requiredFieldsFilled.hasOwnProperty(field.id)) {
			setRequiredFieldsFilled(prevFields => ({
				...prevFields,
				[field.id]: !!value
			}));
		}

		setDisplayFields(
			displayFields.map(f => {
				if (f.id === field.id) {
					return { ...f, value: value };
				}
				return f;
			})
		);
	};

	const formProperties = getProperties();

	// Function where we will return for the onClose event of the newer modals
	const done = () => {
		const options = {
			updateExisting: updateExisting,
			skipProjects: false,
			skipAccountManagers: false,
			skipAddresses: false,
			skipCategories: false,
			runActionsForExisting: !updateExisting && !!existing
		};

		let buildProperties: {
			User?: (User | number)[];
			AddUser: number[];
			Project: Project[];
			Categories: Category[];
		} = {
			User: [],
			AddUser: [],
			Project: [],
			Categories: []
		};

		buildProperties = _.pick(properties, function (value) {
			if ((Array.isArray(value) && value.length) || (!Array.isArray(value) && value)) {
				return true;
			}
			return false;
		});

		// Map out the account managers if any were selected
		if (buildProperties.User && buildProperties.User.length > 0) {
			if (multipleAccountManagers) {
				buildProperties.AddUser = buildProperties.User.map(user => {
					if (typeof user === 'number') {
						return user;
					} else {
						return user.id;
					}
				});
				delete buildProperties.User;
			} else {
				buildProperties.User = buildProperties.User.map(user => {
					if (typeof user === 'number') {
						return user;
					} else {
						return user.id;
					}
				});
			}
		}

		setSaving(true);

		if (isProspecting) {
			const clientData = {
				custom: [],
				sourceType: 'prospecting-grouptree'
			};

			const nameMap: {
				[key: string]: string;
				User: string;
				AddUser: string;
				Project: string;
				Categories: string;
			} = {
				User: 'users',
				AddUser: 'users',
				Project: 'projects',
				Categories: 'categories'
			};

			Object.entries(buildProperties).reduce((res, [name, value]) => {
				const prospectingName = nameMap[name];
				if (prospectingName) {
					res[prospectingName] = Array.isArray(value) ? value.map(id => ({ id })) : [{ id: value }];
				}
				return res;
			}, clientData as { [key: string]: any });

			displayFields.forEach(
				(customField: { id: CustomFieldWithValue['id']; value: CustomFieldWithValue['value'] }) => {
					const value = getCustomFieldValue(customField);
					if (value !== null) {
						clientData.custom.push({ fieldId: customField.id, value } as never);
					}
				}
			);

			const body = {
				filters: filters,
				activityProperties: [],
				country: country,
				clientData
			};

			Prospecting.saveBulk(body)
				.then(() => {
					setSaving(false);
					close('true');
				})
				.catch(error => {
					setSaving(false);
					logError(error, 'confirmBuyProspects - prospecting');
				});
		} else {
			const finalProperties: { name: string; value: number[] | string }[] = [];
			// Map Custom
			Tools.ActionProperties.mapCustomFields(finalProperties, [...selectableFields, ...displayFields], 'Client');
			// Map properties
			Tools.ActionProperties.map(finalProperties, buildProperties as any, arrayProperties);

			const buyPreviewPromise = makeCancelable(
				Tools.SoliditetClient.customer(customerId).buyPreview(filters, finalProperties, options)
			);

			buyPreviewPromise.promise
				.then(function () {
					const metadata = Tools.AppService.getMetadata();
					metadata.soliditetCredits = Math.max(metadata.soliditetCredits - cost, 0);
					Tools.AppService.setMetadata(metadata);

					setSaving(false);
					close('true');
				})
				.catch(error => {
					logError(error, 'confirmBuyProspects - soliditet');
					setSaving(false);
				});
			return () => {
				if (buyPreviewPromise) {
					buyPreviewPromise.cancel();
				}
			};
		}
	};

	return (
		<form
			autoComplete="off"
			onSubmit={e => {
				e.preventDefault();
				done();
			}}
		>
			<ModalContent className={classes.elem('up-modal-content').b()}>
				{/* SOLIDITET */}
				{!isProspecting && (
					<div className={classes.elem('left-column').b()}>
						{newAccounts > 0 && (
							<div className="section section--border-bottom">
								<Text className="section-descriptor" size="sm">
									{T('soliditet.newAccounts') + ':'}
								</Text>
								<Title className="section-title">
									{T('soliditet.buyXcompanies', { value: newAccounts })}
								</Title>
								<Flex className="section-action" gap="u2">
									<Icon color="bright-green" name="check" />
									<Text size="sm">{T('soliditet.buyFromSoliditet')}</Text>
								</Flex>
								{formProperties.map((text, index) => (
									<Flex key={index} className="section-action" gap="u2">
										<Icon color="bright-green" name="check" />
										<Text size="sm">{text}</Text>
									</Flex>
								))}
							</div>
						)}
						{existing > 0 && (
							<div className="section">
								<div className="section-descriptor">{T('default.possibleDuplicates') + ':'}</div>
								<div className="section-title">
									{T('soliditet.updateXcompanies', { value: existing })}
								</div>
								{(!updateExisting || newAccounts > 0) && (
									<Flex className="section-action" gap="u2">
										<Toggle
											checked={updateExisting}
											onChange={() => setUpdateExisting(!updateExisting)}
										/>
										<Text size="sm">{T('soliditet.buyFromSoliditet')}</Text>
									</Flex>
								)}
								{formProperties.map((text, index) => (
									<Flex key={index} className="section-action" gap="u2">
										<Icon color="bright-green" name="check" />
										<Text size="sm">{text}</Text>
									</Flex>
								))}
							</div>
						)}
						{cost > credits && (
							<div className="section">
								<Text color="red" size="lg">
									{T('soliditet.notEnoughCreditsMulti')}.
								</Text>
								<div style={{ marginTop: '10px', marginBottom: '15px' }}>
									<Text color="red" size="lg">
										{T('ads.yourCredits')}: {credits}.
									</Text>
								</div>
							</div>
						)}
						<div className="section section-action--bottom">
							<button
								type="submit"
								className="button btn btn-block up-btn btn-green no-shadow"
								disabled={
									cost > credits ||
									saving ||
									Object.values(requiredFieldsFilled).some(value => value === false)
								}
							>
								{updateExisting && newAccounts === 0 && (
									<span>
										{T('soliditet.update')} {total}{' '}
										{total === 1 ? T('default.client') : T('default.clients').toLowerCase()}
									</span>
								)}
								{!updateExisting && newAccounts > 0 && (
									<span>
										{T('soliditet.buy')} {newAccounts}{' '}
										{newAccounts === 1 ? T('default.client') : T('default.clients').toLowerCase()}
									</span>
								)}
								{updateExisting && newAccounts > 0 && existing > 0 && (
									<span>
										{T('soliditet.buy')} {newAccounts}, {T('soliditet.update').toLowerCase()}{' '}
										{existing}{' '}
										{existing === 1 ? T('default.client') : T('default.clients').toLowerCase()}
									</span>
								)}
								{saving && <span className="fa fa-refresh fa-spin"></span>}
							</button>
							<button
								type="button"
								className="btn btn-block up-btn btn-link btn-grey"
								onClick={() => close(null)}
							>
								{T('default.abort')}
							</button>
						</div>
					</div>
				)}

				{/* PROSPECTING */}
				{isProspecting && (
					<div className={classes.elem('left-column').b()}>
						{newAccounts > 0 && (
							<div className="section section--border-bottom">
								<Text className="section-descriptor" size="sm">
									{T('soliditet.newAccounts') + ':'}
								</Text>
								<Title className="section-title">
									{T('prospecting.manageSelection.addXCompaniesToUpsales', { count: newAccounts })}
								</Title>
								<Flex className="section-action" gap="u2">
									<Icon color="bright-green" name="check" />
									<Text size="sm">{T('confirmBuyProspects.addFromProspecting')}</Text>
								</Flex>
								{formProperties.map((text, index) => (
									<Flex key={index} className="section-action" gap="u2">
										<Icon color="bright-green" name="check" />
										<Text size="sm">{text}</Text>
									</Flex>
								))}
							</div>
						)}
						{existing > 0 && (
							<div className="section">
								<Text className="section-descriptor" size="sm">
									{T('default.possibleDuplicates') + ':'}
								</Text>
								<Title className="section-title">
									{T('prospecting.manageSelection.updateXCompaniesInUpsales', { count: existing })}
								</Title>
								{(!updateExisting || newAccounts > 0) && (
									<Flex className="section-action" gap="u2">
										<Toggle
											checked={updateExisting}
											onChange={() => setUpdateExisting(!updateExisting)}
										/>
										<Text size="sm">{T('confirmBuyProspects.addFromProspecting')}</Text>
									</Flex>
								)}
								{formProperties.map((text, index) => (
									<Flex key={index} className="section-action" gap="u2">
										<Icon color="bright-green" name="check" />
										<Text size="sm">{text}</Text>
									</Flex>
								))}
							</div>
						)}
						<div className="section section-action--bottom">
							<button
								type="submit"
								className="button btn btn-block up-btn btn-green no-shadow"
								disabled={saving || Object.values(requiredFieldsFilled).some(value => value === false)}
							>
								{updateExisting && newAccounts === 0 && (
									<span>
										{T('default.update')} {total}{' '}
										{total === 1 ? T('default.client') : T('default.clients').toLowerCase()}
									</span>
								)}
								{!updateExisting && newAccounts > 0 && (
									<span>
										{T('default.add')} {newAccounts}{' '}
										{newAccounts === 1 ? T('default.client') : T('default.clients').toLowerCase()}
									</span>
								)}
								{updateExisting && newAccounts > 0 && existing > 0 && (
									<span>
										{T('default.add')} {newAccounts}, {T('default.update').toLowerCase()} {existing}{' '}
										{existing === 1 ? T('default.client') : T('default.clients').toLowerCase()}
									</span>
								)}
								{saving && <span className="fa fa-refresh fa-spin"></span>}
							</button>
							<button
								type="button"
								className="btn btn-block up-btn btn-link btn-grey"
								onClick={() => close(null)}
							>
								{T('default.abort')}
							</button>
						</div>
					</div>
				)}
				<div className={classes.elem('right-column').b()}>
					<div className="section">
						<Flex gap="u4" flex={1}>
							<Flex direction="column" flex={0.5}>
								<Text size="lg">
									{T('default.accountManager')}
									<Link className={classes.elem('select-me').b()} nostyle={true} onClick={selectMe}>
										{T('default.select')} {T('default.me').toLowerCase()}
									</Link>
								</Text>

								<div className="form-group">
									<div className="input-group">
										<span className="input-group-addon">
											<Icon name="user-plus" />
										</span>
										{multipleAccountManagers ? (
											<Select
												data-testid="Input_user"
												multi
												anchor={anchor}
												options={users.map(user => ({ id: user.id, title: user.name }))}
												value={selectedUsers || []}
												onChange={handleSelectedUsersChange}
												onRemove={id =>
													setSelectedUsers(selectedUsers.filter(user => user.id !== id))
												}
												placeholder={
													T('default.select') + ' ' + T('default.user').toLowerCase()
												}
											/>
										) : (
											<Select
												data-testid="Input_user"
												anchor={anchor}
												options={users.map(user => ({ id: user.id, title: user.name }))}
												value={selectedUser}
												onChange={handleSelectedUsersChange}
												onClear={() => setSelectedUser(null)}
												placeholder={
													T('default.select') + ' ' + T('default.user').toLowerCase()
												}
											/>
										)}
									</div>
								</div>
							</Flex>
						</Flex>
					</div>

					<div className="section">
						<Text size="lg">{T('soliditet.addToCampaignCategory')}</Text>

						<Text className={classes.elem('add-to-campaign-category').b()} size="sm" color="grey-11">
							{T('soliditet.addToCampaignCategoryInfo')}
						</Text>

						<Flex gap="u4" flex={1}>
							<Flex direction="column" flex={0.5}>
								<div className="form-group">
									<Text bold={true} size="sm">
										{T('default.categories')}
									</Text>
									<ReactTemplates.INPUTS.upCategories
										multiple={true}
										type="account"
										value={selectedCategories || []}
										onChange={categories => {
											setSelectedCategories(categories); //upCategories returns the selected array
										}}
										placeholder={T('category.chooseCategory')}
									/>
								</div>
							</Flex>
							<Flex direction="column" flex={0.5}>
								<div className="form-group">
									<Text bold={true} size="sm">
										{T('default.campaigns')}
										<Link
											nostyle={true}
											className={classes.elem('create-campaign').b()}
											onClick={createCampaign}
										>
											{T('default.create')} {T('default.new').toLowerCase()}
										</Link>
									</Text>
									<ReactTemplates.INPUTS.upCampaigns
										multiple={true}
										value={selectedCampaigns || []}
										onChange={campaigns => {
											setSelectedCampaigns(campaigns); //upCampaigns returns the selected array
										}}
										placeholder={T('campaign.chooseCampaign')}
									/>
								</div>
							</Flex>
						</Flex>
					</div>

					{showCustomFields && (
						<div className="section">
							<Text className={classes.elem('add-company-data').b()} size="lg">
								{T('prospecting.manageSelection.addCompanyData')}
							</Text>
							<div className="row">
								{displayFields.map(field => (
									<div className="col-md-6" key={field.id}>
										<div className="form-group">
											<Text bold={true} size="sm">
												{field.name}
												<Text
													size="xl"
													color="red"
													bold={true}
													className={classes.elem('required-icon').b()}
													style={{ display: field.obligatoryField ? 'inline' : 'none' }}
												>
													&bull;
												</Text>
											</Text>
											<ReactTemplates.INPUTS.customFieldInput
												field={field}
												name={field.name}
												onChange={(value: string) => handleValueChange(field, value)}
												usenewdate
												useNewTime
											/>
										</div>
									</div>
								))}
								{selectableFields.length > 0 && (
									<div className="col-md-6">
										<div style={{ marginTop: '21px' }} className="form-group">
											<div className="input-group">
												<span className="input-group-addon">
													<Icon name="plus" />
												</span>
												<Select
													data-testid="Input_field"
													anchor={anchor}
													options={selectableFields.map(field => ({
														id: field.id,
														title: field.name
													}))}
													value={null}
													onChange={handleSelectedFieldChange}
													placeholder={T('soliditet.addField')}
												/>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					)}
				</div>
			</ModalContent>
		</form>
	);
};

export default ConfirmBuyProspects;
