import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect, Provider } from 'react-redux';
import store from 'Store';

import { retrieveData } from 'Store/reducers/BillingReducer';
import { Text, Block, Row, Column, ModalHeader, Link, Loader } from '@upsales/components';
import BillingAddSeatDrawer from '../Billing/BillingAddSeatDrawer';
import DeactivateUserDrawer from './DeactivateUserDrawer';
import { nextPage } from '../../store/actions/MaxCeilingReachedOnSeats';
import T from 'Components/Helpers/translate';
import bemClass from '@upsales/components/Utils/bemClass';
import './AddOrDeactivate.scss';

const mapStateToProps = ({ Billing, MaxCeilingReachedOnSeats }) => ({
	addOrDeactivate: MaxCeilingReachedOnSeats.addOrDeactivate,
	maxLicences: Billing.maxLicences,
	userCount: Billing.userCount,
	invoiceSubscriptions: Billing.invoiceSubscriptions,
	licenceInfo: Billing.licenceInfo,
	loading: Billing.loading
});

export class AddOrDeactivate extends Component {
	static propTypes = {
		nextPage: PropTypes.func.isRequired,
		closeModal: PropTypes.func.isRequired,
		userCount: PropTypes.number.isRequired,
		maxLicences: PropTypes.number.isRequired,
		loading: PropTypes.bool
	};
	constructor(props) {
		super(props);
		this.state = {
			addSeatsDrawerVisible: false,
			deactivateUserDrawerVisible: false
		};
		this.openAddSeatsDrawer = this.openAddSeatsDrawer.bind(this);
		this.closeAddSeatsDrawer = this.closeAddSeatsDrawer.bind(this);
		this.openDeactivateUserDrawer = this.openDeactivateUserDrawer.bind(this);
		this.closeDeactivateUserDrawer = this.closeDeactivateUserDrawer.bind(this);
		this.switchDrawer = this.switchDrawer.bind(this);
		this.onNextPage = this.onNextPage.bind(this);

		store.dispatch(retrieveData());
	}
	openAddSeatsDrawer() {
		this.setState({ addSeatsDrawerVisible: true });
	}

	closeAddSeatsDrawer() {
		this.setState({ addSeatsDrawerVisible: false });
	}

	openDeactivateUserDrawer() {
		this.setState({ deactivateUserDrawerVisible: true });
	}

	closeDeactivateUserDrawer() {
		this.setState({ deactivateUserDrawerVisible: false });
	}
	switchDrawer() {
		this.closeDeactivateUserDrawer();
		this.openAddSeatsDrawer();
	}

	onNextPage(id) {
		this.props.nextPage(id);
	}

	render() {
		const getAngularModule = require('../../angularHelpers/getAngularModule').default;
		const security = getAngularModule('security');
		const classes = new bemClass('AddOrDeactivate');
		const activeUsers = Tools.AppService.getUsers().filter(user => user.active === 1 && user.ghost === 0);
		const { userCount, maxLicences, loading } = this.props;
		const logOutAndClose = () => {
			security.logout();
		};
		const licenseDiff = userCount - maxLicences;

		return (
			<Provider store={store}>
				<ModalHeader alignContent={null} className={classes.b()} title={T('admin.maxCeilingReachedOnSeats')}>
					<Link className={classes.elem('logout-btn').b()} onClick={logOutAndClose}>
						{T('admin.maxCeilingReachedOnSeats.logout')}
					</Link>
				</ModalHeader>
				{loading ? (
					<Loader className={classes.elem('loader').b()} size="lg" />
				) : (
					<div className={classes.elem('content').b()}>
						<Text bold size="xl">
							{T('admin.maxCeilingReachedOnSeats.maxReached')}
						</Text>
						{licenseDiff === 1 ? (
							<Text size="md">{T('admin.maxCeilingReachedOnSeats.explainText', { licenseDiff })}</Text>
						) : (
							<Text size="md">
								{T('admin.maxCeilingReachedOnSeats.explainText.plural', { licenseDiff })}
							</Text>
						)}
						<Text bold size="md">
							{T('admin.maxCeilingReachedOnSeats.whatToDo')}
						</Text>
						<Row>
							<Column>
								<Block
									borderColor="grey-6"
									border="ts rs bs ls"
									space="ptl prl pbl pll"
									className={classes.elem('button_block').mod('addseat').b()}
									onClick={this.openAddSeatsDrawer}
								>
									<div className={classes.elem('wrapper').b()}>
										<Text size="md" bold>
											{T('admin.maxCeilingReachedOnSeats.addSeats')}
										</Text>
										<Text size="sm" color="grey-11" className={classes.elem('Terms').b()}>
											{T('admin.maxCeilingReachedOnSeats.addSeatsDescription')}
										</Text>
									</div>
								</Block>
							</Column>
							<Column>
								<Block
									borderColor="grey-6"
									border="ts rs bs ls"
									space="ptl prl pbl pll"
									className={classes.elem('button_block').mod('deactivate').b()}
									onClick={this.openDeactivateUserDrawer}
								>
									<div className={classes.elem('wrapper').b()}>
										<Text size="md" bold>
											{T('admin.maxCeilingReachedOnSeats.deactivateUser')}
										</Text>
										<Text size="sm" color="grey-11" className={classes.elem('Terms').b()}>
											{T('admin.maxCeilingReachedOnSeats.deactivateUserDescription')}
										</Text>
									</div>
								</Block>
							</Column>
						</Row>
						<BillingAddSeatDrawer
							visible={this.state.addSeatsDrawerVisible}
							onClose={this.closeAddSeatsDrawer}
							nextPage={this.onNextPage}
							activeUsers={activeUsers.length}
						/>
						<DeactivateUserDrawer
							visible={this.state.deactivateUserDrawerVisible}
							onClose={this.closeDeactivateUserDrawer}
							activeUsers={activeUsers}
							switchDrawer={this.switchDrawer}
							nextPage={this.onNextPage}
						/>
					</div>
				)}
			</Provider>
		);
	}
}

const mapDispatchToProps = {
	nextPage
};
export const detached = AddOrDeactivate;
export default connect(mapStateToProps, mapDispatchToProps)(AddOrDeactivate);
