import t from 'Components/Helpers/translate';
import type { ClientAddress } from 'App/resources/Model/Client';

export const formatOrgNumber = (orgNo: string) => {
	if (!orgNo) {
		return '-';
	}
	if (orgNo) {
		orgNo = orgNo + '';
	}
	if (orgNo && orgNo.length === 10) {
		orgNo = orgNo.substring(0, 6) + '-' + orgNo.substring(6, 10);
	}
	return orgNo;
};

export const decideHeadline = (entityName: string, ignore: boolean) => {
	const lang = {
		hiddenClients: t('handleDuplicates.hiddenClients'),
		mergeClients: t('handleDuplicates.mergeClients'),
		hiddenContacts: t('handleDuplicates.hiddenContacts'),
		mergeContacts: t('handleDuplicates.mergeContacts')
	};

	if (entityName === 'Client' && ignore) {
		return lang.hiddenClients;
	} else if (entityName === 'Client' && !ignore) {
		return lang.mergeClients;
	} else if (entityName === 'Contact' && ignore) {
		return lang.hiddenContacts;
	} else if (entityName === 'Contact' && !ignore) {
		return lang.mergeContacts;
	}
};

export const handleHideAction = (actions: { [key: number]: string | number }, id: number) => {
	const nextActions = { ...actions };
	if (actions[id] === 'hide') {
		delete nextActions[id];
	} else {
		nextActions[id] = 'hide';
	}
	return nextActions;
};

export const getCompanyAddress = (addresses: ClientAddress[]) => {
	const typeOrder = ['Visit', 'Mail', 'Delivery', 'Invoice', 'Other'];

	return typeOrder.map(type => addresses?.find(address => address.type === type)).find(Boolean);
};

export const MAX_CUSTOM_FIELDS_BEFORE_SCROLL = 5;
