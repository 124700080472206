import {
	Button,
	Card,
	Input,
	Label,
	Link,
	ModalContent,
	ModalControls,
	ModalHeader,
	Text,
	Title
} from '@upsales/components';
import { asyncModalAdapter, setupComponentCompatibility } from 'App/helpers/angularPortingHelpers';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import AccountSelect from '../AccountSelect/AccountSelect';
import './AccountRelationModal.scss';

class AccountRelationModalComponent extends Component {
	static propTypes = {
		relation: PropTypes.object,
		reject: PropTypes.func.isRequired,
		resolve: PropTypes.func.isRequired,
		reloadModalPosition: PropTypes.func.isRequired,
		$modalParams: PropTypes.object.isRequired
	};

	state = {
		isSaving: false,
		selectedAccount: null,
		rel1: '',
		rel2: '',
		relId: -1,
		customRelations: null
	};

	constructor(props) {
		super(props);

		const t = Tools.$translate;
		this.lang = {
			relation: t('client.relation'),
			abort: t('default.abort'),
			createRelationDescription: t('client.createRelationDescription'),
			tips: t('default.tips'),
			relationTips: t('client.relationTips'),
			searchForAccount: t('client.searchForAccount'),
			wrongRelatedClient: t('client.wrongRelatedClient'),
			startOver: t('default.startOver'),
			howIs: t('client.howIs'),
			relatedTo: t('client.relatedTo'),
			save: t('default.save'),
			relationExample1: t('client.relationExample1'),
			relationExample2: t('client.relationExample2')
		};
	}

	async getAccountRelation(customerId, relationId) {
		return Tools.AccountRelation.customer(customerId)
			.get(relationId)
			.then(({ data }) => data);
	}

	async getRelatedAccount(customerId, relatedClientId) {
		return Tools.Account.customer(customerId)
			.get(relatedClientId)
			.then(({ data }) => data);
	}

	async componentDidMount() {
		const { reject, $modalParams } = this.props;
		const { relation, customerId, account } = $modalParams;

		if (relation && parseInt(relation.id) > -1) {
			try {
				const accountRelation = await this.getAccountRelation(customerId, relation.id);
				const { clientId, relatedToClientId, description, descriptionChildParent } = accountRelation;

				const relatedAccount = await this.getRelatedAccount(
					customerId,
					account.id === relatedToClientId ? clientId : relatedToClientId
				);

				const isRelatedAccount = relatedAccount.id === clientId;

				this.setState({
					selectedAccount: relatedAccount,
					rel1: isRelatedAccount ? descriptionChildParent : description,
					rel2: isRelatedAccount ? description : descriptionChildParent,
					relId: accountRelation.id
				});
			} catch (e) {
				reject(e);
			}
		} else {
			try {
				const relations = await Tools.AccountRelations.get($modalParams.account);
				this.setState({
					customRelations: relations.reduce((res, relation) => {
						if (relation.connected) {
							res.push(relation.relatedToClientId);
						}
						return res;
					}, [])
				});
			} catch (e) {
				reject(e);
			}
		}
	}

	componentDidUpdate() {
		this.props.reloadModalPosition();
	}

	onChangeAccountSelect = account => {
		this.setState({ selectedAccount: account });
	};

	reset = () => {
		this.setState({ selectedAccount: null, rel1: '', rel2: '' });
	};

	save = () => {
		this.setState({ isSaving: true });

		const { resolve, reject, $modalParams } = this.props;
		const { customerId, account } = $modalParams;
		const { selectedAccount, rel1, rel2, relId } = this.state;

		const relation = {
			clientId: account.id,
			relatedToClientId: selectedAccount.id,
			description: rel1,
			descriptionChildParent: rel2
		};

		if (parseInt(relId) > -1) {
			relation.id = relId;
		}

		Tools.AccountRelation.customer(customerId)
			.save(relation)
			.then(res => {
				let otherClientId;
				if (account.id === res.data.relatedToClientId) {
					otherClientId = res.data.clientId;
				} else {
					otherClientId = res.data.relatedToClientId;
				}
				Tools.Account.customer(customerId)
					.get(otherClientId)
					.then(result => {
						resolve({ client: result.data, relation: res.data });
					})
					.catch(err => reject(err));
			})
			.catch(err => reject(err));
	};

	setRel = rel => e => {
		this.setState({ [rel]: e.target.value });
	};

	renderStepOne = ({ account, customerId }) => {
		if (!this.state.customRelations) {
			return null;
		}
		return (
			<Fragment>
				<Title>
					{this.lang.createRelationDescription} {account.name}
				</Title>
				<Card borderColor="bright-blue" border="lm">
					<Text>
						{`${this.lang.tips}:`} {this.lang.relationTips}
					</Text>
				</Card>
				<Label>{this.lang.searchForAccount}</Label>
				<AccountSelect
					customerId={customerId}
					accountId={account.id}
					onChange={this.onChangeAccountSelect}
					autoFocus={true}
					canSelectRemovable
					excludeAccounts={this.state.customRelations}
				/>
			</Fragment>
		);
	};

	renderStepTwo = ({ account, relation }) => {
		const { selectedAccount, rel1, rel2 } = this.state;
		return (
			<Fragment>
				<Label>
					{this.lang.howIs} <strong>{account.name}</strong> {this.lang.relatedTo}{' '}
					<strong>{selectedAccount.name}</strong>
					{'?'}
				</Label>
				<Input
					placeholder={this.lang.relationExample1}
					noborder={true}
					value={rel1}
					onChange={this.setRel('rel1')}
				/>
				<Label>
					{this.lang.howIs} <strong>{selectedAccount.name}</strong> {this.lang.relatedTo}{' '}
					<strong>{account.name}</strong>
					{'?'}
				</Label>
				<Input
					placeholder={this.lang.relationExample2}
					noborder={true}
					value={rel2}
					onChange={this.setRel('rel2')}
				/>
				{!relation && (
					<Text>
						{this.lang.wrongRelatedClient}
						{'?'} <Link onClick={this.reset}>{this.lang.startOver}</Link>
					</Text>
				)}
			</Fragment>
		);
	};

	render() {
		const { reject, $modalParams } = this.props;
		const { selectedAccount, isSaving, rel1, rel2 } = this.state;
		const isLoading = $modalParams.relation && !selectedAccount;

		return (
			<div className="AccountRelationModal">
				<ModalHeader icon="sitemap" title={this.lang.relation} onClose={reject} />
				<ModalContent>
					{!isLoading && (
						<Fragment>
							{selectedAccount ? this.renderStepTwo($modalParams) : this.renderStepOne($modalParams)}
						</Fragment>
					)}
				</ModalContent>
				<ModalControls>
					{selectedAccount && (
						<Button
							color="bright-blue"
							onClick={this.save}
							loading={isSaving}
							disabled={isSaving || !rel1 || !rel2}
						>
							{this.lang.save}
						</Button>
					)}
					<Button type="link" color="grey" onClick={reject}>
						{this.lang.abort}
					</Button>
				</ModalControls>
			</div>
		);
	}
}

export const AccountRelationModal = setupComponentCompatibility(AccountRelationModalComponent, {
	modalName: 'AccountRelationModal',
	modalSize: 'lg',
	modalParamsMapper: $modalParams => ({ $modalParams })
});

export const openAccountRelationModal = asyncModalAdapter({
	upModalName: 'editClientRelation',
	openModalName: 'AccountRelationModal',
	featureFlag: 'EDIT_CLIENT_RELATION_REACT'
});

export default AccountRelationModalComponent;
