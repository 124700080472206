import LZString from 'lz-string';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useState, useEffect, useRef } from 'react';
import { Loader, Modal, ModalContent, ModalHeader, Flex } from '@upsales/components';

import logError from 'App/babel/helpers/logError';
import NotificationResource from 'Resources/Notification';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import type Notification from 'App/resources/Model/Notification.d.ts';
import { type ModalProps } from 'App/components/Modals/Modals';
import { useTranslation } from 'Components/Helpers/translate';
import NotificationData from './NotificationData';

type NotificationDetailModalProps = ModalProps & {
	notification: Notification;
};

type NotificationResourcePromise = Awaited<ReturnType<typeof NotificationResource.get>>;

const NotificationDetailModal = ({ notification, className, close }: NotificationDetailModalProps) => {
	const classes = new BemClass('NotificationDetailModal', className);
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [notificationData, setNotificationData] = useState<any | null>(null);
	const pendingNotificationRequest = useRef<CancelablePromise<NotificationResourcePromise> | null>(null);

	const parseAndSetNotificationData = (data: string) => {
		try {
			setNotificationData(JSON.parse(LZString.decompressFromBase64(data) || '{}'));
		} catch (err) {
			logError(err, 'Failed to parse notification data');
		}
	};

	const fetchNotification = async (id: number) => {
		try {
			setLoading(true);
			if (pendingNotificationRequest.current) {
				pendingNotificationRequest.current.cancel();
			}
			pendingNotificationRequest.current = makeCancelable(NotificationResource.get(id));
			const fetchedNotification = await pendingNotificationRequest.current.promise;

			const data = fetchedNotification?.data?.data;
			if (data) {
				parseAndSetNotificationData(data);
			}
		} catch (err) {
			logError(err, 'Failed to get notification');
		}
		setLoading(false);
	};

	useEffect(() => {
		if (notification?.id) {
			/*
                We decided to keep the notification data outside of the indexing since it can be quite large and we
                currently don't have a good policy regarding notifications since we only display a limited number of the
                stored notifications but store every notification ever displayed in the database.
				So could be a very large number of notifications.
                This limitation leads us to fetch the notification data when needed instead.

                But if a notification is sent in with data, then we don't need to fetch it.
            */

			if (notification.data) {
				parseAndSetNotificationData(notification.data);
			} else {
				fetchNotification(notification.id);
			}

			return () => {
				if (pendingNotificationRequest.current) {
					pendingNotificationRequest.current.cancel();
				}
			};
		}
	}, [notification?.id, notification?.data]);

	return (
		<Modal className={classes.b()}>
			<ModalHeader title={t(`noti.${notification.action}-failed`)} onClose={close} />
			<ModalContent>
				{loading ? (
					<Flex justifyContent="center" alignItems="center">
						<Loader />
					</Flex>
				) : null}

				<NotificationData notificationData={notificationData} />
			</ModalContent>
		</Modal>
	);
};

export default NotificationDetailModal;
