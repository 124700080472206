enum Actions {
	SET_TREE = '[CompanyGroup] SET_TREE',
	SET_GROUP = '[CompanyGroup] SET_GROUP',
	SET_LOADING_TREE = '[CompanyGroup] SET_LOADING_TREE',
	SET_PROSPECTING_ID = '[CompanyGroup] SET_PROSPECTING_ID',
	SET_CLIENT_IDS = '[CompanyGroup] SET_CLIENT_IDS',
	SET_GROUP_STATEMENT = '[CompanyGroup] SET_GROUP_STATEMENT',
	SET_ACTIVITY_NUMBERS = '[CompanyGroup] SET_ACTIVITY_NUMBERS',
	SET_NUMBER_OF_CONTACTS = '[CompanyGroup] SET_NUMBER_OF_CONTACTS',
	SET_SALES_DATA = '[CompanyGroup] SET_SALES_DATA',
	SET_OPPORTUNITY_DATA = '[CompanyGroup] SET_OPPORTUNITY_DATA',
	NO_CLIENTS = '[CompanyGroup] NO_CLIENTS'
}

export default Actions;
