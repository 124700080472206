import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import translate from 'Components/Helpers/translate';
import { init, selectIntegration, deSelectIntegration } from 'App/babel/store/reducers/ChooseMailIntegrationReducer';
import AppList from './AppList';
import Terms from './Terms';
import ConfigureIntegration from './ConfigureIntegration';

import './ChooseMailIntegration.scss';
import { Modal } from '@upsales/components';

const mapStateToProps = state => {
	return {
		...state.ChooseMailIntegration,
		configIntegration: state.ConfigIntegration
	};
};

const mapDispatchToProps = {
	init,
	selectIntegration,
	deSelectIntegration
};

export class ChooseMailIntegration extends React.Component {
	constructor(props) {
		super(props);

		this.lang = {
			title: translate('chooseMailIntegration.title'),
			subTitle: translate('chooseMailIntegration.subTitle'),
			abort: translate('default.abort'),
			learnMore: translate('default.learnMore'),
			connect: translate('default.connect'),
			terms: translate('default.termsShort')
		};
	}

	componentDidMount() {
		this.props.init();
	}

	reject = () => {
		const { reject } = this.props;
		const mailModalElem = document.querySelector('[name="MailForm"]');
		// This is to prevent the mailModal to be seen for 1 second if you close this modal (looks ugly)
		if (mailModalElem && mailModalElem.parentNode) {
			mailModalElem.parentNode.style.display = 'none';
		}
		reject();
	};

	render() {
		const {
			integrations,
			loading,
			selectIntegration,
			deSelectIntegration,
			selectedIntegration,
			sendEmailModalParams,
			configIntegration,
			resolve
		} = this.props;
		const oauthField = configIntegration.fields.find(field => field.type === 'oauth2');
		const showTerms = !configIntegration.contractAccepted || oauthField;

		if (selectedIntegration && showTerms) {
			return (
				<Terms
					integration={selectedIntegration}
					reject={deSelectIntegration}
					sendEmailModalParams={sendEmailModalParams}
				/>
			);
		} else if (selectedIntegration) {
			return <ConfigureIntegration {...{ resolve, reject: deSelectIntegration }} />;
		} else {
			return <AppList {...{ integrations, loading, selectIntegration, reject: this.reject }} />;
		}
	}
}

const ConnectedChooseMailIntegration = connect(mapStateToProps, mapDispatchToProps)(ChooseMailIntegration);

export const ChooseMailIntegrationModal = props => {
	const { className, close, ...passthrough } = props;
	return (
		<Modal className={className}>
			<ConnectedChooseMailIntegration
				{...passthrough}
				reject={close}
				resolve={close}
				reloadModalPosition={() => {}}
			/>
		</Modal>
	);
};

ChooseMailIntegrationModal.propTypes = {
	className: PropTypes.string,
	close: PropTypes.func,
	modalId: PropTypes.number
};

ChooseMailIntegration.propTypes = {
	init: PropTypes.func.isRequired,
	reject: PropTypes.func.isRequired,
	resolve: PropTypes.func.isRequired,
	selectIntegration: PropTypes.func.isRequired,
	deSelectIntegration: PropTypes.func.isRequired,
	integrations: PropTypes.array.isRequired,
	selectedIntegration: PropTypes.object,
	loading: PropTypes.bool.isRequired,
	sendEmailModalParams: PropTypes.object,
	configIntegration: PropTypes.object.isRequired
};

export default ConnectedChooseMailIntegration;
