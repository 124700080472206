import React from 'react';
import { Tooltip } from '@upsales/components';
import '../TableWidget/TableWidget.scss';
import { RCDashboardWidget } from 'Resources/ReportDashboard';
import { RCWidgetData, RCWidgetDataSubRow } from 'Resources/ReportWidget';
import { GenericWidgetProps } from '../ReportcenterWidget';
import './VirtualizedTableWidget.scss';
import bemClass from '@upsales/components/Utils/bemClass';
import { loadDrilldown } from '../chartHelper';
import { AutoSizer, Grid, ScrollSync } from 'react-virtualized';
import 'react-virtualized/styles.css';
import { renderLoadingTable, renderNoResultsTable } from '../TableWidget/TableWidget';

export type CellRendererProps = {
	columnIndex: number;
	key: string;
	rowIndex: number;
	style: React.CSSProperties;
};

type VirtualizedTableWidgetCoreProps = {
	config: RCDashboardWidget;
	data: RCWidgetData;
	drilldown: boolean;
	loading: boolean;
	renderBodyCell?: (
		data: RCWidgetDataSubRow | undefined,
		keys: string[],
		cellProps: Partial<CellRendererProps>
	) => JSX.Element;
};

export const VirtualizedTableWidgetCore = ({
	config,
	data,
	drilldown,
	loading,
	renderBodyCell
}: VirtualizedTableWidgetCoreProps) => {
	const classes = new bemClass('VirtualizedTableWidget');
	const columnArray = data.total?.columnArray; // Sorted list of columns
	const enableDrilldown = data.enableDrilldown && drilldown;

	// Grid values
	const columnCount = columnArray ? columnArray.length + 1 : 0; // Total number of columns
	const columnWidth = ({ index }: { index: number }) => {
		switch (index) {
			case 0:
				return 150;
			default:
				return 100;
		}
	}; // Width of each column
	const height = 98 + 170 * (config.height - 1) - (config.legend ? 45 : 0); // Height of the displayed grid area
	const rowCount = data.rows?.length; // Total number of rows
	const rowHeight = 40; // Height of each row
	const headerRowHeight = 30; // Height of header row
	const overscanColumnCount = 5; // Number of columns to render outside the visible grid
	const overscanRowCount = 10; // Number of rows to render outside the visible grid

	// Cell render functions
	const renderLeftCell = ({ columnIndex, key, rowIndex, style }: CellRendererProps) => {
		const label = data.rows[rowIndex].label;
		return (
			<div className={classes.elem('cell').mod('left').b()} key={key} style={style}>
				<div className={classes.elem('ellipsedText').b()}>
					<Tooltip title={label} position={'bottom'}>
						{data.rows[rowIndex].label}
					</Tooltip>
				</div>
			</div>
		);
	};

	const renderHeaderCell = ({ columnIndex, key, rowIndex, style }: CellRendererProps) => {
		if (columnIndex === 0) {
			return;
		}
		const label = columnArray?.[columnIndex - 1]?.label ?? '';
		return (
			<div className={classes.elem('cell').mod('header').b()} key={key} style={style}>
				<div className={classes.elem('ellipsedText').b()}>
					<Tooltip title={label} position={'bottom'}>
						{label}
					</Tooltip>
				</div>
			</div>
		);
	};

	const renderLeftHeaderCell = ({ columnIndex, key, rowIndex, style }: CellRendererProps) => {
		if (columnIndex > 0) {
			return;
		}
		return (
			<div className={classes.elem('cell').mod('leftHeader').b()} key={key} style={style}>
				{data.language?.firstGrouping}
			</div>
		);
	};

	const _renderBodyCell = ({ columnIndex, key, rowIndex, style }: CellRendererProps) => {
		if (columnIndex === 0) {
			return;
		}

		const cellData = data.rows[rowIndex]?.rows.find(row => row.key === columnArray?.[columnIndex - 1]?.key);
		const keys = [data.rows[rowIndex].key, columnArray?.[columnIndex - 1]?.key ?? ''];

		return renderBodyCell ? (
			renderBodyCell(cellData, keys, { key, style })
		) : (
			<div
				className={classes.elem('cell').mod('body').b()}
				key={key}
				style={style}
				onClick={
					enableDrilldown && cellData
						? e => {
								e.preventDefault();
								loadDrilldown(keys, config);
						  }
						: undefined
				}
			>
				{cellData?.progress}
			</div>
		);
	};

	return loading ? (
		renderLoadingTable(classes, config.height)
	) : !data.rows.length ? (
		renderNoResultsTable(classes, data)
	) : (
		<ScrollSync>
			{({ clientHeight, clientWidth, onScroll, scrollHeight, scrollLeft, scrollTop, scrollWidth }) => (
				<div className={classes.elem('GridRow').b()}>
					<div
						className={classes.elem('LeftGridContainer').b()}
						style={{
							position: 'absolute',
							left: 0,
							top: 0
						}}
					>
						<Grid
							cellRenderer={renderLeftHeaderCell}
							className={classes.elem('HeaderGrid').b()}
							columnCount={1}
							columnWidth={columnWidth}
							height={headerRowHeight}
							rowCount={1}
							rowHeight={headerRowHeight}
							width={150}
						/>
					</div>
					<div
						className={classes.elem('LeftGridContainer').b()}
						style={{
							position: 'absolute',
							left: 0,
							top: headerRowHeight
						}}
					>
						<Grid
							cellRenderer={renderLeftCell}
							className={classes.elem('LeftGrid').b()}
							columnCount={1}
							columnWidth={columnWidth}
							height={height - headerRowHeight}
							overscanColumnCount={overscanColumnCount}
							overscanRowCount={overscanRowCount}
							rowCount={rowCount}
							rowHeight={rowHeight}
							scrollTop={scrollTop}
							width={150}
						/>
					</div>
					<div className={classes.elem('GridColumn').b()}>
						<AutoSizer disableHeight>
							{({ width }) => (
								<div>
									<div style={{ height: 30 }}>
										<Grid
											cellRenderer={renderHeaderCell}
											className={classes.elem('HeaderGrid').b()}
											columnCount={columnCount}
											columnWidth={columnWidth}
											height={headerRowHeight}
											rowCount={1}
											rowHeight={headerRowHeight}
											scrollLeft={scrollLeft}
											width={width}
										/>
									</div>
									<div style={{ height, width }}>
										<Grid
											cellRenderer={_renderBodyCell}
											className={classes.elem('BodyGrid').b()}
											columnCount={columnCount}
											columnWidth={columnWidth}
											height={height - headerRowHeight}
											onScroll={onScroll}
											overscanColumnCount={overscanColumnCount}
											overscanRowCount={overscanRowCount}
											rowCount={rowCount}
											rowHeight={rowHeight}
											width={width}
										/>
									</div>
								</div>
							)}
						</AutoSizer>
					</div>
				</div>
			)}
		</ScrollSync>
	);
};

const VirtualizedTableWidget = ({ data, config, loading, drilldown, ...props }: GenericWidgetProps) => {
	return <VirtualizedTableWidgetCore config={config} data={data} drilldown={drilldown} loading={loading} />;
};

export default VirtualizedTableWidget;
