import React, { useState, useEffect } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import {
	Loader,
	Text,
	Icon,
	ModalHeader,
	Table,
	TableRow,
	TableColumn,
	ModalContent,
	Select
} from '@upsales/components';
import RequestBuilder from 'Resources/RequestBuilder';
import ComparisonTypes from 'Resources/ComparisonTypes';
import { makeCancelable } from 'App/babel/helpers/promise';
import logError from 'Helpers/logError';
import { DocumentTemplate } from 'App/resources/AllIWant';
import Contact from 'App/resources/Model/Contact';
import './CreateDocument.scss';
import documentResource from 'Resources/document';
import openModal from 'App/services/Modal/Modal';

type DocumentTemplateType =
	| 'activity'
	| 'client'
	| 'agreement'
	| 'object1'
	| 'object2'
	| 'object3'
	| 'object4'
	| 'order';

export interface Props {
	accountId: number | undefined;
	close: () => void;
	id: number | undefined;
	templates: DocumentTemplate[];
	type: string;
	contactId?: number;
	contactName?: string;
}

const CreateDocument = (props: Props) => {
	const { accountId, close, id, templates: propsTemplates, type, contactId, contactName } = props;

	const classes = new BemClass('CreateDocument');
	const anchor = document.querySelector('.Modals');

	const [selectedContact, setSelectedContact] = useState<{ id: Contact['id']; title: Contact['name'] } | null>(null);
	const [templates, setTemplates] = useState(propsTemplates || []);
	const [contacts, setContacts] = useState<Contact[]>([]);
	const [contactsFetched, setContactsFetched] = useState(false);

	const documentTemplateTypes: DocumentTemplateType[] = [
		'activity',
		'agreement',
		'object1',
		'object2',
		'object3',
		'object4',
		'order'
	];

	function isValidDocumentTemplateType(type: string): type is DocumentTemplateType {
		return documentTemplateTypes.includes(type as DocumentTemplateType);
	}

	const selectDocument = (template: any) => {
		const params = {
			entityId: id,
			templateId: template.id,
			type,
			name: template.name,
			accountId: 0,
			contactId: ''
		};

		if (accountId) {
			params.accountId = accountId;
		}

		if (selectedContact) {
			params.contactId = String(contactId); // To match the type when sending to previewPdf
		}
		if (Tools.FeatureHelper.hasSoftDeployAccess('PREVIEW_PDF_REACT')) {
			openModal('PreviewPdfModal', {
				isUploadedTemplate: true,
				documentResource: {
					resource: documentResource,
					entityId: params.entityId,
					templateId: params.templateId,
					type: params.type,
					documentName: params.name,
					accountId: params.accountId,
					contactId: params.contactId
				}
			});
		} else {
			Tools.$upModal.open('pdfPreview', params);
		}
	};

	useEffect(() => {
		const customerId = Tools.AppService.getCustomerId();
		if (!templates) {
			let getDocumentTemplateType: DocumentTemplateType | undefined;

			if (isValidDocumentTemplateType(type)) {
				getDocumentTemplateType = type;
			} else if (type === 'account') {
				getDocumentTemplateType = 'client';
			} else {
				logError(new Error(`Invalid document template type: ${type}`));
			}

			if (getDocumentTemplateType) {
				setTemplates(Tools.AppService.getDocumentTemplates(getDocumentTemplateType));
			}
		}

		if (['client', 'account', 'activity'].includes(type)) {
			const contactFilter = new RequestBuilder();
			contactFilter.addSort(Tools.Contact.attr.name, true);
			if (type === 'activity') {
				contactFilter.addFilter(Tools.Contact.attr.account.attr.id, ComparisonTypes.Equals, accountId);
			} else {
				contactFilter.addFilter(Tools.Contact.attr.account.attr.id, ComparisonTypes.Equals, id);
			}
			contactFilter.addFilter(Tools.Contact.attr.active, ComparisonTypes.Equals, true);

			if (contactName && contactId) {
				setSelectedContact({ id: contactId, title: contactName });
			}
			const fetchedContactsPromise = makeCancelable(
				Tools.Contact.customer(customerId).find(contactFilter.build())
			);
			fetchedContactsPromise.promise
				.then(fetchedContacts => {
					setContacts(fetchedContacts.data);
					setContactsFetched(true);
				})
				.catch(error => {
					logError(error, 'Error fetching contacts');
					setContactsFetched(true);
				});
			return () => {
				if (fetchedContactsPromise) {
					fetchedContactsPromise.cancel();
				}
			};
		} else {
			setContactsFetched(true);
		}
	}, []);

	return (
		<div className={classes.b()}>
			<ModalHeader
				className={classes.elem('up-modal-header').b()}
				title={T('default.create') + ' ' + T('default.document').toLowerCase()}
				icon="file-o"
				onClose={close}
			></ModalHeader>

			<ModalContent className={classes.elem('up-modal-content').b()}>
				<div className={classes.elem('up-panel').b()}>
					{contactsFetched ? (
						contacts.length ? (
							<Select<{ id: Contact['id']; title: Contact['name'] }>
								anchor={anchor}
								value={selectedContact}
								onChange={setSelectedContact}
								onClear={() => setSelectedContact(null)}
								placeholder={T('default.select') + ' ' + T('default.contact').toLowerCase()}
								options={contacts.map(contact => ({
									id: contact.id,
									title: contact.name
								}))}
							/>
						) : null
					) : (
						<Loader size="xs" />
					)}
					<Text space="ptl pbl">{T('document.selectDocumentTemplate')}</Text>
				</div>
				<div className={classes.elem('up-panel').b()}>
					{templates.length ? (
						<Table className={classes.elem('table').b()}>
							{templates
								.sort((a: DocumentTemplate, b: DocumentTemplate) => a.name.localeCompare(b.name))
								.map(template => (
									<TableRow
										className={classes.elem('table-row').b()}
										key={template.id}
										onClick={() => {
											selectDocument(template);
											close();
										}}
									>
										<TableColumn className={classes.elem('mid-mid').b()}>
											<Icon name="file" />
										</TableColumn>
										<TableColumn className={classes.elem('table-column-name').b()}>
											<b>{template.name}</b>
										</TableColumn>
									</TableRow>
								))}
						</Table>
					) : null}
				</div>
			</ModalContent>
		</div>
	);
};

export default CreateDocument;
