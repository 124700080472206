import CreditRisk from 'App/resources/Model/CreditRisk';
import CustomerPortfolioCompany from 'App/resources/Model/CustomerPortfolioCompany';
import React from 'react';
import { Severity, getRiskTooltipTitle } from '../helper';
import { Flex, Icon, TableColumn, Text, Tooltip } from '@upsales/components';
import { getIconColor } from 'App/components/RiskDrawer/RiskDrawerHelper';
import { useTranslation } from 'Components/Helpers/translate';

const RiskColumn = ({ client, risks }: { client: CustomerPortfolioCompany; risks: CreditRisk[] }) => {
	const orgNumber = client.prospectingId ? parseInt(client.prospectingId.split('.').pop()!) : 0;
	const { t } = useTranslation();

	const clientRisks = risks.filter(risk => risk.orgNumber === orgNumber);

	const severityCount = clientRisks.reduce((acc, risk) => {
		const severity = risk.severity as Severity;
		acc[severity] = (acc[severity] || 0) + 1;
		return acc;
	}, {} as Record<Severity, number>);

	return (
		<TableColumn>
			<Flex gap="u3">
				{Object.entries(severityCount)
					.filter(([severity]) => severity !== 'low')
					.map(
						([severity, count]) =>
							count > 0 && (
								<Flex key={client.id + severity} alignItems="center" gap="u1">
									<Tooltip title={t(getRiskTooltipTitle(severity as Severity))}>
										<Icon name="circle" color={getIconColor(severity)} />
									</Tooltip>
									<Text>{count}</Text>
								</Flex>
							)
					)}
			</Flex>
		</TableColumn>
	);
};

export default RiskColumn;
