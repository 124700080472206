import { Flex, Icon, TableColumn, Text } from '@upsales/components';
import CreditRisk from 'App/resources/Model/CreditRisk';
import CustomerPortfolioCompany from 'App/resources/Model/CustomerPortfolioCompany';
import React from 'react';
import { useTranslation } from 'Components/Helpers/translate';
import { getIconColor } from 'App/components/RiskDrawer/RiskDrawerHelper';
import BemClass from '@upsales/components/Utils/bemClass';
import moment from 'moment';

const ColumnBlock = ({ risk }: { risk: CreditRisk }) => {
	const { t } = useTranslation();
	return (
		<Flex alignItems="center" gap="u1">
			<Icon name="circle" color={getIconColor(risk?.severity)} />
			<Text color="grey-11">
				{`${t('customerPortfolio.risks.' + risk.name)} (${moment(risk.date).format('L')})`}
			</Text>
		</Flex>
	);
};

const RiskSection = ({ risks, titleKey }: { risks: CreditRisk[]; titleKey: string }) => {
	const { t } = useTranslation();
	return (
		<Flex direction="column" gap="u2" space="mtm mbl">
			<Text bold>
				{risks.length} {t(titleKey).toLowerCase()}
			</Text>
			{risks.map(risk => (
				<ColumnBlock key={risk.id} risk={risk} />
			))}
		</Flex>
	);
};

const ExpandedColumn = ({
	client,
	risks,
	classes
}: {
	client: CustomerPortfolioCompany;
	risks: CreditRisk[];
	classes: BemClass;
}) => {
	const orgNumber = client.prospectingId ? parseInt(client.prospectingId.split('.').pop()!) : 0;

	const filteredRisks = risks.filter(risk => risk.orgNumber === orgNumber);

	const highRisks = filteredRisks.filter(risk => risk.severity === 'high');
	const mediumRisks = filteredRisks.filter(risk => risk.severity === 'medium');

	return (
		<TableColumn colSpan={4} className={classes.elem('riskColumn').b()}>
			{highRisks.length > 0 ? (
				<RiskSection risks={highRisks} titleKey={`default.warning${highRisks.length !== 1 ? 's' : ''}`} />
			) : null}
			{mediumRisks.length > 0 ? (
				<RiskSection
					risks={mediumRisks}
					titleKey={`companyGroup.startPage.risksCompanyGroup.potentialRisks${
						mediumRisks.length !== 1 ? 'Many' : ''
					}`}
				/>
			) : null}
		</TableColumn>
	);
};

export default ExpandedColumn;
