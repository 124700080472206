import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import bemClass from '@upsales/components/Utils/bemClass';
import React, { useEffect } from 'react';
import AddOrDeactivate from './AddOrDeactivate';
import UserDeactivated from './UserDeactivated';
import SeatsConfirmed from './SeatsConfirmed';
import { PAGES } from '../../store/reducers/MaxCeilingReachedOnSeats';
import { reset } from '../../store/actions/MaxCeilingReachedOnSeats';
import { setupComponentCompatibility } from 'App/helpers/angularPortingHelpers';
import './MaxCeilingReachedOnSeats.scss';

const pages = {
	[PAGES.addOrDeactivate]: AddOrDeactivate,
	[PAGES.userDeactivated]: UserDeactivated,
	[PAGES.seatsConfirmed]: SeatsConfirmed
};

export const MaxCeilingReachedOnSeats = props => {
	const Page = props.page;
	const classes = new bemClass('MaxCeilingReachedOnSeats');

	useEffect(() => {
		props.reset();
	}, []);

	return (
		<div className={classes.b()}>
			<Page closeModal={props.resolve} />
		</div>
	);
};

const mapStateToProps = state => ({
	page: pages[state.MaxCeilingReachedOnSeats.page]
});

const mapDispatchToProps = {
	reset
};

MaxCeilingReachedOnSeats.propTypes = {
	reset: PropTypes.func,
	resolve: PropTypes.func,
	page: PropTypes.oneOf(Object.values(pages))
};

export const detached = MaxCeilingReachedOnSeats;

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(MaxCeilingReachedOnSeats);

export const MaxCeilingReachedOnSeatsReact = setupComponentCompatibility(ConnectedComponent, {
	classNames: ['FullscreenModal', 'portedMaxCeilingReachedOnSeats']
});

export default connect(mapStateToProps, mapDispatchToProps)(MaxCeilingReachedOnSeats);
