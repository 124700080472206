import React, { useRef, useState } from 'react';

import './EditNote.scss';

import BemClass from '@upsales/components/Utils/bemClass';
import {
	Button,
	Column,
	ModalContent,
	ModalControls,
	ModalHeader,
	Row,
	Textarea,
	Icon,
	Tooltip,
	Modal
} from '@upsales/components';
import insertSignatureOnRef from 'App/helpers/insertSignatureOnRef';
import { ModalProps } from 'App/components/Modals/Modals';

export interface Props extends ModalProps<string> {
	notes: string;
	title?: string;
	icon?: React.ComponentProps<typeof Icon>['name'];
	saveText?: string;
	closeText?: string;
	mutable: boolean;
	textareaTooltip?: null | string;
	onClose: (notes?: string) => void;
}

const Component: React.FC<Props> = (props: Props) => {
	const classes = new BemClass('EditNote');
	const textarea = useRef<HTMLTextAreaElement>();
	const [notes, setNotes] = useState(props.notes);

	const t = Tools.$translate;

	const lang = {
		title: t(props.title || 'default.notes'),
		save: t(props.saveText || 'default.save'),
		close: t(props.closeText || (props.mutable ? 'default.abort' : 'default.close')),
		signature: t('default.insertSignature')
	};

	return (
		// <Modal className={props.className}>
		<>
			<ModalHeader title={lang.title} icon={props.icon} />
			<ModalContent>
				<Tooltip title={props.textareaTooltip ?? ''} position="top">
					<Textarea
						className={classes.elem('text').b()}
						textareaRef={r => (textarea.current = r)}
						value={notes || ''}
						disabled={!props.mutable}
						inputClassName={classes.elem('input').b()}
						onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setNotes(e.target.value)}
					/>
				</Tooltip>
			</ModalContent>
			<ModalControls>
				<Row>
					{props.mutable ? (
						<Column align="left">
							<Button
								onClick={() =>
									textarea.current ? insertSignatureOnRef(textarea.current, setNotes) : null
								}
								data-testid="signature-btn"
								type="link"
							>
								{lang.signature}
							</Button>
						</Column>
					) : null}
					<Column align="right">
						{props.mutable ? (
							<Button
								onClick={() => props.close(notes)}
								shadow="none"
								data-testid="save-btn"
								className="main-action"
							>
								{lang.save}
							</Button>
						) : null}
						<Button onClick={() => props.close()} color="grey" data-testid="close-btn" type="link">
							{lang.close}
						</Button>
					</Column>
				</Row>
			</ModalControls>
		</>
		// </Modal>
	);
};

interface PropsAngular {
	$modalParams: Props;
	resolve: (notes: string) => void;
	reject: () => void;
}

const EditNote = <TProps extends Props | PropsAngular>(props: TProps) => {
	if (props.hasOwnProperty('$modalParams')) {
		const classes = new BemClass('CreateGroupMail');
		const componentProps = {
			...props.$modalParams,
			onClose: (notes?: string) => {},
			close: (notes?: string) => {
				if (notes) {
					props.resolve(notes);
				} else {
					props.reject();
				}
			},
			className: '',
			modalId: 0
		};
		return <div className={classes.b()}>{Component(componentProps)}</div>;
	} else {
		return (
			<Modal size="lg" className={`EditNote ${props.className}`}>
				{Component(props)}
			</Modal>
		);
	}
};

export default EditNote<Props>;
