import React from 'react';
import PropTypes from 'prop-types';
import {
	ModalContent,
	ModalControls,
	DateInput,
	TimeInput,
	ModalHeader,
	Button,
	Input,
	Label,
	Row,
	Column,
	ButtonGroup,
	Toggle,
	Text,
	Tooltip,
	Icon
} from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import moment from 'moment';
import { pick } from 'lodash';

import translate from '../../Helpers/translate';
import UpSelect from 'Components/Inputs/UpSelect';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import { ACTIVITY_PRIORITY } from 'App/babel/enum/activity';
import { makeCancelable } from '../../../helpers/promise';
import { mapProperties } from 'Services/ActionProperties';
import { asyncModalAdapter, setupComponentCompatibility } from 'App/helpers/angularPortingHelpers';

import './CreateActivity.scss';

export const TABS = {
	USER: 'user',
	ROLE: 'role'
};

export const COOKIE_PROP = 'actNotification';

const CAMPAIGN_LIMIT = 10;

class CreateActivity extends React.Component {
	static propTypes = {
		reject: PropTypes.func,
		resolve: PropTypes.func,
		reloadModalPosition: PropTypes.func,
		meta: PropTypes.shape({
			activityTypes: PropTypes.array,
			activeUsers: PropTypes.array,
			roles: PropTypes.array,
			activeUserId: PropTypes.number
		}),
		filters: PropTypes.any,
		extraParams: PropTypes.any,
		name: PropTypes.any,
		entity: PropTypes.string.isRequired,
		customerId: PropTypes.number.isRequired,
		numSelected: PropTypes.number
	};

	defaultSelectedTime = moment().format('HH:mm');

	lang = {
		create: translate('default.create'),
		activities: translate('default.activities'),
		description: translate('default.description'),
		activityType: translate('activity_type'),
		date: translate('default.date'),
		time: translate('default.time'),
		user: translate('user'),
		campaign: translate('campaign'),
		action: translate('admin.action'),
		cancel: translate('cancel'),
		accountManager: translate('default.accountManager'),
		assignedUser: translate('automationTerms.assignedUser'),
		describeActivity: translate('activity.describeActivity'),
		activity: translate('activity'),
		searchCampaigns: translate('activity.searchCampaigns'),
		toUserOrRole: translate('activity.toUserOrRole'),
		toUserOrRoleTooltip: translate('activity.toUserOrRole.tooltip'),
		role: translate('default.role'),
		selectUser: translate('default.selectUser'),
		selectRole: translate('default.selectRole'),
		searchRoles: translate('activity.searchRoles'),
		searchUsers: translate('activity.searchUsers'),
		giveToAccountManager: translate('activity.giveToAccountManager'),
		giveToAssignedUser: translate('activity.giveToAssignedUser'),
		sendNotification: translate('activity.sendNotification'),
		noResults: translate('default.noResults'),
		priority: translate('activity.priority'),
		high: translate('activity.high')
	};

	isPriorityEnable = Tools.FeatureHelper.hasSoftDeployAccess('ACTIVITY_PRIORITIZATION');

	constructor(props) {
		super(props);

		const { activeUserId, activeUsers = [], roles = [] } = this.props.meta;
		const select = getAngularModule('$multiSelect');
		this.selected = this.props.numSelected
			? this.props.numSelected
			: select.selected.length
			? select.selected.length
			: select.getTotal();

		this.users = [
			{ id: '{{Client.UserId}}', name: this.lang.accountManager },
			{ id: '{{Client.AssignedUserId}}', name: this.lang.assignedUser },
			...activeUsers.map(user => ({ ...user, id: String(user.id) }))
		];
		this.roles = roles.map(role => ({
			...role,
			users: activeUsers.filter(user => role.id === (user.role && user.role.id))
		}));
		this.state = {
			currentTab: TABS.USER,
			activity: {
				Description: '',
				Date: new Date(),
				Time: '',
				ActivityType: Tools.AppService.getMetadata().params.DefaultActivityTypeId,
				User: this.selected > 1 ? [String(activeUserId)] : String(activeUserId),
				Project: '',
				AssignToManager: 0,
				AssignToUser: 0,
				SendNotification: Number(!document.cookie.includes(COOKIE_PROP)),
				Priority: ACTIVITY_PRIORITY.NONE
			},
			saving: false,
			touched: {
				Description: false
			},
			defaultUsers: this.selected > 1 ? [String(activeUserId)] : String(activeUserId)
		};
	}

	componentWillUnmount() {
		if (this.cancelablePromise) {
			this.cancelablePromise.cancel();
		}
	}

	onBlur = (event, key) => {
		const { touched } = this.state;
		this.setState({
			touched: {
				...touched,
				[key]: true
			}
		});
	};

	onChange = (event, key) => {
		this.setState({
			activity: {
				...this.state.activity,
				[key]: event.target.value
			}
		});
	};

	onUserChange = ({ target }) => {
		const { activity } = this.state;
		const defaultUsers =
			this.selected > 1
				? this.users.filter(user => target.value.includes(user.id)).map(({ id, name }) => ({ id, name }))
				: target.value;

		this.setState({
			defaultUsers,
			activity: {
				...activity,
				User: target.value
			}
		});
	};

	onRoleChange = event => {
		const { users = [] } = this.roles.find(role => role.id === parseInt(event.target.value)) || {};

		this.setState({
			activity: {
				...this.state.activity,
				User: users.map(({ id }) => Number(id))
			}
		});
	};

	onDateChange = event => {
		const isValid = moment(event.target.value).isValid();

		this.setState({
			activity: {
				...this.state.activity,
				Date: isValid ? event.target.value : null
			}
		});
	};

	closeAfterSave() {
		const $multiSelect = getAngularModule('$multiSelect');

		$multiSelect.selectNone();
		this.props.resolve();
	}

	save = () => {
		const { selected } = getAngularModule('$multiSelect');
		const MultiActions = getAngularModule('MultiActions');
		const NotificationService = getAngularModule('NotificationService');
		const { filters, extraParams, name, entity, customerId } = this.props;
		const activity = pick(this.state.activity, activity => !!activity);

		this.setState({ saving: true });

		if (activity.Date) {
			activity.Date = moment(activity.Date).format('YYYY-MM-DD');
		}

		if (Array.isArray(activity.Users)) {
			activity.Users = activity.Users.map(id => Number(id));
		}

		if (selected && selected.length) {
			filters.addFilter({ field: 'id' }, filters.comparisonTypes.Equals, selected);
		}

		const properties = mapProperties(activity);
		this.cancelablePromise = makeCancelable(
			MultiActions.customer(customerId)
				.createActivity(
					entity,
					selected.length,
					properties,
					filters.build(),
					name,
					extraParams ? extraParams : {}
				)
				.then(() => {
					// If we selected less than 20, we create activities directly
					if (selected.length < 20) {
						NotificationService.addNotification({
							style: NotificationService.style.SUCCESS,
							icon: 'check',
							title: 'default.created',
							body: 'saved.activities'
						});
					} else {
						// Else a job will be created
						NotificationService.addNotification({
							style: NotificationService.style.SUCCESS,
							icon: 'check',
							title: 'default.created',
							body: 'saveQued.activities'
						});
					}
				})
		);

		return this.cancelablePromise.promise
			.then(() => {
				this.setState({ saving: false });
				this.closeAfterSave();
			})
			.catch(error => {
				if (error && error.isCanceled) {
					return;
				}

				NotificationService.addNotification({
					style: NotificationService.style.ERROR,
					icon: 'times',
					title: 'default.error',
					body: 'saveError.activities'
				});

				this.setState({ saving: false });
			});
	};

	handleTabChange = currentTab => {
		const { activity } = this.state;
		const { activeUserId } = this.props.meta;
		let user = null;
		if (currentTab === TABS.USER) {
			user = this.selected > 1 ? [String(activeUserId)] : String(activeUserId);
		}

		this.setState({
			currentTab,
			defaultUsers: this.selected > 1 ? [String(activeUserId)] : String(activeUserId),
			activity: {
				...activity,
				User: user
			}
		});
	};

	onToggleChange = (name, checked) => {
		const { activity } = this.state;

		this.setState({
			activity: {
				...activity,
				[name]: Number(checked)
			}
		});
	};

	onNotificationChange = checked => {
		this.onToggleChange('SendNotification', checked);
		if (!document.cookie.includes(COOKIE_PROP)) {
			document.cookie = `${COOKIE_PROP}=0`;
		}
	};

	setUserSelection = (el, cb) => {
		const { activity } = this.state;
		if (!activity.User) return cb(null);
		if (!Array.isArray(activity.User)) {
			return cb(this.users.find(user => activity.User === user.id));
		}
		const defaultUsers = this.users.filter(user => activity.User.includes(user.id));

		cb(defaultUsers);
	};

	campaignTransport = query => {
		const RequestBuilder = getAngularModule('RequestBuilder');
		const Campaign = getAngularModule('Campaign');
		const AppService = getAngularModule('AppService');
		const campaingsRb = new RequestBuilder();

		if (query.data) {
			campaingsRb.fields = ['id', 'name'];
			campaingsRb.addFilter(Campaign.attr.active, campaingsRb.comparisonTypes.Equals, true);
			campaingsRb.addFilter(Campaign.attr.name, campaingsRb.comparisonTypes.Search, query.data.term);
			campaingsRb.addSort(Campaign.attr.name, true);
			campaingsRb.limit = CAMPAIGN_LIMIT;
			campaingsRb.offset = (query.data.page - 1) * CAMPAIGN_LIMIT;

			return Campaign.customer(AppService.getCustomerId())
				.find(campaingsRb.build())
				.then(res => query.success(res));
		}
		return query.success({ data: [] });
	};

	render() {
		const className = new bemClass('CreateActivityModal');
		const { saving, touched, activity, currentTab, defaultUsers } = this.state;
		const { activityTypes = [] } = this.props.meta;
		const {
			ActivityType,
			Date,
			Time,
			Description,
			AssignToManager,
			AssignToUser,
			SendNotification,
			Priority,
			User
		} = activity;
		const hasUser = Array.isArray(User) ? User.length > 0 : !!User;
		const isValid = !!Date && !!Description && hasUser;
		const isMultiSelect = this.selected > 1;
		const campaignOptions = {
			ajax: {
				transport: this.campaignTransport,
				data: (term, page) => ({ term: term, page: page }),
				results: (res, page) => {
					const offset = (page - 1) * CAMPAIGN_LIMIT;
					const more = offset + CAMPAIGN_LIMIT < res.metadata.total;
					return { results: res.data, more };
				}
			},
			formatNoMatches: () => this.lang.noResults
		};

		return (
			<div id="create-acrivity" className={className.b()}>
				<ModalHeader title={`${this.lang.create} ${this.lang.activities}`} onClose={this.props.reject} />
				<ModalContent>
					<Row direction="row">
						<Label required>{this.lang.description}</Label>
						<Input
							autofocus
							className={className.elem('Form-control').b()}
							disabled={saving}
							placeholder={this.lang.describeActivity}
							onChange={event => this.onChange(event, 'Description')}
							onBlur={event => this.onBlur(event, 'Description')}
							state={touched['Description'] && !Description ? 'error' : null}
						/>
					</Row>
					<Row direction="row" className="column-justify">
						<Column size={this.isPriorityEnable ? 6 : 12}>
							<Label>{this.lang.activityType}</Label>
							<UpSelect
								className={className.elem('Form-control').b()}
								getId={(x = {}) => x.id}
								formatResult={(x = {}, container, query, escape) => escape(x.name)}
								formatSelection={(x = {}, container, escape) => escape(x.name)}
								onChange={event => this.onChange(event, 'ActivityType')}
								data={activityTypes}
								disabled={saving}
								defaultValue={ActivityType}
							/>
						</Column>
						{this.isPriorityEnable ? (
							<Column size={5.5}>
								<Label>{this.lang.priority}</Label>
								<div className={className.elem('Priority')}>
									<Toggle
										color="red"
										size="lg"
										onChange={checked =>
											this.onToggleChange(
												'Priority',
												checked ? ACTIVITY_PRIORITY.HIGH : ACTIVITY_PRIORITY.NONE
											)
										}
										checked={!!Priority}
									/>
									<Text>{this.lang.high}</Text>
								</div>
							</Column>
						) : null}
					</Row>
					<Row direction="row">
						<Label required>{this.lang.date}</Label>
						<div className={className.elem('Form-control', 'Date-area').b()}>
							<Column size={4}>
								<DateInput
									closeOnSelect
									placeholder={this.lang.date}
									value={Date}
									onChange={this.onDateChange}
									disabled={saving}
									state={!Date ? 'error' : null}
									calendarSize={6}
								/>
							</Column>
							<Column size={8}>
								<TimeInput
									placeholder={this.lang.time}
									defaultSelected={this.defaultSelectedTime}
									value={Time}
									onChange={event => this.onChange(event, 'Time')}
									disabled={saving}
									state={!Date ? 'error' : null}
								/>
							</Column>
						</div>
					</Row>
					<Row>
						<div className={className.elem('user-or-role-label').b()}>
							<Label>{this.lang.toUserOrRole}</Label>
							<Tooltip title={this.lang.toUserOrRoleTooltip}>
								<Icon name="question-circle" />
							</Tooltip>
						</div>
						<ButtonGroup className={className.elem('Form-control').b()}>
							{Object.keys(TABS).map(tab => (
								<Button
									key={tab}
									size="sm"
									onClick={() => this.handleTabChange(TABS[tab])}
									color={currentTab === TABS[tab] ? 'bright-blue' : 'light-grey'}
								>
									{this.lang[TABS[tab]]}
								</Button>
							))}
						</ButtonGroup>
					</Row>
					{currentTab === TABS.USER ? (
						<Row direction="row">
							<Label required>{this.lang.selectUser}</Label>
							<UpSelect
								required
								multiple={isMultiSelect}
								className={className.elem('Form-control').b() + (hasUser ? '' : ' has-error')}
								getId={(x = {}) => x.id}
								formatResult={(x = {}, container, query, escape) => escape(x.name)}
								formatSelection={(x = {}, container, escape) => escape(x.name)}
								disabled={saving}
								onChange={this.onUserChange}
								data={this.users}
								initSelection={this.setUserSelection}
								placeholder={this.lang.searchUsers}
								defaultValue={defaultUsers}
							/>
						</Row>
					) : null}

					{currentTab === TABS.ROLE ? (
						<Row direction="row">
							<Label required>{this.lang.selectRole}</Label>
							<UpSelect
								required
								className={className.elem('Form-control').b() + (hasUser ? '' : ' has-error')}
								getId={(x = {}) => x.id}
								formatResult={(x = {}, container, query, escape) =>
									`<div class="${className.elem('Result-wrapper')}">
										<span>${escape(x.name)}</span>
										<span class="${className.elem('Users-count')}">${x.users.length} users</span>
									</div>`
								}
								formatSelection={(x = {}, container, escape) => escape(x.name)}
								disabled={saving}
								onChange={this.onRoleChange}
								data={this.roles}
								placeholder={this.lang.searchRoles}
							/>
						</Row>
					) : null}
					<Row direction="row">
						<Label>{this.lang.campaign}</Label>
						<UpSelect
							className={className.elem('Form-control').b()}
							getId={(x = {}) => x.id}
							formatResult={(x = {}, container, query, escape) => escape(x.name)}
							formatSelection={(x = {}, container, escape) => escape(x.name)}
							disabled={saving}
							placeholder={this.lang.searchCampaigns}
							onChange={event => this.onChange(event, 'Project')}
							options={campaignOptions}
						/>
					</Row>
					<Row>
						<Toggle
							size="xs"
							onChange={checked => this.onToggleChange('AssignToManager', checked)}
							checked={!!AssignToManager}
						/>
						<Text>{this.lang.giveToAccountManager}</Text>
					</Row>
					<Row>
						<Toggle
							size="xs"
							onChange={checked => this.onToggleChange('AssignToUser', checked)}
							checked={!!AssignToUser}
						/>
						<Text>{this.lang.giveToAssignedUser}</Text>
					</Row>
					<Row>
						<Toggle size="xs" onChange={this.onNotificationChange} checked={!!SendNotification} />
						<Text>{this.lang.sendNotification}</Text>
					</Row>
				</ModalContent>
				<ModalControls>
					<Button
						shadow="none"
						color={isValid ? 'bright-blue' : 'light-grey'}
						onClick={this.save}
						disabled={!isValid}
						loading={saving}
					>
						{this.lang.create} {this.selected}{' '}
						{isMultiSelect ? this.lang.activities.toLowerCase() : this.lang.activity.toLowerCase()}
					</Button>
					<Button onClick={this.props.reject} shadow="none" type="link" color="grey">
						{this.lang.cancel}
					</Button>
				</ModalControls>
			</div>
		);
	}
}

window.CreateActivity = CreateActivity;

export const CreateActivityModal = setupComponentCompatibility(CreateActivity, {
	modalName: 'CreateActivityModal',
	modalSize: 'sm',
	modalParamsMapper: $modalParams => $modalParams,
	setupMetaInit: async () => ({
		activeUsers: Tools.AppService.getActiveUsers(),
		activeUserId: Tools.AppService.getSelf().id,
		activityTypes: Tools.AppService.getActivityTypes('activity', true),
		activityCustomFields: Tools.AppService.getCustomFields('activity'),
		roles: Tools.AppService.getRoles()
	})
});

export const openCreateActivityModal = asyncModalAdapter({
	upModalName: 'CreateActivityMulti',
	openModalName: 'CreateActivityModal',
	featureFlag: 'REACT_CREATE_ACTIVITY'
});

export default CreateActivity;
