import React, { Component } from 'react';
import Hs from 'highcharts';
import colorMappings from '@upsales/components/Utils/colorMappings';
import { Headline } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import moment from 'moment';
import T from 'Components/Helpers/translate';
import { currencyFormat } from 'App/babel/components/Filters/Currencies';

import './ThirdScreen.scss';

interface Chart {
	new (...args: any): any;
}
const Highcharts = Hs as unknown as {
	Chart: Chart;
};

type Props = {
	next: () => void;
	data: Array<{ value: number; month: number }>;
	visible: boolean;
};

class ThirdScreen extends Component<Props> {
	_chart?: HTMLDivElement | null;
	lang: {
		yourSalesDuringThisYear: string;
		upsalesAverage: string;
		yourSales: string;
	};

	constructor(p: Props) {
		super(p);

		this.lang = {
			yourSalesDuringThisYear: T('yourYearWithUpsales.yourSalesDuringThisYear'),
			upsalesAverage: T('yourYearWithUpsales.upsalesAverage'),
			yourSales: T('yourYearWithUpsales.yourSales')
		};
	}

	generateData() {
		const { data } = this.props;

		const res = Array(12).fill(0);
		for (const obj of data) {
			res[obj.month - 1] = obj.value;
		}

		return res;
	}

	componentDidUpdate() {
		this.renderChart();
	}

	convertCurrency(value: number, currency: string) {
		switch (currency) {
			case 'EUR':
				return value * 0.09;
			case 'GBP':
				return value * 0.078;
			case 'NOK':
				return value * 1.02;
			case 'DKK':
				return value * 0.67;
			default:
				return value;
		}
	}

	renderChart() {
		if (this._chart) {
			const currencies = Tools.AppService.getMetadata()?.customerCurrencies ?? [];
			const masterCurrency = currencies.find(currency => currency.masterCurrency)?.iso ?? 'SEK';
			const config = {
				chart: {
					type: 'spline',
					backgroundColor: 'transparent',
					width: 800
				},
				title: { text: null },
				credits: { enabled: false },
				yAxis: {
					title: { text: null }
				},
				xAxis: {
					categories: moment.monthsShort()
				},
				plotOptions: {
					series: {
						animation: {
							duration: 5000
						}
					}
				},
				legend: {
					verticalAlign: 'top'
				},
				series: [
					{
						name: this.lang.yourSales,
						color: colorMappings.get('dark-green'),
						showInLegend: true,
						lineWidth: 5,
						marker: {
							enabled: false
						},
						tooltip: {
							pointFormatter: function () {
								return currencyFormat((this as any).y, masterCurrency);
							}
						},

						data: this.generateData()
					},
					{
						name: this.lang.upsalesAverage,
						color: colorMappings.get('bright-green'),
						showInLegend: true,
						lineWidth: 5,
						marker: {
							enabled: false
						},
						tooltip: {
							pointFormatter: function () {
								return currencyFormat(
									(this as any).y,
									['EUR', 'GBP', 'NOK', 'DKK'].includes(masterCurrency) ? masterCurrency : 'SEK'
								);
							}
						},

						//select MEDIAN(sum), d from (select TO_VARCHAR("CLOSEDATE", '%Y%m') as d, SUM("value") as sum, USER_ID from ORDERS where PROBABILITY = 100 and YEAR("CLOSEDATE") = 2021 group by USER_ID, d) group by d order by d;
						// Generated using this query 👆
						data: [
							113021, 141580, 138615, 104526, 125000, 126413, 56909, 104555, 103944, 101430, 121185,
							110000
						].map(value => this.convertCurrency(value, masterCurrency))
					}
				]
			};
			new Highcharts.Chart(this._chart, config);
		}
	}

	render() {
		const { next, visible } = this.props;
		const classNames = new bemClass('ThirdScreen');

		return visible ? (
			<div className={classNames.b()} onClick={next}>
				<Headline>{this.lang.yourSalesDuringThisYear}</Headline>
				<div ref={r => (this._chart = r)}></div>
				<img src="img/yourYearWithUpsales/Champagne-lady.svg" />
			</div>
		) : null;
	}
}

export default ThirdScreen;
