import React from 'react';
import PropTypes from 'prop-types';
import bemClass from '@upsales/components/Utils/bemClass';
import { Toggle, Text, Label, Icon, Button, Headline, Input, Row, Column } from '@upsales/components';
import T from '../../Helpers/translate';
import getAngularModule from '../../../angularHelpers/getAngularModule';
import Confirmation from 'Components/Dialogs/Confirmation';
import { getJourneyStep } from 'Components/Helpers/journeyStep';
import { FormComponent } from 'App/components/FormComponent';
import _ from 'lodash';
import Client from 'App/resources/Model/Client';
import UpSelect from 'Components/Inputs/UpSelect';
import OrderStage from 'App/resources/Model/OrderStage';

import './AccountDeactivationModal.scss';

import ClientDeactivationStats from 'App/resources/ClientDeactivationStats';
import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';

type Props = {
	resolve?: (options: Options) => void;
	reject?: () => void;
	company: Pick<Client, 'name' | 'active' | 'journeyStep' | 'id'>;
	oldModal?: boolean;
	close?: (options?: Options) => void;
	className?: string;
};

type State = {
	reason: string;
	confirmation: string;
	inactivateContacts: boolean;
	disqualify: boolean;
	selectedStage: OrderStage | null;
	opportunityNumber: string;
	contactsNumber: string;
	activitiesNumber: string;
};

export type Options = Pick<State, 'reason' | 'inactivateContacts' | 'disqualify'> & {
	moveOrdersToStageId: number | null;
};

class AccountDeactivationModal extends React.Component<Props, State> {
	static propTypes = {
		resolve: PropTypes.func,
		reject: PropTypes.func,
		company: PropTypes.shape({
			name: PropTypes.string,
			active: PropTypes.number,
			journeyStep: PropTypes.string
		})
	};

	state: State;
	lang: { [key: string]: string };
	hasJourney: boolean;
	stages: OrderStage[];

	async getData() {
		const { data: deactivationStats } = await ClientDeactivationStats.get(this.props.company.id);

		this.setState({
			opportunityNumber: `${deactivationStats.opportunities || ''}`,
			contactsNumber: `${deactivationStats.contacts || ''}`,
			activitiesNumber: `${deactivationStats.activities || ''}`
		});
	}

	constructor(props: Props) {
		super(props);

		const AppService = getAngularModule('AppService');
		const FeatureHelper = getAngularModule('FeatureHelper');
		this.hasJourney = FeatureHelper.isAvailable(FeatureHelper.Feature.JOURNEY_STATUS);
		this.stages = AppService.getStages('lost');

		const { company } = this.props;
		const companyName = company.name;
		const journeyStep = getJourneyStep(company.journeyStep || 'lead');

		this.lang = {
			title: T('AccountDeactivationModal.title', { companyName }),
			subtitle:
				!this.hasJourney || company.journeyStep === 'disqualified'
					? T('AccountDeactivationModal.subtitleNoJourney')
					: T('AccountDeactivationModal.subtitle', { journeyStep: journeyStep ? journeyStep.name : '' }),
			options: `${T('default.options')}:`,
			disqualifyCompany: T('AccountDeactivationModal.disqualify'),
			inactivateContacts: T('AccountDeactivationModal.inactivateAllContacts'),
			thisWillHappen: T('AccountDeactivationModal.thisWillHappen'),
			willCloseOpenActivities: 'AccountDeactivationModal.willCloseOpenActivities',
			willInactivateContacts: 'AccountDeactivationModal.willInactivateContacts',
			willSetOpportunitiesToLost: 'AccountDeactivationModal.willSetOpportunitiesToLost',
			willSetOpportunitiesToLostIncludingOtherUsers: T(
				'AccountDeactivationModal.willSetOpportunitiesToLostIncludingOtherUsers'
			),
			willSetJourneyStatusToDisqualified: T('AccountDeactivationModal.willSetJourneyStatusToDisqualified', {
				companyName
			}),
			learnMore: T('AccountDeactivationModal.learnMore'),
			reasonForInactivation: T('AccountDeactivationModal.reasonForInactivation'),
			inactivateCompany: `${T('integration.inactivate')} ${companyName}`,
			cancel: T('default.abort')
		};

		this.state = {
			reason: '',
			confirmation: '',
			inactivateContacts: true,
			disqualify: this.hasJourney,
			selectedStage: this.stages.at(0) || null,
			opportunityNumber: '',
			contactsNumber: '',
			activitiesNumber: ''
		};
		this.getData();
	}

	reject = () => (this.props.oldModal ? this.props.reject?.() : this.props.close?.());

	resolve = () => {
		const options: Options = _.pick(this.state, ['reason', 'inactivateContacts', 'disqualify']);
		options.moveOrdersToStageId = this.state.selectedStage ? this.state.selectedStage.id : null;
		if (this.props.oldModal) {
			this.props.resolve?.(options);
		} else {
			this.props.close?.(options);
		}
	};

	stageSelectChanged = (event: { target: { added: OrderStage } }) =>
		this.setState({ selectedStage: event?.target?.added ?? null });

	onConfirmationChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
		this.setState({ confirmation: event.target.value });
	};

	onReasonChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
		this.setState({ reason: event.target.value });
	};

	onInactivateContactsChanged = (value: boolean) => {
		this.setState({ inactivateContacts: value });
	};

	onDisqualifyChanged = (value: boolean) => {
		this.setState({ disqualify: value });
	};

	render() {
		const { company, oldModal, className } = this.props;
		const classes = new bemClass('AccountDeactivationModal', className).mod({ 'old-modal': !!oldModal });
		const lang = this.lang;
		const companyName = company.name.replace(/\s\s+/g, ' ');
		const confirmationValid = this.state.confirmation.toLowerCase() === companyName.toLowerCase();

		const select2Options = {
			dropdownCssClass: 'up-select2--inline__dropdown',
			minimumResultsForSearch: -1,
			allowClear: false
		};

		return (
			<div className={oldModal ? '' : classes.b()}>
				<div className={classes.elem('header').b()}>
					<Button shadow="none" color="light-grey" onClick={this.reject}>
						<Icon name="times" />
					</Button>
				</div>
				<div className={classes.elem('container').b()}>
					<Headline className={classes.elem('margin').b()} size="sm">
						{lang.title}
					</Headline>
					<Text className={classes.elem('margin').b()} size="lg" color="grey-10">
						{lang.subtitle}
					</Text>
					<Text className={classes.elem('options').b()} bold={true}>
						{lang.options}
					</Text>
					<Row className={classes.elem('toggle-row').b()}>
						<Column>
							<Toggle
								onChange={this.onInactivateContactsChanged}
								size="lg"
								checked={this.state.inactivateContacts}
								color="red"
							/>
							<span>{lang.inactivateContacts}</span>
						</Column>
						{this.hasJourney ? (
							<Column>
								<Toggle
									onChange={this.onDisqualifyChanged}
									size="lg"
									checked={this.state.disqualify}
									color="red"
								/>
								<span>{lang.disqualifyCompany}</span>
							</Column>
						) : null}
					</Row>
					<div className={classes.elem('info-square').b()}>
						<Row>
							<Label>{lang.thisWillHappen}</Label>
						</Row>
						<Row>
							<Icon color="bright-green" name="check" />
							<Text className="text-ellipsis">
								{T(lang.willCloseOpenActivities, {
									activities: this.state.activitiesNumber
								})}
							</Text>
						</Row>
						<Row>
							<Icon color="bright-green" name="check" />
							<Text>
								{T(lang.willSetOpportunitiesToLost, {
									opportunities: this.state.opportunityNumber
								})}
							</Text>
							{this.stages.length > 1 ? (
								<Text color="bright-blue" style={{ overflow: 'hidden' }}>
									<UpSelect
										className="up-select2--inline up-select2--inline-no-border"
										onChange={this.stageSelectChanged}
										defaultValue={this.state.selectedStage}
										options={select2Options}
										data={this.stages}
									/>
								</Text>
							) : (
								<Text bold={true} style={{ marginLeft: 5 }}>
									{this.state.selectedStage ? this.state.selectedStage.name : ''}
								</Text>
							)}
							<Text style={{ marginLeft: 5 }} className="text-ellipsis">
								{lang.willSetOpportunitiesToLostIncludingOtherUsers}
							</Text>
						</Row>
						{this.state.inactivateContacts ? (
							<Row>
								<Icon color="bright-green" name="check" />
								<Text className="text-ellipsis">
									{T(lang.willInactivateContacts, {
										contacts: this.state.contactsNumber
									})}
								</Text>
							</Row>
						) : null}
						{this.state.disqualify ? (
							<Row>
								<Icon color="bright-green" name="check" />
								<Text className="text-ellipsis">{lang.willSetJourneyStatusToDisqualified}</Text>
							</Row>
						) : null}
						<Row>
							<ReactTemplates.elevio articleId={991} sidebar={true} text={this.lang.learnMore} />
						</Row>
					</div>
					<FormComponent value={this.state.reason} maxLength={256} label={lang.reasonForInactivation}>
						<Input value={this.state.reason} onChange={this.onReasonChanged} maxLength={256} />
					</FormComponent>

					{/* id = react_alert_modal is a ugly hax to get the correct css */}
					<div id="react_alert_modal" style={{ marginBottom: 25 }}>
						<Confirmation
							input={this.state.confirmation}
							valid={confirmationValid}
							compare={this.onConfirmationChanged}
							confirmText={companyName}
						/>
					</div>
					<Button
						disabled={!confirmationValid}
						size="xl"
						color="red"
						shadow="none"
						block={true}
						onClick={this.resolve}
					>
						{lang.inactivateCompany}
					</Button>
					<Button block={true} type="link" color="grey" onClick={this.reject}>
						{lang.cancel}
					</Button>
				</div>
			</div>
		);
	}
}

export const openAccountDeactivationModal = asyncModalAdapter({
	upModalName: 'deactivateClientConfirm',
	openModalName: 'AccountDeactivationModal',
	featureFlag: 'ACCOUNT_DEACTIVATION_MODAL_REACT'
});

export default AccountDeactivationModal;
