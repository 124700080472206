import { Block, Flex, Icon, Title } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React from 'react';

type Props = {
	buttonText: string;
	clientName?: string;
	onClick: () => void;
	classes: BemClass;
};

const BackButton = ({ buttonText, clientName, classes, onClick }: Props) => {
	return (
		<Block className={classes.elem('backButton').b()} onClick={onClick}>
			<Title>
				<Flex alignItems="center">
					<Icon name="chevron-left" space="mrm" />
					{buttonText}
					{clientName ? clientName : null}
				</Flex>
			</Title>
		</Block>
	);
};

export default BackButton;
