import { Button, Modal, ModalContent, ModalControls, ModalHeader } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import FormSubmitAttributes from 'App/babel/attributes/FormSubmit';
import MailAttributes from 'App/babel/attributes/MailAttributes';
import OrderAttributes from 'App/babel/attributes/Order';
import UserDefinedObjectAttribute from 'App/babel/attributes/UserDefinedObjectAttributes';
import VisitAttributes from 'App/babel/attributes/VisitAttributes';
import ActivityResource from 'App/babel/resources/Activity';
import AppointmentResource from 'App/babel/resources/Appointment';
import MailResource from 'App/babel/resources/Mail';
import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';
import FormSubmitResource from 'App/resources/FormSubmit';
import Activity from 'App/resources/Model/Activity';
import Appointment from 'App/resources/Model/Appointment';
import Contact from 'App/resources/Model/Contact';
import FormSubmit from 'App/resources/Model/FormSubmit';
import Mail from 'App/resources/Model/Mail';
import Order from 'App/resources/Model/Order';
import UserDefinedObjectType from 'App/resources/Model/UserDefinedObjectType';
import Visitor from 'App/resources/Model/Visit';
import OrderResource from 'App/resources/Order';
import UserDefinedObjectResource, { UserDefinedObject } from 'App/resources/UserDefinedObject';
import VisitResource from 'App/resources/Visit';
import Avatar from 'Components/Avatar';
import { useTranslation } from 'Components/Helpers/translate';
import logError from 'Helpers/logError';
import ComparisonTypes from 'Resources/ComparisonTypes';
import RequestBuilder from 'Resources/RequestBuilder';
import Resource from 'Resources/Resource';
import async from 'async';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ModalProps } from '../Modals/Modals';
import './ExportContactDataModal.scss';

const fetchAll = (item: { find: Resource['find'] }, rb: RequestBuilder) => {
	const promise = new Promise((resolve, reject) => {
		let fetch = true;
		const allResults = {
			metadata: {
				total: 0
			},
			data: [],
			error: undefined
		};
		async.whilst(
			() => fetch,
			(callback: Function) => {
				rb.offset = allResults.data.length;
				item.find(rb.build())
					.then(result => {
						fetch = result.metadata.offset + result.data.length < result.metadata.total;
						allResults.metadata.total = result.metadata.total;
						allResults.data = allResults.data.concat(result.data);
						callback();
					})
					.catch(err => {
						allResults.error = err;
						callback(err);
					});
			},
			(err: unknown) => {
				if (err) {
					return reject(err);
				}
				resolve(allResults);
			}
		);
	});
	return promise;
};

const renderDataRow = ({ title, value }: DataRow) => {
	return (
		<div key={'data-row-' + title} className="export-info-row" style={{ display: 'flex' }}>
			<span className="export-info-title" style={{ minWidth: '60px', flex: 2 }}>
				{title}
			</span>
			<span className="export-info-data" style={{ flex: 3, color: '#999999', fontStyle: 'italic' }}>
				{value ?? ''}
			</span>
		</div>
	);
};

type ExportDataPrintFormatProps = {
	t: (key: string) => string;
	generalData: DataRow[];
	entityData: { title: string; num: number; rows: (row: any) => JSX.Element }[];
};

const ExportDataPrintFormat = ({ t, generalData, entityData }: ExportDataPrintFormatProps) => (
	<div className="export-info-wrapper">
		<h3>{t('default.generalInfo')}</h3>
		{generalData.map(renderDataRow)}
		<h3>{t('default.data')}</h3>
		{entityData.map(entity => (
			<div key={entity.title}>
				<h4>{`${entity.title} (${entity.num})`}</h4>
				{entity.rows}
			</div>
		))}
	</div>
);

const contactFields: (keyof Contact)[] = ['title', 'phone', 'cellPhone', 'email'];

type DataRow = {
	title: string;
	value: any;
};

type Props = {
	contact: Contact;
	UserDefinedObject: UserDefinedObjectType[];
} & ModalProps;

const ExportContactDataModal = ({ className, close, contact, UserDefinedObject }: Props) => {
	const classes = new BemClass('ExportContactDataModal', className);
	const { t } = useTranslation();
	const printAreaRef = useRef<HTMLDivElement>(null);
	const [fetching, setFetching] = useState(false);
	const [fetchingPrintable, setFetchingPrintable] = useState(false);
	const [data, setData] = useState<DataRow[]>([]);
	const generalData = Object.entries(contact).reduce((acc, [key, value]) => {
		if (contactFields.includes(key)) {
			acc.push({ title: t('default.' + key), value });
		}
		return acc;
	}, [] as DataRow[]);

	const itemMap = useMemo<Record<string, any>>(() => {
		let itemMap: Record<string, any> = {
			// number of activities
			activity: {
				title: t('default.activity'),
				// ActivityAttributes.contact.attr.id is 'contacts.id'
				attr: { field: 'contact.id' },
				find: (filter: object) => ActivityResource.find(filter),
				fields: ['date', 'description', 'notes'],
				format: (row: Activity) => (
					<div>
						<p>{`${row.date}: ${(row.description || '').replace(/[<>]/g, '')}`}</p>
						<i>
							{(row.notes || '').split('\n').map((line: string, index: number) => (
								<p key={index}>{line}</p>
							))}
						</i>
					</div>
				)
			},
			// number of meetings
			appointment: {
				title: t('default.appointment'),
				// AppointmentAttributes.contact.attr.id is 'contacts.id'
				attr: { field: 'contact.id' },
				find: (filter: object) => AppointmentResource.find(filter),
				fields: ['regDate', 'description', 'notes'],
				format: (row: Appointment) => (
					<div>
						<p>{`${row.regDate}: ${(row.description || '').replace(/[<>]/g, '')}`}</p>
						<i>
							{(row.notes || '').split('\n').map((line: string, index: number) => (
								<p key={index}>{line}</p>
							))}
						</i>
					</div>
				)
			},
			// number of opportunities/sales
			order: {
				title: t('default.order'),
				attr: OrderAttributes.contact,
				find: (filter: object) => OrderResource.find(filter),
				fields: ['date', 'description'],
				format: (row: Order) => (
					<p>{`${row.date ? moment(row.date).toDate().toLocaleDateString() : ''}: ${(
						row.description || ''
					).replace(/[<>]/g, '')}`}</p>
				)
			},
			// number of emails
			email: {
				title: t('default.mail'),
				attr: MailAttributes.contact.attr.id,
				find: (filters: object) => MailResource.find(filters),
				fields: ['date', 'subject'],
				format: (row: Mail) => (
					<p>{`${row.date ? moment(row.date).toDate().toLocaleDateString() : ''}: ${(
						row.subject || ''
					).replace(/[<>]/g, '')}`}</p>
				)
			},
			// number of web site visits
			visit: {
				title: t('default.visit'),
				attr: VisitAttributes.contact.attr.id,
				find: (filters: object) => VisitResource.find(filters),
				fields: ['startDate'],
				format: (row: Visitor) => (
					<p>{row.startDate ? moment(row.startDate).toDate().toLocaleDateString() : ''}</p>
				)
			},
			// number of form submits
			formSubmits: {
				title: t('default.formSubmits'),
				attr: FormSubmitAttributes.contact.attr.id,
				find: (filters: object) => FormSubmitResource.find(filters),
				fields: ['regDate', 'form'],
				format: (row: FormSubmit) => (
					<p>{`${row.regDate ? moment(row.regDate).toDate().toLocaleDateString() : ''}: ${
						row.form && row.form.title ? row.form.title.replace(/[<>]/g, '') : ''
					}`}</p>
				)
			}
		};

		// Add udo to item map
		itemMap = UserDefinedObject.reduce((res, udo) => {
			res['udo' + udo.id] = {
				title: udo.name,
				attr: UserDefinedObjectAttribute.contact.attr.id,
				find: (filters: object) => UserDefinedObjectResource.find(udo.id, filters),
				fields: ['regDate'],
				format: (row: UserDefinedObject) => <p>{row.regDate ? row.regDate.toLocaleDateString() : ''}</p>
			};
			return res;
		}, itemMap);

		return itemMap;
	}, []);

	const getDataPromises = (onlyMeta?: boolean) => {
		return Object.values(itemMap).map(item => {
			const rb = new RequestBuilder();
			rb.addFilter(item.attr, ComparisonTypes.Equals, contact.id);
			if (onlyMeta) {
				// If only meta, return requests
				rb.limit = 0;
				return item.find(rb.build());
			} else {
				rb.fields = item.fields;
			}
			// Else fetch all
			return fetchAll(item, rb);
		});
	};

	useEffect(() => {
		setFetching(true);
		Promise.all(getDataPromises(true))
			.then(results => {
				const data = Object.keys(itemMap).map((key, index) => {
					return { title: itemMap[key].title, value: results[index].metadata.total };
				});
				setData(data);
				setFetching(false);
			})
			.catch(() => {
				setData([]);
				setFetching(false);
			});
	}, []);

	const openPrintable = () => {
		setFetchingPrintable(true);
		Promise.all(getDataPromises())
			.then(results => {
				const keys = Object.keys(itemMap);
				const entityData = results.reduce((outArr, entity, i) => {
					const item = itemMap[keys[i]];
					outArr.push({
						title: item.title,
						num: entity.data.length,
						rows: entity.data.map(item.format)
					});
					return outArr;
				}, []);

				const elem = React.createElement(ExportDataPrintFormat, { t, generalData, entityData });
				const iframe = document.createElement('iframe');
				printAreaRef.current!.appendChild(iframe);
				const iframedoc = iframe.contentDocument || iframe.contentWindow?.document;
				iframedoc!.body.innerHTML = window.renderToStaticMarkup(elem);
				iframe.contentWindow!.print();
				setTimeout(() => close(), 100);
			})
			.catch(err => logError(err));
	};

	return (
		<Modal className={classes.b()}>
			<ModalHeader title={t('contact.exportContactData')} onClose={close} />
			<ModalContent>
				<div>
					<Avatar user={contact} size={30} style={{ marginTop: '3px' }} />
					<div className="contact-wrapper">
						<h4>{contact.name}</h4>
					</div>
				</div>
				<div className="export-info-wrapper">
					<h3>{t('default.generalInfo')}</h3>
					{generalData.map(renderDataRow)}
					<h3>{t('default.data')}</h3>
					{fetching ? (
						<div className="spinner-wrapper">
							<b className="fa fa-spinner fa-spin" />
						</div>
					) : (
						data.map(renderDataRow)
					)}
				</div>
			</ModalContent>
			<ModalControls>
				<Button
					color="bright-blue"
					disabled={fetchingPrintable}
					onClick={openPrintable}
					className="main-action"
					shadow={'none'}
				>
					{fetchingPrintable ? (
						<span className="spinner-wrapper">
							<b className="fa fa-spinner fa-spin" />
						</span>
					) : (
						''
					)}
					{t('default.print')}
				</Button>
				<Button color="grey" type="link" onClick={() => close()}>
					{t('default.abort')}
				</Button>
			</ModalControls>
			<div ref={printAreaRef} id="export-data-printarea" />
		</Modal>
	);
};

export const openExportContactDataModal = asyncModalAdapter({
	featureFlag: 'EXPORT_CONTACT_DATA_REACT',
	upModalName: 'exportContactData',
	openModalName: 'ExportContactData'
});

export default ExportContactDataModal;
