import React, { ComponentType, useEffect, useState } from 'react';
import { Modal } from '@upsales/components';
import { type ModalProps } from 'App/components/Modals/Modals';
import { type ModalComponentProps, type ModalConfigName } from 'App/services/Modal/modalConfigs';
import { SizesLarge } from '@upsales/components/Utils/Sizes';
import { makeCancelable } from 'Helpers/promise';

type AsyncModalAdapterOpts = {
	upModalName: string;
	openModalName: ModalConfigName;
	featureFlag: string;
	rejectOnEmpty?: boolean;
	rejectOnEvent?: boolean;
	/** Resolve your own onClose, if your modal relies on some crazy behavior and the default isn't good enough */
	customOnCloseResolver?: (res: any, resolve: (value: unknown) => void, reject: (reason?: unknown) => void) => any;
	debug?: boolean;
};

(window as any).openReactModal = null;

export const asyncModalAdapter = <ConfName extends ModalConfigName>({
	upModalName,
	openModalName,
	featureFlag,
	rejectOnEmpty = true,
	rejectOnEvent = false,
	customOnCloseResolver,
	debug = false
}: AsyncModalAdapterOpts) => {
	return async (props?: ModalComponentProps<ConfName>) => {
		let useOpenModal = Tools.FeatureHelper.hasSoftDeployAccess(featureFlag);
		if ((window as any).openReactModal !== null) {
			useOpenModal = (window as any).openReactModal;
		}

		if (useOpenModal) {
			// Lazy load openModal to avoid circular dependencies in tests
			const openModal = (await import('App/services/Modal/Modal')).default;
			if (debug) {
				console.log(`Running openModal(${openModalName}), props:`, props);
			}
			return new Promise((resolve, reject) => {
				openModal(openModalName, {
					...(props ?? {}),
					onClose: (res: any) => {
						if (debug) {
							console.log(`openModal(${openModalName}) closing with:`, res);
						}
						if (res instanceof Error) {
							return reject(res);
						}
						if (res === undefined && rejectOnEmpty) {
							return reject();
						}
						if (res?._reactName && rejectOnEvent) {
							return reject(res);
						}
						if (customOnCloseResolver) {
							return customOnCloseResolver(res, resolve, reject);
						}
						if (debug) {
							console.log(`openModal(${openModalName}) closed with:`, res);
						}
						resolve(res);
					}
				});
			});
		}

		if (debug) {
			console.log(`Running $upModal(${upModalName}), props:`, props);
		}

		return Tools.$upModal.open(upModalName, props).then(res => {
			if (debug) {
				console.log(`$upModal(${upModalName}) resolved with:`, res);
			}
			return res;
		});
	};
};

export type SetupComponentProps<TReturn = unknown> = {
	reject: () => void;
	resolve: (value: TReturn) => void;
	reloadModalPosition: () => void;
};

/**
 * Helper function for compatibility with components that are built for using SetupComponent through $upModal.open, so that they can be used directly
 * with openModal instead.
 * Wraps the component in a Modal component, and passes the necessary props to the wrapped component.
 */
export const setupComponentCompatibility = <TProps extends {}, TReturn extends any = void>(
	Component: ComponentType<any>,
	{
		modalName,
		modalSize,
		modalParamsMapper,
		setupMetaInit,
		classNames,
		debug
	}: {
		/** Assign the modal name as a css class for styling */
		modalName?: string;
		modalSize?: SizesLarge;
		/** Use this if the modal setup function does some transformation to $modalParams before passing them to SetupComponent */
		modalParamsMapper?: (modalParams: TProps) => any;
		/** Use if your component uses a meta initializer. Copy the code from the angular meta function here. */
		setupMetaInit?: (params: TProps) => Promise<{ meta: Record<string, any> }>;
		classNames?: string[];
		debug?: boolean;
	} = {}
) => {
	return (props: ModalProps<TReturn> & TProps) => {
		const { className, close, modalId, ...passthrough } = props;
		const [metaProps, setMetaProps] = useState<Record<string, any> | null>(null);

		useEffect(() => {
			if (setupMetaInit) {
				if (debug) {
					console.log('Running meta init with props', passthrough);
				}
				const cancelablePromise = makeCancelable(setupMetaInit(passthrough as unknown as TProps));
				cancelablePromise.promise
					.then(res => {
						if (!res) {
							throw new Error('No props returned from meta init');
						}
						if (debug) {
							console.log('Received meta props', res);
						}
						setMetaProps(res);
					})
					.catch(e => {
						if (debug) {
							console.log('Failed to fetch meta props for modal', e);
						}
						setMetaProps({});
					});
				return () => {
					cancelablePromise.cancel();
				};
			}
		}, []);

		let finalProps = modalParamsMapper ? modalParamsMapper(passthrough as unknown as TProps) : passthrough;
		if (metaProps) {
			finalProps = { ...finalProps, meta: metaProps };
		}

		if (debug) {
			console.log('Rendered modal with props', finalProps);
		}

		return setupMetaInit && metaProps === null ? null : (
			<Modal
				className={
					(modalName ? `${modalName} ` : '') + (classNames ? `${classNames.join(' ')} ` : '') + className
				}
				size={modalSize}
			>
				<Component {...finalProps} reject={close} resolve={close} reloadModalPosition={() => {}} />
			</Modal>
		);
	};
};
