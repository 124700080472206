import {
	initialState,
	RESET,
	SET_TABLE_DATA,
	SET_VIEWS,
	SET_VIEW_STATS,
	SET_FILTERS,
	SET_SELECTED_VIEW,
	SET_OFFSET,
	SET_REPORT_STATS,
	SET_SELECTED_ID,
	SET_TOP_LIST,
	SET_SELECTED_ROLES_ACTIVITY,
	SET_SELECTED_ROLES_APPOINTMENT,
	SET_FOCUS_DATA,
	SET_MULTI_SELECT,
	SET_DAYS_TO_HIGHLIGHT
} from 'Store/reducers/TodoReducer';
import { batch } from 'react-redux';
import { removeItem, replaceItem } from 'App/babel/store/helpers/array';
import TodoResource from 'Resources/Todo';
import TodoViewResource from 'Resources/TodoView';
import ActivityResource from 'Resources/Activity';
import { OUTCOME_TYPES as PHONECALL_OUTCOME } from 'App/babel/enum/activityOutcome';
import { outcomeTypes as APPOINTMENT_OUTCOME } from 'Components/Modals/Appointment/AppointmentOutcomes/Helpers';
import _ from 'lodash';
import moment from 'moment';

import logError from 'App/babel/helpers/logError';
import { runAction } from './helpers/todoListActions';

import type { TodoState, ViewName, TodoListView, TodoMultiSelect, TodoReportStats } from 'Store/reducers/TodoReducer';
import type { MultiAction } from 'App/components/ListViewActions';
import { ACTIONS } from 'Store/actions/helpers/todoListActions';
import { AppThunk } from '..';
import Todo from 'App/resources/Model/Todo';

export const TABLE_LIMIT = 50;

export const setTableData =
	(
		tableData: TodoState['tableData'],
		tableLoading: TodoState['tableLoading'] = false,
		tableTotal?: TodoState['tableTotal']
	): AppThunk =>
	dispatch => {
		batch(() => {
			dispatch({
				type: SET_TABLE_DATA,
				tableLoading,
				tableData,
				tableTotal
			});
		});
	};
const _setOffset = (offset: TodoState['offset']) => ({ type: SET_OFFSET, offset });

const getTableFilters = (
	filters: TodoState['filters'],
	selectedView: TodoState['selectedView'],
	offset: number,
	limit = TABLE_LIMIT,
	focus = false
) => {
	const reqFilters: Partial<TodoState['filters']> & {
		view: TodoState['selectedView'];
		limit?: number;
		offset?: number;
		focus?: 1;
	} = {
		view: selectedView
	};
	if (filters.prio) {
		reqFilters.prio = true;
	}
	if (filters.searchString) {
		reqFilters.searchString = filters.searchString;
	}
	reqFilters.limit = limit;
	reqFilters.offset = offset;
	if (focus) {
		reqFilters.focus = 1;
	}
	return reqFilters;
};

export const setFocusData = (focus: TodoState['focus']) => ({ type: SET_FOCUS_DATA, focus });

const _getFocusData = (): AppThunk => async (dispatch, getState) => {
	const { filters, selectedView } = getState().Todo;
	// Fetch a list of focus data, for now we fetch 500, if any user (or QA) works thru that in one go - nice work
	const reqFilters = getTableFilters(filters, selectedView, 0, 500, true);
	const { data } = await TodoResource.find(reqFilters);

	// Set it and stop loading
	dispatch(setFocusData({ data, loading: false }));
};

const getFocusData = (): AppThunk => dispatch => {
	// Reset data and set loading
	dispatch(setFocusData({ data: [], loading: true }));
	dispatch(_getFocusData());
};

const _getTableData = (): AppThunk => async (dispatch, getState) => {
	const { filters, selectedView, offset } = getState().Todo;
	const reqFilters = getTableFilters(filters, selectedView, offset);
	const { data, metadata } = await TodoResource.find(reqFilters);
	dispatch(setTableData(data, false, metadata.total));
};

const getTableData = (): AppThunk => dispatch => {
	dispatch(setTableData([], true));
	batch(() => {
		dispatch(_getTableData());
		dispatch(getFocusData());
	});
};

export const isCustomView = (viewName: ViewName) => !!viewName?.startsWith('custom-');

export const getTableDataQuietly = (): AppThunk => dispatch => {
	batch(() => {
		dispatch(_getTableData());
		dispatch(_getFocusData());
	});
};

export const getViews = (): AppThunk<Promise<void>> => async dispatch => {
	const hasCallLists = Tools.FeatureHelper.hasSoftDeployAccess('CALL_LISTS');
	const { data }: { data: TodoListView[] } = await TodoViewResource.find(
		hasCallLists ? { includeCallLists: true } : undefined
	);

	const views = _.groupBy(data, 'group');
	const allLeadsIndex = views.leads?.findIndex(view => view.name === 'allLeads');
	if (views.leads && allLeadsIndex !== -1) {
		views.leads.splice(allLeadsIndex, 1);
	}
	const viewMap = data.reduce((res, view) => {
		if (view.group !== 'custom') {
			res[view.name] = view;
		}
		return res;
	}, {} as TodoState['viewMap']);
	dispatch({ type: SET_VIEWS, viewsLoading: false, views, viewMap });
};

export const resetMultiSelect = () => ({
	type: SET_MULTI_SELECT,
	multiselect: { ...initialState.multiselect }
});

const setMultiSelectAvailable = (available: TodoMultiSelect['available']) => ({
	type: SET_MULTI_SELECT,
	multiselect: { available }
});

export const setMultiSelectEnabled = (enabled: TodoMultiSelect['enabled']) => ({
	type: SET_MULTI_SELECT,
	multiselect: { allSelected: false, selectedIds: initialState.multiselect.selectedIds, enabled }
});

export const setMultiSelectAllSelected = (allSelected: TodoMultiSelect['allSelected']) => ({
	type: SET_MULTI_SELECT,
	multiselect: { allSelected, selectedIds: initialState.multiselect.selectedIds, enabled: allSelected }
});

export const setDaysToHighlight = (daysToHighlight: TodoState['daysToHighlight']) => ({
	type: SET_DAYS_TO_HIGHLIGHT,
	daysToHighlight
});

export const setMultiSelectAllSelectedPage = (): AppThunk => (dispatch, getState) => {
	const {
		tableData,
		multiselect: { selectedIds }
	} = getState().Todo;
	const pageSelectedIds = tableData.reduce((res, row) => {
		res[row.id] = true;
		return res;
	}, {} as TodoMultiSelect['selectedIds']);
	dispatch({
		type: SET_MULTI_SELECT,
		multiselect: {
			allSelected: false,
			selectedIds: {
				...selectedIds,
				...pageSelectedIds
			},
			enabled: true
		}
	});
};

export const toggleMultiselectedId =
	(id: keyof TodoMultiSelect['selectedIds']): AppThunk =>
	(dispatch, getState) => {
		const { selectedIds } = getState().Todo.multiselect;
		dispatch({
			type: SET_MULTI_SELECT,
			multiselect: { selectedIds: { ...selectedIds, [id]: !selectedIds[id] } }
		});
	};

export const getViewStats = (): AppThunk => async dispatch => {
	//Added a timeout due to a bugg where the total count was not updated correctly for the sidebar
	await new Promise(resolve => setTimeout(resolve, 500));

	const hasCallLists = Tools.FeatureHelper.hasSoftDeployAccess('CALL_LISTS');
	const { data } = await TodoViewResource.getStats(hasCallLists ? { includeCallLists: true } : undefined);
	dispatch({ type: SET_VIEW_STATS, viewStatsLoading: false, viewStats: data });
};

export const runMultiAction =
	(action: MultiAction): AppThunk =>
	async (dispatch, getState) => {
		const { filters, selectedView, offset, multiselect } = getState().Todo;
		const reqFilters = getTableFilters(filters, selectedView, offset);
		const selectedIds = Object.keys(multiselect.selectedIds).reduce<number[]>((r, k) => {
			if (multiselect.selectedIds[Number(k)]) {
				r.push(Number(k));
			}
			return r;
		}, []);
		// eslint-disable-next-line promise/catch-or-return
		runAction(action.id as ACTIONS, reqFilters, selectedIds, multiselect.total ?? 0).then(() => {
			dispatch(setMultiSelectEnabled(false)); // deselect and hide multiactions
			// If we selected less than 50 we delete/update them now
			if (multiselect?.total && multiselect.total <= TABLE_LIMIT) {
				// Wait for index then..
				setTimeout(function () {
					batch(() => {
						dispatch(getTableData());
						dispatch(getViewStats());
						dispatch(getViews());
					});
				}, 4000);
			}
		});
	};

export const canUseFocus = (viewMap: TodoState['viewMap'], viewName: ViewName) => {
	return (
		isCustomView(viewName) ||
		(viewMap[viewName]?.type ? ['all', 'coldcall', 'hot', 'phonecalls'].includes(viewMap[viewName]!.type) : false)
	);
};

export const getReportStats = (): AppThunk => async (dispatch, getState) => {
	const startOfDay = moment().utc().startOf('day').format();
	// DONE PHONE CALLS
	const phoneCallOutcomeRb = Tools.RequestBuilder();
	const phoneCallAgg = phoneCallOutcomeRb.aggregationBuilder();

	phoneCallAgg.addAggregation(phoneCallOutcomeRb.aggregationTypes.Terms, { field: 'outcomes.type' });
	phoneCallAgg.addFilter(
		{ field: 'outcomes.user.id' },
		phoneCallOutcomeRb.comparisonTypes.Equals,
		Tools.AppService.getSelf().id
	);
	phoneCallAgg.addFilter(
		{ field: 'outcomes.date' },
		phoneCallOutcomeRb.comparisonTypes.GreaterThanEquals,
		startOfDay
	);
	phoneCallAgg.addFilter({ field: 'outcomes.type' }, phoneCallOutcomeRb.comparisonTypes.Equals, [
		PHONECALL_OUTCOME.ANSWER,
		PHONECALL_OUTCOME.NO_ANSWER
	]);

	phoneCallAgg.done();

	// DONE APPOINTMENTS
	const appointmentOutcomeRb = Tools.RequestBuilder();
	const appointmentAgg = appointmentOutcomeRb.aggregationBuilder();

	appointmentAgg.addAggregation(appointmentOutcomeRb.aggregationTypes.Terms, { field: 'outcome' });
	appointmentAgg.addFilter(
		{ field: 'user.id' },
		appointmentOutcomeRb.comparisonTypes.Equals,
		Tools.AppService.getSelf().id
	);
	appointmentAgg.addFilter({ field: 'client.id' }, appointmentOutcomeRb.comparisonTypes.NotEquals, null);
	appointmentAgg.addFilter({ field: 'date' }, appointmentOutcomeRb.comparisonTypes.GreaterThanEquals, startOfDay);
	appointmentAgg.addFilter({ field: 'date' }, appointmentOutcomeRb.comparisonTypes.LessThanEquals, moment());
	appointmentAgg.done();

	// DONE TO-DO'S
	const doneTodoRb = Tools.RequestBuilder();
	doneTodoRb.addFilter({ field: 'closeDate' }, doneTodoRb.comparisonTypes.GreaterThanEquals, startOfDay);
	doneTodoRb.addFilter({ field: 'user.id' }, doneTodoRb.comparisonTypes.Equals, Tools.AppService.getSelf().id);
	doneTodoRb.addFilter(
		{ field: 'activityType.id' },
		doneTodoRb.comparisonTypes.Equals,
		Tools.AppService.getTodoTypes().TODO.id
	);

	// CREATED ORDERS
	const orderRb = Tools.RequestBuilder();
	orderRb.addFilter(Tools.Order.attr.closeDate, orderRb.comparisonTypes.GreaterThanEquals, startOfDay);
	orderRb.addFilter(Tools.Order.attr.user, orderRb.comparisonTypes.Equals, Tools.AppService.getSelf().id);
	orderRb.addFilter(Tools.Order.attr.probability, orderRb.comparisonTypes.Equals, 100);
	orderRb.limit = 0;

	// CREATED OPPORTUNITIES
	const opportunityRb = Tools.RequestBuilder();
	opportunityRb.addFilter({ field: 'regDate' }, opportunityRb.comparisonTypes.GreaterThanEquals, startOfDay);
	opportunityRb.addFilter(
		Tools.Opportunity.attr.user,
		opportunityRb.comparisonTypes.Equals,
		Tools.AppService.getSelf().id
	);
	opportunityRb.addFilter(Tools.Opportunity.attr.probability, opportunityRb.comparisonTypes.NotEquals, 100);
	opportunityRb.limit = 0;

	// CREATED APPOINTMENTS
	const appointmentRb = Tools.RequestBuilder();
	appointmentRb.addFilter(
		Tools.Appointment.attr.regDate,
		appointmentRb.comparisonTypes.GreaterThanEquals,
		startOfDay
	);
	appointmentRb.addFilter(
		Tools.Appointment.attr.regBy,
		appointmentRb.comparisonTypes.Equals,
		Tools.AppService.getSelf().id
	);
	appointmentRb.addFilter(Tools.Appointment.attr.client, appointmentRb.comparisonTypes.NotEquals, null);
	appointmentRb.limit = 0;

	const [
		{
			data: { group_by_outcomes_type: groupByOutcomesType }
		},
		{
			data: { group_by_outcome: groupByOutcome }
		},
		{
			metadata: { total: todosDone }
		},
		{
			metadata: { total: ordersCreated }
		},
		{
			metadata: { total: opportunitiesCreated }
		},
		{
			metadata: { total: appointmentsCreated }
		}
	] = await Promise.all([
		Tools.Report.customer(Tools.AppService.getCustomerId())
			.setType(Tools.Report.type.ACTIVITY)
			.find(phoneCallOutcomeRb.build()),
		Tools.Report.customer(Tools.AppService.getCustomerId())
			.setType(Tools.Report.type.APPOINTMENT)
			.find(appointmentOutcomeRb.build()),
		Tools.Activity.customer(Tools.AppService.getCustomerId()).find(doneTodoRb.build()),
		Tools.Order.customer(Tools.AppService.getCustomerId()).find(orderRb.build()),
		Tools.Opportunity.customer(Tools.AppService.getCustomerId()).find(opportunityRb.build()),
		Tools.Appointment.customer(Tools.AppService.getCustomerId()).find(appointmentRb.build())
	]);

	const phoneCalls = groupByOutcomesType?.buckets ?? [];
	const appointments = groupByOutcome?.buckets ?? [];

	const phoneCallsDone: TodoReportStats['phoneCallsDone'] = (
		phoneCalls as {
			key: keyof typeof PHONECALL_OUTCOME;
			// eslint-disable-next-line camelcase
			doc_count: number;
		}[]
	).reduce(
		(acc, cur) => {
			const { key, doc_count: docCount } = cur;

			return {
				...acc,
				[key]: docCount,
				total: docCount + (acc.total || 0)
			};
		},
		{
			[PHONECALL_OUTCOME.NO_ANSWER]: 0,
			[PHONECALL_OUTCOME.ANSWER]: 0,
			total: 0
		}
	);

	const appointmentsDone: TodoReportStats['appointmentsDone'] = (
		appointments as {
			key: keyof typeof APPOINTMENT_OUTCOME;
			// eslint-disable-next-line camelcase
			doc_count: number;
		}[]
	).reduce(
		(acc, cur) => {
			const { key, doc_count: docCount } = cur;

			return {
				...acc,
				[key]: docCount,
				total: docCount + (acc.total || 0)
			};
		},
		{
			[APPOINTMENT_OUTCOME.COMPLETED]: 0,
			[APPOINTMENT_OUTCOME.NOTCOMPLETED]: 0,
			[APPOINTMENT_OUTCOME.PLANNED]: 0,
			total: 0
		}
	);
	const { reportStats } = getState().Todo;
	dispatch({
		type: SET_REPORT_STATS,
		reportStats: {
			...reportStats,
			ordersCreated,
			opportunitiesCreated,
			appointmentsCreated,
			todosDone,
			appointmentsDone,
			phoneCallsDone
		},
		reportLoading: false
	});
};

export const getTopListData = (): AppThunk => async (dispatch, getState) => {
	const { selectedRolesActivity, selectedRolesAppointment } = getState().Todo;
	const startOfDay = moment().utc().startOf('day').format();
	const userIds = Tools.AppService.getActiveUsers().map(user => user.id);

	/** APPOINTMENT **/
	const appointmentRoles = selectedRolesAppointment ? selectedRolesAppointment.map(role => role.id) : [];
	const appointmentRb = Tools.RequestBuilder();
	appointmentRb.addFilter({ field: 'regDate' }, appointmentRb.comparisonTypes.GreaterThanEquals, startOfDay);
	appointmentRb.addFilter({ field: 'regBy.id' }, appointmentRb.comparisonTypes.Equals, userIds);
	appointmentRb.addFilter({ field: 'client.id' }, appointmentRb.comparisonTypes.NotEquals, null);

	if (appointmentRoles.length) {
		appointmentRb.addFilter({ field: 'regBy.role.id' }, appointmentRb.comparisonTypes.Equals, appointmentRoles);
	}

	const appointmentAggBuilder = appointmentRb.aggregationBuilder();
	appointmentAggBuilder.addAggregation(appointmentRb.aggregationTypes.Terms, 'regBy.id');
	appointmentAggBuilder.aggregationOrder('_count', false);
	appointmentAggBuilder.aggregationSize(4);
	appointmentAggBuilder.done();

	/** ACTIVITY **/
	const activityRoles = selectedRolesActivity ? selectedRolesActivity.map(role => role.id) : [];
	const outcomeUserRb = Tools.RequestBuilder();

	if (activityRoles.length) {
		outcomeUserRb.addFilter({ field: 'user.role.id' }, outcomeUserRb.comparisonTypes.Equals, activityRoles);
	}

	const outcomeUserAggBuilder = outcomeUserRb.aggregationBuilder();
	outcomeUserAggBuilder.addAggregation(outcomeUserRb.aggregationTypes.Terms, {
		field: 'outcomes.user.id'
	});
	outcomeUserAggBuilder.aggregationOrder('_count', false);
	outcomeUserAggBuilder.addFilter(
		{ field: 'outcomes.date' },
		outcomeUserRb.comparisonTypes.GreaterThanEquals,
		startOfDay
	);
	outcomeUserAggBuilder.addFilter({ field: 'outcomes.type' }, outcomeUserRb.comparisonTypes.Equals, [
		PHONECALL_OUTCOME.ANSWER,
		PHONECALL_OUTCOME.NO_ANSWER
	]);
	outcomeUserAggBuilder.aggregationSize(4);
	outcomeUserAggBuilder.done();

	const topListData: TodoReportStats['topListData'] = {};
	const customerID = Tools.AppService.getCustomerId();

	await Promise.all([
		// Activities toplist data
		Tools.Report.customer(customerID).setType(Tools.Report.type.ACTIVITY).find(outcomeUserRb.build()),
		// Appointments toplist data
		Tools.Report.customer(customerID).setType(Tools.Report.type.APPOINTMENT).find(appointmentRb.build())
	])
		.then(res => {
			topListData.activityList = res[0].data.group_by_outcomes_user_id?.buckets ?? [];
			topListData.appointmentList = res[1].data.group_by_regBy_id?.buckets ?? [];
		})
		.catch(error => logError(error, 'Error getting toplist data'));

	dispatch({ type: SET_TOP_LIST, topListData });
};

export const setSelectedRolesActivity = (selectedRolesActivity: TodoState['selectedRolesActivity']) => ({
	type: SET_SELECTED_ROLES_ACTIVITY,
	selectedRolesActivity
});

export const setSelectedRolesAppointment = (selectedRolesAppointment: TodoState['selectedRolesAppointment']) => ({
	type: SET_SELECTED_ROLES_APPOINTMENT,
	selectedRolesAppointment
});

let filterDebounce: ReturnType<typeof setTimeout>;
export const setFilters =
	(filters: Partial<TodoState['filters']>): AppThunk =>
	dispatch => {
		dispatch({ type: SET_FILTERS, filters });

		if (filterDebounce) {
			clearTimeout(filterDebounce);
		}

		filterDebounce = setTimeout(() => dispatch(getTableData()), 300);
	};

export const setOffset =
	(offset: TodoState['offset']): AppThunk =>
	dispatch => {
		dispatch(_setOffset(offset));
		dispatch(getTableData());
	};

export const setSelectedView =
	(selectedView: ViewName, noGet: boolean, daysToHighlight = ''): AppThunk =>
	(dispatch, getState) => {
		const { viewMap } = getState().Todo;

		if (viewMap[selectedView] || isCustomView(selectedView)) {
			batch(() => {
				dispatch(resetMultiSelect());
				dispatch(
					setMultiSelectAvailable(
						viewMap[selectedView]?.type !== 'all' ||
							['late', 'noDate'].includes(viewMap[selectedView]?.group ?? '')
					)
				);
				dispatch({ type: SET_SELECTED_VIEW, selectedView });
				dispatch(getViewStats());
				dispatch(_setOffset(0));
				if (!noGet) {
					dispatch(getTableData());
				} else {
					dispatch(getFocusData());
				}
				dispatch(setDaysToHighlight(daysToHighlight));
			});
		}
	};

export const removeItemIfExists =
	(todo: Todo): AppThunk =>
	(dispatch, getState) => {
		const { tableData, focus, tableTotal, multiselect } = getState().Todo;

		// Remove from data
		const index = tableData.findIndex(t => t.id === todo.id);
		if (index !== -1) {
			dispatch(setTableData(removeItem(tableData, index), false, tableTotal ? tableTotal - 1 : undefined));
		}
		// Remove from multiselect based on selectId
		if (multiselect.selectedIds[todo.id]) {
			dispatch(toggleMultiselectedId(todo.id));
		}
		// Remove from focus data
		const focusIndex = focus.data.findIndex(t => t.id === todo.id);
		if (focusIndex !== -1) {
			const data = removeItem(focus.data, focusIndex);
			dispatch(setFocusData({ data, loading: false }));
		}
	};

export const recheckFocusItem =
	(id: Todo['id']): AppThunk =>
	async (dispatch, getState) => {
		const { focus } = getState().Todo;
		if (focus.loading) {
			return;
		}

		// Check if an item exists in focus data. If not, try fetching it to see if it has been deleted or just disappeared from
		// the view for some other reason. If it has not been deleted, it is added back in so that it can be accessed again.
		const index = focus.data.findIndex(t => t.id === id);
		if (index === -1) {
			dispatch(setFocusData({ data: focus.data, loading: true }));
			try {
				await ActivityResource.get(id).then(res => {
					dispatch(setFocusData({ data: [res.data as unknown as Todo, ...focus.data], loading: false }));
				});
			} catch {
				dispatch(setFocusData({ data: focus.data, loading: false }));
			}
		}
	};

const timerMap: { [k: Todo['id']]: ReturnType<typeof setTimeout> } = {};
export const toggleTodoDone =
	(todo: Todo): AppThunk =>
	(dispatch, getState) => {
		const { tableData } = getState().Todo;
		const shouldRemove = !todo.closeDate;
		//update todo's closeDate in tableData
		const index = tableData.findIndex(t => t.id === todo.id);
		const updatedTodo: Todo = { ...todo, closeDate: shouldRemove ? new Date() : undefined };

		if (index !== -1) {
			dispatch(setTableData(replaceItem(tableData, index, updatedTodo)));
		}
		if (timerMap[todo.id]) {
			clearTimeout(timerMap[todo.id]);
			delete timerMap[todo.id];
		}
		if (!shouldRemove) {
			return;
		}

		timerMap[todo.id] = setTimeout(() => {
			delete timerMap[todo.id];
			// if closeDate set after timeout then remove & save
			const TodoState = getState().Todo;

			if (
				updatedTodo.closeDate ||
				(TodoState.viewMap[TodoState.selectedView]?.group === 'completedToday' && !updatedTodo.closeDate)
			) {
				dispatch(removeItemIfExists(todo));
				ActivityResource.save({ id: todo.id, closeDate: updatedTodo.closeDate });
			}
		}, 3000);
	};

export const toggleTodoPriority =
	(todo: Todo): AppThunk =>
	(dispatch, getState) => {
		const { tableData } = getState().Todo;
		const newTodo: Todo = { ...todo, priority: todo.priority === 3 ? 0 : 3 };
		// Update in data
		const index = tableData.findIndex(t => t.id === todo.id);
		if (index !== -1) {
			dispatch(setTableData(replaceItem(tableData, index, newTodo)));
		}

		// Update activity
		ActivityResource.save({ id: todo.id, priority: newTodo.priority });
	};

export const init =
	(initialView?: ViewName): AppThunk =>
	dispatch => {
		dispatch({ type: RESET });

		const viewsPromise = dispatch(getViews()).then(() => {
			if (initialView) {
				dispatch(setSelectedView(initialView, true));
			}
			return dispatch(getTableData());
		});

		return Promise.all([
			viewsPromise,
			dispatch(getViewStats()),
			dispatch(getReportStats()),
			dispatch(getTopListData())
		]);
	};

export const updateTableQuietlyIfExists =
	(data: Todo): AppThunk =>
	(dispatch, getState) => {
		const { tableData } = getState().Todo;
		const index = tableData.findIndex(
			t =>
				t.id === data.id ||
				t.parentActivityId === data.id ||
				t.parentAppointmentId === data.id ||
				t.opportunity?.id === data.id
		);
		if (index !== -1) {
			dispatch(getTableDataQuietly());
		}
	};

export const setSelectedId = (selectedId: TodoState['selectedId']) => ({
	type: SET_SELECTED_ID,
	selectedId
});
