import CompanyPortfolioAttributes from 'App/babel/attributes/CustomerPortfolioCompany';
import CustomerPortfolioCompanyResource from 'App/resources/CustomerPortfolioCompany';
import CustomerPortfolioCompany from 'App/resources/Model/CustomerPortfolioCompany';
import { DisplayValueType, getValueTypeFromSalesModelOption } from 'App/pages/CustomerPortfolio/CustomerPortfolio';
import { ListViewTableProvided } from '../ListView/ListViewTable/ListViewTable';
import { fetchCreditRisks, getOrgNumbersFromProspectingId } from './helper';
import { ListViewDefaultColumn } from '../ListView/ListViewRenderHelpers';
import useComponentDidUnmount from '../hooks/useComponentDidUnmount';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import { useTranslation } from 'Components/Helpers/translate';
import ListView, { ListViewPropsExternal } from '../ListView';
import { getMetadata } from 'Store/selectors/AppSelectors';
import { comparisonTypes } from 'Resources/RequestBuilder';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useMemo, useRef, useState } from 'react';
import CreditRisk from 'App/resources/Model/CreditRisk';
import ExpandedColumn from './Columns/ExpandedColumn';
import { useMetadata } from '../hooks/appHooks';
import { ModalProps } from '../Modals/Modals';
import RiskColumn from './Columns/RiskColumn';
import logError from 'Helpers/logError';
import {
	ClickableItem,
	Drawer,
	DrawerHeader,
	Flex,
	Help,
	Icon,
	TableColumn,
	TableRow,
	Text,
	Title,
	Tooltip
} from '@upsales/components';

import './RisksCompanyGroup.scss';

type ListViewProps = ListViewPropsExternal<CustomerPortfolioCompany>;

const RisksCompanyGroup = ({
	className,
	clientIds,
	close,
	valueType
}: { clientIds: number[]; valueType: DisplayValueType } & ModalProps) => {
	const classes = new BemClass('RisksCompanyGroup', className);
	const { t } = useTranslation();
	const defaultCurrency = getMetadata()!.defaultCurrency;
	const [total, setTotal] = useState(0);
	const [expandedRow, setExpandedRow] = useState<number[]>([]);
	const [creditRisks, setCreditRisks] = useState<CreditRisk[]>([]);
	const customerTableReq = useRef<CancelablePromise | null>(null);
	const creditRisksReq = useRef<CancelablePromise | null>(null);
	const metadata = useMetadata();

	const { SalesModelOption: salesModelOption = 'sales' } = metadata?.params || {};

	const displayValueType = useMemo(
		() => getValueTypeFromSalesModelOption(salesModelOption ?? 'sales'),
		[salesModelOption]
	);

	const handleExpandRow = (id: number) => {
		if (expandedRow.includes(id)) {
			setExpandedRow(expandedRow.filter(row => row !== id));
		} else {
			setExpandedRow([...expandedRow, id]);
		}
	};
	const fetchCreditRisksData = (clients: CustomerPortfolioCompany[]): Promise<CustomerPortfolioCompany[]> => {
		return new Promise((resolve, reject) => {
			if (clients.length === 0) {
				resolve([]);
				return;
			}
			const orgNumbers = getOrgNumbersFromProspectingId(clients);
			creditRisksReq.current = fetchCreditRisks(orgNumbers);
			creditRisksReq
				.current!.promise.then(response => {
					setCreditRisks(response.data);
					const filteredData = clients.filter(client =>
						response.data.some((risk: CreditRisk) => String(risk.orgNumber) === client.orgNo)
					);
					resolve(filteredData);
				})
				.catch(error => {
					logError(error);
					reject(error);
				});
		});
	};

	const getData: ListViewProps['getData'] = async (
		rb
	): Promise<{
		data: CustomerPortfolioCompany[];
		metadata: {
			total: number;
		};
	}> => {
		if (rb._sorting[0]?.attribute === 'prospectingCreditRating') {
			const ascending = rb._sorting[0].ascending;
			const missing = 'last';
			rb._sorting = [
				{ attribute: 'prospectingCreditRating', ascending, missing },
				{ attribute: 'prospectingNumericCreditRating', ascending: !ascending, missing }
			];
		}

		rb.addFilter({ field: 'client.id' }, comparisonTypes.Equals, clientIds);
		rb.addExtraParam('valueType', displayValueType);
		customerTableReq.current = makeCancelable(CustomerPortfolioCompanyResource.find(rb.build()));

		const result = await customerTableReq.current.promise;

		const currencyFields = [
			'salesLast12Months',
			'cmLast12Months',
			'salesTrend12Months',
			'cmTrend12Months',
			'arrLast12Months',
			'arr',
			'mrrLast12Months',
			'mrr'
		] as const;
		for (const company of result.data) {
			company.currency = defaultCurrency.iso;
			for (const field of currencyFields) {
				const value = company[field];
				if (typeof value === 'number') {
					company[field] = Math.round(value * defaultCurrency.rate);
				}
			}
		}
		const filteredData = await fetchCreditRisksData(result.data);
		setTotal(filteredData.length);
		return { data: filteredData, metadata: { total: filteredData.length } };
	};

	useComponentDidUnmount(() => {
		customerTableReq.current?.cancel();
		creditRisksReq.current?.cancel();
	});

	const columns = ['name', 'risks', ''];

	if (valueType === 'value') {
		columns.splice(1, 0, 'salesLast12Months');
	} else if (valueType === 'annualValue') {
		columns.splice(1, 0, 'arr');
	} else if (valueType === 'monthlyValue') {
		columns.splice(1, 0, 'mrr');
	} else if (valueType === 'contributionMargin') {
		columns.splice(1, 0, 'cmLast12Months');
	}

	const customActivityAttributes = useMemo(() => {
		const attributesCopy = { ...CompanyPortfolioAttributes };
		attributesCopy.name = {
			...attributesCopy.name,
			title: t('default.account')
		};
		return attributesCopy;
	}, []);

	const renderTableRow = (
		client: CustomerPortfolioCompany,
		{ columns, attributes }: ListViewTableProvided<CustomerPortfolioCompany>
	) => {
		const columnElements = columns.map(column => {
			switch (column) {
				case 'risks': {
					return <RiskColumn key={column + client.id} client={client} risks={creditRisks} />;
				}
				case '': {
					return (
						<TableColumn key={column + client.id}>
							<Flex justifyContent="flex-end">
								<ClickableItem
									borderRadius
									icon={expandedRow.includes(client.id) ? 'chevron-up' : 'chevron-down'}
									onClick={() => handleExpandRow(client.id)}
								/>
							</Flex>
						</TableColumn>
					);
				}
				default: {
					return (
						<ListViewDefaultColumn<CustomerPortfolioCompany>
							key={column + client.id}
							item={client}
							attributes={attributes}
							column={column}
						/>
					);
				}
			}
		});
		// Need to return an array since it needs a key on each table row, otherwise it will complain.
		// Could not wrap it in a fragment since you can't add a key there and could not wrap it in a div since it is not valid in a table.
		return [
			<TableRow key={client.id} onClick={() => handleExpandRow(client.id)}>
				{columnElements}
			</TableRow>,
			expandedRow.includes(client.id) ? (
				<TableRow key={client.id + 'expandedColumn'}>
					<ExpandedColumn client={client} risks={creditRisks} classes={classes} />
				</TableRow>
			) : null
		];
	};

	return (
		<Drawer className={classes.b()}>
			<DrawerHeader onHide={close}>
				<Flex
					alignItems="center"
					justifyContent="space-between"
					className={classes.elem('header').elem('controls').b()}
					space="pll prl"
				>
					<Flex direction="column">
						<Title>{t('companyGroup.startPage.risksCompanyGroup.title')}</Title>
						<Text size="sm" color="grey-11">
							{`${total} ${
								total === 1 ? t('default.account').toLowerCase() : t('default.accounts').toLowerCase()
							}`}
						</Text>
					</Flex>
					<Tooltip title={t('companyGroup.startPage.risksCompanyGroup.riskInfo')}>
						<Help articleId={1466} sidebar>
							<Icon name="question-circle" />
						</Help>
					</Tooltip>
				</Flex>
			</DrawerHeader>

			<ListView<CustomerPortfolioCompany>
				key={clientIds.join(',')}
				className={classes.elem('company-list-widget').b()}
				getData={getData}
				columns={columns}
				isFullscreen
				// @ts-ignore - Need renderTableRow to return an array which ts doesn't like, see comment above
				renderTableRow={renderTableRow}
				attributes={customActivityAttributes}
				renderToolsColumn={false}
				skipSortById
			/>
		</Drawer>
	);
};

export default RisksCompanyGroup;
