import React from 'react';
import PropTypes from 'prop-types';
import AccountRow from './AccountRow';
import { Flex, TableHeader, Title } from '@upsales/components';
import _ from 'lodash';
import ClientResource from 'App/resources/Client';
import ClientAttributes from 'App/babel/attributes/Client';
import { makeCancelable } from '../../../helpers/promise';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';

import './GroupTree.scss';
import logError from 'Helpers/logError';

export default class GroupTree extends React.Component {
	constructor(props) {
		super(props);
		const t = Tools.$translate;

		this.lang = {
			unknownAccounts: t('account.unknownAccounts'),
			groupTreeError: t('account.groupTreeError')
		};

		this.state = {
			tree: props.tree || null,
			maxDepth: 0,
			unknowns: props.unknowns || null,
			unknownOffset: 0,
			error: false,
			rowState: {}
		};

		this.setUnknownPage = this.setUnknownPage.bind(this);
		this.toggleSubaccounts = this.toggleSubaccounts.bind(this);
		this.openSubaccounts = this.openSubaccounts.bind(this);
		this.closeSubaccounts = this.closeSubaccounts.bind(this);
		this.hasSubaccountsV2 =
			Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS') &&
			Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS_V2');
		this.hasGroupBonanza = Tools.FeatureHelper.hasSoftDeployAccess('GROUP_BONANZA');
	}

	componentDidMount() {
		const { accounts, rootDuns, config } = this.props;

		try {
			if (this.state.tree) {
				const maxDepth = this.getMaxDepthOfTree(this.state.tree, 0);
				return this.setState({ maxDepth });
			}

			const keyedAccounts = _.groupBy(accounts, config.parentField);
			const tree = this.buildTree(keyedAccounts);
			const maxDepth = this.getMaxDepthOfTree(tree, 0);
			const unknowns = _.filter(keyedAccounts['null'], account => {
				return parseInt(account[config.groupIdField]) !== rootDuns;
			});

			this.setState({
				tree: tree,
				maxDepth: maxDepth,
				unknowns: unknowns
			});
		} catch (e) {
			this.setState({ error: true });
		}
	}

	componentWillUnmount() {
		if (this.cancelablePromise) {
			this.cancelablePromise.cancel();
		}
	}

	componentDidUpdate(prevProps) {
		const isChanged = !_.isEqual(this.props.tree, prevProps.tree);

		if (isChanged) {
			this.setState({ tree: this.props.tree });
		}
	}

	toggleSubaccounts(companyId) {
		const rowState = this.state.rowState[companyId] || { status: 'CLOSED', subaccounts: [] };
		if (rowState.status === 'CLOSED') {
			this.openSubaccounts(companyId);
		} else {
			this.closeSubaccounts(companyId);
		}
	}

	openSubaccounts(companyId) {
		const rowState = this.state.rowState[companyId] || { status: 'CLOSED', subaccounts: [] };
		if (rowState.status === 'CLOSED' && !rowState.subaccounts.length) {
			const rb = new RequestBuilder();
			rb.addFilter(ClientAttributes.operationalAccount.attr.id, comparisonTypes.Equals, companyId);
			const promise = ClientResource.find(rb.build());

			this.cancelablePromise = makeCancelable(promise);
			this.cancelablePromise.promise
				.then(res => {
					rowState.status = 'OPEN';
					rowState.subaccounts = res.data.map(subaccount => ({
						...subaccount,
						isSubaccount: true
					}));
					this.setState({ rowState: { ...this.state.rowState, [companyId]: rowState } });
				})
				.catch(error => {
					if (!error.isCanceled) {
						logError(error, 'could not open subaccounts');
					}
				});
		} else {
			rowState.status = 'OPEN';
			this.setState({ rowState: { ...this.state.rowState, [companyId]: rowState } });
		}
	}

	closeSubaccounts(companyId) {
		const rowState = this.state.rowState[companyId] || { status: 'CLOSED', subaccounts: [] };
		rowState.status = 'CLOSED';
		this.setState({ rowState: { ...this.state.rowState, [companyId]: rowState } });
	}

	buildTree(keyedAccounts, account) {
		const { accounts, rootDuns, config, dataSourceId } = this.props;

		if (!keyedAccounts) {
			keyedAccounts = _.groupBy(accounts, config.parentField);
		}

		if (!account) {
			account = _.find(accounts, account => parseInt(account[config.groupIdField]) === parseInt(rootDuns));
		}

		account.children = _.map(keyedAccounts[account[config.groupIdField]], account =>
			this.buildTree(keyedAccounts, account)
		);
		account.type = dataSourceId;

		return account;
	}

	getMaxDepthOfTree(node, depth) {
		let maxDepth = depth;

		_.forEach(node.children, child => {
			maxDepth = Math.max(maxDepth, this.getMaxDepthOfTree(child, depth + 1));
		});

		if (this.hasSubaccountsV2) {
			return maxDepth + 1;
		}

		return maxDepth;
	}

	drawTree(level, tree, isLastChild, holes) {
		const {
			actions,
			type,
			idField,
			dataSourceId,
			addingAccount,
			groupAccount,
			selected,
			multiselect,
			viewOnly,
			showPipelineColumn
		} = this.props;

		const showPipelineColumnWithBiggerWidth = showPipelineColumn && window.innerWidth > 1500;

		if (!level) {
			level = 0;
		}

		if (!tree) {
			tree = this.state.tree;
		}

		if (!holes) {
			holes = [];
		} else {
			holes = _.cloneDeep(holes);
		}

		let subaccountRows = null;
		const treeIdReal = tree.id;
		const rowState = this.state.rowState[treeIdReal] || { status: 'CLOSED', subaccounts: [] };

		if (rowState.status === 'OPEN' && rowState.subaccounts.length && !tree.hidden) {
			subaccountRows = rowState.subaccounts.map((subaccount, index) => {
				const isLast = index === rowState.subaccounts.length - 1;
				return this.drawTree(level + 1, subaccount, isLast, isLastChild ? holes.concat(level) : holes);
			});
		}
		let childRows = null;
		if (tree.children && tree.children.length) {
			childRows = _.map(tree.children, (child, index) => {
				const isLast = index === tree.children.length - 1;
				return this.drawTree(level + 1, child, isLast, isLastChild ? holes.concat(level) : holes);
			});
		}

		const existing = tree.existing;
		const fields = [];
		for (let j = 0; j <= this.state.maxDepth; j++) {
			const isHoleLevel = holes.indexOf(j + 1) !== -1;
			const nameCoulmn = dataSourceId === 'prospecting' ? 'nameProspecting' : 'nameSoliditet';

			if (j === level) {
				fields.push({
					field: 'name',
					type: nameCoulmn,
					skipAdditionalInfo: true,
					header: 'default.account',
					width: 100 - (this.state.maxDepth * 4 + 10) + '%',
					className: 'overflow-visible'
				});
			} else if (j === level - 1) {
				fields.push({
					type: isLastChild ? 'parentLineLast' : 'parentLine',
					width: '4%'
				});
			} else if (j <= level - 2 && !isHoleLevel) {
				fields.push({
					type: 'parentLineStraight',
					width: '4%'
				});
			} else {
				fields.push({
					type: 'padding',
					width: '4%'
				});
			}
		}

		const external = tree.matchInUpsales?.isExternal || false;
		const isSubaccount = tree.isSubaccount;

		if ((existing && !external) || isSubaccount) {
			if (!this.hasGroupBonanza && !this.hasSubaccountsV2) {
				fields.push({
					type: 'history',
					width: '20%'
				});
			}
			fields.push({
				field: 'accountManager',
				type: 'accountManager',
				className: 'accountManager',
				iconOnly: true,
				existing: existing,
				width: '10%'
			});

			fields.push({
				type: 'currency',
				path: 'growth.salesLast12Months',
				width: '10%'
			});
			if (showPipelineColumnWithBiggerWidth) {
				fields.push({
					type: 'currency',
					path: 'totalPipeline',
					width: '10%'
				});
			}
		} else {
			fields.push({}, {});
			if (showPipelineColumnWithBiggerWidth) {
				fields.push({});
			}
			if (!this.hasGroupBonanza && !this.hasSubaccountsV2) {
				fields.push({});
			}
		}

		if (!viewOnly && !isSubaccount) {
			if (
				tree.existing &&
				Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.JOURNEY_STATUS) &&
				!external
			) {
				fields.push({
					type: 'journey',
					width: '35%'
				});
			} else if (dataSourceId !== 'prospecting') {
				fields.push({
					type: 'add',
					width: '35%'
				});
			} else if (tree.prospectingId) {
				fields.push({
					type: 'add',
					width: '35%'
				});
			} else {
				fields.push({});
			}
		} else {
			fields.push({});
		}

		if (addingAccount && parseInt(addingAccount.externalId) === parseInt(tree[idField])) {
			tree = Object.assign({}, tree, addingAccount);
		}

		const row = tree.hidden ? null : (
			<AccountRow
				key={tree.id || tree[idField]}
				account={tree}
				fields={fields}
				actions={actions}
				groupAccount={groupAccount}
				type={type}
				idField={idField}
				dataSourceId={dataSourceId}
				multiselect={multiselect}
				selected={selected.indexOf(parseInt(tree[idField])) !== -1}
				onToggleSubaccounts={() => this.toggleSubaccounts(tree.id)}
				showSubaccounts={rowState.status === 'OPEN'}
				isSubaccount={rowState.subaccounts.length}
			/>
		);

		let content = [row, subaccountRows, childRows];
		const t = Tools.$translate;
		const statusColumnWidth = actions.beginMerge ? '250px' : '150px';
		const numberOfColumns = showPipelineColumnWithBiggerWidth ? 6 : 5;
		const numberOfColumnsSubaccount = showPipelineColumnWithBiggerWidth ? 5 : 4;

		if (level === 0) {
			const listHeaders =
				this.hasGroupBonanza || this.hasSubaccountsV2 ? (
					<TableHeader className="no-height">
						{_.times(this.state.maxDepth + numberOfColumnsSubaccount, index => {
							let width = '4%';
							let content = '';

							if (index === 0) {
								content = t('default.account');
							}
							if (index === this.state.maxDepth + 1) {
								width = '180px';
								content = t('default.accountManager');
							} else if (index === this.state.maxDepth + 2) {
								width = '180px';
								content = t('default.salesLast12months');
							} else if (showPipelineColumnWithBiggerWidth && index === this.state.maxDepth + 3) {
								width = '180px';
								content = t('default.pipeline');
							} else if (index === this.state.maxDepth + (showPipelineColumnWithBiggerWidth ? 4 : 3)) {
								width = '220px';
								content = (
									<Flex justifyContent="flex-end" alignItems="center">
										{t('default.journeyStep')}
									</Flex>
								);
							} else if (index === this.state.maxDepth) {
								width = 100 - (this.state.maxDepth * 4 + 40) + '%';
							}
							return (
								<th key={index} width={width}>
									{content}
								</th>
							);
						})}
					</TableHeader>
				) : (
					<TableHeader className="no-height">
						{_.times(this.state.maxDepth + numberOfColumns, index => {
							let width = '4%';
							let content = '';

							if (index === 0) {
								content = t('default.account');
							}

							if (index === this.state.maxDepth + 1) {
								width = '25%';
								content = t('default.history');
							} else if (index === this.state.maxDepth + 2) {
								width = '15%';
								content = t('default.accountManager');
							} else if (index === this.state.maxDepth + 3) {
								width = '15%';
								content = t('default.salesLast12months');
							} else if (showPipelineColumnWithBiggerWidth && index === this.state.maxDepth + 4) {
								width = '10%';
								content = t('default.pipeline');
							} else if (index === this.state.maxDepth + (showPipelineColumnWithBiggerWidth ? 5 : 4)) {
								width = statusColumnWidth;
								content = (
									<Flex justifyContent="flex-end" alignItems="center">
										{t('default.status')}
									</Flex>
								);
							} else if (index === this.state.maxDepth) {
								width = 100 - (this.state.maxDepth * 4 + 40) + '%';
							}
							return (
								<th key={index} width={width}>
									{content}
								</th>
							);
						})}
					</TableHeader>
				);

			content = (
				<table className="result-table group-tree found-tree">
					{listHeaders}
					<tbody>{content}</tbody>
				</table>
			);
		}

		return content;
	}

	setUnknownPage(page) {
		this.setState({
			unknownOffset: page * 50
		});
	}

	render() {
		const {
			actions,
			type,
			idField,
			config,
			dataSourceId,
			addingAccount,
			className,
			multiselect,
			selected,
			viewOnly,
			showPipelineColumn
		} = this.props;

		let tree = null;
		let paginator = null;
		let unknowns = null;

		const showPipelineColumnWithBiggerWidth = showPipelineColumn && window.innerWidth > 1500;

		if (this.state.error) {
			return (
				<Title center={true} color="red" className="GroupTree__error">
					{this.lang.groupTreeError}
					<ReactTemplates.elevio style={{ marginLeft: 5 }} articleId={1024} />
				</Title>
			);
		}

		if (this.state.tree) {
			tree = this.drawTree();
		}

		if (this.state.unknowns && !this.hasSubaccountsV2) {
			const rows = [];
			for (let i = 0; i < 50; i++) {
				const fields = [
					{
						field: 'name',
						width: '50%',
						skipAdditionalInfo: true
					}
				];

				const account = this.state.unknowns[this.state.unknownOffset + i];
				if (account) {
					account.type = account.existing
						? 'existing'
						: dataSourceId === 'prospecting'
						? 'prospecting'
						: 'soliditet';
					const nameCoulmn = dataSourceId === 'prospecting' ? 'nameProspecting' : 'nameSoliditet';
					fields[0].type = nameCoulmn;

					if (account.existing) {
						if (!this.hasGroupBonanza && !this.hasSubaccountsV2) {
							fields.push({
								type: 'history',
								width: '20%'
							});
						}
						fields.push({
							field: 'accountManager',
							type: 'accountManager',
							className: 'accountManager',
							iconOnly: true,
							existing: account.existing,
							width: '10%'
						});
						fields.push({
							type: 'currency',
							path: 'growth.salesLast12Months',
							width: '10%'
						});
						if (showPipelineColumnWithBiggerWidth) {
							fields.push({
								type: 'currency',
								path: 'totalPipeline',
								width: '10%'
							});
						}
					} else {
						fields.push({}, {});
						if (showPipelineColumnWithBiggerWidth) {
							fields.push({});
						}
						if (!this.hasGroupBonanza && !this.hasSubaccountsV2) {
							fields.push({});
						}
					}

					if (!viewOnly) {
						if (
							account.existing &&
							Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.JOURNEY_STATUS)
						) {
							fields.push({
								type: 'journey',
								width: '25%'
							});
						} else {
							fields.push({
								type: 'add',
								width: '25%'
							});
						}
					} else {
						fields.push({});
					}

					rows.push(
						<AccountRow
							key={this.state.unknownOffset + i}
							account={account}
							addingAccount={addingAccount}
							actions={actions}
							fields={fields}
							type={type}
							idField={idField}
							dataSourceId={dataSourceId}
							multiselect={multiselect}
							selected={selected.indexOf(parseInt(account[config.groupIdField])) !== -1}
						/>
					);
				}
			}

			if (this.state.unknowns.length > 50) {
				const offset = Math.abs(this.state.unknownOffset / 50);
				paginator = (
					<ReactTemplates.TOOLS.Paginator
						currentPage={offset > 0 ? offset : 1}
						max={Math.max(Math.floor(this.state.unknowns.length / 50), 1)}
						total={this.state.unknowns.length}
						onChange={this.setUnknownPage}
					/>
				);
			}

			const statusColumnWidth = actions.beginMerge ? '220px' : '100px';

			const shouldShowOldView = !this.hasGroupBonanza && !this.hasSubaccountsV2;

			if (this.state.unknowns.length) {
				unknowns = (
					<table
						className={`result-table group-tree ${shouldShowOldView ? 'unknown-tree' : 'unknown-tree-new'}`}
					>
						<thead>
							<tr>
								<th width="25%">{this.lang.unknownAccounts}</th>
								{shouldShowOldView ? <th width="20%" /> : null}
								<th width="10%" />
								<th width="10%" />
								{showPipelineColumnWithBiggerWidth ? <th width="10%" /> : null}
								<th width={statusColumnWidth} />
							</tr>
						</thead>
						<tbody>{rows}</tbody>
					</table>
				);
			}
		}

		const outerDivProps = {};
		if (className) {
			outerDivProps.className = className;
		}

		const accountCardClassName = `accounts-card accounts-card-${dataSourceId}`;

		return (
			<div {...outerDivProps}>
				<div className={accountCardClassName}>{tree}</div>
				<div className={accountCardClassName}>
					{unknowns}
					{paginator}
				</div>
			</div>
		);
	}
}
GroupTree.defaultProps = {
	selected: [],
	config: {
		groupIdField: 'dunsNo',
		parentField: 'parentDuns',
		ultimateParentField: 'rootParentDuns'
	}
};

GroupTree.propTypes = {
	className: PropTypes.string,
	addingAccount: PropTypes.object,
	actions: PropTypes.object,
	groupAccount: PropTypes.object,
	type: PropTypes.string,
	accounts: PropTypes.arrayOf(PropTypes.object),
	rootDuns: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	idField: PropTypes.string,
	dataSourceId: PropTypes.string,
	multiselect: PropTypes.bool,
	selected: PropTypes.arrayOf(PropTypes.number),
	config: PropTypes.object,
	tree: PropTypes.object,
	unknowns: PropTypes.array,
	viewOnly: PropTypes.bool,
	showPipelineColumn: PropTypes.bool
};
