import Resource from './Resource';
import parseStandardMailTemplate from './parsers/standardMailTemplate';
import Attributes from 'Attributes/MailTemplateAttributes';

class StandardMailTemplate extends Resource {
	constructor() {
		super('standardMailTemplate', Attributes);

		this.parse = parseStandardMailTemplate;
	}
}

const resource = new StandardMailTemplate();

export default resource;
