import React, { Component } from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import logError from 'App/babel/helpers/logError';
import { makeCancelable } from 'App/babel/helpers/promise';
import { Loader, Icon } from '@upsales/components';
import { SlideFade } from 'App/components/animations';
import YourYearWithUpsalesResource from 'App/babel/resources/YourYearWithUpsales';
import colorMappings from '@upsales/components/Utils/colorMappings';
import { globalTracker } from 'App/babel/helpers/Tracker';

import FirstScreen from './FirstScreen';
import SecondScreen from './SecondScreen';
import ThirdScreen from './ThirdScreen';
import TopList from './TopList';
import LetsCrunchSomeNumbers from './LetsCrunchSomeNumbers';
import ThankYou from './ThankYou';
import { asyncModalAdapter, setupComponentCompatibility } from 'App/helpers/angularPortingHelpers';

import './YourYearWithUpsales.scss';

type Props = {
	reject: () => void;
};

enum Screens {
	First = 1,
	Second = 2,
	TopList = 3,
	ThirdScreen = 4,
	LetsCrunchSomeNumbers = 5,
	ThankYou = 6
}

type State = {
	currentScreen: Screens;
	loading: boolean;
	data?: {
		thisYear: object;
		bestProduct: { name: string };
		graph: Array<{ value: number; month: number }>;
		bestMonth: string;
		bestDeal: string;
		mostSoldProduct: string;
		quickestDeal: number;
		calls: number;
		meetings: number;
		pipeline: { value: number; currency: string };
		numberOfClients: number;
	};
};

const BgColorMap = {
	[Screens.First]: colorMappings.get('super-light-green'),
	[Screens.Second]: '#FCF0C0',
	[Screens.TopList]: '#A3C6F5',
	[Screens.ThirdScreen]: '#FCF0C0',
	[Screens.LetsCrunchSomeNumbers]: '#FDDCCC',
	[Screens.ThankYou]: colorMappings.get('green')
};

type ScreenProps = {
	screen: Screens;
	currentScreen: Screens;
	classes: bemClass;
};

const Screen: React.FC<ScreenProps> = ({ children, screen, currentScreen, classes }) => {
	return (
		<SlideFade
			direction={currentScreen > screen ? 'left' : 'right'}
			visible={currentScreen === screen}
			delayInMs={700}
			delayOutInMs={0}
			bounce
		>
			<div className={classes.elem('screen').b()}>{children}</div>
		</SlideFade>
	);
};

class YourYearWithUpsales extends Component<Props, State> {
	fetchPromise: { promise: Promise<any>; cancel: () => void } | undefined;
	constructor(p: Props) {
		super(p);

		this.state = { currentScreen: Screens.First, loading: true };
	}

	componentWillUnmount() {
		if (this.fetchPromise) {
			this.fetchPromise.cancel();
		}
	}

	componentDidMount() {
		globalTracker.track('Your year with upsales');
		this.fetchPromise = makeCancelable(YourYearWithUpsalesResource.get());
		this.fetchPromise.promise
			.then(({ data }) => this.setState({ loading: false, data }))
			.catch(e => logError(e, 'Failed to fetch from YourYearWithUpsalesResource'));
	}

	renderAllScreens(className: bemClass) {
		const { reject } = this.props;
		const { currentScreen, data } = this.state;
		if (data) {
			return (
				<div>
					<Screen classes={className} currentScreen={currentScreen} screen={Screens.First}>
						<FirstScreen
							key={'FirstScreen'}
							active={currentScreen === Screens.First}
							next={() => {
								this.setState({ currentScreen: Screens.Second });
							}}
						/>
					</Screen>
					<Screen classes={className} currentScreen={currentScreen} screen={Screens.Second}>
						<SecondScreen
							key={'SecondScreen'}
							soldFor={data.thisYear as any}
							next={() => this.setState({ currentScreen: Screens.TopList })}
							visible={currentScreen === Screens.Second}
						/>
					</Screen>
					<Screen classes={className} currentScreen={currentScreen} screen={Screens.TopList}>
						<TopList
							key={'TopList'}
							companiesSoldTo={data.numberOfClients}
							createdPipeline={data.pipeline}
							meetings={data.meetings}
							calls={data.calls}
							next={() => this.setState({ currentScreen: Screens.ThirdScreen })}
							visible={currentScreen === Screens.TopList}
						/>
					</Screen>
					<Screen classes={className} currentScreen={currentScreen} screen={Screens.ThirdScreen}>
						<ThirdScreen
							key={'ThirdScreen'}
							next={() => this.setState({ currentScreen: Screens.LetsCrunchSomeNumbers })}
							data={data.graph}
							visible={currentScreen === Screens.ThirdScreen}
						/>
					</Screen>
					<Screen classes={className} currentScreen={currentScreen} screen={Screens.LetsCrunchSomeNumbers}>
						<LetsCrunchSomeNumbers
							key={'LetsCrunchSomeNumbers'}
							next={() => this.setState({ currentScreen: Screens.ThankYou })}
							bestDeal={data.bestDeal}
							bestMonth={data.bestMonth}
							mostSoldProduct={data.mostSoldProduct}
							quickestDeal={data.quickestDeal}
						/>
					</Screen>
					<Screen classes={className} currentScreen={currentScreen} screen={Screens.ThankYou}>
						<ThankYou key={'ThankYou'} next={() => reject()} />
					</Screen>
				</div>
			);
		}
		return null;
	}
	render() {
		const { reject } = this.props;
		const { loading, currentScreen } = this.state;

		const classNames = new bemClass('YourYearWithUpsales');
		return (
			<div className={classNames.b()} style={{ backgroundColor: BgColorMap[currentScreen] }}>
				<Icon className={classNames.elem('close').b()} onClick={reject} name="times" />
				{loading ? <Loader /> : this.renderAllScreens(classNames)}
			</div>
		);
	}
}

export const YourYearWithUpsalesModal = setupComponentCompatibility(YourYearWithUpsales, {
	modalName: 'FullscreenModal'
});

export const openYourYearWithUpsalesModal = asyncModalAdapter({
	featureFlag: 'YOUR_YEAR_WITH_UPSALES_REACT',
	upModalName: 'yourYearWithUpsales',
	openModalName: 'YourYearWithUpsalesModal'
});

export default YourYearWithUpsales;
