import { Block, ButtonSelect, Flex, Input, Text, Loader, Row, DropDownMenu } from '@upsales/components';
import { fetchClientsFromCustomFields, setCustomClientFieldsFilters } from 'App/helpers/accountsHelper';
import { CancelablePromise, makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import { DefaultButton, PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import TicketDefaultEmailContactResource from 'App/resources/TicketDefaultEmailContact';
import { ExternalContact, MultiMatchClientContact } from 'App/resources/Model/Ticket';
import { useEditTicketContext } from 'App/components/EditTicket/Context/Context';
import { getMainRecipient } from 'App/components/EditTicket/Context/Helpers';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import { FormObserverOnFieldChange } from 'App/components/FormObserver';
import Client, { ClientAddress } from 'App/resources/Model/Client';
import { useTranslation } from 'Components/Helpers/translate';
import React, { useEffect, useRef, useState } from 'react';
import { useSoftDeployAccess } from 'App/components/hooks';
import BemClass from '@upsales/components/Utils/bemClass';
import ContactResource from 'App/resources/Contact';
import ClientResource from 'App/resources/Client';
import logError from 'App/babel/helpers/logError';
import Contact from 'App/resources/Model/Contact';
import './SuggestedClientContact.scss';

export enum SuggestedContent {
	Contact = 'contact',
	Client = 'client',
	MoveContactToClient = 'moveContact'
}

const SuggestedClientContact = ({
	onFormChange,
	hasCustomerSupport,
	close,
	suggestedContent
}: {
	onFormChange: FormObserverOnFieldChange;
	hasCustomerSupport: boolean;
	close: () => void;
	suggestedContent: SuggestedContent;
}) => {
	const {
		state: { ticket, activeExternalContactId, replyMode }
	} = useEditTicketContext();
	const classes = new BemClass('SuggestedClientContact');
	const hasCustomContactSearch = useSoftDeployAccess('CLIENT_CONTACT_CUSTOM_FIELD_SEARCH');
	const { t } = useTranslation();
	const relationDescription = t('ticket.moveContactToClientRelationDescription');
	// If all entries with this ticket id have contact value NULL, then it is a domain match
	const hasNonNullContacts = ticket?.multiMatchClientContacts?.some(
		(match: MultiMatchClientContact) => match.contact !== null
	);

	const data =
		activeExternalContactId && replyMode === 'external'
			? ticket.externalContacts.find((ec: ExternalContact) => ec.id === activeExternalContactId)
			: ticket;

	const { client, involved } = data ?? {};
	const mainRecipient = getMainRecipient(involved ?? []);
	const { contact } = mainRecipient ?? {};

	const [buttonSelect, setButtonSelect] = useState<'suggested' | 'all'>(
		suggestedContent === SuggestedContent.MoveContactToClient ? 'all' : 'suggested'
	);
	const [searchString, setSearchString] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(true);
	const [suggestedContacts, setSuggestedContacts] = useState<MultiMatchClientContact[]>([]);
	const [allContacts, setAllContacts] = useState<Contact[]>([]);
	const [suggestedClients, setSuggestedClients] = useState<MultiMatchClientContact[]>([]);
	const [allClients, setAllClients] = useState<Client[]>([]);

	const suggestedClientContact = buttonSelect === 'suggested';

	const getContactsPromise = useRef<null | CancelablePromise<Awaited<ReturnType<typeof ContactResource.find>>>>(null);
	const getContactsFromCustomFieldsPromise = useRef<null | CancelablePromise<void>>(null);
	const getClientPromise = useRef<null | CancelablePromise<Awaited<ReturnType<typeof ClientResource.find>>>>(null);
	const defaultEmailContactPromise = useRef<null | CancelablePromise<
		Awaited<ReturnType<typeof TicketDefaultEmailContactResource.findByEmail>>
	>>(null);
	const updateEmailContactPromise = useRef<null | CancelablePromise<
		Awaited<ReturnType<typeof TicketDefaultEmailContactResource.updateEmail>>
	>>(null);
	const saveEmailContactPromise = useRef<null | CancelablePromise<
		Awaited<ReturnType<typeof TicketDefaultEmailContactResource.save>>
	>>(null);
	const movePromise = useRef<null | CancelablePromise<any>>(null);
	const relationPromise = useRef<null | CancelablePromise<any>>(null);

	useEffect(() => {
		let debounce: NodeJS.Timeout;

		const getContacts = () => {
			setLoading(true);
			const rb = new RequestBuilder();

			if (hasCustomContactSearch) {
				getContactsFromCustomFieldsPromise.current = makeCancelable(
					fetchClientsFromCustomFields(searchString)
						.then(customClientIds => {
							getContactsPromise.current = makeCancelable(
								ContactResource.search(searchString, undefined, undefined, rb, customClientIds)
							);
							getContactsPromise.current.promise
								.then(({ data }) => {
									setAllContacts(data);
								})
								.catch(e => logError(e, 'Failed to find contacts'))
								.finally(() => setLoading(false));
						})
						.catch(e => logError(e, 'Failed to find clients'))
				);
			} else {
				getContactsPromise.current = makeCancelable(
					ContactResource.search(searchString, undefined, undefined, rb)
				);
				getContactsPromise.current.promise
					.then(({ data }) => {
						setAllContacts(data);
					})
					.catch(e => logError(e, 'Failed to find contacts'))
					.finally(() => setLoading(false));
			}
		};

		const getClients = () => {
			setLoading(true);
			const rb = new RequestBuilder();

			if (searchString.length > 0) {
				const orFilter = rb.orBuilder();
				orFilter.next();
				orFilter.addFilter({ field: 'name' }, comparisonTypes.Search, searchString);

				if (!isNaN(parseInt(searchString))) {
					orFilter.next();
					orFilter.addFilter({ field: 'id' }, comparisonTypes.Search, parseInt(searchString));
				}

				setCustomClientFieldsFilters(orFilter, searchString);

				orFilter.done();
			}
			rb.addSort('name', true);
			rb.limit = 10;
			getClientPromise.current = makeCancelable(ClientResource.find(rb.build()));
			getClientPromise.current.promise
				.then(({ data }) => {
					setAllClients(data);
				})
				.catch(e => logError(e, 'Failed to find clients'))
				.finally(() => setLoading(false));
		};

		if (buttonSelect === 'all') {
			debounce = setTimeout(() => {
				if (suggestedContent === SuggestedContent.Contact) {
					getContacts();
				} else {
					getClients();
				}
			}, 300);
		} else if (buttonSelect === 'suggested') {
			debounce = setTimeout(() => {
				if (ticket?.multiMatchClientContacts) {
					switch (suggestedContent) {
						// Normal contact match
						case SuggestedContent.Contact: {
							const filteredSuggestedContacts = ticket?.multiMatchClientContacts?.filter(
								(match: MultiMatchClientContact) => {
									const matchContact = match.contact;
									const matchClient = match.client;
									const lowerCaseSearchString = searchString.toLowerCase();
									return (
										matchContact.name.toLowerCase().includes(lowerCaseSearchString) ||
										matchClient.name.toLowerCase().includes(lowerCaseSearchString) ||
										(matchContact.email &&
											matchContact.email.toLowerCase().includes(lowerCaseSearchString)) ||
										(matchContact.phone &&
											matchContact.phone.toLowerCase().includes(lowerCaseSearchString)) ||
										(matchContact.cellPhone &&
											matchContact.cellPhone.toLowerCase().includes(lowerCaseSearchString))
									);
								}
							);
							setSuggestedContacts(filteredSuggestedContacts);
							setSuggestedClients([]);
							break;
						}

						// Normal domain match
						case SuggestedContent.Client: {
							const filteredSuggestedClients = ticket?.multiMatchClientContacts?.filter(
								(match: MultiMatchClientContact) => {
									const matchClient = match.client;
									const lowerCaseSearchString = searchString.toLowerCase();
									return (
										matchClient.name.toLowerCase().includes(lowerCaseSearchString) ||
										(matchClient?.address &&
											matchClient.address.toLowerCase().includes(lowerCaseSearchString)) ||
										(matchClient?.zipcode &&
											matchClient.zipcode.toLowerCase().includes(lowerCaseSearchString)) ||
										(matchClient?.state &&
											matchClient.state.toLowerCase().includes(lowerCaseSearchString))
									);
								}
							);
							setSuggestedContacts([]);
							setSuggestedClients(filteredSuggestedClients);
							break;
						}

						// Contact match but 'move contact to client' suggestions should be empty
						case SuggestedContent.MoveContactToClient: {
							setSuggestedContacts([]);
							setSuggestedClients([]);
							break;
						}
					}
					setLoading(false);
				}
			}, 300);
		}

		return () => {
			if (debounce) {
				clearTimeout(debounce);
			}
			getContactsFromCustomFieldsPromise?.current?.cancel();
			getContactsPromise?.current?.cancel();
			getClientPromise?.current?.cancel();
			defaultEmailContactPromise?.current?.cancel();
			updateEmailContactPromise?.current?.cancel();
			saveEmailContactPromise?.current?.cancel();
			movePromise?.current?.cancel();
			relationPromise?.current?.cancel();
		};
	}, [client?.id, contact?.id, searchString, buttonSelect, suggestedContent]);

	const moveContactToClient = async (contact: MultiMatchClientContact['contact'], client: Client) => {
		setLoading(true);

		try {
			const movedContact = await getAngularModule('Contact').move({
				id: contact.id,
				client: { id: client.id }
			});

			// Only add a relation if the match was a match on 1 or or more email (so multi contact match or exact match)
			if (
				relationDescription &&
				(suggestedContent === SuggestedContent.MoveContactToClient || hasNonNullContacts)
			) {
				try {
					await getAngularModule('ContactRelation').customer(Tools.AppService.getCustomerId()).save({
						contactId: contact.id,
						relatedToClientId: client.id,
						description: relationDescription
					});
				} catch (e) {
					logError(e, 'Failed to create relation between contact and client');
				}
			}

			onFormChange('contactInfo', {
				client: movedContact.client,
				contact: movedContact,
				email: movedContact.email
			});
		} catch (e) {
			logError(e, 'Failed to move contact to new client');
		} finally {
			setLoading(false);
		}
	};

	const handleMoveContactToClient = (
		matchClient: (Client & ClientAddress) | MultiMatchClientContact['client'],
		innerClose: () => void,
		close: () => void
	) => {
		getClientPromise.current = makeCancelable(ClientResource.get(matchClient.id));
		getClientPromise.current.promise
			.then(({ data }) => {
				const clientData = Array.isArray(data) ? data[0] : data;
				if (contact) {
					moveContactToClient(contact, clientData);
				}
			})
			.catch(e => logError(e, `Failed to find client '${matchClient.id}'`))
			.finally(() => {
				innerClose();
				close();
			});

		return () => {
			getClientPromise?.current?.cancel();
		};
	};

	const handleChangeContact = (
		matchContact: Contact | MultiMatchClientContact['contact'],
		matchClient: (Client & ClientAddress) | MultiMatchClientContact['client'],
		innerClose: () => void,
		close: () => void
	) => {
		getClientPromise.current = makeCancelable(ClientResource.get(matchClient.id));
		getClientPromise.current.promise
			.then(({ data }) => {
				onFormChange('contactInfo', {
					client: data,
					contact: matchContact,
					email: matchContact.email
				});
				innerClose();
				close();
			})
			.catch(e => logError(e, `Failed to find client connected to contact '${matchContact.id}'`));

		return () => {
			getClientPromise?.current?.cancel();
		};
	};

	const handleSetDefaultContact = (
		matchContact: Contact | MultiMatchClientContact['contact'],
		matchClient: (Client & ClientAddress) | MultiMatchClientContact['client'],
		innerClose: () => void
	) => {
		defaultEmailContactPromise.current = makeCancelable(
			TicketDefaultEmailContactResource.findByEmail(matchContact.email)
		);

		defaultEmailContactPromise.current.promise
			.then(existing => {
				if (existing) {
					updateEmailContactPromise.current = makeCancelable(
						TicketDefaultEmailContactResource.updateEmail(existing.id, {
							email: matchContact.email,
							contactId: matchContact.id
						})
					);
					return updateEmailContactPromise.current.promise;
				} else {
					saveEmailContactPromise.current = makeCancelable(
						TicketDefaultEmailContactResource.save({
							email: matchContact.email,
							contactId: matchContact.id
						})
					);
					return saveEmailContactPromise.current.promise;
				}
			})
			.then(() => {
				handleChangeContact(matchContact, matchClient, innerClose, close);
			})
			.catch(e => logError(e, `Failed to process contact operation for contact ${matchContact.id}`));

		return () => {
			defaultEmailContactPromise?.current?.cancel();
			updateEmailContactPromise?.current?.cancel();
			saveEmailContactPromise?.current?.cancel();
			getClientPromise?.current?.cancel();
		};
	};

	const renderContacts = (
		contacts: (MultiMatchClientContact | Contact)[],
		isSuggested: boolean,
		close: () => void
	) => {
		if (isSuggested) {
			// Filter out contacts where the mail does not match (if emails has been changed since the matching occured)
			contacts = contacts.filter(match => {
				const matchContact = (match as MultiMatchClientContact).contact;
				return mainRecipient?.email === matchContact.email;
			});
		}
		return contacts.length === 0 ? (
			<>
				<Text space="mtl mbxl" align="center" italic color="grey-11">
					{t('default.noContacts')}
				</Text>
				<hr />
			</>
		) : (
			contacts.map((match: MultiMatchClientContact | Contact) => {
				const matchClient = isSuggested
					? (match as MultiMatchClientContact).client
					: ((match as Contact).client as Client & ClientAddress);
				const matchContact = isSuggested ? (match as MultiMatchClientContact).contact : (match as Contact);
				const matchAddress = isSuggested ? matchClient : matchClient?.addresses?.[0] ?? {};
				return (
					<Flex
						key={matchContact.id}
						className={classes.elem('row').b()}
						data-id={matchContact.id}
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						space="ptm pbm prl pll"
					>
						<Flex className={classes.elem('userInfo').b()} direction="column" flex={1}>
							<>
								<Text bold={true} className={classes.elem('row').elem('title').b()}>
									{matchContact.name}
								</Text>
								<Text size="sm" color="grey-11">
									{`${matchClient.name}`}
								</Text>
								<Text size="sm" color="grey-11">
									{`${matchAddress.address ?? ''} ${
										isSuggested ? (matchAddress as any).zipCode ?? '' : matchAddress.zipcode ?? ''
									} ${matchAddress.state ?? ''}`}
								</Text>
							</>
						</Flex>
						<DropDownMenu
							align="right"
							verticalAlign="center"
							className={classes.elem('confirmDropdown').b()}
							renderTrigger={(isExpanded, setExpanded) => (
								<PrimaryButton
									onClick={setExpanded}
									size="sm"
									disabled={matchContact.id === contact?.id}
								>
									{matchContact.id === contact?.id ? t('default.selectedOne') : t('default.select')}
								</PrimaryButton>
							)}
						>
							{innerClose => (
								<Flex direction="column" space="ptl prl pbl pll">
									<Text>{t('ticket.linkEmailToContact', { name: matchContact.name })}</Text>
									<Flex direction="row" space="ptl" gap="u2">
										<PrimaryButton
											onClick={() =>
												handleSetDefaultContact(matchContact, matchClient, innerClose)
											}
											size="sm"
										>
											{t('default.yes')}
										</PrimaryButton>
										<DefaultButton
											onClick={() =>
												handleChangeContact(matchContact, matchClient, innerClose, close)
											}
											size="sm"
										>
											{t('default.noOnlyThisTime')}
										</DefaultButton>
										<ThirdButton
											className={classes.elem('noPadding').b()}
											onClick={innerClose}
											size="sm"
										>
											{t('default.cancel')}
										</ThirdButton>
									</Flex>
								</Flex>
							)}
						</DropDownMenu>
					</Flex>
				);
			})
		);
	};

	const renderClients = (clients: (MultiMatchClientContact | Client)[], isSuggested: boolean, close: () => void) => {
		return clients.length === 0 ? (
			<>
				<Text space="mtl mbxl" align="center" italic color="grey-11">
					{t('default.noAccounts')}
				</Text>
				<hr />
			</>
		) : (
			clients.map((match: MultiMatchClientContact | Client) => {
				const matchClient = isSuggested
					? (match as MultiMatchClientContact).client
					: (match as Client & ClientAddress);
				const matchAddress = isSuggested ? matchClient : (match as Client & ClientAddress).addresses?.[0] ?? {};
				return (
					<Flex
						key={matchClient.id}
						className={classes.elem('row').b()}
						data-id={matchClient.id}
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						space="ptm pbm prl pll"
					>
						<Flex className={classes.elem('userInfo').b()} direction="column" flex={1}>
							<>
								<Text bold={true} className={classes.elem('row').elem('title').b()}>
									{matchClient.name}
								</Text>
								<Text size="sm" color="grey-11">
									{`${matchAddress.address ?? ''} ${
										isSuggested ? (matchAddress as any).zipCode ?? '' : matchAddress.zipcode ?? ''
									} ${matchAddress.state ?? ''}`}
								</Text>
							</>
						</Flex>
						<DropDownMenu
							align="right"
							verticalAlign="center"
							className={classes.elem('confirmDropdown').mod('moveClient').b()}
							renderTrigger={(isExpanded, setExpanded) => (
								<PrimaryButton onClick={setExpanded} size="sm" disabled={matchClient.id === client?.id}>
									{matchClient.id === client?.id ? t('default.selectedOne') : t('default.select')}
								</PrimaryButton>
							)}
						>
							{innerClose => (
								<Flex direction="column" space="ptl prl pbl pll">
									<Text>{t('ticket.confirmMove', { name: matchClient.name })}</Text>
									<Flex direction="row" space="ptl">
										<PrimaryButton
											onClick={() => handleMoveContactToClient(matchClient, innerClose, close)}
											size="sm"
										>
											{t('default.move')}
										</PrimaryButton>
										<ThirdButton onClick={innerClose} size="sm">
											{t('default.cancel')}
										</ThirdButton>
									</Flex>
								</Flex>
							)}
						</DropDownMenu>
					</Flex>
				);
			})
		);
	};

	return (
		<Flex direction="column" className={classes.elem('suggestedBox').b()}>
			<Block space="pll prl ptl pbm">
				<Input
					icon="search"
					value={searchString}
					onChange={e => setSearchString(e.target.value)}
					placeholder={t('default.search')}
				/>
			</Block>
			<Flex space="pbl ptl pll prl">
				<ButtonSelect
					className={classes.elem('btnSelect').b()}
					size="sm"
					value={buttonSelect}
					onChange={v => {
						setLoading(true);
						setButtonSelect(v);
						setSearchString('');
					}}
					options={[
						{
							value: 'suggested',
							title:
								suggestedContent === SuggestedContent.Contact
									? t('ticket.suggestedContacts')
									: t('ticket.suggestedClients')
						},
						{ value: 'all', title: t('default.all') }
					]}
					disabled={!hasCustomerSupport}
				/>
			</Flex>
			<div className={classes.elem('result').b()}>
				{loading ? (
					<Block space="ptm">
						<Row align="center">
							<Loader size="sm" />
						</Row>
					</Block>
				) : suggestedContent === SuggestedContent.Contact ? (
					renderContacts(
						suggestedClientContact ? suggestedContacts : allContacts,
						suggestedClientContact,
						close
					)
				) : (
					renderClients(suggestedClientContact ? suggestedClients : allClients, suggestedClientContact, close)
				)}
			</div>
		</Flex>
	);
};

export default SuggestedClientContact;
