import React from 'react';
import { Flex, Icon, Text, Tooltip } from '@upsales/components';
import formatWidgetValue from '../../formatWidgetValue';
import { Datatypes } from 'Resources/ReportWidget';
import { loadDrilldown } from '../../chartHelper';
import { RCDashboardWidget } from 'Resources/ReportDashboard';
import { useSoftDeployAccess } from 'App/components/hooks/featureHelper';
import { renderToString } from 'react-dom/server';

import './DistributionBar.scss';
import bemClass from '@upsales/components/Utils/bemClass';

const DefaultColors = ['bright-green', 'green', 'medium-turcose', 'orange', 'blue', 'bright-blue', 'purple'];

type DistributionBarProps = {
	distribution?: {
		id: string | number;
		label: string;
		value: number;
		count?: number;
		color?: string;
		striped?: boolean;
		disableTooltip?: boolean;
	}[];
	datatype: keyof typeof Datatypes;
	currency?: string;
	thick?: boolean;
	drilldown?: boolean;
	enableDrilldown?: boolean;
	config?: RCDashboardWidget;
	openedFromCompanyGroupCard?: boolean;
};

const TooltipContent = ({ distribution, datatype, currency }: DistributionBarProps) => {
	return (
		<>
			{distribution?.map((entry, idx) => {
				const title = `${entry.label} - ${formatWidgetValue(entry.value, datatype, currency)}`;
				const fallbackColor = DefaultColors[idx % DefaultColors.length];
				const color = entry.color || fallbackColor;
				return (
					<Flex key={entry.id} gap="u1" alignItems="center">
						<Icon name="circle" color={color} />
						<Text>{title}</Text>
					</Flex>
				);
			})}
		</>
	);
};

const DistributionBar = ({
	distribution,
	datatype,
	currency,
	thick,
	drilldown,
	enableDrilldown,
	config,
	openedFromCompanyGroupCard
}: DistributionBarProps) => {
	const classes = new bemClass('DistributionBar');
	const hasGroupBonanza = useSoftDeployAccess('GROUP_BONANZA');

	if (thick) {
		classes.mod('thick');
	}

	if (distribution) {
		const totalValue = distribution.reduce((acc, entry) => acc + entry.value, 0);

		const drilldownEnabled = drilldown && enableDrilldown;

		return (
			<div className={classes.b()}>
				{distribution.map((entry, idx) => {
					const percentage = totalValue > 0 ? (100 * entry.value) / totalValue : 0;

					const style = {
						width: percentage + '%',
						backgroundColor: entry.color
					};

					const entryClasses = new bemClass('DistributionBar__entry');
					const fallbackColor = DefaultColors[idx % DefaultColors.length];
					const color = entry.color || fallbackColor;
					entryClasses.mod(color);

					if (entry.striped) {
						entryClasses.mod(`${entry.color}--striped`);
					}

					const count = entry.count ? ` (${entry.count}) ` : '';
					const title = `${entry.label} ${count}- ${formatWidgetValue(entry.value, datatype, currency)}`;

					return hasGroupBonanza && openedFromCompanyGroupCard ? (
						<Tooltip
							disabled={entry.disableTooltip}
							key={entry.id}
							title={renderToString(
								<TooltipContent distribution={distribution} datatype={datatype} currency={currency} />
							)}
							theme="white"
							distance={20}
							className={entryClasses.b()}
							style={style}
						>
							<div
								onClick={
									drilldownEnabled && config
										? e => {
												e.stopPropagation();
												loadDrilldown([String(entry.id)], config);
										  }
										: undefined
								}
							/>
						</Tooltip>
					) : (
						<Tooltip key={entry.id} title={title} className={entryClasses.b()} style={style}>
							<div
								style={{ width: '100%', height: '100%' }}
								onClick={
									drilldownEnabled && config
										? e => {
												e.stopPropagation();
												loadDrilldown([String(entry.id)], config);
										  }
										: undefined
								}
							/>
						</Tooltip>
					);
				})}
			</div>
		);
	}
	return <div />;
};

export default DistributionBar;
