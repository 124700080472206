import { Loader, Modal, ModalContent, ModalControls, ModalHeader } from '@upsales/components';
import React, { useEffect, useState } from 'react';
import T from 'Components/Helpers/translate';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import UpSelect from 'Components/Inputs/UpSelect';
import Contact from 'App/resources/Model/Contact';
import { makeCancelable } from 'Helpers/promise';
import logError from 'Helpers/logError';

type Props = {
	id: number;
	accountId: number;
	customerId: number;
	close: (reason?: string) => void;
	className: string;
};

function MergeContacts(props: Props) {
	const [selectedContact, setSelectedContact] = useState<Contact>();
	const [mergeContact, setMergeContact] = useState<Contact>();
	const [selectData, setSelectData] = useState<Contact[]>([]);
	const [saving, setSaving] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isAjax, setIsAjax] = useState(false);

	useEffect(() => {
		const rb = new RequestBuilder();
		rb.addFilter(Tools.Contact.attr.client.attr.id, comparisonTypes.Equals, props.accountId);
		rb.addFilter(Tools.Contact.attr.id, comparisonTypes.NotEquals, props.id);
		rb.addFilter(Tools.Contact.attr.active, comparisonTypes.Equals, true);
		rb.addSort(Tools.Contact.attr.name, true);
		rb.fields = ['id', 'name'];

		const { promise, cancel } = makeCancelable(
			Promise.all([
				Tools.Contact.customer(props.customerId).get(props.id),
				Tools.Contact.customer(props.customerId).find(rb.build())
			])
		);

		promise
			.then(([contact, contacts]) => {
				setMergeContact(contact.data);
				let contactResults: Contact[] & { disabled?: boolean } = contacts.data;
				if (contacts.data.length < contacts.metadata.total) {
					setIsAjax(true);
				} else {
					contactResults = contacts.data.map((contact: Contact & { disabled?: boolean }) => {
						if (!contact.userRemovable) {
							contact.disabled = true;
						}
						return contact;
					});
				}
				setSelectData(contactResults);
				if (!contacts.data.length) {
					Tools.$upModal.open('warningAlert', {
						title: 'default.noContacts',
						body: 'contact.noContactsToMerge'
					});
					props.close();
				}
			})
			.then(() => {
				setIsLoading(false);
			})
			.catch(err => {
				logError(err, 'Failed to load contacts for merge');
			});
		return cancel;
	}, []);

	const missingRights = T('noDeleteRights.contact');

	const contactSelectConfig = {
		placeholder: T('default.selectContact'),
		allowClear: 1,
		formatResult: function (obj: Contact, el: object, x: object, encode: (str: string) => string) {
			var nameStr = encode(obj.name);
			if (!obj.userRemovable) {
				nameStr += ' (' + missingRights + ')';
			}
			if (obj.title) {
				nameStr += '<br/><span class="subtitle">' + encode(obj.title) + '</span>';
			}
			return nameStr;
		},
		formatSelection: function (obj: Contact, x: object, encode: (str: string) => string) {
			var nameStr = obj.name;
			if (!obj.userRemovable) {
				nameStr += ' (' + missingRights + ')';
			}
			return encode(nameStr);
		},
		matcher: function (term: string, udnef: undefined, obj: Contact) {
			return obj.name.toLowerCase().indexOf(term.toLowerCase()) !== -1;
		},
		ajax: isAjax
			? {
					data: function (term: string) {
						return term;
					},
					transport: function (query: any) {
						if (query.data !== undefined) {
							// If we have initial data to show when no searchString is set
							if (query.data === '' && selectData && selectData.length) {
								return query.success({ data: selectData });
							}

							var filter = new RequestBuilder();

							filter.addFilter(Tools.Contact.attr.name, comparisonTypes.Search, query.data);
							filter.addFilter(Tools.Contact.attr.account, comparisonTypes.Equals, props.accountId);
							filter.addFilter(Tools.Contact.attr.id, comparisonTypes.NotEquals, props.id);

							filter.fields = ['id', 'name'];

							filter.limit = 10;

							return Tools.Contact.find(filter.build()).then(query.success);
						}
						return query.success({
							data: []
						});
					},
					results: function (res: any) {
						for (const contact of res.data) {
							if (!contact.userRemovable) {
								contact.disabled = true;
							}
						}

						return { results: res.data };
					}
			  }
			: undefined
	};

	const merge = () => {
		if (!selectedContact || !mergeContact) {
			return;
		}
		if (Number(selectedContact.id) === Number(mergeContact.id)) {
			return;
		}
		setSaving(true);
		Tools.Contact.customer(props.customerId)
			.merge(mergeContact, selectedContact)
			.then(() => {
				setTimeout(() => {
					props.close();
					setSaving(false);
				}, 1500);
			})
			.catch(function () {
				setSaving(false);
			});
	};

	const select = (selected: any) => {
		setSelectedContact(selected.target.added);
	};

	return (
		<Modal size="xl" className={props.className}>
			<ModalHeader
				onClose={props.close}
				title={T('contact.mergeContacts')}
				className="up-modal-header"
			></ModalHeader>
			{!isLoading ? (
				<>
					<ModalContent className="up-modal-content">
						<div className="up-panel">
							<div className="up-panel-content">
								<div className="input-group">
									<span className="input-group-addon">
										{T('default.merge')} <b>{mergeContact && mergeContact.name}</b>{' '}
										{T('default.from').toLocaleLowerCase()}
									</span>
									<UpSelect
										options={contactSelectConfig}
										className="form-control"
										data={selectData ?? []}
										defaultValue={selectedContact}
										onChange={select}
									/>
								</div>

								<br />

								<p>
									{T('contact.mergeInfo1')} <b>{mergeContact && mergeContact.name}</b>.
								</p>

								<p>
									<i>{T('contact.canOnlyMergeOnSameAccount')}</i>
								</p>
							</div>
						</div>
					</ModalContent>
					<ModalControls className="up-modal-controls">
						{selectedContact && mergeContact && selectedContact.id === mergeContact.id ? (
							<p className="text-danger pull-left">
								<b>{T('mergeError.sameIdContact')}</b>
							</p>
						) : null}
						{selectedContact && mergeContact && selectedContact && !selectedContact.userRemovable ? (
							<p className="text-danger pull-left">
								<b>{T('mergeError.missingRightsContact')}</b>
							</p>
						) : null}
						<div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
							<button
								type="button"
								className="btn up-btn btn-grey btn-link"
								disabled={saving}
								onClick={() => props.close('aborted')}
							>
								{T('default.abort')}
							</button>
							<button
								type="button"
								onClick={merge}
								className="btn up-btn btn-bright-blue no-shadow main-action btn-primary-action"
								disabled={saving || !selectedContact || !selectedContact.userRemovable || !mergeContact}
							>
								{T(saving ? 'default.merging' : 'contact.mergeContacts')}
								&nbsp;
								{saving ? <span className={`${saving ? 'fa-spin' : null} fa fa-refresh`}></span> : null}
							</button>
						</div>
					</ModalControls>
				</>
			) : (
				<div style={{ display: 'flex', justifyContent: 'center', padding: '16px' }}>
					<Loader size="sm" id="pageLoader" />
				</div>
			)}
		</Modal>
	);
}

export default MergeContacts;
