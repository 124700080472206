import React from 'react';
import { Label, Text, Block } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';

type NotificationDataProps = {
	notificationData: any;
};

const NotificationData = ({ notificationData }: NotificationDataProps) => {
	const classes = new BemClass('NotificationData');

	const renderNotificationData = (value: any = '') => {
		if (value === undefined || value === null) {
			return null;
		}

		if (Array.isArray(value)) {
			return value.map((item, index) => {
				return (
					<Block key={index} space="mbm">
						{renderNotificationData(item)}
					</Block>
				);
			});
		}

		if (typeof value === 'object') {
			return Object.entries(value).map(([key, objValue]) => (
				<Block key={key} space="mbm">
					<Label>{key}</Label>
					{renderNotificationData(objValue)}
				</Block>
			));
		}

		return <Text size="sm">{value}</Text>;
	};

	return <Block className={classes.b()}>{renderNotificationData(notificationData)}</Block>;
};

export default NotificationData;
