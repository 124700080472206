import { Text, Button, Icon, Title, Link, Flex } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import moment from 'moment';
import React, { Fragment, useEffect } from 'react';
import Signals, { Item } from 'Resources/Signals';
import SignalsAddMenu from 'App/pages/Prospecting/Signals/SignalsAddMenu';

import './NewsItem.scss';
import BouncyThumbtack from 'App/components/Icon/BouncyThumbtack';
import { globalTracker } from 'Helpers/Tracker';

type NewsItemProps = {
	item: Item;
};

export const getMedium = (medium: number) => {
	switch (medium) {
		case 2:
			return <Icon name="cision" color="grey-10" />;
		case 3:
			return <Icon name="di" color="grey-10" />;
		case 4:
			return <Icon name="breakit" color="grey-10" />;
		case 9:
			return <Icon name="borskollen" color="grey-10" />;
		case 10:
			return <Icon name="avanza" color="grey-10" />;
		case 11:
			return <Icon name="economist" color="grey-10" />;
		case 12:
			return <Icon name="idg" color="grey-10" />;
		default:
			return <Icon name="newspaper" color="grey-10" />;
	}
};
const NewsItem: React.FC<NewsItemProps> = ({ item }) => {
	const t = Tools.$translate;
	const classes = new bemClass('NewsItem');
	const [pinned, setPinned] = React.useState<boolean>(item.pinned);

	useEffect(() => {
		setPinned(item.pinned);
	}, [item]);

	const toggle = async (pin: boolean) => {
		if (pin) {
			setPinned(true);
			const data = await Signals.pin(item.clientId, item.id, item.date);
			if (data && data.id) {
				item.pinned = true;
				item.eventId = data.id;
			}
		} else {
			setPinned(false);
			await Signals.unpin(item.eventId);
			item.pinned = false;
		}
	};

	const accountHref = Tools.$state.href('account.dashboard', {
		id: item.clientId,
		customerId: Tools.AppService.getCustomerId()
	});

	const openLink = () => {
		window.open(item.link || '', '_blank');
		globalTracker.track('Signals click read more');
	};

	return (
		<>
			<div className={classes.elem('text-container').b()}>
				<div className={classes.elem('text').b()}>
					<Link onClick={e => e.stopPropagation()} href={accountHref}>
						<Text bold size="md">
							{item.name}
						</Text>
					</Link>
					<Title onClick={openLink} size="md" className={classes.elem('title').b()}>
						{item.title}
					</Title>
					<Text onClick={openLink} size="md" color="grey-11" className="content-text">
						{item.value}
					</Text>
				</div>
				{item.img ? (
					<div className={classes.elem('image').b()}>
						<img src={item.img}></img>
					</div>
				) : null}
			</div>
			<Flex justifyContent="space-between" space="ptl pbl pll prl">
				<Flex gap="u3">
					<Button id="link-btn" color="super-light-green" onClick={openLink}>
						{t('news.readArticle')}
					</Button>
					<SignalsAddMenu item={item} />
					<BouncyThumbtack
						pinnedTooltip={t('prospecting.news.removeFromTimeline')}
						unPinnedTooltip={t('prospecting.news.addToTimeline')}
						pinned={pinned}
						onToggle={toggle}
					/>
				</Flex>
				<Flex gap="u1" justifyContent="flex-end" alignItems="center">
					<Text size="sm" color="grey-10">
						{moment(item.date).format('LLL')}
					</Text>
					<Text size="sm" color="grey-10">
						&bull;
					</Text>
					{item.medium ? (
						<Fragment>
							{getMedium(item.medium)}
							<Text size="sm" color="grey-10">
								{item.mediumName}
							</Text>
						</Fragment>
					) : null}
				</Flex>
			</Flex>
		</>
	);
};

export default NewsItem;
