import { AllIWantData, IntegrationConfig, IntegrationInit } from 'App/resources/AllIWant';
import Category, { CategoryType } from 'App/resources/Model/Category';
import EngageAccount from 'App/resources/Model/EngageAccount';
import ActivityType, { AppointmentType } from 'App/resources/Model/ActivityType';
import JourneyStep from 'App/resources/Model/JourneyStep';
import OrderStage from 'App/resources/Model/OrderStage';
import Product from 'App/resources/Model/Product';
import ProductCategory from 'App/resources/Model/ProductCategory';
import StaticValue from 'App/resources/Model/StaticValue';
import Script from 'App/resources/Model/Script';
import { AnyAction } from 'redux';
import SalesboardCard from 'App/resources/Model/SalesboardCard';
import type PriceList from 'App/resources/Model/PriceList';
import Link from 'App/resources/Model/Links';
import type PaymentExtension from 'App/resources/Model/PaymentExtension';
import CustomField from 'App/resources/Model/CustomField';
import { TicketStatus, TicketType } from 'App/resources/Model/Ticket';

export const RESET = '[App] Reset';
export const SET_LOADING = '[App] Set loading';
export const SET_ACCOUNT_SELF = '[App] Set accountSelf';
export const SET_AUTHENTICATED = '[App] Set authenticated';
export const SET_SELF = '[App] Set self';
export const SET_ACCESS_RIGHTS = '[App] Set accessRights';
export const SET_SIDEBAR_LINKS = '[App] Set sidebarLinks';
export const SET_TOTALS = '[App] Set totals';
export const SET_USER_MAP = '[App] Set userMap';
export const SET_ROLE_MAP = '[App] Set roleMap';
export const SET_BRANDS = '[App] Set brands';
export const SET_TODO_TYPES = '[App] Set todoTypes';
export const SET_SCRIPTS = '[App] Set scripts';
export const SET_HAS_ANGULAR_DEPENDANT_SCRIPT = '[App] Set hasAngularDependantScript';
export const SET_AD_ACCOUNT = '[App] Set adAccount';
export const SET_JOURNEY_STEPS = '[App] Set journeySteps';
export const SET_PRODUCTS = '[App] Set products';
export const SET_TOP_COUNTRIES = '[App] Set topCountries';
export const SET_CUSTOM_FIELDS = '[App] Set customFields';
export const RESET_CUSTOM_FIELDS = '[App] Reset customFields';
export const SET_DOCUMENT_TEMPLATES = '[App] Set documentTemplates';
export const SET_CATEGORY_TYPES = '[App] Set categoryTypes';
export const SET_CATEGORIES = '[App] Set categories';
export const SET_METADATA = '[App] Set metadata';
export const SET_SESSION_TIMEOUT_MS = '[App] Set sessionTimeoutMs';
export const SET_PHONE_INTEGRATION = '[App] Set phoneIntegration';
export const SET_MAIL_INTEGRATION = '[App] Set mailIntegration';
export const SET_CUSTOMER_ID = '[App] Set customerId';
export const SET_LIST_VIEWS = '[App] Set listViews';
export const SET_ACTIVITY_TYPES = '[App] Set activityTypes';
export const SET_PRODUCT_CATEGORIES = '[App] Set productCategories';
export const SET_STAGES = '[App] Set stages';
export const SET_REPORT_VIEWS = '[App] Set reportViews';
export const SET_STATIC_VALUES = '[App] Set staticValues';
export const SET_ANGULAR_LOADED = '[App] Set angularLoaded';
export const SET_ESIGN_INTEGRATIONS = '[App] Set esignIntegrations';
export const SET_SMS_INTEGRATIONS = '[App] Set smsIntegrations';
export const SET_WEBINAR_INTEGRATIONS = '[App] Set webinarIntegrations';
export const SET_FILE_STORAGE_INTEGRATIONS = '[App] Set fileStorageIntegrations';
export const SET_CALENDAR_INTEGRATIONS = '[App] Set calendarIntegrations';
export const SET_ACTIVE_CALENDAR_INTEGRATIONS = '[App] Set activeCalendarIntegrations';
export const SET_PROFILE_IMAGE_ID = '[App] Set profileImageId';
export const SET_SHOW_ONBOARDING = '[App] Set showOnboarding';
export const SET_ACCEPT_TERMS = '[App] Set acceptTerms';
export const SET_PRICE_LISTS = '[App] Set priceLists';
export const SET_PAYMENT_EXTENSIONS = '[App] Set paymentExtensions';
export const SET_CUSTOMER_SUPPORT_FORWARD_EMAIL = '[App] Set customerSupportForwardEmail';
export const SET_TICKET_TYPES = '[App] Set ticketTypes';
export const SET_TICKET_STATUSES = '[App] Set ticketStatuses';

export type AppState = {
	loading: boolean;
	accountSelf: null | AllIWantData['self'];
	self: null | AllIWantData['customerSelf'];
	accessRights: AllIWantData['accessRights'];
	sidebarLinks: Link[];
	authenticated: boolean;
	totals: AllIWantData['totals'];
	userMap: AllIWantData['userMap'];
	roleMap: AllIWantData['roleMap'];
	brands: AllIWantData['brands'];
	todoTypes: Partial<AllIWantData['todoTypes']>;
	scripts: Script[];
	hasAngularDependantScript: boolean;
	adAccount: EngageAccount | null;
	salesboardCardConfig: SalesboardCard | null;
	journeySteps: JourneyStep[];
	products: Product[];
	topCountries: string[];
	searchableFields: AllIWantData['customFields'];
	customFields: AllIWantData['customFields'];
	documentTemplates: AllIWantData['documentTemplates'];
	categoryTypes: {
		[key: string]: CategoryType[];
	};
	categories: { [type: string]: Category[] };
	metadata: null | AllIWantData['metadata'];
	sessionTimeoutMs: number;
	phoneIntegration: null | IntegrationConfig;
	mailIntegration: null | IntegrationConfig;
	esignIntegrations: IntegrationInit[];
	smsIntegrations: IntegrationInit[];
	webinarIntegrations: IntegrationInit[];
	fileStorageIntegrations: IntegrationInit[];
	calendarIntegrations: IntegrationInit[];
	activeCalendarIntegrations: IntegrationConfig[];
	customerId: number;
	listViews: AllIWantData['listViews'];
	activityTypes: {
		activity: ActivityType[];
		appointment: AppointmentType[];
	};
	productCategories: ProductCategory[];
	stages: {
		all: OrderStage[];
		order: OrderStage[];
		opportunity: OrderStage[];
		lost: OrderStage[];
		won: OrderStage[];
		excludedIds: number[];
	};
	reportViews: AllIWantData['reportViews'];
	staticValues: {
		languages: {
			id: string;
			name: string;
			country: string;
			language: string;
		}[];
		[key: string]: StaticValue[];
	};
	angularLoaded: boolean;
	profileImageIds: {
		[userId: number]: number;
	};
	showOnboarding: boolean;
	acceptTerms: AllIWantData['acceptTerms'];
	priceLists: PriceList[];
	paymentExtensions?: PaymentExtension[];
	customerSupportForwardEmail: string;
	ticketTypes: TicketType[];
	ticketStatuses: TicketStatus[];
};

export const initialState: AppState = {
	loading: true,
	accountSelf: null,
	authenticated: false,
	self: null,
	accessRights: { Agreement: false, SocialEvent: false },
	sidebarLinks: [],
	totals: {
		accounts: 0,
		activeCampaigns: 0,
		activeContacts: 0,
		activeProducts: 0,
		activeCallList: 0,
		campaigns: 0,
		contacts: 0,
		oldActivities: 0,
		products: 0
	},
	userMap: {},
	roleMap: {},
	brands: [],
	todoTypes: {},
	scripts: [],
	hasAngularDependantScript: false,
	adAccount: null,
	salesboardCardConfig: null,
	journeySteps: [],
	products: [],
	topCountries: [],
	searchableFields: {
		order: [],
		orderrow: [],
		opportunity: [],
		account: [],
		agreement: [],
		activity: [],
		todo: [],
		appointment: [],
		contact: [],
		product: [],
		project: [],
		projectPlan: [],
		lead: [],
		ticket: [],
		user: [],
		userDefined1: [],
		userDefined2: [],
		userDefined3: [],
		userDefined4: []
	},
	customFields: {
		order: [],
		orderrow: [],
		opportunity: [],
		account: [],
		agreement: [],
		activity: [],
		todo: [],
		appointment: [],
		contact: [],
		product: [],
		project: [],
		projectPlan: [],
		lead: [],
		ticket: [],
		user: [],
		userDefined1: [],
		userDefined2: [],
		userDefined3: [],
		userDefined4: []
	},
	documentTemplates: {
		activity: [],
		agreement: [],
		client: [],
		object1: [],
		object2: [],
		object3: [],
		object4: [],
		order: []
	},
	categoryTypes: {},
	categories: {},
	metadata: null,
	sessionTimeoutMs: 3_600_000 - 30_000, // Default timeout is 1h minus 30 sec
	phoneIntegration: null,
	mailIntegration: null,
	esignIntegrations: [],
	smsIntegrations: [],
	webinarIntegrations: [],
	fileStorageIntegrations: [],
	calendarIntegrations: [],
	activeCalendarIntegrations: [],
	customerId: 0,
	listViews: {
		salesboard: [],
		form: [],
		page: [],
		advancedSearchAccount: [],
		advancedSearchContact: [],
		advancedSearchActivity: [],
		advancedSearchAppointment: [],
		advancedSearchOpportunity: [],
		advancedSearchOrder: [],
		order: [],
		agreement: [],
		phoneCall: [],
		visitor: [],
		formSubmit: [],
		mailCampaign: [],
		account: [],
		contact: [],
		mail: [],
		mailTemplate: [],
		activity: [],
		opportunity: [],
		campaign: [],
		callList: [],
		leads2: [],
		segment: [],
		flow: [],
		projectPlan: [],
		ticket: []
	},
	activityTypes: {
		activity: [],
		appointment: []
	},
	productCategories: [],
	stages: {
		all: [],
		order: [],
		opportunity: [],
		lost: [],
		won: [],
		excludedIds: []
	},
	reportViews: {},
	staticValues: {
		languages: []
	},
	angularLoaded: false,
	profileImageIds: {},
	showOnboarding: false,
	acceptTerms: {
		show: false,
		accepted: false,
		termsId: -1,
		termsLink: 'string',
		termsDesc: 'string'
	},
	priceLists: [],
	paymentExtensions: [],
	customerSupportForwardEmail: '',
	ticketTypes: [],
	ticketStatuses: []
};

const ACTION_HANDLERS: { [key: string]: (s: AppState, a: AnyAction) => AppState } = {
	['RESET_ALL_REDUCERS']: (_state, { loading }) => ({
		...initialState,
		...(loading !== undefined ? { loading } : {})
	}),
	[RESET]: () => ({ ...initialState }),
	[SET_LOADING]: (state, { loading }) => ({ ...state, loading }),
	[SET_ACCOUNT_SELF]: (state, { accountSelf }) => ({ ...state, accountSelf }),
	[SET_AUTHENTICATED]: (state, { authenticated }) => ({ ...state, authenticated }),
	[SET_SELF]: (state, { self }) => ({ ...state, self }),
	[SET_ACCESS_RIGHTS]: (state, { accessRights }) => ({ ...state, accessRights }),
	[SET_SIDEBAR_LINKS]: (state, { sidebarLinks }) => ({ ...state, sidebarLinks }),
	[SET_TOTALS]: (state, { totals }) => ({ ...state, totals }),
	[SET_USER_MAP]: (state, { userMap }) => ({ ...state, userMap }),
	[SET_ROLE_MAP]: (state, { roleMap }) => ({ ...state, roleMap }),
	[SET_BRANDS]: (state, { brands }) => ({ ...state, brands }),
	[SET_TODO_TYPES]: (state, { todoTypes }) => ({ ...state, todoTypes }),
	[SET_SCRIPTS]: (state, { scripts }) => ({ ...state, scripts }),
	[SET_HAS_ANGULAR_DEPENDANT_SCRIPT]: (state, { hasAngularDependantScript }) => ({
		...state,
		hasAngularDependantScript
	}),
	[SET_AD_ACCOUNT]: (state, { adAccount }) => ({ ...state, adAccount }),
	[SET_JOURNEY_STEPS]: (state, { journeySteps }) => ({ ...state, journeySteps }),
	[SET_PRODUCTS]: (state, { products }) => ({ ...state, products }),
	[SET_TOP_COUNTRIES]: (state, { topCountries }) => ({ ...state, topCountries }),
	[SET_CUSTOM_FIELDS]: (state, { key, fields }) => {
		const searchableTypes = ['String', 'Integer', 'Select', 'Link'];
		if (key === 'agreement') {
			fields = fields.map((cf: CustomField) => ({ ...cf, nameType: 'Agreement' }));
		}

		return {
			...state,
			customFields: { ...state.customFields, [key]: fields },
			searchableFields: {
				...state.searchableFields,
				[key]: (fields as CustomField[]).filter(
					field => field.searchable && searchableTypes.includes(field.datatype)
				)
			}
		};
	},
	[RESET_CUSTOM_FIELDS]: state => ({
		...state,
		customFields: { ...initialState.customFields },
		searchableFields: { ...initialState.searchableFields }
	}),
	[SET_DOCUMENT_TEMPLATES]: (state, { key, templates }) => ({
		...state,
		documentTemplates: { ...state.documentTemplates, [key]: templates }
	}),
	[SET_CATEGORY_TYPES]: (state, { key, categoryTypes }) => ({
		...state,
		categoryTypes: { ...state.categoryTypes, [key]: categoryTypes }
	}),
	[SET_CATEGORIES]: (state, { key, categories }) => ({
		...state,
		categories: { ...state.categories, [key]: categories }
	}),
	[SET_METADATA]: (state, { metadata }) => ({ ...state, metadata }),
	[SET_SESSION_TIMEOUT_MS]: (state, { sessionTimeoutMs }) => ({ ...state, sessionTimeoutMs }),
	[SET_PHONE_INTEGRATION]: (state, { phoneIntegration }) => ({ ...state, phoneIntegration }),
	[SET_MAIL_INTEGRATION]: (state, { mailIntegration }) => ({ ...state, mailIntegration }),
	[SET_CUSTOMER_ID]: (state, { customerId }) => ({ ...state, customerId }),
	[SET_LIST_VIEWS]: (state, { listViews }) => ({ ...state, listViews }),
	[SET_ACTIVITY_TYPES]: (state, { types, key }) => ({
		...state,
		activityTypes: { ...state.activityTypes, [key]: types }
	}),
	[SET_PRODUCT_CATEGORIES]: (state, { productCategories }) => ({ ...state, productCategories }),
	[SET_STAGES]: (state, { stages }) => ({ ...state, stages }),
	[SET_REPORT_VIEWS]: (state, { reportViews }) => ({ ...state, reportViews }),
	[SET_STATIC_VALUES]: (state, { staticValues }) => ({ ...state, staticValues }),
	[SET_ANGULAR_LOADED]: (state, { angularLoaded }) => ({ ...state, angularLoaded }),
	[SET_ESIGN_INTEGRATIONS]: (state, { esignIntegrations }) => ({ ...state, esignIntegrations }),
	[SET_SMS_INTEGRATIONS]: (state, { smsIntegrations }) => ({ ...state, smsIntegrations }),
	[SET_WEBINAR_INTEGRATIONS]: (state, { webinarIntegrations }) => ({ ...state, webinarIntegrations }),
	[SET_FILE_STORAGE_INTEGRATIONS]: (state, { fileStorageIntegrations }) => ({ ...state, fileStorageIntegrations }),
	[SET_CALENDAR_INTEGRATIONS]: (state, { calendarIntegrations }) => ({ ...state, calendarIntegrations }),
	[SET_ACTIVE_CALENDAR_INTEGRATIONS]: (state, { activeCalendarIntegrations }) => ({
		...state,
		activeCalendarIntegrations
	}),
	[SET_PROFILE_IMAGE_ID]: (state, { userId, imageId }) => ({
		...state,
		profileImageIds: { ...state.profileImageIds, [userId]: imageId }
	}),
	[SET_SHOW_ONBOARDING]: (state, { showOnboarding }) => ({ ...state, showOnboarding }),
	[SET_ACCEPT_TERMS]: (state, { acceptTerms }) => ({ ...state, acceptTerms }),
	[SET_PRICE_LISTS]: (state, { priceLists }) => ({ ...state, priceLists }),
	[SET_PAYMENT_EXTENSIONS]: (state, { paymentExtensions }) => ({ ...state, paymentExtensions }),
	[SET_CUSTOMER_SUPPORT_FORWARD_EMAIL]: (state, { customerSupportForwardEmail }) => ({
		...state,
		customerSupportForwardEmail
	}),
	[SET_TICKET_TYPES]: (state, { ticketTypes }) => ({ ...state, ticketTypes }),
	[SET_TICKET_STATUSES]: (state, { ticketStatuses }) => ({ ...state, ticketStatuses })
};

export default (state = { ...initialState }, action: AnyAction): AppState => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
