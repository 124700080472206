import React from 'react';
import { connect, type ConnectedProps } from 'react-redux';
import { Button, Icon, Loader, Row, Column } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { onOpen, views } from 'Store/reducers/AssignModalReducer';
import AssignModalSidebar from './AssignModalSidebar';
import AssignModalActivities from './AssignModalActivities';
import AssignModalAssigned from './AssignModalAssigned';
import AssignModalCloseActivity from './AssignModalCloseActivity';
import AssignModalAssign from './AssignModalAssign';
import { asyncModalAdapter, setupComponentCompatibility, SetupComponentProps } from 'App/helpers/angularPortingHelpers';
import type { RootState } from 'Store/index';
import type { ClientIdName } from 'App/resources/Model/Client';
import moment from 'moment';
import _ from 'lodash';

import './AssignModal.scss';

const hasInTimeAppointivities = (activitiesAndAppointments: any[]) => {
	return _.some(activitiesAndAppointments, item => {
		if (item.isAppointment) {
			return true;
		}
		var date = moment(item.date);

		if (!item.time) {
			date.hours(23).minutes(59);
		}
		if (date.isAfter(moment())) {
			return true;
		}
		return false;
	});
};

export const mapStateToProps = (state: RootState) => ({
	client: state.AssignModal.client,
	loading: state.AssignModal.loading,
	view: state.AssignModal.view
});

const mapDispatchToProps = {
	onOpen
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ModalParams = {
	client: ClientIdName;
	contactId: number;
	from: string;
	formFields: string[];
	formId: number;
};

type Props = ConnectedProps<typeof connector> & {
	activitiesAndAppointments: RootState['AssignModal']['activitiesAndAppointments'];
} & SetupComponentProps & {
		modalParams: ModalParams;
	};

class AssignModal extends React.Component<Props> {
	lang: Record<string, string>;

	constructor(props: Props) {
		super(props);
		const t = Tools.$translate;
		this.lang = {
			accountManagers: t('default.accountManagers'),
			accountManager: t('default.accountManager'),
			noAccountManager: t('default.noAccountManager'),
			inactiveUser: t('default.inactiveUser'),
			planedActivitiesAndAppointments: t('processedBy.planedActivitiesAndAppointments'),
			delayedActivities: t('processedBy.delayedActivities'),
			delayedActivity: t('processedBy.delayedActivity'),
			assignUser: t('processedBy.AssignUser')
		};

		props.onOpen(props.modalParams).catch(() => {
			// Close modal if we fail
			props.reject();
		});

		this.renderContent = this.renderContent.bind(this);
	}

	componentDidUpdate() {
		this.props.reloadModalPosition();
	}

	renderContent(hasInTime: boolean) {
		switch (this.props.view) {
			case views.ACTIVITIES:
				return (
					<AssignModalActivities hasInTime={hasInTime} reloadModalPosition={this.props.reloadModalPosition} />
				);
			case views.ASSIGN:
				return (
					<AssignModalAssign
						close={this.props.reject}
						onAssign={this.props.resolve}
						reloadModalPosition={this.props.reloadModalPosition}
					/>
				);
			case views.ASSIGNED:
				return <AssignModalAssigned />;
			case views.CLOSEACTIVITY:
				return <AssignModalCloseActivity reloadModalPosition={this.props.reloadModalPosition} />;
			default:
				return null;
		}
	}

	render() {
		const { client, loading, reject } = this.props;
		const modalClass = new BemClass('AssignModal');
		if (loading) {
			return (
				<div className={modalClass.elem('loader').b()}>
					<Loader />
				</div>
			);
		}
		const hasInTime = hasInTimeAppointivities(this.props.activitiesAndAppointments);

		if (!hasInTime) {
			modalClass.mod('only-overdue');
		}

		return (
			<div className={modalClass.b()}>
				<Button color="grey" type="link" className={modalClass.elem('close').b()} onClick={reject}>
					<Icon name="times" />
				</Button>
				<Row>
					<Column fixedWidth={250}>{client ? <AssignModalSidebar client={client} /> : null}</Column>
					<Column>
						<div className={modalClass.elem('content').b()}>{this.renderContent(hasInTime)}</div>
					</Column>
				</Row>
			</div>
		);
	}
}

const ConnectedComponent = connector(AssignModal);

export const AssignModalReact = setupComponentCompatibility<ModalParams>(ConnectedComponent, {
	modalName: 'AssignModal',
	modalParamsMapper: $modalParams => ({
		modalParams: {
			client: $modalParams.client,
			contactId: $modalParams.contactId,
			from: $modalParams.from,
			formFields: $modalParams.formFields,
			formId: $modalParams.formId
		}
	})
});

export const openAssignModal = asyncModalAdapter({
	upModalName: 'processedBy',
	openModalName: 'AssignModal',
	featureFlag: 'REACT_ASSIGN_MODAL'
});

export const detached = AssignModal;
export default ConnectedComponent;
