import BemClass from '@upsales/components/Utils/bemClass';
import { useTranslation } from 'Components/Helpers/translate';
import React, { useEffect, useState } from 'react';
import { Avatar, Modal, ModalContent, ModalControls, ModalHeader, Tooltip } from '@upsales/components';
import { ModalProps } from 'App/components/Modals/Modals';
import { Esign, EsignEnum, EsignInvolved } from 'App/resources/Model/Esign';
import moment from 'moment';
import logError from 'Helpers/logError';
import openModal from 'App/services/Modal';
import { getDateTimeString } from 'App/helpers/DateHelpers';
import { renderToString } from 'react-dom/server';
import './ConfirmEsign.scss';
import { openIntegrationModal } from 'Components/IntegrationModal/IntegrationModal';

type Props = {
	id?: number;
	esign?: Esign;
} & ModalProps<boolean>;

const ConfirmEsign: React.FC<Props> = ({ id, esign: esignInput, className, close }) => {
	const classes = new BemClass('ConfirmEsign', className);
	const { t } = useTranslation();
	const [esign, setEsign] = useState<Esign>();
	const [documentType, setDocumentType] = useState<string>();
	const [sending, setSending] = useState(false);

	const view = esign?.state !== EsignEnum.DRAFT;
	const showListBtn = Tools.$state.current.name !== 'esign';

	const downloadURL = esign?.file || (esign?.integrationId && esign.title) ? Tools.Esign.getDownloadUrl(esign) : null;
	const unsignedUsers = esign?.involved.filter(p => !p.sign && p.userId && signedByCounterpart(p)).length;
	const signCount = esign?.involved.filter(p => p.sign !== null || signedByCounterpart(p)).length;

	useEffect(() => {
		const getSettings = (integrationId: number) =>
			Tools.Esign.runAction({
				type: 'settings',
				integrationId
			});

		const esignPromise = id
			? Tools.Esign.get(id).then(({ data }) => data)
			: esignInput
			? Promise.resolve(esignInput)
			: Promise.reject('Missing confirm(esign) or id');

		esignPromise
			.then(esignData => {
				sortInvolvees(esignData);
				setEsign(esignData);

				if (esignData.id && esignData.integrationId) {
					return getSettings(esignData.integrationId).then(({ data: settingsData }) => {
						setDocumentType(settingsData.documentType);
					});
				}

				return;
			})
			.catch(e => {
				close(false);
			});

		const listener = Tools.$rootScope.$on('esign.updated', (e, updated: Esign) => {
			if (esign && updated.id === esign.id) {
				setEsign(prevEsign => {
					const newEsign = structuredClone(prevEsign);
					for (const involved of newEsign?.involved ?? []) {
						const updatedPerson = updated.involved.find(p => p.id === involved.id);

						if (updatedPerson) {
							Object.assign(involved, updatedPerson);
						}
					}

					return newEsign;
				});
			}
		});

		return () => {
			listener();
		};
	}, []);

	function confirm() {
		setSending(true);

		const esignCopy = structuredClone(esign!);
		esignCopy.state = EsignEnum.PENDING;
		esignCopy.sendToScrive = true;

		Tools.Esign.save(esignCopy, { skipNotification: true })
			.then(function (res) {
				sortInvolvees(res.data);
				setEsign(res.data);
				setSending(false);
			})
			.catch(function () {
				setSending(false);
			});
	}

	function cancel() {
		if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
			openModal('Alert', {
				title: 'esign.revokeEsign',
				body: 'esign.revokeEsignConfirm',
				confirmButtonText: 'esign.revokeEsign',
				headerIcon: 'warning',
				onClose: confirmed => {
					if (confirmed) {
						Tools.Esign.cancel(esign!.id)
							.then(function () {
								close(false);
							})
							.catch(function (err) {
								logError(err, 'Esign.cancel rejected');
							});
					}
				}
			});
			return;
		}

		// eslint-disable-next-line promise/catch-or-return
		Tools.$upModal
			.open('warningConfirm', {
				title: 'esign.revokeEsign',
				body: 'esign.revokeEsignConfirm',
				resolveTrue: 'esign.revokeEsign',
				no: 'default.abort',
				icon: 'fa-warning'
			})
			.then(function () {
				Tools.Esign.cancel(esign!.id)
					.then(function () {
						close(false);
					})
					.catch(function (err) {
						logError(err, 'Esign.cancel rejected');
					});
			});
	}

	function goToList() {
		Tools.$state.go('esign');
		close(false);
	}

	function copy() {
		if (esign) {
			const data = {
				client: esign.client,
				user: esign.user,
				file: esign.file,
				title: esign.title,
				involved: esign.involved.map(function (p) {
					return {
						fstname: p.fstname,
						sndname: p.sndname,
						email: p.email,
						userId: p.userId,
						contactId: p.contactId,
						deliveryMethod: p.deliveryMethod,
						authMethod: p.authMethod
					};
				}),
				opportunity: esign.opportunity,
				orderStage: esign.orderStage
			};

			Tools.$upModal.open('editEsign', {
				esign: data
			});

			close(false);
		}
	}

	function openHtmlFrameNew() {
		const opts = {
			name: 'esignDocument',
			type: 'editEsign',
			integrationId: esign!.integrationId,
			objectId: esign!.id || null,
			fullscreen: true
		};

		openIntegrationModal(opts);
		close(false);
	}

	function openHtmlFrame() {
		if (Tools.FeatureHelper.hasSoftDeployAccess('ESIGN_VIEW_NEW')) {
			openHtmlFrameNew();
		} else {
			const esignCopy = structuredClone(esign!);
			esignCopy.sendToScrive = true;
			Tools.Esign.save(esignCopy)
				.then(function (res) {
					const opts = {
						name: 'esignDocument',
						type: 'editEsign',
						integrationId: esign!.integrationId,
						objectId: res.data.id || null,
						fullscreen: true
					};
					openIntegrationModal(opts);
					close(false);
				})
				.catch(function (err) {
					logError(err, 'Failed to save esign');
				});
		}
	}

	function sortInvolvees(esign: Esign): Esign {
		var future = moment().add(1, 'year').toDate();

		esign.involved
			.sort(function (p1, p2) {
				var sign1 = p1.sign || future;
				var sign2 = p2.sign || future;
				return sign1.getTime() - sign2.getTime();
			})
			.sort(function (person1, person2) {
				return (person1.userId ? 0 : 1) - (person2.userId ? 0 : 1);
			});

		return esign;
	}

	function signedByCounterpart(person: EsignInvolved) {
		return !person.sign && esign?.state === EsignEnum.ALL_SIGNED;
	}

	if (!esign) return null;

	return (
		<Modal className={classes.add(view ? 'view' : '').b()}>
			<form autoComplete="off" name="ConfirmEsignForm">
				{/* TITLE */}
				<ModalHeader
					title={view ? `${t('esign.sentEsign')} - ${esign.client.name}` : t('esign.confirmEsign')}
					onClose={() => close(false)}
				/>

				<div id="esign-sending-curtain" className={sending ? 'visible' : ''}>
					<div id="progress-bar-wrap">
						<div id="progress-bar"></div>
					</div>
					<span>{t('esign.sendingEsign')}</span>
				</div>

				<ModalContent>
					{esign.version < 2 ? (
						<div id="esign-version-warning">
							<b className="fa fa-warning"></b> <span>{t('esign.oldEsignInfo')}</span>
						</div>
					) : null}

					{/* INFO */}
					<p className="text-center">
						<span className="text-black">{esign.user.name}</span>{' '}
						<span>{(view ? t('esign.didSend') : t('esign.sends')).toLocaleLowerCase()}</span>{' '}
						<span>
							{downloadURL ? (
								<a target="_blank" href={downloadURL}>
									{esign.file?.filename || esign.title}
								</a>
							) : (
								<span className="text-black">{esign.file?.filename}</span>
							)}
							{!esign.file ? (
								<span className="text-black">{t('default.eSign').toLocaleLowerCase()}</span>
							) : null}
						</span>{' '}
						<span>{t('esign.toFollowingRecipients').toLocaleLowerCase()}</span>:
					</p>

					{/* LIST OF INVOLVEES */}
					<div id="involvee-list-wrap">
						{esign.involved.map(person => {
							const personClassNames = ['involvee-row'];
							if (person.sign || signedByCounterpart(person)) {
								personClassNames.push('signed');
							}
							if (person.declineDate) {
								personClassNames.push('rejected');
							}

							return (
								<div key={person.id} className={personClassNames.join(' ')} data-testid="involvee-row">
									{/* AVATAR */}
									<Avatar
										size={'md'}
										type="round"
										className={classes.elem('avatar').b()}
										initials={`${person.fstname} ${person.sndname}`}
										email={person.email}
									/>

									{/* NAME */}
									<h3>
										<span>
											{person.fstname} {person.sndname}
										</span>{' '}
										{person.seen ? (
											<Tooltip
												position="top"
												title={`${t('esign.viewed')} ${getDateTimeString(person.seen)}`}
											>
												<b className="fa fa-eye text-dark-grey"></b>
											</Tooltip>
										) : null}
										{person.undelivered ? (
											<Tooltip
												position="top"
												title={t('esign.undelivered.' + person.undelivered)}
											>
												<b className="fa fa-warning text-orange text-dark-grey"></b>
											</Tooltip>
										) : null}
									</h3>

									{/* INFO */}
									{person.declineDate && person.declineMsg ? (
										<Tooltip
											html
											position="top"
											title={renderToString(
												<>
													<b>{t('esign.recipientMsg')}</b>
													<br />
													<i>{person.declineMsg}</i>
												</>
											)}
										>
											<b className="row-info fa fa-info-circle"></b>
										</Tooltip>
									) : null}

									{view ? (
										/* SUBTITLE CONFIRM */
										<div className="subtitle">
											<div className="signed-info">
												{/* Signed by counterpart */}
												{signedByCounterpart(person) ? (
													<span>
														<b className="fa fa-check"></b>{' '}
														<span>{t('esign.signedByCounterpart')}</span>
													</span>
												) : null}

												{/* SIGNED */}
												{person.sign ? (
													<span>
														<b className="fa fa-check"></b>{' '}
														<span>{t('esign.didSign')}</span>{' '}
														<span>{getDateTimeString(person.sign)}</span>
														{person.authMethod === 'eleg' ? (
															<span>{t('esign.withBankId').toLocaleLowerCase()}</span>
														) : null}
													</span>
												) : null}

												{/* REJECTED */}
												{person.declineDate ? (
													<span>
														<b className="fa fa-times"></b>{' '}
														<span>{t('esign.rejected')}</span>
													</span>
												) : null}

												{/* NOT SIGNED */}
												{!person.sign &&
												!person.declineDate &&
												!signedByCounterpart(person) &&
												!(unsignedUsers && person.contactId) ? (
													<span>{t('esign.waitingForSign')}</span>
												) : null}

												{/* WAITING FOR USERS */}
												{unsignedUsers &&
												person.contactId &&
												!person.sign &&
												!signedByCounterpart(person) ? (
													<span>{t('esign.waitingForUsersToSign')}</span>
												) : null}
											</div>

											{/* INFO */}
											<div className="involvee-info">
												<span>
													{unsignedUsers && person.contactId
														? t('esign.sendTo')
														: t('esign.sentTo')}
												</span>
												: <span className="text-black">{person.email}</span>
												{person.deliveryMethod === 'email_mobile' ? (
													<span>
														, <span className="text-black">{person.mobile}</span>
													</span>
												) : null}
											</div>
										</div>
									) : (
										/* SUBTITLE VIEW */
										<div className="subtitle">
											{/* INFO */}
											<div className="involvee-info">
												<span>{t('esign.sendTo')}</span>:{' '}
												<span className="text-black">{person.email}</span>
												{person.deliveryMethod === 'email_mobile' ? (
													<span>
														, <span className="text-black">{person.mobile}</span>
													</span>
												) : null}
												<br />
												<b className="fa fa-check"></b>{' '}
												{person.authMethod === 'standard' ? (
													<span>{t('esign.isSigning')}</span>
												) : null}
												{person.authMethod === 'eleg' ? (
													<span>{t('esign.isSigningBankId')}</span>
												) : null}
											</div>
										</div>
									)}
								</div>
							);
						})}
					</div>

					{/* COUNT SIGNED */}
					{view ? (
						<div className="signed-count" data-testid="signed-count">
							{signCount ? (
								<span>
									{`${signCount}/${esign.involved.length} ${t(
										'esign.hasSigned'
									).toLocaleLowerCase()}`}
								</span>
							) : (
								<span>{t('esign.nobodySigned')}</span>
							)}
						</div>
					) : null}
				</ModalContent>

				{/* CTRLS */}
				<ModalControls>
					{/* CANCELED INFO */}
					{esign.state === 40 ? (
						<span className="info text-red-i pull-left">{t('esign.wasCanceled')}</span>
					) : null}

					{view && esign.state !== 40 && esign.state !== 30 && esign.state !== 20 && esign.version >= 2 ? (
						<Tooltip className="pull-left" position="top" title={t('esign.cancelingAlsoDeletesIt')}>
							{/* REVOKE BTN */}
							<button type="button" className="up-btn btn-link btn-red" onClick={() => cancel()}>
								{t('esign.revokeEsign')}
							</button>
						</Tooltip>
					) : null}

					{/* COPY BTN */}
					{view && esign.version >= 2 && (esign.state === 20 || esign.state === 30 || esign.state === 40) ? (
						<button type="button" className="up-btn btn-green no-shadow btn-lined" onClick={() => copy()}>
							{t('esign.copyToNewEsign')}
						</button>
					) : null}

					{/* SEND BTN */}
					{!view && documentType !== 'html' ? (
						<button
							type="button"
							className="up-btn btn-green no-shadow main-action"
							onClick={() => confirm()}
						>
							{t('mail.send')}
						</button>
					) : null}

					{/* OPEN IFRAME BTN */}
					{documentType === 'html' ? (
						<button
							type="button"
							onClick={() => openHtmlFrame()}
							className="btn up-btn btn-green no-shadow main-action"
						>
							{t('esign.viewEsign')}
						</button>
					) : null}

					{/* GO TO LIST BTN */}
					{view && showListBtn ? (
						<button
							type="button"
							className="up-btn btn-green no-shadow btn-lined"
							onClick={() => goToList()}
						>
							{t('esign.showAllEsigns')}
						</button>
					) : null}

					{/* ABORT BTN */}
					<button type="button" className="up-btn btn-link btn-grey" onClick={() => close(false)}>
						{view ? t('default.close') : t('default.abort')}
					</button>
				</ModalControls>
			</form>
		</Modal>
	);
};

export default ConfirmEsign;
