import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button, Input } from '@upsales/components';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import { openFileBrowserModal } from 'Components/FileBrowser';

const getImgWidth = function (src, cb) {
	const img = document.createElement('img');
	img.src = src;
	img.onload = () => {
		cb(img.width);
	};

	img.onerror = () => {
		cb(0);
	};
};

class MailContentEditorSelectImage extends React.Component {
	constructor(p) {
		super(p);

		const t = getAngularModule('$translate');

		this.lang = {
			selectImage: t('mail.selectImage')
		};

		this.state = {
			src: this.props.value,
			loading: false
		};

		this.selectImage = () => {
			openFileBrowserModal({ selectOnUpload: true, public: true, types: ['image'] })
				.then(selectedImages => {
					if (selectedImages.length) {
						const src = selectedImages[0].$$publicUrl;
						this.setState({ src });
						// get img real width
						getImgWidth(src, realWidth => {
							const allowedWidth = this.props.column.initialFixedWidth - this.props.column.hPadding * 2;
							const imageWidth = realWidth > allowedWidth ? allowedWidth : realWidth;
							this.props.onChange({ src, realWidth, imageWidth });
						});
					}
				})
				.catch(err => {
					console.error(err);
				});
		};

		let onChangeTimeout;
		this.onInputChange = src => {
			this.setState({ src });
			this.props.onChange({ src });
			if (onChangeTimeout) {
				clearTimeout(onChangeTimeout);
			}
			onChangeTimeout = setTimeout(() => {
				this.setState({ loading: true });
				getImgWidth(src, realWidth => {
					const allowedWidth = this.props.column.initialFixedWidth - this.props.column.hPadding * 2;
					const imageWidth = realWidth > allowedWidth ? allowedWidth : realWidth;
					this.setState({ loading: false });
					this.props.onChange({ src, realWidth, imageWidth });
				});
			}, 200);
		};
	}

	shouldComponentUpdate(nextProps) {
		return this.state.src !== nextProps.value;
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.src !== nextProps.value) {
			return { src: nextProps.value };
		}

		return null;
	}

	render() {
		return (
			<div>
				<ButtonGroup block={true}>
					<Button className="ButtonGroup__no-flex" shadow="none" onClick={this.selectImage} type="lined">
						{this.lang.selectImage}
					</Button>
					<Input value={this.state.src} onChange={e => this.onInputChange(e.target.value)} />
				</ButtonGroup>
			</div>
		);
	}
}

MailContentEditorSelectImage.propTypes = {
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired
};

MailContentEditorSelectImage.defaultProps = {
	onChange: () => {},
	value: ''
};

export default MailContentEditorSelectImage;
