import React from 'react';
import PropTypes from 'prop-types';
import Bem from '@upsales/components/Utils/bemClass';
import './WistiaVideo.scss';
import { Icon } from '@upsales/components';
import { openGenericModal } from 'App/components/GenericModal/GenericModal';

const WistiaVideo = ({ video, height, width, reject }) => {
	const WistiaVideoClass = new Bem('WistiaVideo');

	const defaultHeight = '100%';
	const defaultWidth = '100%';
	return (
		<div className={WistiaVideoClass}>
			<div className={WistiaVideoClass.elem('Reject')} onClick={() => reject()}>
				<Icon name="remove" />
			</div>
			<iframe
				style={{ border: 'none' }}
				src={`//fast.wistia.net/embed/iframe/${video}`}
				allowTransparency="true"
				frameBorder={0}
				scrolling="no"
				className="wistia_embed"
				name="wistia_embed"
				allowFullScreen
				mozallowFullscreen
				webkitallowFullscreen
				oallowFullscreen
				msallowFullscreen
				width={width || defaultWidth}
				height={height || defaultHeight}
			/>
		</div>
	);
};

WistiaVideo.propTypes = {
	video: PropTypes.string,
	height: PropTypes.number,
	width: PropTypes.number,
	reject: PropTypes.func
};

const EmbeddedVideoComponent = function ({
	videoId,
	thumbnail,
	cropResize = '130x80',
	buttonSize = '1x',
	buttonColor = '54bbffe0'
}) {
	const EmbeddedVideo = new Bem('EmbeddedVideo');

	return (
		<img
			className={EmbeddedVideo}
			onClick={() => {
				openGenericModal({
					Component: WistiaVideo,
					video: videoId,
					thumbnail: thumbnail,
					constantHeight: 500,
					/* Styles for angular modal */
					inlineStyle: {
						width: '890px',
						height: '500px',
						marginLeft: '-445px',
						top: '180px',
						zIndex: '9999'
					},
					/* Styles for react modal */
					style: {
						width: '890px',
						height: '500px'
					}
				});
			}}
			src={`${thumbnail}?image_play_button=1&amp;image_play_button_color=${buttonColor}&amp;image_crop_resized=${cropResize}&amp;image_play_button_size=${buttonSize}`}
		/>
	);
};

EmbeddedVideoComponent.propTypes = {
	videoId: PropTypes.string,
	thumbnail: PropTypes.string,
	cropResize: PropTypes.string,
	buttonSize: PropTypes.string,
	buttonColor: PropTypes.string
};

export const EmbeddedVideo = EmbeddedVideoComponent;

export default WistiaVideo;
