import { Button, Card, Icon, Label, Modal, ModalContent, ModalControls, ModalHeader, Text } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import AccountSelect from 'Components/Account/AccountSelect/AccountSelect';
import React, { useEffect, useRef, useState } from 'react';
import { ModalProps } from 'App/components/Modals/Modals';
import T from 'Components/Helpers/translate';
import logError from 'Helpers/logError';
import Client from 'App/resources/Model/Client';
import './MergeClients.scss';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';

interface Props extends ModalProps {
	title?: string;
	icon?: React.ComponentProps<typeof Icon>['name'];
	saveText?: string;
	closeText?: string;
	textareaTooltip?: string;
	onClose?: () => void;
	id: number;
	customerId: number;
	meta: { account: { data: { id: number; name: string } } };
}

const Component = (props: Props) => {
	const [isSaving, setIsSaving] = useState(false);
	const [selectedAccount, setSelectedAccount] = useState<Client>();
	const cancelRef = useRef<CancelablePromise | null>(null);

	useEffect(() => {
		return () => {
			if (cancelRef.current) {
				cancelRef.current.cancel();
			}
		};
	}, []);

	const account = props.meta.account.data;

	const onChangeAccountSelect = (account: Client) => {
		setSelectedAccount(account);
	};

	const merge = () => {
		const account = props.meta.account.data;
		if (!account || !selectedAccount) {
			return;
		}
		setIsSaving(true);
		cancelRef.current = makeCancelable(Tools.Account.customer(props.customerId).merge(account, selectedAccount));
		cancelRef.current.promise
			.then(() => {
				props.close();
			})
			.catch((err: string) => {
				logError(err);
			});
	};

	const mergeBtnText = T('account.mergeDataTo', { companyName: selectedAccount ? account.name : '' });

	return (
		<div>
			<ModalHeader icon="exchange" title={T('account.mergeClients')} onClose={props.close} />
			<ModalContent>
				<Label>
					{T('default.merge')} {account.name} {T('default.from').toLowerCase()}
				</Label>
				<AccountSelect
					customerId={props.customerId}
					accountId={account.id}
					onChange={onChangeAccountSelect}
					autoFocus={true}
				/>
				<Text>{`${T('account.mergeInfo1')} ${account.name}.`}</Text>
				<Card borderColor="bright-blue" border="lm">
					<Text>{T('account.mergeInfo2')}</Text>
				</Card>
			</ModalContent>
			<ModalControls style={{ textAlign: 'right' }}>
				<Button color="bright-blue" onClick={merge} loading={isSaving} disabled={isSaving || !selectedAccount}>
					{mergeBtnText}
				</Button>
				<Button type="link" color="grey" onClick={() => props.close()}>
					{T('default.abort')}
				</Button>
			</ModalControls>
		</div>
	);
};

interface PropsAngular {
	$modalParams: Props;
	resolve: () => void;
	reject: () => void;
}

const MergeClients = <TProps extends Props | PropsAngular>(props: TProps) => {
	if (props.hasOwnProperty('$modalParams')) {
		const classes = new BemClass('MergeClients');
		return (
			<div className={classes.b()}>
				{Component({
					...props.$modalParams,
					onClose: () => {},
					className: '',
					modalId: 0
				})}
			</div>
		);
	} else {
		return (
			<Modal size="xl" className={`${props.className} MergeClients`}>
				{Component(props)}
			</Modal>
		);
	}
};

export default MergeClients<Props>;
