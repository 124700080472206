import { openSharedViewsModal } from 'Components/Admin/SharedViews/SharedViewsModal';

const initialState = {
	current: null,
	list: [],
	loading: true,
	total: 0,
	title: '',
	sharedList: [],
	privateList: [],
	isHiddenView: false,
	hiddenViewType: null,
	isSaveView: false,
	hiddenViewSearchString: '',
	adminSearchString: '',
	createView: {},
	allListViews: [],
	isExpanded: false,
	type: null,
	closeDropdown: false,
	selectedView: null,
	isReport: false,
	modalView: null,
	isSaving: false
};

const SET_DATA = '[Shared Views] SET_DATA';
const LOADING_DONE = '[Shared Views] LOADING_DONE';
const SHOW_HIDDEN_VIEW = '[Shared Views] SHOW_HIDDEN_VIEW';
const SHOW_REGULAR_VIEW = '[Shared Views] SHOW_REGULAR_VIEW';
const SET_HIDDEN_SEARCH = '[Shared Views] SET_HIDDEN_SEARCH';
const SET_VIEW_TO_SAVE = '[Shared Views] SET_VIEW_TO_SAVE';
const CREATE_VIEW_ONCHANGE = '[Shared Views] CREATE_VIEW_ONCHANGE';
const GET_ALL_LIST_VIEWS = '[Shared Views] GET_ALL_LIST_VIEWS';
const SET_ADMIN_SEARCH = '[Shared Views] SET_ADMIN_SEARCH';
const CLOSE_VIEW = '[Shared Views] CLOSE_VIEW';

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_DATA:
			return { ...state, ...action.data };
		case GET_ALL_LIST_VIEWS:
			return { ...state, allListViews: action.data };
		case LOADING_DONE:
			return { ...state, loading: false };
		case SHOW_HIDDEN_VIEW:
			return { ...state, isHiddenView: true, hiddenViewType: action.listType };
		case SHOW_REGULAR_VIEW:
			return { ...state, isHiddenView: false, hiddenViewSearchString: '', isSaveView: false };
		case SET_HIDDEN_SEARCH:
			return { ...state, hiddenViewSearchString: action.value };
		case SET_ADMIN_SEARCH:
			return { ...state, adminSearchString: action.value };
		case CLOSE_VIEW:
			return { ...state, closeDropdown: action.value };
		case SET_VIEW_TO_SAVE:
			return { ...state, isSaveView: true, createView: action.createView };
		case CREATE_VIEW_ONCHANGE:
			return { ...state, createView: { ...state.createView, [action.key]: action.value } };
		default:
			return state;
	}
};

export default reducer;

export const initView = props => (dispatch, getState) => {
	// TODO: This should not have to store list views in its own store. Ticket created
	const { listViews, reportViews } = getState().App;

	let list;
	if (props.isReport) {
		list = reportViews[props.type] ?? [];
	} else {
		list = listViews[props.type] ?? [];
	}
	const data = { ...props, list: [...list] };
	data.sharedList = _.remove(data.list, view => view.shared === true);
	data.privateList = _.remove(data.list, view => view.private === true);
	data.isReport = props.isReport ? props.isReport : false;

	dispatch({ type: SET_DATA, data });

	if (getState().SharedViews.loading === true) {
		dispatch({ type: LOADING_DONE });
	}
};

export const createViewChanged = (key, value) => dispatch => dispatch({ type: CREATE_VIEW_ONCHANGE, key, value });

export const closeEntireView = () => (dispatch, getState) =>
	dispatch({ type: CLOSE_VIEW, value: !getState().SharedViews.closeDropdown });

/** @type {(opts: {editView: ListViewType, createNew?: boolean}) => (dispatch: any, getState: any) => any} */
export const showSaveView =
	({ editView, createNew = false }) =>
	(dispatch, getState) => {
		const { selectedView } = getState().SharedViews;
		let createView = editView ? { ...editView } : { ...selectedView };
		createView.userlist = [];

		if (createNew || (createView.id && !!parseInt(createView.id) === false)) {
			createView.title = '';
		}

		if (createNew) {
			delete createView.id;
			createView = resetView(createView);
			createView.regBy = Tools.AppService.getSelf().id;
		}

		if (!createView.hasOwnProperty('roles') && !createView.hasOwnProperty('users')) {
			return dispatch({ type: SET_VIEW_TO_SAVE, createView });
		}

		for (const item of createView.roles) {
			for (const role of Tools.AppService.getRoles()) {
				if (role.id === item) {
					const copiedRole = Object.assign({}, role);
					copiedRole.id = `role-${item}`;
					createView.userlist.push(copiedRole);
				}
			}
		}

		for (const item of createView.users) {
			for (const user of Tools.AppService.getUsers()) {
				if (user.id === item) {
					createView.userlist.push(user);
				}
			}
		}

		dispatch({ type: SET_VIEW_TO_SAVE, createView });
	};

export const showHiddenView = type => dispatch => dispatch({ type: SHOW_HIDDEN_VIEW, listType: type });

export const setView = view => dispatch => dispatch({ type: SET_DATA, data: { selectedView: view } });

export const showRegularView = () => dispatch => {
	dispatch({ type: SHOW_REGULAR_VIEW });
};

export const setSearchString = value => dispatch => {
	dispatch({ type: SET_HIDDEN_SEARCH, value });
};

export const showView = row => dispatch => {
	row.hidden = false;
	dispatch(genericUpdateView(row));
};

export const hideView = row => dispatch => {
	row.hidden = true;
	dispatch(genericUpdateView(row));
};

function resetView(original) {
	return Object.assign({}, original, {
		roles: [],
		users: [],
		userlist: [],
		shared: false,
		private: true,
		hidden: false,
		default: false,
		description: ''
	});
}

export const setIsSaving = value => dispatch => {
	dispatch({ type: SET_DATA, data: { isSaving: value } });
};

export const saveView =
	(viewInfo, view, shouldResetView = true, returnView = false, comesFromAdminModal = false) =>
	async (dispatch, getState) => {
		dispatch(setIsSaving(true));

		const { type, isReport } = getState().SharedViews;
		const mergedView = Object.assign({}, shouldResetView ? resetView(view) : view, viewInfo);
		const users = [],
			roles = [];

		mergedView.type = type || mergedView.type;
		if (mergedView.type === 'client') {
			mergedView.type = 'account';
		}

		if (mergedView.type === 'salesboard') {
			mergedView.standard = false;
		}

		if (mergedView.userlist) {
			if (mergedView.userlist.length > 0) {
				mergedView.shared = true;
				mergedView.private = false;
				mergedView.hidden = false;
			} else {
				mergedView.private = true;
				mergedView.hidden = false;
			}

			for (const item of mergedView.userlist) {
				// if the item object have the parent property
				// we know that it is a role.
				if (item.hasOwnProperty('parent')) {
					if (isNaN(item.id)) {
						roles.push(parseInt(item.id.replace('role-', '')));
					} else {
						roles.push(item.id);
					}
				} else {
					users.push(item.id);
				}
			}

			mergedView.users = users;
			mergedView.roles = roles;
			delete mergedView.userlist;
		}

		// delete the id if this is a standard list view as these should be untouched..
		if (mergedView.id && !!parseInt(mergedView.id) === false) {
			delete mergedView.id;
			mergedView.default = false;
		}

		if (comesFromAdminModal) {
			// delete the hidden to not trigger the
			// afterSave replace into..
			delete mergedView.hidden;
		}

		const originalType = mergedView.type;
		let updatedView;

		if (isReport) {
			const savedPromise = await Tools.ReportView.setEntityType(mergedView.type).save(mergedView);
			updatedView = savedPromise.data;
		} else {
			updatedView = await Tools.ListViewService.save(
				mergedView.type,
				mergedView,
				Tools.AppService.getCustomerId()
			);
		}

		updatedView.type = originalType;
		await updateListViews({
			view: updatedView,
			isReport
		});

		dispatch(showRegularView());
		dispatch(refreshListViews(updatedView.type));
		dispatch(setIsSaving(false));

		if (returnView) {
			return updatedView;
		}
	};

export const deleteView = view => async (dispatch, getState) => {
	const { isReport } = getState().SharedViews;
	const type = view.type;

	if (isReport) {
		await Tools.ReportView.setEntityType(type).delete(view);
	} else {
		await Tools.ListViewService.delete(type, view, Tools.AppService.getCustomerId());
	}

	await removeListViewFromAppService(type, view, isReport);
	dispatch(refreshListViews(type));
};

export const openModal = view => dispatch => {
	openSharedViewsModal(view);
	dispatch(setModalView(view));
};

export function setModalView(view, updateAllViews = false) {
	return (dispatch, getState) => {
		dispatch({
			type: SET_DATA,
			data: {
				modalView: view
			}
		});

		if (updateAllViews) {
			const { allListViews } = getState().SharedViews;
			const updatedListViews = [
				...allListViews.map(item => {
					if (item.id === view.id) {
						return { ...view };
					}

					return item;
				})
			];

			dispatch({
				type: SET_DATA,
				data: { allListViews: updatedListViews }
			});
		}
	};
}

export const mapUsersAndRoles = view => {
	const availableRoles = Tools.AppService.getRoles();
	const availableUsers = Tools.AppService.getUsers();

	if (view.regBy) {
		const foundUser = _.find(availableUsers, { id: view.regBy });

		if (foundUser) {
			view.regBy = Object.assign({}, foundUser);
		}
	}

	if (view.roles && view.roles.length) {
		view.roles = view.roles.map(roleId => {
			const foundRole = _.find(availableRoles, { id: roleId });

			if (foundRole) {
				return foundRole;
			}

			return roleId;
		});
	}

	if (view.users && view.users.length) {
		view.users = view.users.map(userId => {
			const foundUser = _.find(availableUsers, { id: userId });

			if (foundUser) {
				return foundUser;
			}

			return userId;
		});
	}

	return { ...view };
};

export const getAllListViewsExceptStandard = () => async dispatch => {
	const response = await Tools.ListViews.getShared();
	const views = response.data.map(view => {
		return mapUsersAndRoles(view);
	});

	dispatch({
		type: GET_ALL_LIST_VIEWS,
		data: views
	});
};

export const adminSearchString = keyword => ({
	type: SET_ADMIN_SEARCH,
	value: keyword
});

export const makeOwner = (view, user) => dispatch => {
	view.regBy = user.id;

	dispatch(genericUpdateView(view));
};

export const makeDefault = row => async dispatch => {
	row.default = !row.default;
	dispatch(genericUpdateView(row, [{ default: false }]));
};

function genericUpdateView(view, extraUpdates) {
	return async (dispatch, getState) => {
		const { isReport } = getState().SharedViews;

		let updatedView;

		if (isReport) {
			const result = await Tools.ReportView.setEntityType(view.type).save(Object.assign({}, view));
			updatedView = result.data;
		} else {
			updatedView = await Tools.ListViewService.save(
				view.type,
				Object.assign({}, view),
				Tools.AppService.getCustomerId()
			);
		}

		updateListViews({
			view: updatedView,
			extra: extraUpdates,
			isReport
		});

		dispatch(refreshListViews(updatedView.type));
	};
}

function refreshListViews(type) {
	return (dispatch, getState) => {
		const { isReport } = getState().SharedViews;
		dispatch(initView({ isReport, type }));
	};
}

function updateListViews({ view, extra, isReport }) {
	if (view && isReport === false) {
		Tools.AppService.setListView(view.type, view, extra);
	} else {
		Tools.AppService.setReportViews(view.type, view, extra);
	}
}

function removeListViewFromAppService(type, view, isReport) {
	if (isReport) {
		Tools.AppService.purgeReportViews(type, view);
	} else {
		Tools.AppService.purgeListViews(type, view);
	}
}
