import React, { FC, Fragment } from 'react';

import { t } from 'i18next';
import { Icon, Modal, ModalContent, ModalControls, ModalHeader, Tooltip } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';

import { ModalProps } from '../Modals/Modals';
import './SelectFieldModal.scss';

type Field = {
	title: string;
	name: string;
	datatype: string;
	type: string;
	disabled: boolean;
	optInType?: string;
	options?: string;
};

type Datatype = {
	title: string;
	value: string;
	classNames: string;
};

type Group = {
	title: string;
	fields: Field[];
	sort: number;

	disabled?: boolean;
};

type AvailableOptIns = {
	id: number;
	type: string;
	title: string;
	approvedCount: number;
	text: string;
	terms: string | null;
	emailTitle: string;
	emailDescription: string;
	emailLinkText: string;
	emailExplanation: string;
	landingpageTitle: string;
	landingpageDescription: string;
};

type SelectFieldModalProps = ModalProps<Field> & {
	availableOptIns: { [key: string]: AvailableOptIns[] };
	availableFields: Field[];
};

const SelectFieldModal: FC<SelectFieldModalProps> = ({ availableOptIns, availableFields, close, ...props }) => {
	const datatypes: Datatype[] = [
		{ title: 'datatype.Text', value: 'text', classNames: 'fa fa-font' },
		{ title: 'datatype.Date', value: 'date', classNames: 'fa fa-calendar-o' },
		{ title: 'datatype.Number', value: 'number', classNames: 'fa fa-dollar' },
		{ title: 'datatype.Checkbox', value: 'checkbox', classNames: 'fa fa-check-square-o' },
		{ title: 'datatype.Select', value: 'select', classNames: 'fa fa-caret-square-o-down' },
		{ title: 'datatype.Radio', value: 'radio', classNames: 'fa fa-dot-circle-o' },
		{ title: 'datatype.Textarea', value: 'textarea', classNames: 'fa fa-square-o' }
	];

	if (Tools.FeatureHelper.hasSoftDeployAccess('FORM_PASSWORD')) {
		datatypes.push({
			title: 'datatype.Password',
			value: 'password',
			classNames: 'fa fa-lock'
		});
	}

	const selectType = function (typeObj: Datatype) {
		close({
			title: t(typeObj.title),
			name: 'Extra',
			datatype: typeObj.value,
			type: 'extra',
			disabled: false
		});
	};

	var groups: { [key: string]: Group } = {
		Client: { title: 'form.client.standard', fields: [], sort: 1 },
		ClientCustom: { title: 'form.client.cf', fields: [], sort: 2 },
		Contact: { title: 'form.contact.standard', fields: [], sort: 3 },
		ContactCustom: { title: 'form.contact.cf', fields: [], sort: 4 },
		NotAvailable: { title: 'form.contact.notAvailable.forms', fields: [], sort: 5, disabled: true }
	};
	if (Object.values(availableOptIns).some(arr => arr.length > 0)) {
		var fields = Object.entries(availableOptIns)
			.reduce((acc: Field[], [type, arr]) => {
				if (arr.length > 0) {
					acc.push({
						title: 'form.' + type + 'OptIn',
						name: type + 'OptIn.' + Date.now(),
						datatype: 'optIn',
						type: 'standard',
						optInType: type,
						disabled: false
					});
				}
				return acc;
			}, [])
			.sort((a, b) => {
				return (a.optInType === 'single' ? -1 : 1) - (b.optInType === 'single' ? -1 : 1);
			});

		groups.OptIn = { title: 'form.optIn', fields, sort: 0 };
	}

	availableFields.forEach(function (field) {
		if (field.datatype === 'user' || field.datatype === 'users') {
			groups.NotAvailable.fields.push(field);
		} else if (field.name.indexOf('Client.custom_') === 0) {
			groups.ClientCustom.fields.push(field);
		} else if (field.name.indexOf('Client.') === 0) {
			groups.Client.fields.push(field);
		} else if (field.name.indexOf('Contact.custom_') === 0) {
			groups.ContactCustom.fields.push(field);
		} else if (field.name.indexOf('Contact') === 0) {
			groups.Contact.fields.push(field);
		}
	});

	const classes = new BemClass('SelectFieldModal', props.className);

	return (
		<Modal className={classes.b()} size="sm" id="select-field-modal">
			<ModalHeader onClose={close} icon="tag" title={t('form.addField')} alignContent="left">
				<Tooltip title={t('form.fieldInfo')}>
					<Icon name="question-circle"></Icon>
				</Tooltip>
			</ModalHeader>
			<ModalContent>
				<div>
					{datatypes.map(type => (
						<div
							key={type.title}
							className="select-field-datatype"
							onClick={() => {
								selectType(type);
							}}
						>
							<b className={type.classNames}></b>
							<span>{t(type.title)}</span>
						</div>
					))}
				</div>
				<table className="main-table">
					{Object.values(groups)
						.sort((a, b) => a.sort - b.sort)
						.map(group => (
							<Fragment key={group.title}>
								<thead>
									<tr>
										<th className={`th-inner ${group.disabled && 'main-table-disabled'}`}>
											{t(group.title)}
										</th>
									</tr>
								</thead>
								{!group.disabled
									? group.fields.map(field => (
											<tbody key={field.name}>
												<tr className="selectable" onClick={() => close(field)}>
													<td>{t(field.title)}</td>
												</tr>
											</tbody>
									  ))
									: null}
								{group.disabled
									? group.fields.map(field => (
											<tbody key={field.title}>
												<tr className="selectable">
													<td className="main-table-disabled">
														{field.title}
														<span>
															<i className="fa fa-lock"></i>
														</span>
													</td>
												</tr>
											</tbody>
									  ))
									: null}
							</Fragment>
						))}
				</table>
			</ModalContent>
			<ModalControls className="up-modal-controls pior">
				<button type="button" className="btn btn-bright-blue btn-link up-btn" onClick={() => close()}>
					{t('default.abort')}
				</button>
			</ModalControls>
		</Modal>
	);
};

export default SelectFieldModal;
