import type IndexIncreaseSettings from 'App/resources/Model/IndexIncreaseSettings';
import { IndexConflictDecision } from 'App/resources/Model/IndexIncreaseSettings';

export type State = IndexIncreaseSettings & {
	setAsLocked: boolean;
	isSaving: boolean;
	isDirty: boolean;
	isPassed: boolean;
	totalIncreaseARR: number;
	excludingSubscriptions: boolean;
	isLoadingSubscriptions: boolean;
	hasConflicts: boolean;
	indexConflictDecisionsMap: { [key: number]: IndexConflictDecision };
};

export enum Actions {
	EXCLUDE_MULTIPLE_SUBSCRIPTIONS = 'EXCLUDE_MULTIPLE_SUBSCRIPTIONS',
	SET_CONFLICTING_SUBSCRIPTIONS = 'SET_CONFLICTING_SUBSCRIPTIONS',
	SET_IS_LOADING_SUBSCRIPTIONS = 'SET_IS_LOADING_SUBSCRIPTIONS',
	SET_EXCLUDING_SUBSCRIPTIONS = 'SET_EXCLUDING_SUBSCRIPTIONS',
	TOGGLE_SPECIFIC_RECIPIENT = 'TOGGLE_SPECIFIC_RECIPIENT',
	SET_SUBSCRIPTION_FILTER = 'SET_SUBSCRIPTION_FILTER',
	TOGGLE_INDEXING_ENABLED = 'TOGGLE_INDEXING_ENABLED',
	SET_SHOULD_CREATE_DIFF = 'SET_SHOULD_CREATE_DIFF',
	SET_SUBSCRIPTION_VALUE = 'SET_SUBSCRIPTION_VALUE',
	SET_SUBSCRIPTION_COUNT = 'SET_SUBSCRIPTION_COUNT',
	SET_SUBSCRIPTION_NOTE = 'SET_SUBSCRIPTION_NOTE',
	SET_CONFLICT_DECISION = 'SET_CONFLICT_DECISION',
	TOGGLE_SPECIFIC_DATE = 'TOGGLE_SPECIFIC_DATE',
	INCLUDE_SUBSCRIPTION_ID = 'INCLUDE_SUBSCRIPTION_ID',
	EXCLUDE_SUBSCRIPTION_ID = 'EXCLUDE_SUBSCRIPTION_ID',
	SET_SPECIFIC_DATE_OLD = 'SET_SPECIFIC_DATE_OLD',
	SET_INDEX_PERCENTAGE = 'SET_INDEX_PERCENTAGE',
	SET_ACCOUNT_FILTER = 'SET_ACCOUNT_FILTER',
	SET_INDEX_INTERVAL = 'SET_INDEX_INTERVAL',
	SET_HAS_CONFLICTS = 'SET_HAS_CONFLICTS',
	SET_SPECIFIC_DATE = 'SET_SPECIFIC_DATE',
	SET_SPECIFIC_USER = 'SET_SPECIFIC_USER',
	SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS',
	SET_ORDER_NOTE = 'SET_ORDER_NOTE',
	SET_AS_LOCKED = 'SET_AS_LOCKED',
	TOGGLE_SAVING = 'TOGGLE_SAVING',
	SET_UI_FILTER = 'SET_UI_FILTER',
	TOGGLE_DIRTY = 'TOGGLE_DIRTY',
	TOGGLE_LOCK = 'TOGGLE_LOCK',
	SET_STATE = 'SET_STATE',
	TOGGLE_LOCK_OLD = 'TOGGLE_LOCK_OLD',
	SET_FILTER_Q = 'SET_FILTER_Q',
	SET_NAME = 'SET_NAME'
}

export type SetConflictingSubscriptions = {
	type: Actions.SET_CONFLICTING_SUBSCRIPTIONS;
	payload: State['indexConflictDecisionsMap'];
};
export type SetIsLoadingSubscriptions = {
	type: Actions.SET_IS_LOADING_SUBSCRIPTIONS;
	payload: State['isLoadingSubscriptions'];
};
export type SetIsSpecificRecipient = { type: Actions.TOGGLE_SPECIFIC_RECIPIENT; payload: State['isSpecificRecipient'] };
export type SetSubscriptionFilter = { type: Actions.SET_SUBSCRIPTION_FILTER; payload: State['subscriptionFilter'] };
export type SetSubscriptionCount = { type: Actions.SET_SUBSCRIPTION_COUNT; payload: State['subscriptionCount'] };
export type SetExcludeMultipleSubscriptions = { type: Actions.EXCLUDE_MULTIPLE_SUBSCRIPTIONS; payload: number[] };
export type SetSubscriptionValue = { type: Actions.SET_SUBSCRIPTION_VALUE; payload: State['subscriptionValue'] };
export type SetShouldCreateDiff = { type: Actions.SET_SHOULD_CREATE_DIFF; payload: State['shouldCreateDiff'] };
export type SetIndexingEnabled = { type: Actions.TOGGLE_INDEXING_ENABLED; payload: State['indexingEnabled'] };
export type SetSubscriptionNote = { type: Actions.SET_SUBSCRIPTION_NOTE; payload: State['subscriptionNote'] };
export type SetIndexPercentage = { type: Actions.SET_INDEX_PERCENTAGE; payload: State['indexPercentage'] };
export type SetConflictDecision = { type: Actions.SET_CONFLICT_DECISION; payload: IndexConflictDecision };
export type SetIsSpecificDate = { type: Actions.TOGGLE_SPECIFIC_DATE; payload: State['isSpecificDate'] };
export type SetExcludingSubscriptions = { type: Actions.SET_EXCLUDING_SUBSCRIPTIONS; payload: boolean };
export type SetAccountFilter = { type: Actions.SET_ACCOUNT_FILTER; payload: State['accountFilter'] };
export type SetIndexInterval = { type: Actions.SET_INDEX_INTERVAL; payload: State['indexInterval'] };
export type SetSubscriptions = { type: Actions.SET_SUBSCRIPTIONS; payload: State['subscriptions'] };
/** @deprecated */
export type SetSpecificDateOld = { type: Actions.SET_SPECIFIC_DATE_OLD; payload: State['specificDate'] };
export type SetHasConflicts = { type: Actions.SET_HAS_CONFLICTS; payload: State['hasConflicts'] };
export type SetSpecificDate = { type: Actions.SET_SPECIFIC_DATE; payload: State['specificDate'] };
export type SetSpecificUser = { type: Actions.SET_SPECIFIC_USER; payload: State['specificUser'] };
export type SetIncludeSubscriptionId = { type: Actions.INCLUDE_SUBSCRIPTION_ID; payload: number };
export type SetExcludeSubscriptionId = { type: Actions.EXCLUDE_SUBSCRIPTION_ID; payload: number };
export type SetAsLockedToggle = { type: Actions.SET_AS_LOCKED; payload: State['setAsLocked'] };
export type SetOrderNote = { type: Actions.SET_ORDER_NOTE; payload: State['orderNote'] };
export type SetUIFilter = { type: Actions.SET_UI_FILTER; payload: State['uiFilters'] };
/** @deprecated */
export type SetLockToggleOld = { type: Actions.TOGGLE_LOCK_OLD; payload: State['isLocked'] };
export type SetLockToggle = { type: Actions.TOGGLE_LOCK; payload: State['isLocked'] };
export type SetIsSaving = { type: Actions.TOGGLE_SAVING; payload: State['isSaving'] };
export type SetIsDirty = { type: Actions.TOGGLE_DIRTY; payload: State['isDirty'] };
export type SetState = { type: Actions.SET_STATE; payload: Partial<State> };
export type SetFilterQ = { type: Actions.SET_FILTER_Q; payload: State['filterQ'] };
export type SetName = { type: Actions.SET_NAME; payload: State['name'] };

export type Action =
	| SetExcludeMultipleSubscriptions
	| SetConflictingSubscriptions
	| SetExcludingSubscriptions
	| SetIsLoadingSubscriptions
	| SetIncludeSubscriptionId
	| SetExcludeSubscriptionId
	| SetIsSpecificRecipient
	| SetSubscriptionFilter
	| SetSubscriptionCount
	| SetSubscriptionValue
	| SetConflictDecision
	| SetSubscriptionNote
	| SetShouldCreateDiff
	| SetAsLockedToggle
	| SetIndexPercentage
	| SetIndexingEnabled
	| SetSpecificDateOld
	| SetIsSpecificDate
	| SetLockToggleOld
	| SetAccountFilter
	| SetSubscriptions
	| SetIndexInterval
	| SetHasConflicts
	| SetSpecificDate
	| SetSpecificUser
	| SetLockToggleOld
	| SetLockToggle
	| SetOrderNote
	| SetIsSaving
	| SetUIFilter
	| SetIsDirty
	| SetFilterQ
	| SetState
	| SetName;
