import { Modal, ModalContent, ModalControls, ModalHeader } from '@upsales/components';
import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';
import { useTranslation } from 'Components/Helpers/translate';
import { EventEntityTypes } from 'App/resources/Model/Event';
import BemClass from '@upsales/components/Utils/bemClass';
import { getSelf } from 'Store/selectors/AppSelectors';
import Contact from 'App/resources/Model/Contact';
import Client from 'App/resources/Model/Client';
import { ModalProps } from '../Modals/Modals';
import React, { useState } from 'react';
import './NewMarketEvent.scss';
import { BasicUser } from 'App/resources/Model/User';

type Props = {
	account: Client;
	contact: Contact;
} & ModalProps;

const NewMarketEvent = ({ close, account, contact, className }: Props) => {
	const [saving, setSaving] = useState(false);
	const [score, setScore] = useState<number | null>(null);
	const [description, setDescription] = useState('');
	const user = getSelf();
	const { t } = useTranslation();

	const save = (e: React.FormEvent) => {
		e.preventDefault();
		setSaving(true);
		const newEvent = {
			score: score ?? 0,
			client: { id: account.id, name: account.name },
			entityType: 'Manual' as EventEntityTypes,
			subType: 'ManualAdjustment',
			value: description || '',
			contact: contact ? contact.id : null,
			users: [user as BasicUser],
			entityId: 0
		};

		Tools.Event.save(newEvent)
			.then(function (res) {
				Tools.NotificationService.addNotification({
					title: 'default.saved',
					body: 'saved.marketEvent',
					style: 'Success',
					icon: 'check'
				});
				Tools.$rootScope.$broadcast('market.added', res.data);
				close();
			})
			.catch(function () {
				Tools.NotificationService.addNotification({
					title: 'default.error',
					body: 'saveError.marketEvent',
					style: 'Error',
					icon: 'times'
				});
			})
			.finally(() => setSaving(false));
	};

	const classes = new BemClass('NewMarketEvent', className);

	return (
		<Modal className={classes.b()}>
			<form name="myForm" onSubmit={save}>
				<ModalHeader title={t('default.newMarketEvent')} onClose={() => close()}></ModalHeader>
				<ModalContent>
					<>
						<div className="col-md-12">
							<br />
							{contact ? (
								<p>
									{t('market.newEventFor')} <b>{contact.name}</b> {t('default.at').toLowerCase()}{' '}
									{account.name}
								</p>
							) : (
								<p>
									{t('market.newEventFor')} <b>{account.name}</b>
								</p>
							)}
						</div>
						<div className="col-md-12">
							<div className="form-group">
								<table>
									<tr>
										<td width="20%">
											<label>{t('default.score')}</label>
											<input
												type="number"
												name="field_NewMarketScore"
												id="field_NewMarketScore"
												value={score === null ? '' : score}
												onChange={e => {
													const inputValue = e.target.value;
													setScore(inputValue === '' ? null : Number(inputValue));
												}}
												className="form-control"
												placeholder={t('default.scoreTotal')}
												disabled={saving}
											/>
										</td>
										<td>
											<label>{t('default.description')}</label>
											<input
												type="text"
												name="field_NewMarketDescription"
												id="field_NewMarketDescription"
												value={description}
												onChange={e => setDescription(e.target.value)}
												className="form-control"
												placeholder={t('column.description')}
												disabled={saving}
											/>
										</td>
									</tr>
								</table>
							</div>
						</div>
					</>
				</ModalContent>
				<ModalControls>
					<button
						disabled={saving}
						type="submit"
						className="btn up-btn btn-bright-blue no-shadow btn-primary-action"
					>
						{t('default.save')}
					</button>
					<button
						disabled={saving}
						type="button"
						className="btn up-btn btn-grey btn-link"
						onClick={() => close()}
					>
						{t('default.close')}
					</button>
				</ModalControls>
			</form>
		</Modal>
	);
};

export const openNewMarketEvent = asyncModalAdapter({
	openModalName: 'NewMarketEvent',
	upModalName: 'newMarketEvent',
	featureFlag: 'REACT_NEW_MARKET_EVENT',
	rejectOnEmpty: false
});

export default NewMarketEvent;
