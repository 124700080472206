import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import { ModalProps, useModalClose } from 'App/components/Modals/Modals';
import { init, setLoading } from 'Store/reducers/AccountReducer';
import { NOTIFICATION_TYPES } from 'App/babel/enum/notification';
import React, { useState, useCallback, useEffect, useRef, MutableRefObject } from 'react';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import ClientAttributes from 'App/babel/attributes/Client';
import BemClass from '@upsales/components/Utils/bemClass';
import useGetData from 'App/components/hooks/useGetData';
import type Client from 'App/resources/Model/Client';
import { useSelector } from 'App/components/hooks';
import ClientResource from 'App/resources/Client';
import { Drawer } from '@upsales/components';
import logError from 'Helpers/logError';
import { connect } from 'react-redux';
import FadeWrap from './FadeWrap';
import Loading from './Loading';
import Header from './Header';
import moment from 'moment';
import List from './List';
import Add from './Add';

import './SubaccountDrawer.scss';

export enum SubaccountDrawerView {
	List,
	Add,
	Loading
}

export type DrawerClient = Pick<Client, 'id' | 'name'> & { hasAccess: boolean };

type Props = {
	client: DrawerClient;
	account: Client;
	includingSubaccountData: boolean;
	initAccountReducer: (account: Client, fetchRelations: boolean) => void;
	setLoadingAccount: (loading: boolean) => void;
	defaultView?: SubaccountDrawerView;
} & ModalProps;

type NotificationReduced = {
	action: string;
	status: number;
};

type NotificationResponse = {
	data: NotificationReduced[];
};

const fetcher = (rb: RequestBuilder, { clientId }: { clientId: number }) => {
	rb.addFilter(ClientAttributes.operationalAccount.attr.id, comparisonTypes.Equals, clientId);
	rb.fields = ['id', 'name'];
	return ClientResource.find(rb.build());
};

const SubaccountDrawer = ({
	className,
	close,
	modalId,
	client,
	includingSubaccountData,
	account,
	initAccountReducer,
	setLoadingAccount,
	defaultView = SubaccountDrawerView.List
}: Props) => {
	const classes = new BemClass('SubaccountDrawer', className);
	const [subaccountIdsOnOpen, setSubaccountIdsOnOpen] = useState<number[]>([]);
	const [includeSubaccountData, setIncludeSubaccount] = useState(includingSubaccountData);
	const [view, setView] = useState<SubaccountDrawerView>(SubaccountDrawerView.Loading);
	const [transitionToDefaultView, setTransitionToDefaultView] = useState(false);
	const { loading: isLoadingBranchOffices } = useSelector(state => state.Account);
	const [pendingPurchase, setPendingPurchase] = useState<NotificationReduced | null>(null);
	const notificationsRef = useRef<CancelablePromise>(null) as MutableRefObject<CancelablePromise>;

	const { data: subaccounts } = useGetData({
		broadcastTypes: ['account.updated', 'account.deleted', 'account.subaccountsAdded'],
		broadcastWaitForIndex: true, // IDK why I need this as the client endpoint is running index.update with safe and refresh... but it sometimes doesn't work without it
		fetcher,
		fetcherProps: {
			clientId: client.id
		},
		limit: 1000,
		offset: 0,
		onFetched: ({ data: subaccounts }) => {
			// If it is the initial load we want to do some more stuff
			if (view === SubaccountDrawerView.Loading) {
				setView(SubaccountDrawerView.List);
				/**
				 * As the banner is it not mounted until it is visible, its subscriptions are not initialized
				 * and therefore it will not receive the events. So first we allow the list view to be mounted and then we immediately change it
				 * to the default view if it is present.
				 */
				if (defaultView) {
					setTransitionToDefaultView(true);
				}
				const subaccountIds = subaccounts.map(subaccount => subaccount.id);
				setSubaccountIdsOnOpen(subaccountIds);
			}
		}
	});

	const goToAccount = useCallback(({ id }: Pick<Client, 'id'>) => {
		close(undefined, true);
		Tools.$state.go('account.dashboard', { id }, { inherit: false });
	}, []);

	const closeDrawer = () => {
		close(undefined, true);
	};

	useModalClose(
		modalId,
		event => {
			if (view === SubaccountDrawerView.List) {
				event.preventDefault();
				closeDrawer();
			}
		},
		[view, includeSubaccountData, subaccounts, subaccountIdsOnOpen]
	);

	useEffect(() => {
		if (transitionToDefaultView) {
			setView(defaultView);
			setTransitionToDefaultView(false);
		}
	}, [defaultView, transitionToDefaultView]);

	useEffect(() => {
		if (account) {
			setLoadingAccount(true);
			initAccountReducer(account, false);
		}
	}, [subaccounts, account]);

	useEffect(() => {
		const NOTIFICATION_ACTIONS = [
			'prospecting-save-bulk:prospecting-ai',
			'prospecting-save-bulk:prospecting-grouptree',
			'prospecting-save-bulk:prospecting-manual',
			'soliditet-buy-multiple-clients'
		];
		/**
		 * Need to check if there is a pending purchase running, if so we need to show the banner
		 * and hide the unbought companies from the list
		 */
		let unsub: (() => void) | null = null;
		const getNotifications = () => {
			notificationsRef.current?.cancel?.();
			const filters = new RequestBuilder();
			filters.addFilter({ field: 'type' }, comparisonTypes.Equals, 'job');
			filters.addFilter({ field: 'status' }, comparisonTypes.NotEquals, [100, -1]);
			filters.addFilter({ field: 'action' }, comparisonTypes.Wildcard, NOTIFICATION_ACTIONS);
			filters.addFilter(
				{ field: 'date' },
				comparisonTypes.GreaterThan,
				moment().subtract(1, 'days').format('YYYY-MM-DD')
			);

			notificationsRef.current = makeCancelable(
				Tools.PushNotifications.customer(Tools.AppService.getCustomerId()).find(filters.build())
			);

			return notificationsRef.current.promise
				.then((response: NotificationResponse) => {
					if (!response.data?.length) {
						setPendingPurchase(null);
						return;
					}
					setPendingPurchase(response.data[0]);
				})
				.catch(err => {
					if (!err.isCanceled) {
						logError(err);
					}
				});
		};
		const subscribeToNotifications = () => {
			unsub = Tools.$rootScope.$on('pushNotification.data', (e, data) => {
				if (data.type === NOTIFICATION_TYPES.JOB && NOTIFICATION_ACTIONS.includes(data.action)) {
					getNotifications();
				}
			});
		};

		getNotifications();
		subscribeToNotifications();

		return () => {
			unsub?.();
			notificationsRef.current?.cancel?.();
		};
	}, []);

	return (
		<Drawer className={classes.b()}>
			<Header client={client} close={close} goToAccount={goToAccount} />
			<FadeWrap visible={view === SubaccountDrawerView.Loading} unmountOnFade={true}>
				<Loading />
			</FadeWrap>
			<FadeWrap visible={view === SubaccountDrawerView.List}>
				<List
					client={client}
					goToAccount={goToAccount}
					includeSubaccountData={includeSubaccountData}
					setIncludeSubaccount={setIncludeSubaccount}
					setView={setView}
					subaccounts={subaccounts}
					isLoadingBranchOffices={isLoadingBranchOffices}
					pendingPurchase={pendingPurchase}
				/>
			</FadeWrap>
			<FadeWrap visible={view === SubaccountDrawerView.Add} unmountOnFade={true}>
				<Add
					client={client}
					closeDrawer={closeDrawer}
					modalId={modalId}
					setView={setView}
					subaccounts={subaccounts}
					pendingPurchase={pendingPurchase}
				/>
			</FadeWrap>
		</Drawer>
	);
};
const mapDispatchToProps = {
	initAccountReducer: init,
	setLoadingAccount: setLoading
};

export const detached = SubaccountDrawer;
const Component = connect(null, mapDispatchToProps)(SubaccountDrawer);
export default Component;
