import { AccountManagers, ClientColumnSubtitle, ClientColumnTitle } from 'Components/SubaccountDrawer/columnParts';
import { Block, Flex, Input, Label } from '@upsales/components';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import { PrimaryButton } from '@upsales/components/Buttons';
import Client, { ConnectedClient } from 'App/resources/Model/Client';
import { SelectAsync } from '@upsales/components/Select/Select';
import { useTranslation } from 'Components/Helpers/translate';
import React, { useState, useEffect, useRef } from 'react';
import ClientAttributes from 'App/babel/attributes/Client';
import { CancelablePromise, makeCancelable } from 'App/babel/helpers/promise';
import openModal from 'App/services/Modal';

import BackButton from '../BackButton';
import BemClass from '@upsales/components/Utils/bemClass';
import ClientResource from 'App/resources/Client';
import logError from 'Helpers/logError';

import './AddRelation.scss';
import { useModalClose } from 'App/components/Modals/Modals';

type SelectableClient = Client & { title: string };

type ConnectedClientDrawer = ConnectedClient & { connected: boolean };

type Props = {
	client: Client;
	goBack: () => void;
	close: () => void;
	modalId: number;
};

const RESULT_LIMIT = 20;

export const getFetcher =
	(clientIds: number[]) =>
	(searchString?: string): Promise<SelectableClient[]> => {
		const filter = new RequestBuilder();
		filter.addFilter(ClientAttributes.active, comparisonTypes.Equals, 1);
		filter.addFilter(ClientAttributes.id, comparisonTypes.NotEquals, clientIds);

		if (searchString) {
			filter.addFilter(ClientAttributes.name, comparisonTypes.Search, searchString);
		}

		filter.addSort(ClientAttributes.name, true);
		filter.limit = RESULT_LIMIT;
		return ClientResource.find(filter.build()).then(({ data }) =>
			data.map((client: Client) => ({ ...client, title: client.name }))
		);
	};

const Relation = (props: Props) => {
	const { client, goBack, close, modalId } = props;
	const classes = new BemClass('AddRelation');
	const { t } = useTranslation();

	const [selectedClient, setSelectedClient] = useState<SelectableClient>();
	const [selectedToClient, setSelectedToClient] = useState<string>('');
	const [clientToSelected, setClientToSelected] = useState<string>('');
	const [saving, setSaving] = useState(false);
	const [existingRelations, setExistingRelations] = useState<number[]>([]);

	const saveRelationRequest = useRef<null | CancelablePromise<any>>(null);

	const lang = {
		backButton: t('createRelation.relation.backButton'),
		chooseComapanyToRelate: t('createRelation.relation.chooseCompanyToRelate'),
		howIsCompanyRelated: t('createRelation.relation.howIsCompanyRelated'),
		relationExample1: t('client.relationExample1'),
		relationExample2: t('client.relationExample2'),
		saveButtonText: t('createRelation.relation.saveRelation'),
		unsavedChanges: t('createRelation.relation.unsavedChanges'),
		select: t('default.select')
	};

	useEffect(() => {
		const fetchRelations = () => {
			// Fetch all existing relations for the client.
			// This does a get on each client relation so we might want to optimize this when we got time.
			Tools.AccountRelations.get(client)
				.then((relations: ConnectedClientDrawer[]) => {
					const connectedRelations = relations.reduce<number[]>((res, relation) => {
						if (relation.connected) {
							res.push(relation.relatedToClientId);
						}
						return res;
					}, []);
					setExistingRelations(connectedRelations);
				})
				.catch(error => {
					logError(error, 'Failed to fetch relations');
				});
		};

		fetchRelations();
	}, [client]);

	useModalClose(
		modalId,
		event => {
			event.preventDefault();

			if (selectedClient && !saving) {
				const body = lang.unsavedChanges;

				openModal('UnsavedChangesAlert', {
					body,
					confirmButtonText: t('default.goBack'),
					onClose: (confirmed?: boolean) => {
						if (confirmed || confirmed === undefined) {
							return;
						}
						close();
					}
				});
			} else {
				close();
			}
		},
		[selectedClient, saving]
	);

	const saveRelation = () => {
		if (!selectedClient) {
			return;
		}

		setSaving(true);
		const relation = {
			clientId: client.id,
			relatedToClientId: selectedClient.id,
			description: selectedToClient,
			descriptionChildParent: clientToSelected
		};

		saveRelationRequest.current = makeCancelable(
			Tools.AccountRelation.customer(Tools.AppService.getCustomerId()).save(relation)
		);

		saveRelationRequest.current.promise
			.then(() => {
				close();
			})
			.catch(error => {
				logError(error, 'Failed to save relation');
			})
			.finally(() => {
				setSaving(false);
			});

		return () => {
			saveRelationRequest.current?.cancel();
		};
	};

	const allClientIdsToSkip = [client.id, ...existingRelations];
	const fetcher = getFetcher(allClientIdsToSkip);

	return (
		<Flex className={classes.b()} direction="column" justifyContent="space-between">
			<Block className={classes.elem('content').b()}>
				<BackButton buttonText={lang.backButton} classes={classes} clientName={client.name} onClick={goBack} />
				<Flex direction="column" space="ptl" gap="u10">
					<Block>
						<Label required> {lang.chooseComapanyToRelate}</Label>
						<SelectAsync
							key={selectedClient?.id}
							anchor={'.AddRelation'}
							disabled={saving}
							fetchOnMount={false}
							onChange={setSelectedClient}
							placeholder={lang.select}
							fetcher={fetcher}
							value={selectedClient ?? null}
							onClear={() => setSelectedClient(undefined)}
							renderItem={client => (
								<Flex
									justifyContent="space-between"
									space="pts pbs"
									className={classes.elem('selectCustomRow').b()}
								>
									<Flex direction="column" gap="u1">
										<ClientColumnTitle client={client} />
										<ClientColumnSubtitle client={client} />
									</Flex>
									<Flex alignItems="center">
										<AccountManagers users={client.users} />
									</Flex>
								</Flex>
							)}
						/>
					</Block>
					{selectedClient ? (
						<Flex direction="column" gap="u4">
							<Block>
								<Label required>
									{t('createRelation.relation.howIsCompanyRelated', {
										companyName1: selectedClient.name,
										companyName2: client.name
									})}
								</Label>
								<Input
									placeholder={lang.relationExample1}
									value={selectedToClient}
									onChange={e => setSelectedToClient(e.target.value)}
								></Input>
							</Block>
							<Block>
								<Label required>
									{t('createRelation.relation.howIsCompanyRelated', {
										companyName1: client.name,
										companyName2: selectedClient.name
									})}
								</Label>
								<Input
									placeholder={lang.relationExample2}
									value={clientToSelected}
									onChange={e => setClientToSelected(e.target.value)}
								></Input>
							</Block>
						</Flex>
					) : null}
				</Flex>
			</Block>
			<Block
				backgroundColor="grey-1"
				border="ts"
				borderColor="grey-4"
				className={classes.elem('footer').b()}
				space="ptl pbl"
			>
				<Flex alignItems="center" justifyContent="center">
					<PrimaryButton
						disabled={!selectedClient || !selectedToClient || !clientToSelected || saving}
						icon="check"
						onClick={saveRelation}
						size="lg"
					>
						{lang.saveButtonText}
					</PrimaryButton>
				</Flex>
			</Block>
		</Flex>
	);
};

export default Relation;
