import Resource from './ResourceTyped';
import type Notification from 'App/resources/Model/Notification.d.ts';

class NotificationResource extends Resource<Notification> {
	constructor() {
		super('notifications');
	}
}

export default new NotificationResource();
