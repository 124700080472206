import React, { useEffect, useRef, useState } from 'react';
import { ModalProps } from '../Modals/Modals';
import { Button, Modal } from '@upsales/components';
import t from 'Components/Helpers/translate';
import copyToClipboard from 'Services/copyToClipboard';
import { CancelablePromise, makeCancelable } from '@upsales/components/Utils/CancelablePromise';

type Props = ModalProps & {
	edit?: boolean;
};

const ApiKey = ({ className, close }: Props) => {
	const [name, setName] = useState('');
	const [key, setKey] = useState('');
	const [edit, setEdit] = useState(true);
	const [copied, setCopied] = useState(false);
	const [saving, setSaving] = useState(false);
	const reqRef = useRef<CancelablePromise<Awaited<ReturnType<typeof Tools.ApiKey.save>>> | null>(null);
	const copyRef = useRef<number | null>(null);
	let rightSideContent, wrapClasses;

	useEffect(() => {
		return () => {
			reqRef.current?.cancel();
			if (copyRef.current) {
				clearTimeout(copyRef.current);
			}
		};
	}, []);

	const save = () => {
		if (!name) {
			return;
		}

		setSaving(true);

		reqRef.current = makeCancelable(Tools.ApiKey.save({ name }));

		reqRef.current.promise
			.then(function (res) {
				setSaving(false);
				setKey(res.data.key);
				setEdit(false);
			})
			.catch(function (e) {
				setSaving(false);
			});
	};

	if (edit) {
		wrapClasses = 'column-wrap';
		rightSideContent = (
			<div className="generate-button-wrap">
				<Button disabled={!name} loading={saving} block color="white" submit>
					{t('admin.apiKey.generate')}
				</Button>
			</div>
		);
	} else {
		wrapClasses = 'column-wrap edit';
		rightSideContent = (
			<div className="display-key-wrap">
				<label>{t('default.key')}</label>
				<div className="key-container">{key}</div>
				<button
					type="button"
					className="btn up-btn no-shadow copy-button"
					onClick={() => {
						setCopied(true);
						copyToClipboard(key);
						copyRef.current = window.setTimeout(() => {
							setCopied(false);
						}, 2000);
					}}
				>
					{copied ? t('default.copiedToClipboard') : t('default.clickToCopy')}
				</button>
			</div>
		);
	}

	return (
		<Modal className={className} id="edit-apikey-modal">
			<form
				onSubmit={e => {
					e.preventDefault();
					save();
				}}
			>
				<div className={wrapClasses}>
					<a className="btn btn-link btn-link--gray modal-close" onClick={() => close()}>
						<i className="fa fa-times"></i>
					</a>
					<div className="left-column">
						<div className="header">
							<i className="fa fa-key" aria-hidden="true"></i>
							<span className="title">{t('admin.apiKey.modal.create')}</span>
						</div>
						<div className="body">{t('admin.apiKey.modal.description')}</div>
						<div className="footer">
							<Button color="white" type="link" onClick={() => close()}>
								{t('default.close')}
							</Button>
						</div>
					</div>
					<div className="right-column">
						<div className="name-input-wrap">
							<label>{t('default.name')}</label>
							<input
								className="form-control name-input"
								placeholder={t('admin.apiKey.namePlaceholder')}
								onChange={e => setName(e.target.value)}
								value={name}
								disabled={!edit}
								maxLength={50}
							/>
						</div>
						{rightSideContent}
					</div>
				</div>
			</form>
		</Modal>
	);
};

export default ApiKey;
