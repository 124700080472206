import Attribute, { DisplayType, Type } from './Attribute';

const OrderAttributes: { [key: string]: ReturnType<typeof Attribute> } = {
	id: Attribute({
		title: 'order.id',
		field: 'id',
		type: Type.Number,
		displayType: DisplayType.Text,
		selectableColumn: true,
		sortable: 'id'
	}),
	description: Attribute({
		title: 'default.description',
		field: 'description',
		type: Type.String,
		displayType: DisplayType.Text,
		inputType: 'text',
		groupable: false,
		sortable: 'description',
		selectableColumn: true
	}),
	operationalAccount: Attribute({
		title: 'default.account',
		type: Type.String,
		displayKey: 'client',
		field: 'client.operationalAccount.id',
		sortable: 'client.operationalAccount.name',
		displayType: DisplayType.MainAccount
	}),
	date: Attribute({
		title: 'order.date',
		field: 'date',
		type: Type.Date,
		displayType: DisplayType.Date,
		sortable: 'date',
		selectableColumn: true,
		elevioId: 276
	}),
	closeDate: Attribute({
		title: 'default.closeDate',
		field: 'closeDate',
		type: Type.Date,
		displayType: DisplayType.Date,
		sortable: 'closeDate',
		selectableColumn: true,
		elevioId: 277
	}),
	value: Attribute({
		title: 'default.value',
		field: 'value',
		type: Type.String,
		displayType: DisplayType.Currency,
		displayKey: ['value', 'currency'],
		displayKeyMap: {
			value: 'value',
			currency: 'currency'
		},
		currencyFallback: 'master',
		selectableColumn: true,
		sortable: 'valueInMasterCurrency',
		elevioId: 965
	}),
	contributionMargin: Attribute({
		title: 'default.contributionMarginInMasterCurrency',
		field: 'contributionMargin',
		type: Type.Number,
		displayType: DisplayType.Currency,
		displayKey: ['contributionMargin', 'currency'],
		displayKeyMap: {
			contributionMargin: 'value',
			currency: 'master'
		},
		currencyFallback: 'master',
		selectableColumn: false, // This was never selectable in the old list
		sortable: 'contributionMargin'
	}),
	contributionMarginLocalCurrency: Attribute({
		title: 'default.contributionMargin',
		field: 'contributionMarginLocalCurrency',
		type: Type.Number,
		displayType: DisplayType.Currency,
		displayKey: ['contributionMarginLocalCurrency', 'currency'],
		displayKeyMap: {
			contributionMarginLocalCurrency: 'value',
			currency: 'currency'
		},
		currencyFallback: 'master',
		selectableColumn: true,
		sortable: 'contributionMarginLocalCurrency'
	}),
	contributionMarginInRoleCurrency: Attribute({
		title: 'default.contributionMargin',
		field: 'contributionMarginInRoleCurrency',
		type: Type.Number,
		displayType: DisplayType.Number,
		sortable: 'contributionMarginInRoleCurrency'
	}),
	weightedContributionMargin: Attribute({
		title: 'default.weightedContributionMargin',
		field: 'weightedContributionMargin',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: false,
		sortable: 'weightedContributionMargin'
	}),
	weightedContributionMarginInRoleCurrency: Attribute({
		title: 'default.weightedContributionMarginInRoleCurrency',
		field: 'weightedContributionMarginInRoleCurrency',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: false,
		sortable: 'weightedContributionMarginInRoleCurrency'
	}),
	weightedContributionMarginLocalCurrency: Attribute({
		title: 'default.contributionMargin',
		field: 'weightedContributionMarginLocalCurrency',
		type: Type.Number,
		displayType: DisplayType.Number,
		sortable: 'weightedContributionMarginLocalCurrency'
	}),
	valueInMasterCurrency: Attribute({
		title: 'default.valueInMasterCurrency',
		field: 'valueInMasterCurrency',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: false,
		sortable: 'valueInMasterCurrency'
	}),
	valueIncontributionMargin: Attribute({
		title: 'default.contributionMargin',
		field: 'contributionMargin',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: false,
		sortable: 'valueIncontributionMargin'
	}),
	weightedValue: Attribute({
		title: 'default.weightedValue',
		field: 'weightedValue',
		type: Type.String,
		displayType: DisplayType.Currency,
		displayKey: ['weightedValue', 'currency'],
		displayKeyMap: { weightedValue: 'value', currency: 'currency' },
		currencyFallback: 'master',
		selectableColumn: false,
		sortable: 'weightedValue'
	}),
	weightedValueInMasterCurrency: Attribute({
		title: 'default.weightedValueInMasterCurrency',
		field: 'weightedValueInMasterCurrency',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: false,
		sortable: 'weightedValueInMasterCurrency'
	}),
	agreement: Attribute({
		title: 'default.agreement',
		field: 'agreement.id',
		displayAttr: 'description',
		type: Type.Number,
		displayType: DisplayType.Text,
		link: 'agreement',
		groupable: false,
		sortable: 'agreement.description',
		selectableColumn: false,
		parent: 'agreement',
		attr: {
			id: Attribute({
				field: 'agreement.id',
				type: Type.Number
			}),
			description: Attribute({
				field: 'agreement.description',
				type: Type.String
			})
		}
	}),
	user: Attribute({
		title: 'default.user',
		field: 'user.id',
		type: Type.Number,
		displayType: DisplayType.User,
		displayKey: 'user',
		sortable: 'user.name',
		selectableColumn: true
	}),
	users: Attribute({
		title: 'default.accountManager',
		field: 'client.user.id',
		type: Type.Number,
		displayType: DisplayType.Users,
		displayKey: 'client.users',
		selectableColumn: true
	}),
	contact: Attribute({
		title: 'default.contact',
		field: 'contact.id',
		type: Type.Number,
		displayType: DisplayType.ContactLink,
		displayKey: ['contact.id', 'contact.name'],
		displayKeyMap: {
			'contact.id': 'id',
			'contact.name': 'name'
		},
		sortable: 'contact.name',
		selectableColumn: true
	}),
	account: Attribute({
		title: 'default.account',
		field: 'client.id',
		// inputType: 'select',
		displayAttr: 'name',
		type: Type.Number,
		displayType: DisplayType.Text,
		link: 'accounts',
		groupable: false,
		sortable: 'client.name',
		selectableColumn: false,
		parent: 'client',
		attr: {
			id: Attribute({
				field: 'client.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'client.name',
				type: Type.String
			}),
			user: Attribute({
				field: 'client.user',
				type: Type.Object,
				attr: {
					id: Attribute({
						field: 'client.user.id',
						type: Type.Number
					})
				}
			}),
			active: Attribute({
				field: 'client.active',
				type: Type.Boolean
			})
		}
	}),
	client: Attribute({
		title: 'default.account',
		field: 'client.id',
		type: Type.String,
		displayType: DisplayType.ClientLink,
		displayKey: ['client.id', 'client.name', 'client.journeyStep'],
		displayKeyMap: { 'client.id': 'id', 'client.name': 'name', 'client.journeyStep': 'journeyStep' },
		sortable: 'client.name',
		selectableColumn: true
	}),
	probability: Attribute({
		title: 'default.probability',
		field: 'probability',
		type: Type.Number,
		displayType: DisplayType.Number,
		displayKey: 'probability',
		sortable: 'probability',
		selectableColumn: true
	}),
	notes: Attribute({
		field: 'notes',
		type: Type.String
	}),
	project: Attribute({
		title: 'default.campaigns',
		field: 'project.id',
		type: Type.Number,
		displayType: DisplayType.Text,
		displayKey: 'project.name',
		selectableColumn: true,
		sortable: 'project.name',
		placeholder: 'default.noCampaign'
	}),
	product: Attribute({
		title: 'default.product',
		field: 'orderRow.product.id',
		type: Type.Array,
		displayType: DisplayType.Array,
		displayKey: ['orderRow', 'product', 'name'],
		selectableColumn: true
	}),
	stage: Attribute({
		title: 'default.stage',
		field: 'stage.id',
		displayKey: 'stage.name',
		displayType: DisplayType.Stage,
		type: Type.String,
		selectableColumn: true,
		sortable: 'stage.name'
	}),
	currency: Attribute({
		title: 'default.currency',
		field: 'currency',
		type: Type.String,
		displayType: DisplayType.Text
	}),
	currencyRate: Attribute({
		title: 'default.currencyRate',
		field: 'currencyRate',
		type: Type.Float,
		displayType: DisplayType.Number
	}),
	clientConnection: Attribute({
		title: 'default.clientConnection',
		field: 'clientConnection.id',
		type: Type.Number,
		displayType: DisplayType.ClientLink,
		parent: 'clientConnection',
		selectableColumn: true,
		sortable: 'clientConnection.name',
		displayKey: ['clientConnection.id', 'clientConnection.name'],
		displayKeyMap: { 'clientConnection.id': 'id', 'clientConnection.name': 'name' },
		attr: {
			id: Attribute({
				field: 'clientConnection.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'clientConnection.name',
				type: Type.String
			})
		}
	}),
	closedCol: Attribute({
		title: 'order.closed',
		field: 'closedCol',
		type: Type.Boolean,
		displayType: DisplayType.DateUser,
		displayKey: ['date', 'user.name'],
		displayKeyMap: { date: 'date', 'user.name': 'name' },
		sortable: 'date',
		groupable: false,
		selectableColumn: true
	}),
	descStageCol: Attribute({
		title: 'order.descriptionAndStage',
		field: 'descStageCol',
		type: Type.String,
		displayType: DisplayType.SubtitleText,
		displayKey: ['description', 'stage.name'],
		displayKeyMap: {
			description: 'title',
			'stage.name': 'subtitle'
		},
		sortable: 'description',
		selectableColumn: true
	}),
	journeyStep: Attribute({
		title: 'default.journeyStep',
		field: 'client.journeyStep',
		type: Type.String,
		displayType: DisplayType.Journey,
		displayKey: 'client.journeyStep',
		groupable: false,
		selectableColumn: false
	}),
	clientJourneyStep: Attribute({
		title: 'default.journeyStepClient',
		field: 'client.journeyStep',
		type: Type.String,
		displayType: DisplayType.Journey,
		displayKey: 'client.journeyStep',
		groupable: false,
		selectableColumn: true
	}),
	contactJourneyStep: Attribute({
		title: 'default.journeyStepContact',
		field: 'contact.journeyStep',
		type: Type.String,
		displayType: DisplayType.Journey,
		displayKey: 'contact.journeyStep',
		groupable: false,
		selectableColumn: true
	}),
	oneOffValue: Attribute({
		title: 'default.oneOffValue',
		field: 'oneOffValue',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: false,
		sortable: 'oneOffValue'
	}),
	oneOffValueInMasterCurrency: Attribute({
		title: 'default.oneOffValueInMasterCurrency',
		field: 'oneOffValueInMasterCurrency',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: false,
		sortable: 'oneOffValueInMasterCurrency'
	}),
	weightedOneOffValue: Attribute({
		title: 'default.weightedOneOffValue',
		field: 'weightedOneOffValue',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: false,
		sortable: 'weightedOneOffValue'
	}),
	weightedOneOffValueInMasterCurrency: Attribute({
		title: 'default.weightedOneOffValueInMasterCurrency',
		field: 'weightedOneOffValueInMasterCurrency',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: false,
		sortable: 'weightedOneOffValueInMasterCurrency'
	}),
	oneOffValueInRoleCurrency: Attribute({
		title: 'default.oneOffValueInRoleCurrency',
		field: 'oneOffValueInRoleCurrency',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: false,
		sortable: 'oneOffValueInRoleCurrency'
	}),
	weightedOneOffValueInRoleCurrency: Attribute({
		title: 'default.weightedOneOffValueInRoleCurrency',
		field: 'weightedOneOffValueInRoleCurrency',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: false,
		sortable: 'weightedOneOffValueInRoleCurrency'
	}),
	valueInRoleCurrency: Attribute({
		title: 'default.valueInRoleCurrency',
		field: 'valueInRoleCurrency',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: false,
		sortable: 'valueInRoleCurrency'
	}),
	weightedValueInRoleCurrency: Attribute({
		title: 'default.weightedValueInRoleCurrency',
		field: 'weightedValueInRoleCurrency',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: false,
		sortable: 'weightedValueInRoleCurrency'
	}),
	annualValue: Attribute({
		title: 'default.annualValue',
		field: 'annualValue',
		displayKey: ['annualValue', 'currency'],
		displayKeyMap: {
			annualValue: 'value',
			currency: 'currency'
		},
		type: Type.Number,
		displayType: DisplayType.Currency,
		selectableColumn: true,
		sortable: 'annualValue'
	}),
	annualValueInMasterCurrency: Attribute({
		title: 'default.annualValueInMasterCurrency',
		field: 'annualValueInMasterCurrency',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: false,
		sortable: 'annualValueInMasterCurrency'
	}),
	weightedAnnualValue: Attribute({
		title: 'default.weightedAnnualValue',
		field: 'weightedAnnualValue',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: false,
		sortable: 'weightedAnnualValue'
	}),
	weightedAnnualValueInMasterCurrency: Attribute({
		title: 'default.weightedAnnualValueInMasterCurrency',
		field: 'weightedAnnualValueInMasterCurrency',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: false,
		sortable: 'weightedAnnualValueInMasterCurrency'
	}),
	annualValueInRoleCurrency: Attribute({
		title: 'default.annualValueInRoleCurrency',
		field: 'annualValueInRoleCurrency',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: false,
		sortable: 'annualValueInRoleCurrency'
	}),
	weightedAnnualValueInRoleCurrency: Attribute({
		title: 'default.weightedAnnualValueInRoleCurrency',
		field: 'weightedAnnualValueInRoleCurrency',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: false,
		sortable: 'weightedAnnualValueInRoleCurrency'
	}),
	monthlyValue: Attribute({
		title: 'default.monthlyValue',
		field: 'monthlyValue',
		displayKey: ['monthlyValue', 'currency'],
		displayKeyMap: {
			monthlyValue: 'value',
			currency: 'currency'
		},
		type: Type.Number,
		displayType: DisplayType.Currency,
		selectableColumn: true,
		sortable: 'monthlyValue'
	}),
	monthlyValueInMasterCurrency: Attribute({
		title: 'default.monthlyValueInMasterCurrency',
		field: 'monthlyValueInMasterCurrency',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: false,
		sortable: 'monthlyValueInMasterCurrency'
	}),
	weightedMonthlyValue: Attribute({
		title: 'default.weightedMonthlyValue',
		field: 'weightedMonthlyValue',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: false,
		sortable: 'weightedMonthlyValue'
	}),
	weightedMonthlyValueInMasterCurrency: Attribute({
		title: 'default.weightedMonthlyValueInMasterCurrency',
		field: 'weightedMonthlyValueInMasterCurrency',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: false,
		sortable: 'weightedMonthlyValueInMasterCurrency'
	}),
	monthlyValueInRoleCurrency: Attribute({
		title: 'default.monthlyValueInRoleCurrency',
		field: 'monthlyValueInRoleCurrency',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: false,
		sortable: 'monthlyValueInRoleCurrency'
	}),
	weightedMonthlyValueInRoleCurrency: Attribute({
		title: 'default.weightedMonthlyValueInRoleCurrency',
		field: 'weightedMonthlyValueInRoleCurrency',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: false,
		sortable: 'weightedMonthlyValueInRoleCurrency'
	}),
	confirmedBudget: Attribute({
		title: 'order.confirmedBudget',
		field: 'confirmedBudget',
		type: Type.Number,
		displayType: DisplayType.Text,
		selectableColumn: false,
		sortable: 'confirmedBudget'
	}),
	confirmedDate: Attribute({
		title: 'order.confirmedDate',
		field: 'confirmedDate',
		type: Type.Date,
		displayType: DisplayType.Date,
		selectableColumn: false,
		sortable: 'confirmedDate'
	}),
	confirmedSolution: Attribute({
		title: 'order.confirmedSolution',
		field: 'confirmedSolution',
		type: Type.Boolean,
		selectableColumn: false,
		sortable: 'confirmedSolution'
	}),
	lostReason: Attribute({
		title: 'order.lostReason',
		field: 'lostReasonTagId',
		type: Type.Number,
		displayType: DisplayType.Text,
		displayKey: 'lostReason.value',
		selectableColumn: true,
		sortable: 'lostReason.tagId'
	}),
	competitorId: Attribute({
		title: 'order.competitorId',
		field: 'competitorId',
		type: Type.Number,
		selectableColumn: false,
		sortable: 'competitorId',
		attr: {
			id: {
				field: 'competitor.id',
				type: Type.Number
			},
			name: {
				field: 'competitor.name',
				type: Type.String
			}
		}
	}),
	competitor: Attribute({
		title: 'order.competitorId',
		field: 'competitor.id',
		type: Type.Number,
		displayType: DisplayType.Text,
		displayKey: 'competitor.name',
		selectableColumn: true,
		sortable: 'competitor.name'
	}),
	noTimesClosingDateChanged: Attribute({
		field: 'noTimesClosingDateChanged',
		type: Type.Number
	}),
	noTimesOrderValueChanged: Attribute({
		field: 'noTimesOrderValueChanged',
		type: Type.Number
	}),
	noPostponedAppointments: Attribute({
		field: 'noPostponedAppointments',
		type: Type.Number
	}),
	noCompletedAppointments: Attribute({
		field: 'noCompletedAppointments',
		type: Type.Number
	}),
	risks: Attribute({
		field: 'risks',
		type: Type.Object,
		attr: {
			closingDateChangedOccured: Attribute({
				field: 'risks.closingDateChangedOccured',
				type: Type.Date
			}),
			orderValueChangedOccured: Attribute({
				field: 'risks.orderValueChangedOccured',
				type: Type.Date
			}),
			orderValueOccured: Attribute({
				field: 'risks.orderValueOccured',
				type: Type.Date
			}),
			daysInStageOccured: Attribute({
				field: 'risks.daysInStageOccured',
				type: Type.Date
			}),
			noNextStepOccured: Attribute({
				field: 'risks.noNextStepOccured',
				type: Type.Date
			}),
			postponedAppointmentsOccured: Attribute({
				field: 'risks.postponedAppointmentsOccured',
				type: Type.Date
			}),
			noAppointmentsOccured: Attribute({
				field: 'risks.noAppointmentsOccured',
				type: Type.Date
			})
		}
	}),
	delete: Attribute({
		// This is to allow a delete column. SimpleListView replaces the column with 'delete' as key with a trash icon and a delete function
		title: '',
		field: '',
		type: Type.String
	})
};

export default OrderAttributes;
