import React from 'react';
import PropTypes from 'prop-types';
import BuyButton from '../../DataSource/BuyButton';
import { ThirdButton } from '@upsales/components/Buttons';
import _ from 'lodash';
import logError from 'Helpers/logError';
import { makeCancelable } from 'App/babel/helpers/promise';

export default class RequiredFields extends React.Component {
	constructor(props) {
		super(props);

		var t = Tools.$translate;

		const entityTranslation = {
			contact: { desc: t('contact.pleaseFillOutDescription'), save: t('default.contact').toLowerCase() }
		}[props.entityType?.toLowerCase()];

		this.lang = {
			pleaseFillOutHeader: t('account.pleaseFillOutHeader'),
			pleaseFillOutDescription: t(entityTranslation?.desc ?? 'account.pleaseFillOutDescription'),
			saveCompany: t('default.save') + ' ' + t(entityTranslation?.save ?? 'default.account').toLowerCase(),
			adding: t('account.adding'),
			added: t('account.added')
		};

		this.state = {
			requiredFields: _.cloneDeep(this.props.requiredFields),
			formSubmitted: false,
			adding: false
		};

		this.onCustomChange = this.onCustomChange.bind(this);
		this.submit = this.submit.bind(this);
	}

	componentWillUnmount() {
		this.addAccountPromise?.cancel();
	}

	onCustomChange(fieldId, value) {
		var requiredFields = this.state.requiredFields;

		requiredFields = requiredFields.map(function (field) {
			if (field.id === fieldId) {
				field.value = value;
			}
			return field;
		});

		this.setState({
			requiredFields: requiredFields
		});
	}

	renderCustomFields() {
		var self = this;
		return this.state.requiredFields
			.map(function (field, index) {
				if (field.$hasAccess && (field.visible || field.editable)) {
					var cf = React.createElement(ReactTemplates.INPUTS.customFieldInput, {
						key: 'cf-' + index,
						field: field,
						name: name,
						disabled: !field.editable,
						multiple: false,
						className: 'form-control up-input',
						filter: false,
						tools: Tools,
						onChange: self.onCustomChange.bind(self, field.id)
					});

					var classes = 'form-group';

					if (self.state.formSubmitted && !field.value && field.value !== 0) {
						classes += ' has-error';
					}

					var wrapperClasses = ['half'];
					if (index % 2 === 0) {
						wrapperClasses.push('no-padding-left');
					} else {
						wrapperClasses.push('no-padding-right');
					}

					return (
						<div className={wrapperClasses.join(' ')} key={'cf-' + index + '-col'}>
							<div className={classes}>
								<label>
									{field.name} {field.obligatoryField ? <b className="text-danger">{'*'}</b> : null}
								</label>
								<div className="input">{cf}</div>
							</div>
						</div>
					);
				}
				return null;
			})
			.sort((a, b) => (a?.sortId ?? 0) - (b?.sortId ?? 0));
	}

	submit(purchaseType) {
		var isInvalid = false;
		var requiredFields = this.state.requiredFields.map(function (field) {
			if (!field.value && field.value !== 0) {
				isInvalid = true;
				field.isInvalid = true;
			}

			return field;
		});

		if (isInvalid) {
			this.setState({
				formSubmitted: true,
				requiredFields: requiredFields
			});
			Tools.NotificationService.addNotification({
				style: Tools.NotificationService.style.WARN,
				icon: 'warning',
				title: 'default.error',
				body: 'default.youHaveFormErrors'
			});
		} else {
			var properties = [];
			Tools.ActionProperties.mapCustomFields(properties, requiredFields, this.props.entityType ?? 'Client');
			this.setState({ adding: true });
			const promise = this.props.actions.addAccount(
				this.props.addingAccount,
				properties,
				purchaseType,
				this.props.resolve
			);
			this.addAccountPromise = makeCancelable(promise instanceof Promise ? promise : Promise.resolve());
			this.addAccountPromise.promise
				.catch(e => {
					logError(e, 'Failed to add account');
				})
				.finally(() => {
					this.setState({ adding: false });
				});
		}
	}

	render() {
		const { addingAccount = {}, accounts = {}, reject } = this.props;
		const dataSource = accounts[addingAccount.dataSourceId];

		return (
			<div style={{ height: '100%' }}>
				<div className="header-content full-screen-modal-content">
					<div className="required-fields">
						<h1>{this.lang.pleaseFillOutHeader}</h1>
						<p>{this.lang.pleaseFillOutDescription}</p>
						<div>{this.renderCustomFields()}</div>
						<div>
							<div className="half no-padding-left">
								<BuyButton
									pricing={dataSource?.pricing}
									added={addingAccount.added}
									adding={addingAccount.adding ?? this.state.adding}
									onConfim={this.submit}
									renderButton={this.renderBuyButton}
									language={{ add: this.lang.saveCompany }}
									size="lg"
								/>
								<ThirdButton onClick={reject} size="lg">
									{Tools.$translate('default.cancel')}
								</ThirdButton>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

RequiredFields.propTypes = {
	addingAccount: PropTypes.object,
	actions: PropTypes.object,
	requiredFields: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	accounts: PropTypes.object,
	resolve: PropTypes.func,
	reject: PropTypes.func,
	entityType: PropTypes.string
};
