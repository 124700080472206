import React, { Component } from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import { Headline } from '@upsales/components';
import { currencyFormat } from '../../Filters/Currencies';
import moment from 'moment';
import T from 'Components/Helpers/translate';
import { SlideFade } from 'App/components/animations';

import './SecondScreen.scss';

type Widget = {
	total: { progress: number };
	currency: string;
};

type Props = {
	soldFor: { recurringSales: Widget; oneoffSales: Widget; contributionMarginSales: Widget; sales: Widget };
	next: () => void;
	visible: boolean;
};

class SecondScreen extends Component<Props> {
	lang: {
		salesthisYear: (year: string) => string;
		bestDay: (bestDay: string, year: string) => string;
	};

	constructor(p: Props) {
		super(p);
		this.lang = {
			salesthisYear: year => T('yourYearWithUpsales.salesthisYear', { year }),
			bestDay: (date, value) => T('yourYearWithUpsales.bestDay', { date, value })
		};
	}

	getSales() {
		const { soldFor, visible } = this.props;
		const { params } = Tools.AppService.getMetadata();
		switch (params.SalesModel) {
			case 'sales': {
				return (
					<SlideFade delayInMs={2000} visible={visible}>
						<Headline size="xl">
							{currencyFormat(soldFor.sales.total.progress, soldFor.sales.currency)}
						</Headline>
					</SlideFade>
				);
			}
			case 'rr': {
				const type = params.SalesModelOption === 'mrr' ? T('default.monthlyValue') : T('default.annualValue');

				return (
					<React.Fragment>
						<SlideFade delayInMs={2000} visible={visible}>
							<Headline size="xl">
								{`${type}: ${currencyFormat(
									soldFor.recurringSales.total.progress,
									soldFor.recurringSales.currency
								)}`}
							</Headline>
						</SlideFade>
						<SlideFade delayInMs={3000} visible={visible}>
							<Headline size="xl">
								{`${T('default.oneOffValue')}: ${currencyFormat(
									soldFor.oneoffSales.total.progress,
									soldFor.oneoffSales.currency
								)}`}
							</Headline>
						</SlideFade>
					</React.Fragment>
				);
			}
			case 'cm': {
				return (
					<SlideFade delayInMs={2000} visible={visible}>
						<Headline size="xl">
							{`${T('default.contributionMargin')}: ${currencyFormat(
								soldFor.contributionMarginSales.total.progress,
								soldFor.contributionMarginSales.currency
							)}`}
						</Headline>
					</SlideFade>
				);
			}
		}
	}

	render() {
		const classNames = new bemClass('SecondScreen');
		const { next } = this.props;

		return (
			<div className={classNames.b()} onClick={next}>
				<div>
					<Headline className={classNames.elem('thisYear').b()}>
						{this.lang.salesthisYear(moment().format('YYYY'))}
					</Headline>
					<div className={classNames.elem('thisYearValue').b()}>{this.getSales()}</div>
					<img src="img/yourYearWithUpsales/Champagne-lady.svg" />
				</div>
			</div>
		);
	}
}

export default SecondScreen;
