import ResourceTyped from 'Resources/ResourceTyped';

export type ContactRelation = {
	contactId: number;
	relatedToClientId: number;
	description: string;
	id: number;
};

class ContactRelationResource extends ResourceTyped<ContactRelation> {
	eventName = 'contactRelation';
	constructor() {
		super('contactrelation');
	}
}

const contactRelationResource = new ContactRelationResource();

export default contactRelationResource;
