import { StaticSelect, type StaticSelectConsumerProps } from '../StaticSelect';
import { useTranslation } from 'Components/Helpers/translate';
import PriceList from 'App/resources/Model/PriceList';
import React, { useMemo } from 'react';

const useSharedProps = () => {
	const { t } = useTranslation();

	const placeholder = t('default.priceListSelect');
	const priceLists = Tools.AppService.getPriceLists().filter(priceList => priceList.active);

	const options = useMemo(() => {
		return (
			priceLists?.map(list => ({
				...list,
				id: list.id,
				title: list.name
			})) ?? []
		);
	}, [priceLists]);

	return { placeholder, options };
};

export const StaticPriceListSelect = (props: StaticSelectConsumerProps<PriceList>) => {
	const sharedProps = useSharedProps();
	return <StaticSelect {...sharedProps} {...props} />;
};
