import { ButtonSelect, Card, Block, Flex, Title, Text } from '@upsales/components';
import { useAccountSelf } from 'App/components/hooks/appHooks';
import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import { useAnimationData } from 'App/components/hooks';
import React, { useState } from 'react';
import Lottie from 'react-lottie';

import './SubaccountHelp.scss';

const SUBACCOUNT_TABS = {
	CONSOLIDATED: 'consolidated',
	LOCATION: 'location',
	SEARCH: 'search',
	CARD: 'card'
} as const;

type SubaccountTab = (typeof SUBACCOUNT_TABS)[keyof typeof SUBACCOUNT_TABS];

const SubaccountHelp = () => {
	const classes = new BemClass('SubaccountHelp');
	const [currentTab, setCurrentTab] = useState<SubaccountTab>(SUBACCOUNT_TABS.CONSOLIDATED);
	const language = useAccountSelf()?.language;
	const imgSearchPathLang = language === 'sv-SE' ? 'sv' : 'en';

	const { t } = useTranslation();

	const options = [
		{ value: SUBACCOUNT_TABS.CONSOLIDATED, title: t('subaccount.help.buttonGroup.consolidated') },
		{ value: SUBACCOUNT_TABS.LOCATION, title: t('subaccount.help.buttonGroup.location') },
		{ value: SUBACCOUNT_TABS.SEARCH, title: t('subaccount.help.buttonGroup.search') },
		{ value: SUBACCOUNT_TABS.CARD, title: t('subaccount.help.buttonGroup.card') }
	];

	const lang = {
		consolidated: t('subaccount.help.consolidated'),
		search: t('subaccount.help.search'),
		card: t('subaccount.help.card'),
		location: t('subaccount.help.location')
	};

	const { animationData: Location } = useAnimationData(() => import(`./Lotties/${imgSearchPathLang}/location.json`));

	const renderContent = () => {
		switch (currentTab) {
			case SUBACCOUNT_TABS.CONSOLIDATED:
				return (
					<Flex className={classes.elem('flex').b()} alignItems="center">
						<Block className={classes.elem('textBlock').b()}>
							<Text italic className={classes.elem('infoText').b()} color="grey-11">
								{lang.consolidated}
							</Text>
						</Block>
						<Flex className={classes.elem('imgBlock').b()} justifyContent="flex-end">
							<img
								src={`img/subaccounts/help/${imgSearchPathLang}/consolidated.svg`}
								alt="consolidated"
							/>
						</Flex>
					</Flex>
				);
			case SUBACCOUNT_TABS.LOCATION:
				return (
					<Flex className={classes.elem('flex').b()} alignItems="center" justifyContent="space-between">
						<Block className={classes.elem('textBlockLottie').b()}>
							<Text italic className={classes.elem('infoTextSmall').b()} color="grey-11">
								{lang.location}
							</Text>
						</Block>
						<Flex className={classes.elem('lottieBlock').b()}>
							<Lottie
								options={{
									animationData: Location,
									loop: true,
									rendererSettings: { className: classes.elem('lottie').b() }
								}}
								isClickToPauseDisabled={true}
							/>
						</Flex>
					</Flex>
				);
			case SUBACCOUNT_TABS.SEARCH:
				return (
					<Flex className={classes.elem('flex').b()} alignItems="center">
						<Flex className={classes.elem('textBlockSearch').b()} direction="column" gap="u2">
							<Text italic className={classes.elem('infoTextSmall').b()} color="grey-11">
								{lang.search}
							</Text>
							<Block className={classes.elem('arrowBlock').b()}>
								<img src="img/subaccounts/help/arrow.svg" />
							</Block>
						</Flex>
						<Flex className={classes.elem('imgBlockSearch').b()}>
							<img src={`img/subaccounts/help/${imgSearchPathLang}/search.svg`} alt="search" />
						</Flex>
					</Flex>
				);
			case SUBACCOUNT_TABS.CARD:
				return (
					<Flex className={classes.elem('flex').b()} alignItems="center">
						<Flex className={classes.elem('textBlock').b()}>
							<Text italic className={classes.elem('infoTextSmall').b()} color="grey-11">
								{lang.card}
							</Text>
						</Flex>
						<img
							className={classes.elem('imgCard').b()}
							src={`img/subaccounts/help/${imgSearchPathLang}/card.svg`}
							alt="card"
						/>
					</Flex>
				);
			default:
				return null;
		}
	};

	return (
		<Flex className={classes.b()} direction="column" justifyContent="space-between" space="ptl" gap="u5">
			<Title space="mtl">{t('subaccount.help.title')}</Title>
			<ButtonSelect
				value={currentTab}
				options={options}
				onChange={value => setCurrentTab(value as SubaccountTab)}
			/>
			<Card color="grey-1" borderRadius border="bs ts rs ls" borderColor="grey-4">
				{renderContent()}
			</Card>
		</Flex>
	);
};

export default SubaccountHelp;
