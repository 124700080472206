import React, { Component } from 'react';
import logo from './bag_anim.svg';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Text, Button, Loader } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import './SeatsConfirmed.scss';
import T from 'Components/Helpers/translate';

export class SeatsConfirmed extends Component {
	static propTypes = {
		loading: PropTypes.bool,
		closeModal: PropTypes.func.isRequired
	};

	constructor(props) {
		super(props);
	}

	render() {
		const classes = new bemClass('SeatsConfirmed');
		const { loading } = this.props;
		return loading ? (
			<Loader className={classes.elem('loader').b()} size="lg" />
		) : (
			<div className={classes.elem('content').b()}>
				<img src={logo} alt="Logo" />
				<Text bold size="xl">
					{T('admin.seatsConfirmed.confirmed')}
				</Text>
				<Text color="grey-10" size="md">
					{T('admin.seatsConfirmed.continueDescription')}
				</Text>
				<Button size="xl" onClick={() => this.props.closeModal()}>
					{T('admin.seatsConfirmed.startUpsales')}
				</Button>
			</div>
		);
	}
}

const mapStateToProps = ({ Billing }) => ({
	loading: Billing.loading
});

export default connect(mapStateToProps)(SeatsConfirmed);
