import Form, { RawForm } from '../Model/Form';

export default (form: Partial<RawForm>) => {
	if (typeof form.socialMediaTags === 'string') {
		form.socialMediaTags = JSON.parse(form.socialMediaTags);
	}

	// Form html gets escaped in api/src/controller/customer/form.js to prevent XSS attacks, so we need to unescape it here
	const parseReplace = (fieldType: string) => {
		return fieldType
			?.replaceAll('&amp;', '&')
			.replaceAll('&lt;', '<')
			.replaceAll('&gt;', '>')
			.replaceAll('&quot;', '"')
			.replaceAll('&#039;', "'");
	};

	if (form.title) {
		form.title = parseReplace(form.title);
	}
	if (form.description) {
		form.description = parseReplace(form.description);
	}
	if (form.fields) {
		form.fields.forEach(field => {
			field.title = parseReplace(field.title);
		});
	}

	return form as Form;
};
