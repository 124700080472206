import { asyncModalAdapter, setupComponentCompatibility } from 'App/helpers/angularPortingHelpers';
import MailTemplateBrowser from 'App/upsales/common/components/react/mailTemplateBrowser';

export const MailTemplateBrowserModal = setupComponentCompatibility(MailTemplateBrowser, {
	modalName: 'MailTemplateBrowserModal',
	modalParamsMapper: $modalParams => ({
		$modalParams: $modalParams
	})
});

export const openMailTemplateBrowserModal = asyncModalAdapter({
	upModalName: 'mailTemplateBrowser',
	openModalName: 'MailTemplateBrowserModal',
	featureFlag: 'REACT_MAIL_TEMPLATE_BROWSER_MODAL'
});
