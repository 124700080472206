import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';
import { Modal, ModalHeader } from '@upsales/components';
import React, { useEffect, useState } from 'react';
import { ModalProps } from 'App/components/Modals/Modals';
import './IntegrationModal.scss';
import BemClass from '@upsales/components/Utils/bemClass';
import { FrameMessageEvent } from 'App/helpers/uiElementsHelper';
import { useSelector } from 'App/components/hooks';
import config from 'App/babel/config';

interface IntegrationModalProps extends ModalProps<string | number | null> {
	frameless: boolean;
	name: string;
	type: string;
	integrationId: string;
	objectId: string | null;
	fullscreen: boolean;
}

const IntegrationModal = (props: IntegrationModalProps) => {
	const IntegrationModalClassName = new BemClass('IntegrationModal');
	const [title, setTitle] = useState('');

	const userId = useSelector(({ App }) => {
		return App.self?.id;
	});

	useEffect(() => {
		const callback = (event: MessageEvent<FrameMessageEvent>) => {
			if (event.data && event.data.length > 0) {
				if (event.data[0] === 'closeModal' && event.data[event.data.length - 1] === props.name) {
					props.close(event.data.length >= 3 ? event.data[1] : null);
				} else if (event.data[0] === 'setTitle' && event.data[event.data.length - 1] === props.name) {
					setTitle(event.data[1] as string);
				}
			}
		};
		window.addEventListener('message', callback);
		return () => {
			window.removeEventListener('message', callback);
		};
	}, [props.name]);

	const src = `${config.URL}${config.API}function/standardIntegrationIframe/${props.name}?typeId=${
		props.type
	}&integrationId=${props.integrationId}&objectId=${props?.objectId ?? ''}&userId=${userId ?? ''}`;

	return (
		<Modal className={`${IntegrationModalClassName.mod({ fullscreen: props.fullscreen }).b()} ${props.className}`}>
			{props.frameless ? (
				<iframe className="si-modal-iframe" src={src}></iframe>
			) : (
				<>
					<ModalHeader title={title} onClose={props.close} />
					<div className="si-modal-iframe-wrap">
						<iframe className="si-modal-iframe-in-frame" allow="camera;microphone" src={src}></iframe>
					</div>
				</>
			)}
		</Modal>
	);
};

export const openIntegrationModal = asyncModalAdapter({
	upModalName: 'integrationModal',
	openModalName: 'IntegrationModal',
	featureFlag: 'REACT_INTEGRATION_MODAL',
	rejectOnEvent: true
});

export default IntegrationModal;
