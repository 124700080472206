import Resource from 'Resources/ResourceTyped';

class ActivityList extends Resource<any> {
	constructor() {
		super('search/activitylist');
	}
}

const resource = new ActivityList();

export default resource;
