import { Block, Card, Flex, Icon, Text } from '@upsales/components';
import { useMetadata } from 'App/components/hooks/appHooks';
import Client from 'App/resources/Model/Client';
import React, { useRef, useEffect, useState, useMemo } from 'react';
import ContactResource from 'App/resources/Contact';
import ActivityListResource from 'App/resources/ActivityList';
import OrderResource from 'App/resources/Order';
import ContactAttributes from 'App/babel/attributes/Contact';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import logError from 'Helpers/logError';
import RequestBuilder from 'Resources/RequestBuilder';
import ComparisonTypes from 'Resources/ComparisonTypes';
import OrderAttributes from 'App/babel/attributes/Order';
import { getSalesModelOption } from 'App/helpers/salesModelHelpers';
import { useTranslation } from 'Components/Helpers/translate';
import { currencyFormat } from 'Components/Filters/Currencies';
import ProspectingClient from 'App/resources/Model/ProspectingClient';
import SoliditetClient from 'App/resources/Model/SoliditetClient';

type Props = {
	selectedClients: Client[];
	selectedBranches: ProspectingClient[] | SoliditetClient[];
};

const calculateTotalSalesSum = (selectedClients: Client[], salesModelOption: string | null) => {
	return selectedClients.reduce((acc, client) => {
		switch (salesModelOption) {
			case 'arr':
				return acc + (client.growth?.arrLast12Months || 0);
			case 'mrr':
				return acc + (client.growth?.mrrLast12Months || 0);
			case 'cm':
				return acc + (client.growth?.cmLast12Months || 0);
			case 'sales':
				return acc + (client.growth?.salesLast12Months || 0);
			default:
				return acc;
		}
	}, 0);
};

const Preview = (props: Props) => {
	const { selectedClients } = props;
	const { t } = useTranslation();
	const [numberOfContacts, setNumberOfContacts] = useState(0);
	const [numberOfOpenActivities, setNumberOfOpenActivities] = useState(0);
	const [opportunityValue, setOpportunityValue] = useState(0);
	const metadata = useMetadata();
	const currency = metadata?.defaultCurrency.iso || 'SEK';

	const firstClientName = selectedClients.length > 0 ? selectedClients[0].name : '';
	const selectedClientsLength = selectedClients.length;
	const selectedClientIds = useMemo(() => selectedClients.map(client => client?.id), [selectedClients]);

	const salesModelOption = metadata ? getSalesModelOption(metadata) : null;

	const fetchContactReq = useRef<null | CancelablePromise<Awaited<ReturnType<typeof ContactResource.find>>>>(null);
	const fetchActivityReq = useRef<null | CancelablePromise<Awaited<ReturnType<typeof ActivityListResource.find>>>>(
		null
	);
	const fetchOpportunityReq = useRef<null | CancelablePromise<Awaited<ReturnType<typeof OrderResource.find>>>>(null);

	const lang = {
		oneContact: t('contact').toLowerCase(),
		manyContacts: t('contacts'),
		oneActivity: t('default.activity').toLowerCase(),
		manyActivities: t('default.activities'),
		title: t('createRelation.subaccount.preview.title'),
		from: t('default.from').toLowerCase(),
		arr: t('default.annualValue'),
		mrr: t('default.monthlyValue'),
		info: t('createRelation.subaccount.preview.info'),
		opportunities: t('default.opportunities'),
		sales: t('default.sales')
	};

	const fetchContacts = () => {
		const rb = new RequestBuilder();
		rb.addFilter(ContactAttributes.client.attr.id, ComparisonTypes.Equals, selectedClientIds);
		rb.addFilter(ContactAttributes.active, ComparisonTypes.Equals, true);
		rb.limit = 0;

		fetchContactReq.current = makeCancelable(ContactResource.find(rb.build()));
		fetchContactReq.current.promise
			.then(res => {
				setNumberOfContacts(res.metadata?.total ?? 0);
			})
			.catch(err => logError(err, 'Failed to fetch contacts'));
	};

	const fetchOpenActivities = () => {
		const rb = new RequestBuilder();
		rb.addFilter({ field: 'client.id' }, ComparisonTypes.Equals, selectedClientIds);
		const orBuilder = rb.orBuilder();
		orBuilder.next();
		orBuilder.addFilter({ field: 'isAppointment' }, ComparisonTypes.Equals, false);
		orBuilder.addFilter({ field: 'closeDate' }, ComparisonTypes.Equals, null);
		orBuilder.next();
		orBuilder.addFilter({ field: 'isAppointment' }, ComparisonTypes.Equals, true);
		orBuilder.addFilter({ field: 'outcome' }, ComparisonTypes.Equals, 'planned');
		orBuilder.done();
		rb.limit = 0;

		fetchActivityReq.current = makeCancelable(ActivityListResource.find(rb.build()));
		fetchActivityReq.current.promise
			.then(res => {
				setNumberOfOpenActivities(res.metadata?.total ?? 0);
			})
			.catch(err => logError(err, 'Failed to fetch activities'));
	};

	const fetchOpportunities = () => {
		const rb = new RequestBuilder();
		rb.addFilter(OrderAttributes.probability, ComparisonTypes.NotEquals, 100);
		rb.addFilter(OrderAttributes.account, ComparisonTypes.Equals, selectedClientIds);
		rb.limit = 1000;

		fetchOpportunityReq.current = makeCancelable(OrderResource.find(rb.build()));
		fetchOpportunityReq.current.promise
			.then(res => {
				const values = res.data.map((opportunity: any) => {
					return opportunity.valueInMasterCurrency || 0;
				});

				const sum = values.reduce((acc: number, value: number) => acc + value, 0);
				setOpportunityValue(sum);
			})
			.catch(err => logError(err, 'Failed to fetch opportunities'));
	};

	useEffect(() => {
		if (selectedClientsLength > 0) {
			fetchContacts();
			fetchOpenActivities();
			fetchOpportunities();
		}

		return () => {
			fetchContactReq.current?.cancel();
			fetchActivityReq.current?.cancel();
			fetchOpportunityReq.current?.cancel();
		};
	}, [selectedClientIds]);

	const totalSalesSum = calculateTotalSalesSum(selectedClients, salesModelOption);

	const hasDataInAllValues =
		totalSalesSum > 0 && opportunityValue > 0 && numberOfContacts > 0 && numberOfOpenActivities > 0;

	return (
		<Block space="ptl">
			<Card space="ptl pll pbl prl" color="grey-1" borderRadius border="bs ts rs ls" borderColor="grey-4">
				<Flex direction="column" gap="u5">
					{selectedClientsLength > 0 ? (
						<Text bold>
							{lang.title} {lang.from} {firstClientName}
							{selectedClientsLength > 1 ? ` +${selectedClientsLength - 1}` : null}
						</Text>
					) : (
						<Text bold>{lang.title}</Text>
					)}
					<Block>
						<Flex direction="column" gap="u4">
							<Flex alignItems="center">
								<Icon name="check" space="mrs" />
								{hasDataInAllValues ? (
									<Text>
										{`+${currencyFormat(totalSalesSum, currency)} ${lang.sales.toLowerCase()}`}
										{salesModelOption === 'arr'
											? ` (${lang.arr})`
											: salesModelOption === 'mrr'
											? ` (${lang.mrr})`
											: null}
									</Text>
								) : (
									<Text>{lang.sales}</Text>
								)}
							</Flex>
							<Flex alignItems="center">
								<Icon name="check" space="mrs" />
								{hasDataInAllValues ? (
									<Text>
										{`+${currencyFormat(opportunityValue, currency)} `}
										{lang.opportunities.toLowerCase()}
									</Text>
								) : (
									<Text>{lang.opportunities}</Text>
								)}
							</Flex>
							<Flex alignItems="center">
								<Icon name="check" space="mrs" />
								{hasDataInAllValues && numberOfContacts > 0 ? (
									<Text>
										{`+${numberOfContacts} `}
										{numberOfContacts === 1 ? lang.oneContact : lang.manyContacts.toLowerCase()}
									</Text>
								) : (
									<Text>{lang.manyContacts}</Text>
								)}
							</Flex>

							<Flex alignItems="center">
								<Icon name="check" space="mrs" />
								{hasDataInAllValues && numberOfOpenActivities > 0 ? (
									<Text>
										{`+${numberOfOpenActivities} `}
										{numberOfOpenActivities === 1
											? lang.oneActivity
											: lang.manyActivities.toLowerCase()}
									</Text>
								) : (
									<Text>{lang.manyActivities}</Text>
								)}
							</Flex>
						</Flex>
					</Block>

					<Flex alignItems="center">
						<Icon name="info-circle" color="green" />
						<Text space="mlm" color="grey-11">
							{lang.info}
						</Text>
					</Flex>
				</Flex>
			</Card>
		</Block>
	);
};

export default Preview;
