import { StaticJourneyStepSelect } from 'App/components/Inputs/Selects/StaticJourneyStepSelect/StaticJourneyStepSelect';
import { StaticCategoryMultiSelect } from 'App/components/Inputs/Selects/StaticCategorySelect/StaticCategorySelect';
import { StaticPriceListSelect } from 'App/components/Inputs/Selects/StaticPriceListSelect/StaticPriceListSelect';
import { StaticUserSelect } from 'App/components/Inputs/Selects/StaticUserSelect/StaticUserSelect';
import CustomField, { CustomFieldWithValue } from 'App/resources/Model/CustomField';
import { CoreSelectItem } from 'App/components/Inputs/Selects/StaticSelect';
import ModalControls from '@upsales/components/ModalControls/ModalControls';
import { defaultFilterGetter } from 'App/helpers/multiActionRunnerHelpers';
import ButtonSelect from '@upsales/components/ButtonSelect/ButtonSelect';
import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import ModalContent from '@upsales/components/ModalContent/ModalContent';
import ModalHeader from '@upsales/components/ModalHeader/ModalHeader';
import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';
import { MultiSelect } from '../../ListView/ListViewRenderHelpers';
import { PropertyMap, PropertyArray } from '../MultiActionModal';
import { mapProperties } from 'Services/ActionProperties';
import { useTranslation } from 'Components/Helpers/translate';
import { ModalProps } from 'App/components/Modals/Modals';
import JourneyStep from 'App/resources/Model/JourneyStep';
import BemClass from '@upsales/components/Utils/bemClass';
import Column from '@upsales/components/Column/Column';
import PriceList from 'App/resources/Model/PriceList';
import RequestBuilder from 'Resources/RequestBuilder';
import LabeledInput from '../../Inputs/LabeledInput';
import Modal from '@upsales/components/Modal/Modal';
import Block from '@upsales/components/Block/Block';
import Category from 'App/resources/Model/Category';
import { Flex, Link, Select, Text } from '@upsales/components';
import React, { useEffect, useState } from 'react';
import { prepDates } from 'Helpers/multiModals';
import './MultiUpdateClient.scss';
import { ProjectIdName } from 'App/resources/Model/Project';

type Props = {
	entity: string;
	extraParams?: any;
	filters: RequestBuilder;
	isTrigger?: boolean;
} & (
	| {
			onSave: (props: PropertyArray) => Promise<void>;
			multiSelect?: never;
	  }
	| {
			multiSelect: MultiSelect;
			onSave?: never;
	  }
) &
	ModalProps;

type Field = { id: string; name: string; value?: any; dates?: Field[]; datatype?: string; editable: boolean };

var arrayProperties = ['AddProject', 'RemoveProject', 'AddCategories', 'RemoveCategories'];

const MultiUpdateClient = ({ filters, multiSelect, entity, onSave, close, extraParams, className }: Props) => {
	const { t } = useTranslation();

	const modalRef = document.querySelector('.Modals') || undefined;

	const classes = new BemClass('MultiUpdateClient', className);

	const userTags = [
		{
			id: '{{General.CurrentUserId}}',
			title: t('tag.general.currentuser')
		}
	];

	const [properties, setProperties] = useState<PropertyMap>({});

	const [addCampaigns, setAddCampaigns] = useState<ProjectIdName[]>([]);
	const [removeCampaigns, setRemoveCampaigns] = useState<ProjectIdName[]>([]);

	const [addCategories, setAddCategories] = useState<CoreSelectItem<Category>[]>([]);
	const [removeCategories, setRemoveCategories] = useState<CoreSelectItem<Category>[]>([]);

	const [userChangeType, setUserChangeType] = useState<'Add' | 'Set' | 'Remove'>('Add');
	const [selectedUser, setSelectedUser] = useState<CoreSelectItem | null>(null);

	const [journeyStep, setJourneyStep] = useState<CoreSelectItem<JourneyStep> | null>(null);
	const [priceList, setPriceList] = useState<CoreSelectItem<PriceList> | null>(null);
	const [availableFields, setAvailableFields] = useState<CustomFieldWithValue[]>([]);
	const [customFields, setCustomFields] = useState<CustomFieldWithValue[]>([]);
	const customerId = Tools.AppService.getCustomerId();
	const customDateTypes = prepDates(entity);

	const hasPriceList =
		Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PRICE_LISTS) &&
		Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.PRICE_LISTS);

	const hasJourney = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.JOURNEY_STATUS);
	const metadata = Tools.AppService.getMetadata();
	const showJourneyStep = true;

	const save = async () => {
		const propArray: PropertyArray = [];

		Tools.ActionProperties.mapCustomFields(propArray, customFields, 'Client');

		if (onSave) {
			return onSave(propArray);
		}

		const selectedFilters = defaultFilterGetter(filters, multiSelect);

		propArray.push(...mapProperties(properties, arrayProperties));

		propArray.forEach(prop => {
			if (prop.name === 'User' && userChangeType !== 'Set') {
				prop.name = userChangeType + 'User';
			}
		});

		Tools.MultiActions.customer(Tools.AppService.getCustomerId()).updateAccount(
			entity || 'Client',
			multiSelect.selectedIds.length,
			propArray,
			selectedFilters.build(),
			extraParams
		);
	};

	const createCampaign = async () => {
		return Tools.$upModal
			.open('editCampaign', { customerId: customerId, noRedirect: true })
			.then(function (campaign) {
				if (campaign) {
					const updatedCampaigns = [...addCampaigns, campaign];
					setAddCampaigns(updatedCampaigns);
				}
			});
	};

	const getAvailableFields = (startingFields: CustomField[], customDateTypes: { [key: string]: any }) => {
		let newFields = JSON.parse(JSON.stringify(startingFields));

		newFields = newFields.filter((field: Field) => field.editable);

		return newFields.map((field: Field) => {
			if (customDateTypes && field.datatype === 'Date') {
				field.dates = Object.values(customDateTypes);
			}
			return field;
		});
	};
	useEffect(() => {
		setAvailableFields(getAvailableFields(Tools.AppService.getCustomFields('client'), customDateTypes));
	}, []);

	const handleCustomFieldChange = (value: {
		id: CustomFieldWithValue['id'];
		title: CustomFieldWithValue['name'];
	}) => {
		const selectedField = availableFields.find(field => field.id === value.id);
		if (selectedField) {
			setCustomFields([...customFields, selectedField]);
		}

		setAvailableFields(availableFields.filter(field => field.id !== value.id));
	};
	const removeCustomField = (field: CustomFieldWithValue) => {
		setCustomFields(customFields.filter(f => f.id !== field.id));
		setAvailableFields([...availableFields, field]);
	};

	const handleValueChange = (field: CustomFieldWithValue, value: string) => {
		setProperties({
			...properties,
			['Custom_' + field.id]: value || null
		});

		setCustomFields(
			customFields.map(f => {
				if (f.id === field.id) {
					return { ...f, value: value };
				}
				return f;
			})
		);
	};
	return (
		<Modal size="lg" className={classes.b()}>
			<form onSubmit={save}>
				<ModalHeader title={t('default.updateAccounts')} onClose={close} icon="edit" />
				<ModalContent>
					<div className="row">
						<div className="col-md-6">
							<div className="form-group">
								<Text bold={true} space="mbs">
									{`${t('default.add')} ${t('default.campaigns')}`}
									<Link
										nostyle={true}
										className={classes.elem('create-campaign').b()}
										onClick={createCampaign}
									>
										{t('default.create')} {t('default.new').toLowerCase()}
									</Link>
								</Text>
								<ReactTemplates.INPUTS.upCampaigns
									multiple={true}
									value={addCampaigns || []}
									onChange={campaign => {
										setProperties({
											...properties,
											AddProject: campaign.length ? campaign.map(e => e.id) : []
										});
										setAddCampaigns(campaign ?? []);
									}}
									placeholder={t('campaign.chooseCampaign')}
								/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<Text bold={true} space="mbs">
									{`${t('default.remove')} ${t('default.campaigns')}`}
								</Text>
								<ReactTemplates.INPUTS.upCampaigns
									multiple={true}
									value={removeCampaigns || []}
									onChange={campaign => {
										setProperties({
											...properties,
											RemoveProject: campaign.length ? campaign.map(e => e.id) : []
										});
										setRemoveCampaigns(campaign ?? []);
									}}
									placeholder={t('campaign.chooseCampaign')}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<LabeledInput title={`${t('default.add')} ${t('default.categories')}`}>
								<StaticCategoryMultiSelect
									data-testid="selectAddCategories"
									categoryType="account"
									value={addCategories}
									onChange={value => {
										setProperties({
											...properties,
											AddCategories: value.length ? value.map(e => e.id) : []
										});
										setAddCategories(value);
									}}
									anchor={modalRef}
								/>
							</LabeledInput>
						</div>
						<div className="col-md-6">
							<LabeledInput title={`${t('default.remove')} ${t('default.categories')}`}>
								<StaticCategoryMultiSelect
									data-testid="selectRemoveCategories"
									categoryType="account"
									value={removeCategories}
									onChange={value => {
										setProperties({
											...properties,
											RemoveCategories: value.length ? value.map(e => e.id) : []
										});
										setRemoveCategories(value);
									}}
									anchor={modalRef}
								/>
							</LabeledInput>
						</div>
					</div>
					{metadata.params.teamAccountManager ? (
						<Block space="mts">
							<ButtonSelect
								data-testid="selectUserChangeType"
								size="sm"
								value={userChangeType}
								options={[
									{ value: 'Add' as const, title: t('default.add') },
									{ value: 'Set' as const, title: t('multi.setAsOnlyAccountManager') },
									{ value: 'Remove' as const, title: t('default.remove') }
								]}
								onChange={setUserChangeType}
							/>
						</Block>
					) : null}
					<div className="row">
						<div className="col-md-6">
							<LabeledInput title={t('default.accountManager')}>
								<StaticUserSelect
									data-testid="selectAccountManager"
									extraOptions={userTags}
									value={selectedUser}
									onChange={value => {
										setSelectedUser(value);
										setProperties({ ...properties, User: value?.id ?? null });
									}}
									anchor={modalRef}
								/>
							</LabeledInput>
						</div>
						{hasJourney && showJourneyStep ? (
							<div className="col-md-6">
								<LabeledInput title={t('default.journeyStep')}>
									<StaticJourneyStepSelect
										data-testid="selectJourneyStep"
										value={journeyStep}
										onChange={value => {
											setProperties({
												...properties,
												JourneyStep: value?.id ?? null
											});
											setJourneyStep(value);
										}}
										anchor={modalRef}
									/>
								</LabeledInput>
							</div>
						) : null}
						{hasPriceList ? (
							<div className="col-md-6">
								<LabeledInput title={t('default.priceList')}>
									<StaticPriceListSelect
										value={priceList}
										onChange={value => {
											setProperties({
												...properties,
												PriceListId: value?.id ?? null
											});
											setPriceList(value);
										}}
										anchor={modalRef}
									/>
								</LabeledInput>
							</div>
						) : null}
					</div>
					{availableFields.length ? (
						<>
							<legend>
								<span>{t('default.otherInfo')}</span>
							</legend>
							<div className="row">
								{customFields.map((field, index) => (
									<div className="col-md-6" key={field.id}>
										<div className="form-group">
											<Flex justifyContent="space-between">
												<Text className={classes.elem('custom-title').b()} bold>
													{field.name}
													<Text
														size="lg"
														color="red"
														bold={true}
														style={{ display: field.obligatoryField ? 'inline' : 'none' }}
													>
														&bull;
													</Text>
												</Text>
												<ThirdButton
													className={classes.elem('remove-custom').b()}
													icon={'trash'}
													onClick={e => removeCustomField(field)}
													size="sm"
													color="blue"
												/>
											</Flex>
											<ReactTemplates.INPUTS.customFieldInput
												anchor={modalRef}
												field={field}
												name={field.name}
												className={classes.elem('custom-field-input').b()}
												multiple
												onChange={(value: string) => handleValueChange(field, value)}
												usenewdate
												useNewTime
												useNumberInput
											/>
										</div>
									</div>
								))}
								<div className="col-md-6">
									<Select
										data-testid="Input_field"
										anchor={modalRef}
										options={availableFields.map(field => ({
											id: field.id,
											title: field.name
										}))}
										value={null}
										onChange={handleCustomFieldChange}
										disabled={!availableFields.length}
										className={classes.elem('custom-field-select').b()}
										placeholder={t('default.add') + ' ' + t('default.field').toLowerCase()}
									/>
								</div>
							</div>
						</>
					) : null}
				</ModalContent>
				<ModalControls>
					<Column align="right">
						<PrimaryButton
							onClick={() => {
								save();
								close();
							}}
						>
							{t('default.save')}
						</PrimaryButton>
						<ThirdButton onClick={() => close()}>{t('default.cancel')}</ThirdButton>
					</Column>
				</ModalControls>
			</form>
		</Modal>
	);
};

export const openMultiUpdateClientModal = asyncModalAdapter({
	upModalName: 'UpdateClientMulti',
	openModalName: 'MultiUpdateClient',
	featureFlag: 'REACT_MULTI_UPDATE_CLIENT'
});
export default MultiUpdateClient;
