import SubscriptionIndexingState, { State } from '../SubscriptionIndexingState/SubscriptionIndexingState';
import AgreementResource from 'Resources/Agreement';
import SubscriptionList from './SubscriptionList';
import React, { useCallback } from 'react';
import RequestBuilder from 'Resources/RequestBuilder';
import { useSoftDeployAccess } from 'App/components/hooks';
import Agreement from 'App/resources/Model/Agreement';
import { Banner, Button, Text } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import { Action } from '../SubscriptionIndexingState/SubscriptionIndexingActions';
import BemClass from '@upsales/components/Utils/bemClass';

import './SubscriptionListConflict.scss';
import logError from 'Helpers/logError';

type Props = {
	state: State;
	dispatch: React.Dispatch<Action>;
	setConflictDecision: (agreement: Agreement, decision: string) => void;
	indexConflictDecisionsMap: State['indexConflictDecisionsMap'];
};

const SubscriptionListConflict = ({ state, dispatch, indexConflictDecisionsMap }: Props) => {
	const { id, filter, excludingSubscriptions } = state;
	const classes = new BemClass('SubscriptionListConflict');

	const { t } = useTranslation();
	const {
		excludeSubscriptionId,
		includeSubscriptionId,
		excludeAllConflicts,
		setIsLoadingSubscriptions,
		setHasConflicts
	} = SubscriptionIndexingState(dispatch);
	const conflictCount = Object.keys(indexConflictDecisionsMap).length;

	const hasConflictHandling = useSoftDeployAccess('INDEXING_CONFLICT_HANDLING');

	const getData = useCallback(
		async (rb: RequestBuilder) => {
			setIsLoadingSubscriptions(true);
			const { sort } = rb.build();
			const subFilter = { ...filter, sort };
			subFilter.limit = rb?.limit ?? 25;
			subFilter.offset = rb?.offset ?? 0;

			return AgreementResource.getAgreementsWithConflicts(subFilter, id)
				.then(res => {
					setHasConflicts(res?.metadata?.total ? true : false);
					return res;
				})
				.catch(err => {
					logError(err, 'Could not load conflicts');
					return { data: [], metadata: { total: 0 } };
				})
				.finally(() => {
					setIsLoadingSubscriptions(false);
				});
		},
		[filter]
	);

	if (!hasConflictHandling) return null;

	const columns = ['info', 'lastIndexIncreaseDate', 'increasedPeriodValue', 'exclude'];

	return (
		<>
			{conflictCount ? (
				<Banner
					color="black"
					icon="warning"
					state="warning"
					className={classes.toString()}
					title={''}
					renderSubtitle={() => (
						<Text>
							{t(`subscription.indexing.summary.conflicts.description`, {
								count: conflictCount
							})}
						</Text>
					)}
				>
					<Button
						disabled={excludingSubscriptions}
						onClick={() => {
							excludeAllConflicts(filter);
						}}
					>
						{t('subscription.indexing.summary.conflicts.description.excludeThese')}
					</Button>
				</Banner>
			) : null}
			<SubscriptionList
				indexConflictDecisionsMap={indexConflictDecisionsMap}
				includeOrExcludeSub={(type, id) =>
					type === 'exclude' ? excludeSubscriptionId(id) : includeSubscriptionId(id)
				}
				tableLimitOptions={[5, 25]}
				state={state}
				getData={getData}
				columns={columns}
			/>
		</>
	);
};

export default SubscriptionListConflict;
