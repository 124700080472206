import React, { FC, useEffect, useState } from 'react';

import { SalesboardListView } from 'App/resources/AllIWant';
import { Modal } from '@upsales/components';
import { ModalProps } from '../Modals/Modals';
import EditRole from 'Components/EditRole';
import { FormatedSalesboard } from 'Components/EditRole/EditRole';
import { AccessChanged, RoleAccess } from 'Components/EditRole/EditRoleAccessTable';
import BemClass from '@upsales/components/Utils/bemClass';
import { RolePermissionValues, AccessRolePermissionValues } from '../../enum/Role';
import { SalesboardHelper } from 'App/upsales/domain/admin/controllers/editRoleSalesboardHelper';
import T from 'Components/Helpers/translate';
import { getListViews } from 'Store/selectors/AppSelectors';
import Role from 'App/resources/Model/Role';

type Props = ModalProps<string> & {
	roleId?: number;
};

type Store = {
	name: string;
	description: string;
	parentId: number;
	saving: boolean;
	hasDiscount: boolean;
	roleAccess: RoleAccess[];
	roleId: number;
	defaultCurrency: string;
	hasMultiCurrency: boolean;
	currencies: any[];
	parsingRoleSettings: boolean;
	defaultSalesboard: any;
	salesboards: any[];
	hasMultiSalesboards: boolean;
};

const EditRoleModal: FC<Props> = ({ close, roleId: roleIdProp, ...props }) => {
	const [store, setStoreState] = useState<Store>({
		name: '',
		description: '',
		parentId: 0,
		saving: false,
		hasDiscount: true,
		roleAccess: [],
		roleId: 0,
		defaultCurrency: '',
		hasMultiCurrency: false,
		currencies: [],
		parsingRoleSettings: false,
		defaultSalesboard: {},
		salesboards: [],
		hasMultiSalesboards: false
	});

	const setStore = (data: Partial<Store>) => {
		setStoreState(prev => ({ ...prev, ...data }));
	};

	const setOne = (key: keyof Store, value: any) => {
		setStore({ [key]: value });
	};

	var mapObject = function (obj: RoleAccess[]) {
		var templateData: { [key: string]: any } = {
			access: {},
			modify: {},
			delete: {}
		};
		var values = [
			RolePermissionValues.NO,
			RolePermissionValues.OWN,
			RolePermissionValues.ALL,
			AccessRolePermissionValues.TOP,
			AccessRolePermissionValues.OWN_AND_NO_USER,
			AccessRolePermissionValues.ROLE_AND_NO_USER
		];

		obj.forEach(function (entity) {
			if (entity.access) {
				var accessVal = values.indexOf(entity.access.selectedValue);
				templateData.access[entity.entity] = accessVal !== -1 ? accessVal : 1;
			}
			if (entity.modify) {
				var modifyVal = values.indexOf(entity.modify.selectedValue);
				templateData.modify[entity.entity] = modifyVal !== -1 ? modifyVal : 1;
			}
			if (entity.delete) {
				var deleteVal = values.indexOf(entity.delete.selectedValue);
				templateData.delete[entity.entity] = deleteVal !== -1 ? deleteVal : 1;
			}
		});
		return templateData;
	};

	var actions = {
		save: function () {
			setStore({ saving: true });

			// MAP ROLE
			var role = Tools.Role.new() as Omit<Role, 'parent'> & { templateData: Tools.TemplateData } & {
				parent: Partial<Role>;
			};
			role.name = store.name;
			role.description = store.description;
			role.hasDiscount = store.hasDiscount;
			role.templateData = mapObject(store.roleAccess) as Tools.TemplateData;

			const editingOwnRoleCurrency =
				role.defaultCurrency !== store.defaultCurrency && store.roleId === Tools.AppService.getSelf().role?.id;
			if (editingOwnRoleCurrency) {
				const metadata = Tools.AppService.getMetadata();
				metadata.role.defaultCurrency = store.defaultCurrency;
				Tools.AppService.setMetadata(metadata);
			}
			role.defaultCurrency = store.defaultCurrency;
			role.defaultSalesboardId = store.defaultSalesboard?.id ?? null;

			if (roleIdProp) {
				role.id = roleIdProp;
			}

			role.parent = {
				id: store.parentId !== -1 ? store.parentId : 0
			};

			Tools.Role.save(role as Role)
				.then(function (res: any) {
					if (res.error) {
						close(Tools.$translate('saveError.role'));
					}
					close();
				})
				.catch(function () {
					setStore({ saving: false });
				});
		},
		close: function () {
			close();
		},
		rootPropChanged: function (key: string, value: string | number | FormatedSalesboard | boolean) {
			setOne(key as keyof Store, value);
		},
		accessChanged: function (entity, type, value) {
			var roleAccess = store.roleAccess;

			const ra = store.roleAccess.find(r => r.entity === entity);
			ra![type]!.selectedValue = value;
			setStore({ roleAccess: roleAccess });
		} as AccessChanged
	};

	var roleAccess: RoleAccess[] = [
		{
			name: Tools.$translate('default.account'),
			namePlural: Tools.$translate('default.accounts'),
			group: 'sales',
			entity: 'Client',
			access: {
				selectedValue: RolePermissionValues.OWN,
				values: [RolePermissionValues.OWN, RolePermissionValues.ALL, AccessRolePermissionValues.TOP]
			},
			modify: {
				selectedValue: RolePermissionValues.OWN,
				values: [RolePermissionValues.OWN, RolePermissionValues.ALL]
			},
			delete: {
				selectedValue: RolePermissionValues.NO,
				values: [RolePermissionValues.NO, RolePermissionValues.OWN, RolePermissionValues.ALL]
			}
		},
		{
			name: Tools.$translate('default.contact'),
			namePlural: Tools.$translate('default.contacts'),
			group: 'sales',
			entity: 'Contact',
			access: {
				selectedValue: RolePermissionValues.OWN,
				values: [RolePermissionValues.OWN, RolePermissionValues.ALL, AccessRolePermissionValues.TOP]
			},
			modify: {
				selectedValue: RolePermissionValues.OWN,
				values: [RolePermissionValues.OWN, RolePermissionValues.ALL]
			},
			delete: {
				selectedValue: RolePermissionValues.NO,
				values: [RolePermissionValues.NO, RolePermissionValues.OWN, RolePermissionValues.ALL]
			}
		},
		{
			name: Tools.$translate('default.activity'),
			namePlural: Tools.$translate('default.activities'),
			group: 'sales',
			entity: 'Activity',
			access: {
				selectedValue: RolePermissionValues.OWN,
				values: [RolePermissionValues.OWN, RolePermissionValues.ALL, AccessRolePermissionValues.TOP]
			},
			modify: {
				selectedValue: RolePermissionValues.OWN,
				values: [RolePermissionValues.NO, RolePermissionValues.OWN, RolePermissionValues.ALL]
			},
			delete: {
				selectedValue: RolePermissionValues.NO,
				values: [RolePermissionValues.NO, RolePermissionValues.OWN, RolePermissionValues.ALL]
			}
		},
		{
			name: Tools.$translate('default.order'),
			namePlural: Tools.$translate('default.order'),
			group: 'sales',
			entity: 'Order',
			modify: {
				selectedValue: RolePermissionValues.OWN,
				values: [RolePermissionValues.NO, RolePermissionValues.OWN, RolePermissionValues.ALL]
			},
			delete: {
				selectedValue: RolePermissionValues.NO,
				values: [RolePermissionValues.NO, RolePermissionValues.OWN, RolePermissionValues.ALL]
			}
		} as RoleAccess,
		{
			name: Tools.$translate('default.opportunity'),
			namePlural: Tools.$translate('default.opportunities'),
			group: 'sales',
			entity: 'Opportunity',
			access: {
				selectedValue: RolePermissionValues.OWN,
				values: [RolePermissionValues.OWN, RolePermissionValues.ALL, AccessRolePermissionValues.TOP]
			},
			modify: {
				selectedValue: RolePermissionValues.OWN,
				values: [RolePermissionValues.OWN, RolePermissionValues.ALL]
			},
			delete: {
				selectedValue: RolePermissionValues.NO,
				values: [RolePermissionValues.NO, RolePermissionValues.OWN, RolePermissionValues.ALL]
			}
		},
		{
			name: Tools.$translate('default.document'),
			namePlural: Tools.$translate('default.documents'),
			group: 'sales',
			entity: 'Document',
			access: {
				selectedValue: RolePermissionValues.OWN,
				values: [RolePermissionValues.OWN, RolePermissionValues.ALL, AccessRolePermissionValues.TOP]
			},
			delete: {
				selectedValue: RolePermissionValues.NO,
				values: [RolePermissionValues.NO, RolePermissionValues.OWN, RolePermissionValues.ALL]
			}
		},
		{
			name: Tools.$translate('default.recurringOrder'),
			namePlural: Tools.$translate('default.recurringOrders'),
			group: 'sales',
			entity: 'Agreement',
			access: {
				selectedValue: RolePermissionValues.OWN,
				values: [
					RolePermissionValues.NO,
					RolePermissionValues.OWN,
					RolePermissionValues.ALL,
					AccessRolePermissionValues.TOP
				]
			},
			modify: {
				selectedValue: RolePermissionValues.OWN,
				values: [RolePermissionValues.NO, RolePermissionValues.OWN, RolePermissionValues.ALL]
			},
			delete: {
				selectedValue: RolePermissionValues.NO,
				values: [RolePermissionValues.NO, RolePermissionValues.OWN, RolePermissionValues.ALL]
			}
		},
		{
			name: Tools.$translate('default.campaign'),
			namePlural: Tools.$translate('default.campaigns'),
			group: 'sales',
			entity: 'Project',
			access: {
				selectedValue: RolePermissionValues.OWN,
				values: [RolePermissionValues.OWN, RolePermissionValues.ALL, AccessRolePermissionValues.TOP]
			}
		},
		{
			name: Tools.$translate('salesboard.column.report'),
			namePlural: Tools.$translate('feature.reports'),
			group: 'sales',
			entity: 'Report',
			access: {
				selectedValue: RolePermissionValues.OWN,
				values: [RolePermissionValues.OWN, RolePermissionValues.ALL, AccessRolePermissionValues.TOP]
			}
		}
	];
	if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SOCIAL_EVENTS)) {
		roleAccess.push({
			name: T('default.socialEvent'),
			namePlural: T('default.socialEvents'),
			group: 'market',
			entity: 'SocialEvent',
			access: {
				selectedValue: RolePermissionValues.NO,
				values: [
					RolePermissionValues.NO,
					RolePermissionValues.OWN,
					RolePermissionValues.ALL,
					AccessRolePermissionValues.TOP
				]
			},
			modify: {
				selectedValue: RolePermissionValues.NO,
				values: [RolePermissionValues.NO, RolePermissionValues.OWN, RolePermissionValues.ALL]
			},
			delete: {
				selectedValue: RolePermissionValues.NO,
				values: [RolePermissionValues.NO, RolePermissionValues.OWN, RolePermissionValues.ALL]
			}
		});
	}
	if (Tools.FeatureHelper.hasSoftDeployAccess('CLIENT_TARGET')) {
		roleAccess.splice(1, 0, {
			name: T('client.target.editTarget'),
			namePlural: T('client.target.editTarget'),
			group: 'sales',
			entity: 'ClientQuotas',
			modify: {
				selectedValue: RolePermissionValues.NO,
				values: [RolePermissionValues.NO, RolePermissionValues.OWN, RolePermissionValues.ALL]
			}
		} as RoleAccess);
	}
	roleAccess[0].access!.values = [
		RolePermissionValues.OWN,
		RolePermissionValues.ALL,
		AccessRolePermissionValues.TOP,
		AccessRolePermissionValues.OWN_AND_NO_USER,
		AccessRolePermissionValues.ROLE_AND_NO_USER
	];

	var parseRoleSettings = function (roleId: number, roleAccess: RoleAccess[]) {
		return Tools.Role.getSettings(roleId).then(function (res) {
			if (!res) {
				return close(Tools.$translate('admin.usersAndRoles.roleNotFound'));
			}
			var templateData = res.templateData;
			var values = [
				RolePermissionValues.NO,
				RolePermissionValues.OWN,
				RolePermissionValues.ALL,
				AccessRolePermissionValues.TOP,
				AccessRolePermissionValues.OWN_AND_NO_USER,
				AccessRolePermissionValues.ROLE_AND_NO_USER
			];

			roleAccess.forEach(function (field: RoleAccess) {
				var accessValue = templateData.access[field.entity as keyof typeof templateData.access];
				var modifyValue = templateData.modify[field.entity as keyof typeof templateData.modify];
				var deleteValue = templateData.delete[field.entity as keyof typeof templateData.delete];
				if (field.access) {
					field.access.selectedValue =
						accessValue !== undefined ? values[accessValue] : RolePermissionValues.OWN;
					if (field.entity === 'SocialEvent' && !accessValue) {
						field.access.selectedValue = RolePermissionValues.NO;
					}
				}
				if (field.modify) {
					field.modify.selectedValue =
						modifyValue !== undefined ? values[modifyValue] : RolePermissionValues.OWN;
					if (field.entity === 'SocialEvent' && !modifyValue) {
						field.modify.selectedValue = RolePermissionValues.NO;
					}
				}
				if (field.delete) {
					field.delete.selectedValue =
						deleteValue !== undefined ? values[deleteValue] : RolePermissionValues.OWN;
					if (field.entity === 'SocialEvent' && !deleteValue) {
						field.delete.selectedValue = RolePermissionValues.NO;
					}
				}
			});

			var newStore: Partial<Store> = {
				roleAccess: roleAccess,
				name: res.name || '',
				description: res.description || '',
				parentId: res.parent ? res.parent.id : -1,
				roleId: res.id,
				parsingRoleSettings: false,
				hasDiscount: !!res.hasDiscount
			};

			var defaultCurrency = null;
			var metadata = Tools.AppService.getMetadata();
			if (metadata.params.MultiCurrency && res.defaultCurrency && res.defaultCurrency?.length) {
				defaultCurrency = metadata.customerCurrencies.find(c => c.iso === res.defaultCurrency);
				if (defaultCurrency) {
					newStore.defaultCurrency = defaultCurrency.iso;
				}
			}

			setStore(newStore);
		});
	};
	const salesboards = getListViews('salesboard') as SalesboardListView[];

	var init = function () {
		var metadata = Tools.AppService.getMetadata();
		var udos = metadata.params.UserDefinedObject;
		var parentId = 0;
		var roleId = 0;

		const roles = Tools.AppService.getRoles();
		const salesboardHelper = new SalesboardHelper({
			salesboards,
			roles,
			currentRoleId: roleIdProp
		});

		udos.forEach(function (udo) {
			roleAccess.push({
				name: udo.name || '<<UDO ' + udo.id + '>>',
				namePlural: udo.name || '<<UDO ' + udo.id + '>>',
				group: 'sales',
				entity: 'UserDefObj' + udo.id,
				access: {
					selectedValue: RolePermissionValues.OWN,
					values: [
						RolePermissionValues.NO,
						RolePermissionValues.OWN,
						RolePermissionValues.ALL,
						AccessRolePermissionValues.TOP
					]
				},
				modify: {
					selectedValue: RolePermissionValues.OWN,
					values: [RolePermissionValues.NO, RolePermissionValues.OWN, RolePermissionValues.ALL]
				},
				delete: {
					selectedValue: RolePermissionValues.NO,
					values: [RolePermissionValues.NO, RolePermissionValues.OWN, RolePermissionValues.ALL]
				}
			});
		});
		let parsingRoleSettings = true;
		if (roleIdProp) {
			parseRoleSettings(roleIdProp, roleAccess as RoleAccess[]);
		} else {
			parentId = -1;
			parsingRoleSettings = false;
		}

		if (metadata.params.MultiCurrency) {
			// find master
			var defaultCurrency = metadata.customerCurrencies.find(c => c.masterCurrency === true);
			if (!defaultCurrency) {
				// Select first if not found
				defaultCurrency = metadata.customerCurrencies[0];
			}
			setStore({
				name: '',
				description: '',
				parentId: parentId,
				saving: false,
				hasDiscount: true,
				roleAccess: roleAccess,
				roleId: roleId,
				defaultCurrency: defaultCurrency.iso,
				hasMultiCurrency: metadata.params.MultiCurrency,
				currencies: metadata.customerCurrencies.filter(currency => currency.active),
				parsingRoleSettings,
				defaultSalesboard: salesboardHelper.getDefaultSalesboard(),
				salesboards: salesboardHelper.getFormatedSalesboards(),
				hasMultiSalesboards: salesboardHelper.hasMultiSalesboards()
			});
		}
	};

	useEffect(() => {
		init();
	}, []);

	const classes = new BemClass('EditRoleModal FullscreenModal', props.className);

	return (
		<Modal className={classes.b()}>
			<EditRole actions={actions} {...store} />
		</Modal>
	);
};

export default EditRoleModal;
