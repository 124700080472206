import React from 'react';
import { connect } from 'react-redux';
import { Flex, Headline, ButtonBox, Card, Title, Tooltip, Block, Text } from '@upsales/components';
import EditorHeaderButton from 'Components/EditorHeader/EditorHeaderButton';
import Stages from '../Stages';
import T from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import { setTimeframe } from 'Store/reducers/SalesCoachReducer';
import { cloneDeep } from 'lodash';
import { Fade } from '@upsales/components/animations';
import { salesCoachTracker } from 'App/babel/helpers/Tracker';
import { getStages } from 'Store/actions/AppGetterActions';
import OrderStage from 'App/resources/Model/OrderStage';
import SalesProcessAddonHint from 'Components/Admin/SalesProcess/SalesCoach/SalesProcessAddonHint';
import { setupComponentCompatibility, asyncModalAdapter } from 'App/helpers/angularPortingHelpers';

import './VerifyTimeframe.scss';

type StageObj = {
	active: boolean;
	required?: boolean;
};

type Selected = {
	[key: number]: StageObj;
};

type Timeframe = { active: boolean; entireSalesProcess: boolean; stages: Selected };

type Props = {
	reject: () => void;
	timeframe: Timeframe;
	setTimeframe: (timeframe: Timeframe) => void;
	getStages: (
		type: 'all' | 'order' | 'opportunity' | 'lost' | 'won' | 'excludedIds',
		skipAuth: boolean
	) => OrderStage[] | number[];
};

type State = {
	timeframe: Timeframe;
};

const mapStateToProps = ({ SalesCoachReducer }: { SalesCoachReducer: { timeframe: Timeframe } }) => ({
	timeframe: SalesCoachReducer.timeframe
});

const mapDispatchToProps = { setTimeframe, getStages };

class VerifyTimeframe extends React.Component<Props, State> {
	lang: { [key: string]: string };

	constructor(p: Props) {
		super(p);

		this.lang = {
			cancel: T('default.cancel'),
			save: T('default.save'),
			descriptionTimeframe: T('admin.newSalesProcess.descriptionTimeframe'),
			atWhatPoint: T('admin.newSalesProcess.atWhatPoint'),
			specificStage: T('admin.newSalesProcess.specificStage'),
			entireSalesProcess: T('admin.newSalesProcess.entireSalesProcess'),
			verifyingTiming: T('admin.newSalesProcess.verifyingTiming'),
			backToChecklist: T('admin.newSalesProcess.backToChecklist'),
			chooseStagesToSave: T('admin.newSalesProcess.chooseStagesToSave')
		};
		this.state = { timeframe: cloneDeep(p.timeframe) };
	}

	track = (id: number, active: boolean) => {
		const { getStages } = this.props;
		const probability = (getStages('opportunity', false) as OrderStage[]).find(
			stage => stage.id === id
		)?.probability;
		if (!probability) {
			return;
		}

		const { ACTIVATED_TIMEFRAME, DEACTIVATED_TIMEFRAME } = salesCoachTracker.events;
		const event = active ? ACTIVATED_TIMEFRAME : DEACTIVATED_TIMEFRAME;
		salesCoachTracker.track(event, { probability });
	};

	flipSelected = (id: number) => {
		const { timeframe } = this.state;
		if (!timeframe.stages[id]) {
			timeframe.stages[id] = { active: false };
		}
		timeframe.stages[id].active = !timeframe.stages[id].active;
		if (!timeframe.stages[id].active && timeframe.stages[id]?.required) {
			timeframe.stages[id].required = false;
		}

		this.track(id, timeframe.stages[id].active);
		this.setState({ timeframe });
	};

	flipRequired = (id: number) => {
		const { timeframe } = this.state;
		if (!timeframe.stages[id]) {
			timeframe.stages[id] = { active: false };
		}
		timeframe.stages[id].required = !timeframe.stages[id]?.required;
		if (timeframe.stages[id].required && !timeframe.stages[id]?.active) {
			timeframe.stages[id].active = true;
		}
		this.setState({ timeframe });
	};

	toggleEntireSalesProcess = () => {
		const { timeframe } = this.state;
		if (timeframe.entireSalesProcess) {
			return;
		}

		this.setState({ timeframe: { ...timeframe, entireSalesProcess: true, stages: {} } });
	};

	toggleSpecificStage = () => {
		const { timeframe } = this.state;
		if (!timeframe.entireSalesProcess) {
			return;
		}

		this.setState({ timeframe: { ...timeframe, entireSalesProcess: false, stages: {} } });
	};

	haveNoStagesSelected() {
		if (!this.state.timeframe.entireSalesProcess) {
			const activeStage = Object.values(this.state.timeframe.stages).find(stage => {
				return stage.active;
			});
			return !activeStage;
		}
		return false;
	}

	render() {
		const { reject, setTimeframe } = this.props;
		const { timeframe } = this.state;

		const classNames = new BemClass('VerifyTimeframe');

		return (
			<Fade>
				<Flex className={classNames.b()} justifyContent="center" direction="column" gap="u7">
					<Card>
						<Flex justifyContent="space-between" flex={[0, 0, '50px']} alignItems="center" space="pll">
							<Title>{this.lang.verifyingTiming}</Title>
							<div>
								<EditorHeaderButton
									title={this.lang.cancel}
									onClick={() => {
										reject();
									}}
									supertitle={undefined}
									className={undefined}
									noIcon
									next={false}
								/>
								<Tooltip title={this.lang.chooseStagesToSave} disabled={!this.haveNoStagesSelected()}>
									<EditorHeaderButton
										title={this.lang.save}
										supertitle={this.lang.backToChecklist}
										disabled={this.haveNoStagesSelected()}
										onClick={() => {
											setTimeframe({ ...timeframe, active: true });
											reject();
										}}
										next
										className="VerifyTimeframe__EditorHeaderButton"
										noIcon={false}
									/>
								</Tooltip>
							</div>
						</Flex>
					</Card>
					<div className={classNames.elem('content').b()}>
						<Headline>{this.lang.verifyingTiming}</Headline>
						<Text color="grey-11">{this.lang.descriptionTimeframe}</Text>
						<Block space="mtxl">
							<Title size="lg">{this.lang.atWhatPoint}</Title>
						</Block>
						<Flex className={classNames.elem('buttonBoxes').b()} space="mtl" gap="u4">
							<ButtonBox
								onClick={this.toggleEntireSalesProcess}
								selected={timeframe.entireSalesProcess}
								title={this.lang.entireSalesProcess}
							/>
							<ButtonBox
								onClick={this.toggleSpecificStage}
								selected={!timeframe.entireSalesProcess}
								title={this.lang.specificStage}
							/>
						</Flex>
						<Fade visible={!timeframe.entireSalesProcess}>
							<Block space="mtl">
								<Stages selected={timeframe.stages} onToggleItem={this.flipSelected} />
								<SalesProcessAddonHint />
							</Block>
						</Fade>
					</div>
				</Flex>
			</Fade>
		);
	}
}
const Component = connect(mapStateToProps, mapDispatchToProps)(VerifyTimeframe);

export const VerifyTimeframeModal = setupComponentCompatibility(Component, {
	modalName: 'VerifyTimeframeModal FullscreenModal PortedVerifyTimeframe'
});

export const openVerifyTimeframeModal = asyncModalAdapter({
	upModalName: 'VerifyTimeframe',
	openModalName: 'VerifyTimeframeModal',
	featureFlag: 'REACT_VERIFY_TIMEFRAME'
});

export const detached = VerifyTimeframe;
export default Component;
