import { Modal, ModalContent, ModalHeader } from '@upsales/components';
import React from 'react';
import { useTranslation } from 'Components/Helpers/translate';
import { currencyFormat } from 'Components/Filters/Currencies';
import Opportunity from 'App/resources/Model/Opportunity';
import Order from 'App/resources/Model/Order';
import { ModalProps } from '../Modals/Modals';

type Props = ModalProps<Order> & {
	opportunies: Opportunity[];
};

export default (props: Props) => {
	const { t } = useTranslation();
	const { className, close, opportunies } = props;
	var f = Tools.$filter;
	return (
		<Modal size="sm" className={className}>
			<ModalHeader
				title={t('activity.connectOpportunityShort')}
				icon="usd"
				onClose={() => close()}
				style={{ paddingLeft: '10px' }}
			></ModalHeader>
			<ModalContent className="ModalContent--no-padding">
				<div className="up-panel" style={{ padding: 0 }}>
					<p style={{ padding: '10px', margin: 0 }}> {t('activity.doesItBelongDesc')} </p>
					<table className="table table-hover" style={{ margin: 0, cursor: 'pointer' }}>
						<tbody>
							{opportunies.map((opportunity, index) => (
								<tr key={index} onClick={() => close(opportunity)}>
									<td className="mid-mid">
										<i className="fa fa-usd"></i>
									</td>
									<td>
										<p>
											<b>
												{f('upsalesDate')(opportunity.date)} {opportunity.description}
											</b>
										</p>
										{currencyFormat(opportunity.value, opportunity.currency, true)} <br />
										{opportunity.user.name}
									</td>
								</tr>
							))}
							<tr onClick={() => close()}>
								<td className="mid-mid" width="50">
									<i className="fa fa-times"></i>
								</td>
								<td>
									<br />
									{t('activity.dontBelong')}
									<br />
									<br />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</ModalContent>
		</Modal>
	);
};
