import DistributionBar from 'App/pages/Reportcenter/ReportcenterWidget/Components/DistributionBar';
import { CancelablePromise, makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import { useCompanyGroupSelector } from '../../Context/CompanyGroupContext';
import { Flex, Title, Block, Text, Loader } from '@upsales/components';
import { currencyFormat } from 'Components/Filters/Currencies';
import { useTranslation } from 'Components/Helpers/translate';
import UpsellCompanyGroup from 'Resources/upsellCompanyGroup';
import type UpsellPotential from 'App/resources/Model/UpsellPotential';
import { useMetadata } from 'App/components/hooks/appHooks';
import { openDrawer } from 'Services/Drawer';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useEffect, useRef, useState } from 'react';
import logError from 'Helpers/logError';

import './UpsellWidget.scss';

const DefaultColors = ['bright-green', 'green', 'medium-turcose', 'orange', 'blue', 'bright-blue', 'purple'];
const classes = new BemClass('UpsellWidgetCompanyCard');

type DistributionType = {
	id: string;
	label: string;
	value: number;
	color?: string;
	striped?: boolean;
	disableTooltip?: boolean;
}[];

type RenderUpsellProps = {
	distribution: DistributionType;
	currency?: { iso: string; rate: number };
};

const RenderUpsell = ({ distribution, currency = { iso: 'SEK', rate: 1 } }: RenderUpsellProps) => {
	return (
		<>
			{distribution?.map((entry, idx) => {
				const fallbackColor = DefaultColors[idx % DefaultColors.length];
				const color = fallbackColor;
				return (
					<Flex key={entry.id} justifyContent="space-between" space="pbm">
						<Flex alignItems="center" gap="u2">
							<Block className={classes.elem('upsellDot').b()} backgroundColor={color} />
							<Text size="sm" color="grey-11" align="center">
								{entry.label}
							</Text>
						</Flex>
						<Flex alignItems="center">
							<Text bold size="sm">
								{currencyFormat(entry.value, currency.iso)}
							</Text>
						</Flex>
					</Flex>
				);
			})}
		</>
	);
};

export const roundNumber = (num: number) => {
	let divider = 1000;
	if (num === 0) {
		return 0;
	} else if (num < 10_000) {
		return 10_000;
	} else if (num < 100_000) {
		divider = 10_000;
	} else if (num < 250_000) {
		divider = 25_000;
	} else if (num < 500_000) {
		divider = 50_000;
	} else {
		divider = 100_000;
	}

	return Math.round(num / divider) * divider;
};

const UpsellWidget = () => {
	const { tree } = useCompanyGroupSelector(({ tree }) => ({
		tree
	}));
	const groupMotherOrgNumber = tree?.groupMotherOrgnumber;

	const { t } = useTranslation();

	const [potential, setPotential] = useState<{ newPotential: number; existingPotential: number }>({
		newPotential: 0,
		existingPotential: 0
	});
	const currency = useMetadata()?.defaultCurrency || { iso: 'SEK', rate: 1 };
	const salesModelParam = useMetadata()?.params.SalesModel ?? 'sales';
	const salesModelOption = useMetadata()?.params.SalesModelOption ?? 'arr';
	const [numberOfClients, setNumberOfClients] = useState<number>(0);
	const [upsellData, setUpsellData] = useState<UpsellPotential[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const upsellReq = useRef<CancelablePromise<Awaited<ReturnType<typeof UpsellCompanyGroup.fetchForGroup>>> | null>(
		null
	);

	const salesModel = salesModelParam === 'rr' ? salesModelOption : salesModelParam;

	useEffect(() => {
		if (!groupMotherOrgNumber) return;

		upsellReq.current?.cancel();
		setLoading(true);
		upsellReq.current = makeCancelable(UpsellCompanyGroup.fetchForGroup(groupMotherOrgNumber));

		upsellReq.current.promise
			.then(({ data }) => {
				setUpsellData(data);
				const aggregated = data.reduce(
					(acc, curr) => {
						if (curr.hasNewPotential) {
							acc.newPotential += curr.newPotential;
							acc.count++;
						}
						if (curr.hasTakeRatePotential) {
							acc.existingPotential += curr.takeRatePotential;
							acc.count++;
						}
						return acc;
					},
					{ newPotential: 0, existingPotential: 0, count: 0 }
				);

				setPotential({
					newPotential: roundNumber(aggregated.newPotential),
					existingPotential: roundNumber(aggregated.existingPotential)
				});
				setNumberOfClients(aggregated.count);
			})
			.catch(err => {
				logError(err, 'Failed to fetch upsell data');
			})
			.finally(() => setLoading(false));

		return () => {
			upsellReq.current?.cancel();
		};
	}, [groupMotherOrgNumber]);

	const distribution: DistributionType = [
		{
			id: '1',
			label: t('account.companyGroup.lowSpendingCompanies'),
			value: potential.existingPotential * currency.rate
		},
		{
			id: '2',
			label: t('account.companyGroup.newPotentialCustomers'),
			value: potential.newPotential * currency.rate
		}
	];

	const emptyStateDistribution: DistributionType = [
		{
			id: '1',
			label: '',
			value: 1,
			color: 'grey-11',
			striped: true,
			disableTooltip: true
		}
	];

	if (loading) {
		return (
			<Flex justifyContent="center" alignItems="center" space="pbxl">
				<Loader size="sm" />
			</Flex>
		);
	}

	const totalPotential = currencyFormat(
		(potential.newPotential + potential.existingPotential) * currency.rate,
		currency.iso
	);

	return (
		<Flex
			className={classes.b()}
			space="pbm ptl pll prl"
			gap="u3"
			direction="column"
			onClick={() =>
				numberOfClients > 0
					? openDrawer('UpsellCompanyGroup', {
							upsellData,
							currency,
							numberOfClients,
							totalPotential,
							salesModel
					  })
					: undefined
			}
		>
			<Flex gap="u1" alignItems="center" space="pbm">
				<Title>{t('account.companyGroup.upsellPotential')}</Title>
			</Flex>
			<Flex justifyContent="center" space="ptl">
				<Block>
					<Title bold className={classes.elem('bigNumbers').b()} color="black">
						{'~' + totalPotential}
					</Title>
					<Flex justifyContent="center">
						<Text size="xs" color="grey-11">
							{numberOfClients + ' ' + t(numberOfClients === 1 ? 'company' : 'companies').toLowerCase()}
						</Text>
					</Flex>
				</Block>
			</Flex>

			<Flex space="ptl pbl">
				<DistributionBar
					distribution={numberOfClients !== 0 ? distribution : emptyStateDistribution}
					datatype="currency"
					openedFromCompanyGroupCard
					thick
					currency={currency.iso}
				/>
			</Flex>

			<RenderUpsell distribution={distribution} currency={currency} />
		</Flex>
	);
};

export default UpsellWidget;
