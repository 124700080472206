import React, { FC, useState } from 'react';

import BemClass from '@upsales/components/Utils/bemClass';
import { Modal } from '@upsales/components';

import FileUpload, { FileUploadDropzone, FileUploadDropzoneActive } from 'Components/FileUpload';
import FileDropzoneTable from '../FileDropzoneTable/FileDropzoneTable';
import NotificationService from 'App/babel/NotificationService';
import { useTranslation } from 'Components/Helpers/translate';
import { acceptedFileTypes } from 'Components/FileBrowser';
import FileResource from 'App/babel/resources/File';
import { ModalProps } from '../Modals/Modals';

import './UploadFileModal.scss';

type UploadFileModalProps = ModalProps & {
	accountId?: number;
	folderId?: number;
	contactId?: number;
	orderId?: number;
	closeOnUpload?: boolean;
};

export type FileType = Partial<
	File & {
		id: number;
		name: string;
		size: number;
		mimetype: string;
		loading: boolean;
		progress: number;
		status: 'uploading' | 'success' | `error`;
	}
>;

const UploadFile: FC<UploadFileModalProps> = ({
	close,
	accountId,
	folderId,
	contactId,
	orderId,
	closeOnUpload,
	...props
}) => {
	const [files, setFiles] = useState<{ [key: number]: FileType }>([]);
	const { t } = useTranslation();
	const classes = new BemClass('UploadFileModal', props.className);

	const onUploadFile: <File>(files: File[]) => void = async rawfiles => {
		rawfiles.forEach((rawfile, i) => {
			const file = rawfile as FileType;
			const id = Date.now() + i;

			setFiles(prev => ({
				...prev,
				[id]: {
					id,
					name: file.name,
					size: file.size,
					mimetype: file.type,
					loading: true,
					progress: 0,
					status: 'uploading'
				}
			}));
			FileResource.upload(file, {
				accountId,
				folderId,
				contactId,
				orderId,
				onUploadProgress: (progress: number) =>
					setFiles(prev => ({
						...prev,
						[id]: { ...prev[id], progress }
					}))
			})
				.then(res => {
					setFiles(prev => ({ ...prev, [id]: { ...prev[id], loading: false, status: 'success' } }));
					NotificationService.add({
						style: NotificationService.style.SUCCESS,
						title: 'default.done',
						body: 'file.fileUploaded',
						icon: 'check'
					});

					Tools.$rootScope.$broadcast('file.uploaded', res);
					if (closeOnUpload) {
						close();
					}
				})
				.catch(err => {
					setFiles(prev => ({ ...prev, [id]: { ...prev[id], loading: false, status: 'error' } }));
					var errorBody = 'file.uploadFailed';

					const error = err?.response?.data?.error;

					if (error.errorCode === 114) {
						errorBody = 'file.uploaded.invalidType';
						// I think errorCode === 148 can never happen but keeping it here just in case.
					} else if (err === 'file.tooLarge' || error.errorCode === 148) {
						errorBody = 'file.tooLarge';
						setFiles(prev => {
							const newFiles = { ...prev };
							delete newFiles[id];
							return newFiles;
						});
					}
					NotificationService.add({
						style: NotificationService.style.ERROR,
						title: 'default.error',
						body: errorBody,
						icon: 'times'
					});
					Tools.$rootScope.$broadcast('file.uploadFailed', error);
					if (closeOnUpload) {
						close(err);
					}
				});
		});
	};

	return (
		<Modal className={classes.b()} id="upload-file">
			<div className={classes.elem('DropzoneWrapper').b()}>
				<FileUpload onDrop={onUploadFile} accept={Object.values(acceptedFileTypes).flat()}>
					{({ open, isDragActive }) => (
						<div>
							<FileUploadDropzoneActive visible={isDragActive} />
							<FileUploadDropzone title={t('file.dropToUpload')} onClick={open} iconName="cloud-upload" />
						</div>
					)}
				</FileUpload>
				<div>
					<a
						className="Button up-btn btn-link btn-green modal-close on-passive CloseButton"
						onClick={() => close()}
					>
						<i className="Icon Icon-times"></i>
					</a>
				</div>
			</div>
			<FileDropzoneTable files={Object.values(files)} />
		</Modal>
	);
};

export default UploadFile;
