const createTicketLocalStorage = <T>(key: string) => {
	const getKey = () => {
		const client = Tools.AppService.getAccountSelf().client;
		return `ls.ticket.${key}-${client.id}-${client.userId}`;
	};

	const getValue = (): T | null => {
		const item = localStorage.getItem(getKey());
		return item ? (JSON.parse(item) as T) : null;
	};

	const setValue = (value: T) => {
		localStorage.setItem(getKey(), JSON.stringify(value));
	};

	return { getValue, setValue };
};

export default createTicketLocalStorage;
