import React, { useEffect, useRef, useState } from 'react';
import {
	Flex,
	Block,
	Card,
	Table,
	TableRow,
	TableColumn,
	ClickableItem,
	Label,
	SelectAsync,
	Loader
} from '@upsales/components';
import {
	AccountManagers,
	ClientColumnSubtitle,
	ClientColumnTitle
} from 'App/babel/components/SubaccountDrawer/columnParts';
import { getFetcher } from 'App/babel/components/CreateRelation/AddRelation/AddRelation';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import Client from 'App/resources/Model/Client';
import { useTranslation } from 'Components/Helpers/translate';
import { PrimaryButton } from '@upsales/components/Buttons';
import ClientAttributes from 'App/babel/attributes/Client';
import openModal from 'App/services/Modal';
import BemClass from '@upsales/components/Utils/bemClass';
import ClientResource from 'App/resources/Client';
import { useGetData } from 'App/components/hooks';
import logError from 'Helpers/logError';
import BackButton from '../BackButton';

import './AddParentCompany.scss';
import { useModalClose } from 'App/components/Modals/Modals';

type Props = {
	client: Client;
	goBack: () => void;
	close: () => void;
	modalId: number;
};

const subaccountFetcher = (rb: RequestBuilder, { clientId }: { clientId: number }) => {
	rb.addFilter(ClientAttributes.operationalAccount.attr.id, comparisonTypes.Equals, clientId);
	rb.fields = ['id', 'name'];
	return ClientResource.find(rb.build());
};

const AddParentCompany = ({ goBack, client, close, modalId }: Props) => {
	const classes = new BemClass('AddParentCompany');
	const { t } = useTranslation();
	const [parentCompany, setParentCompany] = useState<Client | null>(null);
	const [saving, setSaving] = useState(false);
	const [loading, setLoading] = useState(false);

	const { data: subaccounts } = useGetData({
		broadcastTypes: ['account.updated', 'account.deleted', 'account.subaccountsAdded'],
		broadcastWaitForIndex: true,
		fetcher: subaccountFetcher,
		fetcherProps: {
			clientId: client.id
		},
		limit: 1000,
		offset: 0
	});

	const updateRequest = useRef<null | CancelablePromise<Awaited<ReturnType<typeof ClientResource.save>>>>(null);
	const getRequest = useRef<null | CancelablePromise<Awaited<ReturnType<typeof ClientResource.get>>>>(null);

	const getParentCompany = (id: number) => {
		setLoading(true);
		getRequest.current = makeCancelable(ClientResource.get(id));
		getRequest.current.promise
			.then(res => {
				setParentCompany(res.data);
			})
			.catch(e => {
				logError(e, 'Failed to get parent company');
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const lang = {
		backButton: t('createRelation.card.titleParent'),
		saveButtonText: t('createRelation.parent.saveRelation'),
		select: t('default.select'),
		parentCompany: t('client.parent'),
		unsavedChanges: t('createRelation.parent.unsavedChanges')
	};

	useEffect(() => {
		if (client.parent) {
			getParentCompany(client.parent.id);
		}

		return () => {
			getRequest.current?.cancel();
		};
	}, [client.parent]);

	useModalClose(
		modalId,
		event => {
			event.preventDefault();
			if (client.parent?.id === parentCompany?.id && !saving) {
				return close();
			}
			const body = lang.unsavedChanges;
			openModal('UnsavedChangesAlert', {
				body,
				confirmButtonText: t('default.goBack'),
				onClose: (confirmed?: boolean) => {
					if (confirmed || confirmed === undefined) {
						return;
					}
					close();
				}
			});
		},
		[parentCompany, saving]
	);

	const saveParentCompany = () => {
		if (!parentCompany) {
			return;
		}
		setSaving(true);

		const parent = {
			id: client.id,
			parent: parentCompany.id
		};

		updateRequest.current = makeCancelable(ClientResource.save(parent));
		updateRequest.current.promise
			.then(() => {
				close();
			})
			.catch(e => {
				logError(e, 'Failed to save parent company');
			})
			.finally(() => {
				setSaving(false);
			});

		return updateRequest.current.promise;
	};

	const allClientIdsToSkip = [client.id, ...subaccounts.map(client => client.id)];
	const fetcher = getFetcher(allClientIdsToSkip);

	return (
		<Flex className={classes.b()} direction="column" justifyContent="space-between">
			<Block className={classes.elem('content').b()}>
				<BackButton buttonText={lang.backButton} classes={classes} onClick={goBack} />
				<Flex direction="column" space="ptxl">
					<Label required> {lang.parentCompany}</Label>
					{loading ? (
						<Flex justifyContent="center">
							<Loader size="md" />
						</Flex>
					) : parentCompany ? (
						<Card border="ts rs bs ls" borderColor="grey-4" borderRadius>
							<Table>
								<TableRow inactive={!client.active} disabled={false}>
									<TableColumn
										size="xl"
										title={<ClientColumnTitle client={parentCompany} />}
										subtitle={<ClientColumnSubtitle client={parentCompany} />}
									/>
									<TableColumn>
										<AccountManagers users={client.users} />
									</TableColumn>
									<TableColumn align="right">
										<Flex alignItems="center" justifyContent="end">
											<ClickableItem
												block={true}
												borderRadius
												icon="times"
												onClick={() => setParentCompany(null)}
											/>
										</Flex>
									</TableColumn>
								</TableRow>
							</Table>
						</Card>
					) : (
						<>
							<SelectAsync
								anchor={'.AddParentCompany'}
								disabled={saving}
								fetchOnMount={false}
								onChange={setParentCompany}
								placeholder={lang.select}
								fetcher={fetcher}
								value={parentCompany ?? null}
								onClear={() => setParentCompany(null)}
								renderItem={client => (
									<Flex
										direction="column"
										gap="u1"
										space="pts pbs"
										className={classes.elem('selectCustomRow').b()}
									>
										<ClientColumnTitle client={client} />
										<Flex justifyContent="space-between">
											<ClientColumnSubtitle client={client} />
											<AccountManagers users={client.users} />
										</Flex>
									</Flex>
								)}
							/>
						</>
					)}
				</Flex>
			</Block>
			<Block
				backgroundColor="grey-1"
				border="ts"
				borderColor="grey-4"
				className={classes.elem('footer').b()}
				space="ptl pbl"
			>
				<Flex alignItems="center" justifyContent="center">
					<PrimaryButton
						icon="check"
						disabled={client.parent?.id === parentCompany?.id || !parentCompany}
						onClick={saveParentCompany}
						size="lg"
					>
						{lang.saveButtonText}
					</PrimaryButton>
				</Flex>
			</Block>
		</Flex>
	);
};

export default AddParentCompany;
