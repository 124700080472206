import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import T from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import { Title, Text, Column, Row, Button, Icon, Loader, ModalHeader, ModalContent } from '@upsales/components';

import { setAssignData, onOpen, resetState, setLoading } from 'Store/reducers/AssignModalReducer';
import './AssignModalAssign.scss';
import './AssignModalLead.scss';
import AssignModalActivityInfo from './AssignModalActivityInfo';
import AssignButton from './AssignButton';
import AssignModalLeadSelect from './AssignModalLeadSelect';
import { asyncModalAdapter, setupComponentCompatibility } from 'App/helpers/angularPortingHelpers';

export const mapStateToProps = state => ({
	selectedStep: state.AssignModal.assign.step,
	assign: state.AssignModal.assign,
	loading: state.AssignModal.loading,
	blinkUserStep: state.AssignModal.blinkUserStep
});

const mapDispatchToProps = {
	setAssignData,
	resetState,
	setLoading,
	onOpen
};

export const TABS = {
	USER: 'user',
	ROLE: 'role'
};

class AssignModalLeadComponent extends React.Component {
	state = {
		currentTab: TABS.USER
	};

	constructor(props) {
		super(props);
		this.lang = {
			abort: T('default.abort'),
			chooseUser: T('assign.chooseSeller'),
			settUserAsAccountManager: T('processedBy.settUserAsAccountManager'),
			willBeAssigned: T('assign.willBeAssigned'),
			toContact: T('assign.toContact'),
			noContactSelected: T('assign.noContactSelected'),
			describeLead: T('assign.describeLead'),
			describeLeadInfo: T('assign.describeLeadInfo'),
			searchRoles: T('activity.searchRoles'),
			searchUsers: T('activity.searchUsers'),
			assign: T('default.assign'),
			or: T('default.or').toLowerCase()
		};

		this.initSteps();
	}

	componentDidMount() {
		const assignData = this.getAssignStateFromProperties();
		this.props.resetState();
		this.props.setAssignData(assignData);
		this.props.setLoading(false);

		if (assignData.role) {
			this.setState({ currentTab: TABS.ROLE });
		}
	}

	componentDidUpdate() {
		this.props.reloadModalPosition();
	}

	initSteps() {
		this.steps = [
			{
				number: 1,
				title: T('assign.selectUserToAssign'),
				text: T('assign.assignUserInfo'),
				renderContent: classNames => {
					const { currentTab } = this.state;

					return (
						<AssignModalLeadSelect
							classNames={classNames}
							currentTab={currentTab}
							handleTabChange={this.handleTabChange}
							setAssignData={this.props.setAssignData}
							allowAdvanced={this.props.entity === 'form'}
						/>
					);
				},
				renderPassedTitle: (classNames, number) => {
					const { user, role } = this.props.assign;

					if (!user && !role) {
						return null;
					}

					return (
						<div
							className={classNames.elem('passed-title')}
							onClick={() => this.props.setAssignData({ step: number })}
						>
							<Text className={this.props.blinkUserStep ? 'AssignModal__BlinkText' : ''}>
								{user ? user.name : role.name}
							</Text>{' '}
							<Text color="grey-10">{this.lang.willBeAssigned}</Text>
						</div>
					);
				},
				titleBtnDisabled: () => false
			},
			{
				number: 2,
				title: this.lang.describeLead,
				text: this.lang.describeLeadInfo,
				renderContent: () => <AssignModalActivityInfo reloadModalPosition={this.props.reloadModalPosition} />,
				renderPassedTitle: () => null,
				titleBtnDisabled: () => false
			}
		];
	}

	getAssignStateFromProperties() {
		if (!this.props.properties || !this.props.properties.length) {
			return {};
		}

		const properties = _.reduce(this.props.properties, (acc, { name, value }) => ({ ...acc, [name]: value }), {});
		const assignData = {
			assignToManager: !!Number(properties.AssignToManager),
			setUserAsAccountManager: !!Number(properties.setUserAsAccountManager),
			notes: properties.activityNote,
			description: properties.activityDescription,
			priority: Number(properties.activityPriority),
			advanced: !!Number(properties.advancedAssign),
			advancedData: {
				chosenField: properties.chosenField,
				fallback: (() => {
					if (!properties.fallback) {
						return undefined;
					}
					const [type, id] = properties.fallback.split(',');
					return {
						type,
						id: Number(id)
					};
				})(),
				// map the option properties into an array of objects
				assignedOptions: Object.entries(properties)
					.filter(([k]) => k.startsWith('option'))
					.map(([, v]) => {
						const [option, assignType, assignId] = v.split(',') || [];
						return {
							option: decodeURIComponent(option),
							type: assignType,
							id: Number(assignId) || undefined
						};
					})
			}
		};

		if (properties.activityProject) {
			assignData.projectId = properties.activityProject;
		}

		if (properties.activityCallList) {
			assignData.callListId = properties.activityCallList;
		}

		if (properties.User) {
			const user = Tools.AppService.getActiveUsers().find(({ id }) => id === Number(properties.User));

			if (user) {
				assignData.user = user;
			}
		} else if (properties.Role) {
			const role = Tools.AppService.getRoles().find(({ id }) => id === Number(properties.Role));

			if (role) {
				assignData.role = role;
			}
		}

		return assignData;
	}

	getPropertiesFromAssignState() {
		const { priority, description, notes, advanced, advancedData, projectId, callListId } = this.props.assign;

		let properties = [
			{ name: 'sendEmail', value: this.props.entity === 'form' },
			{ name: 'activityNote', value: notes || '' },
			{ name: 'activityDescription', value: description || '' },
			{ name: 'advancedAssign', value: !!advanced }
		];

		if (advanced) {
			const { chosenField, fallback, assignedOptions } = advancedData;

			/**
			Advanced data format example:
			{
				chosenField: 'Extra.1672839074334'
				fallback: 'user,123'
				option0: 'first%20option,user,321'
				option1: 'second%20option,role,12'
			} 
			*/

			properties = properties.concat([
				{ name: 'chosenField', value: chosenField },
				{ name: 'fallback', value: fallback ? `${fallback.type},${fallback.id}` : null },
				...assignedOptions.map((opt, i) => ({
					name: `option${i}`,
					value: `${encodeURIComponent(opt.option)},${opt.type},${opt.id}`
				}))
			]);
		} else {
			const { assignToManager, role, user, setUserAsAccountManager } = this.props.assign;

			properties = properties.concat([
				{ name: 'AssignToManager', value: !!assignToManager },
				{ name: 'setUserAsAccountManager', value: !!setUserAsAccountManager }
			]);

			if (role) {
				properties.push({ name: 'Role', value: role.id });
			} else {
				properties.push({ name: 'User', value: user.id });
			}
		}

		if (this.props.entity === 'form') {
			properties.push({ name: 'notify', value: true });
		}

		if (priority) {
			properties.push({ name: 'activityPriority', value: priority });
		}

		if (projectId) {
			properties.push({ name: 'activityProject', value: projectId });
		}

		if (callListId) {
			properties.push({ name: 'activityCallList', value: callListId });
		}

		return properties;
	}

	handleTabChange = currentTab => {
		this.setState({ currentTab });
	};

	assign = () => {
		this.props.resolve(this.getPropertiesFromAssignState());
	};

	renderStep = ({ number, title, text, renderContent, renderPassedTitle, titleBtnDisabled, locked }, rootClass) => {
		const current = this.props.selectedStep === number;
		const passed = this.props.selectedStep > number;
		const classNames = rootClass.elem('step');
		const titleBtnIsDisabled = titleBtnDisabled();
		const prevStep = this.steps[number - 2];
		let isNextStep = this.props.selectedStep === number - 1;

		if (prevStep && prevStep.locked && this.props.selectedStep === number - 2) {
			isNextStep = true;
		}

		classNames.mod({ passed, active: current });

		let titleElem = null;

		if (!current && isNextStep && !titleBtnIsDisabled) {
			titleElem = (
				<Button
					hoverColor="bright-blue"
					color="grey"
					type="link"
					className={classNames.elem('title-btn')}
					onClick={() => this.props.setAssignData({ step: locked ? number - 1 : number })}
				>
					{title} <Icon name="angle-right" />
				</Button>
			);
		} else {
			const passedTitle = renderPassedTitle(classNames, number);
			titleElem =
				!current && passedTitle ? (
					passedTitle
				) : (
					<Title
						className={classNames
							.elem('title')
							.mod(passed && !locked ? 'clickable' : locked ? 'locked' : null)
							.b()}
						size={current ? 'lg' : 'sm'}
						color={current ? null : 'grey-10'}
						onClick={passed && !locked ? () => this.props.setAssignData({ step: number }) : null}
					>
						{title}
						{locked ? (
							<Text color="grey-10">
								<Icon color="grey-10" name="lock" /> {locked}
							</Text>
						) : null}
					</Title>
				);
		}

		return (
			<Row key={number} className={classNames}>
				<Column fixedWidth={80} className={classNames.elem('indicator')}>
					<div className={classNames.elem('nr')}>{number}</div>
				</Column>
				<Column className={classNames.elem('content')}>
					{titleElem}
					{current ? (
						<Text color="grey-10" className={classNames.elem('desc')}>
							{text}
						</Text>
					) : null}
					{current ? renderContent(classNames) : null}
				</Column>
			</Row>
		);
	};

	renderContent() {
		const { role, user, description, advanced, advancedData } = this.props.assign;
		const { assign } = this.lang;
		const classNames = new BemClass('AssignModalAssign');
		const lastStepClass = classNames.elem('step');
		const lastStepIndex = this.steps.length + 1;
		const lastEnabled = ((!advanced && (user || role)) || (advanced && !!advancedData?.chosenField)) && description;
		let assignTo = `${user ? user.name : ''}`;

		if (!assignTo && role) {
			assignTo = role.name;
		}

		const submitText = advanced ? assign : `${assign} ${assignTo}`;

		return (
			<div className={classNames}>
				{this.steps.map(s => this.renderStep(s, classNames))}
				<Row key={lastStepIndex} className={lastStepClass.mod('last')}>
					<Column fixedWidth={80} className={lastStepClass.elem('indicator')}>
						<div className={lastStepClass.elem('nr')}>{lastStepIndex}</div>
					</Column>
					<Column className={lastStepClass.elem('content')}>
						<div className={lastStepClass.elem('assign-btn')}>
							{lastEnabled ? (
								<AssignButton size="lg" onClick={this.assign}>
									{submitText}
								</AssignButton>
							) : (
								<Button size="lg" disabled color="grey">
									{submitText}
								</Button>
							)}
						</div>
					</Column>
				</Row>
			</div>
		);
	}

	render() {
		const { title, loading, reject } = this.props;
		const modalClass = new BemClass('AssignModal');

		if (loading) {
			return (
				<div className={modalClass.elem('loader').b()}>
					<Loader />
				</div>
			);
		}

		return (
			<div className={modalClass.b() + ' AssignModalLead'}>
				<ModalHeader title={title} onClose={reject} />
				<ModalContent>
					<div className={modalClass.elem('content')}>{this.renderContent()}</div>
				</ModalContent>
			</div>
		);
	}
}

AssignModalLeadComponent.propTypes = {
	reject: PropTypes.func,
	resolve: PropTypes.func,
	assign: PropTypes.object,
	properties: PropTypes.array,
	title: PropTypes.string,
	entity: PropTypes.string,
	setAssignData: PropTypes.func,
	resetState: PropTypes.func,
	setLoading: PropTypes.func,
	selectedContact: PropTypes.object,
	reloadModalPosition: PropTypes.func,
	loading: PropTypes.bool,
	selectedStep: PropTypes.number,
	onOpen: PropTypes.func,
	blinkUserStep: PropTypes.bool
};

export const detached = AssignModalLeadComponent;
const Component = connect(mapStateToProps, mapDispatchToProps)(AssignModalLeadComponent);

export const AssignModalLead = setupComponentCompatibility(Component, {
	modalName: 'AssignModalLead',
	modalSize: 'lg',
	modalParamsMapper: $modalParams => ({
		...$modalParams,
		title: T('assign.assignLead')
	})
});

export const openAssignModalLead = asyncModalAdapter({
	upModalName: 'AssignToUserAction',
	openModalName: 'AssignModalLead',
	featureFlag: 'ASSIGN_TO_USER_ACTION_REACT',
	rejectOnEvent: true
});

export default Component;
