import { Block, Modal, ModalContent, ModalControls, ModalHeader } from '@upsales/components';
import FieldTranslation from 'App/babel/resources/FieldTranslations';
import { getLiveTags } from 'Store/actions/SocialEventActions';
import { useTranslation } from 'Components/Helpers/translate';
import { modules, track } from 'App/helpers/segmentHelper';
import RequestBuilder from 'Resources/RequestBuilder';
import { AppState } from 'Store/reducers/AppReducer';
import { ModalProps } from '../Modals/Modals';
import React, { useEffect } from 'react';
import logError from 'Helpers/logError';
import _ from 'lodash';

interface Props extends ModalProps<Tag | undefined> {
	extraTagGroups?: TagGroup[];
	strictTagList?: TagGroup[];
	socialEvent: boolean;
	hideIdTags?: boolean;
	isWebinar: boolean;
	tagEntity?: string;
}
interface Tag extends Tools.Tag {
	isDisabled?: boolean;
	value: string;
	raw?: boolean;
	icon?: string;
	html?: string;
}
type TagGroup = Tools.TagGroup;

type SocialEventTags = {
	[key: string]: { name: string; value: string; html?: boolean; isDisabled?: boolean; hidden?: boolean };
};

export default function InsertTag(props: Props) {
	const [originalTags, setOriginalTags] = React.useState<TagGroup[]>([]);
	const [tagGroups, setTagGroups] = React.useState<TagGroup[]>([]);
	const [search, setSearch] = React.useState('');
	const hasNewFields = Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');
	const { t } = useTranslation();

	useEffect(() => {
		if (hasNewFields) {
			var rb = new RequestBuilder();
			rb.addFilter({ field: 'type' }, rb.comparisonTypes.Equals, 'salutation');

			Promise.all([Tools.AppService.getStaticValuesPromise('languages'), FieldTranslation.find(rb.build())])
				.then(function (res) {
					var languages = (res[0] as AppState['staticValues']['languages']) || [];
					var fieldTranslations = res[1] ? res[1].data : [];
					var selectedLanguages: string[] = _.unique(_.pluck(fieldTranslations, 'language'));

					init(languages, selectedLanguages);
				})
				.catch(e => logError(e, 'Failed to open insertTag modal'));
		} else {
			init([]);
		}
	}, []);

	useEffect(() => {
		searchUpdate();
	}, [search]);

	useEffect(() => {
		if (originalTags.length === 0) {
			setOriginalTags(_.cloneDeep(tagGroups));
		}
	}, [tagGroups]);

	const selectTag = (tag: Tag) => {
		track('Selected tag ' + tag.value, modules.tagPicker);
		props.close(tag);
	};

	const getSocialEventTags = (isWebinar: boolean): TagGroup[] => {
		const socialEventTags: SocialEventTags = getLiveTags();
		socialEventTags['Mail.CanNotAttendLink'] = {
			name: t('socialEvent.canNotAttendLink'),
			value: `<a href="{{Mail.CanNotAttendLink}}" ng-non-bindable>${t('socialEvent.canNotAttend')}</a>`,
			html: true
		};
		socialEventTags['Mail.WebinarInviteLink'] = {
			name: isWebinar ? t('socialEvent.webinar.link') : t('socialEvent.webinar.linkPlaceholder'),
			value: '<a href="{{Mail.WebinarInviteLink}}" ng-non-bindable>webinar</a>',
			html: true,
			isDisabled: !isWebinar
		};
		socialEventTags['Mail.OnDemandLink'] = {
			name: isWebinar ? t('socialEvent.onDemandLink') : t('socialEvent.webinar.onDemandLinkPlaceholder'),
			value: `<a href="{{Mail.OnDemandLink}}" ng-non-bindable>${t('socialEvent.onDemandLink')}</a>`,
			html: true,
			isDisabled: !isWebinar
		};

		return [
			{
				title: 'default.socialEvents',
				group: 'SocialEvent',
				tags: TypedObject.keys(socialEventTags).reduce<Tag[]>((tags, key) => {
					var tag = socialEventTags[key];
					if (!tag.hidden) {
						tags.push((tag.html ? tag : { name: tag.name, value: `{{${key}}}` }) as Tag);
					}
					return tags;
				}, []),
				sort: tagGroups.length + 1
			}
		];
	};

	function init(languages: AppState['staticValues']['languages'], selectedLanguages?: string[]) {
		let tempTagGroups = [];

		if (props.tagEntity) {
			tempTagGroups = Tools.TagsService.getTagsByEntity2(props.tagEntity);
		} else if (props.strictTagList) {
			tempTagGroups = props.strictTagList;
		} else {
			tempTagGroups = Tools.TagService.getMailTags(!!props.hideIdTags);
		}

		if (props.extraTagGroups) {
			tempTagGroups = [...tempTagGroups, ...props.extraTagGroups];
		}

		if (props.socialEvent) {
			tempTagGroups = [...tempTagGroups, ...getSocialEventTags(props.socialEvent)];
		}

		setTagGroups(tempTagGroups);

		if (hasNewFields) {
			var contactGroupIndex = _.findIndex(tagGroups, { group: 'Contact' });

			if (contactGroupIndex > -1) {
				var contactGroup = tagGroups[contactGroupIndex];
				contactGroup.tags = contactGroup.tags.filter(function (tag) {
					return tag.value !== '{{Contact.SalutationValue}}';
				});

				var salutationTag = _.remove(contactGroup.tags, { value: '{{Contact.Salutation}}' });

				if (salutationTag.length) {
					tagGroups.forEach(function (tagGroup, index) {
						if (index >= contactGroupIndex + 2) {
							tagGroup.sort = tagGroup.sort + 1;
						}
					});

					var salutationGroup = {
						title: 'default.salutation',
						class: 'th-inner--translation',
						group: 'Contact',
						tags: [] as Tag[],
						sort: contactGroup.sort + 1
					};

					(selectedLanguages || []).forEach(function (language: string) {
						var tag = '{{Translate_' + language + '_Contact.SalutationValue}}';
						var option = languages.find(
							(lang: { language: string; country: string; name: string }) => lang.language === language
						);

						if (option) {
							var icon = option ? 'flag-icon flag-icon-' + option.country : '';
							salutationGroup.tags.push({
								value: tag,
								name: option.name,
								raw: false,
								group: 'Contact',
								icon: icon,
								type: '',
								entity: ''
							} as Tag);
						}
					});

					tagGroups.splice(contactGroupIndex + 1, 0, salutationGroup);
				}
			}
		}
	}

	function searchUpdate() {
		if (search.length === 0) {
			setTagGroups(originalTags);
			return;
		}

		setTagGroups(
			(originalTags || []).reduce(function (result, tagGroup) {
				var cloneGroup = _.cloneDeep(tagGroup);
				cloneGroup.tags = tagGroup.tags.reduce(function (result, tag) {
					if (cloneGroup.class && cloneGroup.class === 'th-inner--translation') {
						var cloneGroupTile = Tools.$translate(cloneGroup.title);
						var groupTileMatch = cloneGroupTile.toLowerCase().indexOf(search.toLowerCase()) !== -1;

						if (groupTileMatch) {
							result.push(tag);
						}
					} else if (tag.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
						result.push(tag);
					}
					return result;
				}, [] as Tag[]);

				if (cloneGroup.tags.length) {
					result.push(cloneGroup);
				}

				return result;
			}, [] as TagGroup[])
		);
	}

	return (
		<Modal className={props.className} size="sm" id="insert-tag-modal">
			<ModalHeader title={t('default.tag')} icon="tag" onClose={props.close}></ModalHeader>
			<ModalContent className="ModalContent--no-padding">
				<div className="up-panel">
					<div className="up-panel-content">
						<Block space="mtm">
							<div className="search-holder">
								<b className="fa fa-search" />
								<input
									type="text"
									className="search-input"
									onChange={e => {
										setSearch(e.target.value);
									}}
									ng-model="InsertTag.search"
									placeholder={t('default.search')}
								/>
							</div>
						</Block>

						{tagGroups
							.sort((a, b) => a.sort - b.sort)
							.map((group, groupIndex) => (
								<table key={groupIndex} className="main-table">
									<thead>
										<tr>
											<th className={`th-inner ${group.class || ''}`}>{t(`${group.title}`)}</th>
										</tr>
									</thead>
									<tbody>
										{group.tags.map((tag: Tag, tagIndex: number) => (
											<tr
												key={tagIndex}
												className="selectable"
												onClick={() => {
													if (!tag.isDisabled) {
														selectTag(tag);
													}
												}}
											>
												<td className={tag.isDisabled ? 'disbaled-tag' : ''}>
													<i className={tag.icon}></i>
													<span>{tag.name}</span>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							))}
					</div>
				</div>
			</ModalContent>
			<ModalControls>
				<button type="button" className="btn btn-bright-blue btn-link up-btn" onClick={() => props.close()}>
					{t('default.abort')}
				</button>
			</ModalControls>
		</Modal>
	);
}
