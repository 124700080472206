import React from 'react';
import { Title, Text, Link, Icon, IconName } from '@upsales/components';
import translate from 'Components/Helpers/translate';
import './FileUploadDropzone.scss';
import bemClass from '@upsales/components/Utils/bemClass';

interface FileUploadDropzoneProps {
	title: string;
	onClick: () => void;
	iconName?: IconName;
}

const classes = new bemClass('FileUploadDropzone');

const FileUploadDropzone = ({ title, onClick, iconName }: FileUploadDropzoneProps) => (
	<div className={classes.b()} onClick={onClick}>
		{/* class used for modal styling */}
		<div className={classes.elem('instructions').b()}>
			<Title>
				{iconName ? <Icon name={iconName} className={classes.elem('icon').b()} /> : null}
				{title || translate('default.uploadFile')}
			</Title>
			<Text color="grey-10">
				<Link>{translate('file.uploadHelpText_split1')}</Link>
				{' ' + translate('file.uploadHelpText_split2')}
			</Text>
		</div>
	</div>
);

export default FileUploadDropzone;
