import { getCalculatingFieldTooltip } from 'App/helpers/formulaText';
import { FormComponent } from 'App/components/FormComponent';
import { SlideFade } from '@upsales/components/animations';
import BemClass from '@upsales/components/Utils/bemClass';
import { Headline, Icon, Title, Tooltip } from '@upsales/components';
import CustomField, { EntityCustomField } from 'App/resources/Model/CustomField';
import T from 'Components/Helpers/translate';
import { calculateField } from '@upsales/common';
import { InputProps } from './../FormObserver';
import Order from 'App/resources/Model/Order';
import React, { useMemo, useState } from 'react';
import type { AllIWantData } from 'App/resources/AllIWant';
import OrderRow from 'App/resources/Model/OrderRow';
import { useFeatureAvailable, useSelector } from 'App/components/hooks';
import { Feature } from 'Store/actions/FeatureHelperActions';

import './CustomFields.scss';
import { type FieldOverride } from 'Store/reducers/EditListenerReducer';

type Props = {
	onChange: (fieldId: number, value: string) => void;
	renderSubtitle?: () => JSX.Element | null;
	inputProps: { [key: string]: InputProps<any> };
	type: keyof AllIWantData['customFields'];
	orderOrOrderRow?: object; // thanks to calculateField.calculate
	onlyShowIds?: number[];
	disabled?: boolean;
	className?: string;
	stageId?: number;
	title?: string;
	customFields?: EntityCustomField[];
	showFormGroupName?: boolean;
	isDisabledField?: (fieldName: string) => boolean | undefined;
	isVisibleField?: (fieldName: string) => boolean | undefined;
	getCustomFieldOverrides?: (fieldId: number) => FieldOverride;
	allFieldsOptional?: boolean;
	allFieldsEditable?: boolean;
	allFieldsVisible?: boolean;
};

// This component is expected to have a FormObserver as parent
const CustomFields = ({
	allFieldsOptional = false,
	allFieldsEditable = false,
	allFieldsVisible = false,
	disabled = false,
	orderOrOrderRow,
	onlyShowIds,
	inputProps,
	className,
	renderSubtitle,
	onChange,
	stageId,
	title,
	type,
	showFormGroupName,
	isDisabledField,
	isVisibleField,
	getCustomFieldOverrides
}: Props) => {
	const classNames = new BemClass('CustomFields', className);
	const [showCf, setShowCf] = useState(true);
	const idsToShow = new Set(onlyShowIds);

	const customFieldsMap = useSelector(state => state.App.customFields);
	const customFields = customFieldsMap[type];

	const hasCalculatingFields = useFeatureAvailable(Feature.CALCULATING_FIELDS);
	const hasFormGroups = useFeatureAvailable(Feature.FORM_GROUPS);
	const hasOrderRowCustomFeature = useFeatureAvailable(Feature.ORDER_ROW_CUSTOM);
	const hasFormulaVisible = Tools.FeatureHelper.hasSoftDeployAccess('FORMULA_VISIBLE');

	const mappedCustomFields = useMemo(() => {
		let orderRow: OrderRow[];

		if (type === 'order' && orderOrOrderRow && hasCalculatingFields) {
			orderRow = (orderOrOrderRow as Order).orderRow.map(row => ({
				...row,
				custom: row.custom?.map(cf => {
					const field = customFieldsMap['orderrow'].find(c => c.id === cf.fieldId);
					if (!field || field.datatype !== 'Calculation') {
						return cf;
					}

					const value = calculateField.calculate(field.formula, row).toString();

					return {
						...cf,
						value
					};
				})
			}));
		}

		return (customFields ?? [])
			.map(field => {
				const obligatoryField = allFieldsOptional ? 0 : field.obligatoryField;
				const editable = allFieldsEditable ? true : isVisibleField?.(`custom.${field.id}`) ?? field.editable;
				const visible = allFieldsVisible ? true : isVisibleField?.(`custom.${field.id}`) ?? field.visible;
				const foundField = inputProps?.[`custom.Custom_${field.id}`];
				let value = foundField?.value ?? null;

				if (orderOrOrderRow && field?.datatype === 'Calculation' && hasCalculatingFields) {
					const extendedCustomFields = ((orderOrOrderRow as OrderRow | Order).custom ?? []).map(cf => {
						const customField = customFields.find(c => c.id === cf.fieldId);
						if (!customField) {
							return cf;
						}
						return {
							...customField,
							...cf
						};
					});

					value = calculateField
						.calculate(field.formula, { ...orderOrOrderRow, orderRow, custom: extendedCustomFields })
						.toString();
				}

				return {
					...field,
					fieldId: field.id,
					value,
					obligatoryField,
					editable,
					visible
				} satisfies CustomField & EntityCustomField;
			})
			.filter(field => field.$hasAccess && (field.visible || field.editable))
			.sort((a, b) => a.sortId - b.sortId);
	}, [customFields, inputProps, orderOrOrderRow, allFieldsOptional, allFieldsEditable, allFieldsVisible]);

	if ((!hasOrderRowCustomFeature && type === 'orderrow') || mappedCustomFields.length === 0) {
		return null;
	}

	const defaultOverrideHandler = (fieldDefault: string | string[], defaultOverride: string | string[]) => {
		if (Array.isArray(fieldDefault) && Array.isArray(defaultOverride)) {
			return fieldDefault.filter(defaultValue => {
				return defaultOverride.includes(defaultValue);
			});
		} else if (typeof fieldDefault === 'string' && typeof defaultOverride === 'string') {
			return defaultOverride;
		}
		return fieldDefault;
	};

	const customFieldsContent = (mappedCustomFields: (CustomField & EntityCustomField)[]) => {
		return (
			<div className={classNames.elem('content').b()}>
				{mappedCustomFields
					.filter(field => {
						if (onlyShowIds?.length) {
							return idsToShow.has(field.id);
						}
						return true;
					})
					.map(field => {
						const fieldWithStage = field as unknown as CustomField & {
							stages?: (Order['stage'] & { required: boolean })[];
						};
						const requiredInStage = !!(
							stageId && fieldWithStage.stages?.find(stage => stage.id === stageId && stage.required)
						);
						const state = inputProps?.[`custom.Custom_${field.id}`]?.state;

						const maxLength =
							['Link', 'Email', 'String', 'Text', 'Currency'].indexOf(field.datatype) >= 0
								? field.maxLength
								: 0;
						const fieldDisabled = isDisabledField?.(`custom.${field.id}`) ?? (!field.editable || disabled);
						const fieldRequired =
							(!!field.obligatoryField || requiredInStage) && (field.value || !fieldDisabled);

						const customFieldOverride = getCustomFieldOverrides?.(field.id);
						if (customFieldOverride?.default) {
							field.default = defaultOverrideHandler(field.default, customFieldOverride.default);
						}

						return (
							<FormComponent
								key={`cfw-${field.id}`}
								required={!!fieldRequired}
								maxLength={maxLength}
								maxLengthText={''}
								value={field.value}
								label={customFieldOverride?.name ?? field.name}
							>
								<Tooltip
									title={
										field.datatype === 'Calculation'
											? getCalculatingFieldTooltip(field.formula, field.name)
											: ''
									}
									disabled={
										field.datatype !== 'Calculation' || (hasFormulaVisible && !field.formulaVisible)
									}
								>
									<ReactTemplates.customFieldInput
										usenewdate
										useNewTime
										useNumberInput
										key={'cf-' + field.id}
										isNew
										field={field}
										state={state}
										entity={type}
										name={`custom.Custom_${field.id}`}
										placeholder={customFieldOverride?.placeholder ?? field.name}
										disabled={fieldDisabled}
										useExternalDisabled
										useDebounce={false}
										valueChange={(value: string) => {
											// For multiple users custom field
											if (Array.isArray(value) && value.length === 0) {
												value = '';
											}
											onChange(field.id, value);
										}}
									/>
								</Tooltip>
							</FormComponent>
						);
					})}
			</div>
		);
	};

	const customFieldsGroups = () => {
		const showFormGroups = hasFormGroups && (type === 'order' || type === 'account');

		if (showFormGroups) {
			const formGroups = mappedCustomFields.reduce(
				(previous: { name: string | null; fields: (CustomField & EntityCustomField)[] }[], row) => {
					let group = previous.find(group => group.name === row.formGroup);
					if (!group) {
						group = {
							name: row.formGroup,
							fields: []
						};
						previous.push(group);
					}
					group.fields.push(row);
					return previous;
				},
				[]
			);
			return (
				<div className={classNames.elem('formGroupsContainer').b()}>
					{formGroups.map(formGroup => {
						return (
							<div
								key={formGroup.name ?? 'default.otherInfo'}
								className={classNames.elem('formGroupContent').b()}
							>
								{showFormGroupName ? (
									<Title space="mbm" className={classNames.elem('formGroupTitle').b()}>
										{formGroup.name ?? T('default.otherInfo')}
									</Title>
								) : null}
								{customFieldsContent(formGroup.fields)}
							</div>
						);
					})}
				</div>
			);
		} else {
			if (showFormGroupName) {
				return (
					<div className={classNames.elem('formGroupContent').b()}>
						<Title space="mbm" className={classNames.elem('formGroupTitle').b()}>
							{T('default.otherInfo')}
						</Title>
						{customFieldsContent(mappedCustomFields)}
					</div>
				);
			} else {
				return customFieldsContent(mappedCustomFields);
			}
		}
	};

	return (
		<div className={classNames.b()}>
			{title ? (
				<div>
					<div className={classNames.elem('title').mod({ rotateIcon: !showCf }).b()}>
						<div>
							<Headline size="sm">{title}</Headline>
							{renderSubtitle ? renderSubtitle() : null}
						</div>
						<Icon name="chevron-up" onClick={() => setShowCf(!showCf)} />
					</div>
					<SlideFade visible={showCf}>{customFieldsGroups()}</SlideFade>
				</div>
			) : (
				customFieldsGroups()
			)}
		</div>
	);
};

export default CustomFields;
