import Resource from './Resource';
import type UpsellPotential from 'App/resources/Model/UpsellPotential';

class upsellCompanyGroup extends Resource {
	constructor() {
		super('upsellCompanyGroup');
	}

	fetchForGroup(groupMotherOrgNumber: string): Promise<{ data: UpsellPotential[] }> {
		return super._getRequest(groupMotherOrgNumber).then(d => d.data);
	}
}

const resource = new upsellCompanyGroup();

export default resource;
