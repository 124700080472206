import React, { RefObject } from 'react';
import { useTranslation } from 'Components/Helpers/translate';
import { DateInput, Flex, Label, TimeInput } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';

export type Props = {
	anchor: RefObject<Element>;
	setDate: (value: Date) => void;
	setTime: (value: string) => void;
	date?: Date;
	time?: string;
};

const DateAndTime = ({ date, setDate, time, setTime, anchor }: Props) => {
	const { t } = useTranslation();
	const classes = new BemClass('DateAndTime');

	return (
		<div className={classes.b()}>
			<div className="form-group">
				<Label required>{`${t('default.date')} ${t('default.and').toLowerCase()} ${t(
					'default.time'
				).toLowerCase()}`}</Label>

				<Flex direction="row">
					<DateInput
						onChange={e => setDate(e.target.value)}
						placeholder={t('default.date')}
						value={date}
						closeOnSelect={true}
						anchor={anchor.current}
					/>

					<TimeInput
						value={time}
						onChange={e => setTime(e.target.value)}
						placeholder={t('default.time')}
						anchor={anchor.current}
					/>
				</Flex>
			</div>
		</div>
	);
};

export default DateAndTime;
