import React, { useState } from 'react';
import {
	Text,
	Drawer,
	DrawerHeader,
	Flex,
	Link,
	Title,
	TableColumn,
	TableRow,
	TableHeader,
	Table,
	Icon,
	ButtonSelect,
	ClickableItem,
	EllipsisTooltip,
	Tooltip
} from '@upsales/components';
import { ModalProps } from '../Modals/Modals';
import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import { currencyFormat } from 'Components/Filters/Currencies';
import { roundNumber } from 'App/pages/CompanyGroupCard/Sidebar/UpsellWidget/UpsellWidget';
import { DefaultButton } from '@upsales/components/Buttons';
import { useSoftDeployAccess, useFeatureAvailable } from '../hooks';
import { Feature } from 'Store/actions/FeatureHelperActions';
import { openDrawer } from 'Services/Drawer';
import RequiredFields from 'Components/Modals/CreateNewAccount/RequiredFields';
import { useCustomFields } from '../hooks/appHooks';
import Prospecting from 'Resources/Prospecting';
import CustomField from 'App/resources/Model/CustomField';
import type UpsellPotential from 'App/resources/Model/UpsellPotential';
import { getJourneyStep } from 'Components/Helpers/journeyStep';

import './UpsellCompanyGroup.scss';

type Props = {
	upsellData: UpsellPotential[];
	currency: { iso: string; rate: number };
	numberOfClients: number;
	totalPotential: string;
	salesModel: string;
};

const UpsellCompanyGroup = ({
	className,
	close,
	upsellData,
	currency,
	numberOfClients,
	totalPotential,
	salesModel
}: Props & ModalProps) => {
	const classes = new BemClass('UpsellCompanyGroup', className);
	const { t } = useTranslation();
	const hasTodoList = useSoftDeployAccess('TODO_LIST');
	const hasTodosAndRemoveActivity = useSoftDeployAccess('REMOVE_ACTIVITIES') && hasTodoList;
	const hasActivityFeature = useFeatureAvailable(Feature.ACTIVITIES_AND_APPOINTMENTS);
	const accountCustomFields = useCustomFields('account');

	const [sort, setSort] = useState({ field: 'potential', asc: false });
	const [view, setView] = useState('all');

	const columns = [
		{ title: t('default.account') },
		{
			title:
				salesModel === 'cm'
					? t('default.potential') + ` (${t('default.contributionMarginShort')})`
					: salesModel === 'sales'
					? t('default.potential')
					: t('default.potential') + ` (${salesModel.toLocaleUpperCase()})`,
			sort: sort.field,
			sortType: 'numeric'
		}
	];
	const currentJourneyStep = (journey: string) => {
		const status = getJourneyStep(journey);

		return status?.name ?? '';
	};

	const percentageConverter = (percentage: number) => {
		return Math.round(percentage * 10000) / 100; //to always get 2 decimals, multiply by 10000 and divide by 100
	};

	let filteredData = [...upsellData];

	if (view === 'upsell') {
		filteredData = filteredData.filter((upsell: UpsellPotential) => upsell.hasTakeRatePotential);
	} else if (view === 'potential') {
		filteredData = filteredData.filter((potential: UpsellPotential) => potential.hasNewPotential);
	}

	const goToAccount = (id: number) => {
		close();
		Tools.$state.go('account.dashboard', { id }, { inherit: false });
	};
	const doAddAccount = (prospectingId: string, customValues: CustomField[] = []) => {
		Prospecting.save({ prospectingId, customValues }, { params: { skipAddCEO: false } });
		// TODO: Fix better logic for this
		close();
	};

	const onClickAddAccount = (prospectingId: string) => {
		const requiredFields = accountCustomFields.filter(value => {
			return value.obligatoryField && value.$hasAccess && value.editable && value.alias !== 'ORG_NO';
		});

		if (requiredFields.length) {
			Tools.$upModal.open('generic', {
				Component: (props: any) => (
					<div id="create-new-account-modal">
						<RequiredFields {...props} />
					</div>
				),
				requiredFields: requiredFields,
				fullscreen: true,
				actions: {
					addAccount: (addingAccount: any, properties: any, purchaseType: any, resolve: () => void) =>
						doAddAccount(prospectingId, properties)
				}
			});
		} else {
			doAddAccount(prospectingId);
		}
	};

	const sortedData = [...filteredData].sort((a, b) => {
		const valueA = a.newPotential || a.takeRatePotential;
		const valueB = b.newPotential || b.takeRatePotential;

		if (sort.asc) {
			return valueA - valueB;
		} else {
			return valueB - valueA;
		}
	});

	const row = sortedData.map((item: UpsellPotential) => (
		<TableRow key={item.prospectingId}>
			<TableColumn className={classes.elem('firstColumn').b()} size="lg">
				{item.client?.id ? (
					<Link onClick={() => goToAccount(item.client.id)}>{item.name}</Link>
				) : (
					<Text>{item.name}</Text>
				)}
				<Text color="grey-11">
					{item.hasNewPotential
						? (() => {
								const avgValue = currencyFormat(
									roundNumber(item.newPotential) * currency.rate,
									currency.iso
								);
								return t('account.relations.avgDealSize', {
									avgValue: avgValue
								});
						  })()
						: (() => {
								const percentage = percentageConverter(item.takeRatePotentialPercentage);
								return t('account.relations.similarCustomersBuyFor', {
									percentage: percentage
								});
						  })()}
				</Text>
			</TableColumn>
			<TableColumn size="lg">
				<Flex justifyContent="space-between" alignItems="center">
					<Flex>
						{currencyFormat(
							roundNumber(item.hasNewPotential ? item.newPotential : item.takeRatePotential) *
								currency.rate,
							currency.iso
						)}
					</Flex>
					<Flex space="prl">
						{item.client?.id && item.hasTakeRatePotential ? (
							<Flex alignItems="center">
								{hasTodosAndRemoveActivity && hasActivityFeature ? (
									<Tooltip title={t('todo.planACall')}>
										<ClickableItem
											onClick={() => openDrawer('CreateCall', { client: item.client })}
											icon="phone"
											size="sm"
											borderRadius
										/>
									</Tooltip>
								) : hasActivityFeature ? (
									<Tooltip title={t('create_activity')}>
										<ClickableItem
											onClick={() =>
												Tools.$upModal.open('editActivity', {
													activity: { client: { id: item.client.id } }
												})
											}
											icon="activity"
											size="sm"
											borderRadius
										/>
									</Tooltip>
								) : null}
								{hasActivityFeature ? (
									<Tooltip title={t('todo.bookAppointment')}>
										<ClickableItem
											onClick={() =>
												Tools.$upModal.open('editAppointment', {
													appointment: { client: { id: item.client.id } }
												})
											}
											icon="calendar"
											size="sm"
											borderRadius
										/>
									</Tooltip>
								) : null}
								{/* Uncomment this when there is logic for removing/hiding an upselling potential */}
								{/* <InlineConfirm
									show
									keepTabPosition
									entity={t('account.companyGroup.upsellPotential')}
									onConfirm={() => removeUpsellPotential(client.prospectingId)}
								>
									<ClickableItem icon="trash" size="sm" borderRadius />
								</InlineConfirm> */}
							</Flex>
						) : item.client?.id && item.hasNewPotential ? (
							<EllipsisTooltip title={t(currentJourneyStep(item.client?.journeyStep))}>
								<Text>{t(currentJourneyStep(item.client?.journeyStep))}</Text>
							</EllipsisTooltip>
						) : !item.client?.id ? (
							<DefaultButton size="sm" onClick={() => onClickAddAccount(item.prospectingId)}>
								<Icon name="plus" space="mrs" />
								{t('default.addAccount')}
							</DefaultButton>
						) : null}
					</Flex>
				</Flex>
			</TableColumn>
		</TableRow>
	));

	return (
		<Drawer className={classes.b()}>
			<DrawerHeader onHide={close}>
				<Flex
					alignItems="center"
					justifyContent="space-between"
					className={classes.elem('header').elem('controls').b()}
					space="pll prl"
				>
					<Flex direction="column">
						<Title>{t('account.companyGroup.upsellPotential')}</Title>
						<Text size="xs" color="grey-11">
							{numberOfClients +
								' ' +
								t(
									`account.companyGroup.upsellPotential.subtitle${
										numberOfClients !== 1 ? 'Many' : ''
									}`,
									{
										value: totalPotential,
										salesModel:
											salesModel === 'cm'
												? `(${t('default.contributionMarginShort')})`
												: salesModel === 'sales'
												? ''
												: `(${salesModel.toLocaleUpperCase()})`
									}
								)}
						</Text>
					</Flex>
					<Flex>
						<ButtonSelect
							size="sm"
							options={[
								{ value: 'all', title: t('default.all') },
								{ value: 'upsell', title: t('account.companyGroup.upsell') },
								{ value: 'potential', title: t('account.companyGroup.potentialCustomer') }
							]}
							onChange={v => setView(v)}
							value={view}
						></ButtonSelect>
					</Flex>
				</Flex>
			</DrawerHeader>
			<Table>
				<TableHeader columns={columns} onSortChange={setSort} sorting={sort.field} asc={sort.asc} />
				{row}
			</Table>
		</Drawer>
	);
};

export default UpsellCompanyGroup;
