import Avatar from 'Components/Avatar';
import BemClass from '@upsales/components/Utils/bemClass';
import FlexItem from 'App/components/FlexItem';
import React, { useMemo } from 'react';
import T from 'Components/Helpers/translate';
import UserStack from 'App/components/UserStack';
import SubAccountLabel from 'Components/Misc/SubAccountLabel';
import { Flag, Icon, Block, Flex, Text, EllipsisTooltip } from '@upsales/components';
import { currencyFormat, numberFormat } from 'App/babel/components/Filters/Currencies';

import type Client from 'App/resources/Model/Client';

type ClientColumn = Pick<
	Client,
	'id' | 'name' | 'users' | 'addresses' | 'currency' | 'noEmployees' | 'turnover' | 'operationalAccount'
> & { disabled?: boolean };

export const AccountManagers = ({ users }: { users: Client['users'] }) => {
	switch (users.length) {
		case 0:
			return (
				<Text color="grey-10" italic={true}>
					{T('default.noAccountManager')}
				</Text>
			);
		case 1:
			return (
				<Flex alignItems="center">
					<Avatar user={users[0]} size={20} />
					<Text>{users[0].name}</Text>
				</Flex>
			);
		default:
			return <UserStack users={users} />;
	}
};

export const getAddress = (client: ClientColumn) => {
	// Can not use the god damn ClientAddressType enum for this as it seem to create an impossible circular dependency
	for (const addressType of ['Visit', 'Mail', 'Delivery', 'Invoice', 'Other']) {
		const found = client.addresses.find(address => address.type === addressType);
		if (found?.city) {
			return found;
		}
	}

	return null;
};

export const ClientColumnSubtitle = ({ client }: { client: ClientColumn }) => {
	const address = getAddress(client);

	return (
		<Text size="sm" color="grey-12">
			<Flex space="mts" alignItems="center">
				{address ? (
					<>
						{address.country ? <Flag space="mrs" code={address.country.toLowerCase()} /> : null}
						{address.city}
						<Block space="mlm mrm">{'•'}</Block>
					</>
				) : null}
				<Icon space="mrs" name="user-friends" />
				{numberFormat(client.noEmployees)}
				<Block space="mlm mrm">{'•'}</Block>
				<Icon space="mrs" name="bar-chart" />
				{currencyFormat(client.turnover, client.currency)}
			</Flex>
		</Text>
	);
};

export const ClientColumnTitle = ({ client }: { client: ClientColumn }) => {
	const classes = new BemClass('SubaccountDrawer');
	const noName = useMemo(() => T('default.noName'), []);

	return (
		<Flex alignItems="baseline" className={classes.elem('ClientColumnTitle').b()}>
			<EllipsisTooltip title={client.name || noName}>
				{client.name ? (
					client.disabled ? (
						<Text italic color="grey-11">
							{client.name}
						</Text>
					) : (
						<Text>{client.name}</Text>
					)
				) : (
					<Text color="grey-10" italic={true}>
						{noName}
					</Text>
				)}
			</EllipsisTooltip>
			<FlexItem flexShrink={0}>
				<SubAccountLabel operationalAccount={client.operationalAccount} />
			</FlexItem>
		</Flex>
	);
};
