import React from 'react';
import T from 'Components/Helpers/translate';
import './ListViewActions.scss';
import _ from 'lodash';
import { Tooltip, Text, Icon, Row, Column, Card, IconName } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { makeCancelable } from 'App/babel/helpers/promise';
import { numberFormat } from 'Components/Filters/Currencies';

export type MultiAction = {
	id: string;
	title: string;
	icon: IconName;
	tooltip?: string;
	disabledTooltip?: string;
	disabled?: boolean | ((selected: number, allSelcted: boolean) => boolean);
};

export type Props = {
	actions: MultiAction[];
	runAction: (action: MultiAction) => Promise<any> | void;
	selectNone: () => void;
	selected: number;
	allSelected: boolean;
};

type State = {
	open: boolean;
};

class ListViewActions extends React.Component<Props, State> {
	actionPromise: ReturnType<typeof makeCancelable> | null = null;
	constructor(props: Props) {
		super(props);
		this.state = {
			open: true
		};
	}
	componentWillUnmount() {
		if (this.actionPromise) {
			this.actionPromise.cancel();
		}
	}
	openList = () => {
		this.setState({ open: true });
	};
	closeList = () => {
		this.setState({ open: false });
	};
	toggleList = () => {
		// eslint-disable-next-line no-unused-expressions
		if (this.state.open) {
			this.closeList();
		} else {
			this.openList();
		}
	};
	runAction = (action: MultiAction) => {
		const runner = this.props.runAction(action);
		this.closeList();
		if (runner?.then) {
			this.actionPromise = makeCancelable(runner);
			this.actionPromise.promise.catch(() => {});
		}
		return runner;
	};
	renderAction = (action: MultiAction, i: number) => {
		const rowClasses = new BemClass('ListViewActions').elem('action');
		const isFa = action.icon?.startsWith('fa-');
		const disabled =
			typeof action.disabled === 'function'
				? action.disabled(this.props.selected, this.props.allSelected)
				: action.disabled;
		const actionRow = (
			<Row
				key={'action-' + i}
				className={rowClasses.mod({ disabled }).b()}
				onClick={disabled ? _.noop : this.runAction.bind(this, action)}
			>
				<Column fixedWidth={44} align="center">
					{isFa ? (
						<b className={rowClasses.elem('icon', `fa fa-fw ${action.icon}`).b()}></b>
					) : action.icon ? (
						<Icon name={action.icon} className={rowClasses.elem('icon').b()} />
					) : null}
				</Column>
				<Column>
					<Text color="inherit">{T(action.title)}</Text>
				</Column>
			</Row>
		);

		if ((disabled && action.disabledTooltip) || action.tooltip) {
			return (
				<Tooltip
					key={'action-tt-' + i}
					title={T((disabled ? action.disabledTooltip : action.tooltip) || '')}
					position="top"
				>
					{actionRow}
				</Tooltip>
			);
		}

		return actionRow;
	};
	componentDidUpdate(prevProps: Props) {
		if (prevProps.selected > 0 && this.props.selected === 0) {
			this.openList();
		}
	}
	render() {
		const classes = new BemClass('ListViewActions');
		const { actions, selected, allSelected, selectNone } = this.props;

		const formattedSelected = numberFormat(selected);

		// Prefer static order of the actions. Some components (activities list I'm looking at you) send in actions as an object
		// instead of an array, and for those we reverse sort by title. The sortedActions line below will no longer be
		// needed when actions is always an array.
		const sortedActions =
			actions.map !== undefined
				? actions
				: _.chain(actions)
						.sortBy(a => T(a.title))
						.reverse()
						.value();
		const renderedActions = sortedActions.map(this.renderAction);

		return (
			<Card
				tabIndex={-1}
				color="green"
				className={classes.mod({ visible: !!selected, open: !!selected && this.state.open }).b()}
			>
				<div className={classes.elem('header').b()} onClick={this.toggleList}>
					<Text size="sm" color="white" center>
						{T('default.manageSelected')}
					</Text>
					<Text color="white" center>
						{allSelected
							? `${T('default.all')} ${T('default.selected').toLowerCase()} (${formattedSelected})`
							: `${formattedSelected} ${T('default.selected').toLowerCase()}`}
					</Text>
					<Icon name="angle-up" color="white" />
				</div>

				{renderedActions}
				<div
					key="select-none"
					className={classes.elem('select-none').b()}
					onClick={() => {
						this.closeList();
						selectNone();
					}}
				>
					<Text center color="inherit">
						{T('default.deselectAll')}
					</Text>
				</div>
			</Card>
		);
	}
}

export default ListViewActions;
