import React from 'react';

import SalesboardFilterButton from '../Salesboard/SalesboardFilterButton';
import { FilterPreset, generateFilter } from 'App/babel/filterConfigs/filterGenerators';
import AccessType from 'App/enum/AccessType';
import { ListViewFilter } from 'App/resources/AllIWant';

export const filterConfigs = {
	User: generateFilter(
		FilterPreset.User,
		{
			field: 'user.id',
			title: 'default.users',
			parent: 'default.user'
		},
		AccessType.ACCOUNT
	)
};

type Props = {
	filter: ListViewFilter;
	onChange: (filter: ListViewFilter) => void;
};

const UserFilter = ({ filter, onChange }: Props) => {
	return (
		<SalesboardFilterButton
			key={filter.filterName}
			filter={filter}
			onChange={onChange}
			filterConfigs={filterConfigs}
			resetOnSelectAll={true}
		></SalesboardFilterButton>
	);
};

export default UserFilter;
