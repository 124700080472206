import React from 'react';
import { Headline, Row } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import './LetsCrunchSomeNumbers.scss';
import { SlideFade } from 'App/components/animations';
import { Item } from '../TopList/TopList';
import moment from 'moment';
import { capitalize } from 'lodash';

type Props = {
	next: () => void;
	bestDeal: string;
	bestMonth: string;
	mostSoldProduct: string;
	quickestDeal: number;
};

const LetsCrunchSomeNumbers = (props: Props) => {
	const { next, bestDeal, bestMonth, mostSoldProduct, quickestDeal } = props;
	const classNames = new bemClass('LetsCrunchSomeNumbers');
	return (
		<div
			className={classNames.b()}
			onClick={() => {
				next();
			}}
		>
			<div>
				<Row className={classNames.elem('headline').b()}>
					<Headline>{T('yourYearWithUpsales.letsCrunchSomeNumbers')}</Headline>
				</Row>
				<Row className={classNames.elem('data').b()}>
					<div className={classNames.elem('list').b()}>
						<SlideFade direction="left" visible>
							<div>
								<Item
									classes={classNames}
									icon={'gem'}
									title={T('yourYearWithUpsales.yourBestDeal')}
									value={bestDeal}
									visible
								/>
								<Item
									classes={classNames}
									icon={'flash'}
									title={T('yourYearWithUpsales.quickestDeal')}
									value={
										quickestDeal
											? quickestDeal +
											  ` ${quickestDeal === 1 ? T('default.day') : T('default.days')}`
											: '-'
									}
									visible
								/>
								<Item
									classes={classNames}
									icon={'calendar-dollar'}
									title={T('yourYearWithUpsales.yourBestMonth')}
									value={bestMonth === '-' ? bestMonth : capitalize(moment(bestMonth).format('MMMM'))}
									visible
								/>
								<Item
									classes={classNames}
									icon={'cubes'}
									title={T('yourYearWithUpsales.mostSoldProduct')}
									value={mostSoldProduct || '-'}
									visible
								/>
							</div>
						</SlideFade>
					</div>
				</Row>
				<img src="img/yourYearWithUpsales/data-dude.svg" />
			</div>
		</div>
	);
};

export default LetsCrunchSomeNumbers;
