import { Drawer, DrawerHeader, Loader, Flex, ButtonSelect, Title } from '@upsales/components';
import { detached as SignalsContent } from 'App/pages/Prospecting/Signals/SignalsFeed/SignalsContent';
import { FilterType } from 'Store/reducers/SignalsFeedReducer';
import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useEffect, useRef, useState } from 'react';
import { type CancelablePromise, makeCancelable } from 'Helpers/promise';
import { ModalProps } from '../Modals/Modals';
import Signals, { Item } from 'Resources/Signals';
import logError from 'Helpers/logError';

import './SignalsNews.scss';

const PAGE_SIZE = 50;

const SignalsNews = ({ className, clientIds, close }: { clientIds: number[] } & ModalProps) => {
	const [items, setItems] = useState<Item[]>([]);
	const [total, setTotal] = useState(0);
	const [offset, setOffset] = useState(0);
	const [loading, setLoading] = useState(true);
	const [type, setType] = useState<FilterType.ALL | FilterType.UPDATES | FilterType.NEWS>(FilterType.ALL);
	const { t } = useTranslation();
	const signalsRef = useRef<CancelablePromise<{ data: Item[]; metadata: { total: number } }> | null>(null);

	useEffect(() => {
		if (signalsRef.current) {
			signalsRef.current.cancel();
		}
		if (!loading) {
			setLoading(true);
		}
		signalsRef.current = makeCancelable(Signals.getFeed('all', type, 'all', PAGE_SIZE, offset, clientIds));
		signalsRef.current.promise
			.then(({ data, metadata }) => {
				setItems(data);
				setTotal(metadata.total);
			})
			.catch(e => {
				logError(e, 'Failed to fetch news items');
			})
			.finally(() => {
				setLoading(false);
			});

		return () => {
			if (signalsRef.current) {
				signalsRef.current.cancel();
			}
		};
	}, [clientIds, type, offset]);

	const classes = new BemClass('SignalsNews', className);
	return (
		<Drawer className={classes.b()}>
			<DrawerHeader onHide={close}>
				<Flex
					alignItems="center"
					justifyContent="space-between"
					className={classes.elem('header').elem('controls').b()}
					space="pll prl"
				>
					<Title>{t('companyGroup.startPage.signalsWidget')}</Title>
					<ButtonSelect
						options={[
							{
								value: FilterType.NEWS,
								title: t('default.news')
							},
							{
								value: FilterType.UPDATES,
								title: t('default.companyUpdates')
							},
							{
								value: FilterType.ALL,
								title: t('default.all')
							}
						]}
						value={type}
						onChange={setType}
					></ButtonSelect>
				</Flex>
			</DrawerHeader>
			{loading ? (
				<Flex space="mtxl" justifyContent="center">
					<Loader />
				</Flex>
			) : (
				<SignalsContent
					items={items}
					total={total}
					limit={PAGE_SIZE}
					offset={offset}
					paginate={offset => {
						setOffset(offset);
					}}
				/>
			)}
		</Drawer>
	);
};

export default SignalsNews;
