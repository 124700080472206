import React from 'react';
import { Button, Block, Icon, Tooltip, Link } from '@upsales/components';
import NotificationBadge from 'App/components/NotificationBadge';

export type Props = {
	className?: string;
	linkTarget: string;
	occurances: number;
	tooltipTitle: string;
};

export default function BadDataWarning({ linkTarget, occurances, tooltipTitle, className }: Props) {
	return (
		<Block space="mrs mts" className={className}>
			<Link color="blue" href={linkTarget}>
				<Tooltip title={tooltipTitle}>
					<Button
						id="list-view-add-btn"
						shadow="none"
						color="yellow-2"
						size="sm"
						className="notification-badge-parent"
					>
						<Icon name="warning" color="black" />
						<NotificationBadge color="yellow-5" amount={occurances} maxAmount={99} />
					</Button>
				</Tooltip>
			</Link>
		</Block>
	);
}
