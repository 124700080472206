import React from 'react';
import PropTypes from 'prop-types';
import { Block, Link, Flex, Text, Tooltip, Icon, Flag } from '@upsales/components';
import { NumberFormat } from 'App/babel/utils/numberFormat';
import { openDrawer } from 'Services/Drawer';
import { getAddress } from 'App/babel/components/CreateRelation/AddSubaccount/Views/columnParts';
import { trunc } from 'lodash';

export default class NameProspecting extends React.Component {
	constructor(props) {
		super(props);

		const t = Tools.$translate;

		this.lang = {
			parentCompany: t('default.parentCompany'),
			headquarters: t('soliditet.headquarters'),
			branch: t('default.branchOffice'),
			city: t('address.city'),
			numEmployees: t('default.numOfEmployees'),
			turnover: t('default.turnover')
		};

		this.state = { ref: null };
	}

	getSubtitle(hasSubAccountsV1AndV2 = false) {
		const { account } = this.props;

		const isSubaccountSubtitle = account.isSubaccount && hasSubAccountsV1AndV2;

		if (isSubaccountSubtitle) {
			const address = getAddress(account);

			return address ? (
				<Flex alignItems="center">
					<Flex>
						{address.country ? (
							<Flag className="subaccount-flag" space="mrs" code={address.country.toLowerCase()} />
						) : null}
					</Flex>
					<Text size="sm" color="grey-11">
						{address.city}
					</Text>
				</Flex>
			) : null;
		}
		if (
			!account.visitingCountry &&
			!account.visitingTown &&
			!account.postCountry &&
			!account.postTown &&
			!account.registeredCountry &&
			!account.registeredTown &&
			!account.revenue &&
			!account.noEmployees &&
			!account.countryCode
		) {
			return null;
		}

		const country =
			account.visitingCountry || account.postCountry || account.registeredCountry || account.countryCode;

		const flag = country ? <Flag space="mrs" code={country.toLowerCase()} /> : null;

		const town = account.visitingTown || account.postTown || account.registeredTown || null;

		const addressSpan = (
			<span style={{ verticalAlign: 'middle' }}>
				{flag}
				{ReactTemplates.TOOLS.withTooltip(<span>{town}</span>, this.lang.city, {
					placement: 'top'
				})}
			</span>
		);

		let numEmployees = null,
			turnover = null;

		if (account.headquarters === 1) {
			const numberFormat = new NumberFormat();
			if (account.noEmployees !== null) {
				const numEmployeesText = numberFormat.short(account.noEmployees);
				numEmployees = (
					<span style={{ marginLeft: '10px' }}>
						<i className="fa fa-user" /> {numEmployeesText}
					</span>
				);
			}
			if (account.revenue !== null) {
				const turnoverText = numberFormat.short(account.revenue);
				turnover = (
					<span style={{ marginLeft: '10px' }}>
						<i className="fa fa-bar-chart" /> {turnoverText}
					</span>
				);
			}
		}

		return (
			<span className="subtitle">
				{addressSpan}
				{ReactTemplates.TOOLS.withTooltip(numEmployees, this.lang.numEmployees, { placement: 'top' })}
				{ReactTemplates.TOOLS.withTooltip(turnover, this.lang.turnover, { placement: 'top' })}
			</span>
		);
	}

	getAdditionalInfo() {
		if (this.props.account.closestGroupMotherOrgnumber === null && this.props.account.groupSize > 1) {
			return <span className="additional-info">{this.lang.parentCompany}</span>;
		} else if (this.props.account.headquarters === 1) {
			return <span className="additional-info">{this.lang.headquarters}</span>;
		} else {
			return <span className="additional-info">{this.lang.branch}</span>;
		}
	}

	setRef = ref => {
		this.setState({ ref });
	};

	render() {
		let overflow = false;
		const { ref } = this.state;
		if (ref) {
			overflow = ref.scrollWidth > ref.offsetWidth;
		}
		const skipAdditionalInfo = this.props.field?.skipAdditionalInfo;
		const hasSubAccounts = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS');
		const hasSubAccountsV2 = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS_V2');

		const hasSubAccountsV1AndV2 = hasSubAccounts && hasSubAccountsV2;

		const { account } = this.props;
		const count = account.numberOfSubaccounts ?? 0;
		const langTag = `account.subaccount.count${count > 1 ? '.multi' : ''}`;
		const windowWidth = window.innerWidth;

		const accountName = trunc(account?.name, {
			length: windowWidth > 1500 ? 40 : windowWidth > 1300 ? 30 : 20
		});

		const onClick = e => {
			e.stopPropagation();
			openDrawer('SubaccountDrawer', {
				client: {
					id: account.id,
					name: account.name,
					hasAccess: true
				},
				includingSubaccountData: true
			});
		};

		const res = (
			<Flex>
				{hasSubAccountsV1AndV2 && account.numberOfSubaccounts ? (
					<Icon
						space="mrm"
						name={this.props.showSubaccounts ? 'chevron-up' : 'chevron-down'}
						className="chevron-icon"
						onClick={e => {
							e.stopPropagation();
							this.props.onToggleSubaccounts?.();
						}}
					/>
				) : null}
				<Flex direction="column">
					<Flex alignItems="center">
						<Tooltip title={account.name} disabled={account.name === accountName && !overflow}>
							<span className="row-text" ref={this.setRef}>
								{accountName} {skipAdditionalInfo ? null : this.getAdditionalInfo()}
							</span>
						</Tooltip>
						{hasSubAccounts && !hasSubAccountsV2 && account.numberOfSubaccounts ? (
							<Block space="mlm">
								<Text size="sm">
									<Link onClick={onClick}>{Tools.$translate(langTag, { count })}</Link>
								</Text>
							</Block>
						) : null}
						{hasSubAccountsV1AndV2 ? (
							<Block space="mlm" backgroundColor="grey-4" borderRadius>
								<Text size="sm" color="grey-11" space="pls prs">
									{account.numberOfSubaccounts && !this.props.showSubaccounts
										? Tools.$translate(langTag, { count })
										: account.isSubaccount
										? Tools.$translate('account.subaccount')
										: null}
								</Text>
							</Block>
						) : null}
					</Flex>

					{this.getSubtitle(hasSubAccountsV1AndV2)}
				</Flex>
			</Flex>
		);
		return this.props.noTable ? (
			<div className={`DataSourceColumn ${this.props.field?.className}`}>{res}</div>
		) : (
			<td className={`DataSourceColumn ${this.props.field?.className}`}>{res}</td>
		);
	}
}

NameProspecting.propTypes = {
	account: PropTypes.object,
	field: PropTypes.object,
	noTable: PropTypes.bool,
	onToggleSubaccounts: PropTypes.func,
	showSubaccounts: PropTypes.bool
};
