import { Modal, ModalHeader } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { ModalProps } from 'App/components/Modals/Modals';
import OrderStage from 'App/resources/Model/OrderStage';
import { useTranslation } from 'Components/Helpers/translate';
import React from 'react';

type Props = {
	title: string;
	hideHeader: boolean;
	highlight: boolean;
	columns: { title: string; value: keyof OrderStage }[];
	data: OrderStage[];
	selected: number;
} & ModalProps<OrderStage>;

const StageListModal: React.FC<Props> = ({
	title,
	hideHeader,
	highlight,
	columns,
	data: stages,
	selected,
	close,
	className
}) => {
	const { t } = useTranslation();
	const classes = new BemClass('StageList', className);

	const { lost, won, open } = stages.reduce<{ lost: OrderStage[]; won: OrderStage[]; open: OrderStage[] }>(
		(acc, stage) => {
			if (stage.probability === 0) {
				acc.lost.push(stage);
			} else if (stage.probability === 100) {
				acc.won.push(stage);
			} else {
				acc.open.push(stage);
			}
			return acc;
		},
		{ lost: [], won: [], open: [] }
	);

	lost.sort((a, b) => a.name.localeCompare(b.name));
	won.sort((a, b) => a.name.localeCompare(b.name));
	open.sort((a, b) => {
		if (a.probability === b.probability) {
			return a.name.localeCompare(b.name);
		}
		return a.probability - b.probability;
	});

	return (
		<Modal size="sm" className={classes.b()}>
			<ModalHeader title={t(title)} onClose={() => close()} />
			<table className="main-table">
				{!hideHeader ? (
					<thead>
						<tr>
							{columns.map(column => (
								<th key={column.value}>{t(column.title)}</th>
							))}
						</tr>
					</thead>
				) : null}

				<tbody style={{ color: '#55585a' }}>
					{open.length ? (
						<tr className="stage-title">
							<td colSpan={2}>{t('default.ongoing').toUpperCase()}</td>
						</tr>
					) : null}

					{open.map((row, index) => (
						<tr
							key={row.id}
							style={{ borderBottom: 0 }}
							className={`active ${highlight && row.id === selected ? 'selected-grey' : ''}`}
							data-testid={row.probability + '-' + index}
							onClick={() => close(row)}
						>
							{columns.map(column => (
								<td key={column.value} style={{ borderLeft: '0' }}>
									{column.value === 'probability' ? (
										<span>
											<i>{row[column.value]} %</i>
										</span>
									) : (
										<span>{row[column.value]}</span>
									)}
								</td>
							))}
						</tr>
					))}

					{won.length ? (
						<tr className="stage-title">
							<td colSpan={2}>{t('default.won').toUpperCase()}</td>
						</tr>
					) : null}

					{won.map((row, index) => (
						<tr
							key={row.id}
							className={`won ${highlight && row.id === selected ? 'selected-grey' : ''}`}
							data-testid={row.probability + '-' + index}
							onClick={() => close(row)}
						>
							{columns.map(column => (
								<td key={column.value} style={{ borderLeft: '0' }}>
									{column.value === 'probability' ? (
										<span>
											<i>{row[column.value]} %</i>
										</span>
									) : (
										<span>{row[column.value]}</span>
									)}
								</td>
							))}
						</tr>
					))}

					{lost.length ? (
						<tr className="stage-title">
							<td colSpan={2}>{t('default.lost').toUpperCase()}</td>
						</tr>
					) : null}

					{lost.map((row, index) => (
						<tr
							key={row.id}
							className={`lost ${highlight && row.id === selected ? 'selected-grey' : ''}`}
							data-testid={row.probability + '-' + index}
							onClick={() => close(row)}
						>
							{columns.map(column => (
								<td key={column.value} className={'selected-blue'} style={{ borderLeft: 0 }}>
									{column.value === 'probability' ? (
										<span>
											<i>{row[column.value]} %</i>
										</span>
									) : (
										<span>{row[column.value]}</span>
									)}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</Modal>
	);
};

export default StageListModal;
