import React from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Label,
	Input,
	Row,
	Column,
	Title,
	Toggle,
	Tabs,
	Tab,
	Icon,
	Card,
	CardContent,
	Loader,
	Text,
	Block,
	DropDownButton,
	DropDownMenu,
	Table,
	TableRow,
	TableColumn
} from '@upsales/components';
import getAngularModule from '../../../../angularHelpers/getAngularModule';
import bemClass from '@upsales/components/Utils/bemClass';
import CodeEditor from '../../../CodeEditor';
import { connect } from 'react-redux';
import './TemplateEditor.scss';
import { getTemplateOld, setEditorBody, saveTemplateOld, clearEditor } from 'Store/actions/TemplateEditorActions';
import ModalTagList from '../../../Modals/ModalTagList';
import InlineAction from 'Components/Dialogs/InlineAction/InlineAction';
import { asyncModalAdapter, setupComponentCompatibility } from 'App/helpers/angularPortingHelpers';

const mapDispatchToProps = {
	getTemplateOld,
	setEditorBody,
	saveTemplateOld,
	clearEditor
};

const mapStateToProps = state => ({
	editor: state.TemplateEditor.editor,
	preview: state.TemplateEditor.preview,
	isDirty: state.TemplateEditor.isDirty
});

const editorModes = {
	HTML: 'html',
	CSS: 'css',
	JSON: 'json',
	LANGUAGE: 'language'
};

class TemplateEditor extends React.PureComponent {
	constructor(p) {
		super(p);
		const t = getAngularModule('$translate');
		this.state = {
			tab: editorModes.HTML,
			html: '',
			css: '',
			json: '',
			language: '',
			editorValue: '',
			mode: 'handlebars',
			templateName: '',
			showTagList: false,
			expandTemplates: false,
			showInlineAction: 'none'
		};
		this.lang = {
			modalTitle: t('admin.documentTemplate.standardTemplatesModal.modalTitle'),
			nameLabel: t('admin.documentTemplate.standardTemplatesModal.nameLabel'),
			namePlaceHolder: t('admin.documentTemplate.standardTemplatesModal.namePlaceHolder'),
			userRoleLabel: t('admin.documentTemplate.standardTemplatesModal.userRoleLabel'),
			userRolePlaceHolder: t('admin.documentTemplate.standardTemplatesModal.userRolePlaceHolder'),
			previewError: t('document.previewError'),
			browserErrorDescription: t('document.browserError.description'),
			browserErrorHelperLink: t('document.browserError.helperLink'),
			browserErrorTitle: t('document.browserError.title'),
			sampleDataTab: t('admin.documentTemplate.standardTemplatesModal.sampleDataTab'),
			languageTab: t('admin.documentTemplate.standardTemplatesModal.languageTab'),
			previewTitle: t('admin.documentTemplate.standardTemplatesModal.previewTitle'),
			goBackBtn: t('admin.documentTemplate.standardTemplatesModal.goBackBtn'),
			saveAndReloadBtn: t('admin.documentTemplate.standardTemplatesModal.saveAndReloadBtn'),
			saveBtn: t('admin.documentTemplate.standardTemplatesModal.saveBtn'),
			active: t('admin.documentTemplate.standardTemplatesModal.activeLabel'),
			htmlTab: t('admin.documentTemplate.standardTemplatesModal.htmlTab'),
			cssTab: t('admin.documentTemplate.standardTemplatesModal.cssTab'),
			clearEditor: t('admin.documentTemplate.standardTemplatesModal.clearEditor'),
			showTags: t('admin.documentTemplate.standardTemplatesModal.showTags'),
			aboutCustomTemplates: t('admin.documentTemplate.standardTemplatesModal.aboutCustomTemplates'),
			createFrom: t('admin.documentTemplate.standardTemplatesModal.createFrom'),
			emptyStandardTemplateList: t('admin.documentTemplate.standardTemplatesModal.emptyStandardTemplateList')
		};
	}
	onChangeTab = tab => {
		this.setState({ tab });
		if (tab === editorModes.HTML) {
			this.setState({ editorValue: this.state.html, mode: 'handlebars' });
		} else if (tab === editorModes.CSS) {
			this.setState({ editorValue: this.state.css, mode: editorModes.CSS });
		} else if (tab === editorModes.JSON) {
			this.setState({ editorValue: this.state.json, mode: editorModes.JSON });
		} else {
			this.setState({ editorValue: this.state.language, mode: editorModes.JSON });
		}
	};

	onEditorValueChange = value => {
		this.props.setEditorBody(this.state.tab, this.props.editor, value.code);
	};

	saveTemplate = (saveAndClose = false) => {
		this.props.editor.templateName = this.state.templateName;
		this.props.saveTemplateOld(this.props.editor, saveAndClose, this.props.reject);
	};

	onChangeUpdate = (keyValue, e) => {
		if (keyValue === 'templateName') {
			this.setState({ templateName: e });
		}
		this.props.setEditorBody(keyValue, this.props.editor, e);
	};

	toggleTagList() {
		this.setState({
			showTagList: !this.state.showTagList
		});
	}

	resetEditor() {
		this.setState({
			templateName: ''
		});
		this.props.clearEditor();
	}

	setTemplate = (template, createFromAnother = false) => {
		this.setState({ templateName: template.templateData.template_name });
		this.props.getTemplateOld(template, createFromAnother);
	};

	getTemplateDropdownContent = close => {
		return (
			<Table>
				{this.props.standardTemplates.length ? (
					this.props.standardTemplates.map((template, index) => (
						<TableRow className="template-row" key={index}>
							<TableColumn
								onClick={() => {
									this.setTemplate(template, true);
									close();
								}}
								title={template.templateData.template_name}
							/>
						</TableRow>
					))
				) : (
					<TableRow disabled>
						<TableColumn
							onClick={() => {
								close();
							}}
							title={this.lang.emptyStandardTemplateList}
						/>
					</TableRow>
				)}
			</Table>
		);
	};

	closeModal = from => {
		if (this.props.isDirty) {
			this.setState({ showInlineAction: from });
		} else {
			this.props.reject();
		}
	};

	componentDidMount() {
		if (this.props.documentTemplate) {
			this.setState({ templateName: this.props.documentTemplate.templateData.template_name });
			this.props.getTemplateOld(this.props.documentTemplate);
		}
	}

	componentWillUnmount() {
		this.props.clearEditor();
	}

	render() {
		const { reject, editor, preview } = this.props;
		const classes = new bemClass('TemplateEditor');
		const roles = Tools.AppService.getRoles();

		return (
			<div className={classes.b()}>
				<div className={classes.elem('left').b()}>
					<div className={classes.elem('editor-header').b()}>
						<Title color="white">{this.lang.modalTitle}</Title>
						<Block space="ptl">
							<Row>
								<Block space="prm">
									<Column size={4}>
										<Label className="TemplateEditor__label" required>
											{this.lang.nameLabel}
										</Label>
										<Input
											size="md"
											color="grey-2"
											placeholder={this.lang.namePlaceHolder}
											value={this.state.templateName}
											onChange={e => this.onChangeUpdate('templateName', e.target.value)}
										/>
									</Column>
								</Block>
								<Column size={5}>
									<Label className="TemplateEditor__label">{this.lang.userRoleLabel}</Label>
									<ReactTemplates.INPUTS.upRoles
										data={roles}
										value={editor.userRoles}
										multiple={true}
										onChange={e => this.onChangeUpdate('userRoles', e)}
										placeholder={this.lang.userRolePlaceHolder}
										className="template-editor-roles"
									/>
								</Column>
								<Column align="right">
									<Block space="prm">
										<Label className="TemplateEditor__label">{this.lang.active}</Label>
										<Toggle
											size="lg"
											color="bright-blue"
											checked={editor.active}
											onChange={v => this.onChangeUpdate('active', v)}
										/>
									</Block>
								</Column>
							</Row>
						</Block>
						<Row>
							<Column size={6}>
								<Tabs
									color="blue"
									noFlex={false}
									selected={this.state.tab}
									onChange={tab => this.onChangeTab(tab)}
									className={classes.elem('tabs')}
								>
									<Tab noFlex={false} id={editorModes.HTML}>
										{this.lang.htmlTab}
									</Tab>
									<Tab noFlex={false} id={editorModes.CSS}>
										{this.lang.cssTab}
									</Tab>
									<Tab noFlex={false} id={editorModes.JSON}>
										{this.lang.sampleDataTab}
									</Tab>
									<Tab noFlex={false} id={editorModes.LANGUAGE}>
										{this.lang.languageTab}
									</Tab>
								</Tabs>
							</Column>
							<Column align="right" className="Editor-tab-buttons">
								<Block space="ptm">
									<DropDownMenu
										renderTrigger={(expanded, setExpanded) => (
											<DropDownButton
												title={this.lang.createFrom}
												className="TemplateEditor__templates"
												size="sm"
												color="grey-11"
												onClick={setExpanded}
												expanded={expanded}
											/>
										)}
									>
										{close => this.getTemplateDropdownContent(close)}
									</DropDownMenu>
									<Button
										type="link"
										hoverColor="white"
										onClick={() => this.toggleTagList()}
										className="TemplateEditor__tags"
									>
										{this.lang.showTags}
									</Button>
								</Block>
							</Column>
						</Row>
					</div>
					<div className={classes.elem('editor').b()}>
						<CodeEditor
							value={editor[this.state.tab]}
							onChange={value => this.onEditorValueChange(value)}
							theme="monokai"
							useWorker={this.state.tab !== editorModes.CSS}
							showGutter={true}
							mode={this.state.mode}
							maxLines={{ Infinity }}
						/>
					</div>
				</div>
				<div className={classes.elem('right').b()}>
					<div className={classes.elem('preview-header').b()}>
						<Row>
							<Column size={3}>
								<Title color="black">{this.lang.previewTitle}</Title>
							</Column>
							<Column size={9} align="right">
								<ReactTemplates.elevio articleId={1131} sidebar>
									<Button type="link">
										{this.lang.aboutCustomTemplates}{' '}
										<Icon name="question-circle" color="dark-blue" />
									</Button>
								</ReactTemplates.elevio>
								<Button
									color="bright-blue"
									type="lined"
									onClick={() => this.closeModal('top')}
									style={{ marginRight: '6px' }}
								>
									<Icon name="angle-left" style={{ paddingRight: '6px' }} /> {this.lang.goBackBtn}
								</Button>
								<Button color="bright-blue" onClick={() => this.saveTemplate()}>
									{this.lang.saveAndReloadBtn}
								</Button>
								{this.state.showInlineAction === 'top' ? (
									<InlineAction
										toggleInlineAction={val => this.setState({ showInlineAction: val })}
										onReject={reject}
										onConfirm={() => this.saveTemplate(true)}
										showTop
									/>
								) : null}
							</Column>
						</Row>
					</div>
					<div className={classes.elem('preview-pdf').b()}>
						{preview.loading ? (
							<Loader size="sm" className="pdf-loader" />
						) : preview.error ? (
							<Card color="grey-11" borderColor="blue" className="error-card">
								<CardContent>
									<Text center size="xl" color="white">
										{this.lang.previewError}
									</Text>
								</CardContent>
							</Card>
						) : (
							<object
								data={preview.url}
								type="application/pdf"
								width="100%"
								height="100%"
								className="iframe"
							>
								<Card color="grey-11" borderColor="blue" className="helper-message">
									<CardContent>
										<Title center size="md" color="white">
											{this.lang.browserErrorTitle}
										</Title>
										<Text center size="md" color="grey-8">
											{this.lang.browserErrorDescription}
										</Text>
										<Block align="center" space="ptxl">
											<ReactTemplates.elevio sidebar={true} articleId={1161}>
												<Button size="md" color="bright-blue">
													{this.lang.browserErrorHelperLink}
												</Button>
											</ReactTemplates.elevio>
										</Block>
									</CardContent>
								</Card>
							</object>
						)}
					</div>
				</div>
				<div className={classes.elem('footer').b()}>
					<Button size="sm" loading={false} color="grey" onClick={() => this.saveTemplate()}>
						{this.lang.saveBtn}
					</Button>
					<Button
						size="sm"
						disabled={false}
						color="grey"
						type="link"
						onClick={() => this.closeModal('bottom')}
					>
						{this.lang.goBackBtn}
					</Button>
					{this.state.showInlineAction === 'bottom' ? (
						<InlineAction
							toggleInlineAction={val => this.setState({ showInlineAction: val })}
							onReject={reject}
							onConfirm={() => this.saveTemplate(true)}
							showRight={false}
							showLeft
						/>
					) : null}
				</div>
				<div className={classes.elem('tagList').mod({ visible: this.state.showTagList }).b()}>
					<ModalTagList
						entity="order"
						onClose={() => this.toggleTagList()}
						style={this.state.showTagList ? { display: 'block' } : { display: 'none' }}
						isFromPdfTemplates
					/>
				</div>
			</div>
		);
	}
}

TemplateEditor.propTypes = {
	reject: PropTypes.func.isRequired,
	editor: PropTypes.object.isRequired,
	preview: PropTypes.object.isRequired,
	standardTemplates: PropTypes.array
};

TemplateEditor.defaultProps = {
	reject: () => {},
	standardTemplates: []
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const ConnectedComponent = connector(TemplateEditor);

export const TemplateEditorModal = setupComponentCompatibility(ConnectedComponent, {
	modalName: 'TemplateEditorModal' + ' ' + 'FullscreenModal'
});

export const openTemplateEditorModal = asyncModalAdapter({
	upModalName: 'templateEditor',
	openModalName: 'TemplateEditorModal',
	featureFlag: 'REACT_TEMPLATE_EDITOR'
});

export const detached = TemplateEditor;
export default connect(mapStateToProps, mapDispatchToProps)(TemplateEditor);
