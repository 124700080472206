import React from 'react';
import { connect } from 'react-redux';
import T from '../../Helpers/translate';
import { Icon, ModalContent, ModalHeader, ModalControls, Text, Checkbox, Avatar } from '@upsales/components';
import { makeOwner, saveView, setModalView, mapUsersAndRoles } from 'Store/reducers/SharedViewsReducer';
import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import { asyncModalAdapter, setupComponentCompatibility } from 'App/helpers/angularPortingHelpers';

const mapStateToProps = state => ({
	data: state.SharedViews.modalView
});

const mapDispatchToProps = {
	makeOwner,
	saveView,
	setModalView
};

function isSelected(id, type, data) {
	if (type === 'roles' && isNaN(id)) {
		id = parseInt(id.replace('role-', ''));
	}

	return !!data[type].find(item => {
		return item.id === id;
	});
}

const checkboxChange = (obj, currentView) => {
	if (!obj.isRole && !isSelected(obj.id, 'users', currentView)) {
		// the obj is not a selected user and its not a role
		if (obj.role && isSelected(obj.role.id, 'roles', currentView)) {
			// its not a role but its role is selected.
			// this indicates that a click on this one is an 'uncheck'
			const usersInsideRole = Tools.AppService.getUsers().filter(item => {
				return item.role.id === obj.role.id && item.id !== obj.id;
			});

			currentView.roles = currentView.roles.filter(role => role.id !== obj.role.id);
			currentView.users = [...usersInsideRole];
		} else {
			currentView.users = [...currentView.users, obj];
		}
	} else if (!obj.isRole && isSelected(obj.id, 'users', currentView)) {
		currentView.users = currentView.users.filter(user => user.id !== obj.id);
	} else if (obj.isRole) {
		let parsedRoleId = obj.id;

		if (isNaN(obj.id)) {
			parsedRoleId = parseInt(obj.id.replace('role-', ''));
		}

		if (isSelected(parsedRoleId, 'roles', currentView)) {
			// should remove every that got obj.id as id
			currentView.roles = currentView.roles.filter(role => role.id !== parsedRoleId);
		} else {
			currentView.roles = [...currentView.roles, obj];
		}
	}

	return currentView;
};

function SharedViewsModalComponent({ data, resolve, reject, saveView, setModalView, makeOwner }) {
	const ContentTree = [];

	const tree = [
		{
			name: 'All active users',
			$depth: 0,
			isSpecialRow: true,
			children: window.UserRoleTree({
				selectableRoles: true,
				noParentRef: true,
				roleIdPrefix: true,
				includeInactive: true
			})
		}
	];

	function changeOwner(view, user) {
		const returnView = true;
		const updateAllViews = true;
		const updatedView = Object.assign(view, { regBy: user.id });

		makeOwner(view, user, returnView);
		const mappedView = mapUsersAndRoles(updatedView);
		setModalView(mappedView, updateAllViews);
	}

	async function onCheckChange(event, obj) {
		const currentView = checkboxChange(obj, { ...data });

		currentView.userlist = [...currentView.roles, ...currentView.users];
		currentView.regBy = currentView.regBy.id;
		delete currentView.hidden;
		delete currentView.default;

		const resetView = false;
		const returnView = true;
		const comesFromAdminModal = true;
		const updateAllViews = true;

		const updatedView = await saveView(currentView, {}, resetView, returnView, comesFromAdminModal);
		const mappedView = mapUsersAndRoles(updatedView);
		setModalView(mappedView, updateAllViews);
	}

	let total = 0;
	function recursiveMapper(row) {
		ContentTree.push(row);

		if (row.children) {
			row.children.forEach(child => {
				if (!child.isRole) total += 1;
				recursiveMapper(child);
			});
		}
	}

	tree.forEach(row => {
		recursiveMapper(row);
	});

	return (
		<div className="SharedViewsModal">
			<ModalHeader title={data.title} />
			<ModalContent>
				{ContentTree.map(item => {
					let checkboxTicked;
					if (item.id) {
						if (isNaN(item.id)) {
							checkboxTicked = isSelected(parseInt(item.id.replace('role-', '')), 'roles', data);
						} else {
							checkboxTicked = isSelected(item.id, 'users', data);

							if (checkboxTicked === false && item.role) {
								checkboxTicked = isSelected(item.role.id, 'roles', data);
							}
						}
					}

					if (data.regBy.id === item.id) {
						checkboxTicked = true;
					}

					const depthPadding = (item.$depth + 1) * 15;
					if (item.isSpecialRow) {
						return (
							<div
								className="SharedViewsModal__Row SharedViewsModal__Row--Role"
								style={{ paddingLeft: depthPadding }}
								key={item.name}
							>
								<Checkbox
									disabled={true}
									checked={checkboxTicked}
									size="xs"
									style={{ display: 'inline-flex' }}
								/>
								<Text style={{ display: 'inline-block' }}>{item.name}</Text>
								<Text color="grey-11" style={{ display: 'inline-block' }}>
									<Icon name="users" />
									{total}
								</Text>
							</div>
						);
					}

					if (item.isRole) {
						let halfChecked = false;
						let subSelected = [];

						for (const userChild of item.children) {
							if (data.regBy.id === userChild.id) {
								subSelected.push(true);
							}

							const value = isSelected(userChild.id, 'users', data);
							subSelected.push(value);
						}

						subSelected = _.compact(subSelected);
						if (subSelected.length > 0) {
							checkboxTicked = true;

							if (
								subSelected.length !== item.children.length ||
								isSelected(item.id, 'roles', data) === false
							) {
								halfChecked = true;
							}
						}

						return (
							<div
								className="SharedViewsModal__Row SharedViewsModal__Row--Role"
								style={{ paddingLeft: depthPadding }}
								key={item.name}
							>
								<Checkbox
									onClick={event => onCheckChange(event, item)}
									checked={checkboxTicked}
									half={halfChecked}
									size="xs"
									style={{ display: 'inline-flex' }}
								/>
								<Text style={{ display: 'inline-block' }}>{item.name}</Text>
								<Text color="grey-11" style={{ display: 'inline-block' }}>
									<Icon name="users" />
									{item.children.length}
								</Text>
								<Icon color="grey-8" style={{ marginLeft: 'auto' }} name="chevron-down" />
							</div>
						);
					}

					return (
						<div
							className="SharedViewsModal__Row SharedViewsModal__Row--User"
							style={{ paddingLeft: depthPadding }}
							key={item.name}
						>
							<Checkbox
								onClick={event => onCheckChange(event, item)}
								checked={checkboxTicked}
								size="xs"
								style={{ display: 'inline-flex' }}
							/>
							<Avatar size={28} email={item.email} initials={item.name} />
							<Text style={{ display: 'inline-block' }}>{item.name}</Text>
							{data.regBy.id === item.id ? (
								<div className="Pill">{T('sharedViews.owner')}</div>
							) : (
								<ThirdButton onClick={() => changeOwner(data, item)} type="link" className="NotOwner">
									{T('sharedViews.admin.makeOwner')}
								</ThirdButton>
							)}
						</div>
					);
				})}
			</ModalContent>
			<ModalControls>
				<PrimaryButton onClick={resolve} color="bright-blue" shadow="none">
					{T('default.save')}
				</PrimaryButton>
				<ThirdButton onClick={reject} color="grey" type="link">
					{T('cancel')}
				</ThirdButton>
			</ModalControls>
		</div>
	);
}

export const detached = SharedViewsModalComponent;
export const functions = {
	checkboxChange,
	isSelected
};
const Component = connect(mapStateToProps, mapDispatchToProps)(SharedViewsModalComponent);

export const SharedViewsModal = setupComponentCompatibility(Component, {
	modalName: 'SharedViewsModal',
	modalSize: 'md'
});

export const openSharedViewsModal = asyncModalAdapter({
	upModalName: 'sharedViewsModal',
	openModalName: 'SharedViewsModal',
	featureFlag: 'REACT_SHARED_VIEWS_MODAL'
});

export default Component;

window.SharedViewsModal = Component;
