import Attribute, { DisplayType, Type } from './Attribute';

const VisitAttributes = {
	id: Attribute({
		title: 'default.id',
		field: 'id',
		type: Type.Number,
		displayType: DisplayType.Number,
		sortable: 'id'
	}),
	startDate: Attribute({
		title: 'default.date',
		field: 'startDate',
		type: Type.Date,
		displayType: DisplayType.DateTime,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'startDate'
	}),
	endDate: Attribute({
		title: 'default.date',
		field: 'endDate',
		type: Type.Date,
		displayType: DisplayType.DateTime,
		groupable: false,
		selectableColumn: false,
		filterable: false
	}),
	client: Attribute({
		title: 'default.client',
		field: 'client',
		type: Type.Object,
		displayKey: ['client.id', 'client.name'],
		displayKeyMap: { 'client.id': 'id', 'client.name': 'name' },
		displayType: DisplayType.ClientLink,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'client.id'
	}),
	contact: Attribute({
		title: 'default.contact',
		field: 'contact',
		type: Type.Object,
		displayKey: ['contact.id', 'contact.name'],
		displayKeyMap: { 'contact.id': 'id', 'contact.name': 'name' },
		displayType: DisplayType.ContactLink,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'name',
		attr: {
			id: {
				field: 'contact.id',
				type: Attribute.types.Number
			},
			name: {
				field: 'contact.name',
				type: Attribute.types.String
			}
		}
	}),
	account: Attribute({
		title: 'default.contactAndClient',
		field: 'account',
		inputType: 'select',
		displayAttr: 'name',
		type: Type.Number,
		displayType: DisplayType.Text,
		selectableColumn: true,
		sortable: 'account'
	}),
	status: Attribute({
		title: 'default.history',
		field: 'client',
		type: Type.Object,
		displayKey: [
			'client.hasActivity',
			'client.hadActivity',
			'client.hasAppointment',
			'client.hadAppointment',
			'client.hasOpportunity',
			'client.hadOpportunity',
			'client.hadOrder',
			'client.hasOrder'
		],
		displayKeyMap: {
			'client.hasActivity': 'hasActivity',
			'client.hadActivity': 'hadActivity',
			'client.hasAppointment': 'hasAppointment',
			'client.hadAppointment': 'hadAppointment',
			'client.hasOpportunity': 'hasOpportunity',
			'client.hadOpportunity': 'hadOpportunity',
			'client.hadOrder': 'hadOrder',
			'client.hasOrder': 'hasOrder'
		},
		displayType: DisplayType.ClientHistory,
		groupable: false,
		selectableColumn: true,
		filterable: false
	}),
	location: Attribute({
		title: 'address.city',
		field: 'location',
		type: Type.Object,
		displayKey: ['client.mailAddress.city', 'client.mailAddress.country'],
		displayKeyMap: { 'client.mailAddress.city': 'name', 'client.mailAddress.country': 'country' },
		displayType: DisplayType.Location,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'client.mailAddress.city'
	}),
	assigned: Attribute({
		title: 'assign.assignedTo',
		field: 'assigned',
		type: Type.Object,
		displayKey: ['client.processedBy', 'client.processedBy'],
		displayKeyMap: { 'client.processedBy': 'processedBy' },
		displayType: DisplayType.Assigned,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		elevioId: 242
	}),
	score: Attribute({
		title: 'default.score',
		field: 'score',
		type: Type.Number,
		displayType: DisplayType.Score,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'score'
	}),
	leadSource: Attribute({
		title: 'default.leadsource',
		field: 'leadSource',
		type: Type.Object,
		displayType: DisplayType.LeadSource,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'leadSource.source'
	}),
	journeyStep: Attribute({
		type: Type.String,
		title: 'default.journeyStep',
		field: 'client.journeyStep',
		displayKey: 'client.journeyStep',
		displayType: DisplayType.Journey,
		selectableColumn: true
	}),
	clientJourneyStep: Attribute({
		title: 'default.journeyStepClient',
		type: Type.String,
		field: 'client.journeyStep',
		displayKey: 'client.journeyStep',
		displayType: DisplayType.Journey
	}),
	contactJourneyStep: Attribute({
		type: Type.String,
		title: 'default.journeyStepContact',
		field: 'contact.journeyStep',
		displayKey: 'contact.journeyStep',
		displayType: DisplayType.Journey
	}),
	pages: Attribute({
		title: 'visitor.pages',
		field: 'pages',
		type: Type.Array,
		displayType: DisplayType.Number,
		groupable: false,
		selectableColumn: true,
		filterable: false
	}),
	time: Attribute({
		title: 'default.time',
		field: 'time',
		type: Type.Array,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false
	}),
	visitCountry: Attribute({
		title: 'visit.country',
		field: 'country',
		type: Type.Array,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false
	}),
	turnover: Attribute({
		title: 'column.revenue',
		field: 'turnover',
		type: Type.Array,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false
	}),
	groupStructure: Attribute({
		title: 'account.relations.groupSize.title',
		field: 'groupStructure',
		type: Type.Array,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false
	}),
	utmCampaign: Attribute({
		title: 'column.utmCampaign',
		field: 'type',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'leadSource.utm.type'
	}),
	utmContent: Attribute({
		title: 'column.utmContent',
		field: 'utmContent',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'leadSource.utm.utmContent'
	}),
	utmMedium: Attribute({
		title: 'column.utmMedium',
		field: 'utmMedium',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'leadSource.utm.utmMedium'
	}),
	utmSource: Attribute({
		title: 'column.utmSource',
		field: 'utmSource',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'leadSource.utm.utmSource'
	}),
	utmTerm: Attribute({
		title: 'column.utmTerm',
		field: 'utmTerm',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'leadSource.utm.utmTerm'
	}),
	lastAssigned: Attribute({
		title: 'assign.lastAssigned',
		field: 'client.assigned.regDate',
		type: Type.Date,
		displayType: DisplayType.Date,
		selectableColumn: true
	})
};

export default VisitAttributes;
