import { RCDashboardWidget } from 'Resources/ReportDashboard';
import { RCWidgetData, RCWidgetMeta } from 'Resources/ReportWidget';
import { displayTypeKeys } from '../reportCenterHelpers';
import T from 'Components/Helpers/translate';
import { IconName } from '@upsales/components';
import { Metadata } from 'App/resources/AllIWant';
import { getCMWithRROption } from 'App/helpers/salesModelHelpers';
import { getStagesFromState } from 'Store/selectors/AppSelectors';
import store from 'Store/index';
const generate2ndGroupingRow = (goal: number, progress: number, remaining: number, index: number, label: string) => ({
	goal,
	key: `sr${index}`,
	label,
	progress,
	remaining
});

type Translate = (tag: string, opts?: object | undefined) => string;

const getGroupLabels = (groupType: string, rows: Array<Array<number>>, T: Translate) => {
	// Probably length 3 is enough.
	const monthLabels = ['date.january', 'date.february', 'date.march'].map(month => `${T(month).slice(0, 3)} 2021`);
	const yearLabels = ['2019', '2020', '2021'];
	const company = T('default.client');
	const clientLabels = [`${company} A`, `${company} B`, `${company} C`];
	const product = T('default.product');
	const productLabels = [`${product} A`, `${product}  B`, `${product} C`];
	const weekLabels = rows.map((r, i) => `${T('date.weekUnit')}${i + 35}`);
	const userLabels = rows.map(() => 'report.widgetExamples.exampleData.nameLabel');

	let groupLabels = [];
	switch (groupType) {
		case 'month':
			groupLabels = monthLabels;
			break;
		case 'year':
			groupLabels = yearLabels;
			break;
		case 'week':
			groupLabels = weekLabels;
			break;
		case 'client':
			groupLabels = clientLabels;
			break;
		case 'product':
			groupLabels = productLabels;
			break;
		case 'user': // Default is user
		default:
			groupLabels = userLabels;
	}
	return groupLabels;
};

const generateMockData = (dataType: string, overrides: any) => {
	const { progressLabel, groupBy, progressSpecial, totalSpecial } = overrides;
	const is2ndGrouping = groupBy?.length > 1;

	const rows =
		dataType === 'currency'
			? [
					[500000, 390000, 110000],
					[500000, 500000, 0]
			  ]
			: [
					[11, 8, 3],
					[18, 13, 5]
			  ];
	const total = dataType === 'currency' ? 890000 : 21;
	const displayCurrency = dataType === 'currency' ? 'SEK' : dataType === 'days' ? T('days') : null;

	let groupLabels1 = rows.map(() => 'report.widgetExamples.exampleData.nameLabel');
	if (groupBy?.length) {
		groupLabels1 = getGroupLabels(groupBy[0], rows, T);
		if (groupBy.length > 1) {
			// For another time: Handle 2nd grouping
		}
	}

	return {
		currency: displayCurrency,
		rows: rows.map(([g, p, r], i) => ({
			key: i,
			label: T(groupLabels1[i]),
			goal: g,
			progress: p,
			progressSpecial,
			remaining: r,
			rows: is2ndGrouping
				? [generate2ndGroupingRow(g, p, r, i, T('report.widgetExamples.exampleData.nameLabel'))]
				: [],
			colors: !is2ndGrouping
				? { progress: { color: '#1D3D48' }, overGoal: { color: '#256C76' }, goal: { color: '#EDF1F5' } }
				: {}
		})),
		datatype: { goal: dataType, progress: dataType, remaining: dataType },
		language: {
			goal: T('default.goal'),
			progress: progressLabel ?? T('report.widgetExamples.exampleData.progress'),
			remaining: T('report.widgetExamples.exampleData.remaining'),
			firstGrouping: groupBy?.[0] ? T(`reportcenter.grouping.${groupBy[0]}`) : T('reportcenter.grouping.user'),
			total: T('default.total'),
			pipeline: T('report.pipeline')
		},
		total: { goal: total * 1.2, progress: total, remaining: total * 1.2 - total, special: totalSpecial },
		colors: {
			progress: { color: '#1D3D48' },
			overGoal: { color: '#256C76' },
			goal: { color: '#EDF1F5' },
			pipeline: { color: '#256C76' }
		}
	};
};

const getDimensionsByType = (displayType: string): { width: number; height: number } => {
	switch (displayType) {
		case displayTypeKeys.BAR_CHART:
		case displayTypeKeys.TABLE:
			return { width: 4, height: 3 };
		case displayTypeKeys.BIG_NUMBER:
			return { width: 2, height: 1 };
		case displayTypeKeys.PROGRESS_BAR:
			return { width: 2, height: 1 };
		case displayTypeKeys.FUNNEL:
			return { width: 4, height: 2 };
		case displayTypeKeys.PRODUCT_GRID:
			return { width: 4, height: 3 };
	}
	return { width: 1, height: 1 };
};

// IMPORTANT: If you add anything to this function that uses Tools, it will probably break the dashboard sendouts since rc-dashboard is pure React and doesn't have access to it.
export const generateWidget = (
	type: string,
	displayType: string,
	dataType: string,
	metadata: Metadata,
	overrides: any = {}
) => {
	const { width, height } = getDimensionsByType(displayType);
	const excludedStages = getStagesFromState(store.getState().App).filter(s => s.exclude);
	return {
		name: '',
		title: '',
		type,
		displayType,
		groupBy: ['user'],
		width,
		height,
		data: generateMockData(dataType, overrides),
		showGoal: !!overrides?.showGoal,
		filters: [
			{
				attribute: 'date',
				comparison: 'eq',
				value: metadata.params.brokenFiscalYearEnabled ? 'CurrentFiscalYear' : 'CurrentYear'
			},
			...(excludedStages.length > 0
				? [
						{
							attribute: 'stage',
							comparison: 'ne',
							value: excludedStages.map(stage => stage.id).join(',')
						}
				  ]
				: [])
		],
		customOrderSumField: null,
		...overrides
	};
};

export type WidgetExampleType = {
	[k: string]: {
		title: string;
		description: string;
		exampleConfigs: (RCDashboardWidget & { data: RCWidgetData })[];
		unavailable?: { title: string; description: string };
	};
};

const widgetExamples: (metadata: Metadata) => WidgetExampleType = metadata => {
	const salesModelOption = getCMWithRROption() ?? metadata.params.SalesModelOption;
	return {
		AGREEMENT_TCV: {
			title: T('reportcenter.tcvWidgets'),
			description: T('reportcenter.widgetExamples.tcv.description'),
			exampleConfigs: [
				generateWidget('AGREEMENT_TCV', displayTypeKeys.COLUMN_CHART, 'currency', metadata, {
					title: T('report.widgetExamples.tcv.title'),
					groupBy: ['client'],
					progressLabel: T('TD report.widget.tcv')
				})
			]
		},
		REVENUE_RECOGNITION: {
			title: T('reportcenter.revenueRecognitionWidgets'),
			description: T('reportcenter.widgetExamples.revenueRecognition.secured.description'),
			exampleConfigs: [
				generateWidget('REVENUE_RECOGNITION', displayTypeKeys.COLUMN_CHART, 'currency', metadata, {
					title: T('report.widgetExamples.revenueRecognition.title'),
					groupBy: ['month'],
					progressLabel: T('report.widget.revenue')
				})
			]
		},
		REVENUE_RECOGNITION_IDEAL: {
			title: T('reportcenter.revenueRecognitionIdealWidgets'),
			description: T('reportcenter.widgetExamples.revenueRecognition.ideal.description'),
			exampleConfigs: [
				generateWidget('REVENUE_RECOGNITION_IDEAL', displayTypeKeys.COLUMN_CHART, 'currency', metadata, {
					title: T('report.widgetExamples.revenueRecognitionIdeal.title'),
					groupBy: ['month'],
					progressLabel: T('report.widget.revenue')
				})
			]
		},
		REVENUE_RECOGNITION_WEIGHTED: {
			title: T('reportcenter.revenueRecognitionWeightedWidgets'),
			description: T('reportcenter.widgetExamples.revenueRecognition.weighted.description'),
			exampleConfigs: [
				generateWidget('REVENUE_RECOGNITION_WEIGHTED', displayTypeKeys.COLUMN_CHART, 'currency', metadata, {
					title: T('report.widgetExamples.revenueRecognitionWeighted.title'),
					groupBy: ['month'],
					progressLabel: T('report.widget.revenue')
				})
			]
		},
		CREATED_ASSIGNED: {
			title: T('report.createdAssignedWidgets'),
			description: T('reportcenter.widgetExamples.createdAssigned.description'),
			exampleConfigs: [
				generateWidget('CREATED_ASSIGNED', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('report.widgetExamples.createdAssignedWidgets.title'),
					groupBy: ['week'],
					progressLabel: T('report.widget.assigned')
				})
			]
		},
		SOLD_PRODUCTS_COUNT: {
			title: T('report.soldProductsCountWidgets'),
			description: T('reportcenter.widgetExamples.soldProductsCount.description'),
			exampleConfigs: [
				generateWidget('SOLD_PRODUCTS_COUNT', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('report.widgetExamples.soldProductsCount.title')
				})
			]
		},
		CLOSED_ORDER_COUNT: {
			title: T('report.closedOrderWidgets'),
			description: T('reportcenter.widgetExamples.closedOrderCount.description'),
			exampleConfigs: [
				generateWidget('CLOSED_ORDER_COUNT', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('report.widgetExamples.closedOrderCount.title')
				})
			]
		},
		SALES: {
			title: T('default.sales'),
			description: T('reportcenter.widgetExamples.sales.description'),
			exampleConfigs: [
				generateWidget('SALES', displayTypeKeys.COLUMN_CHART, 'currency', metadata, {
					title: T('report.widgetExamples.sales.title')
				})
			]
		},
		SUM_CUSTOM_ORDER_FIELD: {
			title: T('reportcenter.widgetExamples.sumCustomOrderField.title'),
			description: T('reportcenter.widgetExamples.sumCustomOrderField.description'),
			exampleConfigs: [
				generateWidget('SUM_CUSTOM_ORDER_FIELD', displayTypeKeys.COLUMN_CHART, 'currency', metadata, {
					title: T('default.sum') + ' ' + T('default.customField')
				})
			]
		},
		LEADS_FUNNEL: {
			title: T('report.widgetExamples.leadsFunnelWidgets.title'),
			description: T('reportcenter.widgetExamples.leadsFunnel.description'),
			exampleConfigs: [
				generateWidget('LEADS_FUNNEL', displayTypeKeys.FUNNEL, 'currency', metadata, {
					title: T('report.widgetExamples.leadsFunnelWidgets.title'),
					data: {
						currency: 'SEK',
						total: {
							special: {
								stage1: 50,
								stage2: 25,
								stage3: 15,
								stage4: 10,
								avgValue: 40000,
								totalValue: 1000000,
								percentCompleted: 80,
								percentCreated: 50,
								percentWon: 25
							}
						},
						datatype: { progress: 'currency' },
						language: {
							stage1: T('report.widget.assignedLeads'),
							stage2: T('report.widget.appointment.booked.assigned'),
							stage3: T('report.widget.createdOpportunities.leads'),
							stage4: T('report.widgetExamples.salesFunnelWidgets.wonDeals'),
							avgValue: T('report.widgetExamples.salesFunnelWidgets.avgValue'),
							totalValue: T('report.widgetExamples.salesFunnelWidgets.totalValue')
						}
					}
				})
			]
		},
		SALES_CYCLE: {
			title: T('report.widget.sales.salesCycle.title'),
			description: T('reportcenter.widgetExamples.salesCycle.description'),
			exampleConfigs: [
				generateWidget('SALES_CYCLE', displayTypeKeys.COLUMN_CHART, 'days', metadata, {
					title: T('report.widget.sales.salesCycle.title')
				})
			]
		},
		BOOKED_APPOINTMENT: {
			title: T('report.bookedAppointments'),
			description: T('reportcenter.widgetExamples.bookedAppointments.description'),
			exampleConfigs: [
				generateWidget('BOOKED_APPOINTMENT', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('report.widgetExamples.bookedAppointments.title')
				})
			]
		},

		COMPLETED_APPOINTMENT: {
			title: T('report.completedAppointments'),
			description: T('reportcenter.widgetExamples.completedAppointments.description'),
			exampleConfigs: [
				generateWidget('COMPLETED_APPOINTMENT', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('report.widgetExamples.completedAppointments.title')
				})
			]
		},

		PLANNED_APPOINTMENT: {
			title: T('report.plannedAppointments'),
			description: T('reportcenter.widgetExamples.plannedAppointments.description'),
			exampleConfigs: [
				generateWidget('PLANNED_APPOINTMENT', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('report.widgetExamples.plannedAppointments.title')
				})
			]
		},

		COMPLETED_FIRST_APPOINTMENT: {
			title: T('reportcenter.completedFirstAppointmentWidgets'),
			description: T('reportcenter.widgetExamples.completedFirstAppointments.description'),
			exampleConfigs: [
				generateWidget('COMPLETED_FIRST_APPOINTMENT', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('report.widgetExamples.completedFirstAppointmentWidgets.title')
				})
			]
		},
		BOOKED_FIRST_APPOINTMENT: {
			title: T('reportcenter.bookedFirstAppointmentWidgets'),
			description: T('reportcenter.widgetExamples.bookedFirstAppointments.description'),
			exampleConfigs: [
				generateWidget('BOOKED_FIRST_APPOINTMENT', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('report.widgetExamples.bookedFirstAppointmentWidgets.title')
				})
			]
		},
		BOOKED_APPOINTMENT_HIT_RATE: {
			title: T('report.widgetExamples.bookedAppointmentHitRate.title'),
			description: T('reportcenter.widgetExamples.bookedAppointmentHitRate.description'),
			exampleConfigs: [
				generateWidget('BOOKED_APPOINTMENT_HIT_RATE', displayTypeKeys.TABLE, 'percent', metadata, {
					title: T('report.widgetExamples.bookedAppointmentHitRate.title'),
					progressSpecial: { type: 'hitRate', data: { numerator: 1, denominator: 5, percent: 20 } },
					totalSpecial: { numerator: 1, denominator: 5, percent: 20 },
					progressLabel: T('report.widget.hitRate.hitRate')
				})
			]
		},
		BOOKED_FIRST_APPOINTMENT_HIT_RATE: {
			title: T('report.widgetExamples.bookedFirstAppointmentHitRate.title'),
			description: T('reportcenter.widgetExamples.bookedFirstAppointmentHitRate.description'),
			exampleConfigs: [
				generateWidget('BOOKED_FIRST_APPOINTMENT_HIT_RATE', displayTypeKeys.TABLE, 'percent', metadata, {
					title: T('report.widgetExamples.bookedFirstAppointmentHitRate.title'),
					progressSpecial: { type: 'hitRate', data: { numerator: 1, denominator: 5, percent: 20 } },
					totalSpecial: { numerator: 1, denominator: 5, percent: 20 },
					progressLabel: T('report.widget.hitRate.hitRate')
				})
			]
		},
		PHONECALL_APPOINTMENT_HIT_RATE: {
			title: T('reportcenter.widget.hitrate.bookedAppointment.phoneCall.widgets'),
			description: T('reportcenter.widgetExamples.phoneCallAppointmentHitRate.description'),
			exampleConfigs: [
				generateWidget('PHONECALL_APPOINTMENT_HIT_RATE', displayTypeKeys.TABLE, 'percent', metadata, {
					title: T('reportcenter.widgetExamples.hitrate.bookedAppointment.phoneCall.title'),
					groupBy: ['user'],
					progressSpecial: { type: 'hitRate', data: { numerator: 1, denominator: 5, percent: 20 } },
					totalSpecial: { numerator: 1, denominator: 5, percent: 20 },
					progressLabel: T('report.widget.hitRate.hitRate')
				})
			]
		},
		CONTRIBUTION_MARGIN: {
			title: T('reportcenter.contributionMarginWidgets'),
			description: T('reportcenter.widgetExamples.contributionMargin.description'),
			exampleConfigs: [
				generateWidget('CONTRIBUTION_MARGIN', displayTypeKeys.COLUMN_CHART, 'currency', metadata, {
					title: T('report.widgetExamples.contributionMarginWidgets.sales.title'),
					progressLabel: T('report.widget.sales.contributionMargin')
				})
			]
		},
		RECURRING: {
			title: T(`reportcenter.recurringWidgets.${salesModelOption}`),
			description: T(`reportcenter.widgetExamples.recurringVsTarget.${salesModelOption}.description`),
			exampleConfigs: [
				salesModelOption === 'arr'
					? generateWidget('RECURRING', displayTypeKeys.COLUMN_CHART, 'currency', metadata, {
							title: T('report.widgetExamples.recurringWidgets.arr.title'),
							groupBy: ['year']
					  })
					: generateWidget('RECURRING', displayTypeKeys.COLUMN_CHART, 'currency', metadata, {
							title: T('report.widgetExamples.recurringWidgets.mrr.title'),
							groupBy: ['month']
					  })
			]
		},
		RECURRING_CHANGES: {
			title: T(`reportcenter.widgetExamples.recurringChanges.${salesModelOption}`),
			description: T(`reportcenter.widgetExamples.recurringChanges.${salesModelOption}.description`),
			exampleConfigs: [
				generateWidget('RECURRING_CHANGES', displayTypeKeys.COLUMN_CHART, 'currency', metadata, {
					customColumn: () => {},
					title: T(`report.widgetExamples.recurringChanges.${salesModelOption}.title`),
					groupBy: ['month', 'recurringType'],
					data: {
						type: {
							name: 'RECURRING_CHANGES',
							family: 'rr',
							salesModel: ['rr'],
							feature: 'recurringOrder'
						},
						language: {
							goal: T('default.goal'),
							progress: T(`report.widget.change.${salesModelOption}`),
							remaining: T('report.widgetExamples.exampleData.remaining'),
							total: T('default.total'),
							firstGrouping: T('reportcenter.grouping.month')
						},
						datatype: {
							goal: 'currency',
							progress: 'currency',
							remaining: 'currency'
						},
						currency: 'SEK',
						rows: [
							{
								key: '2021-9',
								sorting: '2021-08-06T22:00:00.000Z',
								label: 'Aug 2021',
								goal: 0,
								progress: 41500,
								remaining: 0,
								rows: [
									{
										key: 'NEW_CLIENT',
										sorting: 20000,
										label: 'New customer',
										goal: 0,
										progress: 20000,
										remaining: 0
									},
									{
										key: 'WIN_BACK',
										sorting: 21500,
										label: 'Win-back',
										goal: 0,
										progress: 21500,
										remaining: 0
									}
								],
								rowMaxProgress: 4150,
								rowMinProgress: 0
							},
							{
								key: '2021-8',
								sorting: '2021-06-17T22:00:00.000Z',
								label: 'Jun 2021',
								goal: 0,
								progress: 67500,
								remaining: 0,
								rows: [
									{
										key: 'NEW_CLIENT',
										sorting: 27000,
										label: 'New customer',
										goal: 0,
										progress: 27000,
										remaining: 0
									},
									{
										key: 'INCREASE',
										sorting: 40500,
										label: 'Upsell',
										goal: 0,
										progress: 40500,
										remaining: 0
									}
								],
								rowMaxProgress: 67500,
								rowMinProgress: 0
							}
						],
						total: {
							goal: 0,
							progress: 109000,
							remaining: 11000,
							columns: {
								INCREASE: 40500,
								NEW_CLIENT: 47000,
								WIN_BACK: 21500
							},
							columnArray: [
								{
									key: 'INCREASE',
									progress: 104329812,
									label: 'arrchange.type.INCREASE'
								},
								{
									key: 'NEW_CLIENT',
									progress: 913583.333332,
									label: 'arrchange.type.NEW_CLIENT'
								},
								{
									key: 'WIN_BACK',
									progress: 4150,
									label: 'arrchange.type.WIN_BACK'
								}
							]
						},
						enableDrilldown: true,
						hasGoal: false,
						colors: {
							DECREASE: { color: '#F58523', label: T('arrchange.type.DECREASE') },
							LOST_CLIENT: { color: '#CA5342', label: T('arrchange.type.LOST_CLIENT') },
							INCREASE: { color: '#3F76BE', label: T('arrchange.type.INCREASE') },
							INDEX_INCREASE: { color: '#3F76BE', label: T('arrchange.type.INDEX_INCREASE') },
							NEW_CLIENT: { color: '#41909B', label: T('arrchange.type.NEW_CLIENT') },
							WIN_BACK: { color: '#A4B3C7', label: T('arrchange.type.WIN_BACK') }
						}
					}
				})
			]
		},
		TOTAL_RECURRING: {
			title: T(`reportcenter.widgetExamples.totalRecurring.${salesModelOption}`),
			description: T(`reportcenter.widgetExamples.totalRecurring.${salesModelOption}.description`),
			exampleConfigs: [
				generateWidget('TOTAL_RECURRING', displayTypeKeys.COLUMN_CHART, 'currency', metadata, {
					title: T(`report.widgetExamples.totalRecurring.title`, {
						salesModelOption: salesModelOption?.toUpperCase()
					}),
					groupBy: ['month']
				})
			]
		},
		TOTAL_RECURRING_PRODUCT: {
			title: T(`reportcenter.widgetExamples.totalRecurringProduct.${salesModelOption}`),
			description: T(`reportcenter.widgetExamples.totalRecurringProduct.${salesModelOption}.description`),
			exampleConfigs: [
				generateWidget('TOTAL_RECURRING_PRODUCT', displayTypeKeys.COLUMN_CHART, 'currency', metadata, {
					title: T(`report.widgetExamples.totalRecurringProduct.title`, {
						salesModelOption: salesModelOption?.toUpperCase()
					}),
					groupBy: ['year', 'product']
				})
			]
		},
		CURRENT_TOTAL_RECURRING: {
			title: T(`reportcenter.widgetExamples.currentTotalRecurring.${salesModelOption}`),
			description: T(`reportcenter.widgetExamples.currentTotalRecurring.${salesModelOption}.description`),
			exampleConfigs: [
				generateWidget('CURRENT_TOTAL_RECURRING', displayTypeKeys.COLUMN_CHART, 'currency', metadata, {
					title: T(`report.widgetExamples.currentTotalRecurring.title`, {
						salesModelOption: salesModelOption?.toUpperCase()
					}),
					groupBy: ['user'],
					filters: []
				})
			]
		},
		RECURRING_GROWTH: {
			title: T('report.widgetExamples.recurringGrowth.name', {
				salesModelOption: salesModelOption?.toUpperCase()
			}),
			description: T(`reportcenter.widgetExamples.totalRecurring.${salesModelOption}.description`),
			exampleConfigs: [
				generateWidget('RECURRING_GROWTH', displayTypeKeys.COLUMN_CHART, 'percent', metadata, {
					title: T('report.widgetExamples.recurringGrowth.title', {
						salesModelOption: salesModelOption?.toUpperCase()
					}),
					groupBy: ['year'],
					progressLabel: T('report.widget.change')
				})
			]
		},
		ONEOFF: {
			title: T('reportcenter.oneOffWidgets'),
			description: T('reportcenter.widgetExamples.oneOff.description'),
			exampleConfigs: [
				generateWidget('ONEOFF', displayTypeKeys.COLUMN_CHART, 'currency', metadata, {
					title: T('report.widgetExamples.oneOffWidgets.title'),
					groupBy: ['user']
				})
			]
		},
		ACTIVE_CUSTOMERS_COUNT: {
			title: T('report.widgetExamples.activeCustomersWidget.title'),
			description: T('reportcenter.widgetExamples.activeCustomers.description'),
			exampleConfigs: [
				generateWidget('ACTIVE_CUSTOMERS_COUNT', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('report.widgetExamples.activeCustomersWidget.title')
				})
			]
		},
		FLOW_NUMBER_OF_CONTACTS: {
			title: T('reportcenter.defaultDb.marketing.flowNumberOfContacts'),
			description: T('reportcenter.widgetExamples.flowNumberOfContacts.description'),
			exampleConfigs: [
				generateWidget('FLOW_NUMBER_OF_CONTACTS', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('reportcenter.defaultDb.marketing.flowNumberOfContacts'),
					groupBy: ['month'],
					progressLabel: T('report.widget.flowNumberOfContacts.progress')
				})
			]
		},
		EMAIL_OUTCOME: {
			title: T('reportcenter.emailOutcomeWidgets'),
			description: T('reportcenter.widgetExamples.emailOutcome.description'),
			exampleConfigs: [
				generateWidget('EMAIL_OUTCOME', displayTypeKeys.TABLE, 'number', metadata, {
					title: T('report.widgetExamples.emailOutcomeWidgets.title'),
					groupBy: ['user'],
					data: {
						rows: [
							{
								key: '1',
								label: 'Gustav Pettersson',
								rows: [
									{
										key: 'sentMail',
										label: T('activity.outcomeLog.sent'),
										progress: 8,
										sorting: 0
									},
									{
										key: 'openedMail',
										label: T('event.mailWasRead'),
										progress: 5,
										sorting: 1
									},
									{
										key: 'appointments',
										label: T('default.oppointments'),
										progress: 3,
										sorting: 3
									},
									{
										key: 'opportunities',
										label: T('default.opportunities'),
										progress: 2,
										sorting: 4
									},
									{
										key: 'orders',
										label: T('default.orders'),
										progress: 2,
										sorting: 5
									}
								],
								total: 8
							}
						],
						total: {
							sentMail: 8,
							openedMail: 5,
							appointments: 3,
							opportunities: 2,
							orders: 2
						},
						language: {
							firstGrouping: T('default.user'),
							total: T('default.total')
						},
						datatype: {
							progress: 'number'
						},
						enableDrilldown: true
					}
				})
			]
		},
		ACTIVITY_OUTCOME: {
			title: T('reportcenter.activityOutcomeWidgets'),
			description: T('reportcenter.widgetExamples.activityOutcome.description'),
			exampleConfigs: [
				generateWidget('ACTIVITY_OUTCOME', displayTypeKeys.TABLE, 'number', metadata, {
					title: T('report.widgetExamples.activityOutcomeWidgets.title'),
					groupBy: ['user'],
					data: {
						rows: [
							{
								key: '1',
								label: 'Gustav Pettersson',
								rows: [
									{
										key: 'total',
										label: 'Total',
										progress: 8,
										sorting: 0
									},
									{
										key: 'reached',
										label: 'Reached',
										progress: 5,
										sorting: 1
									},
									{
										key: 'notReached',
										label: 'Not reached',
										progress: 3,
										sorting: 2
									},
									{
										key: 'appointments',
										label: 'Appointments',
										progress: 0,
										sorting: 3
									},
									{
										key: 'opportunities',
										label: 'Opportunities',
										progress: 0,
										sorting: 4
									},
									{
										key: 'orders',
										label: 'Orders',
										progress: 0,
										sorting: 5
									}
								],
								total: 8
							},
							{
								key: '9',
								label: 'John Wilson',
								rows: [
									{
										key: 'total',
										label: 'Total',
										progress: 17,
										sorting: 0
									},
									{
										key: 'reached',
										label: 'Reached',
										progress: 12,
										sorting: 1
									},
									{
										key: 'notReached',
										label: 'Not reached',
										progress: 5,
										sorting: 2
									},
									{
										key: 'appointments',
										label: 'Appointments',
										progress: 3,
										sorting: 3
									},
									{
										key: 'opportunities',
										label: 'Opportunities',
										progress: 2,
										sorting: 4
									},
									{
										key: 'orders',
										label: 'Orders',
										progress: 2,
										sorting: 5
									}
								],
								total: 17
							}
						],
						total: {
							total: 25,
							reached: 17,
							notReached: 8,
							appointments: 3,
							opportunities: 2,
							orders: 2
						},
						language: {
							firstGrouping: T('default.user'),
							total: T('default.total')
						},
						datatype: {
							progress: 'number'
						},
						enableDrilldown: true
					}
				})
			]
		},
		REACHED_CLIENTS: {
			title: T('reportcenter.reachedClientsWidgets'),
			description: T('reportcenter.widgetExamples.reachedClients.description'),
			exampleConfigs: [
				generateWidget('REACHED_CLIENTS', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('report.widgetExamples.reachedClientsWidgets.title'),
					groupBy: ['week']
				})
			]
		},
		CLOSED_ACTIVITIES: {
			title: T('reportcenter.closedActivitiesWidgets'),
			description: T('reportcenter.widgetExamples.closedActivities.description'),
			exampleConfigs: [
				generateWidget('CLOSED_ACTIVITIES', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('report.widgetExamples.closedActivitiesWidgets.title'),
					groupBy: ['week']
				})
			]
		},
		CREATED_PIPELINE: {
			title: T('reportcenter.createdPipelineWidgets'),
			description: T('reportcenter.widgetExamples.createdPipeline.description'),
			exampleConfigs: [
				generateWidget('CREATED_PIPELINE', displayTypeKeys.COLUMN_CHART, 'currency', metadata, {
					title: T('report.widgetExamples.createdPipelineWidgets.title')
				})
			]
		},
		PIPELINE: {
			title: T('reportcenter.pipelineWidgets'),
			description: T('reportcenter.widgetExamples.pipeline.description'),
			exampleConfigs: [
				generateWidget('PIPELINE', displayTypeKeys.COLUMN_CHART, 'currency', metadata, {
					title: T('report.widgetExamples.pipelineWidgets.value.title')
				})
			]
		},
		WEIGHTED_PIPELINE: {
			title: T('reportcenter.weightedPipelineWidgets'),
			description: T('reportcenter.widgetExamples.weightedPipeline.description'),
			exampleConfigs: [
				generateWidget('WEIGHTED_PIPELINE', displayTypeKeys.COLUMN_CHART, 'currency', metadata, {
					title: T('report.widgetExamples.weightedPipelineWidgets.value.title')
				})
			]
		},
		CONTRIBUTION_MARGIN_PIPELINE: {
			title: T('reportcenter.contributionMarginPipeline'),
			description: T('report.widget.pipeline.contributionMargin.description'),
			exampleConfigs: [
				generateWidget('CONTRIBUTION_MARGIN_PIPELINE', displayTypeKeys.COLUMN_CHART, 'currency', metadata, {
					title: T('report.widget.pipeline.contributionMargin.title'),
					progressLabel: T('report.widget.sales.contributionMargin')
				})
			]
		},
		CONTRIBUTION_MARGIN_WEIGHTED_PIPELINE: {
			title: T('reportcenter.contributionMarginWeightedPipeline'),
			description: T('report.widget.pipeline.weightedContributionMargin.description'),
			exampleConfigs: [
				generateWidget(
					'CONTRIBUTION_MARGIN_WEIGHTED_PIPELINE',
					displayTypeKeys.COLUMN_CHART,
					'currency',
					metadata,
					{
						title: T('report.widget.pipeline.weightedContributionMargin.title'),
						progressLabel: T('report.widget.sales.contributionMargin')
					}
				)
			]
		},
		PIPELINE_SPECIAL: {
			title: T('reportcenter.pipelineSpecialWidgets'),
			description: T('reportcenter.widgetExamples.pipelineSpecial.description'),
			exampleConfigs: [
				generateWidget('PIPELINE_SPECIAL', displayTypeKeys.TABLE, 'currency', metadata, {
					title: T('report.widgetExamples.pipelineSpecialWidgets.title'),
					groupBy: ['user'],
					data: {
						currency: 'SEK',
						rows: [
							{
								key: 1,
								label: T('report.widgetExamples.exampleData.nameLabel'),
								rows: [
									{
										key: 'pipeline',
										label: T('report.widgetExamples.pipelineSpecialWidgets.totalPipeline'),
										progress: 15300,
										sorting: 0
									},
									{
										key: 'weightedPipeline',
										label: T('report.widgetExamples.pipelineSpecialWidgets.weightedValue'),
										progress: 6400,
										sorting: 1
									},
									{
										key: 'avgValue',
										label: T('report.widgetExamples.pipelineSpecialWidgets.avgValue'),
										progress: 3060,
										sorting: 2
									},
									{
										key: 'distribution',
										label: T('report.widgetExamples.pipelineSpecialWidgets.distribution'),
										progress: {
											type: 'stageDistribution',
											stages: [
												{
													value: 40,
													total: 6120,
													count: 2,
													stageId: 1,
													stageName: T(
														'report.widgetExamples.pipelineSpecialWidgets.firstStage'
													)
												},
												{
													value: 60,
													total: 9180,
													count: 3,
													stageId: 2,
													stageName: T(
														'report.widgetExamples.pipelineSpecialWidgets.secondStage'
													)
												}
											]
										},
										sorting: 3
									}
								]
							},
							{
								key: 2,
								label: T('report.widgetExamples.exampleData.nameLabel'),
								rows: [
									{
										key: 'pipeline',
										label: T('report.widgetExamples.pipelineSpecialWidgets.totalPipeline'),
										progress: 15300,
										sorting: 0
									},
									{
										key: 'weightedPipeline',
										label: T('report.widgetExamples.pipelineSpecialWidgets.weightedValue'),
										progress: 6400,
										sorting: 1
									},
									{
										key: 'avgValue',
										label: T('report.widgetExamples.pipelineSpecialWidgets.avgValue'),
										progress: 3060,
										sorting: 2
									},
									{
										key: 'distribution',
										label: T('report.widgetExamples.pipelineSpecialWidgets.distribution'),
										progress: {
											type: 'stageDistribution',
											stages: [
												{
													value: 40,
													total: 6120,
													count: 2,
													stageId: 1,
													stageName: T(
														'report.widgetExamples.pipelineSpecialWidgets.firstStage'
													)
												},
												{
													value: 60,
													total: 9180,
													count: 3,
													stageId: 2,
													stageName: T(
														'report.widgetExamples.pipelineSpecialWidgets.secondStage'
													)
												}
											]
										},
										sorting: 3
									}
								]
							},
							{
								key: 3,
								label: T('report.widgetExamples.exampleData.nameLabel'),
								rows: [
									{
										key: 'pipeline',
										label: T('report.widgetExamples.pipelineSpecialWidgets.totalPipeline'),
										progress: 15300,
										sorting: 0
									},
									{
										key: 'weightedPipeline',
										label: T('report.widgetExamples.pipelineSpecialWidgets.weightedValue'),
										progress: 6400,
										sorting: 1
									},
									{
										key: 'avgValue',
										label: T('report.widgetExamples.pipelineSpecialWidgets.avgValue'),
										progress: 3060,
										sorting: 2
									},
									{
										key: 'distribution',
										label: T('report.widgetExamples.pipelineSpecialWidgets.distribution'),
										progress: {
											type: 'stageDistribution',
											stages: [
												{
													value: 40,
													total: 6120,
													count: 2,
													stageId: 1,
													stageName: T(
														'report.widgetExamples.pipelineSpecialWidgets.firstStage'
													)
												},
												{
													value: 60,
													total: 9180,
													count: 3,
													stageId: 2,
													stageName: T(
														'report.widgetExamples.pipelineSpecialWidgets.secondStage'
													)
												}
											]
										},
										sorting: 3
									}
								]
							}
						],
						datatype: { progress: 'currency' },
						language: {
							firstGrouping: T('default.user'),
							total: T('default.total')
						},
						total: {
							columnArray: [
								{
									key: 'pipeline',
									label: T('report.widgetExamples.pipelineSpecialWidgets.totalPipeline'),
									progress: 17702
								},
								{
									key: 'weightedPipeline',
									label: T('report.widgetExamples.pipelineSpecialWidgets.weightedValue'),
									progress: 3780
								},
								{
									key: 'avgValue',
									label: T('report.widgetExamples.pipelineSpecialWidgets.avgValue'),
									progress: 3540
								},
								{
									key: 'distribution',
									label: 'Fördelning',
									progressSpecial: {
										label: T('report.widgetExamples.pipelineSpecialWidgets.distribution'),
										data: {
											stages: [
												{
													value: 0,
													total: 2,
													count: 1,
													stageName: T(
														'report.widgetExamples.pipelineSpecialWidgets.firstStage'
													),
													stageId: 8
												},
												{
													value: 44,
													total: 7800,
													count: 1,
													stageName: T(
														'report.widgetExamples.pipelineSpecialWidgets.secondStage'
													),
													stageId: 9
												},
												{
													value: 56,
													total: 9900,
													count: 3,
													stageName: T(
														'report.widgetExamples.pipelineSpecialWidgets.secondStage'
													),
													stageId: 10
												}
											]
										}
									}
								}
							]
						},
						colors: {
							'8': {
								label: 'Fallback',
								color: '#1D3D48'
							},
							'9': {
								label: 'Prospect 1',
								color: '#2EAA9B'
							},
							'10': {
								label: 'Prospect 2',
								color: '#41909B'
							}
						}
					}
				})
			]
		},
		SALES_FUNNEL: {
			title: T('reportcenter.salesFunnelWidgets'),
			description: T('reportcenter.widgetExamples.salesFunnel.description'),
			exampleConfigs: [
				generateWidget('SALES_FUNNEL', displayTypeKeys.FUNNEL, 'currency', metadata, {
					title: T('report.widgetExamples.salesFunnelWidgets.title'),
					data: {
						currency: 'SEK',
						total: {
							special: {
								stage1: 100,
								stage2: 80,
								stage3: 50,
								stage4: 25,
								avgValue: 40000,
								totalValue: 1000000,
								percentCompleted: 80,
								percentCreated: 50,
								percentWon: 25
							}
						},
						datatype: { progress: 'currency' },
						language: {
							stage1: T('report.widgetExamples.salesFunnelWidgets.bookedFirstAppointments'),
							stage2: T('report.widgetExamples.salesFunnelWidgets.completedFirstAppointments'),
							stage3: T('report.widgetExamples.salesFunnelWidgets.createdOpportunities'),
							stage4: T('report.widgetExamples.salesFunnelWidgets.wonDeals'),
							avgValue: T('report.widgetExamples.salesFunnelWidgets.avgValue'),
							totalValue: T('report.widgetExamples.salesFunnelWidgets.totalValue')
						}
					}
				})
			]
		},
		OPPORTUNITY_COUNT: {
			title: T('reportcenter.opportunityCountWidgets'),
			description: T('reportcenter.widgetExamples.opportunityCount.description'),
			exampleConfigs: [
				generateWidget('OPPORTUNITY_COUNT', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('report.widgetExamples.opportunityCountWidgets.title')
				})
			]
		},
		CREATED_PIPELINE_COUNT: {
			title: T('reportcenter.createdPipelineCountWidgets'),
			description: T('reportcenter.widgetExamples.createdPipelineCount.description'),
			exampleConfigs: [
				generateWidget('CREATED_PIPELINE_COUNT', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('report.widgetExamples.createdPipelineCountWidgets.title'),
					groupBy: ['month'],
					progressLabel: T('report.totalOpportunities')
				})
			]
		},
		PIPELINE_PRODUCT_COUNT: {
			title: T('reportcenter.pipelineProductCount'),
			description: T('report.widgetExamples.pipelineProductCount.description'),
			exampleConfigs: [
				generateWidget('PIPELINE_PRODUCT_COUNT', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('report.widgetExamples.pipelineProductCount.title'),
					groupBy: ['product']
				})
			]
		},
		FLOW_FINISH_HIT_RATE: {
			title: T('report.widgetExamples.flowFinishedHitRate.title'),
			description: T('reportcenter.widgetExamples.flowFinishedHitRate.description'),
			exampleConfigs: [
				generateWidget('FLOW_FINISH_HIT_RATE', displayTypeKeys.TABLE, 'percent', metadata, {
					title: T('report.widgetExamples.flowFinishedHitRate.title'),
					progressSpecial: { type: 'hitRate', data: { numerator: 1, denominator: 5, percent: 20 } },
					totalSpecial: { numerator: 1, denominator: 5, percent: 20 },
					progressLabel: T('report.widget.hitRate.hitRate'),
					groupBy: ['month']
				})
			]
		},
		CREATED_OPPORTUNITY_HIT_RATE: {
			title: T('report.widgetExamples.createdOpportunityHitRate.title'),
			description: T('reportcenter.widgetExamples.createdOpportunityHitRate.description'),
			exampleConfigs: [
				generateWidget('CREATED_OPPORTUNITY_HIT_RATE', displayTypeKeys.TABLE, 'percent', metadata, {
					title: T('report.widgetExamples.createdOpportunityHitRate.title'),
					progressSpecial: { type: 'hitRate', data: { numerator: 1, denominator: 5, percent: 20 } },
					totalSpecial: { numerator: 1, denominator: 5, percent: 20 },
					progressLabel: T('report.widget.hitRate.hitRate')
				})
			]
		},
		COMPLETED_APPOINTMENT_HIT_RATE: {
			title: T('reportcenter.hitrate.completedAppointments'),
			description: T('reportcenter.widgetExamples.completedAppointmentHitRate.description'),
			exampleConfigs: [
				generateWidget('COMPLETED_APPOINTMENT_HIT_RATE', displayTypeKeys.TABLE, 'percent', metadata, {
					title: T('report.widgetExamples.appointment.hitrate.title'),
					groupBy: ['user'],
					progressSpecial: { type: 'hitRate', data: { numerator: 1, denominator: 5, percent: 20 } },
					totalSpecial: { numerator: 1, denominator: 5, percent: 20 },
					progressLabel: T('report.widget.hitRate.hitRate')
				})
			]
		},
		COMPLETED_FIRST_APPOINTMENT_HIT_RATE: {
			title: T('reportcenter.hitrate.completedFirstAppointments'),
			description: T('reportcenter.widgetExamples.completedFirstAppointmentHitRate.description'),
			exampleConfigs: [
				generateWidget('COMPLETED_FIRST_APPOINTMENT_HIT_RATE', displayTypeKeys.TABLE, 'percent', metadata, {
					title: T('report.widgetExamples.appointmentFirst.hitrate.title'),
					groupBy: ['user'],
					progressSpecial: { type: 'hitRate', data: { numerator: 1, denominator: 5, percent: 20 } },
					totalSpecial: { numerator: 1, denominator: 5, percent: 20 },
					progressLabel: T('report.widget.hitRate.hitRate')
				})
			]
		},

		CREATED_LEADS: {
			title: T('reportcenter.createdLeadsWidgets'),
			description: T('reportcenter.widgetExamples.createdLeads.description'),
			exampleConfigs: [
				generateWidget('CREATED_LEADS', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('report.widgetExamples.createdLeadsWidgets.title'),
					groupBy: ['week'],
					progressLabel: T('report.widget.createdLeads')
				})
			]
		},
		CREATED_MQL: {
			title: T('report.createdMqlWidgets'),
			description: T('reportcenter.widgetExamples.createdMql.description'),
			exampleConfigs: [
				generateWidget('CREATED_MQL', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('report.widgetExamples.createdMqlWidgets.title'),
					groupBy: ['year'],
					progressLabel: T('report.widget.MQL')
				})
			]
		},
		CREATED_SQL: {
			title: T('reportcenter.createdSQLWidgets'),
			description: T('reportcenter.widgetExamples.createdSql.description'),
			exampleConfigs: [
				generateWidget('CREATED_SQL', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('report.widgetExamples.createdSQLWidgets.title'),
					groupBy: ['year'],
					progressLabel: T('report.widget.SQL')
				})
			]
		},
		CHURNED_CUSTOMERS: {
			title: T('reportcenter.churnedCustomersWidgets'),
			description: T('reportcenter.widgetExamples.churnedCustomers.description'),
			exampleConfigs: [
				generateWidget('CHURNED_CUSTOMERS', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('report.widgetExamples.churnedCustomers.title'),
					groupBy: ['month']
				})
			]
		},
		NEW_CUSTOMERS: {
			title: T('reportcenter.newCustomers'),
			description: T('reportcenter.widgetExamples.newCustomers.description'),
			exampleConfigs: [
				generateWidget('NEW_CUSTOMERS', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('report.widgetExamples.newCustomers.title'),
					groupBy: ['month']
				})
			]
		},
		INCREASED_CUSTOMERS: {
			title: T('report.widgetExamples.increasedCustomers.title'),
			description: T('reportcenter.widgetExamples.increasedCustomers.description'),
			exampleConfigs: [
				generateWidget('INCREASED_CUSTOMERS', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('report.widgetExamples.increasedCustomers.title'),
					groupBy: ['month']
				})
			]
		},
		UPSELL: {
			title: T('reportcenter.upsell'),
			description: T('reportcenter.widgetExamples.upsell.description'),
			exampleConfigs: [
				generateWidget('UPSELL', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('report.widgetExamples.upsell.title'),
					groupBy: ['client']
				})
			]
		},
		DECREASED_CUSTOMERS: {
			title: T('report.widgetExamples.decreasedCustomers.title'),
			description: T('reportcenter.widgetExamples.decreased.description'),
			exampleConfigs: [
				generateWidget('DECREASED_CUSTOMERS', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('report.widgetExamples.decreasedCustomers.title'),
					groupBy: ['month'],
					progressLabel: T('arrchange.type.DECREASE')
				})
			]
		},
		CONTRACTION: {
			title: T('reportcenter.contraction'),
			description: T('reportcenter.widgetExamples.contraction.description'),
			exampleConfigs: [
				generateWidget('CONTRACTION', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('report.widgetExamples.contraction.title'),
					groupBy: ['client']
				})
			]
		},
		LOST_OPPORTUNITIES: {
			title: T('reportcenter.lostOpportunities'),
			description: T('reportcenter.widgetExamples.lostOpportunities.description'),
			exampleConfigs: [
				generateWidget('LOST_OPPORTUNITIES', displayTypeKeys.COLUMN_CHART, 'currency', metadata, {
					title: T('report.widgetExamples.competitor.title'),
					groupBy: ['competitor']
				})
			]
		},
		FORM_SUBMIT_COUNT: {
			title: T('reportcenter.defaultDb.marketing.formSubmitCount'),
			description: T('reportcenter.widgetExamples.formSubmitCount.description'),
			exampleConfigs: [
				generateWidget('FORM_SUBMIT_COUNT', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('reportcenter.defaultDb.marketing.formSubmitCount'),
					groupBy: ['month'],
					progressLabel: T('default.formSubmits')
				})
			]
		},
		EVENT_REGISTRATION_COUNT: {
			title: T('reportcenter.defaultDb.marketing.eventRegistrationCount'),
			description: T('reportcenter.widgetExamples.eventRegistrationCount.description'),
			exampleConfigs: [
				generateWidget('EVENT_REGISTRATION_COUNT', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('reportcenter.defaultDb.marketing.eventRegistrationCount'),
					groupBy: ['month'],
					progressLabel: T('socialEvent.status.attending')
				})
			]
		},
		EVENT_REGISTRATION_CLIENT_COUNT: {
			title: T('reportcenter.defaultDb.marketing.eventRegistrationClientCount'),
			description: T('reportcenter.widgetExamples.eventRegistrationClientCount.description'),
			exampleConfigs: [
				generateWidget('EVENT_REGISTRATION_CLIENT_COUNT', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('reportcenter.defaultDb.marketing.eventRegistrationClientCount'),
					groupBy: ['month'],
					progressLabel: T('socialEvent.status.attending')
				})
			]
		},
		CLICKED_FLOW_MAILS: {
			title: T('reportcenter.clickedFlowMails'),
			description: T('reportcenter.widgetExamples.clickedFlowMails.description'),
			exampleConfigs: [
				generateWidget('CLICKED_FLOW_MAILS', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('reportcenter.widgetExamples.clickedFlowMails.title'),
					groupBy: ['month'],
					progressLabel: T('report.widget.clickedEmails')
				})
			]
		},
		SENT_GROUP_MAILS: {
			title: T('reportcenter.sentGroupMails'),
			description: T('reportcenter.widgetExamples.sentGroupMails.description'),
			exampleConfigs: [
				generateWidget('SENT_GROUP_MAILS', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('reportcenter.widgetExamples.sentGroupMails.title'),
					groupBy: ['month'],
					progressLabel: T('report.widget.sentEmails')
				})
			]
		},
		OPENED_GROUP_MAILS: {
			title: T('reportcenter.openedGroupMails'),
			description: T('reportcenter.widgetExamples.openedGroupMails.description'),
			exampleConfigs: [
				generateWidget('OPENED_GROUP_MAILS', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('reportcenter.widgetExamples.openedGroupMails.title'),
					groupBy: ['month'],
					progressLabel: T('report.widget.openedEmails')
				})
			]
		},
		PRODUCT_GRID: {
			title: T('reportcenter.productGrid'),
			description:
				metadata.params.SalesModel === 'rr'
					? T('reportcenter.widgetExamples.productGrid.description.rr')
					: T('reportcenter.widgetExamples.productGrid.description'),
			exampleConfigs: [
				generateWidget('PRODUCT_GRID', displayTypeKeys.PRODUCT_GRID, 'currency', metadata, {
					title: T('report.widgetExamples.productGrid.title'),
					groupBy: ['client', 'product'],
					height: 2,
					data: {
						language: {
							firstGrouping: T('default.client')
						},
						rows: [
							{
								key: '1',
								label: `${T('default.client')} 1`,
								rows: [
									{
										key: '1',
										progress: 1
									},
									{
										key: '2',
										progress: 1
									}
								]
							},
							{
								key: '2',
								label: `${T('default.client')} 2`,
								rows: [
									{
										key: '1',
										progress: 1
									}
								]
							},
							{
								key: '3',
								label: `${T('default.client')} 3`,
								rows: [
									{
										key: '1',
										progress: 1
									},
									{
										key: '3',
										progress: 1
									},
									{ key: '5', goal: 1 }
								]
							},
							{
								key: '4',
								label: `${T('default.client')} 4`,
								rows: [
									{
										key: '1',
										progress: 1
									},
									{
										key: '3',
										progress: 1
									}
								]
							},
							{
								key: '5',
								label: `${T('default.client')} 5`,
								rows: [
									{
										key: '1',
										progress: 1
									},
									{
										key: '3',
										progress: 0,
										goal: 1
									}
								]
							}
						],
						total: {
							columnArray: [
								{ key: '1', label: `${T('default.product')} 1`, progress: 1 },
								{ key: '2', label: `${T('default.product')} 2`, progress: 1 },
								{ key: '3', label: `${T('default.product')} 3`, progress: 1 },
								{ key: '4', label: `${T('default.product')} 4`, progress: 1 },
								{ key: '5', label: `${T('default.product')} 5`, progress: 1 },
								{ key: '6', label: `${T('default.product')} 6`, progress: 1 },
								{ key: '7', label: `${T('default.product')} 7`, progress: 1 }
							]
						}
					}
				})
			],
			unavailable: {
				title: T('default.onlyAvailableWith', { requirement: T('admin.billing.addon.REPORTCENTER_PRO.name') }),
				description: T('reportcenter.productGrid.shortDescription')
			}
		},
		SOCIAL_EVENT_NUMBER_OF_EVENTS: {
			title: T('report.widget.socialEventNumberEvents'),
			description: T('reportcenter.widgetExamples.socialEventNumberEvents.description'),
			exampleConfigs: [
				generateWidget('SOCIAL_EVENT_NUMBER_OF_EVENTS', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('report.widget.socialEventNumberEvents'),
					groupBy: ['month'],
					progressLabel: T('report.widget.socialEventNumberEvents')
				})
			]
		},
		FORM_SUBMIT_GROUP_MAILS: {
			title: T('reportcenter.formSubmitGroupMails'),
			description: T('reportcenter.widgetExamples.formSubmitGroupMails.description'),
			exampleConfigs: [
				generateWidget('FORM_SUBMIT_GROUP_MAILS', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('reportcenter.widgetExamples.formSubmitGroupMails.title'),
					groupBy: ['month'],
					progressLabel: T('report.widget.formSubmits')
				})
			]
		},
		CLICKED_GROUP_MAILS: {
			title: T('reportcenter.clicksGroupMails'),
			description: T('reportcenter.widgetExamples.clickedGroupMails.description'),
			exampleConfigs: [
				generateWidget('CLICKED_GROUP_MAILS', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('reportcenter.widgetExamples.clickedGroupMails.title'),
					groupBy: ['month'],
					progressLabel: T('report.widget.clickedEmails')
				})
			]
		},
		MARKETING_CONTRIBUTION: {
			title: T('reportcenter.marketingContribution'),
			description: T('reportcenter.widgetExamples.marketingContribution.description'),
			exampleConfigs: [
				generateWidget('MARKETING_CONTRIBUTION', displayTypeKeys.COLUMN_CHART, 'currency', metadata, {
					title: T('reportcenter.widgetExamples.marketingContribution.title'),
					groupBy: ['month']
				})
			]
		},
		FORM_SUBMIT_GROUP_MAILS_HIT_RATE: {
			title: T('reportcenter.hitrate.formSubmitGroupMail'),
			description: T('reportcenter.widgetExamples.hitrate.formSubmitGroupMails.description'),
			exampleConfigs: [
				generateWidget('FORM_SUBMIT_GROUP_MAILS_HIT_RATE', displayTypeKeys.TABLE, 'percent', metadata, {
					title: T('reportcenter.hitrate.formSubmitGroupMail'),
					groupBy: ['month'],
					progressSpecial: { type: 'hitRate', data: { numerator: 1, denominator: 5, percent: 20 } },
					totalSpecial: { numerator: 1, denominator: 5, percent: 20 },
					progressLabel: T('report.widget.hitRate.hitRate')
				})
			]
		},
		WEBSITE_VISITS: {
			title: T('reportcenter.websiteVisits'),
			description: T('reportcenter.widgetExamples.websiteVisits.description'),
			exampleConfigs: [
				generateWidget('WEBSITE_VISITS', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('reportcenter.widgetExamples.websiteVisits.title'),
					groupBy: ['month'],
					progressLabel: T('default.countVisits')
				})
			]
		},
		NET_REVENUE_RETENTION: {
			title: T('reportcenter.netRevenueRetention'),
			description: T('reportcenter.widgetExamples.netRevenueRetention.description'),
			exampleConfigs: [
				generateWidget('NET_REVENUE_RETENTION', displayTypeKeys.BIG_NUMBER, 'percent', metadata, {
					title: T('reportcenter.netRevenueRetention'),
					groupBy: ['client'],
					width: 4,
					height: 2
				})
			],
			unavailable: {
				title: T('default.onlyAvailableWith', { requirement: T('admin.billing.addon.REPORTCENTER_PRO.name') }),
				description: T('reportcenter.netRevenueRetention.shortDescription')
			}
		},
		OPENED_FLOW_MAILS: {
			title: T('reportcenter.openedFlowMails'),
			description: T('reportcenter.widgetExamples.openedFlowMails.description'),
			exampleConfigs: [
				generateWidget('OPENED_FLOW_MAILS', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('reportcenter.widgetExamples.openedFlowMails.title'),
					groupBy: ['month'],
					progressLabel: T('report.widget.openedEmails')
				})
			]
		},
		CURRENT_RECURRING: {
			title: T(`reportcenter.currentRecurring.${salesModelOption === 'mrr' ? 'mrr' : 'arr'}.title`),
			description: T(
				`reportcenter.widgetExamples.currentRecurring.${salesModelOption === 'mrr' ? 'mrr' : 'arr'}.description`
			),
			exampleConfigs: [
				generateWidget('CURRENT_RECURRING', displayTypeKeys.COLUMN_CHART, 'currency', metadata, {
					title: T(`reportcenter.currentRecurring.${salesModelOption === 'mrr' ? 'mrr' : 'arr'}.title`),
					groupBy: ['product'],
					progressLabel: T(`default.${salesModelOption}`),
					height: 2,
					width: 4,
					goal: false
				})
			],
			unavailable: {
				title: T('default.onlyAvailableWith', { requirement: T('admin.billing.addon.REPORTCENTER_PRO.name') }),
				description: T(
					`reportcenter.widgetExamples.currentRecurring.${
						salesModelOption === 'mrr' ? 'mrr' : 'arr'
					}.description`
				)
			}
		},
		MARKETING_ENGAGEMENTS: {
			title: T('reportcenter.marketingEngagements'),
			description: T('reportcenter.widgetExamples.marketingEngagements.description'),
			exampleConfigs: [
				generateWidget('MARKETING_ENGAGEMENTS', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('reportcenter.widgetExamples.marketingEngagements.title'),
					groupBy: ['journeyStepClient']
				})
			]
		},
		FIRST_APP_NON_CUSTOMER_FORM_SUBMIT: {
			title: T('reportcenter.bookedFirstNonCustomerFormSubmitsWidget'),
			description: T('reportcenter.widgetExamples.bookedFirstNonCustomerFormSubmits.description'),
			exampleConfigs: [
				generateWidget('FIRST_APP_NON_CUSTOMER_FORM_SUBMIT', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('report.widget.appointment.bookedFirstNonCustomerFormSubmits.title'),
					groupBy: ['week'],
					progressLabel: T('report.widget.bookedFirst')
				})
			]
		},
		CHURN_RATE_LOST_CUSTOMERS: {
			title: T('reportcenter.churnRateLostCustomersWidget'),
			description: T('reportcenter.widgetExamples.churnRateLostCustomers.description'),
			exampleConfigs: [
				generateWidget('CHURN_RATE_LOST_CUSTOMERS', displayTypeKeys.BIG_NUMBER, 'percent', metadata, {
					title: T('reportcenter.widgetExamples.churnRateLostCustomers.title'),
					groupBy: ['user']
				})
			],
			unavailable: {
				title: T('default.onlyAvailableWith', { requirement: T('admin.billing.addon.REPORTCENTER_PRO.name') }),
				description: T('reportcenter.churnRateLostCustomers.shortDescription', {
					option: salesModelOption?.toUpperCase()
				})
			}
		},
		CHURN_RATE_RECURRING_VALUE: {
			title: T('reportcenter.churnRateRecurringValueWidget', {
				option: salesModelOption?.toUpperCase()
			}),
			description: T('reportcenter.widgetExamples.churnRateRecurringValue.description', {
				option: salesModelOption?.toUpperCase()
			}),
			exampleConfigs: [
				generateWidget('CHURN_RATE_RECURRING_VALUE', displayTypeKeys.BIG_NUMBER, 'percent', metadata, {
					title: T('reportcenter.widgetExamples.churnRateRecurringValue.title', {
						option: salesModelOption?.toUpperCase()
					}),
					groupBy: ['user']
				})
			],
			unavailable: {
				title: T('default.onlyAvailableWith', { requirement: T('admin.billing.addon.REPORTCENTER_PRO.name') }),
				description: T('reportcenter.churnRateRecurringValue.shortDescription', {
					option: salesModelOption?.toUpperCase()
				})
			}
		},
		FLOW_CLIENT_COUNT: {
			title: T('reportcenter.flowClientCount'),
			description: T('reportcenter.widgetExamples.flowClientCount.description'),
			exampleConfigs: [
				generateWidget('FLOW_CLIENT_COUNT', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('reportcenter.flowClientCount'),
					groupBy: ['month'],
					progressLabel: T('default.clients')
				})
			]
		},
		FLOW_LEAD_COUNT: {
			title: T('reportcenter.flowLeadCount'),
			description: T('reportcenter.widgetExamples.flowLeadCount.description'),
			exampleConfigs: [
				generateWidget('FLOW_LEAD_COUNT', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('reportcenter.flowLeadCount'),
					groupBy: ['month'],
					progressLabel: T('default.clients')
				})
			]
		},
		FLOW_FORM_SUBMIT_COUNT: {
			title: T('reportcenter.flowFormSubmitCount'),
			description: T('reportcenter.widgetExamples.flowFormSubmitCount.description'),
			exampleConfigs: [
				generateWidget('FLOW_FORM_SUBMIT_COUNT', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('reportcenter.flowFormSubmitCount'),
					groupBy: ['month'],
					progressLabel: T('default.formSubmits')
				})
			]
		},
		SENT_FLOW_MAILS: {
			title: T('reportcenter.sentFlowMails'),
			description: T('reportcenter.widgetExamples.sentFlowMails.description'),
			exampleConfigs: [
				generateWidget('SENT_FLOW_MAILS', displayTypeKeys.COLUMN_CHART, 'number', metadata, {
					title: T('reportcenter.widgetExamples.sentFlowMails.title'),
					groupBy: ['month'],
					progressLabel: T('report.widget.sentEmails')
				})
			]
		},
		OPEN_TICKET_COUNT: {
			title: T('reportcenter.openTicketCount'),
			description: T('reportcenter.widgetExamples.openTicketCount.description'),
			exampleConfigs: [
				generateWidget('OPEN_TICKET_COUNT', displayTypeKeys.BIG_NUMBER, 'number', metadata, {
					title: T('reportcenter.widgetExamples.openTicketCount.title'),
					groupBy: ['user'],
					progressLabel: T('default.tickets'),
					filters: []
				})
			]
		},
		CLOSED_TICKET_COUNT: {
			title: T('reportcenter.closedTicketCount'),
			description: T('reportcenter.widgetExamples.closedTicketCount.description'),
			exampleConfigs: [
				generateWidget('CLOSED_TICKET_COUNT', displayTypeKeys.BIG_NUMBER, 'number', metadata, {
					title: T('reportcenter.widgetExamples.closedTicketCount.title'),
					groupBy: ['user'],
					progressLabel: T('default.tickets')
				})
			]
		},
		CREATED_TICKET_COUNT: {
			title: T('reportcenter.createdTicketCount'),
			description: T('reportcenter.widgetExamples.createdTicketCount.description'),
			exampleConfigs: [
				generateWidget('CREATED_TICKET_COUNT', displayTypeKeys.BIG_NUMBER, 'number', metadata, {
					title: T('reportcenter.widgetExamples.createdTicketCount.title'),
					groupBy: ['ticketType'],
					progressLabel: T('default.tickets')
				})
			]
		},
		TICKET_FIRST_RESPONSE_TIME: {
			title: T('reportcenter.ticketFirstResponseTime'),
			description: T('reportcenter.widgetExamples.ticketFirstResponseTime.description'),
			exampleConfigs: [
				generateWidget('TICKET_FIRST_RESPONSE_TIME', displayTypeKeys.BIG_NUMBER, 'time', metadata, {
					title: T('reportcenter.widgetExamples.ticketFirstResponseTime.title'),
					groupBy: ['user'],
					progressLabel: T('default.time')
				})
			]
		},
		TICKET_RESOLUTION_TIME: {
			title: T('reportcenter.ticketResolutionTime'),
			description: T('reportcenter.widgetExamples.ticketResolutionTime.description'),
			exampleConfigs: [
				generateWidget('TICKET_RESOLUTION_TIME', displayTypeKeys.BIG_NUMBER, 'number', metadata, {
					title: T('reportcenter.widgetExamples.ticketResolutionTime.title'),
					groupBy: ['ticketType'],
					progressLabel: T('default.time')
				})
			]
		},
		TICKET_FIRST_RESOLUTION_TIME: {
			title: T('reportcenter.ticketFirstResolutionTime'),
			description: T('reportcenter.widgetExamples.ticketFirstResolutionTime.description'),
			exampleConfigs: [
				generateWidget('TICKET_FIRST_RESOLUTION_TIME', displayTypeKeys.BIG_NUMBER, 'number', metadata, {
					title: T('reportcenter.widgetExamples.ticketFirstResolutionTime.title'),
					groupBy: ['ticketType'],
					progressLabel: T('default.time')
				})
			]
		}
	};
};

const groups: { [k: string]: { title: string; icon: IconName; sorting: number } } = {
	sales: {
		title: 'default.sales',
		icon: 'sales',
		sorting: 1
	},
	appointments: {
		title: 'default.appointments',
		icon: 'appointment',
		sorting: 2
	},
	activities: {
		title: 'default.activities',
		icon: 'activity',
		sorting: 3
	},
	pipeline: {
		title: 'reportcenter.pipeline',
		icon: 'opportunity',
		sorting: 4
	},
	journey: {
		title: 'reportcenter.journey',
		icon: 'journey',
		sorting: 5
	},
	rr: {
		title: 'reportcenter.rr',
		icon: 'subscription',
		sorting: 6
	},
	marketing: {
		title: 'reportcenter.defaultDb.marketing',
		icon: 'bullseye',
		sorting: 7
	},
	socialEvent: {
		title: 'default.socialEvent',
		icon: 'email-campaign',
		sorting: 8
	},
	form: {
		title: 'form.forms',
		icon: 'form',
		sorting: 9
	},
	emailCampaign: {
		title: 'default.mailing',
		icon: 'rocket',
		sorting: 10
	},
	flow: {
		title: 'default.flows',
		icon: 'code-fork',
		sorting: 11
	},
	websiteVisit: {
		title: 'default.siteVisits',
		icon: 'globe',
		sorting: 12
	},
	proWidget: {
		title: 'reportcenter.proWidgets',
		icon: 'pro',
		sorting: 13
	},
	customerSupport: {
		title: 'reportcenter.customerSupport',
		icon: 'customer-support',
		sorting: 14
	}
};

export const generateGroups = (meta: { [k: string]: RCWidgetMeta }) => {
	const hasNewWidgetGallery = Tools.FeatureHelper.hasSoftDeployAccess('NEW_WIDGET_GALLERY');
	const hasPro = Tools.FeatureHelper.isAvailable('REPORTCENTER_PRO');
	const metadata = Tools.AppService.getMetadata();

	const examples = widgetExamples(metadata);

	const widgetGroups = Object.keys(meta).reduce(
		(
			res: {
				[k: string]: { title: string; icon: IconName; widgets: Array<string>; family: string; sorting: number };
			},
			widget: string
		) => {
			let { family } = meta[widget];
			const { proWidget, unavailableProWidget } = meta[widget];

			const addWidget = () => {
				if (!res[family]) {
					res[family] = {
						title: T(groups[family].title || family),
						icon: groups[family].icon,
						family: family,
						widgets: [],
						sorting: groups[family].sorting
					};
				}
				res[family].widgets.push(widget);
			};

			// Only add widgets that we have examples for
			if (examples[widget]?.exampleConfigs?.length) {
				if (!hasNewWidgetGallery) {
					addWidget();
				} else {
					if (['emailCampaign', 'form', 'socialEvent', 'flow', 'websiteVisit'].includes(family)) {
						family = 'marketing';
					}
					if (!proWidget || unavailableProWidget) {
						addWidget();
					} else if (hasPro) {
						res['proWidgets'].widgets.push(widget);
						if (!unavailableProWidget) {
							addWidget();
						}
					}
				}
			}
			return res;
		},
		hasNewWidgetGallery && hasPro
			? {
					proWidgets: {
						title: T(groups.proWidget.title),
						icon: groups.proWidget.icon,
						widgets: [],
						family: 'proWidgets',
						sorting: groups.proWidget.sorting
					}
			  }
			: {}
	);
	if (!widgetGroups.proWidgets?.widgets.length) {
		delete widgetGroups.proWidgets;
	}

	return widgetGroups;
};

export default widgetExamples;
