import React from 'react';
import { Text, Block, Row, Column, Tooltip, StateFrame } from '@upsales/components';
import formatWidgetValue from '../formatWidgetValue';
import bemClass from '@upsales/components/Utils/bemClass';
import './DistributionBarWidget.scss';
import { Pulse } from 'App/components/animations';
import { GenericWidgetProps } from '../ReportcenterWidget';
import DistributionBar from '../Components/DistributionBar';
import Icon from '@upsales/components/Icon';
import { renderToString } from 'react-dom/server';
import { Datatypes } from 'Resources/ReportWidget';

type TooltipType =
	| undefined
	| string
	| {
			title: string;
			listItems: string[];
	  };
const renderTooltip = (tooltip: TooltipType, classes: bemClass, color?: string) => {
	if (!tooltip) return null;

	if (typeof tooltip === 'string') {
		return (
			<Tooltip title={tooltip}>
				<Icon name="info-circle" />
			</Tooltip>
		);
	}

	return (
		<Tooltip
			html={true}
			theme="white"
			position="bottom"
			distance={24}
			title={renderToString(
				<div className={classes.elem('tooltip').b()}>
					<Block space="mbs">
						<Text bold>{tooltip.title}</Text>
						{tooltip.listItems.map((item, index) => (
							<Column key={index} className={classes.elem('info-column').b()}>
								<Block backgroundColor={color} className={classes.elem('info-row-dot').b()} />
								<Row className={classes.elem('info-row-label').b()}>
									<Text size="sm" color="grey-11" key={index}>
										{item}
									</Text>
								</Row>
							</Column>
						))}
					</Block>
				</div>
			)}
		>
			<Icon name="info-circle" />
		</Tooltip>
	);
};

interface InfoColumnProps {
	value: string;
	label: string;
	classes: bemClass;
	loading: boolean;
	dotColor?: string;
	tooltip?: string;
}

const InfoColumn = ({ value, label, classes, loading, dotColor, tooltip }: InfoColumnProps) => (
	<Column className={classes.elem('info-column').b()}>
		{!loading && dotColor ? (
			<Block backgroundColor={dotColor} className={classes.elem('info-row-dot').b()} />
		) : null}
		<Row className={classes.elem('info-row-value').b()}>
			<Text size="md" color={'black'} bold loading={loading}>
				{value}
			</Text>
		</Row>
		<Row className={classes.elem('info-row-label').b()}>
			<Text size="sm" color={'grey-11'} loading={loading} className="text-ellipsis">
				{label} {renderTooltip(tooltip, classes, dotColor)}
			</Text>
		</Row>
	</Column>
);

export type DistributionBarWidgetProps = Pick<GenericWidgetProps, 'loading' | 'className' | 'drilldown' | 'config'> & {
	data: {
		currency?: string;
		language?: {
			noData?: string;
			noneTitleMany?: string;
		};
		total: {
			special: {
				distribution: {
					id: string;
					label: string;
					tooltip?: string;
					count?: number;
					value: number;
					color: string;
				}[];
			};
		};
		enableDrilldown?: boolean;
	};
};

const DistributionBarWidget = ({
	loading = false,
	data,
	className = '',
	drilldown,
	config
}: DistributionBarWidgetProps) => {
	const classes = new bemClass('DistributionBarWidget', className).mod({ loading });

	const currency = data.currency;
	const datatype = currency ? Datatypes.currency : Datatypes.number;
	const distribution = data.total?.special?.distribution || [];
	const totalValue = !loading ? distribution.reduce((acc, entry) => acc + entry.value, 0) : 0;

	return (
		<div className={classes.b()}>
			{!loading && totalValue <= 0 && config.showAllRisks ? (
				<Block space="mll mrl mtm mbl">
					<StateFrame state="success" icon="thumbs-up" title={data.language?.noneTitleMany || 'No data'} />
				</Block>
			) : null}
			{!loading && totalValue <= 0 && !config.showAllRisks ? (
				<Block space="mlxl mrxl mtl mbl">
					<Text italic>{data.language?.noData || 'No data'}</Text>
				</Block>
			) : null}
			{!loading && totalValue > 0 ? (
				<Block space="mlxl mrxl mtm mbs">
					<Row>
						<Column>
							<div className={classes.elem('progress-bar-wrapper').b()}>
								<Pulse disabled={!loading}>
									<DistributionBar
										distribution={distribution}
										datatype={datatype}
										currency={currency}
										drilldown={drilldown}
										enableDrilldown={data.enableDrilldown}
										config={config}
										thick
									/>
								</Pulse>
							</div>
						</Column>
					</Row>
				</Block>
			) : null}
			{!loading && totalValue > 0 ? (
				<Block space="mlxl mrxl mtl mbl" className={classes.elem('infoColumn').b()}>
					<Row>
						{distribution?.map(entry => (
							<InfoColumn
								key={entry.id}
								value={formatWidgetValue(entry.value, datatype, currency)}
								label={entry.label}
								tooltip={entry.tooltip}
								classes={classes}
								loading={loading}
								dotColor={entry.color}
							/>
						))}
					</Row>
				</Block>
			) : null}
		</div>
	);
};

export default DistributionBarWidget;
