import {
	Block,
	Flex,
	Label,
	Link,
	Modal,
	ModalContent,
	ModalControls,
	ModalHeader,
	Select,
	Text
} from '@upsales/components';
import { ThirdButton } from '@upsales/components/Buttons';
import Tooltip from '@upsales/components/Tooltip/Tooltip';
import BemClass from '@upsales/components/Utils/bemClass';
import { SelectItem } from '@upsales/components/Utils/selectHelpers';
import LabeledInput from 'App/components/Inputs/LabeledInput';
import { StaticCategoryMultiSelect } from 'App/components/Inputs/Selects/StaticCategorySelect/StaticCategorySelect';
import { StaticJourneyStepSelect } from 'App/components/Inputs/Selects/StaticJourneyStepSelect/StaticJourneyStepSelect';
import { CoreSelectItem } from 'App/components/Inputs/Selects/StaticSelect';
import { MultiSelectContext } from 'App/components/MultiselectProvider/MultiselectProvider';
import { useProductAvailable } from 'App/components/hooks/featureHelper';
import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';
import { defaultFilterGetter } from 'App/helpers/multiActionRunnerHelpers';
import Category from 'App/resources/Model/Category';
import { CustomFieldWithValue } from 'App/resources/Model/CustomField';
import FieldTranslation from 'App/resources/Model/FieldTranslation';
import JourneyStep from 'App/resources/Model/JourneyStep';
import { useTranslation } from 'Components/Helpers/translate';
import { formatDateType, prepDates } from 'Helpers/multiModals';
import RequestBuilder from 'Resources/RequestBuilder';
import { mapProperties } from 'Services/ActionProperties';
import React, { useEffect, useRef, useState } from 'react';
import { ModalProps } from '../../Modals/Modals';
import { useFeatureAvailable, useSelector, useSoftDeployAccess } from '../../hooks';
import { PropertyArray } from '../MultiActionModal';
import './MultiUpdateContact.scss';
import { ResourceMultiSelect, ResourceSelect } from 'App/components/ResourceSelect';
import { CampaignSelectItem } from 'Components/PlanPhonecallsDrawer/CampaignSelect/CampaignSelect';
import ProjectResource from 'App/resources/Project';
import ComparisonTypes from 'Resources/ComparisonTypes';
import FieldTranslations from 'Resources/FieldTranslations';

type Props = {
	entity: string;
	tagEntity: string;
	extraParams?: any;
	filters: RequestBuilder;
	removeAction: any;
	actionIndex: any;
	clientContacts: boolean;
	multiSelect: MultiSelectContext;
	onSave?: (props: PropertyArray) => Promise<void>;
} & ModalProps<PropertyArray>;

const arrayProperties = ['AddProject', 'RemoveProject', 'AddCategories', 'RemoveCategories'];

const MultiUpdateContact = ({ className, close, onSave, modalId, ...modalParams }: Props) => {
	const { entity, tagEntity, clientContacts, multiSelect, extraParams } = modalParams;
	const { t } = useTranslation();
	const modalRef = useRef<HTMLDivElement>(null);

	const classes = new BemClass('MultiUpdateContact', className);

	const hasNewFields = useSoftDeployAccess('NEW_FIELDS');
	const hasJourney = useFeatureAvailable(Tools.FeatureHelper.Feature.JOURNEY_STATUS);
	const market = useProductAvailable(Tools.FeatureHelper.Product.MA);
	const {
		customerId,
		customFields: startingFields,
		titleCategoryActive
	} = useSelector(({ App }) => ({
		customerId: App.customerId,
		customFields: App.customFields.contact,
		titleCategoryActive: App.metadata?.standardFields?.Contact?.TitleCategory?.active
	}));

	const [saving, setSaving] = useState(false);

	const [properties, setProperties] = useState<Record<string, any>>({});

	const [addCampaigns, setAddCampaigns] = useState<CampaignSelectItem[]>([]);
	const [removeCampaigns, setRemoveCampaigns] = useState<CampaignSelectItem[]>([]);

	const [addCategories, setAddCategories] = useState<CoreSelectItem<Category>[]>([]);
	const [removeCategories, setRemoveCategories] = useState<CoreSelectItem<Category>[]>([]);

	const [journeyStep, setJourneyStep] = useState<CoreSelectItem<JourneyStep> | null>(null);
	const [titleCategory, setTitleCategory] = useState<SelectItem<FieldTranslation> | null>(null);

	const [availableFields, setAvailableFields] = useState<CustomFieldWithValue[]>([]);
	const [customFields, setCustomFields] = useState<CustomFieldWithValue[]>([]);
	const [customDateTypes, setCustomDateTypes] = useState<{ [key: string]: any }>();

	const showTitleCategory = hasNewFields && titleCategoryActive;
	const showJourneySteps = true;

	const save = async () => {
		setSaving(true);

		const propArray: PropertyArray = [];

		Tools.ActionProperties.mapCustomFields(propArray, customFields, 'Contact');
		propArray.push(...mapProperties(properties, arrayProperties));

		if (properties.JourneyStep) {
			propArray.push({ name: 'ForceInvalidPropertyChange', value: true });
		}

		if (onSave) {
			return onSave(propArray)
				.then(() => {
					close();
				})
				.finally(() => {
					setSaving(false);
				});
		}

		if (!modalParams.filters || !propArray.length) {
			return close(propArray);
		}

		const filters = defaultFilterGetter(modalParams.filters ?? new RequestBuilder(), multiSelect);

		let savePromise: Promise<any>;

		if (clientContacts) {
			savePromise = Tools.MultiActions.customer(customerId).updateClientContacts(
				entity || 'Client',
				multiSelect.selectedIds.length,
				propArray,
				filters.build(),
				extraParams ?? {}
			);
		} else {
			savePromise = Tools.MultiActions.customer(customerId).updateContact(
				entity || 'Contact',
				multiSelect.selectedIds.length,
				propArray,
				filters.build(),
				extraParams ?? {}
			);
		}

		return savePromise
			.then(() => {
				multiSelect.selectNone();
				close();
			})
			.finally(() => {
				setSaving(false);
			});
	};

	const showErrorMessage = (form: any) => {
		return false;
	};

	const createCampaign = async () => {
		return Tools.$upModal.open('editCampaign', { customerId: customerId, noRedirect: true });
	};

	useEffect(() => {
		const { customDateTypes } = prepDates(entity || tagEntity);
		const newFields = structuredClone(startingFields)
			.filter(field => field.editable)
			.map(field => {
				if (customDateTypes && field.datatype === 'Date') {
					(field as any).customDateSelect = true;
				}
				return field;
			});

		setCustomDateTypes(formatDateType(customDateTypes));
		setAvailableFields(newFields as CustomFieldWithValue[]);
	}, []);

	const handleCustomFieldChange = (value: {
		id: CustomFieldWithValue['id'];
		title: CustomFieldWithValue['name'];
	}) => {
		const selectedField = availableFields.find(field => field.id === value.id);
		if (selectedField) {
			setCustomFields(prev => [...customFields, selectedField]);
		}

		setAvailableFields(prev => prev.filter(field => field.id !== value.id));
	};

	const removeCustomField = (field: CustomFieldWithValue) => {
		if (customDateTypes && field.datatype === 'Date') {
			(field as any).customDateSelect = true;
		}
		setCustomFields(customFields.filter(f => f.id !== field.id));
		setAvailableFields([...availableFields, field]);
	};

	const handleCustomFieldValueChange = (field: CustomFieldWithValue, value: any) => {
		setCustomFields(
			customFields.map(f => {
				if (f.id === field.id) {
					let newValue = value;
					if (field.datatype === 'Date' && value?.tag === 'RegularDate') {
						(field as any).customDateSelect = false;
						newValue = '';
					}

					return { ...f, value: newValue };
				}
				return f;
			})
		);
	};

	return (
		<Modal className={classes.b()} size="lg">
			<div ref={modalRef}>
				<ModalHeader
					title={`${t('default.update')} ${t(
						clientContacts ? 'default.clientContacts' : 'default.contact'
					).toLocaleLowerCase()}`}
					onClose={close}
					icon="edit"
				></ModalHeader>
				<ModalContent>
					{clientContacts ? (
						<Block className="col-span2">
							<div className="alert alert-warning">
								<i className="fa fa-info-circle"></i> {t('multi.updateContactsInfo')}
							</div>
						</Block>
					) : null}

					<div className="row">
						<div className="col-md-6">
							<LabeledInput
								title={t('default.add') + ' ' + t('default.campaigns')}
								topRight={
									<Link
										nostyle={true}
										className={classes.elem('create-campaign').b()}
										onClick={createCampaign}
									>
										{t('default.create')} {t('default.new').toLowerCase()}
									</Link>
								}
							>
								<ResourceMultiSelect
									resource={ProjectResource}
									placeholder={t('campaign.chooseCampaign')}
									value={addCampaigns}
									onChange={campaign => {
										setProperties(prev => ({
											...prev,
											AddProject: campaign.length ? campaign.map(e => e.id) : []
										}));
										setAddCampaigns(campaign ?? []);
									}}
									modifyRb={rb => {
										rb.addFilter(ProjectResource.attr.active, ComparisonTypes.Equals, true);
									}}
									anchor={modalRef.current}
								/>
							</LabeledInput>
						</div>
						<div className="col-md-6">
							<LabeledInput title={t('default.remove') + ' ' + t('default.campaigns')}>
								<ResourceMultiSelect
									resource={ProjectResource}
									placeholder={t('campaign.chooseCampaign')}
									value={removeCampaigns}
									onChange={campaign => {
										setProperties(prev => ({
											...prev,
											RemoveProject: campaign.length ? campaign.map(e => e.id) : []
										}));
										setRemoveCampaigns(campaign ?? []);
									}}
									modifyRb={rb => {
										rb.addFilter(ProjectResource.attr.active, ComparisonTypes.Equals, true);
									}}
									anchor={modalRef.current}
								/>
							</LabeledInput>
						</div>
					</div>

					<div className="row">
						<div className="col-md-6">
							<LabeledInput title={`${t('default.add')} ${t('default.categories')}`}>
								<StaticCategoryMultiSelect
									data-testid="selectAddCategories"
									categoryType="contact"
									value={addCategories}
									onChange={value => {
										setProperties(prev => ({
											...prev,
											AddCategories: value.length ? value.map(e => e.id) : []
										}));
										setAddCategories(value);
									}}
									anchor={modalRef.current}
								/>
							</LabeledInput>
						</div>
						<div className="col-md-6">
							<LabeledInput title={`${t('default.remove')} ${t('default.categories')}`}>
								<StaticCategoryMultiSelect
									data-testid="selectRemoveCategories"
									categoryType="contact"
									value={removeCategories}
									onChange={value => {
										setProperties(prev => ({
											...prev,
											RemoveCategories: value.length ? value.map(e => e.id) : []
										}));
										setRemoveCategories(value);
									}}
									anchor={modalRef.current}
								/>
							</LabeledInput>
						</div>
					</div>

					{(hasJourney && showJourneySteps) || showTitleCategory ? (
						<div className="row">
							{hasJourney && showJourneySteps ? (
								<div className="col-md-6">
									<LabeledInput title={t('default.journeyStep')}>
										<StaticJourneyStepSelect
											data-testid="selectJourneyStep"
											value={journeyStep}
											onChange={value => {
												setProperties(prev => ({
													...prev,
													JourneyStep: value?.id ?? null
												}));
												setJourneyStep(value);
											}}
											anchor={modalRef.current}
										/>
									</LabeledInput>
								</div>
							) : null}

							{showTitleCategory ? (
								<div className="col-md-6">
									<LabeledInput title={t('default.titlecategory')}>
										<ResourceSelect
											value={titleCategory}
											onChange={value => {
												setProperties(prev => ({
													...prev,
													TitleCategory: (value as any) ?? null
												}));
												setTitleCategory(value);
											}}
											anchor={modalRef.current}
											resource={FieldTranslations}
											titleField="value"
											modifyRb={(rb: RequestBuilder) => {
												rb.fields = ['tagId', 'value'];
												rb.addFilter(
													{ field: 'type' },
													ComparisonTypes.Equals,
													'titlecategory'
												);
											}}
										/>
									</LabeledInput>
								</div>
							) : null}
						</div>
					) : null}

					{market ? (
						<>
							<legend>
								<span>{t('default.market')}</span>
							</legend>
							<div className="row">
								<div className="col-md-12">
									<Label>
										{t('default.newMarketEvent')}{' '}
										<Tooltip title={t('default.newMarketEventTooltip')} position="right">
											<i className="fa fa-info-circle"></i>
										</Tooltip>
									</Label>
									<table>
										<tbody>
											<tr>
												<td width="20%">
													<input
														type="number"
														name="field_NewMarketScore"
														id="field_NewMarketScore"
														className="form-control"
														placeholder={t('default.score')}
														disabled={saving}
														value={properties.NewMarketScore as string}
														onChange={e => {
															setProperties(prev => ({
																...prev,
																NewMarketScore: e.target.value
															}));
														}}
													></input>
												</td>
												<td>
													<input
														type="text"
														name="field_NewMarketDescription"
														id="field_NewMarketDescription"
														className="form-control"
														placeholder={t('column.description')}
														disabled={saving}
														value={properties.NewMarketDescription as string}
														onChange={e => {
															setProperties(prev => ({
																...prev,
																NewMarketDescription: e.target.value
															}));
														}}
													></input>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</>
					) : null}

					{customFields.length || availableFields.length ? (
						<legend>
							<span>{t('default.otherInfo')}</span>
						</legend>
					) : null}

					{customFields.length ? (
						<>
							<div className="row">
								{customFields.map((field, index) => (
									<div className="col-md-6" key={field.id}>
										<div className="form-group">
											<Flex justifyContent="space-between">
												<Text className={classes.elem('custom-title').b()} bold>
													{field.name}
													<Text
														size="lg"
														color="red"
														bold={true}
														style={{ display: field.obligatoryField ? 'inline' : 'none' }}
													>
														&bull;
													</Text>
												</Text>
												<ThirdButton
													className={classes.elem('remove-custom').b()}
													icon={'trash'}
													onClick={() => removeCustomField(field)}
													size="sm"
													color="blue"
												/>
											</Flex>
											{customDateTypes &&
											field.datatype === 'Date' &&
											(field as any).customDateSelect ? (
												<ReactTemplates.INPUTS.upSelect
													className={classes.elem('custom-field-input', 'form-control').b()}
													getId={customDateTypes.id}
													options={{ data: customDateTypes.data }}
													formatResult={customDateTypes.formatResult}
													formatSelection={customDateTypes.formatSelection}
													matcher={customDateTypes.matcher}
													onChange={value =>
														handleCustomFieldValueChange(field, value.target.added)
													}
												/>
											) : (
												<ReactTemplates.INPUTS.customFieldInput
													field={field}
													name={field.name}
													className={classes.elem('custom-field-input').b()}
													multiple
													onChange={(value: string) =>
														handleCustomFieldValueChange(field, value)
													}
													usenewdate
													useNewTime
													useNumberInput
													anchor={modalRef.current}
												/>
											)}
										</div>
									</div>
								))}
							</div>
						</>
					) : null}

					{availableFields.length ? (
						<div className="row">
							<div className="col-md-6">
								<Select
									data-testid="Input_field"
									anchor={modalRef.current}
									options={availableFields.map(field => ({
										id: field.id,
										title: field.name
									}))}
									value={null}
									onChange={handleCustomFieldChange}
									disabled={!availableFields.length}
									className={classes.elem('custom-field-select').b()}
									placeholder={t('default.add') + ' ' + t('default.field').toLowerCase()}
								/>
							</div>
						</div>
					) : null}
				</ModalContent>
				<ModalControls>
					{showErrorMessage('myForm') ? (
						<p className="text-danger pull-left">
							<b>{t('default.youHaveFormErrors')}</b>
						</p>
					) : null}
					<button type="submit" className="btn up-btn btn-green no-shadow" disabled={saving} onClick={save}>
						{t(saving ? 'default.saving' : 'default.save')} {t('admin.action').toLocaleLowerCase()}{' '}
						{saving ? <span className={`fa fa-refresh ${saving ? 'fa-spin' : ''}`}></span> : null}
					</button>
					<button className="btn up-btn btn-grey btn-link" onClick={() => close()}>
						{t('default.abort')}
					</button>
				</ModalControls>
			</div>
		</Modal>
	);
};

export const openMultiUpdateContact = asyncModalAdapter({
	featureFlag: 'UPDATE_CONTACT_MULTI_REACT',
	openModalName: 'MultiUpdateContact',
	upModalName: 'UpdateContactMulti',
	rejectOnEvent: true
});

export default MultiUpdateContact;
