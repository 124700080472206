import BemClass from '@upsales/components/Utils/bemClass';
import ClientAttributes from 'App/babel/attributes/Client';
import ClientResource from 'App/resources/Client';
import React, { useMemo, useState, useRef, useEffect } from 'react';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import T from 'Components/Helpers/translate';
import logError from 'App/babel/helpers/logError';
import openModal, { shouldOpenModal } from 'App/services/Modal';
import { AccountManagers, ClientColumnTitle, ClientColumnSubtitle } from './columnParts';
import {
	Card,
	CardContent,
	Text,
	Link,
	Block,
	StateFrame,
	SelectAsync,
	Icon,
	Title,
	Label,
	Row,
	Column,
	Flex,
	Tooltip,
	ClickableItem
} from '@upsales/components';
import { PrimaryButton } from '@upsales/components/Buttons';
import { SubaccountDrawerView } from './SubaccountDrawer';
import { findAndRemoveItem } from 'Store/helpers/array';
import { makeCancelable } from 'App/babel/helpers/promise';
import { useModalClose } from 'App/components/Modals/Modals';

import type Client from 'App/resources/Model/Client';
import type { DrawerClient } from './SubaccountDrawer';
import { useSelector } from 'App/components/hooks';
import ProspectingClient from 'App/resources/Model/ProspectingClient';
import { addManyAccounts } from 'Store/reducers/AccountReducer';
import { connect } from 'react-redux';
import BranchOffices from './BranchOffices';
import PendingPurchaseBanner, { PendingPurchaseType } from './PendingPurchaseBanner';
import SoliditetClient from 'App/resources/Model/SoliditetClient';

type Props = {
	className?: string;
	client: DrawerClient;
	closeDrawer: () => void;
	modalId: number;
	setView: (view: SubaccountDrawerView) => void;
	subaccounts: Pick<Client, 'id' | 'name'>[];
	addBranches: (branches: SoliditetClient[] | ProspectingClient[], operationalAccountId: number) => Promise<boolean>;
	pendingPurchase?: PendingPurchaseType | null;
};

type SelectableClient = Client & { title: string };

const RESULT_LIMIT = 20;

const getFetcher =
	(clientIds: number[]) =>
	(searchString?: string): Promise<SelectableClient[]> => {
		const filter = new RequestBuilder();

		filter.addFilter(ClientAttributes.active, comparisonTypes.Equals, 1);
		filter.addFilter(ClientAttributes.id, comparisonTypes.NotEquals, clientIds);
		filter.addFilter(ClientAttributes.operationalAccount.attr.id, comparisonTypes.Equals, null);
		const orBuilder = filter.orBuilder();
		orBuilder.next();
		orBuilder.addFilter(ClientAttributes.numberOfSubaccounts, comparisonTypes.Equals, 0);
		orBuilder.next();
		orBuilder.addFilter(ClientAttributes.numberOfSubaccounts, comparisonTypes.Equals, null);
		orBuilder.done();

		if (searchString) {
			filter.addFilter(ClientAttributes.name, comparisonTypes.Search, searchString);
		}

		filter.addSort(ClientAttributes.name, true);

		filter.limit = RESULT_LIMIT;

		return ClientResource.find(filter.build()).then(({ data }) =>
			data.map((client: Client) => ({ ...client, title: client.name }))
		);
	};

const getSaveButtonText = (
	selectedClients: Client[],
	selectedBranches: ProspectingClient[] | SoliditetClient[],
	lang: { save: string; saveOne: string }
) => {
	const keySet = new Set<number | string>(selectedClients.map(({ id }) => id));
	selectedBranches?.forEach(branch => {
		const matchInUpsales = (branch as ProspectingClient).matchInUpsales;
		if (matchInUpsales) {
			keySet.add(matchInUpsales.id);
			return;
		}
		const keyId = branch.hasOwnProperty('prospectingId')
			? (branch as ProspectingClient).prospectingId
			: (branch as SoliditetClient).dunsNo;
		keySet.add(keyId);
	});
	switch (keySet.size) {
		case 0:
			return lang.save;
		case 1:
			return lang.saveOne;
		default:
			return T('SubaccountDrawer.Add.saveMany', { count: keySet.size });
	}
};

const mapDispatchToProps = { addBranches: addManyAccounts };

const Add = ({
	className,
	modalId,
	closeDrawer,
	client,
	subaccounts,
	setView,
	addBranches,
	pendingPurchase
}: Props) => {
	const { unknowns, unbought, config } = useSelector(state => state.Account);
	const isProspecting = config?.id === 'prospecting';

	const classes = new BemClass('SubaccountDrawer__Add', className);
	const lang = useMemo(
		() => ({
			stateFrameTitle: T('SubaccountDrawer.Add.stateFrameTitle'),
			stateFrameSubtitle: T('SubaccountDrawer.Add.stateFrameSubtitle'),
			chooseCompany: T('SubaccountDrawer.Add.chooseCompany'),
			select: T('default.select'),
			clickToDeselect: T('activity.outcome.clickToDeselect'),
			save: T('SubaccountDrawer.Add.save'),
			info: T('SubaccountDrawer.Add.info'),
			saveOne: T('SubaccountDrawer.Add.saveOne'),
			createAccount: T('SubaccountDrawer.Add.createCompany'),
			typeToSearch: T('default.typeToSearch'),
			backButton: T('SubaccountDrawer.Add.backButton'),
			noResults: T('default.noResults'),
			branchOffices: T('default.branchOffices'),
			inputLanguage: {
				typeToSearch: T('default.typeToSearch')
			}
		}),
		[]
	);
	const [selectedClients, setSelectedClients] = useState<SelectableClient[]>([]);
	const [selectedBranches, setSelectedBranches] = useState<ProspectingClient[] | SoliditetClient[]>([]);
	const [saving, setSaving] = useState<boolean>(false);
	const inputRef = useRef<HTMLInputElement>(null);

	const cancelRef = useRef<(() => void) | null>(null);
	const isSavingBranches = useRef(false);

	const addSubaccounts = async () => {
		if (
			selectedBranches.length &&
			selectedBranches.some(branch =>
				unbought.includes(
					isProspecting ? (branch as ProspectingClient).prospectingId : (branch as SoliditetClient).dunsNo
				)
			)
		) {
			isSavingBranches.current = true;
			const addedBranches = await addBranches(selectedBranches, client.id);
			// it means that the did not opt for adding the companies from prospecting
			if (!addedBranches) {
				return;
			}
		}

		const clients = [...selectedClients];
		const clientsSet = new Set(selectedClients.map(({ id }) => id));
		if (selectedBranches.length) {
			for (const branch of selectedBranches) {
				if (
					branch.hasOwnProperty('matchInUpsales') &&
					branch.matchInUpsales &&
					!clientsSet.has(branch.matchInUpsales.id)
				) {
					clientsSet.add(branch.matchInUpsales.id);
					clients.push({ ...branch.matchInUpsales, title: branch.name } as SelectableClient);
				}
			}
		}
		if (!clients.length) {
			setView(SubaccountDrawerView.List);
		}
		// We do not want to spam notifications if multiple accounts are added
		const promises = Array.from(clientsSet).map(id =>
			ClientResource.save(
				{ id, operationalAccount: { id: client.id } },
				{
					skipEvent: true, // This becomes very spammy if you save multiple subaccounts, listen for account.subaccountsAdded instead.
					skipNotification: true // This becomes very spammy if you save multiple subaccounts, not really needed as we have the "banner notification".
				}
			)
		);
		const { promise, cancel } = makeCancelable(Promise.all(promises));
		cancelRef.current = cancel;
		promise
			.then(() => {
				setView(SubaccountDrawerView.List);
				Tools.$rootScope.$broadcast('account.subaccountsAdded', { clients: clients });
			})
			.catch(error => {
				logError(error, 'Failed to add accounts as subaccounts.');
				setSaving(false);
			});

		setSaving(true);
	};

	useEffect(() => {
		// Designers wanted it to open after a delay
		const timeoutID = setTimeout(() => {
			inputRef.current?.click();
		}, 250);
		return () => {
			clearTimeout(timeoutID);
			cancelRef.current?.();
		};
	}, []);

	useModalClose(
		modalId,
		event => {
			event.preventDefault();

			if (selectedClients.length > 0 && !saving) {
				const body =
					selectedClients.length === 1
						? T('SubaccountDrawer.Add.unsavedSubaccountsOne')
						: T('SubaccountDrawer.Add.unsavedSubaccountsMany');

				openModal('UnsavedChangesAlert', {
					body,
					confirmButtonText: T('default.goBack'),
					onClose: (confirmed?: boolean) => {
						if (confirmed || confirmed === undefined) {
							return;
						}
						closeDrawer();
					}
				});
			} else {
				closeDrawer();
			}
		},
		[selectedClients, saving]
	);

	const deselectClient = (clientId: number) => {
		const result = findAndRemoveItem(selectedClients, { id: clientId });
		setSelectedClients(result);
	};

	const selectClient = (selectedClient: SelectableClient) => {
		const alreadySelected = selectedClients.some(client => client.id === selectedClient.id);
		if (alreadySelected) {
			deselectClient(selectedClient.id);
			return;
		}
		setSelectedClients([selectedClient, ...selectedClients]);
	};

	const createAccount = () => {
		const onSave = (client: Client) => {
			if (client?.id && client.numberOfSubaccounts === 0) {
				selectClient({ ...client, title: client.name });
			}
		};

		if (shouldOpenModal('CreateAccount')) {
			openModal('CreateAccount', { onSave });
		} else {
			Tools.$upModal.open('createAccount', { onSave });
		}
	};

	const allClientIdsToSkip = [client.id, ...subaccounts.map(client => client.id)];
	const saveButtonText = getSaveButtonText(selectedClients, selectedBranches, lang);

	return (
		<Flex className={classes.b()} direction="column" justifyContent="space-between">
			<Block className={classes.elem('Content').b()}>
				<Block className={classes.elem('BackButton').b()} onClick={() => setView(SubaccountDrawerView.List)}>
					<Title>
						<Flex alignItems="center">
							<Icon name="chevron-left" space="mrm" />
							{lang.backButton}
							<Title bold={true} space="mls">
								{client.name}
							</Title>
						</Flex>
					</Title>
				</Block>
				<Block space="mtl">
					<StateFrame
						icon="info-circle"
						state="info"
						subtitle={lang.stateFrameSubtitle}
						title={lang.stateFrameTitle}
					/>
				</Block>
				<PendingPurchaseBanner pendingPurchase={pendingPurchase} space="mtl" />
				<Block space="mtxl">
					<Label>{lang.chooseCompany}</Label>
					<SelectAsync
						multi
						hideSelected={false}
						keepOpenOnSelect
						anchor={'.SubaccountDrawer__Add__Content'}
						disabled={saving}
						fetchOnMount={false}
						fetcher={getFetcher(allClientIdsToSkip)}
						value={selectedClients?.map(c => ({ ...c, title: c.name })) ?? []}
						inputRef={inputRef}
						onChange={selectClient}
						placeholder={lang.select}
						keepSearchOnBlur
						renderItem={client => (
							<Flex
								direction="column"
								gap="u1"
								space="pts pbs"
								className={classes.elem('selectCustomRow').b()}
							>
								<ClientColumnTitle client={client} />
								<Flex justifyContent="space-between">
									<ClientColumnSubtitle client={client} />
									<AccountManagers users={client.users} />
								</Flex>
							</Flex>
						)}
						language={lang.inputLanguage}
						renderCustomExtraRow={() => (
							<Flex justifyContent="center" space="ptl prl pbl pll" onClick={() => createAccount()}>
								<Text color="grey-10">
									<Link>
										<Icon name="plus" space="mrm" />
										{lang.createAccount}
									</Link>
								</Text>
							</Flex>
						)}
						renderCustomNoResults={() => (
							<Block>
								{(inputRef.current?.value?.length ?? 0) > 1 ? (
									<Block border="bs" borderColor="grey-4" space="ptm prm pbm plm">
										<Text color="grey-10">{lang.noResults}</Text>
									</Block>
								) : (
									<Block border="bs" borderColor="grey-4" space="ptm prm pbm plm">
										<Text bold size="sm">
											{lang.typeToSearch}
										</Text>
									</Block>
								)}
								<Flex justifyContent="center" onClick={() => createAccount()} space="ptl prl pbl pll">
									<Text color="grey-10">
										<Link>
											<Icon name="plus" space="mrm" />
											{lang.createAccount}
										</Link>
									</Text>
								</Flex>
							</Block>
						)}
					/>
				</Block>
				<Block>
					{selectedClients.map(client => (
						<Card key={client.id} space="mtm" borderRadius borderColor="grey-4" border="bs">
							<CardContent>
								<Row>
									<Column>
										<Block>
											<ClientColumnTitle client={client} />
										</Block>
										<Block>
											<ClientColumnSubtitle client={client} />
										</Block>
									</Column>
									<Column fixedWidth={300}>
										<Flex alignItems="center" space="mrl mll">
											<AccountManagers users={client.users} />
										</Flex>
									</Column>
									<Column fixedWidth={50}>
										<Flex alignItems="center" justifyContent="end">
											<Tooltip distance={25} title={lang.clickToDeselect}>
												<ClickableItem
													borderRadius
													icon="times"
													onClick={() => deselectClient(client.id)}
													size="sm"
												/>
											</Tooltip>
										</Flex>
									</Column>
								</Row>
							</CardContent>
						</Card>
					))}
				</Block>
				{unknowns?.length ? (
					<BranchOffices
						clientName={client.name}
						isProspecting={isProspecting}
						hasPendingPurchase={!!pendingPurchase}
						unknowns={unknowns}
						selectedClients={selectedClients}
						onChange={branches => {
							const newClients: SelectableClient[] = [];
							const toRemove: Client[] = [];
							if (isProspecting) {
								// if the branch has a match in upsales then add it to the upper list
								for (const branch of branches) {
									if (
										branch.hasOwnProperty('matchInUpsales') &&
										branch.matchInUpsales &&
										!selectedClients.some(({ id }) => id === branch.matchInUpsales?.id)
									) {
										newClients.push({
											...branch.matchInUpsales,
											title: branch.name
										} as SelectableClient);
									}
								}
								// if we deselected a branch that it is a match in upsales then remove it from the upper list
								for (const selectedClient of selectedClients) {
									if (
										unknowns?.find(
											(unknown: ProspectingClient) =>
												unknown.matchInUpsales?.id === selectedClient.id
										) &&
										!(branches as ProspectingClient[]).find(
											({ matchInUpsales }) => matchInUpsales?.id === selectedClient.id
										)
									) {
										toRemove.push(selectedClient);
									}
								}
								if (newClients.length || toRemove.length) {
									const updatedList = selectedClients.filter(
										({ id }) => !toRemove.some(cr => cr.id === id)
									);
									setSelectedClients([...updatedList, ...newClients]);
								}
							}
							setSelectedBranches(branches);
						}}
					/>
				) : null}
			</Block>
			<Block
				backgroundColor="grey-1"
				border="ts"
				borderColor="grey-4"
				className={classes.elem('Footer').b()}
				space="ptl pbl"
			>
				<Flex alignItems="center" justifyContent="center">
					<PrimaryButton
						data-testid="SaveButton"
						disabled={(selectedClients.length === 0 && selectedBranches.length === 0) || saving}
						icon="check"
						onClick={addSubaccounts}
						size="lg"
					>
						{saveButtonText}
					</PrimaryButton>
				</Flex>
			</Block>
		</Flex>
	);
};
const Component = connect(null, mapDispatchToProps)(Add);
export default Component;
