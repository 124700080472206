import React from 'react';
import PropTypes from 'prop-types';
import { Block, Icon, Text, Row, Column, Headline, Link, Button, Tooltip } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { getJourneyStep } from 'Components/Helpers/journeyStep';
import ClientRating from 'Components/ClientRating';
import './EditPhonecallClient.scss';
import InlineInput from '../Inputs/InlineInput';
import T from 'Components/Helpers/translate';
import Client from 'App/resources/Model/Client';
import { useAppDispatch, useSoftDeployAccess } from 'App/components/hooks';
import { saveAndCloseModals } from 'Store/actions/ModalActions';

const getClientHref = (id: number) =>
	Tools.$state.href('account.dashboard', {
		id,
		customerId: Tools.AppService.getCustomerId()
	});

interface Props {
	client: Client;
	onEditClient: () => void;
	saveClient: React.ComponentProps<typeof InlineInput>['onConfirmInput'];
	clientLoading: boolean;
	closeDate: Date | string;
	closeDrawer: () => void;
	id: number;
	missingAccess?: boolean;
	disabled?: boolean;
}
const EditPhonecallClient = ({
	client,
	onEditClient,
	saveClient,
	clientLoading,
	closeDate,
	closeDrawer,
	missingAccess = false,
	id,
	disabled = false
}: Props) => {
	const classes = new BemClass('EditPhonecallClient');
	const journey = getJourneyStep(client.journeyStep);
	const cols: { l: JSX.Element[]; r: JSX.Element[] } = { l: [], r: [] };
	const phoneRelated = {
		type: 'Activity',
		id: id,
		closeDate: closeDate
	};
	const dispatch = useAppDispatch();
	const hasMinimize = useSoftDeployAccess('MINIMIZE_TASK_DRAWERS');

	if (missingAccess) {
		cols.l.push(
			<Column className={classes.elem('client-name').b()}>
				<Headline size="xs">{client.name}</Headline>
			</Column>
		);
	} else {
		cols.l.push(
			<Column className={classes.elem('client-name').b()}>
				<Link
					href={getClientHref(client.id)}
					onClick={() => {
						if (hasMinimize) {
							closeDrawer();
							dispatch(saveAndCloseModals());
						}
					}}
				>
					<Headline size="xs">{client.name}</Headline>
				</Link>
			</Column>
		);

		cols.l.push(
			<Column className={classes.elem('indicators').b()}>
				<Block>
					<ReactTemplates.TOOLS.leadStatus
						size="xs"
						activity={client.hasActivity}
						activityOld={client.hadActivity}
						appointment={client.hasAppointment}
						appointmentOld={client.hadAppointment}
						opportunity={client.hasOpportunity}
						opportunityOld={client.hadOpportunity}
						order={client.hasOrder}
						orderOld={client.hadOrder}
					/>
				</Block>
				<Block>
					<ReactTemplates.TOOLS.eventTypes size="xs" account={client} />
				</Block>
				<Block>
					<Tooltip
						title={client.about || T('prospecting.descriptionMissing')}
						theme={client.about ? 'green' : 'grey-10'}
						position="bottom"
						distance={24}
					>
						<Icon
							name="info-circle"
							className={
								client.about
									? classes.elem('info-icon').mod('active').b()
									: classes.elem('info-icon').mod('inactive').b()
							}
						></Icon>
					</Tooltip>
				</Block>
				<Block>
					<ClientRating client={client} />
				</Block>
			</Column>
		);
		cols.l.push(<Column>{!clientLoading ? <Text size="sm">{T(journey?.name || '')}</Text> : null}</Column>);
		if (client.parent?.name) {
			cols.r.push(
				<Column className={classes.elem('edit-col').b()} size={5.5}>
					{!clientLoading ? (
						<InlineInput
							value={client.parent.name}
							client={client}
							onConfirmInput={saveClient}
							type="parent"
							disabled={disabled}
						/>
					) : null}
				</Column>
			);
		}
		cols.r.push(
			<Column className={classes.elem('edit-col').b()} size={5.5}>
				{!clientLoading ? (
					<InlineInput
						value={client.webpage ? client.webpage : ''}
						client={client}
						onConfirmInput={saveClient}
						type="webpage"
						disabled={disabled}
					/>
				) : null}
			</Column>
		);

		cols.r.push(
			<Column className={classes.elem('edit-col').b()} size={5.5}>
				{!clientLoading ? (
					<InlineInput
						value={client.phone ? client.phone : ''}
						client={client}
						onConfirmInput={saveClient}
						type="phone"
						phoneRelated={phoneRelated}
						disabled={disabled}
					/>
				) : null}
			</Column>
		);

		cols.r.push(
			<Column className={classes.elem('edit-col').b()} size={5.5}>
				{!clientLoading ? (
					<InlineInput
						value={client.linkedin ? client.linkedin : ''}
						client={client}
						onConfirmInput={saveClient}
						type="linkedin"
						disabled={disabled}
					/>
				) : null}
			</Column>
		);
	}

	return (
		<Block
			space="prxl pbxl plxl"
			className={classes.b()}
			border="ls"
			borderColor="grey-5"
			backgroundColor={missingAccess ? 'grey-1' : undefined}
		>
			<div className={classes.elem('tools').b()}>
				<Tooltip
					title={T(client.userEditable ? 'default.editCompany' : 'error.checkClientRights')}
					distance={15}
				>
					<Button type="link" onClick={() => onEditClient()} color="grey" disabled={!client.userEditable}>
						<Icon name="edit" />
					</Button>
				</Tooltip>
			</div>
			<Row>
				{cols.l[0] || <Column />}
				{cols.r[0]}
			</Row>
			<Row>
				{cols.l[1] || <Column />}
				{cols.r[1]}
			</Row>
			<Row>
				{cols.l[2] || <Column />}
				{cols.r[2]}
			</Row>
			<Row>
				{cols.l[3] || <Column />}
				{cols.r[3]}
			</Row>
		</Block>
	);
};

EditPhonecallClient.propTypes = {
	client: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		journeyStep: PropTypes.string,
		webpage: PropTypes.string,
		phone: PropTypes.string,
		linkedin: PropTypes.string
	}),
	onEditClient: PropTypes.func.isRequired,
	clientLoading: PropTypes.bool,
	saveClient: PropTypes.func,
	closedDate: PropTypes.instanceOf(Date),
	id: PropTypes.number
};

export default EditPhonecallClient;
