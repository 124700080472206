import Resource from 'Resources/Resource';

class FormSubmitResource extends Resource {
	constructor() {
		super('formSubmits');
	}
}

const resource = new FormSubmitResource();

export default resource;
