import React from 'react';

import { Modal } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import CreateDocument, { Props } from './CreateDocument';

type CreateDocumentModalProps = Props & { className?: string };

export default (props: CreateDocumentModalProps) => {
	const classes = new BemClass('CreateDocumentModal', props.className);
	return (
		<Modal className={classes.b()}>
			<CreateDocument {...props} />
		</Modal>
	);
};
