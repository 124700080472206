import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Tooltip, Flex, ClickableItem, Text } from '@upsales/components';
import { DefaultButton } from '@upsales/components/Buttons';
import BuyButton from '../../../DataSource/BuyButton';
import ExistInUpsales from 'Components/Misc/ExistInUpsales';
import { getSoftDeployAccessFromState } from 'App/components/hooks/featureHelper';

export default class Add extends React.Component {
	constructor(props) {
		super(props);

		const t = Tools.$translate;
		const accountSelf = Tools.AppService.getAccountSelf();
		this.hasGroupBonanza = getSoftDeployAccessFromState(accountSelf, 'GROUP_BONANZA');
		this.hasSubaccounts = getSoftDeployAccessFromState(accountSelf, 'SUB_ACCOUNTS');
		this.hasSubaccountsV2 = getSoftDeployAccessFromState(accountSelf, 'SUB_ACCOUNTS_V2') && this.hasSubaccounts;

		this.lang = {
			addToExisting: t('account.addToExisting'),
			addAsNew: this.hasGroupBonanza ? t('default.addAccount') : t('account.addAsNew'),
			select: t('default.select'),
			selected: t('default.selected'),
			adding: t('account.adding'),
			added: t('account.added'),
			selectCompany: t('default.chooseAnAccount'),
			applyInfoTo: t('account.addInfoTo'),
			confirmPurchase: t('default.confirmPurchase'),
			costSingular: t('integration.cost.singular'),
			confirm: t('default.confirm'),
			addAsNewTooltip: t('account.addAsNewTooltip'),
			addToExistingTooltip: t('account.addToExistingTooltip')
		};

		this.beginMerge = this.beginMerge.bind(this);
		this.addAsNew = this.addAsNew.bind(this);
		this.addExternal = this.addExternal.bind(this);
		this.onClose = this.onClose.bind(this);
		this.onOpen = this.onOpen.bind(this);
		this.renderBuyButton = this.renderBuyButton.bind(this);

		this.state = {
			confirmOpen: false
		};
	}

	addAsNew(purchaseType) {
		this.props.actions.beginAddAccount(this.props.dataSourceId, this.props.account, purchaseType);
	}

	addExternal() {
		this.props.actions.beginAddExternal(this.props.dataSourceId, this.props.account);
	}

	beginMerge() {
		this.props.actions.beginMerge(this.props.dataSourceId, this.props.account);
	}

	onClose() {
		this.setState({ confirmOpen: false });
	}

	onOpen() {
		this.setState({ confirmOpen: true });
	}

	renderBuyButton(props, dropDown) {
		const color = dropDown && dropDown.isExpanded ? 'white' : 'green';
		return this.hasGroupBonanza || this.hasSubaccountsV2 ? (
			<DefaultButton {...props} color={this.props.selected ? 'bright-green' : color} className="buy-button">
				<Flex alignItems="center" justifyContent="center">
					{this.props.selected ? <Icon name="check" /> : <Icon name="plus" />}
					<span className="expand-on-button-hover">
						<Text space="mls">
							{this.props.multiselect
								? this.props.selected
									? this.lang.selected
									: this.lang.select
								: this.lang.addAsNew}
						</Text>
					</span>
				</Flex>
			</DefaultButton>
		) : (
			<Tooltip title={this.lang.addAsNewTooltip} position="bottom" distance={30}>
				<DefaultButton
					{...props}
					color={this.props.selected ? 'bright-green' : color}
					style={{ marginLeft: 5 }}
				>
					<span style={{ display: 'inline-block', marginLeft: 5 }}>
						{this.props.selected ? <Icon name="check" /> : <Icon name="plus" />}
					</span>
					<span className="expand-on-row-hover">
						{this.props.multiselect
							? this.props.selected
								? this.lang.selected
								: this.lang.select
							: this.lang.addAsNew}
					</span>
				</DefaultButton>
			</Tooltip>
		);
	}

	render() {
		const { account, addingAccount, idField, pricing, hasRequriedFields, multiselect } = this.props;

		const existing = account.existing;
		const external = account.isExternal || account.matchInUpsales?.isExternal;
		const isThisRow = addingAccount && addingAccount.externalId === _.get(account, idField);
		const addConfing = addingAccount || { adding: false, added: false };
		const { adding, added } = addConfing;

		const client = {
			id: this.props.account.upsalesId || this.props.account.id,
			active: !account.existingInactive
		};

		const hasMerge = !!this.props.actions.beginMerge;

		const buyButton = (
			<BuyButton
				key="buy-button"
				pricing={hasRequriedFields ? null : pricing}
				added={added}
				adding={isThisRow && adding}
				onConfim={external ? this.addExternal : this.addAsNew}
				onOpen={this.onOpen}
				onClose={this.onClose}
				renderButton={this.renderBuyButton}
			/>
		);

		const mergeButton =
			this.hasGroupBonanza || this.hasSubaccountsV2 ? (
				<Tooltip title={this.lang.addToExistingTooltip} position="bottom">
					<ClickableItem
						key="merge-button"
						disabled={adding}
						borderRadius
						icon="merge"
						size="sm"
						onClick={this.beginMerge}
					></ClickableItem>
				</Tooltip>
			) : (
				<Tooltip title={this.lang.addToExistingTooltip} position="bottom" distance={30}>
					<Button
						key="merge-button"
						className={'add-into-existing-btn show-on-row-hover'}
						onClick={this.beginMerge}
						disabled={adding}
						shadow="none"
						type="lined"
					>
						<Icon name="code-fork" />
						{' ' + this.lang.addToExisting}
					</Button>
				</Tooltip>
			);

		return (
			<td className={`DataSourceColumn add-company-column ${this.state.confirmOpen ? 'confirm-open' : ''}`}>
				<div>
					{!external && existing ? <ExistInUpsales client={client} /> : null}
					{added && isThisRow ? (
						<span className="added-text" key="added-text">
							<i className="fa fa-check" />
							{this.lang.added}
						</span>
					) : null}
					{!added && adding && isThisRow ? (
						<span className="grey-text" key="adding-text">
							<i className="fa fa-spinner fa-spin" />
							{this.lang.adding}
						</span>
					) : null}
					{this.hasGroupBonanza || this.hasSubaccountsV2 ? (
						<Flex alignItems="center" justifyContent="flex-end">
							<div>
								{((!adding || !isThisRow) && !existing) || (existing && external) || multiselect
									? buyButton
									: null}
							</div>
							{!adding && !existing && !external && hasMerge ? mergeButton : null}
						</Flex>
					) : (
						<div className="float-right">
							{!adding && !existing && !external && hasMerge ? mergeButton : null}
							{((!adding || !isThisRow) && !existing) || (existing && external) || multiselect
								? buyButton
								: null}
						</div>
					)}
				</div>
			</td>
		);
	}
}

Add.propTypes = {
	actions: PropTypes.object,
	account: PropTypes.object.isRequired,
	dataSourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	addingAccount: PropTypes.object,
	idField: PropTypes.string,
	pricing: PropTypes.object,
	hasRequriedFields: PropTypes.bool,
	multiselect: PropTypes.bool,
	selected: PropTypes.bool
};
