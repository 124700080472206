import t from 'Components/Helpers/translate';
import Automations from 'Resources/Automations';
import getCriteriaFilterConfigs from 'Components/Criteria/getCriteriaFilterConfigs';
import { removeItem, replaceItem } from '../helpers/array';
import MergeFilters from 'Services/MergeFilters';
import openModal from 'App/services/Modal';
import { openAssignModalLead } from 'Components/AssignModal/AssignModalLead';

const SET_VALUE = '[JourneyEditor] Set Value';
const SET_AUTOMATION = '[JourneyEditor] Set automation';
const SET_FILTERS = '[JourneyEditor] Set filters';
const ADD_ACTION = '[JourneyEditor] Add action';
const EDIT_ACTION = '[JourneyEditor] Edit action';
const REMOVE_ACTION = '[JourneyEditor] Remove action';
const SET_SAVING = '[JourneyEditor] Set saving';
const RESET = '[JourneyEditor] Reset';
const SET_ACTIONS = '[JourneyEditor] Set actions';
const SET_AUTO_FOCUS = '[JourneyEditor] Set autoFocus';

const initialState = {
	automation: {
		name: '',
		version: 2,
		active: true,
		filter: [],
		actions: [],
		journeyType: null,
		job: 'automation-contacts'
	},
	filters: {
		and: {},
		or: {}
	},
	actions: [],
	saving: false,
	autoFocus: false
};

const nameMap = {
	mql: 'default.marketingQualifiedLead',
	lost_customer: 'default.lostCustomer'
};

const baseAction = { multiple: false, featureAvailable: true };
export const availableActions = [
	{
		action: 'CreateActivity',
		title: 'automationAction.CreateActivity',
		available: () =>
			!(
				Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
				Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
			),
		...baseAction
	},
	{ action: 'UpdateContact', title: 'automationAction.UpdateContact', ...baseAction },
	{
		action: 'UpdateContactClient',
		title: 'automationAction.ContactUpdateClient',
		modalName: 'UpdateClientAction',
		...baseAction
	},
	{ action: 'SendEmail', title: 'automationAction.SendEmail', ...baseAction, multiple: true },
	{
		action: 'SendWebRequest',
		title: 'automationAction.SendWebhook',
		...baseAction,
		multiple: true,
		modalName: 'SendWebhookAction'
	},
	{ action: 'AnonymizeContact', title: 'automationAction.AnonymizeContact', ...baseAction },
	{ action: 'AssignToUser', title: 'automationAction.AssignToUser', ...baseAction },
	{ action: 'SetCountactJourneyStatus', title: 'automationAction.SetCountactJourneyStatus', ...baseAction }
];

export let filterConfigs;

const getConfig = filterName => {
	return filterConfigs[filterName];
};

/*********** Actions **********/
export const reset = () => ({ type: RESET });
export const setValue = (field, value) => ({ type: SET_VALUE, field, value });
export const setAutoFocus = autoFocus => ({ type: SET_AUTO_FOCUS, autoFocus });
export const setSaving = saving => ({ type: SET_SAVING, saving });
const setFilters = (filterType, filters) => ({ type: SET_FILTERS, filters: filters ? filters : {}, filterType });
const setActions = actions => ({ type: SET_ACTIONS, actions });
const setAutomation = automation => dispatch => {
	const and = automation.filter.filter(c => c.groupType === 'and')[0];
	if (and) {
		dispatch(setFilters('and', { ...and.config }));
	}
	const or = automation.filter.filter(c => c.groupType === 'or')[0];
	if (or) {
		dispatch(setFilters('or', { ...or.config }));
	}

	dispatch(setActions(automation.actions || []));
	dispatch({ type: SET_AUTOMATION, automation });
	setTimeout(() => dispatch(setAutoFocus(true), 0));
};

const getCreateStateSteps = type => {
	switch (type) {
		case 'mql':
			return { target: 'lead', set: 'mql' };
		case 'lost_customer':
			return { target: 'customer', set: 'lost_customer' };
	}
	throw new Error('Invalid type');
};

const getInitialExtraFilters = type => {
	if (type === 'lost_customer') {
		const value = Tools.FilterHelper.filter('ClientOrderFilters', 'contact').value;
		value.hasOrder = false;
		value.Date = {
			value: { start: null, end: null, preset: 'prev24Month' }
		};
		return {
			ClientOrderFilters: {
				filterName: 'ClientOrderFilters',
				locked: true,
				value
			}
		};
	}
};

const loadCreateState = (dispatch, type) => {
	try {
		const { target, set } = getCreateStateSteps(type);
		dispatch(
			setAutomation({
				...initialState.automation,
				name: Tools.$translate(nameMap[type]),
				journeyType: type,
				filter: [
					{
						config: {
							JourneyStep: {
								filterName: 'JourneyStep',
								comparisonType: 'Equals',
								value: [target],
								locked: true
							},
							...getInitialExtraFilters(type)
						},
						groupType: 'and'
					}
				],
				actions: [
					{
						action: 'SetCountactJourneyStatus',
						locked: true,
						properties: [{ name: 'JourneyStep', value: set }]
					}
				]
			})
		);
	} catch (e) {
		Tools.$state.go('administration.journeyStatuses', {}, { location: 'replace' });
	}
};

export const load = (type, id) => dispatch => {
	dispatch(reset());
	filterConfigs = getCriteriaFilterConfigs();
	if (id) {
		Automations.get(id)
			.then(res => {
				dispatch(setAutomation(res.data));
			})
			.catch(e => {
				console.log(e);
				loadCreateState(dispatch, type);
			});
	} else {
		loadCreateState(dispatch, type);
	}
};

export const addFilter = (type, isExclude, filterName) => (dispatch, getState) => {
	const { filters } = getState().JourneyEdit;
	const filter = Tools.FilterHelper.filter(filterName, 'contact', { getConfig });
	// Do it like in segments
	if (
		[
			'OpportunityFilters',
			'ContactOpportunityFilters',
			'ClientOrderFilters',
			'ContactOrderFilters',
			'ClientActivity',
			'ClientAppointment',
			'ClientActivityFilters',
			'ContactOnlyActivityFilters',
			'MailCampaignFilters',
			'VisitorFilters',
			'FormSubmitFilters',
			'ClientAgreementFilters',
			'ContactAgreementFilters',
			'Appointment',
			'ContactToDo',
			'ContactPhoneCall',
			'ClientToDo',
			'ClientPhoneCall'
		].indexOf(filterName) > -1
	) {
		filter.inactive = false;
	}
	const newFilters = { ...filters[type] };
	newFilters[filter.filterName] = filter;
	dispatch(setFilters(type, newFilters));
};

export const removeFilter = (type, isExclude, filterName) => (dispatch, getState) => {
	const { filters } = getState().JourneyEdit;
	const newFilters = { ...filters[type] };
	delete newFilters[filterName];
	dispatch(setFilters(type, newFilters));
};

export const editFilter = (type, isExclude, filterName, filter) => (dispatch, getState) => {
	const { filters } = getState().JourneyEdit;
	const newFilters = { ...filters[type] };
	newFilters[filterName] = filter;
	dispatch(setFilters(type, newFilters));
};

export const editAction = (index, action) => ({
	type: EDIT_ACTION,
	index,
	action
});

export const removeAction = index => ({
	type: REMOVE_ACTION,
	index
});

export const openAction = (action, actionIndex) => dispatch => {
	const realAction = availableActions.find(a => a.action === action.action);
	const modal = realAction.modalName || realAction.action + 'Action';
	let propertiesPromise;
	// Open modal
	if (Tools.FeatureHelper.hasSoftDeployAccess('SEND_WEBHOOK_REACT') && modal === 'SendWebhookAction') {
		propertiesPromise = new Promise((res, rej) => {
			openModal('SendWebhook', {
				tagEntity: 'client',
				properties: action.properties,
				onClose: data => (data ? res(data) : rej())
			});
		});
	} else if (modal === 'AssignToUserAction') {
		propertiesPromise = openAssignModalLead({ tagEntity: 'client', properties: action.properties });
	} else {
		propertiesPromise = Tools.$upModal.open(modal, {
			properties: action.properties || null,
			action,
			tagEntity: 'client',
			removeAction: () => dispatch(removeAction(actionIndex)),
			actionIndex,
			hideDate: true,
			isAutomation: true,
			isTrigger: true
		});
	}
	return propertiesPromise.then(properties => {
		dispatch(
			editAction(actionIndex, {
				...action,
				properties
			})
		);
	});
};

export const addAction = () => (dispatch, getState) => {
	const { actions } = getState().JourneyEdit;
	const data = availableActions.reduce(
		(out, { action, multiple, modalName, title, featureAvailable, type, property, available }) => {
			// Add to list if not yet used or if allowed multiple and also if available
			if ((!actions.find(a => a.action === action) || multiple) && !(available && !available())) {
				var obj = { title: t(title), action, modalName, type, property };
				if (!featureAvailable) {
					obj.$$disabled = true;
					obj.title = '<span class="text-grey">' + t(title) + '</span>';
					obj.missingFeature =
						'<span class="text-grey text-xs">' + t('default.versionMissingFeature') + '</span>';
				}

				return out.concat(obj);
			}
			return out;
		},
		[]
	);

	if (data.length) {
		if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_LIST')) {
			return openModal('ListModal', {
				title: 'admin.addAction',
				hideHeader: true,
				columns: [
					{ title: 'default.name', value: 'title' },
					{ title: '', value: 'missingFeature' }
				],
				data,
				onClose: action => dispatch(openAction(action, actions.length))
			});
		} else {
			return Tools.$upModal
				.open('list', {
					title: 'admin.addAction',
					hideHeader: true,
					columns: [
						{ title: 'default.name', value: 'title' },
						{ title: '', value: 'missingFeature' }
					],
					data
				})
				.then(action => dispatch(openAction(action, actions.length)));
		}
	} else {
		return Tools.$upModal
			.open('warningAlert', {
				title: t('noResult.action'),
				body: t('noResult.action.description'),
				icon: 'fa-warning'
			})
			.then(() => Promise.reject());
	}
};

const getAutomationFilter = (filters, groupType) => {
	return {
		config: filters[groupType],
		body: null,
		groupType
	};
};

export const save = () => (dispatch, getState) => {
	const { automation, filters, actions } = getState().JourneyEdit;
	dispatch(setSaving(true));
	const mergedFilters = MergeFilters(
		[getAutomationFilter(filters, 'and'), getAutomationFilter(filters, 'or')],
		getConfig
	);

	Automations.save({ ...automation, filter: mergedFilters, actions })
		.then(() => {
			dispatch(setSaving(false));
			Tools.$state.go('administration.journeyStatuses');
		})
		.catch(err => {
			console.log(err);
			dispatch(setSaving(false));
		});
};

const ACTION_HANDLERS = {
	[SET_VALUE]: (state, { field, value }) => ({ ...state, automation: { ...state.automation, [field]: value } }),
	[SET_AUTOMATION]: (state, { automation }) => ({ ...state, automation }),
	[SET_FILTERS]: (state, { filters, filterType }) => ({
		...state,
		filters: { ...state.filters, [filterType]: { ...filters } }
	}),
	[ADD_ACTION]: (state, { action }) => ({ ...state, actions: state.actions.concat(action) }),
	[EDIT_ACTION]: (state, { action, index }) => ({ ...state, actions: replaceItem(state.actions, index, action) }),
	[REMOVE_ACTION]: (state, { index }) => ({ ...state, actions: removeItem(state.actions, index) }),
	[SET_SAVING]: (state, { saving }) => ({ ...state, saving }),
	[RESET]: () => ({ ...initialState }),
	[SET_ACTIONS]: (state, { actions }) => ({ ...state, actions }),
	[SET_AUTO_FOCUS]: (state, { autoFocus }) => ({ ...state, autoFocus })
};

export default (state = { ...initialState }, action) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
