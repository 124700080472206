import Attribute, { DisplayType, Type, Attr } from './Attribute';
import ClientAttributes from './Client';

const CompanyPortfolioAttributes = {
	id: ClientAttributes.id,
	name: ClientAttributes.name,
	active: ClientAttributes.active,
	sniCode: {
		...ClientAttributes.sniCode,
		title: 'industry'
	},
	orgNo: ClientAttributes.orgNo,
	users: ClientAttributes.users,
	customerSince: Attribute({
		title: 'default.customerSince',
		field: 'customerSince',
		type: Type.String,
		sortable: 'customerSince'
	}),
	isCustomer: Attribute({
		field: 'isCustomer',
		type: Type.Boolean
	}),
	cmLast12Months: Attribute({
		field: 'cmLast12Months',
		fieldPath: 'cmLast12Months',
		title: 'accountGrowth.column.cmLast12months',
		displayType: DisplayType.Currency,
		type: Type.Number,
		displayKey: ['cmLast12Months', 'currency'],
		displayKeyMap: { cmLast12Months: 'value' },
		selectableColumn: true,
		sortable: 'cmLast12Months',
		elevioId: 1333
	}),
	cmTrend12Months: Attribute({
		field: 'cmTrend12Months',
		fieldPath: 'cmTrend12Months',
		title: 'accountGrowth.column.cmTrend12months',
		displayType: DisplayType.CurrencyChange,
		type: Type.Number,
		displayKey: ['cmTrend12Months', 'currency'],
		displayKeyMap: { cmTrend12Months: 'value' },
		selectableColumn: true,
		sortable: 'cmTrend12Months'
	}),
	salesLast12Months: Attribute({
		field: 'salesLast12Months',
		fieldPath: 'salesLast12Months',
		title: 'accountGrowth.column.salesLast12months',
		displayType: DisplayType.Currency,
		type: Type.Number,
		displayKey: ['salesLast12Months', 'currency'],
		displayKeyMap: { salesLast12Months: 'value' },
		selectableColumn: true,
		sortable: 'salesLast12Months',
		elevioId: 1277
	}),
	salesTrend12Months: Attribute({
		field: 'salesTrend12Months',
		fieldPath: 'salesTrend12Months',
		title: 'accountGrowth.column.salesTrend12Months',
		displayType: DisplayType.CurrencyChange,
		type: Type.Number,
		displayKey: ['salesTrend12Months', 'currency'],
		displayKeyMap: { salesTrend12Months: 'value' },
		selectableColumn: true,
		sortable: 'salesTrend12Months',
		elevioId: 1278
	}),
	arrLast12Months: Attribute({
		field: 'arrLast12Months',
		fieldPath: 'arrLast12Months',
		title: 'accountGrowth.column.arrLast12Months',
		displayType: DisplayType.CurrencyChange,
		type: Type.Number,
		displayKey: ['arrLast12Months', 'currency'],
		displayKeyMap: { arrLast12Months: 'value' },
		selectableColumn: true,
		sortable: 'arrLast12Months',
		elevioId: 1286
	}),
	mrrLast12Months: Attribute({
		field: 'mrrLast12Months',
		fieldPath: 'mrrLast12Months',
		title: 'accountGrowth.column.mrrLast12Months',
		displayType: DisplayType.CurrencyChange,
		type: Type.Number,
		displayKey: ['mrrLast12Months', 'currency'],
		displayKeyMap: { mrrLast12Months: 'value' },
		selectableColumn: true,
		sortable: 'mrrLast12Months',
		elevioId: 1286
	}),
	arr: Attribute({
		field: 'arr',
		fieldPath: 'arr',
		title: 'accountGrowth.column.arr',
		displayType: DisplayType.Currency,
		type: Type.Number,
		displayKey: ['arr', 'currency'],
		displayKeyMap: { arr: 'value' },
		selectableColumn: true,
		sortable: 'arr'
	}),
	mrr: Attribute({
		field: 'mrr',
		fieldPath: 'mrr',
		title: 'accountGrowth.column.mrr',
		displayType: DisplayType.Currency,
		type: Type.Number,
		displayKey: ['mrr', 'currency'],
		displayKeyMap: { mrr: 'value' },
		selectableColumn: true,
		sortable: 'mrr'
	}),
	prospectingCreditRating: Attribute({
		title: 'default.creditRating',
		field: 'prospectingCreditRating',
		type: Type.String,
		sortable: 'prospectingCreditRating'
	}),
	prospectingNumericCreditRating: Attribute({
		title: 'customerPortfolio.risks.bankruptcyRisk',
		field: 'prospectingNumericCreditRating',
		type: Type.Number
	}),
	risks: Attribute({
		title: 'customerPortfolio.header.tab.risk.title',
		field: 'risks',
		type: Type.String
	})
} as { [key: string]: Attr };

export default CompanyPortfolioAttributes;
