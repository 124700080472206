import { ClientIdName } from 'App/resources/Model/Client';
import Event from 'App/resources/Model/Event';
import logError from '../helpers/logError';
import Resource from './Resource';

export interface Item {
	type: 'news' | 'updates' | 'risk';
	name: string;
	id: number;
	eventId: number;
	clientId: number;
	prospectingId: string;
	title: string;
	img: string | null;
	link: string | null;
	pinned: boolean;
	medium: number | null;
	mediumName: string | null;
	date: string;
	value: { added: string; removed: string; status: string };
}

class Signals extends Resource {
	constructor() {
		super('prospecting/signals');
	}

	getFeed(
		view: string,
		type: string,
		date: string,
		limit: number,
		offset: number,
		clientIds?: number[]
	): Promise<{ data: Item[]; metadata: { total: number } }> {
		return this._getRequest(`feed`, {
			methodName: 'getFeed',
			params: {
				view,
				type,
				date,
				limit,
				offset,
				clientIds
			}
		}).then(response => response.data);
	}

	getFeedForProspectingId(
		prospectingId: string,
		type: string,
		date: string,
		limit: number,
		offset: number
	): Promise<{ data: Item[]; metadata: { total: number } }> {
		return this._getRequest(`feed/${prospectingId}`, {
			methodName: 'getFeedForProspectingId',
			params: {
				type,
				date,
				limit,
				offset
			}
		}).then(response => response.data);
	}

	async pin(clientId: number, entityId: number, date: string): Promise<Event | undefined> {
		const { id, name, email } = Tools.AppService.getSelf();
		const e: Event = {
			client: {
				id: clientId
			} as ClientIdName,
			date: new Date(date),
			entityId: entityId,
			entityType: 'News',
			subType: 'Pinned',
			score: 0,
			value: '',
			users: [
				{
					id,
					name,
					email
				}
			]
		};

		return Tools.Event.save(e)
			.then(res => {
				Tools.$rootScope.$broadcast('news.pin', res.data);
				return res.data;
			})
			.catch((error: any) => {
				logError(error, 'Could not pin article');
				return undefined;
			});
	}

	async unpin(eventId: number): Promise<void> {
		Tools.$rootScope.$broadcast('news.unpin', eventId);
		await Tools.Event.delete({ id: eventId });
	}
}

export default new Signals();
