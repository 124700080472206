import React, { useState, useRef } from 'react';
import { OutsideClick, Text, Card, RadioList, RadioItem, Toggle, Icon } from '@upsales/components';
import { SlideFade } from 'App/components/animations';
import T from 'Components/Helpers/translate';
import './SalesboardSumSettings.scss';
import BemClass from '@upsales/components/Utils/bemClass';
import { SalesboardState } from 'App/pages/Salesboard/Salesboard';
import { hasCMWithRR, hasRRWithCM } from 'App/helpers/salesModelHelpers';

interface Props {
	valueType: string;
	weightedValue?: string;
	setDisplayValueType: (value: SalesboardState['salesboardDisplayValueType']) => void;
	toggleWeightedValue?: () => void;
	salesModel: boolean;
	salesModelOption: string;
	salesModelOption3: string;
	hideOneOffValue?: boolean;
	hideWeightedValue?: boolean;
	alignRight?: boolean;
	closeOnSelect?: boolean;
}
const SalesboardSumSettings = ({
	valueType,
	weightedValue,
	setDisplayValueType,
	toggleWeightedValue,
	salesModel,
	salesModelOption,
	salesModelOption3,
	hideOneOffValue,
	hideWeightedValue,
	closeOnSelect,
	alignRight
}: Props) => {
	const [menuOpen, setMenuOpen] = useState(false);
	const mainRef = useRef<HTMLDivElement>(null);
	const classes = new BemClass('SalesboardSumSettings');

	const lang: { [key: string]: string } = {
		buisnessModel: T('default.salesboard.model'),
		annualValue: T('default.annualValue'),
		monthlyValue: T('default.monthlyValue'),
		oneOffValue: T('default.oneOffValue'),
		weightedValue: T('default.WeightedValue'),
		contributionMargin: T('default.contributionMargin'),
		value: T('default.totalOrderValue')
	};

	const toggleMenuOpen = () => {
		if (menuOpen) {
			setMenuOpen(false);
		} else {
			setMenuOpen(true);
		}
	};

	const getRadioItems = () => {
		const items = ['value'];

		// Add the sales model option
		if (salesModelOption === 'arr') {
			items.splice(0, 0, 'annualValue');
		} else if (salesModelOption === 'mrr') {
			items.splice(0, 0, 'monthlyValue');
		} else if (salesModelOption === 'cm') {
			items.splice(0, 0, 'contributionMargin');
		}

		if (hasRRWithCM() || (hasCMWithRR() && salesModelOption !== 'cm')) {
			items.push('contributionMargin');
		}

		if (hasCMWithRR()) {
			if (salesModelOption3 === 'cmCombinedWithARR') {
				items.push('annualValue');
			} else if (salesModelOption3 === 'cmCombinedWithMRR') {
				items.push('monthlyValue');
			}
		}

		// Add one-off value if not explicitly hidden
		if (!hideOneOffValue) {
			items.splice(1, 0, 'oneOffValue');
		}

		return items.map(itemName => (
			<RadioItem
				key={itemName}
				className={classes.elem('RadioItem').b()}
				size="md"
				label={itemName}
				checked={valueType === itemName}
				value={itemName}
			>
				<Text className={classes.elem('RadioText').b()} size="md">
					{lang[itemName]}
				</Text>
			</RadioItem>
		));
	};

	return (
		<div className={classes.elem('header').b()} ref={mainRef}>
			<OutsideClick targetRef={() => mainRef.current} outsideClick={() => setMenuOpen(false)}>
				<div className={classes.elem('header-wrapper').b()} onClick={() => toggleMenuOpen()}>
					<Text size="md">
						{lang.buisnessModel}
						<Text size="sm" color="grey-11">
							{lang[valueType]}
						</Text>
					</Text>
					<Icon
						className={classes.elem('header-icon').b()}
						name={menuOpen ? 'angle-up' : 'angle-down'}
					></Icon>
				</div>
				{menuOpen ? (
					<SlideFade direction="top" visible={menuOpen}>
						<Card
							className={
								alignRight
									? classes.elem('dropdown-wrapper').mod('align-right').b()
									: classes.elem('dropdown-wrapper').b()
							}
						>
							<RadioList
								size="sm"
								onChange={value => {
									setDisplayValueType(value as SalesboardState['salesboardDisplayValueType']);
									if (closeOnSelect) {
										setMenuOpen(false);
									}
								}}
							>
								{getRadioItems()}
							</RadioList>
							{!hideWeightedValue && (
								<div onClick={toggleWeightedValue} className={classes.elem('WeightedDiv').b()}>
									<Toggle color="green" checked={weightedValue === 'on'} size="md" />
									<Text className={classes.elem('RadioText').b()}>{lang.weightedValue}</Text>
								</div>
							)}
						</Card>
					</SlideFade>
				) : null}
			</OutsideClick>
		</div>
	);
};

export default SalesboardSumSettings;
