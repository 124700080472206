import React, { useEffect, useRef, useState } from 'react';
import T from 'Components/Helpers/translate';
import Contact from 'App/resources/Model/Contact';
import ComparisonTypes from 'Resources/ComparisonTypes';
import type { ModalProps } from '../Modals/Modals';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import {
	Block,
	Button,
	Icon,
	Input,
	Modal,
	ModalContent,
	ModalControls,
	ModalHeader,
	Tooltip
} from '@upsales/components';
import ClientSelect from '../ClientSelect';
import { PrimaryButton } from '@upsales/components/Buttons';
import BemClass from '@upsales/components/Utils/bemClass';
import './MoveContactModal.scss';
import type { ClientIdName } from 'App/resources/Model/Client';
import ClientAttributes from 'App/babel/attributes/Client';
import ContactRelationResource from 'App/resources/ContactRelation';
import { type CancelablePromise, makeCancelable } from 'Helpers/promise';

type Props = ModalProps<Contact> & {
	contact: Contact;
};

const MoveContactModal = ({ contact, className, close }: Props) => {
	const classes = new BemClass('MoveContactModal', className);
	const customerId = Tools.AppService.getCustomerId();

	const [moving, setMoving] = useState(false);
	const [selectedAccount, setSelectedAccount] = useState<ClientIdName | null>(null);
	const [relationDescription, setRelationDescription] = useState(T('contact.relationDefaultValue'));

	const movePromise = useRef<CancelablePromise<{ data: Contact }> | null>(null);
	const relationPromise = useRef<CancelablePromise<unknown> | null>(null);

	const move = async function () {
		setMoving(true);

		// TODO: New resource does not have move() method - using old for now
		const ContactResource = getAngularModule('Contact') as any;
		const contactToMove = { id: contact.id, client: selectedAccount };

		movePromise.current = makeCancelable(ContactResource.customer(customerId).move(contactToMove));

		if (relationDescription) {
			var rel = {
				contactId: contact.id,
				relatedToClientId: contact.client.id,
				description: relationDescription
			};
			relationPromise.current = makeCancelable(ContactRelationResource.save(rel));
		}

		// BUG: Modal will not close if either promise fails. This behaviour is preserved from angular modal B)
		return Promise.all([movePromise.current?.promise, relationPromise.current?.promise])
			.then(function (res) {
				close((res[0] as { data: Contact })?.data);
			})
			.finally(function () {
				setMoving(false);
			});
	};

	useEffect(() => {
		return () => {
			movePromise.current?.cancel();
			relationPromise.current?.cancel();
		};
	}, []);

	return (
		<Modal className={classes.b()}>
			<ModalHeader title={T('default.moveContact')} onClose={close} icon="truck" />

			<ModalContent>
				<Block>
					{T('default.whereToMove')} <b>{contact?.name}</b>
					{'? '}
					<Tooltip title={T('default.moveContactInfo')}>
						<Icon name="info-circle"></Icon>
					</Tooltip>
				</Block>
				<ClientSelect
					placeholder={T('default.chooseAnAccount')}
					onChange={event => {
						setSelectedAccount(event.target.added!);
					}}
					modifyRb={rb => {
						rb.addFilter(ClientAttributes.id, ComparisonTypes.NotEquals, contact.client.id);
						rb.addFilter(ClientAttributes.isExternal, ComparisonTypes.Equals, 0);
					}}
					doNotUseLatestAccounts
					onlyActive
				/>
				<br />
				<Block space="ptl">
					<p>
						<b>{T('contact.moveRelationSubject')}</b>
						<br />
						{T('contact.moveRelationDescription3')} {contact.client.name}
					</p>
					<Input
						type="text"
						value={relationDescription}
						placeholder={T('contact.relationExample')}
						onChange={e => setRelationDescription(e.target.value)}
					/>
				</Block>
			</ModalContent>

			<ModalControls>
				<PrimaryButton disabled={!selectedAccount || moving} onClick={move} loading={moving}>
					{T(moving ? 'default.moving' : 'default.move')}
				</PrimaryButton>
				<Button color="grey" type="link" onClick={() => close()}>
					{T('default.abort')}
				</Button>
			</ModalControls>
		</Modal>
	);
};

export default MoveContactModal;
