import React, { useState, useEffect, ComponentProps, useMemo } from 'react';
import { DrawerHeader, Button, Loader } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import Error from 'App/resources/Model/Error';
import MailType, {
	MailRecipient,
	MailActivity,
	MailAppointment,
	MailOpportunity,
	MailResourceSave
} from 'App/resources/Model/Mail';
import logError from 'App/babel/helpers/logError';
import T from 'Components/Helpers/translate';
import SwooshScreen from '../Components/SwooshScreen';
import MailBody from '../Components/MailBody';
import Mail from '../Components/Mail';
import NewMail from '../Components/NewMail';
import RelatedTo from '../Components/RelatedTo';
import { SlideFade } from 'App/components/animations';
import { init, reply as mailContextHelpersReply, send as mailContextHelpersSend } from '../MailContextHelpers';
import { RootState } from 'Store/index';
import { MailProvider, useMailContext } from '../MailContext';
import RelationDropdownMenu from 'App/components/dropdowns/RelationDropdownMenu';
import MailResource from 'Resources/Mail';
import { TYPES } from 'Components/Helpers/SourceHelper';
import { useSelector } from 'react-redux';
import MailSendButton from '../Components/MailSendButton/MailSendButton';
import MailReplyButton from '../Components/MailReplyButton';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import {
	mailIntegrationIsValid,
	formatRecipients,
	getTooltipIfRecipientsExceedMaxSize,
	openNewMailDrawerWithCopiedData
} from 'App/helpers/mailHelpers';
import openModal from 'App/services/Modal';
import { useFeatureAvailable } from 'App/components/hooks';
import { Feature } from 'Store/actions/FeatureHelperActions';
import { ModalProps } from 'App/components/Modals/Modals';

import './SentMail.scss';

type Props = ModalProps & {
	mail: { id: number };
};

const SentMail = ({ className, close, mail }: Props) => {
	const [showReply, setShowReply] = useState(false);
	const [relations, setRelations] = useState<{
		opportunity?: MailOpportunity | null;
		appointment?: MailAppointment | null;
		activity?: MailActivity | null;
	}>();
	const [showSentMail, setShowSentMail] = useState(false);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<MailType | undefined>(undefined);
	const [replyMailHeight, setReplyMailHeight] = useState(750);
	const [error, setError] = useState<Error>();
	const { state, dispatch } = useMailContext();
	const store = useSelector(({ App }: RootState) => ({
		customerId: App.customerId,
		self: App.self,
		mailIntegration: App.mailIntegration
	}));
	const [mailIntegrationValid, setMailIntegrationValid] = useState<boolean>(false);
	const checkValidMailIntegration = async () => {
		return !!(store.mailIntegration && (await mailIntegrationIsValid(store.mailIntegration)));
	};

	const hasEmailFeature = useFeatureAvailable(Feature.EMAIL);

	const resizeReplyMailHeight = () => {
		setReplyMailHeight(window.innerHeight);
	};
	useEffect(() => {
		resizeReplyMailHeight();
		window.addEventListener('resize', resizeReplyMailHeight);
		return () => {
			window.removeEventListener('resize', resizeReplyMailHeight);
		};
	}, []);

	useEffect(() => {
		let mailGetPromise: CancelablePromise<Awaited<ReturnType<typeof MailResource.get>>>;
		if (mail.id) {
			setLoading(true);
			mailGetPromise = makeCancelable(MailResource.get(mail.id));

			mailGetPromise.promise
				.then(res => {
					const mailData = formatRecipients(res.data);
					setData(mailData);
					setError(undefined);
				})
				.catch(error => {
					setError(error);
					const statusCode404 = error.response?.status === 404;
					Tools.NotificationService.addNotification({
						style: Tools.NotificationService.style.ERROR,
						icon: 'times',
						title: 'default.error',
						body: statusCode404 ? 'mailDrawer.couldNotFindEmail' : 'mailDrawer.couldNotOpenEmail'
					});
					close();
				})
				.finally(() => setLoading(false));
		}

		const validateMailPromise: CancelablePromise<boolean> = makeCancelable(checkValidMailIntegration());
		validateMailPromise.promise
			.then(setMailIntegrationValid)
			.catch(err => logError(err, 'Failed to validate mail integration'));

		return () => {
			mailGetPromise?.cancel();
			validateMailPromise?.cancel();
		};
	}, [mail]);

	useEffect(() => {
		const unsubListeners: ReturnType<typeof Tools.$rootScope.$on>[] = [];
		if (relations?.opportunity) {
			unsubListeners.push(
				Tools.$rootScope.$on('opportunity.deleted', (e, opportunity) => {
					if (opportunity.id === relations.opportunity?.id) {
						setRelations({ ...relations, opportunity: null });
					}
				})
			);
		}
		if (relations?.activity) {
			unsubListeners.push(
				Tools.$rootScope.$on('activity.deleted', (e, activity) => {
					if (activity.id === relations.activity?.id) {
						setRelations({ ...relations, activity: null });
					}
				})
			);
		}
		if (relations?.appointment) {
			unsubListeners.push(
				Tools.$rootScope.$on('appointment.deleted', (e, appointment) => {
					if (appointment.id === relations.appointment?.id) {
						setRelations({ ...relations, appointment: null });
					}
				})
			);
		}
		return () => {
			unsubListeners.forEach(unsub => unsub());
		};
	}, [relations]);

	useEffect(() => {
		if (data) {
			const { appointment, opportunity, activity } = data;
			setRelations({ appointment, opportunity, activity });
			if (data.subject && store.mailIntegration) {
				init(dispatch, {
					client: data.client,
					contact: data.contact
				});
			}
		}
	}, [data]);

	const from = useMemo(
		() =>
			data?.from || data?.fromName
				? {
						name: data?.fromName,
						email: data?.from,
						type: data.type === 'in' ? 'contact' : 'user',
						id: data.type === 'in' ? data.contact?.id : data.users?.[0]?.id
				  }
				: undefined,
		[data]
	);

	const closeModal = () => {
		if (!state.hasChanged || !showReply) {
			return close();
		} else {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('Alert', {
					title: 'default.unsavedChanges',
					body: 'confirm.changesWillBeLost',
					headerIcon: 'exclamation-triangle',
					confirmButtonText: 'default.discardChanges',
					onClose: confirmed => {
						if (confirmed) {
							close();
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			Tools.$upModal
				.open('infoConfirm', {
					title: 'default.unsavedChanges',
					body: 'confirm.changesWillBeLost',
					icon: 'fa-exclamation-triangle',
					no: 'default.abort',
					resolveTrue: 'default.discardChanges',
					resolveTrueBtnClass: 'btn-bright-blue btn-lined'
				})
				.then(close);
		}
	};

	const onRelationChange: ComponentProps<typeof RelationDropdownMenu>['onRelationChange'] = async value => {
		const { opportunityId, appointmentId, activityId } = value;
		const saveData: MailResourceSave = {
			id: data!.id,
			opportunity: opportunityId ? { id: opportunityId } : null,
			appointment: appointmentId ? { id: appointmentId } : null,
			activity: activityId ? { id: activityId } : null
		};

		try {
			const {
				data: { opportunity, activity, appointment }
			} = await MailResource.save(saveData);
			setRelations({ opportunity, activity, appointment });
		} catch (err) {
			logError(err, 'failed to set mail relation');
		}
	};

	const handleInvalidMailIntegration = (): Promise<void> => {
		return new Promise((resolve, reject) => {
			if (mailIntegrationValid) {
				resolve();
			} else {
				if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_CHOOSE_MAIL_INTEGRATION')) {
					return new Promise(resolve => {
						openModal('ChooseMailIntegration', {
							startSelectedId: store.mailIntegration?.id,
							onClose: resolve
						});
					});
				}

				return Tools.$upModal.open('chooseMailIntegration', {
					startSelectedId: store.mailIntegration?.id
				});
			}
		});
	};
	const send = () => {
		mailContextHelpersSend(dispatch, state);
		setShowSentMail(true);
		setShowReply(false);
		setTimeout(() => {
			close();
		}, 2000);
	};

	const reply = () => {
		if (data) {
			// eslint-disable-next-line promise/catch-or-return
			handleInvalidMailIntegration().then(() => {
				const replyTo = data?.type === 'in' ? [from as MailRecipient] : data.recipients?.to;
				dispatch(mailContextHelpersReply(data?.subject, replyTo));
				setShowReply(true);
				setShowSentMail(false);
			});
		}
	};

	const copy = () => {
		if (data) {
			// eslint-disable-next-line promise/catch-or-return
			handleInvalidMailIntegration().then(() => {
				close();
				openNewMailDrawerWithCopiedData(data);
			});
		}
	};

	const excludeYourself = (recipients: MailRecipient[]) => {
		return recipients.filter(recipient => recipient.email !== store?.self?.email) || [];
	};

	const replyAll = () => {
		if (data) {
			// eslint-disable-next-line promise/catch-or-return
			handleInvalidMailIntegration().then(() => {
				const to = excludeYourself(data?.recipients?.to);
				const cc = excludeYourself(data?.recipients?.cc);
				if (data?.type === 'in') {
					to.push(from as MailRecipient);
				}
				dispatch(mailContextHelpersReply(data?.subject, to, cc));
				setShowReply(true);
				setShowSentMail(false);
			});
		}
	};

	const tooManyRecipientsTooltip = useMemo(
		() => getTooltipIfRecipientsExceedMaxSize(state.recipients),
		[state.recipients]
	);

	// Will be added again when we can handle threads
	// const forward = () => {
	// 	// eslint-disable-next-line promise/catch-or-return
	// 	handleInvalidMailIntegration().then(() => {
	// 		dispatch(mailContextHelpersForward(data?.subject));
	// 		setShowSentMail(false);
	// 		setShowReply(true);
	// 	});
	// };

	const classes = new BemClass('SentMail', className);

	if (error) {
		return null;
	}
	if (loading) {
		return <Loader />;
	}

	if (!data?.client?.id) {
		return (
			<div className={classes.b()}>
				<DrawerHeader onHide={closeModal} title={data?.subject} />
				<MailBody body={data?.body || ''} />
			</div>
		);
	}

	return (
		<div className={classes.b()}>
			<DrawerHeader onHide={closeModal} title={data?.subject}>
				<div className={classes.elem('rightSide').b()}>
					{Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST') &&
					Tools.FeatureHelper.hasSoftDeployAccess('MAIL_RELATION') &&
					hasEmailFeature ? (
						<RelationDropdownMenu
							icon="plus"
							onRelationChange={onRelationChange}
							client={data.client}
							contact={data.contact}
							source={{ id: data.id, type: TYPES.MAIL }}
							opportunityId={relations?.opportunity?.id}
							activityId={relations?.activity?.id}
							appointmentId={relations?.appointment?.id}
						/>
					) : null}
					{hasEmailFeature ? (
						<>
							{showReply ? (
								<>
									<Button type="link" color="green" onClick={() => setShowReply(false)}>
										{T('cancel')}
									</Button>
									<MailSendButton
										disabled={
											!state.subject.length ||
											!state.recipients.to.length ||
											!!tooManyRecipientsTooltip
										}
										tooltipText={tooManyRecipientsTooltip}
										onClick={send}
										onMailSchedule={close}
										loading={state.loading}
									/>
								</>
							) : (
								<>
									{/* Will be added again when we can handle threads
							<Button onClick={forward} disabled={mail?.type !== 'in'} color="super-light-green">
								<Icon space="mrs" name="forward" />
								<Text>{T('mailDrawer.forward')}</Text>
							</Button> */}
									<MailReplyButton
										copy={copy}
										reply={reply}
										replyAll={replyAll}
										recipients={data?.recipients}
									/>
								</>
							)}
						</>
					) : null}
				</div>
			</DrawerHeader>
			<SlideFade speed="slow" visible={showReply} maxHeight={replyMailHeight} height>
				<div>
					<NewMail reply={true} />
				</div>
			</SlideFade>
			<RelatedTo
				opportunity={relations?.opportunity}
				activity={relations?.activity}
				appointment={relations?.appointment}
			/>
			<SlideFade speed="slow" visible={showSentMail} height maxHeight={500}>
				<div>
					<SwooshScreen />
				</div>
			</SlideFade>
			{from ? (
				<Mail
					from={from}
					date={data.date}
					body={data.body}
					events={data.events}
					attachments={data.attachments}
					recipients={data.recipients}
					close={close}
				/>
			) : null}
		</div>
	);
};

const connectProvider = (props: Props) => (
	<MailProvider>
		<SentMail {...props} />
	</MailProvider>
);

export const detached = SentMail;
export default (props: Props) => connectProvider(props);
