import React from 'react';
import { Headline, Text, Logo, Icon } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';

import './ThankYou.scss';

type Props = {
	next: () => void;
};

const ThankYou = (props: Props) => {
	const { next } = props;
	const classNames = new bemClass('ThankYou');

	return (
		<div className={classNames.b()} onClick={next}>
			<img src="img/yourYearWithUpsales/final.svg" />
			<Headline color="white">{T('yourYearWithUpsales.thanksForThisYear')}</Headline>
			<Text color="white">{T('yourYearWithUpsales.nextYear')}</Text>

			<div className={classNames.elem('upsalesHandShake').b()}>
				<Logo color="super-light-green" />
				<Icon name="handshake" color="super-light-green" />
				<Text bold color="super-light-green">
					{Tools.AppService.getSelf().name}
				</Text>
			</div>
		</div>
	);
};

export default ThankYou;
