import _ from 'lodash';
import {
	RCDashboard,
	RCDashboardExtended,
	RCDashboardFilter,
	RCDashboardWidget,
	RCDashboardView
} from 'Resources/ReportDashboard';
import { RCWidgetMeta } from 'Resources/ReportWidget';
import CustomField from 'App/resources/Model/CustomField';
import T from 'Components/Helpers/translate';
import { IconName } from '@upsales/components';
import User from 'App/resources/Model/User';

export const displayTypeKeys: { [k: string]: string } = {
	BAR_CHART: 'barChart',
	COLUMN_CHART: 'columnChart',
	BIG_NUMBER: 'bigNumber',
	PROGRESS_BAR: 'progressBar',
	TABLE: 'table',
	FUNNEL: 'funnel',
	PRODUCT_GRID: 'productGrid'
};

export const displayTypes = {
	[displayTypeKeys.BAR_CHART]: {
		type: displayTypeKeys.BAR_CHART,
		title: 'reportcenter.barChart',
		minWidth: 2,
		legend: true,
		groupBy: true
	},
	[displayTypeKeys.COLUMN_CHART]: {
		type: displayTypeKeys.COLUMN_CHART,
		title: 'reportcenter.columnChart',
		minWidth: 2,
		legend: true,
		groupBy: true
	},
	[displayTypeKeys.BIG_NUMBER]: {
		type: displayTypeKeys.BIG_NUMBER,
		title: 'reportcenter.bigNumber',
		minWidth: 1,
		legend: false,
		groupBy: false
	},
	[displayTypeKeys.TABLE]: {
		type: displayTypeKeys.TABLE,
		title: 'reportcenter.table',
		minWidth: 2,
		legend: false,
		groupBy: true
	},
	[displayTypeKeys.PROGRESS_BAR]: {
		type: displayTypeKeys.PROGRESS_BAR,
		title: 'reportcenter.progressBar',
		minWidth: 2,
		legend: false,
		groupBy: false
	},
	[displayTypeKeys.FUNNEL]: {
		type: displayTypeKeys.FUNNEL,
		title: 'reportcenter.funnel',
		minWidth: 4,
		legend: false
	},
	[displayTypeKeys.PRODUCT_GRID]: {
		type: displayTypeKeys.PRODUCT_GRID,
		title: 'reportcenter.productGrid',
		minWidth: 4,
		legend: true
	}
};

const groupKeys = {
	ENTITY_TYPE: 'ENTITY_TYPE',
	USER: 'USER',
	CURRENT_USER: 'CURRENT_USER',
	ROLE: 'ROLE',
	YEAR: 'YEAR',
	FISCAL_YEAR: 'FISCAL_YEAR',
	QUARTER: 'QUARTER',
	FISCAL_QUARTER: 'FISCAL_QUARTER',
	MONTH: 'MONTH',
	WEEK: 'WEEK',
	CLIENT: 'CLIENT',
	CLIENT_CAMPAIGN: 'CLIENT_CAMPAIGN',
	CLIENT_CATEGORY: 'CLIENT_CATEGORY',
	CLIENT_CITY: 'CLIENT_CITY',
	CLIENT_STATE: 'CLIENT_STATE',
	CLIENT_COUNTRY: 'CLIENT_COUNTRY',
	ACCOUNT_MANAGER: 'ACCOUNT_MANAGER',
	CAMPAIGN: 'CAMPAIGN',
	CALL_LIST: 'CALL_LIST',
	PRODUCT: 'PRODUCT',
	PRODUCT_CATEGORY: 'PRODUCT_CATEGORY',
	STAGE: 'STAGE',
	RECURRING_TYPE: 'RECURRING_TYPE',
	INDUSTRY: 'INDUSTRY',
	NO_EMPLOYEES: 'NO_EMPLOYEES',
	TURNOVER: 'TURNOVER',
	DEAL_SIZE: 'DEAL_SIZE',
	LOSS_REASON: 'LOSS_REASON',
	COMPETITOR: 'COMPETITOR',
	FORM: 'FORM',
	LEAD_SOURCE_TYPE: 'LEAD_SOURCE_TYPE',
	FLOW: 'FLOW',
	COMPLETED_FLOW: 'COMPLETED_FLOW',
	LEAD_SOURCE_TYPE_VISITS: 'LEAD_SOURCE_TYPE_VISITS',
	LEAD_SOURCE: 'LEAD_SOURCE',
	MAIL_CAMPAIGN: 'MAIL_CAMPAIGN',
	DEVICE: 'DEVICE',
	COUNTRY: 'COUNTRY',
	EVENT_TYPE: 'EVENT_TYPE',
	EVENT_STATUS: 'EVENT_STATUS',
	EVENT: 'EVENT',
	EVENT_ATTENDED: 'EVENT_ATTENDED',
	JOURNEY_STEP_CONTACT: 'JOURNEY_STEP_CONTACT',
	JOURNEY_STEP_CLIENT: 'JOURNEY_STEP_CLIENT',
	FIRST_TIME_SUBMITTER: 'FIRST_TIME_SUBMITTER',
	CUSTOMER: 'CUSTOMER',
	ORDER_RELATION: 'ORDER_RELATION',
	EASY_BOOKING: 'EASY_BOOKING',
	WEB_PAGE: 'WEB_PAGE',
	CLIENT_NEW_SALE: 'CLIENT_NEW_SALE',
	TICKET_TYPE: 'TICKET_TYPE'
};

const getSecondGroupings = (exclude: string[]) => {
	const alwaysExclude = [
		groupKeys.ACCOUNT_MANAGER,
		groupKeys.CLIENT_CAMPAIGN,
		groupKeys.CLIENT_CATEGORY,
		groupKeys.CAMPAIGN,
		groupKeys.DEAL_SIZE
	];

	return Object.values(groupKeys).filter(group => ![...exclude, ...alwaysExclude].includes(group));
};

export const groupings = {
	[groupKeys.ENTITY_TYPE]: {
		value: 'entityType',
		secondGroupings: []
	},
	[groupKeys.USER]: {
		value: 'user',
		secondGroupings: getSecondGroupings([
			groupKeys.USER,
			groupKeys.ROLE,
			groupKeys.MAIL_CAMPAIGN,
			groupKeys.DEVICE,
			groupKeys.COUNTRY,
			groupKeys.FLOW
		]),
		getName: (widgetMeta?: RCWidgetMeta) => {
			const arrWidgets = [
				'RECURRING_CHANGES',
				'RECURRING_GROWTH',
				'TOTAL_RECURRING',
				'CURRENT_TOTAL_RECURRING',
				'TOTAL_RECURRING_PRODUCT'
			];
			const isARRWidget = widgetMeta?.name && arrWidgets.indexOf(widgetMeta.name) >= 0;
			const groupChangesByUser = isARRWidget ? Tools.AppService.getMetadata().params.GroupARRChangesByUser : true;

			if (!isARRWidget) {
				return T(`reportcenter.grouping.user`);
			}

			return groupChangesByUser
				? T(`reportcenter.grouping.currentUser`)
				: T(`reportcenter.grouping.accountManagerAtChange`);
		}
	},
	[groupKeys.CURRENT_USER]: {
		value: 'currentUser',
		secondGroupings: getSecondGroupings([
			groupKeys.USER,
			groupKeys.ROLE,
			groupKeys.MAIL_CAMPAIGN,
			groupKeys.DEVICE,
			groupKeys.COUNTRY,
			groupKeys.FLOW
		]),
		getName: () => T(`reportcenter.grouping.user`)
	},
	[groupKeys.ROLE]: {
		value: 'role',
		secondGroupings: getSecondGroupings([groupKeys.ROLE])
	},
	[groupKeys.PRODUCT]: {
		value: 'product',
		secondGroupings: getSecondGroupings([groupKeys.PRODUCT, groupKeys.PRODUCT_CATEGORY])
	},
	[groupKeys.PRODUCT_CATEGORY]: {
		value: 'productCategory',
		secondGroupings: getSecondGroupings([groupKeys.PRODUCT_CATEGORY])
	},
	[groupKeys.YEAR]: {
		value: 'year',
		secondGroupings: getSecondGroupings([
			groupKeys.YEAR,
			groupKeys.FISCAL_YEAR,
			groupKeys.FISCAL_QUARTER,
			groupKeys.MAIL_CAMPAIGN,
			groupKeys.DEVICE,
			groupKeys.COUNTRY
		])
	},
	[groupKeys.FISCAL_YEAR]: {
		value: 'fiscalYear',
		secondGroupings: getSecondGroupings([
			groupKeys.YEAR,
			groupKeys.FISCAL_YEAR,
			groupKeys.QUARTER,
			groupKeys.FISCAL_QUARTER,
			groupKeys.MAIL_CAMPAIGN,
			groupKeys.DEVICE,
			groupKeys.COUNTRY
		])
	},
	[groupKeys.QUARTER]: {
		value: 'quarter',
		secondGroupings: getSecondGroupings([
			groupKeys.YEAR,
			groupKeys.FISCAL_YEAR,
			groupKeys.QUARTER,
			groupKeys.FISCAL_QUARTER,
			groupKeys.MAIL_CAMPAIGN,
			groupKeys.DEVICE,
			groupKeys.COUNTRY
		])
	},
	[groupKeys.FISCAL_QUARTER]: {
		value: 'fiscalQuarter',
		secondGroupings: getSecondGroupings([
			groupKeys.YEAR,
			groupKeys.FISCAL_YEAR,
			groupKeys.QUARTER,
			groupKeys.FISCAL_QUARTER,
			groupKeys.MAIL_CAMPAIGN,
			groupKeys.DEVICE,
			groupKeys.COUNTRY
		])
	},
	[groupKeys.MONTH]: {
		value: 'month',
		secondGroupings: getSecondGroupings([
			groupKeys.YEAR,
			groupKeys.FISCAL_YEAR,
			groupKeys.QUARTER,
			groupKeys.FISCAL_QUARTER,
			groupKeys.MONTH,
			groupKeys.DEVICE,
			groupKeys.COUNTRY
		])
	},
	[groupKeys.WEEK]: {
		value: 'week',
		secondGroupings: getSecondGroupings([
			groupKeys.YEAR,
			groupKeys.FISCAL_YEAR,
			groupKeys.QUARTER,
			groupKeys.FISCAL_QUARTER,
			groupKeys.MONTH,
			groupKeys.WEEK,
			groupKeys.DEVICE,
			groupKeys.COUNTRY
		])
	},
	[groupKeys.CLIENT]: {
		value: 'client',
		secondGroupings: getSecondGroupings([groupKeys.CLIENT])
	},
	[groupKeys.CLIENT_CATEGORY]: {
		value: 'clientCategory',
		secondGroupings: getSecondGroupings([groupKeys.CLIENT_CATEGORY, groupKeys.CLIENT_CAMPAIGN])
	},
	[groupKeys.CLIENT_CAMPAIGN]: {
		value: 'clientCampaign',
		secondGroupings: getSecondGroupings([groupKeys.CLIENT_CAMPAIGN, groupKeys.CLIENT_CATEGORY])
	},
	[groupKeys.CLIENT_CITY]: {
		value: 'clientCity',
		secondGroupings: getSecondGroupings([groupKeys.CLIENT_CITY])
	},
	[groupKeys.CLIENT_STATE]: {
		value: 'clientState',
		secondGroupings: getSecondGroupings([groupKeys.CLIENT_STATE])
	},
	[groupKeys.CLIENT_COUNTRY]: {
		value: 'clientCountry',
		secondGroupings: getSecondGroupings([groupKeys.CLIENT_COUNTRY])
	},
	[groupKeys.CAMPAIGN]: {
		value: 'campaign',
		secondGroupings: []
	},
	[groupKeys.CALL_LIST]: {
		value: 'callList',
		secondGroupings: []
	},
	[groupKeys.ACCOUNT_MANAGER]: {
		value: 'accountManager',
		secondGroupings: getSecondGroupings([
			groupKeys.ACCOUNT_MANAGER,
			groupKeys.USER,
			groupKeys.ROLE,
			groupKeys.CLIENT_CAMPAIGN,
			groupKeys.CLIENT_CATEGORY
		])
	},
	[groupKeys.RECURRING_TYPE]: {
		value: 'recurringType',
		secondGroupings: getSecondGroupings([groupKeys.RECURRING_TYPE])
	},
	[groupKeys.STAGE]: {
		value: 'stage',
		secondGroupings: getSecondGroupings([groupKeys.STAGE])
	},
	[groupKeys.INDUSTRY]: {
		value: 'industry',
		secondGroupings: getSecondGroupings([groupKeys.INDUSTRY])
	},
	[groupKeys.NO_EMPLOYEES]: {
		value: 'noEmployees',
		secondGroupings: getSecondGroupings([groupKeys.NO_EMPLOYEES])
	},
	[groupKeys.TURNOVER]: {
		value: 'turnover',
		secondGroupings: getSecondGroupings([groupKeys.TURNOVER])
	},
	[groupKeys.DEAL_SIZE]: {
		value: 'dealSize',
		secondGroupings: []
	},
	[groupKeys.LOSS_REASON]: {
		value: 'lossReason',
		secondGroupings: getSecondGroupings([groupKeys.LOSS_REASON])
	},
	[groupKeys.COMPETITOR]: {
		value: 'competitor',
		secondGroupings: getSecondGroupings([groupKeys.COMPETITOR])
	},
	[groupKeys.FORM]: {
		value: 'form',
		secondGroupings: []
	},
	[groupKeys.EVENT_TYPE]: {
		value: 'eventType',
		secondGroupings: getSecondGroupings([groupKeys.EVENT_TYPE])
	},
	[groupKeys.EVENT_STATUS]: {
		value: 'eventStatus',
		secondGroupings: getSecondGroupings([groupKeys.EVENT_STATUS])
	},
	[groupKeys.EASY_BOOKING]: {
		value: 'easyBooking',
		secondGroupings: getSecondGroupings([groupKeys.EASY_BOOKING])
	},
	[groupKeys.LEAD_SOURCE]: {
		value: 'leadSource',
		secondGroupings: []
	},
	[groupKeys.EVENT]: {
		value: 'event',
		secondGroupings: []
	},
	[groupKeys.EVENT_ATTENDED]: {
		value: 'eventAttended',
		secondGroupings: []
	},
	[groupKeys.JOURNEY_STEP_CONTACT]: {
		value: 'journeyStepContact',
		secondGroupings: []
	},
	[groupKeys.JOURNEY_STEP_CLIENT]: {
		value: 'journeyStepClient',
		secondGroupings: []
	},
	[groupKeys.LEAD_SOURCE_TYPE]: {
		value: 'leadSourceType',
		secondGroupings: []
	},
	[groupKeys.FLOW]: {
		value: 'flow',
		secondGroupings: [groupKeys.COMPLETED_FLOW, groupKeys.CUSTOMER]
	},
	[groupKeys.COMPLETED_FLOW]: {
		value: 'completedFlow',
		secondGroupings: []
	},
	[groupKeys.LEAD_SOURCE_TYPE_VISITS]: {
		value: 'leadSourceTypeVisits',
		secondGroupings: []
	},
	[groupKeys.MAIL_CAMPAIGN]: {
		value: 'mailCampaign',
		secondGroupings: []
	},
	[groupKeys.DEVICE]: {
		value: 'device',
		secondGroupings: []
	},
	[groupKeys.COUNTRY]: {
		value: 'country',
		secondGroupings: []
	},
	[groupKeys.FIRST_TIME_SUBMITTER]: {
		value: 'firstTimeSubmitter',
		secondGroupings: []
	},
	[groupKeys.CUSTOMER]: {
		value: 'customer',
		secondGroupings: []
	},
	[groupKeys.ORDER_RELATION]: {
		value: 'orderRelation',
		secondGroupings: getSecondGroupings([groupKeys.ORDER_RELATION]),
		isAvailable: () => {
			return (
				groupings[groupKeys.ORDER_RELATION]!.getName!() &&
				Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.COMPANY_RELATIONS)
			);
		},
		getName: () => {
			// Set clientOrderRelation variable
			const clientRelationField = _.get(
				Tools.AppService.getMetadata().standardFields,
				'Order.ClientOrderRelation',
				{ active: false, nameTag: '' }
			) as { active: boolean; nameTag: string };

			if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS') && clientRelationField.active) {
				const [language] = (Tools.AppService.getSelf().language || '').split('-');
				return (
					(_.get(clientRelationField, `fieldNameTranslations[${language}].value`) as string) ||
					T(clientRelationField.nameTag)
				);
			} else {
				return Tools.AppService.getMetadata().params.clientOrderRelation;
			}
		}
	},
	[groupKeys.WEB_PAGE]: {
		value: 'webPage',
		secondGroupings: []
	},
	[groupKeys.CLIENT_NEW_SALE]: {
		value: 'clientNewSale',
		secondGroupings: getSecondGroupings([groupKeys.CLIENT_NEW_SALE])
	},
	[groupKeys.TICKET_TYPE]: {
		value: 'ticketType',
		secondGroupings: getSecondGroupings([groupKeys.TICKET_TYPE])
	}
};

export const customEntityMap: { [key: string]: Parameters<(typeof Tools)['AppService']['getCustomFields']>[0] } = {
	Client: 'account',
	Order: 'order',
	Product: 'product',
	Campaign: 'project',
	Orderrow: 'orderrow',
	User: 'user',
	Appointment: 'appointment',
	Phonecall: 'activity',
	Agreement: 'agreement',
	Contact: 'contact'
};

export const getCustomFields: (entity: keyof typeof customEntityMap, datatypes: string[]) => CustomField[] = (
	entity,
	datatypes
) => {
	const entityName = customEntityMap[entity];
	const fields = Tools.AppService.getCustomFields(entityName);
	return fields.filter(
		(field: CustomField) => field.$hasAccess && field.visible && datatypes.includes(field.datatype)
	);
};

export const getCustomField = (entity: keyof typeof customEntityMap, id: number) => {
	const entityName = customEntityMap[entity];
	return Tools.AppService.getCustomFields(entityName).find(cf => cf.id === id);
};

export type Select2Section = {
	name: string;
	children: { id: string; name: string }[];
};

export type Groupings = {
	id: string;
	name: string;
	children?: Groupings;
}[];

// These custom field datatypes do not work as groupings
const disabledDatatypeGroupings = ['Users'];

export const getCustomGroupings = (widgetMeta: RCWidgetMeta, firstGrouping?: string) => {
	const dateGroupings = ['week', 'month', 'quarter', 'year'];
	const { customFieldTypes, disableCustomGroupings } = widgetMeta;

	// If there aren't any allowed second groupings or if specific secondary groupings are specified
	// we shouldn't allow custom second groupings
	if (
		disableCustomGroupings ||
		(firstGrouping &&
			groupings[_.snakeCase(firstGrouping)?.toUpperCase()] &&
			!groupings[_.snakeCase(firstGrouping)?.toUpperCase()].secondGroupings.length) ||
		(firstGrouping && Object.keys(widgetMeta.secondaryGroupings ?? {}).length)
	) {
		return [];
	}

	let customGroupings: Groupings = [];
	if (customFieldTypes?.entities?.length) {
		const customFields: { [k: string]: CustomField[] } = {};
		for (const entity of customFieldTypes.entities) {
			const fields = getCustomFields(entity, customFieldTypes.datatypes);
			if (!_.isEmpty(fields)) {
				customFields[entity] = fields.filter(f => !disabledDatatypeGroupings.includes(f.datatype));
			}
		}
		for (const [entity, fields] of Object.entries(customFields)) {
			let children: Groupings = [];
			for (const f of fields) {
				if (f.datatype === 'Date') {
					const dateOptions = dateGroupings.map(period => ({
						id: `${entity.toLowerCase()}_custom_${f.id}_${period}`,
						name: `${f.name}: ${T(`reportcenter.grouping.${period}`)}`
					}));
					children = [...children, ...dateOptions];
				} else {
					children = [
						...children,
						{
							id: `${entity.toLowerCase()}_custom_${f.id}`,
							name: f.name
						}
					];
				}
			}
			const section = {
				id: `${entity.toLowerCase()}_custom`,
				name: `${T('default.customFields')}: ${T(`default.${entity.toLowerCase()}`)}`,
				children
			};
			customGroupings = [...customGroupings, section];
		}
		if (firstGrouping) {
			let filteredGroupings: Groupings = [];
			for (const section of customGroupings) {
				const filteredSection = {
					...section,
					children: section.children!.filter(c => {
						const split = c.id.split('_');
						const lastElem = split[split.length - 1];
						// Only return date groupings that are smaller than first grouping
						if (dateGroupings.includes(lastElem)) {
							const cIndex = dateGroupings.indexOf(lastElem);
							const firstGroupingIndex = dateGroupings.indexOf(
								firstGrouping.split('_')[firstGrouping.split('_').length - 1]
							);
							if (firstGroupingIndex !== -1) {
								return cIndex < firstGroupingIndex;
							}
						}
						return c.id !== firstGrouping;
					})
				};
				if (filteredSection.children.length) {
					filteredGroupings = [...filteredGroupings, filteredSection];
				}
			}
			customGroupings = filteredGroupings;
		}
	}

	return customGroupings;
};

export const getAvailableGroupings = (widgetMeta: RCWidgetMeta, firstGrouping: string | null) => {
	// Widget type supported groupings
	const widgetSupportedGroupings = Object.keys(widgetMeta.groupings);
	const widgetSupportedSecondaryGroupings = Object.keys(widgetMeta?.secondaryGroupings ?? []);

	const supportedWidgetGroupings =
		!firstGrouping || !widgetSupportedSecondaryGroupings?.length
			? widgetSupportedGroupings
			: widgetSupportedSecondaryGroupings;

	// UI supported groupings
	const supportedGroupings = Object.keys(groupings).filter(
		group => !groupings[group].isAvailable || groupings[group].isAvailable!()
	);

	// Groupings available for specific first grouping
	const availableGroupings =
		firstGrouping && !firstGrouping.includes('_custom_')
			? // toUpperCase will work as long as the grouping key/value matches
			  groupings?.[_.snakeCase(firstGrouping)?.toUpperCase()]?.secondGroupings || []
			: Object.keys(groupings);

	const keys = _.intersection(supportedGroupings, supportedWidgetGroupings, availableGroupings);

	return keys.map(key => ({
		id: groupings[key].value,
		name: groupings[key].getName?.(widgetMeta) || T(`reportcenter.grouping.${groupings[key].value}`)
	}));
};

export const getAvailableDisplayTypes = (widgetMeta: RCWidgetMeta) => {
	// The order of display types in the UI
	const sortMap: { [k: string]: number } = {
		funnel: 0,
		columnChart: 1,
		barChart: 2,
		progressBar: 3,
		bigNumber: 4,
		table: 5
	};

	// Widget type supported displayTypes
	const widgetSupportedGroupings = Object.keys(widgetMeta.displayTypes);

	// UI supported displayTypes
	const keys = widgetSupportedGroupings.reduce<string[]>((r, key) => {
		const type = displayTypeKeys[key];
		if (displayTypes[type]) {
			r.push(displayTypes[type].type);
		}
		return r;
	}, []);

	const sortedKeys = keys.sort((a, b) => sortMap[a] - sortMap[b]);
	return sortedKeys;
};

export const titles: { [key: string]: { [key: string]: string } } = {
	date: {
		CurrentDay: 'date.currentDay',
		LastDay: 'date.lastDay',
		NextDay: 'date.nextDay',
		CurrentWeek: 'date.currentWeek',
		LastWeek: 'date.lastWeek',
		NextWeek: 'date.nextWeek',
		CurrentMonth: 'date.currentMonth',
		LastMonth: 'date.lastMonth',
		NextMonth: 'date.nextMonth',
		CurrentQuarter: 'date.currentQuarter',
		LastQuarter: 'date.lastQuarter',
		NextQuarter: 'date.nextQuarter',
		CurrentYear: 'date.currentYear',
		LastYear: 'date.lastYear',
		NextYear: 'date.nextYear',
		CurrentHalfYear: 'date.currentHalfYear',
		LastHalfYear: 'date.lastHalfYear',
		NextHalfYear: 'date.nextHalfYear',
		Last5Years: 'date.prev5Year',
		Custom: 'date.ownPeriod',

		NextFiscalYear: 'date.nextFiscalYear',
		CurrentFiscalYear: 'date.currentFiscalYear',
		LastFiscalYear: 'date.lastFiscalYear',
		CurrentFiscalHalfYear: 'date.currentFiscalHalfYear',
		LastFiscalHalfYear: 'date.lastFiscalHalfYear',
		NextFiscalHalfYear: 'date.nextFiscalHalfYear',
		NextFiscalQuarter: 'date.nextFiscalQuarter',
		CurrentFiscalQuarter: 'date.currentFiscalQuarter',
		LastFiscalQuarter: 'date.lastFiscalQuarter',
		Last5FiscalYears: 'date.prev5FiscalYears',

		rolling7DaysBack: 'date.rolling.rolling7DaysBack',
		rolling7DaysAhead: 'date.rolling.rolling7DaysAhead',
		rolling30DaysBack: 'date.rolling.rolling30DaysBack',
		rolling30DaysAhead: 'date.rolling.rolling30DaysAhead',
		rolling3MonthsBack: 'date.rolling.rolling3MonthsBack',
		rolling3MonthsAhead: 'date.rolling.rolling3MonthsAhead',
		rolling6MonthsBack: 'date.rolling.rolling6MonthsBack',
		rolling6MonthsAhead: 'date.rolling.rolling6MonthsAhead',
		rolling12MonthsBack: 'date.rolling.rolling12MonthsBack',
		rolling12MonthsAhead: 'date.rolling.rolling12MonthsAhead',
		rolling5YearsBack: 'date.rolling.rolling5YearsBack',

		'7DaysBack': 'date.rolling.7DaysBack',
		'7DaysAhead': 'date.rolling.7DaysAhead',
		'30DaysBack': 'date.rolling.30DaysBack',
		'30DaysAhead': 'date.rolling.30DaysAhead',
		'3MonthsBack': 'date.rolling.3MonthsBack',
		'3MonthsAhead': 'date.rolling.3MonthsAhead',
		'6MonthsBack': 'date.rolling.6MonthsBack',
		'6MonthsAhead': 'date.rolling.6MonthsAhead',
		'12MonthsBack': 'date.rolling.12MonthsBack',
		'12MonthsAhead': 'date.rolling.12MonthsAhead',
		'5YearsBack': 'date.rolling.5YearsBack'
	},
	type: {
		Decrease: 'arrchange.type.DECREASE',
		Increase: 'arrchange.type.INCREASE',
		IndexIncrease: 'arrchange.type.INDEX_INCREASE',
		LostClient: 'arrchange.type.LOST_CLIENT',
		NewClient: 'arrchange.type.NEW_CLIENT',
		WinBack: 'arrchange.type.WIN_BACK',
		ExistingCustomer: 'default.existingCustomer'
	},
	eventStatus: {
		Draft: 'socialEvent.draft',
		Launched: 'socialEvent.launched',
		Passed: 'socialEvent.passed',
		Canceled: 'socialEvent.canceled'
	},
	eventType: {
		VenueAndLocation: 'socialEvent.venueAndLocation',
		Webinar: 'socialEvent.webinar'
	},
	industry: {
		A: 'default.snigroupA',
		B: 'default.snigroupB',
		C: 'default.snigroupC',
		D: 'default.snigroupD',
		E: 'default.snigroupE',
		F: 'default.snigroupF',
		G: 'default.snigroupG',
		H: 'default.snigroupH',
		I: 'default.snigroupI',
		J: 'default.snigroupJ',
		K: 'default.snigroupK',
		L: 'default.snigroupL',
		M: 'default.snigroupM',
		N: 'default.snigroupN',
		O: 'default.snigroupO',
		P: 'default.snigroupP',
		Q: 'default.snigroupQ',
		R: 'default.snigroupR',
		S: 'default.snigroupS',
		T: 'default.snigroupT',
		U: 'default.snigroupU'
	},
	devices: {
		desktop: 'device.desktop',
		devices: 'device.devices',
		mobile: 'device.mobile',
		tablet: 'device.tablet'
	},
	initialConversionAction: {
		formSubmit: 'reportcenter.filter.initialConversionAction.formSubmit',
		eventRegistration: 'reportcenter.filter.initialConversionAction.eventRegistration',
		landingpageSubmit: 'reportcenter.filter.initialConversionAction.landingpageSubmit',
		prospecting: 'reportcenter.filter.initialConversionAction.prospecting',
		visit: 'reportcenter.filter.initialConversionAction.visit',
		manual: 'reportcenter.filter.initialConversionAction.manual',
		import: 'reportcenter.filter.initialConversionAction.import',
		bisnode: 'reportcenter.filter.initialConversionAction.bisnode',
		api: 'reportcenter.filter.initialConversionAction.api',
		app: 'reportcenter.filter.initialConversionAction.app'
	},
	marketingSource: {
		socialEvent: 'default.socialEvent',
		mailCampaign: 'default.mailinglist',
		flow: 'default.flow'
	}
};

const labels = {
	date: 'reportcenter.filter.date',
	user: 'reportcenter.filter.usersAndRoles',
	product: 'reportcenter.filter.product',
	productCategory: 'reportcenter.filter.productCategory',
	stage: 'reportcenter.filter.orderStage',
	campaign: 'reportcenter.filter.campaign',
	callList: 'default.callList',
	accountManager: 'reportcenter.filter.accountManager',
	currency: 'reportcenter.filter.currency',
	clientCategory: 'reportcenter.filter.clientCategory',
	appointmentType: 'reportcenter.filter.appointmentType',
	firstAppointmentType: 'reportcenter.filter.appointmentType',
	type: 'reportcenter.filter.type',
	activityType: 'default.activityType',
	client: 'default.client',
	industry: 'default.companyBranch.sni',
	lossReason: 'loss reason',
	noEmployees: 'default.noEmployees',
	turnover: 'default.turnover',
	leadSource: 'default.leadsource',
	leadSourceType: 'reportcenter.filter.leadSourceType',
	flow: 'reportcenter.filter.flow',
	firstTimeSubmitter: 'reportcenter.filter.firstTimeSubmitter',
	mailCampaign: 'reportcenter.filter.mailCampaign',
	form: 'reportcenter.filter.form',
	device: 'reportcenter.filter.device',
	clientAddressesCountry: 'reportcenter.filter.country',
	eventType: 'socialEvent.type',
	eventStatus: 'default.status',
	event: 'default.socialEvent',
	eventAttended: 'socialEvent.attended',
	journeyStepContact: 'default.journeyStepContact',
	journeyStepClient: 'default.journeyStepClient',
	initialConversionAction: 'reportcenter.filter.initialConversionAction',
	marketingSource: 'column.source',
	easyBooking: 'reportcenter.grouping.easyBooking',
	webPage: 'default.webpage',
	label: 'file.label',
	clientNewSale: 'default.typeOfSale',
	clientAddressesState: 'address.county',
	clientAddressesCity: 'address.city',
	clientCampaign: 'reportcenter.filter.clientCampaign',
	ticketType: 'admin.ticketType'
};

export const filterPresets = {
	date: {
		presets: [
			{ value: 'LastDay', title: titles.date.LastDay, type: ['static', 'day', 'calendar', 'fiscal'] },
			{ value: 'CurrentDay', title: titles.date.CurrentDay, type: ['static', 'day', 'calendar', 'fiscal'] },
			{
				value: 'NextDay',
				title: titles.date.NextDay,
				type: ['static', 'day', 'calendar', 'fiscal', 'upcoming']
			},

			{ value: 'LastWeek', title: titles.date.LastWeek, type: ['static', 'week', 'calendar', 'fiscal'] },
			{ value: 'CurrentWeek', title: titles.date.CurrentWeek, type: ['static', 'week', 'calendar', 'fiscal'] },
			{
				value: 'NextWeek',
				title: titles.date.NextWeek,
				type: ['static', 'week', 'calendar', 'fiscal', 'upcoming']
			},

			{ value: 'LastMonth', title: titles.date.LastMonth, type: ['static', 'month', 'calendar', 'fiscal'] },
			{ value: 'CurrentMonth', title: titles.date.CurrentMonth, type: ['static', 'month', 'calendar', 'fiscal'] },
			{
				value: 'NextMonth',
				title: titles.date.NextMonth,
				type: ['static', 'month', 'calendar', 'fiscal', 'upcoming']
			},

			{ value: 'LastQuarter', title: titles.date.LastQuarter, type: ['static', 'quarter', 'calendar'] },
			{ value: 'LastFiscalQuarter', title: titles.date.LastQuarter, type: ['static', 'quarter', 'fiscal'] },
			{ value: 'CurrentQuarter', title: titles.date.CurrentQuarter, type: ['static', 'quarter', 'calendar'] },
			{ value: 'CurrentFiscalQuarter', title: titles.date.CurrentQuarter, type: ['static', 'quarter', 'fiscal'] },
			{
				value: 'NextQuarter',
				title: titles.date.NextQuarter,
				type: ['static', 'quarter', 'calendar', 'upcoming']
			},
			{
				value: 'NextFiscalQuarter',
				title: titles.date.NextQuarter,
				type: ['static', 'quarter', 'fiscal', 'upcoming']
			},

			{ value: 'LastYear', title: titles.date.LastYear, type: ['static', 'year', 'calendar'] },
			{ value: 'LastFiscalYear', title: titles.date.LastYear, type: ['static', 'year', 'fiscal'] },
			{ value: 'CurrentYear', title: titles.date.CurrentYear, type: ['static', 'year', 'calendar'] },
			{ value: 'CurrentFiscalYear', title: titles.date.CurrentYear, type: ['static', 'year', 'fiscal'] },
			{ value: 'LastHalfYear', title: titles.date.LastHalfYear, type: ['static', 'halfyear', 'calendar'] },
			{ value: 'CurrentHalfYear', title: titles.date.CurrentHalfYear, type: ['static', 'halfyear', 'calendar'] },
			{
				value: 'NextHalfYear',
				title: titles.date.NextHalfYear,
				type: ['static', 'halfyear', 'calendar', 'upcoming']
			},
			{ value: 'LastFiscalHalfYear', title: titles.date.LastHalfYear, type: ['static', 'halfyear', 'fiscal'] },
			{
				value: 'CurrentFiscalHalfYear',
				title: titles.date.CurrentHalfYear,
				type: ['static', 'halfyear', 'fiscal']
			},
			{
				value: 'NextFiscalHalfYear',
				title: titles.date.NextHalfYear,
				type: ['static', 'halfyear', 'fiscal', 'upcoming']
			},
			{ value: 'NextYear', title: titles.date.NextYear, type: ['static', 'year', 'calendar', 'upcoming'] },
			{ value: 'NextFiscalYear', title: titles.date.NextYear, type: ['static', 'year', 'fiscal', 'upcoming'] },
			{ value: 'Last5Years', title: titles.date.Last5Years, type: ['static', 'year', 'calendar'] },
			{ value: 'Last5FiscalYears', title: titles.date.Last5Years, type: ['static', 'year', 'fiscal'] },

			{ value: 'rolling7DaysBack', title: titles.date['7DaysBack'], type: ['rolling', 'week'] },
			{ value: 'rolling7DaysAhead', title: titles.date['7DaysAhead'], type: ['rolling', 'week', 'upcoming'] },
			{ value: 'rolling30DaysBack', title: titles.date['30DaysBack'], type: ['rolling', 'month'] },
			{ value: 'rolling30DaysAhead', title: titles.date['30DaysAhead'], type: ['rolling', 'month', 'upcoming'] },
			{ value: 'rolling3MonthsBack', title: titles.date['3MonthsBack'], type: ['rolling', 'quarter'] },
			{
				value: 'rolling3MonthsAhead',
				title: titles.date['3MonthsAhead'],
				type: ['rolling', 'quarter', 'upcoming']
			},
			{ value: 'rolling6MonthsBack', title: titles.date['6MonthsBack'], type: ['rolling', 'halfyear'] },
			{
				value: 'rolling6MonthsAhead',
				title: titles.date['6MonthsAhead'],
				type: ['rolling', 'halfyear', 'upcoming']
			},
			{ value: 'rolling12MonthsBack', title: titles.date['12MonthsBack'], type: ['rolling', 'year'] },
			{
				value: 'rolling12MonthsAhead',
				title: titles.date['12MonthsAhead'],
				type: ['rolling', 'year', 'upcoming']
			},
			{ value: 'rolling5YearsBack', title: titles.date['5YearsBack'], type: ['rolling', 'year'] }
		],
		label: labels.date
	},
	user: {
		presets: [],
		label: labels.user
	},
	product: {
		presets: [],
		label: labels.product
	},
	productCategory: {
		presets: [],
		label: labels.productCategory
	},
	stage: {
		presets: [],
		label: labels.stage
	},
	activityType: {
		presets: [],
		label: labels.activityType
	},
	campaign: {
		presets: [],
		label: labels.campaign
	},
	clientCampaign: {
		presets: [],
		label: labels.clientCampaign
	},
	callList: {
		presets: [],
		label: labels.callList
	},
	accountManager: {
		presets: [],
		label: labels.accountManager
	},
	currency: {
		presets: [],
		label: labels.currency
	},
	clientCategory: {
		presets: [],
		label: labels.clientCategory
	},
	appointmentType: {
		presets: [],
		label: labels.appointmentType
	},
	firstAppointmentType: {
		presets: [],
		label: labels.firstAppointmentType
	},
	type: {
		presets: [
			{ value: 'DECREASE', title: titles.type.Decrease },
			{ value: 'INCREASE', title: titles.type.Increase },
			{ value: 'INDEX_INCREASE', title: titles.type.IndexIncrease },
			{ value: 'LOST_CLIENT', title: titles.type.LostClient },
			{ value: 'NEW_CLIENT', title: titles.type.NewClient },
			{ value: 'WIN_BACK', title: titles.type.WinBack }
		],
		label: labels.type
	},
	client: {
		presets: [],
		label: labels.client
	},
	industry: {
		presets: [
			{ value: 'A', title: titles.industry.A },
			{ value: 'B', title: titles.industry.B },
			{ value: 'C', title: titles.industry.C },
			{ value: 'D', title: titles.industry.D },
			{ value: 'E', title: titles.industry.E },
			{ value: 'F', title: titles.industry.F },
			{ value: 'G', title: titles.industry.G },
			{ value: 'H', title: titles.industry.H },
			{ value: 'I', title: titles.industry.I },
			{ value: 'J', title: titles.industry.J },
			{ value: 'K', title: titles.industry.K },
			{ value: 'L', title: titles.industry.L },
			{ value: 'M', title: titles.industry.M },
			{ value: 'N', title: titles.industry.N },
			{ value: 'O', title: titles.industry.O },
			{ value: 'P', title: titles.industry.P },
			{ value: 'Q', title: titles.industry.Q },
			{ value: 'R', title: titles.industry.R },
			{ value: 'S', title: titles.industry.S },
			{ value: 'T', title: titles.industry.T },
			{ value: 'U', title: titles.industry.U }
		],
		label: labels.industry
	},
	noEmployees: {
		presets: [
			{ value: '0-9', title: '0-9' },
			{ value: '10-24', title: '10-24' },
			{ value: '25-49', title: '25-49' },
			{ value: '50-99', title: '50-99' },
			{ value: '100-199', title: '100-199' },
			{ value: '199', title: '199+' }
		],
		label: labels.noEmployees
	},
	turnover: {
		presets: [],
		label: labels.turnover
	},
	form: {
		presets: [],
		label: labels.form
	},
	leadSource: {
		presets: [],
		label: labels.leadSource
	},
	leadSourceType: {
		presets: [],
		label: labels.leadSourceType
	},
	flow: {
		presets: [],
		label: labels.flow
	},
	leadSourceTypeVisits: {
		presets: [],
		label: labels.leadSourceType
	},
	firstTimeSubmitter: {
		presets: [
			{ value: 'yes', title: 'default.yes' },
			{ value: 'no', title: 'default.no' }
		],
		label: labels.firstTimeSubmitter
	},
	eventType: {
		presets: [
			{ value: 0, title: titles.eventType.Webinar },
			{ value: 1, title: titles.eventType.VenueAndLocation }
		],
		label: labels.eventType
	},
	eventStatus: {
		presets: [
			{ value: 'draft', title: titles.eventStatus.Draft },
			{ value: 'launched', title: titles.eventStatus.Launched },
			{ value: 'passed', title: titles.eventStatus.Passed },
			{ value: 'canceled', title: titles.eventStatus.Canceled }
		],
		label: labels.eventStatus
	},
	mailCampaign: {
		presets: [],
		label: labels.mailCampaign
	},
	device: {
		presets: [
			{ value: 'desktop', title: titles.devices.desktop },
			{ value: 'mobile', title: titles.devices.mobile },
			{ value: 'tablet', title: titles.devices.tablet }
		],
		label: labels.device
	},
	event: {
		presets: [],
		label: labels.event
	},
	eventAttended: {
		presets: [],
		label: labels.eventAttended
	},
	journeyStepContact: {
		presets: [],
		label: labels.journeyStepContact
	},
	journeyStepClient: {
		presets: [],
		label: labels.journeyStepClient
	},
	initialConversionAction: {
		multi: false,
		presets: [
			{ value: 'formSubmit', title: titles.initialConversionAction.formSubmit },
			{ value: 'eventRegistration', title: titles.initialConversionAction.eventRegistration },
			{ value: 'landingpageSubmit', title: titles.initialConversionAction.landingpageSubmit },
			{ value: 'prospecting', title: titles.initialConversionAction.prospecting },
			{ value: 'visit', title: titles.initialConversionAction.visit },
			{ value: 'manual', title: titles.initialConversionAction.manual },
			{ value: 'import', title: titles.initialConversionAction.import },
			{ value: 'bisnode', title: titles.initialConversionAction.bisnode },
			{ value: 'api', title: titles.initialConversionAction.api },
			{ value: 'app', title: titles.initialConversionAction.app }
		],
		label: labels.initialConversionAction
	},
	marketingSource: {
		presets: [
			{ value: 'mailCampaign', title: titles.marketingSource.mailCampaign },
			{ value: 'socialEvent', title: titles.marketingSource.socialEvent },
			{ value: 'flow', title: titles.marketingSource.flow }
		],
		label: labels.marketingSource
	},
	easyBooking: {
		presets: [],
		label: labels.easyBooking
	},
	webPage: {
		presets: [],
		label: labels.webPage
	},
	labelForm: {
		presets: [],
		label: labels.label
	},
	labelMailCampaign: {
		presets: [],
		label: labels.label
	},
	clientNewSale: {
		presets: [
			{ value: 'NEW', title: titles.type.NewClient },
			{ value: 'EXISTING', title: titles.type.ExistingCustomer }
		],
		label: labels.clientNewSale
	},
	clientAddressesCountry: {
		presets: [],
		label: labels.clientAddressesCountry
	},
	clientAddressesState: {
		presets: [],
		label: labels.clientAddressesState
	},
	clientAddressesCity: {
		presets: [],
		label: labels.clientAddressesCity
	},
	ticketType: {
		presets: [],
		label: labels.ticketType
	}
};

type SidebarGroup = {
	key: string;
	label: string;
	dashboards: (RCDashboard | RCStandardDashboard)[];
	hiddenDashboards: (RCDashboard | RCStandardDashboard)[];
};
export const sidebarGroups: { [k: string]: SidebarGroup } = {
	created: {
		key: 'created',
		label: 'reportcenter.sidebarGroups.created',
		dashboards: [],
		hiddenDashboards: []
	},
	shared: {
		key: 'shared',
		label: 'reportcenter.sidebarGroups.shared',
		dashboards: [],
		hiddenDashboards: []
	},
	standard: {
		key: 'standard',
		label: 'reportcenter.sidebarGroups.standard',
		dashboards: [],
		hiddenDashboards: []
	}
};

export const getOptimalDimensionsByType = (displayType: string) => {
	switch (displayType) {
		case displayTypeKeys.COLUMN_CHART:
			return { width: 4, height: 3 };
		default:
		case displayTypeKeys.BAR_CHART:
		case displayTypeKeys.TABLE:
			return { width: 4, height: 3 };
		case displayTypeKeys.BIG_NUMBER:
			return { width: 4, height: 2 };
		case displayTypeKeys.PROGRESS_BAR:
			return { width: 4, height: 1 };
		case displayTypeKeys.FUNNEL:
			return { width: 4, height: 2 };
	}
};

export const isStandardDashboardId = (id: number | string) => id?.toString().indexOf('s') === 0;

export type RCStandardDashboard = {
	id: string;
	trackingName?: string;
	sidebarGroup: string;
	description: string;
	name: string;
	filters: RCDashboardFilter[];
	widgets: RCDashboardWidget[];
	views?: RCDashboardView[];
	viewsConfig?: {
		autoWidth?: boolean;
		hideLabel?: boolean;
	};
	userEditable: boolean;
	icon?: IconName;
	sorting?: number;
	hidden: undefined;
};

export function isStandardDashboard(
	obj: RCStandardDashboard | RCDashboardExtended | RCDashboard
): obj is RCStandardDashboard {
	return isStandardDashboardId(obj.id);
}

export function isSharedDashboard(
	obj: RCStandardDashboard | RCDashboardExtended | RCDashboard
): obj is RCStandardDashboard {
	return obj.sidebarGroup === 'shared';
}

export const dashboardSorter = (a: RCDashboard, b: RCDashboard) => {
	const aIsStandardDashboard = `${a.id}`.includes('s');
	const bIsStandardDashboard = `${b.id}`.includes('s');

	if (a.sorting && b.sorting) {
		return a.sorting - b.sorting;
	}
	if (a.sorting && !b.sorting) {
		return -1;
	}
	if (!a.sorting && b.sorting) {
		return 1;
	}

	if (!aIsStandardDashboard && !bIsStandardDashboard && typeof a.id === 'number' && typeof b.id === 'number') {
		return a.id - b.id;
	}

	if (aIsStandardDashboard && bIsStandardDashboard) {
		return a.id < b.id ? -1 : a.id > b.id ? 1 : 0;
	}

	return aIsStandardDashboard ? 1 : -1;
};

export const filterSorter = (a: string, b: string): number => {
	const sorting = [
		'Date',
		'User',
		'Role',
		'AccountManager',
		'Client',
		'ClientAddressesCountry',
		'ClientCategory',
		'Product',
		'Stage',
		'ClientNewSale',
		'Campaign',
		'ClientCampaign',
		'CallList',
		'Currency',
		'AppointmentType',
		'Type',
		'ActivityType',
		'MailCampaign',
		'JourneyStepClient',
		'Label'
	];

	const first = sorting.indexOf(a);
	const second = sorting.indexOf(b);
	if (first === -1) {
		return 1;
	}
	if (second === -1) {
		return -1;
	}
	return first - second;
};

export const getDefaultDashboard = (dashboards: RCDashboard[]) => {
	const dashboardMap = dashboards.reduce(
		(res, cur) => {
			if (res[cur.sidebarGroup]) {
				return { ...res, [cur.sidebarGroup]: [...res[cur.sidebarGroup], cur] };
			} else {
				return { ...res, [cur.sidebarGroup]: [cur] };
			}
		},
		{} as {
			[key: string]: RCDashboard[];
		}
	);
	const defaultDashboard =
		dashboardMap['created']?.[0] ?? dashboardMap['shared']?.[0] ?? dashboardMap['standard']?.[0];
	return defaultDashboard;
};

export const mapCustomFilterOptions: (
	field: CustomField
) => { value: string | number | 'null' | '{{self}}'; title: string }[] = field => {
	const { params } = Tools.AppService.getMetadata();

	switch (field.datatype) {
		case 'Select': {
			const options = Array.isArray(field.default) ? field.default : [field.default];
			return _.unique(options).map(option => ({ value: option, title: option }));
		}
		case 'Boolean': {
			return [
				{ value: '1', title: T('default.yes') },
				{ value: '0', title: T('default.no') }
			];
		}
		case 'Date': {
			return filterPresets.date.presets
				.filter(preset => (params.brokenFiscalYearEnabled ? true : !preset.value.includes('Fiscal')))
				.map(preset => ({
					value: preset.value,
					title:
						T(preset.title) +
						(preset.value.includes('Fiscal') ? ` (${T('default.brokenFiscalYear')})` : ''),
					datatype: field.datatype
				}));
		}
		case 'Users':
		case 'User': {
			const activeUsers = Tools.AppService.getActiveUsers() as User[];
			const options: { value: number | string; title: string }[] = activeUsers.map((user: User) => ({
				value: user.id,
				title: user.name
			}));
			options.unshift({ value: 'null', title: T('default.noUser') });
			options.unshift({ value: '{{self}}', title: T('default.loggedInUser') });
			return options;
		}
		default:
			return [];
	}
};
