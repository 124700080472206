import React, { useEffect, useState } from 'react';
import history from 'App/pages/routes/history';
import PropTypes from 'prop-types';
import OptOutLangSection from './OptOutLangSection';
import UpCountry from '../Inputs/UpCountry';
import './OptOutModal.scss';
import {
	ModalHeader,
	ModalControls,
	ModalContent,
	Toggle,
	Label,
	Block,
	Icon,
	Text,
	Link,
	Modal
} from '@upsales/components';
import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import GroupMailCategory from 'App/resources/GroupMailCategory';
import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';

const validateStr = str => {
	if (!str || !str.length) {
		return false;
	}
	return true;
};

const validate = optOut => {
	let valid = true;
	const validated = optOut;
	if (!validateStr(optOut.title)) {
		optOut.$invalidTitle = true;
		valid = false;
	}
	if (!validateStr(optOut.description)) {
		optOut.$invalidDescription = true;
		valid = false;
	}

	validated.languages = optOut.languages.map(lang => {
		if (!validateStr(lang.title)) {
			lang.$invalidTitle = true;
			valid = false;
		}
		if (!validateStr(lang.description)) {
			lang.$invalidDescription = true;
			valid = false;
		}
		return lang;
	});
	return { valid, validated };
};

const map = optOut => {
	optOut.languages = optOut.languages.map(({ language, title, description }) => {
		return { language, title, description };
	});
	return optOut;
};

class OptOutModalContent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
			isCurrentlyActive: props.optOut.active
		};

		this.lang = {
			editOptOutCategory: T('admin.editOptOutCategory'),
			save: T('default.save'),
			abort: T('default.abort'),
			addLanguage: T('default.addLanguage'),
			selectLanguage: T('default.selectLanguage'),
			se: T('language.swedish'),
			no: T('language.norweigan'),
			fi: T('language.finish'),
			dk: T('language.danish'),
			de: T('language.german'),
			en: T('language.english'),
			nl: T('language.netherlands'),
			pl: T('language.polish'),
			pt: T('language.portuguese'),
			es: T('language.spanish'),
			jp: T('language.japanese'),
			cn: T('language.chinese'),
			active: T('default.active'),
			warning: T('admin.editOptOutCategory.warning'),
			seeList: T('admin.editOptOutCategory.seeList'),
			mailCampaigns: T('default.mailings'),
			flows: T('default.flows')
		};
	}

	render() {
		const classes = new BemClass('OptOutModal');
		const flagFormat = (country, container, escape) => {
			return (
				'<span><i style="margin-right: 5px;" class="flag-icon flag-icon-' +
				escape(country.code).toLowerCase() +
				'"></i>' +
				escape(country.translated) +
				'</span>'
			);
		};

		const upCountryProps = {
			formatSelection: flagFormat,
			formatResult: (obj, container, query, escape) => flagFormat(obj, container, escape),
			countryCodes: this.props.availableLanguages,
			isLanguage: true,
			placeholder: this.lang.selectLanguage,
			updateOnDataChange: true
		};

		const canDeactivate =
			this.props.optOut.relatedMailCampaigns?.length > 0 &&
			this.props.optOut.active &&
			this.state.isCurrentlyActive;
		const usedInFlows = [];
		let moreFlows = 0;
		let moreMailCampaigns = 0;
		const usedInMailCampaigns = [];
		(this.props.optOut.relatedMailCampaigns || []).forEach(item => {
			if (item.entity === 'MailCampaign') {
				if (usedInMailCampaigns.length > 4) {
					moreMailCampaigns++;
				} else {
					if (usedInMailCampaigns.length > 0) {
						usedInMailCampaigns.push(', ');
					}
					usedInMailCampaigns.push(
						<Link
							key={item.value.id}
							onClick={() => {
								history.push(`/group-mail-editor/${item.value.id}/settings`);
								this.props.actions.onClose();
							}}
						>
							{item.value.name}
						</Link>
					);
				}
			} else {
				if (usedInFlows.length > 4) {
					moreFlows++;
				} else {
					if (usedInFlows.length > 0) {
						usedInFlows.push(', ');
					}
					usedInFlows.push(
						<Link
							key={item.value.id}
							href={
								item.value.segmentId
									? `#/${Tools.AppService.getCustomerId()}/segments/${item.value.segmentId}/flow/`
									: `#/${Tools.AppService.getCustomerId()}/flows/${item.value.id}/`
							}
							onClick={this.props.actions.onClose}
						>
							{item.value.name}
						</Link>
					);
				}
			}
		});

		const languages = (this.props.optOut.languages || []).map(langObj => {
			const languageName = this.lang[langObj.language.toLowerCase()];
			return (
				<OptOutLangSection
					key={langObj.language}
					languageName={languageName}
					title={langObj.title}
					description={langObj.description}
					invalidTitle={langObj.$invalidTitle}
					invalidDescription={langObj.$invalidDescription}
					onChange={this.props.actions.onLanguageChange.bind(null, langObj.language)}
					onRemove={this.props.actions.onLanguageRemove.bind(null, langObj.language)}
				/>
			);
		});

		return (
			<div className={classes.b()}>
				<ModalHeader
					title={this.lang.editOptOutCategory}
					icon="edit"
					onClose={this.props.actions.onClose}
					articleId={549}
				/>

				<ModalContent className="ModalContent--no-padding">
					<OptOutLangSection
						default={true}
						languageName={this.lang.en}
						title={this.props.optOut.title}
						description={this.props.optOut.description}
						invalidTitle={this.props.optOut.$invalidTitle}
						invalidDescription={this.props.optOut.$invalidDescription}
						onChange={this.props.actions.onLanguageChange.bind(null, 'en')}
					/>

					{languages}

					{this.props.availableLanguages.length ? (
						<div className={classes.elem('add-language').b()}>
							<div>{this.lang.addLanguage}</div>
							<UpCountry
								value={null}
								onChange={this.props.actions.onLanguageAdd}
								options={upCountryProps}
								className={classes.elem('add-language-select').b()}
							/>
						</div>
					) : null}
					<div className={classes.elem('add-active').b()}>
						<Label size="sm">{this.lang.active}</Label>
						<Toggle
							checked={this.props.optOut.active}
							color="bright-blue"
							onChange={this.props.actions.activeChanged}
							disabled={canDeactivate}
						/>
						{canDeactivate && this.state.isCurrentlyActive ? (
							<>
								<Block className={classes.elem('warning').b()}>
									<Icon name="warning" />
									<Text>{this.lang.warning}</Text>
								</Block>
								<Link
									onClick={() => {
										this.setState({ open: !this.state.open }, () =>
											this.props.actions.reloadModalPosition()
										);
									}}
								>
									{this.lang.seeList}
									{this.state.open ? (
										<Icon name="angle-up" space="mlm" />
									) : (
										<Icon name="angle-down" space="mlm" />
									)}
								</Link>
								{this.state.open ? (
									<Block className={classes.elem('related-content')}>
										{usedInMailCampaigns.length > 0 ? (
											<>
												<Text>{`${this.lang.mailCampaigns}:`}</Text>
												{usedInMailCampaigns}
												{moreMailCampaigns > 0
													? T('admin.editOptOutCategory.listMailCampaigns', {
															count: moreMailCampaigns
													  })
													: '.'}{' '}
											</>
										) : null}
										{usedInFlows.length > 0 ? (
											<>
												<Text>{`${this.lang.flows}:`}</Text>
												{usedInFlows}
												{moreFlows > 0
													? T('admin.editOptOutCategory.listMailCampaigns', {
															count: moreFlows
													  })
													: '.'}{' '}
											</>
										) : null}
									</Block>
								) : null}
							</>
						) : null}
					</div>
				</ModalContent>

				<ModalControls>
					<PrimaryButton loading={this.props.loading} onClick={this.props.actions.onSave}>
						{this.lang.save}
					</PrimaryButton>
					<ThirdButton onClick={this.props.actions.onClose}>{this.lang.abort}</ThirdButton>
				</ModalControls>
			</div>
		);
	}
}

OptOutModalContent.propTypes = {
	actions: PropTypes.object.isRequired,
	optOut: PropTypes.object.isRequired,
	onLanguageChange: PropTypes.func,
	availableLanguages: PropTypes.array,
	loading: PropTypes.bool
};

OptOutModalContent.defaultProps = {
	availableLanguages: []
};

export const OptOutModal = ({ id, close, className }) => {
	const [optOut, setOptOut] = useState();
	const [saving, setSaving] = useState(false);
	const [availableLanguages, setAvailableLanguages] = useState([
		'se',
		'no',
		'fi',
		'dk',
		'de',
		'nl',
		'pl',
		'pt',
		'es',
		'jp',
		'cn'
	]);

	useEffect(() => {
		const optOutPromise = id
			? GroupMailCategory.get(id).then(res => res.data)
			: Promise.resolve(GroupMailCategory.new());

		optOutPromise
			.then(data => {
				data.languages = data.languages || [];

				setAvailableLanguages(prev => prev.filter(l => !data.languages.find(lang => lang.language === l)));
				setOptOut(data);
			})
			.catch(() => {
				console.error('Failed to fetch opt-out category');
			});
	}, []);

	const actions = {
		onClose: close,
		onSave: () => {
			setSaving(true);
			const { valid, validated } = validate({ ...optOut });

			// Stop if invalid
			if (!valid) {
				setOptOut(validated);
				setSaving(false);
				return;
			}

			// Map opt-out before save
			const mapped = map({ ...optOut });

			GroupMailCategory.save(mapped)
				.then(close)
				.catch(() => setSaving(false));
		},
		onLanguageAdd: lang => {
			const code = lang.code.toLowerCase();

			setOptOut(prev => ({
				...prev,
				languages: [...prev.languages, { language: code, title: '', description: '' }]
			}));
			setAvailableLanguages(prev => prev.filter(l => l !== code));
		},
		onLanguageRemove: language => {
			setOptOut(prev => ({ ...prev, languages: prev.languages.filter(l => l.language !== language) }));
			setAvailableLanguages(prev => [...prev, language]);
		},
		onLanguageChange: (language, data) => {
			setOptOut(prev => {
				let next = { ...prev };
				if (data.title) {
					data.$invalidTitle = !validateStr(data.title);
				}
				if (data.description) {
					data.$invalidDescription = !validateStr(data.description);
				}

				if (language === 'en') {
					next = Object.assign(next, data);
				} else {
					const i = next.languages.findIndex(l => l.language === language);
					next.languages[i] = Object.assign(next.languages[i], data);
				}

				return next;
			});
		},
		activeChanged: active => setOptOut(prev => ({ ...prev, active })),
		reloadModalPosition: () => {}
	};

	if (!optOut) {
		return null;
	}

	return (
		<Modal className={className}>
			<OptOutModalContent
				optOut={optOut}
				availableLanguages={availableLanguages}
				loading={saving}
				actions={actions}
			/>
		</Modal>
	);
};

export const openEditOptOutModal = asyncModalAdapter({
	featureFlag: 'EDIT_OPT_OUT_REACT',
	openModalName: 'OptOutModal',
	upModalName: 'editOptOut',
	rejectOnEmpty: false
});

export default OptOutModalContent;
