import { CurrencyFormat } from 'App/babel/utils/numberFormat';
import BemClass from '@upsales/components/Utils/bemClass';
import { Text, Tooltip } from '@upsales/components';
import OrderRow from 'App/resources/Model/OrderRow';
import { renderToString } from 'react-dom/server';
import T from 'Components/Helpers/translate';
import React from 'react';

import './ToolTipOrderRows.scss';

const ToolTipOrderRows = ({
	hasMRR = false,
	interval,
	orderRows,
	currency,
	currencyRate,
	children
}: {
	hasMRR: boolean;
	interval: number;
	orderRows: OrderRow[];
	currency: string;
	currencyRate: number;
	children: JSX.Element;
}) => {
	const classNames = new BemClass('ToolTipOrderRows');
	const currencyFormat = new CurrencyFormat(currency);

	const toolTip = [];
	for (const [idx, orderRow] of orderRows.slice(0, 4).entries()) {
		const monthlyValue = (orderRow.price * orderRow.quantity * currencyRate) / interval;
		const recurringValue = monthlyValue * (hasMRR ? 1 : 12);
		const value = currencyFormat.short(recurringValue) + ` (${hasMRR ? 'MRR' : 'ARR'})`;
		const name = orderRow.product?.name;

		toolTip.push(
			<div key={orderRow.id ?? idx} className={classNames.elem('tooltipRow').b()}>
				<Text
					size="sm"
					color="white"
					className={classNames.elem('tooltipRow').elem('name').b() + ' text-ellipsis'}
				>
					{name}
				</Text>
				<Text size="sm" color="white" bold className={classNames.elem('tooltipRow').elem('value').b()}>
					{value}
				</Text>
			</div>
		);
	}

	const toolTipTitle = (
		<div className={classNames.elem('tooltip').b()}>
			{T('subscription.card.products.tooltip.title')}
			{toolTip}
			{orderRows.length > 4 ? (
				<div>
					<Text color="white" size="sm" className={classNames.elem('tooltip').elem('showMore').b()}>
						{T('subscription.clientCard.showMoreProducts')}
					</Text>
				</div>
			) : null}
		</div>
	);

	return (
		<Tooltip distance={20} title={renderToString(toolTipTitle)} position="left">
			{children}
		</Tooltip>
	);
};

export default ToolTipOrderRows;
